import { Inject, Injectable } from "@angular/core";
import { Flight } from "booking-app-v2/flights/models";
import { FareRules, FlightPricesRawResponse, FlightRawResponse } from "booking-app-v2/flights/types";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  FlightAvailabilityCheckResult,
  GlobalDataEnum,
} from "booking-app-v2/shared/types";
import { Observable, ReplaySubject } from "rxjs";
import { PayWithPointsCashService } from "../../pay-with-points-cash.service";
import { CheckoutError } from "booking-app-v2/shared/models";
import { AppSettingsService } from "../../app-settings.service";
import { GlobalData } from "../../global-data.service";
import { FlightUtilService } from "booking-app-v2/flights/services/flight-util.service";
import { RollbarService } from "../../rollbar.service";
import Rollbar from "rollbar";
import { UrlUtil } from "booking-app-v2/flights/utils/url.util";
import { ForceSyncBaseStep } from "./force-sync-base-step";

@Injectable({
  providedIn: "root",
})
export class FlightAvailabilityCheckService implements ForceSyncBaseStep {
  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.FLIGHT_AVAILABILITY_CHECK;

  flightPricesRawResponse: FlightPricesRawResponse;

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private payWithPointsCashService: PayWithPointsCashService,
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private flightUtilService: FlightUtilService,
  ) {}

  processAsync(results: CheckoutResults): Observable<FlightAvailabilityCheckResult> {
    const result: ReplaySubject<FlightAvailabilityCheckResult> = new ReplaySubject<FlightAvailabilityCheckResult>(1);

    this.flightPricesRawResponse = results[CHECKOUT_RESULT_NAME.FLIGHT_FETCH_PRICE];

    if (this.flightPricesRawResponse.itineraries.length === 0) {
      this.flightUtilService.showFlightExpiredDialog();
      result.error(new CheckoutError("Flight expired"));
    } else {
      if (this.flightPricesRawResponse.itineraries.length > 0) {
        this.globalData.set(
          GlobalDataEnum.IS_PRICE_CHANGED,
          this.flightUtilService.checkIsPriceChanged(this.flightPricesRawResponse),
        );
      }
      this.loadTermsData(result);
    }

    return result.asObservable();
  }

  private isExternalTermsConditionLink(rules): boolean {
    return rules.length === 1 && UrlUtil.isValidHttpUrl(rules[0].rule);
  }

  private loadTermsData(result: ReplaySubject<FlightAvailabilityCheckResult>): void {
    const flightRawResponse: FlightRawResponse = this.flightPricesRawResponse.itineraries[0];
    this.flightUtilService.fetchFlightsTerms(flightRawResponse.booking_key).subscribe({
      next: (fareRules: FareRules) => {
        let externalTermsConditionLink: string;
        if (this.isExternalTermsConditionLink(fareRules.rules)) {
          externalTermsConditionLink = UrlUtil.convertToHttpsUrl(fareRules.rules[0].rule);
        }
        result.next({
          selectedFlights: this.buildFlights(),
          bookingRules: fareRules,
          externalTermsConditionLink,
        });
      },
      error: (errors) => this.rollbar.warning("Error on loading flights terms: " + JSON.stringify(errors)),
    });
  }

  private buildFlights(): Flight[] {
    return this.flightPricesRawResponse.itineraries.map(
      (flightRawResponse) =>
        new Flight(this.appSettingsService, this.globalData, this.payWithPointsCashService, flightRawResponse),
    );
  }
}
