import { Component, Input } from "@angular/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { TravelTypeService } from "booking-app-v2/shared/services/initializers/travel-type.service";

@Component({
  selector: "travel-breakdown",
  template: `
  <div class="travel-breakdown-container"
    *ngIf='travelTypeService.isTravelType("hotels")'
    [ngClass]="{ 'default-direction': appSettingsService.isTravelBreakdownFlexDirectionDefault }">
    <span class="travel-breakdown-item" *ngIf="duration == 1" translate= "txt.night_one"></span>
    <span
      class="travel-breakdown-item" *ngIf='duration > 1'
      translate="night.count.other"
      [translateParams]="{ unit: 'night.count.other' | translate, count: duration }"></span>
    <span
      class="travel-breakdown-item"
      *ngIf='roomCount == 1' translate= 'Single Room Count' [translateParams]="{ count: 'roomCount' }"></span>
    <span
      class="travel-breakdown-item"
      *ngIf='roomCount > 1' translate= 'Multiple Room Count' [translateParams]="{ count: 'roomCount' }"></span>
  </div>
  <div class="travel-breakdown-container"
    *ngIf='travelTypeService.isTravelType("cars")'
    [ngClass]="{ 'default-direction': appSettingsService.isTravelBreakdownFlexDirectionDefault }">
    <span class="travel-breakdown-item" *ngIf="duration == 1" translate= "Single Day Count"></span>
    <span
      class="travel-breakdown-item" *ngIf='duration > 1'
      translate="Multiple Days Count"
      [translateParams]="{ count: duration }"></span>
  </div>
  `,
})
export class TravelBreakdownComponent {
  @Input() duration: number;
  @Input() roomCount: number;

  constructor(
    public travelTypeService: TravelTypeService,
    public appSettingsService: AppSettingsService,
    ) {}
}
