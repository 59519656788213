import { NgModule } from "@angular/core";
import {
  MobileRoomPricesButtonComponent,
} from "booking-app-v2/shared/components/mobile-room-prices-button/mobile-room-prices-button.component";

import { SharedModule } from "booking-app-v2/shared/shared.module";

@NgModule({
  declarations: [ MobileRoomPricesButtonComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ MobileRoomPricesButtonComponent ],
  providers: [],
})
export class MobileRoomPricesButtonModule {}
