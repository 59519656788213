import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "main-button",
  template: `
    <button [ngClass]="buttonClass" [disabled]="isDisabled">
      <div class="button-content">
        <ng-content select="[leftContent]"></ng-content>
        <ng-content select="[centerContent]"></ng-content>
        <ng-content select="[rightContent]"></ng-content>
      </div>
    </button>
  `,
})
export class MainButtonComponent implements OnInit {
  @Input() hasRelativePosition: boolean;
  @Input() isDisabled: boolean;
  buttonClass: object;

  ngOnInit(): void {
    this.buttonClass = {
      "relative": this.hasRelativePosition,
      "fixed": !this.hasRelativePosition,
      "disabled": this.isDisabled,
    };
  }
}
