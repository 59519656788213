import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Config } from "@ascenda/ngx-simple-dropdown";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { HotelSearchForm } from "booking-app-v2/hotels/models";
import { DEVICE } from "booking-app-v2/shared/types";

@Component({
  selector: "room-guest-counter",
  template: `
    <div class="room-guest-counter">
      <div class="room-guest-container">
        <input-counter
          [value]="roomCount"
          [minValue]="minRoomCount"
          [maxValue]="maxRoomCount"
          (onUpdate)="updateRoomCount($event)"
          [customAriaLabel]="'rooms'"
          [customLabel]="roomLabel()"
          *ngIf="shouldRenderRoomCounter()"
        ></input-counter>
        <input-counter
          [value]="adultCount"
          [minValue]="minAdultCount"
          [maxValue]="maxAdultCount"
          (onUpdate)="updateAdultCount($event)"
          [customAriaLabel]="'adults'"
          [customLabel]="adultLabel()"
        ></input-counter>
        <input-counter
          [value]="childCount"
          [minValue]="minChildCount"
          [maxValue]="maxChildCount"
          (onUpdate)="updateChildCount($event)"
          [customAriaLabel]="'children'"
          [customLabel]="childLabel()"
          *ngIf="shouldRenderChildrenCounter"
        ></input-counter>
        <div class="children-age-label" *ngIf="shouldRenderChildrenCounter && numOfChildren.length > 0">
          {{ "Age of children:" | translate }}
        </div>
        <div class="children-inclusion-label" *ngIf="showMaxGuestsMessage()">
          {{ "txt.max_guests_including_children" | translate }}
        </div>
        <div class="age-selectors" *ngIf="shouldRenderChildrenCounter && numOfChildren.length > 0">
          <div class="age-selector" *ngFor="let num of numOfChildren; let i = index">
            <ascenda-ngx-simple-dropdown
              [config]="childrenAgeDropdownConfig"
              [selectedOption]="childrenAges[i]"
              (setDropDownOption)="updateChildrenAges($event, i)"
            ></ascenda-ngx-simple-dropdown>
          </div>
        </div>
      </div>

      <div class="closer" *ngIf="showCloseButton">
        <a (click)="closeModal.emit()"
           (blur)="closeModal.emit()"
           href="javascript:;"
           id="room-guest-counter-apply"
           role="button">
            {{ "button.done" | translate }}
        </a>
      </div>
    </div>
  `,
})
export class RoomGuestCounterComponent {
  @Input() roomCount: number;
  @Input() adultCount: number;
  @Input() childCount: number;
  @Input() childrenAges: number[];

  @Output() onUpdateRoomCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateAdultCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateChildCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateChildrenAges: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  // local state
  minRoomCount: number = HotelSearchForm.MIN_ROOM_COUNT;
  minAdultCount: number = HotelSearchForm.MIN_ADULT_COUNT;
  minChildCount: number = HotelSearchForm.MIN_CHILD_COUNT;
  maxRoomCount: number = HotelSearchForm.MAX_ROOM_COUNT;
  maxAdultCount: number = HotelSearchForm.MAX_ADULT_COUNT;
  maxChildCount: number = HotelSearchForm.MAX_CHILD_COUNT;
  childrenAgeDropdownConfig: Config<number> = HotelSearchForm.CHILDREN_AGE_DROPDOWN_CONFIG;

  constructor(
    private translateService: TranslateService,
    private windowRefService: WindowRefService,
    private appSettingsService: AppSettingsService,
  ) { }

  updateRoomCount(value: number) {
    this.roomCount = value;
    this.onUpdateRoomCount.emit(value);
  }

  updateAdultCount(value: number) {
    this.adultCount = value;
    this.onUpdateAdultCount.emit(value);
  }

  updateChildCount(value: number) {
    this.childCount = value;
    this.onUpdateChildCount.emit(value);
  }

  get numOfChildren(): number[] {
    if (this.childCount === undefined) {
      return [];
    }
    return new Array(this.childCount)
      .fill("")
      .map((_, index) => index);
  }

  updateChildrenAges(value: number, index: number) {
    const childrenAges = [...this.childrenAges];
    childrenAges[index] = value;
    this.onUpdateChildrenAges.emit(childrenAges);
  }

  formatChildValue(value: any) {
    return value;
  }

  shouldRenderRoomCounter(): boolean {
    return Number.isInteger(this.roomCount);
  }

  get shouldRenderChildrenCounter(): boolean {
    if (!Number.isInteger(this.childCount)) {
      return false;
    }
    if (this.shouldRenderRoomCounter()) {
      return this.roomCount === 1;
    }
    return true;
  }

  showMaxGuestsMessage(): boolean {
    return this.appSettingsService.maximumGuestsMessage.showMessage &&
      this.adultCount >= this.appSettingsService.maximumGuestsMessage.maximumGuests;
  }

  adultLabel(): string {
    if (Number.isInteger(this.childCount)) {
      return this.showLabelAsAdult();
    } else {
      return this.showLabelAsGuest();
    }
  }

  showLabelAsAdult(): string {
    if (this.labelOnlyRoomCounter()) {
      return this.translateService.instant("txt.adults");
    } else if (this.adultCount === 1) {
      return this.translateService.instant("count.adult_one");
    } else {
      return this.translateService.instant("count.adult_other", { count: this.adultCount });
    }
  }

  showLabelAsGuest(): string {
    if (this.labelOnlyRoomCounter()) {
      return this.translateService.instant("txt.adults");
    } else if (this.adultCount === 1) {
      return this.translateService.instant("Single Guest Count");
    } else {
      return this.translateService.instant("Multiple Guest Count", { count: this.adultCount });
    }
  }

  childLabel(): string {
    if (this.labelOnlyRoomCounter()) {
      return this.translateService.instant("Children");
    } else if (this.childCount === 1) {
      return this.translateService.instant("count.children_one");
    } else {
      return this.translateService.instant("count.children_other", { count: this.childCount });
    }
  }

  roomLabel(): string {
    if (this.labelOnlyRoomCounter()) {
      return this.translateService.instant("Rooms");
    } else if (this.roomCount === 1) {
      // count.room.one - phrase app doesnt register this pluralization key for other languages
      return this.translateService.instant("count.room_one");
    } else {
      return this.translateService.instant("count.room.other", { count: this.roomCount });
    }
  }

  get showCloseButton(): boolean {
    return this.windowRefService.device !== DEVICE.PHONE &&
      this.appSettingsService.roomGuestParameters.hasRoomGuestCloser;
  }

  private labelOnlyRoomCounter(): boolean {
    return this.appSettingsService.roomGuestParameters.labelOnlyRoomCounter;
  }
}
