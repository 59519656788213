import { Injectable } from "@angular/core";

import {
  CarAvailabilityCheckResult,
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
} from "booking-app-v2/shared/types";
import { CarsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/cars-url-builder.service";
import { Observable, ReplaySubject, take } from "rxjs";
import { CarPricesRawResponse } from "booking-app-v2/cars/types";
import { ForceSyncBaseStep } from "./force-sync-base-step";
import { Car } from "booking-app-v2/cars/models";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";
import { CouponService } from "booking-app-v2/shared/services/coupon.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { VoucherService } from "booking-app-v2/shared/services/voucher.service";

@Injectable({
  providedIn: "root",
})
export class CarAvailabilityCheckService implements ForceSyncBaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.CAR_AVAILABILITY_CHECK;

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private payWithPointsCashService: PayWithPointsCashService,
    private carsUrlBuilderService: CarsUrlBuilderService,
    private couponService: CouponService,
    private currencyService: CurrenciesService,
    private voucherService: VoucherService,
  ) {}

  processAsync(results: CheckoutResults): Observable<CarAvailabilityCheckResult> {
    const result: ReplaySubject<Car> = new ReplaySubject<Car>(1);

    results[CHECKOUT_RESULT_NAME.CAR_FETCH_PRICE].pipe(take(1)).subscribe((rawResponse: CarPricesRawResponse) => {
      if (Object.keys(rawResponse).length === 0 || rawResponse.cars.length === 0) {
        result.error(new Error("Car expired"));
        this.carsUrlBuilderService.redirectToCarResultsPage();
      } else {
        const car = new Car(
          this.appSettingsService,
          this.globalData,
          this.currencyService,
          this.payWithPointsCashService,
          rawResponse.cars[0],
          true,
          this.voucherService.vouchers,
        );
        if (this.couponService.hasCouponCode()) {
          this.couponService.updateValidationResponse(car);
        }
        result.next(car);
      }
    });

    return result.asObservable();
  }
}
