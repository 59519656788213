import { Pipe, PipeTransform } from "@angular/core";

import { GlobalData } from "../services/global-data.service";
import { FormatNumberUtil } from "../utils/format-number.util";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

@Pipe({
  name: "localeShortenNumber",
  pure: false,
})
export class LocaleShortenNumberPipe implements PipeTransform {

  constructor(
    private globalData: GlobalData,
  ) { }

  public transform(value: number): string {
    return FormatNumberUtil.summarizeNumber(
      value,
      this.globalData.get(GlobalDataEnum.SELECTED_LOCALE),
    );
  }
}
