import { HotelRawPrice } from "../types";
import { RoomPrice } from "../models";

export class ComplimentaryNightsEarnDecorator {
  static decorate(price: RoomPrice, rawPrice: HotelRawPrice): void {
    price.complimentary_nights = rawPrice.complimentary_nights;
    price.complimentary_discount = rawPrice.complimentary_discount;
    price.original_price = rawPrice.original_price;
    price.original_price_per_room_per_night = rawPrice.original_price_per_room_per_night;
    price.original_lowest_price_per_room_per_night = rawPrice.original_lowest_price_per_room_per_night;
  }
}
