import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { RoomImageModule } from "booking-app-v2/shared/components/room-image/room-image.module";
import { GroupedRoomListItemModule } from "booking-app-v2/shared/components/grouped-room-list-item/grouped-room-list-item.module";
import { GroupedRoomComponent } from "./grouped-room.component";

@NgModule({
  declarations: [GroupedRoomComponent],
  imports: [
    SharedModule,
    RoomImageModule,
    GroupedRoomListItemModule,
  ],
  exports: [GroupedRoomComponent],
  providers: [],
})
export class GroupedRoomModule { }
