import { Directive } from "@angular/core";
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from "@angular/forms";
import { map, Observable } from "rxjs";

import { CreditCardValidationService } from "booking-app-v2/shared/services/credit-card-validation.service";

@Directive({
  selector: "[validateCreditCard]",
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: CreditCardValidator, multi: true}],
})
export class CreditCardValidator implements AsyncValidator {
  constructor(private creditCardValidationService: CreditCardValidationService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.creditCardValidationService.validate(control.value).pipe(
      map((isValidCreditCard: boolean) => isValidCreditCard ? null : { invalidCreditCard: true }),
    );
  }
}
