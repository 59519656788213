import { Country } from "booking-app-v2/shared/types";

export const countries: Country[] = [
  {code: "AN", name: "Netherlands Antilles (NL)", phoneCode: 599, continent: "Europe"},
  {code: "AX", name: "Aland Islands", phoneCode: 358},
  {code: "AL", name: "Albania", phoneCode: 355, continent: "EU"},
  {code: "AS", name: "American Samoa (US)", phoneCode: 1, continent: "OC"},
  {code: "AD", name: "Andorra", phoneCode: 376, continent: "EU"},
  {code: "AO", name: "Angola", phoneCode: 244, continent: "AF"},
  {code: "AQ", name: "Antarctica", phoneCode: 54, continent: "AN"},
  {code: "AG", name: "Antigua and Barbuda", phoneCode: 1, continent: "NA"},
  {code: "AU", name: "Australia", phoneCode: 61, continent: "OC"},
  {code: "AT", name: "Austria", phoneCode: 43, continent: "EU"},
  {code: "AZ", name: "Azerbaijan", phoneCode: 994, continent: "AS"},
  {code: "BD", name: "Bangladesh", phoneCode: 880, continent: "AS"},
  {code: "BB", name: "Barbados", phoneCode: 1, continent: "NA"},
  {code: "BY", name: "Belarus", phoneCode: 375, continent: "EU"},
  {code: "BJ", name: "Benin", phoneCode: 229, continent: "AF"},
  {code: "BT", name: "Bhutan", phoneCode: 975, continent: "AS"},
  {code: "BA", name: "Bosnia and Herzegovina", phoneCode: 387, continent: "EU"},
  {code: "BW", name: "Botswana", phoneCode: 267, continent: "AF"},
  {code: "IO", name: "British Indian Ocean Territory", phoneCode: 246, continent: "AS"},
  {code: "BG", name: "Bulgaria", phoneCode: 359, continent: "EU"},
  {code: "BF", name: "Burkina Faso", phoneCode: 226, continent: "AF"},
  {code: "BI", name: "Burundi", phoneCode: 257, continent: "AF"},
  {code: "CM", name: "Cameroon", phoneCode: 237, continent: "AF"},
  {code: "CA", name: "Canada", phoneCode: 1, continent: "NA"},
  {code: "CV", name: "Cape Verde", phoneCode: 238, continent: "AF"},
  {code: "CF", name: "Central African Republic", phoneCode: 236, continent: "AF"},
  {code: "TD", name: "Chad", phoneCode: 235, continent: "AF"},
  {code: "CN", name: "China", phoneCode: 86, continent: "AS"},
  {code: "CC", name: "Cocos (Keeling) Islands (AU)", phoneCode: 61, continent: "AS"},
  {code: "KM", name: "Comoros", phoneCode: 269, continent: "AF"},
  {code: "CG", name: "Congo, Republic of the", phoneCode: 242, continent: "AF"},
  {code: "CK", name: "Cook Islands (NZ)", phoneCode: 682, continent: "OC"},
  {code: "CR", name: "Costa Rica", phoneCode: 506, continent: "NA"},
  {code: "CI", name: "Côte D'Ivoire", phoneCode: 225, continent: "AF"},
  {code: "HR", name: "Croatia", phoneCode: 385, continent: "EU"},
  {code: "CU", name: "Cuba", phoneCode: 53, continent: "NA"},
  {code: "CZ", name: "Czech Republic", phoneCode: 420, continent: "EU"},
  {code: "DK", name: "Denmark", phoneCode: 45, continent: "EU"},
  {code: "DJ", name: "Djibouti", phoneCode: 253, continent: "AF"},
  {code: "DO", name: "Dominican Republic", phoneCode: 1, continent: "NA"},
  {code: "GQ", name: "Equatorial Guinea", phoneCode: 240, continent: "AF"},
  {code: "ER", name: "Eritrea", phoneCode: 291, continent: "AF"},
  {code: "EE", name: "Estonia", phoneCode: 372, continent: "EU"},
  {code: "ET", name: "Ethiopia", phoneCode: 251, continent: "AF"},
  {code: "FJ", name: "Fiji", phoneCode: 679, continent: "OC"},
  {code: "FI", name: "Finland", phoneCode: 358, continent: "EU"},
  {code: "FR", name: "France", phoneCode: 33, continent: "EU"},
  {code: "PF", name: "French Polynesia (FR)", phoneCode: 689, continent: "OC"},
  {code: "TF", name: "French Southern Territories", phoneCode: 262},
  {code: "GA", name: "Gabon", phoneCode: 241, continent: "AF"},
  {code: "GM", name: "Gambia", phoneCode: 220, continent: "AF"},
  {code: "GE", name: "Georgia", phoneCode: 995, continent: "AS"},
  {code: "GH", name: "Ghana", phoneCode: 233, continent: "AF"},
  {code: "GI", name: "Gibraltar (UK)", phoneCode: 350, continent: "EU"},
  {code: "GR", name: "Greece", phoneCode: 30, continent: "EU"},
  {code: "GD", name: "Grenada", phoneCode: 1, continent: "NA"},
  {code: "GP", name: "Guadeloupe (FR)", phoneCode: 590, continent: "NA"},
  {code: "GT", name: "Guatemala", phoneCode: 502, continent: "NA"},
  {code: "GG", name: "Guernsey", phoneCode: 44},
  {code: "GN", name: "Guinea", phoneCode: 224, continent: "AF"},
  {code: "GW", name: "Guinea-Bissau", phoneCode: 245, continent: "AF"},
  {code: "HT", name: "Haiti", phoneCode: 509, continent: "NA"},
  {code: "VA", name: "Holy See (Vatican City)", phoneCode: 379, continent: "EU"},
  {code: "HK", name: "Hong Kong", phoneCode: 852, continent: "AS"},
  {code: "HU", name: "Hungary", phoneCode: 36, continent: "EU"},
  {code: "IS", name: "Iceland", phoneCode: 354, continent: "EU"},
  {code: "IN", name: "India", phoneCode: 91, continent: "AS"},
  {code: "IM", name: "Isle of Man", phoneCode: 44, continent: "EU"},
  {code: "IT", name: "Italy", phoneCode: 39, continent: "EU"},
  {code: "JP", name: "Japan", phoneCode: 81, continent: "AS"},
  {code: "JE", name: "Jersey", phoneCode: 44},
  {code: "KE", name: "Kenya", phoneCode: 254, continent: "AF"},
  {code: "KI", name: "Kiribati", phoneCode: 686, continent: "OC"},
  {code: "KR", name: "Korea, Republic of (South)", phoneCode: 82, continent: "AS"},
  {code: "KG", name: "Kyrgyzstan", phoneCode: 996, continent: "AS"},
  {code: "LV", name: "Latvia", phoneCode: 371, continent: "EU"},
  {code: "LS", name: "Lesotho", phoneCode: 266, continent: "AF"},
  {code: "LR", name: "Liberia", phoneCode: 231, continent: "AF"},
  {code: "LY", name: "Libya", phoneCode: 218, continent: "AF"},
  {code: "LI", name: "Liechtenstein", phoneCode: 423, continent: "EU"},
  {code: "LU", name: "Luxembourg", phoneCode: 352, continent: "EU"},
  {code: "MO", name: "Macau", phoneCode: 853, continent: "AS"},
  {code: "MK", name: "Macedonia", phoneCode: 389, continent: "EU"},
  {code: "MG", name: "Madagascar", phoneCode: 261, continent: "AF"},
  {code: "MW", name: "Malawi", phoneCode: 265, continent: "AF"},
  {code: "ML", name: "Mali", phoneCode: 223, continent: "AF"},
  {code: "MT", name: "Malta", phoneCode: 356, continent: "EU"},
  {code: "MQ", name: "Martinique (FR)", phoneCode: 596, continent: "NA"},
  {code: "MR", name: "Mauritania", phoneCode: 222, continent: "AF"},
  {code: "YT", name: "Mayotte (FR)", phoneCode: 262, continent: "AF"},
  {code: "FM", name: "Micronesia, Federated States of", phoneCode: 691, continent: "OC"},
  {code: "MD", name: "Moldova Republic of", phoneCode: 373, continent: "EU"},
  {code: "MC", name: "Monaco", phoneCode: 377, continent: "EU"},
  {code: "MN", name: "Mongolia", phoneCode: 976, continent: "AS"},
  {code: "MS", name: "Montserrat (UK)", phoneCode: 1, continent: "NA"},
  {code: "MZ", name: "Mozambique", phoneCode: 258, continent: "AF"},
  {code: "NR", name: "Nauru", phoneCode: 674, continent: "OC"},
  {code: "NP", name: "Nepal", phoneCode: 977, continent: "AS"},
  {code: "NC", name: "New Caledonia (FR)", phoneCode: 687, continent: "OC"},
  {code: "NZ", name: "New Zealand", phoneCode: 64, continent: "OC"},
  {code: "AI", name: "Anguilla (UK)", phoneCode: 1, continent: "NA"},
  {code: "AW", name: "Aruba", phoneCode: 297, continent: "NA"},
  {code: "BS", name: "Bahamas", phoneCode: 1, continent: "NA"},
  {code: "BE", name: "Belgium", phoneCode: 32, continent: "EU"},
  {code: "BM", name: "Bermuda (UK)", phoneCode: 1, continent: "NA"},
  {code: "BQ", name: "Bonaire, Sint Eustatius and Saba", phoneCode: 599, continent: "NA"},
  {code: "VG", name: "British Virgin Islands (UK)", phoneCode: 1, continent: "NA"},
  {code: "KY", name: "Cayman Islands (UK)", phoneCode: 1, continent: "NA"},
  {code: "CX", name: "Christmas Island (AU)", phoneCode: 61, continent: "AS"},
  {code: "NI", name: "Nicaragua", phoneCode: 505, continent: "NA"},
  {code: "MM", name: "Myanmar", phoneCode: 95, continent: "AS"},
  {code: "MU", name: "Mauritius", phoneCode: 230, continent: "AF"},
  {code: "MY", name: "Malaysia", phoneCode: 60, continent: "AS"},
  {code: "LB", name: "Lebanon", phoneCode: 961, continent: "AS"},
  {code: "LA", name: "Laos", phoneCode: 856, continent: "AS"},
  {code: "KW", name: "Kuwait", phoneCode: 965, continent: "AS"},
  {code: "JO", name: "Jordan", phoneCode: 962, continent: "AS"},
  {code: "IQ", name: "Iraq", phoneCode: 964, continent: "AS"},
  {code: "IR", name: "Iran", phoneCode: 98, continent: "AS"},
  {code: "ID", name: "Indonesia", phoneCode: 62, continent: "AS"},
  {code: "HN", name: "Honduras", phoneCode: 504, continent: "NA"},
  {code: "EG", name: "Egypt", phoneCode: 20, continent: "AF"},
  {code: "EC", name: "Ecuador", phoneCode: 593, continent: "SA"},
  {code: "CY", name: "Cyprus", phoneCode: 357, continent: "EU"},
  {code: "CO", name: "Colombia", phoneCode: 57, continent: "SA"},
  {code: "CL", name: "Chile", phoneCode: 56, continent: "SA"},
  {code: "KH", name: "Cambodia", phoneCode: 855, continent: "AS"},
  {code: "BN", name: "Brunei Darussalam", phoneCode: 673, continent: "AS"},
  {code: "BR", name: "Brazil", phoneCode: 55, continent: "SA"},
  {code: "BO", name: "Bolivia", phoneCode: 591, continent: "SA"},
  {code: "BZ", name: "Belize", phoneCode: 501, continent: "NA"},
  {code: "BH", name: "Bahrain", phoneCode: 973, continent: "AS"},
  {code: "AM", name: "Armenia", phoneCode: 374, continent: "AS"},
  {code: "AR", name: "Argentina", phoneCode: 54, continent: "SA"},
  {code: "DZ", name: "Algeria", phoneCode: 213, continent: "AF"},
  {code: "AF", name: "Afghanistan", phoneCode: 93, continent: "AS"},
  {code: "CD", name: "Congo, Democratic Republic of the", phoneCode: 243, continent: "AF"},
  {code: "CW", name: "Curaçao", phoneCode: 599, continent: "NA"},
  {code: "DM", name: "Dominica", phoneCode: 1, continent: "NA"},
  {code: "FO", name: "Faroe Islands (DK)", phoneCode: 298, continent: "EU"},
  {code: "DE", name: "Germany", phoneCode: 49, continent: "EU"},
  {code: "GL", name: "Greenland (DK)", phoneCode: 299, continent: "NA"},
  {code: "GU", name: "Guam (US)", phoneCode: 1, continent: "OC"},
  {code: "HM", name: "Heard Island and McDonald Islands", phoneCode: 0},
  {code: "IE", name: "Ireland", phoneCode: 353, continent: "EU"},
  {code: "JM", name: "Jamaica", phoneCode: 1, continent: "NA"},
  {code: "KZ", name: "Kazakhstan", phoneCode: 7, continent: "AS"},
  {code: "KP", name: "Korea, Democratic People's Republic (North)", phoneCode: 850, continent: "AS"},
  {code: "LT", name: "Lithuania", phoneCode: 370, continent: "EU"},
  {code: "MH", name: "Marshall Islands", phoneCode: 692, continent: "OC"},
  {code: "ME", name: "Montenegro", phoneCode: 382, continent: "EU"},
  {code: "NA", name: "Namibia", phoneCode: 264, continent: "AF"},
  {code: "NL", name: "Netherlands", phoneCode: 31, continent: "EU"},
  {code: "NE", name: "Niger", phoneCode: 227, continent: "AF"},
  {code: "NG", name: "Nigeria", phoneCode: 234, continent: "AF"},
  {code: "NU", name: "Niue", phoneCode: 683, continent: "OC"},
  {code: "NF", name: "Norfolk Island (AU)", phoneCode: 672, continent: "OC"},
  {code: "MP", name: "Northern Mariana Islands (US)", phoneCode: 1, continent: "OC"},
  {code: "NO", name: "Norway", phoneCode: 47, continent: "EU"},
  {code: "OM", name: "Oman", phoneCode: 968, continent: "AS"},
  {code: "PK", name: "Pakistan", phoneCode: 92, continent: "AS"},
  {code: "PW", name: "Palau", phoneCode: 680, continent: "OC"},
  {code: "PA", name: "Panama", phoneCode: 507, continent: "NA"},
  {code: "PG", name: "Papua New Guinea", phoneCode: 675, continent: "OC"},
  {code: "PN", name: "Pitcairn Islands (UK)", phoneCode: 64},
  {code: "PL", name: "Poland", phoneCode: 48, continent: "EU"},
  {code: "PT", name: "Portugal", phoneCode: 351, continent: "EU"},
  {code: "PR", name: "Puerto Rico (US)", phoneCode: 1, continent: "NA"},
  {code: "RE", name: "Reunion (FR)", phoneCode: 262, continent: "AF"},
  {code: "RO", name: "Romania", phoneCode: 40, continent: "EU"},
  {code: "RU", name: "Russia", phoneCode: 7, continent: "EU"},
  {code: "RW", name: "Rwanda", phoneCode: 250, continent: "AF"},
  {code: "BL", name: "Saint Barthelemy", phoneCode: 590},
  {code: "SH", name: "Saint Helena (UK)", phoneCode: 290, continent: "AF"},
  {code: "KN", name: "Saint Kitts and Nevis", phoneCode: 1, continent: "NA"},
  {code: "LC", name: "Saint Lucia", phoneCode: 1, continent: "NA"},
  {code: "MF", name: "Saint Martin", phoneCode: 590},
  {code: "PM", name: "Saint Pierre & Miquelon (FR)", phoneCode: 508, continent: "NA"},
  {code: "VC", name: "Saint Vincent and the Grenadines", phoneCode: 1, continent: "NA"},
  {code: "WS", name: "Samoa", phoneCode: 685, continent: "OC"},
  {code: "SM", name: "San Marino", phoneCode: 378, continent: "EU"},
  {code: "ST", name: "Sao Tome and Principe", phoneCode: 239, continent: "AF"},
  {code: "SN", name: "Senegal", phoneCode: 221, continent: "AF"},
  {code: "RS", name: "Serbia", phoneCode: 381, continent: "EU"},
  {code: "SC", name: "Seychelles", phoneCode: 248, continent: "AF"},
  {code: "SL", name: "Sierra Leone", phoneCode: 232, continent: "AF"},
  {code: "SX", name: "Sint Maarten (Dutch Part)", phoneCode: 1, continent: "NA"},
  {code: "SK", name: "Slovakia", phoneCode: 421, continent: "EU"},
  {code: "SI", name: "Slovenia", phoneCode: 386, continent: "EU"},
  {code: "SB", name: "Solomon Islands", phoneCode: 677, continent: "OC"},
  {code: "SO", name: "Somalia", phoneCode: 252, continent: "AF"},
  {code: "ZA", name: "South Africa", phoneCode: 27, continent: "AF"},
  {code: "GS", name: "South Georgia & South Sandwich Islands (UK)", phoneCode: 500},
  {code: "SS", name: "South Sudan", phoneCode: 211, continent: "AF"},
  {code: "ES", name: "Spain", phoneCode: 34, continent: "EU"},
  {code: "LK", name: "Sri Lanka", phoneCode: 94, continent: "AS"},
  {code: "SD", name: "Sudan", phoneCode: 249, continent: "AF"},
  {code: "SJ", name: "Svalbard and Jan Mayen", phoneCode: 47},
  {code: "SZ", name: "Swaziland", phoneCode: 268, continent: "AF"},
  {code: "SE", name: "Sweden", phoneCode: 46, continent: "EU"},
  {code: "CH", name: "Switzerland", phoneCode: 41, continent: "EU"},
  {code: "TJ", name: "Tajikistan", phoneCode: 992, continent: "AS"},
  {code: "TZ", name: "Tanzania", phoneCode: 255, continent: "AF"},
  {code: "TL", name: "Timor-Leste", phoneCode: 670, continent: "OC"},
  {code: "TG", name: "Togo", phoneCode: 228, continent: "AF"},
  {code: "TK", name: "Tokelau", phoneCode: 690, continent: "OC"},
  {code: "TO", name: "Tonga", phoneCode: 676, continent: "OC"},
  {code: "TT", name: "Trinidad and Tobago", phoneCode: 1, continent: "NA"},
  {code: "TN", name: "Tunisia", phoneCode: 216, continent: "AF"},
  {code: "TM", name: "Turkmenistan", phoneCode: 993, continent: "AS"},
  {code: "TC", name: "Turks and Caicos Islands (UK)", phoneCode: 1, continent: "NA"},
  {code: "TV", name: "Tuvalu", phoneCode: 688, continent: "OC"},
  {code: "UG", name: "Uganda", phoneCode: 256, continent: "AF"},
  {code: "UA", name: "Ukraine", phoneCode: 380, continent: "EU"},
  {code: "GB", name: "United Kingdom", phoneCode: 44, continent: "EU"},
  {code: "US", name: "United States", phoneCode: 1, continent: "NA"},
  {code: "UM", name: "United States Minor Outlying Islands", phoneCode: 1, continent: "OC"},
  {code: "VI", name: "Virgin Islands (US)", phoneCode: 1, continent: "NA"},
  {code: "UZ", name: "Uzbekistan", phoneCode: 998, continent: "AS"},
  {code: "EH", name: "Western Sahara", phoneCode: 212},
  {code: "ZM", name: "Zambia", phoneCode: 260, continent: "AF"},
  {code: "ZW", name: "Zimbabwe", phoneCode: 263, continent: "AF"},
  {code: "YE", name: "Yemen", phoneCode: 967, continent: "AS"},
  {code: "MA", name: "Morocco", phoneCode: 212, continent: "AF"},
  {code: "TW", name: "Taiwan", phoneCode: 886, continent: "AS"},
  {code: "VN", name: "Vietnam", phoneCode: 84, continent: "AS"},
  {code: "VE", name: "Venezuela", phoneCode: 58, continent: "SA"},
  {code: "VU", name: "Vanuatu", phoneCode: 678, continent: "OC"},
  {code: "UY", name: "Uruguay", phoneCode: 598, continent: "SA"},
  {code: "WF", name: "Wallis and Futuna (FR)", phoneCode: 681, continent: "OC"},
  {code: "AE", name: "United Arab Emirates", phoneCode: 971, continent: "AS"},
  {code: "TR", name: "Turkey", phoneCode: 90, continent: "AS"},
  {code: "TH", name: "Thailand", phoneCode: 66, continent: "AS"},
  {code: "SY", name: "Syria", phoneCode: 963, continent: "AS"},
  {code: "SR", name: "Suriname", phoneCode: 597, continent: "SA"},
  {code: "SG", name: "Singapore", phoneCode: 65, continent: "AS"},
  {code: "SA", name: "Saudi Arabia", phoneCode: 966, continent: "AS"},
  {code: "QA", name: "Qatar", phoneCode: 974, continent: "AS"},
  {code: "PH", name: "Philippines", phoneCode: 63, continent: "AS"},
  {code: "PE", name: "Peru", phoneCode: 51, continent: "SA"},
  {code: "PY", name: "Paraguay", phoneCode: 595, continent: "SA"},
  {code: "PS", name: "Palestinian Territories", phoneCode: 970, continent: "AS"},
  {code: "MX", name: "Mexico", phoneCode: 52, continent: "NA"},
  {code: "MV", name: "Maldives", phoneCode: 960, continent: "AS"},
  {code: "IL", name: "Israel", phoneCode: 972, continent: "AS"},
  {code: "GY", name: "Guyana", phoneCode: 592, continent: "SA"},
  {code: "GF", name: "French Guiana (FR)", phoneCode: 594, continent: "SA"},
  {code: "FK", name: "Falkland Islands (UK)", phoneCode: 500, continent: "SA"},
  {code: "SV", name: "El Salvador", phoneCode: 503, continent: "NA"},
];
