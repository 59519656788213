import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute } from "@angular/router";

import { Hotel, HotelSearchForm, Room, RoomPrice, Rooms, TrustYou } from "booking-app-v2/hotels/models";
import { LandingPage, PointsPartner } from "booking-app-v2/shared/models";
import { HotelRoomRawResponse, RoomRawPrice, TrustYouReviews } from "booking-app-v2/hotels/types";
import { GlobalDataEnum, ProductType } from "booking-app-v2/shared/types";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { DetailsService, HotelCancellationPolicyService, HotelUtilService } from "booking-app-v2/hotels/services";
import { TrustYouService } from "booking-app-v2/shared/services/trust-you.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import { CheckoutValidationService } from "booking-app-v2/shared/services/checkout-validation.service";
import { VoucherService } from "booking-app-v2/shared/services/voucher.service";
import { HotelsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/hotels-url-builder.service";
import { PointsCashPaymentHandlingService } from "booking-app-v2/shared/services/points-cash-payment-handling.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  templateUrl: "/html/hotels/hotel_details_v2",
})
export class DetailsPageComponent implements OnInit, OnDestroy {
  hotel: Hotel;
  trustyou: TrustYou;
  pollErrorCount: number = 0;
  availRooms: Room[] = [];
  firstRoom: Room;
  landingPage: LandingPage;
  productType: ProductType;
  hotelSearchForm: HotelSearchForm;
  pointsPartner: PointsPartner;
  noRoomsMessage: boolean = false;
  doubleMobileRoomPricesButton: boolean;
  taxInclusive: boolean;

  // flags
  searchCompleted: boolean;
  pollHotelPricesIsLoading: boolean = false;
  displayAllRooms: boolean;

  // subscriptions / timeout
  hotelRoomPollTimeout: ReturnType<typeof setTimeout> = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private detailsService: DetailsService,
    private hotelUtilService: HotelUtilService,
    private windowRefService: WindowRefService,
    private trustYouService: TrustYouService,
    private globalData: GlobalData,
    private currencyService: CurrenciesService,
    private payWithPointsCashService: PayWithPointsCashService,
    private hotelCancellationPolicyService: HotelCancellationPolicyService,
    private pageDiscoveryService: PageDiscoveryService,
    public appSettingsService: AppSettingsService,
    public pointsCashShareService: PointsCashShareService,
    public checkoutValidationService: CheckoutValidationService,
    private voucherService: VoucherService,
    private hotelsUrlBuilderService: HotelsUrlBuilderService,
    private pointsCashPaymentHandlingService: PointsCashPaymentHandlingService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit() {
    this.initRouteData();
    this.initPointsCashShareSubscription();
    this.initCurrencyListener();
    this.buildTrustYou();
    this.pollHotelRooms();
    this.landingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
    this.productType = this.globalData.get(GlobalDataEnum.PRODUCT_TYPE);
    this.hotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    this.pointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
    this.doubleMobileRoomPricesButton = this.appSettingsService.hotelDetailsTemplateConfig.doubleMobileRoomPricesButton;
    this.taxInclusive = this.globalData.get(GlobalDataEnum.TAX_INCLUSIVE);
    this.displayAllRooms = false;
  }

  ngOnDestroy() {
    this.hotelCancellationPolicyService.cancelFetchCancellationPolicyQueue();
  }

  pollHotelRooms() {
    this.pollHotelPricesIsLoading = true;
    const hotelId: string = this.windowRefService.getURIParamFromWindow();
    this.detailsService.getHotelRooms(hotelId).subscribe({
      next: (singlePrice: HotelRoomRawResponse) => this.handleSinglePriceSuccess(singlePrice),
      error: (error: string) => this.handleSinglePriceError(error), // angular-v2-todo: handle this separately
    });
  }

  handleSinglePriceError(error: string): void {
    this.pollErrorCount++;
    if (this.pollErrorCount < 3) {
      this.hotelRoomPollTimeout = setTimeout(this.pollHotelRooms, 5000);
    } else {
      clearTimeout(this.hotelRoomPollTimeout);
    }
  }

  handleSinglePriceSuccess(singlePrice: HotelRoomRawResponse): void {
    let rawRoomsPrice: RoomRawPrice[];
    if (singlePrice && !singlePrice.errors) {
      rawRoomsPrice = singlePrice.rooms;
      this.searchCompleted = singlePrice.completed;
      this.pollErrorCount = 0;
    }

    if (rawRoomsPrice?.length > 0) {
      this.hotel.rooms = new Rooms(
        this.appSettingsService,
        this.globalData,
        this.currencyService,
        this.payWithPointsCashService,
        this.pageDiscoveryService,
        this.voucherService.vouchers,
        rawRoomsPrice,
      );
      this.availRooms = this.hotel.rooms.availRooms;
      this.hotelCancellationPolicyService.initiateFetchCancellationPolicyQueue(this.availRooms);
    }

    if (this.searchCompleted) {
      this.pollHotelPricesIsLoading = false;
      clearTimeout(this.hotelRoomPollTimeout);
      if (singlePrice && this.hasRooms()) {
        this.firstRoom = this.availRooms[0];
        if (!this.firstRoom) { return; }

        // angular-v2-todo: TrackerService must be implemented here
      }
    } else {
      this.pollHotelPricesIsLoading = true;
      this.hotelRoomPollTimeout = setTimeout(() => {
        this.pollHotelRooms();
      }, 5000);
    }

  }

  scrollToElementId(elementId: string): void {
    const element: HTMLElement = this.document.getElementById(elementId);
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  roomPaymentLoaded(): boolean {
    if (this.searchCompleted && (
        this.firstRoom?.priceInfo?.pricePerNight ||
        this.firstRoom?.priceInfo?.cash_payment_per_night ||
        this.firstRoom?.priceInfo?.points_payment_per_night
      )) {
      return true;
    } else {
      if (this.searchCompleted) {
        // we dont need to have searchCompleted to  be true flag
        // before this part as we already have the first room data
        // which is only needed to be shown on top
        this.showNoRoomsMessage();
      }
      return false;
    }
  }

  hasRooms(): boolean {
    return this.availRooms && this.availRooms.length > 0;
  }

  moreRoomsToShow(): boolean {
    return this.availRooms.length >= 5;
  }

  showAllRooms(): void {
    this.displayAllRooms = true;
    // angular-v2-todo: find out whether we need to refresh availRooms like in v1 because now working fine without it
    // angular-v2-todo: fetchPolicyTask.addRooms(this.availRooms);
    return;
  }

  zeroFullCashPaymentFirstRoom(): boolean {
    return this.pointsCashPaymentHandlingService.zeroFullCashPayment(
      this.firstRoom?.priceInfo.cash_payment,
      this.firstRoom?.priceInfo.points_payment,
    );
  }

  zeroFullCashPaymentPerNight(): boolean {
    return this.pointsCashPaymentHandlingService.zeroFullCashPayment(
      this.firstRoom?.priceInfo.cash_payment_per_night,
      this.firstRoom?.priceInfo.points_payment_per_night,
    );
  }

  private showNoRoomsMessage(): void {
    this.noRoomsMessage = true;
  }

  private initRouteData(): void {
    this.activatedRoute.data.subscribe((data: { hotel: Hotel }) => {
      this.hotel = new Hotel(this.appSettingsService, this.globalData, data.hotel);
    });

    this.hotelUtilService.setHotelSearchFormFromQueryParams(
      this.hotelsUrlBuilderService.buildResultsPageDataFromQueryParams(
        this.activatedRoute.snapshot.queryParams,
      ),
    );
  }

  private buildTrustYou(): void {
    this.trustyou = new TrustYou(
      this.appSettingsService,
      this.globalData,
      this.trustYouService,
      this.hotel.trustyou,
      this.hotel.name,
    );
    this.trustYouService.getHotelReview(this.hotel.id).subscribe({
      next: (reviews: TrustYouReviews) => this.trustyou.initTrustYouReviewsSuccess(reviews),
      error: () => this.trustyou.initTrustYouReviewsFailed(),
    });
  }

  private initCurrencyListener(): void {
    this.currencyService.onCurrencyChange
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.rebuildRoomPrice();
      });
  }

  private initPointsCashShareSubscription(): void {
    this.pointsCashShareService.getSliderUpdate()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.rebuildRoomPrice();
      });
  }

  private rebuildRoomPrice(): void {
    this.hotel.rooms.availRooms.forEach((room: Room) => {
      room.priceInfo = new RoomPrice(
        this.appSettingsService,
        this.globalData,
        this.currencyService,
        this.payWithPointsCashService,
        room.roomRawPrice,
      );
    });
  }
}
