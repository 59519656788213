import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Observable, of, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DomService {
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  // This method will remove the oldClass from <body> and add newClass to <body>
  updateBodyClass(oldClass: string, newClass: string): void {
    this.renderer.removeClass(this.document.body, oldClass);
    this.renderer.addClass(this.document.body, newClass);
  }

  // This method will remove the oldClass from <html> and add newClass to <html>
  updateDocumentClass(oldClass: string, newClass: string): void {
    this.renderer.removeClass(this.document.documentElement, oldClass);
    this.renderer.addClass(this.document.documentElement, newClass);
  }

  setDocumentAttribute(name: string, value: string): void {
    this.renderer.setAttribute(this.document.documentElement, name, value);
  }

  addClassToElement(elementSelector: string, klass: string): Observable<HTMLElement> {
    const targetElement: HTMLElement = this.document.querySelector(elementSelector);
    if (!targetElement) {
      return throwError(() => new Error("Element not found"));
    }

    this.renderer.addClass(targetElement, klass);
    return of<HTMLElement>(targetElement);
  }
}
