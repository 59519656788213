import { Component, Input } from "@angular/core";

import { CurrenciesService } from "../../services/initializers/currencies.service";
import { GlobalData } from "../../services/global-data.service";

import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { Currency } from "booking-app-v2/shared/models";

@Component({
  selector: "currency-dropdown",
  template: `
    <ng-select class="currency-switcher"
               *ngIf="useNgSelect"
               [ngModel]="selectedCurrency.code"
               [clearable]="false"
               [searchable]="false"
               (ngModelChange)="changeCurrency($event)"
               [ngClass] ="[hasArrow ? 'ng-select-with-arrow': '' ]">
      <ng-template ng-label-tmp>
        <span class="ng-value-label">
          <div *ngIf="showSelectedFlag" class="dropdown-flag-item flag flag-{{ selectedCurrency.code.toLowerCase() }}"></div>
          <div *ngIf="showSelectedSymbol" class="dropdown-item-symbol" [textContent]="selectedCurrency.symbol"></div>
          <div *ngIf="showSelectedCode" class="dropdown-item-code" [textContent]="selectedCurrency.code.toUpperCase()"></div>
          <div *ngIf="showSelectedText" class="dropdown-item-name" [translate]="'wl.currencies.' + currency.code.toLowerCase()"></div>
        </span>
      </ng-template>
      <ng-option *ngFor="let currency of currencies" [value]="currency.code">
        <div *ngIf="showOptionFlag" class="dropdown-flag-item flag flag-{{ currency.code.toLowerCase() }}"></div>
        <div *ngIf="showOptionSymbol" class="dropdown-item-symbol" [textContent]="currency.symbol"></div>
        <div *ngIf="showOptionCode" class="dropdown-item-code" [textContent]="currency.code"></div>
        <div *ngIf="showOptionText" class="dropdown-item-name" [translate]="'wl.currencies.' + currency.code.toLowerCase()"></div>
      </ng-option>
    </ng-select>

    <ng-container *ngIf="!useNgSelect">
      <div class="nav-label" [translate]="'Currency'"></div>
      <div class="nav-value" [textContent]="formattedCurrency(globalData.get('selectedCurrency'))"></div>
      <div class="chevron-right"></div>
      <select class="hiding"
              [ngModel]="selectedCurrency.code"
              (ngModelChange)="changeCurrency($event)">
          <option *ngFor="let currency of currencies"
                  [textContent]="formattedCurrency(currency)"
                  [value]="currency.code">
          </option>
      </select>
    </ng-container>
  `,
})

export class CurrencyDropdownComponent {
  @Input() useNgSelect: boolean;

  @Input() showSelectedFlag: boolean = false;
  @Input() showSelectedSymbol: boolean = true;
  @Input() showSelectedCode: boolean = true;
  @Input() showSelectedText: boolean = false;

  @Input() showOptionFlag: boolean = false;
  @Input() showOptionSymbol: boolean = true;
  @Input() showOptionCode: boolean = true;
  @Input() showOptionText: boolean = false;

  @Input() hasArrow: boolean = false;

  private currencies: Currency[];

  constructor(
    private globalData: GlobalData,
    private currenciesService: CurrenciesService,
  ) {
    this.currencies = this.globalData.get(GlobalDataEnum.ENABLED_CURRENCIES);
  }

  changeCurrency(newCurrencyCode: string): void {
    this.currenciesService.changeCurrency(newCurrencyCode);
  }

  formattedCurrency(currency: Currency): string {
    return `${currency.symbol} ${currency.text}`;
  }

  get selectedCurrency(): Currency {
    return this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
  }
}
