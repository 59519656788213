import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";

import { PointsPartner, User } from "booking-app-v2/shared/models";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

@Component({
  selector: "insufficient-points-check-dialog",
  templateUrl: "/html/dialogs/insufficient_point_check_dialog_v2",
})
export class InsufficientPointsCheckDialogComponent implements OnInit {
  pointsPartner: PointsPartner;

  private currentUser: User;

  constructor(
    public dialogRef: MatDialogRef<InsufficientPointsCheckDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { minPoint: number },
    private globalData: GlobalData,
  ) {}

  ngOnInit() {
    this.pointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
    this.currentUser = this.globalData.get(GlobalDataEnum.CURRENT_USER);
  }

  close(): void {
    this.dialogRef.close();
  }

  hasEnoughPointsToCoverMinimum(): boolean {
    return this.currentUser.currentPointsBalance > this.data.minPoint;
  }

  adjustMilesCall(): void {
    // angular-v2-todo: migrate this part when starting OCBC/Hyundai
    // this.pointsAdjustmentService.adjustMiles();
    this.close();
    // angular-v2-todo: implement scrollIntoSlider functionality
    // this.scrollIntoSlider();
  }

  adjustMilesSelf(): void {
    // angular-v2-todo: migrate this part when starting OCBC/Hyundai
    this.close();
    // angular-v2-todo: implement scrollIntoSlider functionality
    // this.scrollIntoSlider();
  }
}
