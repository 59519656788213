import { Observable, of, tap } from "rxjs";

import { HotelPricesResponse } from "../types";

export class LocalCacheUtil {

  static cacheContent: Record<string, Cache> = {};

  static cacheCall(
    name: string, key: string, time: number,
    request$: () => Observable<HotelPricesResponse>,
    isReady$: (content) => boolean): Observable<HotelPricesResponse> {
    isReady$ = isReady$ ? isReady$ : (content) => true;
    time = time || 300;

    if (this.isCached(name, key)) {
      return of(this.cacheContent[name].content);
    } else {
      const cache: Cache = this.buildCache(name, key, time);
      return request$().pipe(
        tap((result) => {
          this.fillCache(cache, result, isReady$);
        }),
      );
    }
  }

  private static buildCache(name: string, key: string, time: number): Cache {
    const cache: Cache = {
      content: {
        completed: false,
        hotelRawPrices: [],
      },
      timestamp: Date.now(),
      key,
      ready: false,
      time,
    };

    this.cacheContent[name] = cache;
    return this.cacheContent[name];
  }

  private static isCached(name: string, key: string): boolean {
    const cache: Cache = this.cacheContent[name];

    return cache?.key === key && cache?.ready && (cache.timestamp + cache.time * 1000) > Date.now();
  }

  private static fillCache(cache: Cache, newContent: HotelPricesResponse, isReady: (newContent) => boolean): void {
    if (isReady(newContent)) {
      cache.ready = true;
      cache.content = newContent;
    }
  }

}

interface Cache {
  content: HotelPricesResponse;
  key: string;
  ready: boolean;
  time: number;
  timestamp: number;
}
