import { Component, Input, OnInit } from "@angular/core";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { RoomCancellationPolicy } from "booking-app-v2/hotels/types";
import { LandingPage } from "booking-app-v2/shared/models";
import { Room } from "booking-app-v2/hotels/models";

@Component({
  selector: "grouped-room",
  template: `
    <ng-container *ngFor="let listing of roomListing | keyvalue : returnZero; index as listingIndex">
      <div
        class="max-size"
        *ngIf="listingIndex <= currentLimit">
        <div class="header-container">
          <div class="header-title" [textContent]="listing.value.rooms[0].roomDescriptionShort"></div>
          <div class="discount" *ngIf="listing.value.rooms[0].discount"> {{ listing.value.rooms[0].discount + '% off' }}
          </div>
        </div>
        <div class="grouped-room">
          <div class="image-container">
            <room-image [room]="listing.value.rooms[0]"></room-image>
          </div>
          <div class="room-information-container">
            <ng-container *ngFor="let room of listing.value.rooms; trackBy: trackByRoomKey">
              <div class="rooms" *ngIf="room.visible">
              <grouped-room-list-item [room]="room" [policies]="policies"></grouped-room-list-item>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-content></ng-content>

    <ng-container *ngFor="let listing of roomListing | keyvalue: returnZero; index as listingIndex">
      <div
        class="max-size"
        *ngIf="listingIndex > currentLimit && showAllRooms">
        <div class="header-container">
          <div class="header-title" [textContent]="listing.value.rooms[0].roomDescriptionShort"></div>
          <div class="discount" *ngIf="listing.value.rooms[0].discount"> {{ listing.value.rooms[0].discount + '% off' }}
          </div>
        </div>
        <div class="grouped-room">
          <div class="image-container">
            <room-image [room]="listing.value.rooms[0]"></room-image>
          </div>
          <div class="room-information-container">
            <ng-container *ngFor="let room of listing.value.rooms; trackBy: trackByRoomKey">
              <div class="rooms" *ngIf="room.visible">
              <grouped-room-list-item [room]="room" [policies]="policies"></grouped-room-list-item>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  `,
})
export class GroupedRoomComponent implements OnInit {
  @Input() roomListing: ReadonlyMap<string, { rooms: Room[] }>;
  @Input() showAllRooms: boolean;

  @Input() policies: RoomCancellationPolicy;

  landingPage: LandingPage;
  currentLimit: number;

  constructor(
    public globalData: GlobalData,
  ) { }

  ngOnInit() {
    this.landingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
    this.currentLimit = 1;
  }

  trackByRoomKey(index: number, room: Room) {
    return room.key;
  }

  returnZero(): number {
    return 0;
  }
}
