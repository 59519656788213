import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSimpleDropdownModule } from "@ascenda/ngx-simple-dropdown";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { InputCounterModule } from "booking-app-v2/shared/components/input-counter/input-counter.module";
import {
  PassengersFieldMobileModule,
} from "booking-app-v2/shared/components/passengers-field-mobile/passengers-field-mobile.module";
import { FlightsPassengersDropdownComponent } from "./flights-passengers-dropdown.component";

@NgModule({
  declarations: [FlightsPassengersDropdownComponent],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSimpleDropdownModule,
    InputCounterModule,
    PassengersFieldMobileModule,
  ],
  exports: [FlightsPassengersDropdownComponent],
  providers: [],
})
export class FlightsPassengersDropdownModule { }
