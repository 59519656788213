import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ReplaySubject, Subject, take } from "rxjs";

import { BaseStep } from "./base-step";
import {
  CarFetchTermsResult,
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
} from "booking-app-v2/shared/types";
import { CarUtilService } from "booking-app-v2/cars/services/car-util.service";
import { CarsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/cars-url-builder.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { CarTerms } from "booking-app-v2/cars/types";

@Injectable({
  providedIn: "root",
})
export class CarFetchTermsService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.CAR_FETCH_TERMS;

  constructor(
    private activatedRoute: ActivatedRoute,
    private carUtilService: CarUtilService,
    private carsUrlBuilderService: CarsUrlBuilderService,
    private windowRefService: WindowRefService,
  ) {}

  process(results: CheckoutResults): CarFetchTermsResult {
    const params = this.carsUrlBuilderService.buildResultsPageDataFromQueryParams(
      this.activatedRoute.snapshot.queryParams,
    );
    const bookingKey = this.windowRefService.getURIParamFromWindow();
    const result: Subject<CarTerms> = new ReplaySubject<CarTerms>(1);

    this.carUtilService.fetchCarsTerms(params, bookingKey)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          result.next(res);
        },
        error: (err) => {
          result.next({} as CarTerms);
        },
      });

    return result.asObservable();
  }
}
