import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { LandingPage, PointsPartner } from "booking-app-v2/shared/models";
import { GlobalDataEnum, PAYMENT, Payment, ProductType } from "booking-app-v2/shared/types";
import { Room } from "booking-app-v2/hotels/models";
import { Car } from "booking-app-v2/cars/models";

@Injectable({
  providedIn: "root",
})
export class PaymentFormUtilService {
  readonly externalPaymentChannels: string[];
  readonly chinesePaymentChannels: string[];
  readonly landingPage: LandingPage;
  readonly productType: ProductType;
  readonly pointsPartner: PointsPartner;

  onlyShowVisaLogo: boolean;
  displayMastercard: boolean;
  acceptDinersViaAdyen: boolean;
  displayDiners: boolean;
  displayDiscover: boolean;

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
  ) {
    this.externalPaymentChannels = [
      PAYMENT.ADYEN_UNIONPAY,
      PAYMENT.ADYEN_DINERS,
      PAYMENT.ADYEN_JCB,
      PAYMENT.ADYEN_ALIPAY,
      PAYMENT.PAY_ANYONE,
    ];

    this.chinesePaymentChannels = [
      PAYMENT.ADYEN_ALIPAY,
      PAYMENT.ADYEN_UNIONPAY,
    ];

    this.landingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
    this.productType = this.globalData.get(GlobalDataEnum.PRODUCT_TYPE);
    this.pointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);

    this.onlyShowVisaLogo = this.appSettingsService.onlyShowVisaCardLogo;
    this.displayMastercard = this.pointsPartner.name !== "DBS Bank Singapore";
    this.acceptDinersViaAdyen = this.appSettingsService.displayDinersOptions;
    this.displayDiners = this.appSettingsService.displayDinersLogo || this.acceptDinersViaAdyen;
    this.displayDiscover = this.appSettingsService.displayDiscoverLogo;
  }

  acceptAlipay(): boolean {
    return this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).code === "CNY" && this.nonCreditCardPartner();
  }

  acceptJcb(): boolean {
    return this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).code === "JPY" && this.nonCreditCardPartner();
  }

  displayJcb(): boolean {
    return this.appSettingsService.displayJcbLogo || this.acceptJcb();
  }

  acceptUnionpay(): boolean {
    return this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).code === "CNY" && this.nonCreditCardPartner();
  }

  displayUnionpay(): boolean {
    return this.acceptUnionpay();
  }

  acceptNoCCPayment(): boolean {
    return (
      this.acceptAlipay() ||
      this.acceptUnionpay() ||
      this.acceptDinersViaAdyen ||
      this.acceptJcb()
    ) && !this.appSettingsService.onlyShowVisaCardLogo;
  }

  displayAlipayInternational(): boolean {
    return this.acceptAlipay() && !this.chineseUser();
  }

  displayAlipayChina(): boolean {
    return this.acceptAlipay() && this.chineseUser();
  }

  isExternalPaymentProvider(checkoutForm: FormGroup): boolean {
    return this.externalPaymentChannels.indexOf(checkoutForm?.controls?.paymentChannel?.value) > -1;
  }

  isCCPayment(checkoutForm: FormGroup): boolean {
    return !this.isExternalPaymentProvider(checkoutForm);
  }

  showPaymentForm(travelItem: Room | Car): boolean {
    return this.landingPage.showCheckoutPaymentForm() || travelItem?.priceInfo?.cash_payment > 0;
  }

  isAdyenJcbPayment(paymentChannel: Payment): boolean {
    return paymentChannel === PAYMENT.ADYEN_JCB && this.acceptJcb();
  }

  isAdyenDinersPayment(paymentChannel: Payment): boolean {
    return paymentChannel === PAYMENT.ADYEN_DINERS && this.acceptDinersViaAdyen;
  }

  isAdyenUnionPayPayment(paymentChannel: Payment): boolean {
    return paymentChannel === PAYMENT.ADYEN_UNIONPAY && this.acceptUnionpay();
  }

  isPaymentAdyenAlipay(paymentChannel: Payment): boolean {
    return paymentChannel === PAYMENT.ADYEN_ALIPAY && this.acceptAlipay();
  }

  isAdyenCardPayment(checkoutForm: FormGroup): boolean {
    const paymentChannel = checkoutForm?.controls?.paymentChannel?.value;
    return this.isAdyenUnionPayPayment(paymentChannel) ||
      this.isAdyenJcbPayment(paymentChannel) ||
      this.isAdyenDinersPayment(paymentChannel);
  }

  private nonCreditCardPartner(): boolean {
    return this.pointsPartner?.category !== PAYMENT.CREDIT_CARD;
  }

  private chineseUser(): boolean {
    return this.showForLocales(["zh-CN", "zh-HK", "zh-TW", "tw"]);
  }

  private showForLocales(locales: string[]): boolean {
    const selectedLocale = this.globalData.get(GlobalDataEnum.SELECTED_LOCALE)?.langCode;
    return selectedLocale && locales.includes(selectedLocale);
  }
}
