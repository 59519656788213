import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";

import { GlobalDataEnum, PRODUCT_TYPE, ProductType, RawLandingPage, SortType, VoucherType } from "booking-app-v2/shared/types";
import { Base, Locale } from "booking-app-v2/shared/models/index";

export class LandingPage extends Base {
  readonly change_partner_label: string;
  readonly isEarnOnly: boolean;
  readonly useVoucherOnly: boolean;
  readonly isCashVoucher: boolean;
  readonly isPayWithPoints: boolean;
  readonly isComplimentaryNights: boolean;
  readonly points_partner_id: number;
  readonly infographic_url: string;
  readonly background_image_url: string;
  readonly voucher_types: VoucherType[];
  readonly canChangePartner: boolean;
  readonly urlLink: string;
  readonly quickSearchInNewTab: boolean;
  readonly minBookingDays: number;
  readonly welcomeBoxHeader: string;
  readonly welcomeBoxSubHeader: string;
  readonly showPartners: boolean;
  readonly voucherTypes: {};
  readonly sortingOptions: SortType[];
  url: string;
  id: number;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    rawLandingPage: RawLandingPage,
  ) {
    super(appSettingsService, globalData);
    this.sortingOptions = rawLandingPage.sorting_options;
    this.isEarnOnly = rawLandingPage.can_earn_miles;
    this.useVoucherOnly = rawLandingPage.accepts_free_night_voucher;
    this.isCashVoucher = rawLandingPage.accepts_cash_voucher;
    this.isPayWithPoints = rawLandingPage.can_pay_with_points;
    this.isComplimentaryNights = rawLandingPage.has_complimentary_nights;
    this.points_partner_id = rawLandingPage.points_partner_id;
    this.url = rawLandingPage.url;
    this.change_partner_label = rawLandingPage.change_partner_label;
    this.background_image_url = rawLandingPage.background_image_url;
    this.voucher_types = rawLandingPage.voucher_types;
    this.urlLink = `/${rawLandingPage.url}`;
    this.canChangePartner = rawLandingPage.can_change_partner;

    this.quickSearchInNewTab = this.isQuickSearchInNewTab();
    this.minBookingDays = this.getMinBookingDays();
    this.welcomeBoxHeader = this.getWelcomeBoxHeader();
    this.welcomeBoxSubHeader = this.getWelcomeBoxSubHeader();
    this.showPartners = this.isShowPartners();
    this.voucherTypes = this.createVoucherTypes();
  }

  hasProductType(type: ProductType): boolean {
    if (this.appSettingsService.useProductType) {
      return type === this.globalData.get(GlobalDataEnum.PRODUCT_TYPE);
    } else {
      switch (type) {
        case PRODUCT_TYPE.EARN:
          return this.isEarnOnly;
        case PRODUCT_TYPE.REDEEM:
          return this.isPayWithPoints;
        case PRODUCT_TYPE.VOUCHER:
          return this.useVoucherOnly;
        case PRODUCT_TYPE.CASHVOUCHER:
          return this.isCashVoucher;
        case PRODUCT_TYPE.COMPLIMENTARY_NIGHTS:
          return this.isComplimentaryNights;
      }
    }
  }

  allowsVoucher(): boolean {
    return (this.hasProductType(PRODUCT_TYPE.CASHVOUCHER) &&
      this.points_partner_id === this.globalData.get(GlobalDataEnum.POINTS_PARTNER).id)
      || this.hasProductType(PRODUCT_TYPE.VOUCHER);
  }

  setUrl(whiteLabel: string, type: string): void {
    switch (type) {
      case PRODUCT_TYPE.VOUCHER:
        type = "freenight";
        break;
      case PRODUCT_TYPE.CASHVOUCHER:
        type = "cashvoucherearn";
        break;
    }
    this.url = `wl-${whiteLabel.toLowerCase()}-${type}`;
  }

  earnMiles(): boolean {
    return !(this.hasProductType(PRODUCT_TYPE.VOUCHER)
      || this.complimentaryOrRedeem());
  }

  complimentaryOrRedeem(): boolean {
    return this.hasProductType(PRODUCT_TYPE.REDEEM)
      || this.hasProductType(PRODUCT_TYPE.COMPLIMENTARY_NIGHTS);
  }

  changePartnerLabel(): string {
    let label: string;
    if (this.change_partner_label) {
      label = this.change_partner_label;
    } else {
      if (this.hasProductType(PRODUCT_TYPE.VOUCHER)) {
        label = "Redeem Voucher";
      } else if (this.hasProductType(PRODUCT_TYPE.REDEEM)) {
        label = "Redeem Points";
      } else {
        label = "Earn miles or points with";
      }
    }
    return label;
  }

  infographicUrl(): string {
    const selectedLocale: Locale = this.globalData.get(GlobalDataEnum.SELECTED_LOCALE);
    if (!selectedLocale) {
      return this.infographic_url;
    } else if (selectedLocale.langCode === "en") {
      return this.infographic_url;
    } else {
      const url: string = this.infographic_url;
      const fileType: string = url.substr(url.length - 4);
      const urlWithoutFileType: string = url.substring(0, url.length - 4);
      return `${urlWithoutFileType}.${selectedLocale}${fileType}`;
    }
  }

  backgroundImgStyle(): any {
    const image: { "background-image": string } = {
      "background-image": `url(${this.background_image_url})`,
    };

    return image;
  }

  redeemHotel(): boolean {
    return this.hasProductType(PRODUCT_TYPE.REDEEM)
      || this.hasProductType(PRODUCT_TYPE.VOUCHER);
  }

  showResultHeader(): boolean {
    return this.earnMiles();
  }

  showCheckoutPaymentForm(): boolean {
    return this.earnMiles();
  }

  showCheckoutSummaryInfo(): boolean {
    return this.earnMiles();
  }

  showCheckoutFooter(): boolean {
    return this.earnMiles();
  }

  private isQuickSearchInNewTab(): boolean {
    return this.url === "deals" || this.url === "special-feature";
  }

  private getMinBookingDays(): number {
    if (this.hasProductType(PRODUCT_TYPE.CASHVOUCHER) &&
      this.points_partner_id === this.globalData.get(GlobalDataEnum.POINTS_PARTNER)?.id) {
      /**
       * Cash voucher earn DLPs with min 1 day because
       * either voucher is partner funded, or voucher amount is small
       * Not sure we still need this below condition. Added just in case from old logic
       */
      if (this.url === "krisflyeramex-promo" || this.url === "uob-gift") {
        return 1;
      }
      return 2;
    } else {
      return 1;
    }
  }

  private getWelcomeBoxHeader(): string {
    let textToShow: string;
    if (this.url === "refer") {
      textToShow = "Sign up now and get <span class='red'>" +
        "<strong>1,000</strong> bonus miles or points</span> on your first booking";
    } else {
      textToShow = "Get access to <span class='red'>exclusive bonus offers!</span>";
    }

    return textToShow;
  }

  private getWelcomeBoxSubHeader(): string {
    let textToShow: string;
    if (this.url === "refer") {
      textToShow = "";
    } else {
      textToShow = "Sign up now for the best deals and promotions";
    }
    return textToShow;
  }

  private isShowPartners(): boolean {
    return !this.hasProductType(PRODUCT_TYPE.VOUCHER);
  }

  private createVoucherTypes(): {} {
    const voucherTypes = {};
    if (this.voucher_types) {
      this.voucher_types.forEach((voucher) => {
        voucherTypes[voucher.id] = voucher.name;
      });
    }
    return voucherTypes;
  }

}
