import { CookiesService } from "booking-app-v2/shared/services/initializers/cookies.service";
import { CancellationPolicyClause, Locale } from "booking-app-v2/shared/models";
import { TranslateService } from "@ngx-translate/core";
import { FormatNumberUtil } from "booking-app-v2/shared/utils";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

import { Component, Input, OnInit } from "@angular/core";

interface TranslateValues {
  amount: string;
  start_date: string;
  end_date: string;
}

@Component({
  selector: "cancellation-policy",
  template: `
    <div *ngIf="translateValues">
      <div [innerHTML]="internalClauseText()"></div>
    </div>`,
})
export class CancellationPolicyComponent implements OnInit {
  @Input() static: boolean;
  @Input() responsive: boolean;
  @Input() profile: boolean;
  @Input() displayPercentage: boolean;
  @Input() purchase: boolean;
  @Input() amount: number;
  @Input() convertRate: number;
  @Input() leftToPay: number;
  @Input() clause: CancellationPolicyClause;
  @Input() currency: string;

  // internal variables
  private internalClause: CancellationPolicyClause;
  private translateValues: TranslateValues;
  private locale: Locale;

  constructor(
    private appSettingsService: AppSettingsService,
    private translateService: TranslateService,
    private cookieService: CookiesService,
    private globalData: GlobalData,
  ) {
    this.locale = this.globalData.get(GlobalDataEnum.SELECTED_LOCALE);
  }

  ngOnInit() {
    this.internalClause = new CancellationPolicyClause(this.appSettingsService, this.globalData, this.clause);
    this.updateValues();
  }

  internalClauseText(): string {
    this.updateValues();
    return this.translateService.instant(this.internalClause.text, this.translateValues);
  }

  private updateValues(): void {
    if (this.displayPercentage) {
      this.updateClauseForDisplayPercentage();
    } else if (this.purchase) {
      this.updateClauseForPurchase();
    }

    if (this.responsive && this.leftToPay && this.currency) {
      this.updateTranslateValuesForResponsive();
    } else if (this.static) {
      this.updateTranslateValuesForStatic();
    }
  }

  private updateClauseForDisplayPercentage(): void {
    this.internalClause.amount = `${ this.clause.percentage.toFixed(2) }%`;
  }

  private updateClauseForPurchase(): void {
    const num: number = this.amount * this.clause.percentage / 100;
    const formattedNum: string = FormatNumberUtil.roundNumber(num, this.locale, 2);

    this.internalClause.amount = `${this.currency} ${formattedNum}`;
  }

  private updateTranslateValuesForResponsive() {
    const amount: number = (this.internalClause.percentage / 100.0 * this.leftToPay) * this.convertRate;
    const formattedAmount: string = FormatNumberUtil.roundNumber(amount, this.locale, 2);
    this.translateValues = {
      amount: `<strong>${this.currency}${formattedAmount}</strong>`,
      start_date: `<strong>${this.internalClause.formatFromDateInFormat("lll")}</strong>`,
      end_date: `<strong>${this.internalClause.formatToDateInFormat("lll")}</strong>`,
    };
  }

  private updateTranslateValuesForStatic() {
    this.translateValues = {
      amount: `<strong>${this.internalClause.amount}</strong>`,
      start_date: `<strong>${this.internalClause.formatFromDateInFormat("lll")}</strong>`,
      end_date: `<strong>${this.internalClause.formatToDateInFormat("lll")}</strong>`,
    };
  }
}
