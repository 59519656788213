import { NgModule } from "@angular/core";
import { NgxSliderModule } from "@ascenda/ngx-slider";
import { SharedModule } from "booking-app-v2/shared/shared.module";
import { ReviewsScoreSliderComponent } from "./reviews-score-slider.component";

@NgModule({
  declarations: [ ReviewsScoreSliderComponent ],
  imports: [
    SharedModule,
    NgxSliderModule,
  ],
  exports: [ ReviewsScoreSliderComponent ],
  providers: [],
})
export class ReviewsScoreSliderModule {}
