import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";

import { ApiDataService } from "./api-data.service";
import { AppSettingsService } from "./app-settings.service";
import { GlobalData } from "./global-data.service";
import { CarDestination } from "booking-app-v2/cars/models";
import {
  CarPickupAndReturnDestination,
  RawCarDestination,
  RawCarSingleDestination,
} from "booking-app-v2/cars/types";
import { RawFlightDestination } from "booking-app-v2/flights/types";
import { FlightDestination } from "booking-app-v2/flights/models";
import { RawDestination } from "booking-app-v2/shared/types";
import { HotelDestination } from "booking-app-v2/hotels/models";
import { HotelGulfDestination, RawHotelDestination } from "booking-app-v2/hotels/types";

@Injectable({
  providedIn: "root",
})
export class DestinationService {

  constructor(
    private apiDataService: ApiDataService,
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
  ) { }

  getHotelDestinations(term: string): Observable<HotelDestination[]> {
    return this.getDestinations(term)
      .pipe(
        map((rawHotelDestinations: RawHotelDestination[]) => {
          return rawHotelDestinations.map((rawHotelDestination: RawHotelDestination) => {
            return new HotelDestination(this.appSettingsService, this.globalData, rawHotelDestination);
          });
        }),
      );
  }

  getHotelDestination(destinationId: string): Observable<HotelGulfDestination> {
    return this.apiDataService.get(`destinations/${destinationId}`)
      .pipe(map((hotelGulfDestinationResponse: HotelGulfDestination) => {
        return hotelGulfDestinationResponse;
      }));
  }

  getCarDestinations(term: string): Observable<CarDestination[]> {
    return this.getDestinations(term, "cars/locations?")
      .pipe(
        map((rawCarDestinations: RawCarDestination[]) => {
          return rawCarDestinations.map((rawCarDestination: RawCarDestination) => {
            return new CarDestination(this.appSettingsService, this.globalData, rawCarDestination);
          });
        }),
      );
  }

  getCarPickupAndReturnDestination(
    pickupId: string,
    returnId: string,
    locationType: string,
  ): Observable<CarPickupAndReturnDestination> {
    return this.apiDataService.get(`cars/locations/${pickupId}/${returnId}`)
      .pipe(
        map((rawCarSingleDestination: RawCarSingleDestination) => {
          const pickupRawDestination: RawCarDestination = {
            value: pickupId,
            term: rawCarSingleDestination[pickupId],
            type: locationType,
            coordinates: rawCarSingleDestination.coordinates[pickupId],
          };
          const returnRawDestination: RawCarDestination = {
            value: returnId,
            term: rawCarSingleDestination[returnId],
            type: locationType,
            coordinates: rawCarSingleDestination.coordinates[returnId],
          };

          return {
            pickupDestination: new CarDestination(this.appSettingsService, this.globalData, pickupRawDestination),
            returnDestination: new CarDestination(this.appSettingsService, this.globalData, returnRawDestination),
          };
        }),
      );
  }

  getFlightDestinations(term: string): Observable<FlightDestination[]> {
    return this.getDestinations(term, "airports?").pipe(map((rawFlightDestinations: RawFlightDestination[]) => {
      return rawFlightDestinations.map((rawFlightDestination: RawFlightDestination) => {
        return new FlightDestination(this.appSettingsService, this.globalData, rawFlightDestination);
      });
    }));
  }

  private getDestinations(term: string, url: string = "destinations?"): Observable<RawDestination[]> {
    return this.apiDataService.get(`${url}name=${term}`);
  }
}
