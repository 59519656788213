import { Injectable } from "@angular/core";
import { delayWhen, finalize, map, Observable, retryWhen, Subscription, timer } from "rxjs";

import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { TimeUtils } from "booking-app-v2/shared/utils";

import { CancellationPolicyResponse, GlobalDataEnum } from "booking-app-v2/shared/types";
import { CancellationPolicy } from "booking-app-v2/shared/models";
import { Room } from "booking-app-v2/hotels/models";
import { RoomCancellationPolicy } from "../types";

@Injectable({
  providedIn: "root",
})
export class HotelCancellationPolicyService {
  policies: RoomCancellationPolicy = {};
  roomsWithCancellationPolicy: Room[];

  private readonly cancellantionPolicyQueueLimit = 2;
  private cancelQueue: boolean = false;

  constructor(
    private apiDataService: ApiDataService,
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private windowRefService: WindowRefService,
  ) { }

  fetch(bookingKey: string): Observable<CancellationPolicy> {
    return this.apiDataService.get(
      `hotels/${this.getHotelId()}/cancellation_policy/${bookingKey}`,
      { checkin: this.getCheckInDate() },
    ).pipe(
      map((response: CancellationPolicyResponse) => {
        if (!response.completed) {
          throw response;
        }
        if (response.policy === null) {
          return null;
        }
        return new CancellationPolicy(this.appSettingsService, this.globalData, response.policy);
      }),
      retryWhen(errors => {
        let retries: number = 0;
        return errors.pipe(
          map(err => {
            if (retries++ >= 10) {
              throw { invalid: true };
            }
            return err;
          }),
          delayWhen(() => timer(3000)),
        );
      }),
    );
  }

  initiateFetchCancellationPolicyQueue(availRooms: Room[]): void {
    this.cancelQueue = false;
    this.roomsWithCancellationPolicy = availRooms.filter(room => room.key && room.visible);
    this.queueCancellationPolicyRequests();
  }

  queueCancellationPolicyRequests(incomingQueueIndex = 1): void {
    /**
     * We create a new array of room objects called roomsWithCancellationPolicy.
     * We loop through this array, take 2 rooms at a time and call
     * getCancellationPolicyForRooms on each of these rooms.
    */
    this.roomsWithCancellationPolicy = [...this.roomsWithCancellationPolicy];
    let currentQueueIndex: number = incomingQueueIndex;
    /**
     * Below loop acts as a queue implementation to fetch CP.
     * We fetch CP for 2 rooms at a time.
    */
    for (const roomWithCancellationPolicy of this.roomsWithCancellationPolicy) {
      if (currentQueueIndex > this.cancellantionPolicyQueueLimit || this.cancelQueue) {
        break;
      }
      /**
       * Before we call getCancellationPolicyForRooms on the room, we remove it from
       * the array, so that we don't call the CP more than 1 time for the same room
      */
      this.roomsWithCancellationPolicy =  this.roomsWithCancellationPolicy.filter(
        room => room.key !== roomWithCancellationPolicy.key,
      );
      this.getCancellationPolicyForRooms(roomWithCancellationPolicy.key);
      currentQueueIndex++;
    }
  }

  cancelFetchCancellationPolicyQueue(): void {
    this.cancelQueue = true;
  }

  private getCancellationPolicyForRooms(bookingKey: string): void {
    const newQueueIndex: number = 2;
    const fetchHotelPolicyCall: Subscription = this.fetch(bookingKey)
      .pipe(
        finalize(() => fetchHotelPolicyCall.unsubscribe()),
      )
      .subscribe({
        /**
         *
         * If fetchHotelPolicyCall returns policy or if it errors out
         * we recursively call queueCancellationPolicyRequests
         * with queueIndex = 2. Because now we can add 1 more room to queue
        */
        next: (policy: CancellationPolicy) => {
          this.policies[bookingKey] = policy;
          this.queueCancellationPolicyRequests(newQueueIndex);
        },
        error: () => {
          this.policies[bookingKey] = null;
          this.queueCancellationPolicyRequests(newQueueIndex);
        },
      });
  }

  private getHotelId(): string {
    return this.windowRefService.getURIParamFromWindow();
  }

  private getCheckInDate(): string {
    return TimeUtils.format(
      this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM).checkInDate,
      "YYYY-MM-DD",
      "MM/DD/YYYY",
    );
  }
}
