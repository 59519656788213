import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { AdyenFormComponent } from "./adyen-form.component";

@NgModule({
  declarations: [ AdyenFormComponent ],
  imports: [
    SharedModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [ AdyenFormComponent ],
  providers: [],
})
export class AdyenFormModule {}
