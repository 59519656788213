import { Component, Input } from "@angular/core";

import { Hotel } from "booking-app-v2/hotels/models";

@Component({
  selector: "hotel-list-item-image",
  template: `
    <div class="hotel-image-container" [ngStyle]="hotel.defaultHotelBgImageStyle">
      <div class="hotel-image" [ngStyle]="hotel.defaultThumbImageStyle"></div>
    </div>
  `,
})
export class HotelListItemImageComponent {
  @Input() hotel: Hotel;

}
