import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Options } from "@ascenda/ngx-slider";
import { TranslateService } from "@ngx-translate/core";

import { GlobalDataEnum, RedeemPoints } from "booking-app-v2/shared/types";
import { LocaleNumberFormat } from "booking-app-v2/shared/pipes";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { LocaleService } from "booking-app-v2/shared/services/initializers/locale.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "redeem-points-slider",
  template: `
  <ascenda-ngx-slider
    (minValueChange)= "updateSliderMinValue($event)"
    (valueChange)= "updateSliderMaxValue($event)"
    [options]="sliderOptions"
  >
  </ascenda-ngx-slider>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class RedeemPointsSliderComponent implements OnInit, OnChanges {
  sliderOptions: Options;

  @Input() sliderValues: RedeemPoints;
  @Output() onSliderUpdate: EventEmitter<RedeemPoints> = new EventEmitter<RedeemPoints>();

  private shortCurrency: string = this.globalData.get(GlobalDataEnum.POINTS_PARTNER).currency_short;

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private globalData: GlobalData,
    private translateService: TranslateService,
    private localeNumberFormat: LocaleNumberFormat,
    private localeService: LocaleService,
  ) {
  }

  ngOnInit() {
    this.listenLangChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    const sliderValuesObj: RedeemPoints =
      changes.sliderValues.currentValue ?
        changes.sliderValues.currentValue : changes.sliderValues.previousValue;

    this.initSlider({
      min: sliderValuesObj.min,
      max: sliderValuesObj.max,
      ceil: sliderValuesObj.ceil,
      floor: sliderValuesObj.floor,
    });
  }

  updateSliderMinValue(minValue: number): void {
    this.sliderOptions.minValue = minValue;
    this.updateRedeemPoints();
  }

  updateSliderMaxValue(maxValue: number): void {
    this.sliderOptions.value = maxValue;
    this.updateRedeemPoints();
  }

  isRtl(): boolean {
    return this.globalData.get(GlobalDataEnum.DISPLAY_ALIGNMENT) === "rtl";
  }

  private listenLangChange(): void {
    this.localeService.onLocaleChange
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.initSlider({
          min: this.sliderOptions.minValue,
          max: this.sliderOptions.value,
          ceil: this.sliderOptions.ceil,
          floor: this.sliderOptions.floor,
        });
      });
  }

  private updateRedeemPoints(): void {
    const redeemPoints: RedeemPoints = {
      min: this.sliderOptions.minValue,
      max: this.sliderOptions.value,
      ceil: this.sliderOptions.ceil,
      floor: this.sliderOptions.floor,
    };

    this.onSliderUpdate.emit(redeemPoints);
  }

  private initSlider(config?: RedeemPoints): void {
    this.sliderOptions = {
      minValue: config.min,
      floor: config.floor,
      ceil: config.ceil,
      value: config.max,
      step: 1,
      isRtl: this.isRtl(),
      minValueLabel: (minValue) => {
        return `
          ${this.localeNumberFormat.transform(minValue)}
          ${this.translateService.instant(this.shortCurrency)}
        `;
      },
      valueLabel: (value) => {
        return `
          ${this.localeNumberFormat.transform(value)}
          ${this.translateService.instant(this.shortCurrency)}
        `;
      },
    };
    this.changeDetectionRef.markForCheck();
  }

}
