import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { AppSettingsService } from "./../app-settings.service";
import { BootstrapDataService } from "./../bootstrap-data.service";
import { GlobalData } from "./../global-data.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { Currency } from "booking-app-v2/shared/models";
import { BootstrapData, GlobalDataEnum, RawCurrency } from "booking-app-v2/shared/types";
import { CookiesService } from "./cookies.service";
import { LocaleNumberFormat } from "booking-app-v2/shared/pipes/locale-number-format.pipe";

interface LandingPageCurrencies {
  [key: string]: string;
}

@Injectable({
  providedIn: "root",
})

export class CurrenciesService {
  onCurrencyChange: Subject<Currency> = new Subject<Currency>();

  private readonly defaultCurrency = "USD";

  private _currencies: Currency[];
  private _selectedCurrency: Currency;
  private _bootstrapData: BootstrapData;
  private _bootstrapCurrencies: RawCurrency[];

  private landingPageCurrencies: LandingPageCurrencies = {
    "amex-sme-uk": "GBP",
  };

  constructor(
    private appSettingsService: AppSettingsService,
    private bootstrapService: BootstrapDataService,
    private globalData: GlobalData,
    private cookiesService: CookiesService,
    private windowRefService: WindowRefService,
    private localeNumberFormat: LocaleNumberFormat,
  ) { }

  init(): void {
    this._bootstrapData = this.bootstrapService.bootstrapData;
    this._bootstrapCurrencies = this._bootstrapData.currencies;
    this._currencies = this.buildCurrencies(this._bootstrapCurrencies);

    this.initSelectedCurrency();
    this.setupCurrencyCookie();
    this.setupCurrencies();
  }

  default(): Currency {
    return this.findByCode(this.defaultCurrency);
  }

  findByCode(currencyCode: string): Currency {
    return this._currencies.find(currency => currency.code === currencyCode);
  }

  convertFromUsd(amount: number): number {
    return amount * this._selectedCurrency.rate;
  }

  changeCurrency(currencyCode: string): void {
    const selectedCurrency: Currency = this.findByCode(currencyCode);
    if (selectedCurrency?.code === this._selectedCurrency.code) {
      return;
    }

    this._selectedCurrency = selectedCurrency;
    this.globalData.set(GlobalDataEnum.SELECTED_CURRENCY, selectedCurrency);
    this.globalData.set(GlobalDataEnum.CONVERT_RATE, selectedCurrency.rate);
    this.cookiesService.setCurrency(selectedCurrency);
    this.onCurrencyChange.next(selectedCurrency);
  }

  adjustDecimals(amount: number, decimalPlace: number): string {
    const re = RegExp(`^-?\\d+(?:\.\\d{0,${decimalPlace || -1}})?`);
    return amount.toString().match(re)[0];
  }

  adjustZeroDecimal(amount: number, decimalPlace: number): number {
    if (decimalPlace === 0) {
      return Math.ceil(amount);
    }

    return amount;
  }

  formatCurrencyValue(value: number): string {
    const selectedCurrency: Currency = this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
    if (selectedCurrency.decimalPlace === 0) {
      return this.localeNumberFormat.transform(Math.ceil(value), 0);
    }
    return this.localeNumberFormat.transform(value, 2);
  }

  private initSelectedCurrency(): void {
    this._selectedCurrency =
      this.findByCode(this.landingPageCurrencies[this.globalData.get(GlobalDataEnum.LANDING_PAGE).url]) ||
      this.findByCode(this.windowRefService.getQueryParamFromWindow("currency")) ||
      this.findByCode(this.cookiesService.getCurrency()) ||
      this.findByCode(this._bootstrapData.default_currency) ||
      this.default();
  }

  private setupCurrencyCookie(): void {
    this.cookiesService.setCurrency(this._selectedCurrency);
  }

  private setupCurrencies(): void {
    this.globalData.set(GlobalDataEnum.SELECTED_CURRENCY, this._selectedCurrency);
    this.globalData.set(GlobalDataEnum.CONVERT_RATE, this._selectedCurrency.rate);
    this.globalData.set(GlobalDataEnum.ENABLED_CURRENCIES, this._currencies);
  }

  private buildCurrencies(currencies): Currency[] {
    return currencies.map(currency => {
      return new Currency(this.appSettingsService, this.globalData, currency);
    });
  }
}
