import { Component } from "@angular/core";
import { GlobalData } from "../../services/global-data.service";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";

@Component({
  selector: "shared-footer",
  templateUrl: "/html/components/footer_v2",
})
export class SharedFooterComponent {

  constructor(
    private globalData: GlobalData,
    private pageDiscoveryService: PageDiscoveryService,
  ) { }
}
