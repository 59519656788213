import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Room } from "booking-app-v2/hotels/models";
import { Currency, PointsPartner } from "booking-app-v2/shared/models";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { GlobalDataEnum, PRODUCT_TYPE } from "booking-app-v2/shared/types";

@Component({
  selector: "mobile-room-prices-button",
  templateUrl: "/html/components/mobile_room_prices_button_v2",
})
export class MobileRoomPricesButtonComponent {
  @Input() productType: "earn" | "redeem";
  @Input() searchCompleted: boolean;
  @Input() rooms: Room[];
  @Input() loyaltyPointLabel: string;

  @Output() scrollToRoomOptions: EventEmitter<void> = new EventEmitter();

  constructor(
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private currenciesService: CurrenciesService,
  ) {
  }

  hasRoomInfo(): boolean {
    return this.rooms && this.rooms.length > 0;
  }

  firstRoom(): Room {
    if (this.hasRoomInfo()) {
      return this.rooms[0];
    }
  }

  isEarn(): boolean {
    return this.productType === "earn";
  }

  isRedeem(): boolean {
    return this.productType === "redeem";
  }

  get pointsPartner(): PointsPartner {
    return this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
  }

  get selectedCurrency(): Currency {
    return this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
  }

  showTotalNights(): boolean {
    return this.appSettingsService.showTotalNights;
  }

  hasComplimentaryNights(): boolean {
    return this.globalData.get(GlobalDataEnum.LANDING_PAGE).hasProductType(PRODUCT_TYPE.COMPLIMENTARY_NIGHTS);
  }

  roomEarnRate() {
    return this.rooms[0].priceInfo.points + this.rooms[0].priceInfo.bonus;
  }

  loyaltyLabel(): string {
    return this.loyaltyPointLabel ? this.loyaltyPointLabel : "Miles";
  }

  convertedOriginalPricePerRoomPerNightWithTax(): number {
    return this.currenciesService.convertFromUsd(this.firstRoom().priceInfo.original_price_per_room_per_night);
  }
}
