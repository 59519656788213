import { Routes } from "@angular/router";
import { ConfirmationPageComponent } from "../confirmation-page.component";
import { HotelBookingResolver } from "./hotel-booking.resolver";

export const confirmationPageRoutes: Routes = [
  {
    path: ":id",
    component: ConfirmationPageComponent,
    resolve: {
      booking: HotelBookingResolver,
    },
  },
];
