import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const passwordMismatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const newPasswordValue: string = control.get("newPassword").value;
  const confirmNewPasswordValue: string = control.get("confirmNewPassword").value;
  if (newPasswordValue !== confirmNewPasswordValue && control.get("confirmNewPassword").valid) {
    control.get("confirmNewPassword").setErrors({ mismatch: true });
  }
  if (newPasswordValue === confirmNewPasswordValue && control.get("confirmNewPassword").invalid) {
    control.get("confirmNewPassword").setErrors(null);
  }
  return null;
};
