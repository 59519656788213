import { Routes } from "@angular/router";
import { DetailsPageComponent } from "../details-page.component";
import { HotelDetailsResolver } from "./details-page.resolver";

export const detailsPageRoutes: Routes = [
  {
    path: ":id",
    component: DetailsPageComponent,
    resolve: {
      hotel: HotelDetailsResolver,
    },
  },
];
