import { Injectable } from "@angular/core";

import { BaseStep } from "./base-step";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  GlobalDataEnum,
  StartLoadingResult,
} from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class StartLoadingService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.START_LOADING;

  constructor(
    private globalData: GlobalData,
  ) {}

  process(results: CheckoutResults): StartLoadingResult {
    this.globalData.set(GlobalDataEnum.IS_LOADING, true);
  }

}
