import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Event, NavigationEnd, NavigationStart, Router } from "@angular/router";

import { CookiesService } from "./initializers/cookies.service";
import { LocaleService } from "./initializers/locale.service";
import { CurrenciesService } from "./initializers/currencies.service";
import { PointsPartnerService } from "./initializers/points-partner.service";
import { LandingPageService } from "./initializers/landing-page.service";
import { TravelTypeService } from "./initializers/travel-type.service";
import { GlobalData } from "./global-data.service";
import { GoogleAnalyticsService } from "booking-app-v2/shared/services/google-analytics.service";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { WhitelabelTranslateService } from "booking-app-v2/shared/services/whitelabel-translate.service";
import { BannersService } from "./initializers/banners.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalDataEnum, PRODUCT_TYPE, ProductType } from "booking-app-v2/shared/types";
import { LandingPage } from "booking-app-v2/shared/models";
import { SearchSortingUtil } from "booking-app-v2/shared/utils";

@Injectable({
  providedIn: "root",
})

export class AppInitializationService {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  constructor(
    private cookiesService: CookiesService,
    private localeService: LocaleService,
    private currenciesService: CurrenciesService,
    private pointsPartnerService: PointsPartnerService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private landingPageService: LandingPageService,
    private travelTypeService: TravelTypeService,
    private pointsCashShareService: PointsCashShareService,
    private bannersService: BannersService,
    private globalData: GlobalData,
    private router: Router,
    private whitelabelTranslateService: WhitelabelTranslateService,
    private appSettingsService: AppSettingsService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  initializeApp(): void {
    // The order of the below calls matters
    // Be careful adding new init features or changing orders

    this.initRouterEvents();
    this.cookiesService.init();
    this.localeService.init();
    this.landingPageService.init();
    this.initProductType();
    this.currenciesService.init();
    this.travelTypeService.init();
    this.pointsPartnerService.init();
    this.pointsCashShareService.init();
    this.googleAnalyticsService.init();
    this.bannersService.init();
    this.initDefaultSortOptions();
    this.checkImpersonatedUser();
  }

  private initRouterEvents(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.globalData.set(GlobalDataEnum.IS_LOADING, true);
      }
      if (event instanceof NavigationEnd) {
        this.globalData.set(GlobalDataEnum.IS_LOADING, false);
      }
    });
  }

  private initDefaultSortOptions(): void {
    const sortOptions: string[] = this.globalData.get(GlobalDataEnum.LANDING_PAGE).sortingOptions;
    SearchSortingUtil.init(this.appSettingsService, this.pointsCashShareService, this.whitelabelTranslateService);
    SearchSortingUtil.initializePermittedSortingOptions(sortOptions);
  }

  private initProductType(): void {
    // In v1, we set the productType based on url query params productType in main-ctrl first before we assign
    // the other values like below code. But that was an experimental feature and was never been carried forward.

    // The original idea was to replace/combine productType param with landingPage param in the URL,
    // so that we don't need to create a new landing page each time the client has a new product type requirement

    // And we had a plan to use productType instead of landingPage for FAB,
    // so that we can combine complimentary-night and redeem to a single landingPage,
    // but we haven't started this refactoring either.

    // In v2 here, we will just ignore the productType from URL query param and set the kaligoConfig value by default
    // And kaligoConfig.productType will be null for k.com and all DLPs, then we will init from landingPage instead

    if (this.kaligoConfig.productType) {
      this.globalData.set(GlobalDataEnum.PRODUCT_TYPE, this.kaligoConfig.productType);
      return;
    }

    const landingPage: LandingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
    let productType: ProductType;
    if (landingPage.hasProductType(PRODUCT_TYPE.VOUCHER)) {
      productType = PRODUCT_TYPE.VOUCHER;
    } else if (landingPage.hasProductType(PRODUCT_TYPE.CASHVOUCHER)) {
      productType = PRODUCT_TYPE.CASHVOUCHER;
    } else if (landingPage.hasProductType(PRODUCT_TYPE.REDEEM)) {
      productType = PRODUCT_TYPE.REDEEM;
    } else if (landingPage.hasProductType(PRODUCT_TYPE.EARN)) {
      productType = PRODUCT_TYPE.EARN;
    } else if (landingPage.hasProductType(PRODUCT_TYPE.COMPLIMENTARY_NIGHTS)) {
      productType = PRODUCT_TYPE.COMPLIMENTARY_NIGHTS;
    }
    this.globalData.set(GlobalDataEnum.PRODUCT_TYPE, productType);
  }

  private checkImpersonatedUser(): void {
    if (this.cookiesService.getImpersonated() === "true" && this.globalData.isUserLoggedIn()) {
      this.globalData.set(GlobalDataEnum.IS_IMPERSONATED_USER, true);
      const mainElement = this.document.getElementById("main");
      if (mainElement) {
        mainElement.classList.add("impersonated");
      }
    }
  }
}
