import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { BootstrapDataService } from "booking-app-v2/shared/services/bootstrap-data.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";

@Component({
  template: "",
})
export class PageNotFoundComponent implements OnInit {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  constructor(
    private router: Router,
    private bootstrapDataService: BootstrapDataService,
    private windowRefService: WindowRefService,
  ) {}

  ngOnInit() {
    // TODO: After landing page migrated, recheck if we can use this.router.navigate([])
    this.kaligoConfig.applicationType === "whitelabel" ?
      this.router.navigate(["/"]) :
      this.windowRefService.nativeWindow.location.href = this.bootstrapDataService.bootstrapData.landing_page.url || "/";
  }
}
