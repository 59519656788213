const EMPTY_STAR = 0;
const FILLED_STAR = 1;
const MAX_STARS_LIMIT = 5;

interface StartClass {
  "fa": boolean;
  "fa-star": boolean;
  "empty": boolean;
}

import { Component, Input } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "star-rating",
  template: `
    <span [attr.aria-label]="getAriaLabel()" role="text">
      <i *ngFor= "let star of getStarsCollection()" [ngClass]= "starClass(star)"
      [attr.aria-hidden]="true"></i>
    </span>
  `,
})

export class StarRatingComponent {
  @Input() stars: number;
  @Input() maxStars: number;

  constructor(
    private translateService: TranslateService,
  ) {}

  public getStarsCollection(): string[] {
    let starsCount: number;
    if (!this.stars || isNaN(this.stars)) {
      starsCount = 0;
    } else {
      starsCount = Math.min(Math.floor(this.stars), MAX_STARS_LIMIT);
    }

    const emptyStarsCount = (this.maxStars || MAX_STARS_LIMIT) - starsCount;

    const stars = Array(starsCount).fill(FILLED_STAR);
    const emptyStars = Array(emptyStarsCount).fill(EMPTY_STAR);

    return stars.concat(emptyStars);
  }

  public starClass(star: number): StartClass {
    return {
      "fa": true,
      "fa-star": true,
      "empty": star === EMPTY_STAR,
    };
  }

  public getAriaLabel(): string {
    return this.stars > 1 ?
      this.translateService.instant("count.stars_other", { count: this.stars }) :
      this.translateService.instant("count.stars_one");
  }
}
