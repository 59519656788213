import { ObjectValues } from "./object-values.type";

export const CURRENT_PAGE = {
  HOME: "home",
  HOTEL_HOME: "hotel-home",
  CAR_HOME: "car-home",
  FLIGHT_HOME: "flight-home",
  SEARCH_RESULT: "search-result",
  COMPLIMENTARY_NIGHTS: "complimentary-nights",
  HOTEL_DETAIL: "hotel-detail",
  CHECKOUT: "checkout",
  CONFIRMATION: "confirmation",
  CAR_RESULT: "car-result",
  CAR_DETAIL: "car-detail",
  CAR_CHECKOUT: "car-checkout",
  CAR_SUCCESS: "car-success",
  FLIGHT_RESULT: "flight-result",
  FLIGHT_DETAIL: "flight-detail",
  FLIGHT_CHECKOUT: "flight-checkout",
  FLIGHT_SUCCESS: "flight-success",
  MANAGE_BOOKING: "manage-booking",
  CONTACT_US: "contact-us",
  FAQ: "faq",
  TERMS_CONDITION: "terms-condition",
  PRIVACY_POLICY: "privacy-policy",
  PRIVACY_COLLECTION: "privacy-collection",
  STATIC_CONTENT: "static-content",
} as const;

export type CurrentPage = ObjectValues<typeof CURRENT_PAGE>;
