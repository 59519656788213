import { Inject, Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable, ReplaySubject, Subject, take } from "rxjs";
import Rollbar from "rollbar";

import { ForceSyncBaseStep } from "./force-sync-base-step";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { CheckoutComKitService } from "booking-app-v2/shared/services/checkout-com-kit.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PaymentFormUtilService } from "booking-app-v2/shared/services/payment-form-util.service";
import { PaymentMethodService } from "booking-app-v2/shared/services/payment-method.service";
import { RollbarService } from "booking-app-v2/shared/services/rollbar.service";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";

import { CheckoutError, Currency, LandingPage } from "booking-app-v2/shared/models";
import {
  CheckoutComKitCardDetails,
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  GlobalDataEnum,
  IntermediateCheckoutParams,
  Payment,
  PAYMENT,
  PAYMENT_METHOD,
  PaymentChannelData,
  PRODUCT_TYPE,
  SelectPaymentChannelResult,
  TRAVEL_TYPE,
} from "booking-app-v2/shared/types";
import { Room } from "booking-app-v2/hotels/models";
import { Flight } from "booking-app-v2/flights/models";
import { Car } from "booking-app-v2/cars/models";

@Injectable({
  providedIn: "root",
})
export class SelectPaymentChannelService implements ForceSyncBaseStep {
  readonly landingPage: LandingPage;
  readonly selectedCurrency: Currency;

  checkoutForm: FormGroup;
  room: Room;
  flight: Flight;
  car: Car;

  newCardSave: boolean;
  results: CheckoutResults;
  result: Subject<PaymentChannelData>;
  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.SELECT_PAYMENT_CHANNEL;

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private paymentFormUtilService: PaymentFormUtilService,
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private checkoutComKitService: CheckoutComKitService,
    private paymentMethodService: PaymentMethodService,
    private payWithPointsCashService: PayWithPointsCashService,
  ) {
    this.landingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
    this.selectedCurrency = this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
  }

  processAsync(results: CheckoutResults): Observable<SelectPaymentChannelResult> {
    this.results = results;
    this.result = new ReplaySubject<PaymentChannelData>(1);
    this.newCardSave = false;
    this.initializeCheckoutData(results.intermediateCheckoutParams);

    if (this.paymentMethodService.activePaymentTab === PAYMENT_METHOD.PAY_ANYONE) {
      this.checkoutForm.controls.paymentChannel.setValue(PAYMENT.PAY_ANYONE);
    }

    if (this.paymentMethodService.allowSaveCreditCard() && this.checkoutForm.controls.saveCreditCard.value) {
      this.newCardSave = true;
    }

    if (
      this.usePaymentChannel() &&
      !this.isAdyenPaymentChannel() &&
      !this.paymentMethodService.isUsingSavedAdyenCard()
    ) {
      if (this.selectedCurrency.preferredGateway === "checkout_com") {
        this.startCheckoutComKitBooking();
      } else {
        // populate credit card from payment method service if there are multiple payment methods
        // will need to change this if there are other default payment methods other than a credit card
        if (this.appSettingsService.hasMultiplePaymentMethods) {
          this.checkoutForm.controls.selectedSavedCard.setValue(this.paymentMethodService.selectedSavedCard);
        }

        if (this.requiresTokenization()) {
          this.startStripeBooking();
        } else {
          this.handleSuccess(undefined, PAYMENT.STRIPE);
        }
      }
    } else {
      this.handleSuccess(undefined);
    }

    return this.result.asObservable();
  }

  private initializeCheckoutData(intermediateCheckoutParams: IntermediateCheckoutParams): void {
    this.checkoutForm = intermediateCheckoutParams.checkoutForm;
    this.room = intermediateCheckoutParams.room;
    this.flight = intermediateCheckoutParams.flight;
    this.car = intermediateCheckoutParams.car;
  }

  private startCheckoutComKitBooking(): void {
    this.checkoutComKitService.cardToken(this.checkoutComKitCardDetails()).subscribe({
      next: (token: string) => this.handleSuccess(token, PAYMENT.CHECKOUT_COM),
      error: (error: string) => this.handleError(`checkout_com: Unable to create payment source. Error: ${error}`),
    });
  }

  private startStripeBooking(): void {
    this.handleSuccess(undefined, PAYMENT.STRIPE_PAYMENT_INTENTS);
  }

  private usePaymentChannel(): boolean {
    return !this.paymentFormUtilService.isExternalPaymentProvider(this.checkoutForm) &&
           !this.landingPage.hasProductType(PRODUCT_TYPE.VOUCHER);
  }

  private isAdyenPaymentChannel(): boolean {
    return this.checkoutForm.controls.paymentChannel.value.includes("adyen");
  }

  private checkoutComKitCardDetails(): CheckoutComKitCardDetails {
    return {
      number: this.checkoutForm.controls.creditCardNumber.value,
      name: this.checkoutForm.controls.cardName.value,
      cvv: this.checkoutForm.controls.creditVerificationNumber.value,
      expiryMonth: this.checkoutForm.controls.expirationMonth.value,
      expiryYear: this.checkoutForm.controls.expirationYear.value,
    };
  }

  // This covers all cases
  // OTA -> always tokenize
  // EARN -> always tokenize
  // CashVoucherEarn -> always tokenize
  // free_night -> never tokenize
  // pwp -> tokenize when cash > 0
  private requiresTokenization(): boolean {
    return !this.landingPage.complimentaryOrRedeem() ||
      (this.landingPage.complimentaryOrRedeem() && this.hasCashPayment());
  }

  private handleSuccess(paymentNonce: string, paymentChannel?: Payment): void {
    if (paymentChannel) {
      this.checkoutForm.controls.paymentChannel.setValue(paymentChannel);
    }
    this.result.next({ paymentNonce, newCardSave: this.newCardSave });
  }

  private handleError(errorMessage: string): void {
    this.results.checkoutErrorKey$.next("Payment Error");
    this.rollbar.info(errorMessage);
    this.result.error(new CheckoutError("Payment Error"));
  }

  private hasCashPayment(): boolean {
    const travelType = this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);
    switch (travelType) {
      case TRAVEL_TYPE.HOTELS:
        return this.room?.priceInfo.cash_payment > 0;
      case TRAVEL_TYPE.FLIGHTS:
        return  Math.max(this.payWithPointsCashService.getCalculatedCash(this.flight?.rawPrice), 0) > 0;
      case TRAVEL_TYPE.CARS:
        return this.car?.priceInfo.cash_payment > 0;
      default:
        return false;
    }
  }
}
