import { Component, OnInit } from "@angular/core";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { MapboxService } from "booking-app-v2/hotels/services";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { HotelDestinationValue } from "booking-app-v2/hotels/types";

@Component({
  selector: "mini-map",
  template: `
    <div class="mini-map-container">
      <div (click)="mapboxService.toggleViewMode($event)">
        <img [src]="miniMapUrl" [class.opacity]="mapboxService.viewMode === 'map'"
             alt="Map view" aria-hidden="true">
        <div class="button-container">
          <button class="btn-action font-futura">
            <ng-container *ngIf="mapboxService.viewMode === 'list'">
              <span [translate]="'View Map'"></span>
              <em class="fa fa-map-marker"></em>
            </ng-container>
            <ng-container *ngIf="mapboxService.viewMode === 'map'">
              <span [translate]="'View List'"></span>
              <em class="fa fa-list"></em>
            </ng-container>
          </button>
        </div>
      </div>
      <div class="switch-view-btn-blocker"
           *ngIf="globalData.get('hotelResultStillPolling')"
           [tooltip]="'result.wait_for_result' | translate" tooltipPosition="top"></div>
    </div>
  `,
})
export class MiniMapComponent implements OnInit {
  miniMapUrl: string;

  constructor(
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    public mapboxService: MapboxService,
  ) {}

  ngOnInit() {
    this.initMiniMapUrl();
  }

  private initMiniMapUrl(): void {
    const destinationValue: HotelDestinationValue = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM)?.destination?.value;
    const lat: string = destinationValue?.lat;
    const lng: string = destinationValue?.lng;

    if (this.isInvalidCoordinate(lat, lng)) {
      return;
    }

    const baseURL: string = `https://api.mapbox.com/styles/v1/mapbox/streets-v10/static/${lng},${lat},9/240x132`;
    const queryString: string = `?access_token=${this.appSettingsService.mapboxAPIKey}&logo=false&attribution=false`;
    this.miniMapUrl = `${baseURL}${queryString}`;
  }

  private isInvalidCoordinate(lat: string, lng: string): boolean {
    return parseFloat(lat) === 0 || isNaN(parseFloat(lat)) || parseFloat(lng) === 0 || isNaN(parseFloat(lng));
  }
}
