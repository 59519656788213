import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

import { GoogleAnalyticsService } from "booking-app-v2/shared/services/google-analytics.service";
import { Booking } from "booking_app/models";

@Component({
  selector: "ga-ecommerce-tracker",
  template: "",
})
export class GoogleAnalyticsEcommerceTrackerComponent implements OnChanges {
  @Input() booking: Booking;

  constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.booking &&
        changes.booking.currentValue &&
        changes.booking.currentValue.id !== changes.booking.previousValue?.id) {
      this.trackGAEcommerce();
    }
  }

  private trackGAEcommerce(): void {
    this.googleAnalyticsService.commonGa();

    this.googleAnalyticsService.setGa([
      {
        name: "require",
        value: "ecommerce",
      },
      {
        name: "ecommerce:addTransaction",
        value: {
          id: this.booking.zumata_booking_id,
          affiliation: "Kaligo",
          revenue: this.booking.cost,
          shipping: "0",
          tax: this.booking.tax_amount_usd,
        },
      },
      {
        name: "ecommerce:addItem",
        value: {
          id: this.booking.zumata_booking_id,
          name: this.booking.hotel_name,
          sku: this.booking.hotel_id,
          price: this.booking.cost,
          quantity: "1",
        },
      },
      {
        name: "ecommerce:send",
      },
    ]);
  }
}
