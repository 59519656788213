export interface SavedCard {
  payment_channel: string;
  token: string;
  id?: string;
  bin: string;
  last_4: string;
  card_type: string;
  expiration_month: number;
  expiration_year: number;
  default: boolean;
  removeInProgress?: boolean; // Added this extra field to handle removing from front-end
  customer_id?: string;
}

export interface StoredPayments {
  adyen: SavedCard[];
  stripe: SavedCard[];
}

export interface SavedCardCheckoutData {
  payment_method: string;
  customer: string;
}

export const blankStoredPayments: StoredPayments = {
  adyen: [],
  stripe: [],
};
