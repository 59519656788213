import * as Bowser from "bowser";
import { Parser } from "bowser";

import { BROWSER, Browser } from "booking-app-v2/shared/types";

export class UserAgentChecker {
  static isMobileOrTablet(): boolean {
    return this.isMobile() || this.isTablet();
  }

  static isMobile(): boolean {
    return this.currentBrowser().is("mobile");
  }

  static isTablet(): boolean {
    return this.currentBrowser().is("tablet");
  }

  static isIE(): boolean {
    return this.currentBrowser().is("Internet Explorer");
  }

  static isAndroid(): boolean {
    return this.currentBrowser().is("Android");
  }

  static isIOS(): boolean {
    return this.currentBrowser().is("iOS");
  }

  static isIE11(): boolean {
    return this.currentBrowser().satisfies({ "internet explorer": "~11" });
  }

  static isHuaweiBrowser(): boolean {
    return /HuaweiBrowser/i.test(navigator.userAgent);
  }

  static getPlatFormType(): string {
    return this.currentBrowser().getPlatformType(true);
  }

  static getOS(): string {
    return this.currentBrowser().getOSName();
  }

  static browser(): Browser {
    const browser: Parser.Parser = this.currentBrowser();
    if (browser.is("iOS") && browser.is("safari")) {
      return BROWSER.IOS_SAFARI;
    } else if (browser.is("iOS") && browser.is("firefox")) {
      return BROWSER.IOS_FIREFOX;
    } else if (browser.is("chrome") && browser.is("iOS")) {
      return BROWSER.IOS_CHROME;
    } else if (browser.is("chrome") && browser.is("android") && !this.isHuaweiBrowser()) {
      return BROWSER.ANDROID_CHROME;
    } else if (browser.is("firefox") && browser.is("android")) {
      return BROWSER.ANDROID_FIREFOX;
    } else if (browser.is("opera") && browser.is("android")) {
      return BROWSER.ANDROID_OPERA;
    } else if (browser.is("Samsung Internet for Android") && browser.is("android")) {
      return BROWSER.ANDROID_SAMSUNG_INTERNET;
    } else if (browser.is("mobile") && browser.is("puffin")) {
      return BROWSER.MOBILE_PUFFIN;
    } else if (this.isIE11()) {
      return BROWSER.IE11;
    }
  }

  private static currentBrowser(): Parser.Parser {
    return Bowser.getParser(navigator.userAgent);
  }
}
