export const PAY_AT_COUNTER_PURPOSE_ID = "23";

export interface CarFee {
  amount: string;
  currency_code: string;
  description: string;
  // # Counter fee purpose id
  // https://docs.cartrawler.com/docs/xml/api-details/OTA_VehAvailRate.html
  purpose: string;
}
