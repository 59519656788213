import { TimeDropdownItem } from "booking-app-v2/shared/types";
import { TimeUtils } from "booking-app-v2/shared/utils";

export class TimeListUtil {

  static buildTimeList(): TimeDropdownItem[] {
    const timeDropdownList: TimeDropdownItem[] = [];
    for (let i = 0; i <= 23; ++i) {
      const hour = this.formatHour(i);
      const hourToMinutes = +hour * 60;
      const startHour = TimeUtils.formatMinutesToTime(hourToMinutes, "hh:mm A");
      const midHour = TimeUtils.formatMinutesToTime(hourToMinutes + 30, "hh:mm A");
      timeDropdownList.push({
        value: `${hour}:00`,
        display: startHour,
      });
      timeDropdownList.push({
        value: `${hour}:30`,
        display: midHour,
      });
    }
    return timeDropdownList;
  }

  private static formatHour(hour): string {
    if (hour < 10) {
      return `0${hour}`;
    } else {
      return `${hour}`;
    }
  }
}
