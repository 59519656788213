import { Component, Input, OnInit } from "@angular/core";

import { COMPLEX_DIALOG } from "booking-app-v2/shared/types";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { GalleryDialogComponent } from "../dialog/gallery-dialog/gallery-dialog.component";

@Component({
  selector: "gallery",
  template: `
    <carousel [images]="imageList"
              [arrowAriaLabel]="arrowAriaLabel"
              [initialImageIndex]="currentGalleryIndex"
              [imageInsetShadow]="imageInsetShadow"
              (imageChange)="currentGalleryIndex = $event"></carousel>
    <button class="hotel-detail-room-button-text" type="button"
            (click)="openGalleryModal()"
            [attr.aria-label]="'Open hotel photo gallery'">
      <span [translate]="'txt.see_all_photos'"></span>
      <img [cdn-path]="'/icons/photos-icon.png'" [alt]="'txt.see_all_photos' | translate">
    </button>
    <div class="image-counter">
      <span [textContent]="currentGalleryIndex + 1"></span>
      <span>/</span>
      <span [textContent]="imageList.length"></span>
    </div>
  `,
})
export class GalleryComponent implements OnInit {
  @Input() imageList: string[];
  @Input() highResImageList: string[];
  @Input() arrowAriaLabel: string;
  @Input() currentGalleryIndex: number;
  @Input() imageInsetShadow: boolean = true;

  constructor(
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.currentGalleryIndex = this.currentGalleryIndex || 0;
  }

  openGalleryModal(): void {
    this.dialogService.open(COMPLEX_DIALOG.GALLERY, GalleryDialogComponent, {
      images: this.highResImageList,
      currentGalleryIndex: this.currentGalleryIndex,
    });
  }

}
