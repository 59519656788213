import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import {
  GlobalDataEnum,
  PointsPartnerSettings,
  RawPointsPartnerSettings,
  TRAVEL_TYPE,
  TravelType,
} from "booking-app-v2/shared/types";
import { Base } from "booking-app-v2/shared/models";

export class PointsPartner extends Base {
  readonly id: number;
  readonly visible?: boolean;
  readonly name?: string;
  readonly program_name?: string;
  readonly currency_label?: string;
  readonly currency_short: string;
  readonly currency_long?: string;
  readonly logo_url: string;
  readonly category: string;
  readonly require_member_no_only?: boolean;
  readonly points_payment_tiers?: number[];
  readonly cash_payment_tiers: number[];
  readonly hotels_points_payment_tiers?: number[];
  readonly hotels_cash_payment_tiers: number[];
  readonly cars_points_payment_tiers?: number[];
  readonly cars_cash_payment_tiers: number[];
  readonly flights_points_payment_tiers?: number[];
  readonly flights_cash_payment_tiers: number[];
  readonly min_points?: number;
  readonly min_points_cash_value_in_usd?: number;
  readonly settings?: RawPointsPartnerSettings;
  readonly formattedSettings?: PointsPartnerSettings;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    pointsPartner: PointsPartner,
  ) {
    super(appSettingsService, globalData);

    this.id = pointsPartner.id;
    this.visible = pointsPartner.visible;
    this.name = pointsPartner.name;
    this.program_name = pointsPartner.program_name;
    this.currency_label = pointsPartner.currency_label;
    this.currency_short = pointsPartner.currency_short;
    this.currency_long = pointsPartner.currency_long;
    this.logo_url = pointsPartner.logo_url;
    this.category = pointsPartner.category;
    this.require_member_no_only = pointsPartner.require_member_no_only;
    this.points_payment_tiers = pointsPartner.hotels_points_payment_tiers;
    this.cash_payment_tiers = pointsPartner.hotels_cash_payment_tiers;
    this.hotels_points_payment_tiers = pointsPartner.hotels_points_payment_tiers;
    this.hotels_cash_payment_tiers = pointsPartner.hotels_cash_payment_tiers;
    this.cars_points_payment_tiers = pointsPartner.cars_points_payment_tiers;
    this.cars_cash_payment_tiers = pointsPartner.cars_cash_payment_tiers;
    this.flights_points_payment_tiers = pointsPartner.flights_points_payment_tiers;
    this.flights_cash_payment_tiers = pointsPartner.flights_cash_payment_tiers;
    this.min_points = pointsPartner.min_points;
    this.min_points_cash_value_in_usd = pointsPartner.min_points_cash_value_in_usd;
    this.formattedSettings = this.initPointsPartnerSettings(pointsPartner.settings);
  }

  get pointsPaymentTiersByTravelType(): number[] {
    switch (this.travelType) {
      case TRAVEL_TYPE.HOTELS:
        return this.hotels_points_payment_tiers;
      case TRAVEL_TYPE.CARS:
        return this.cars_points_payment_tiers;
      case TRAVEL_TYPE.FLIGHTS:
        return this.flights_points_payment_tiers;
    }
  }

  get cashPaymentTiersByTravelType(): number[] {
    switch (this.travelType) {
      case TRAVEL_TYPE.HOTELS:
        return this.hotels_cash_payment_tiers;
      case TRAVEL_TYPE.CARS:
        return this.cars_cash_payment_tiers;
      case TRAVEL_TYPE.FLIGHTS:
        return this.flights_cash_payment_tiers;
    }
  }

  private get travelType(): TravelType {
    return this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);
  }

  private initPointsPartnerSettings(settings: RawPointsPartnerSettings): PointsPartnerSettings {
    if (settings) {
      return {
        colors: {
          backgroundColor: settings.background_color,
          buttonColor: settings.button_color,
          fontColor: settings.font_color,
        },
        crediting: {
          message: settings.crediting_message,
          min_time: settings.crediting_min_time,
          max_time: settings.crediting_max_time,
          timeframe: settings.crediting_timeframe,
        },
      };
    }

    return {
      colors: {
        backgroundColor: "",
        buttonColor: "",
        fontColor: "",
      },
      crediting: {
        message: "",
        min_time: 0,
        max_time: 0,
        timeframe: "",
      },
    };
  }
}
