import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { FormGroup } from "@angular/forms";

import AdyenCheckout from "@adyen/adyen-web";

import { CheckoutParamsAdyenEncryptedData, PAYMENT, Payment } from "booking-app-v2/shared/types";

interface AdyenCheckoutParams {
  clientKey: string;
  locale: string;
  environment: string;
}

@Injectable({
  providedIn: "root",
})
export class AdyenFormService {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  adyenCheckoutParams(): AdyenCheckoutParams {
    return {
      clientKey: this.kaligoConfig.isProduction ?
                "live_7VD74KOZT5HWZA6IEOL374C25ICCMUWG" :
                "test_3D3KFRSNB5DNZAABGKSL5RFOCQ7ASEZK",
      locale: "en_US",
      environment: this.kaligoConfig.isProduction ? "live" : "test",
    };
  }

  adyenEncryptedData(checkoutForm: FormGroup): CheckoutParamsAdyenEncryptedData {
    return {
      encrypted_card_number: checkoutForm.controls.encryptedCardNumber.value,
      encrypted_expiry_month: checkoutForm.controls.encryptedExpiryMonth.value,
      encrypted_expiry_year: checkoutForm.controls.encryptedExpiryYear.value,
      encrypted_security_code: checkoutForm.controls.encryptedSecurityCode.value,
    };
  }

  validAdyenForm(checkoutForm: FormGroup): boolean {
    if (this.adyenFormNotRequired(checkoutForm.controls.paymentChannel.value)) {
      return true;
    }

    return (
      !!checkoutForm.controls.cardName.value              ||
      !!checkoutForm.controls.encryptedCardNumber.value   ||
      !!checkoutForm.controls.encryptedExpiryMonth.value  ||
      !!checkoutForm.controls.encryptedExpiryYear.value   ||
      !!checkoutForm.controls.encryptedSecurityCode.value
    );
  }

  handle3dsAction(action: any): void {
    const redirectElem = this.renderer.createElement("div");
    redirectElem.id = "redirect-container";
    this.renderer.appendChild(this.document.body, redirectElem);
    AdyenCheckout(this.adyenCheckoutParams()).then((checkout) => {
      checkout.createFromAction(action).mount("#redirect-container");
    });
  }

  private adyenFormNotRequired(paymentChannel: Payment): boolean {
    return !paymentChannel.startsWith("adyen") || paymentChannel === PAYMENT.ADYEN_ALIPAY;
  }
}
