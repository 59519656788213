import { Injectable } from "@angular/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { User } from "booking-app-v2/shared/models";

interface GaValues {
  name: string;
  value: string | any[];
}

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {
  private gaParameters: any;
  private gaValues: GaValues[];
  private windowObj: Window;

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private windowRefService: WindowRefService,
  ) {
    this.windowObj = this.windowRefService.nativeWindow;
  }

  init() {
    const date: Date = new Date();
    const num: number = 1;
    const gaObject = "GoogleAnalyticsObject";

    ((i, s, o, g, r, a, m) => {
      i[gaObject] = r;
      i[r] = i[r] || function _() {
        (i[r].q = i[r].q || []).push(arguments);
      }, i[r].l = num * date.getTime();
      a = s.createElement(o),
      m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(this.windowObj, document, "script", "//www.google-analytics.com/analytics.js", "ga");
  }

  commonGa(): void {
    this.setCommonGaValues();
    this.setGa(this.gaValues);
  }

  setGa(gaParameters: any): void {
    if (!this.windowObj.ga) {
      return;
    }

    this.gaParameters = gaParameters;
    this.gaParameters.forEach((item) => {
      if (item.value) {
        this.windowObj.ga(item.name, item.value);
      } else {
        this.windowObj.ga(item.name);
      }
    });
  }

  private setCommonGaValues(): void {
    this.gaValues = [
      {
        name: "create",
        value: this.appSettingsService.gauid,
      },
    ];

    if (this.appSettingsService.displayFeatures) {
      this.gaValues.push(
        {
          name: "require",
          value: "displayfeatures",
        },
      );
    }

    if (this.currentUserId()) {
      this.gaValues.push(
        {
          name: "set",
          value: ["&uid", this.currentUserId()],
        },
      );
    }
  }

  private currentUserId(): number {
    if (this.globalData.isUserLoggedIn()) {
      const currentUser: User = this.globalData.get(GlobalDataEnum.CURRENT_USER);
      return currentUser.id;
    }
  }
}
