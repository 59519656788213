import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSimpleDropdownModule } from "@ascenda/ngx-simple-dropdown";

import { DatePickerModule } from "booking-app-v2/shared/components/date-picker/date-picker.module";
import { HotelDestinationSearchModule } from "booking-app-v2/shared/components/hotel-destination-search/hotel-destination-search.module";
import { FlightDestinationSearchModule } from "../flight-destination-search/flight-destination-search.module";
import { FlightsPassengersDropdownModule } from "../flights-passengers-dropdown/flights-passengers-dropdown.module";
import { RoomGuestFieldModule } from "booking-app-v2/shared/components/room-guest-field/room-guest-field.module";
import {
  RoomGuestFieldMobileModule,
} from "booking-app-v2/shared/components/room-guest-field-mobile/room-guest-field-mobile.module";
import { SharedModule } from "booking-app-v2/shared/shared.module";
import { HotelSearchFormComponent } from "./hotel-search-form.component";
import { RegionSearchDropdownModule } from "../region-search-dropdown/region-search-dropdown.module";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [ HotelSearchFormComponent ],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HotelDestinationSearchModule,
    RegionSearchDropdownModule,
    DatePickerModule,
    NgxSimpleDropdownModule,
    MatButtonModule,
    FlightDestinationSearchModule,
    FlightsPassengersDropdownModule,
    RoomGuestFieldModule,
    RoomGuestFieldMobileModule,
  ],
  exports: [ HotelSearchFormComponent ],
  providers: [],
})
export class HotelSearchFormModule {}
