import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { FlightSearchForm } from "booking-app-v2/flights/models";

@Component({
  selector: "flights-passengers-dropdown",
  templateUrl: "/html/flights/passengers_dropdown_v2",
})
export class FlightsPassengersDropdownComponent implements OnInit {

  @Input() adultCount: number;
  @Input() childCount: number;
  @Input() infantCount: number;
  @Output() onUpdateAdultCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateChildCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateInfantCount: EventEmitter<number> = new EventEmitter<number>();

  minAdultCount: number;
  maxAdultCount: number;
  minChildCount: number;
  maxChildCount: number;
  minInfantCount: number;
  maxInfantCount: number;

  private isDropdownOpen: boolean;

  private openFlag: boolean;

  constructor(
    private translateService: TranslateService,
  ) { }

  public closePassengerDropdown(): void {
    this.isDropdownOpen = false;
  }

  ngOnInit() {
    this.minAdultCount = FlightSearchForm.MIN_ADULT_COUNT;
    this.maxAdultCount = FlightSearchForm.MAX_ADULT_COUNT;
    this.minChildCount = FlightSearchForm.MIN_CHILD_COUNT;
    this.maxChildCount = FlightSearchForm.MAX_CHILD_COUNT;
    this.minInfantCount = FlightSearchForm.MIN_INFANT_COUNT;
    this.maxInfantCount = FlightSearchForm.MAX_INFANT_COUNT;
    this.openFlag = false;
  }

  toggleDropdown(): void {
    this.openFlag = true;
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  updateAdultCount(value: number) {
    this.adultCount = value;
    this.onUpdateAdultCount.emit(value);
  }

  updateChildCount(value: number) {
    this.childCount = value;
    this.onUpdateChildCount.emit(value);
  }

  updateInfantCount(value: number) {
    this.infantCount = value;
    this.onUpdateInfantCount.emit(value);
  }

  travellerCaption(): string {
    const totalCount = this.adultCount + this.childCount + this.infantCount;
    if (totalCount === 1) {
      return this.translateService.instant("count.traveller_one");
    } else {
      return this.translateService.instant("count.traveller_other", { count: totalCount });
    }
  }
}
