export class UrlUtil {
  static readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  static imageUrl(path: string): string {
    return `${this.kaligoConfig.cdnImageUrl}${path}`;
  }

  static constructUrl(rawString: string): URL {
    let url: URL;

    try {
      url = new URL(rawString);
    } catch (_) {
      return;
    }

    return url;
  }

  static isValidHttpUrl(rawString: string): boolean {
    const url = this.constructUrl(rawString);
    return url?.protocol === "http:" || url?.protocol === "https:";
  }

  static convertToHttpsUrl(rawUrl: string): string {
    const url = this.constructUrl(rawUrl);

    if ( url?.protocol === "http:" ) {
      return `https:${rawUrl.slice(5)}`;
    } else {
      return rawUrl;
    }
  }
}
