import { Injectable } from "@angular/core";
import { catchError, forkJoin, map, Observable, of, Subject, switchMap, take } from "rxjs";
import { FormGroup } from "@angular/forms";

import { FlightDestination, FlightSearchForm } from "booking-app-v2/flights/models";
import {
  FLIGHT_REVALIDATION_STATUS,
  FlightRevalidationResult,
  FlightRevalidationStatus,
  GlobalDataEnum,
  SIMPLE_DIALOG,
} from "booking-app-v2/shared/types";
import {
  FareRules,
  FlightCabinOption,
  FlightCommonQueryParams,
  FlightPricesRawResponse,
  FlightPricesUrlParams,
  FlightSinglePricesUrlParams,
  FlightSingleQueryParams,
  FlightTypeOption,
  FLIGHT_TYPE,
  FlightsFromAndToAirport,
  defaultFlightPricesRawResponse,
} from "booking-app-v2/flights/types";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { FlightsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/flights-url-builder.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { ApiPollingService } from "booking-app-v2/shared/services/api-polling.service";
import { DestinationService } from "booking-app-v2/shared/services/destination.service";
import { TranslateService } from "@ngx-translate/core";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { SimpleDialogComponent } from "booking-app-v2/shared/components/dialog/simple-dialog/simple-dialog.component";

import { TimeUtils } from "booking-app-v2/shared/utils";

@Injectable({
  providedIn: "root",
})
export class FlightUtilService {
  constructor(
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private dialogService: DialogService,
    private flightUrlBuilderService: FlightsUrlBuilderService,
    private apiPollingService: ApiPollingService,
    private translateService: TranslateService,
    private destinationService: DestinationService,
  ) {}

  getFlightTypeOption(): FlightTypeOption {
    return this.flightUrlBuilderService.buildFlightTypeOptionFromUrl();
  }

  getFlightCabinOption(): FlightCabinOption {
    return this.flightUrlBuilderService.buildFlightCabinOptionFromUrl();
  }

  getAdultCountOption(): number {
    return this.flightUrlBuilderService.buildAdultCountFromUrl();
  }

  getChildCountOption(): number {
    return this.flightUrlBuilderService.buildChildCountFromUrl();
  }

  getInfantCountOption(): number {
    return this.flightUrlBuilderService.buildInfantCountFromUrl();
  }

  setFlightSearchFormFromQueryParams(
    params: FlightCommonQueryParams,
    fromAirport: FlightDestination,
    toAirport: FlightDestination,
  ): void {
    const flightSearchForm: FlightSearchForm = new FlightSearchForm(
      this.getFlightTypeOption(),
      this.getFlightCabinOption(),
      fromAirport,
      toAirport,
      this.getAdultCountOption(),
      this.getChildCountOption(),
      this.getInfantCountOption(),
      params.departureDate,
      params.returnDate,
    );

    this.globalData.set(GlobalDataEnum.FLIGHT_SEARCH_FORM, flightSearchForm);
  }

  setFlightSearchFormFromFlightsSinglePrice(
    flightsSinglePriceResponse: FlightPricesRawResponse,
    fromAirport: FlightDestination,
    toAirport: FlightDestination,
  ): void {
    const { flight_type, cabin, passengers, departure_date, return_date } = flightsSinglePriceResponse.search;

    const flightType = {
      key: flight_type,
      label: flight_type === FLIGHT_TYPE.RETURN ? "Return" : "One Way",
    };
    const flightCabin = {
      key: cabin,
      label: this.translateService.instant(`flight_cabin.${cabin}`),
    };
    const [adultCount, childCount, infantCount] = passengers.split(",").map((passenger) => parseInt(passenger, 10));

    const flightSearchForm: FlightSearchForm = new FlightSearchForm(
      flightType,
      flightCabin,
      fromAirport,
      toAirport,
      adultCount,
      childCount,
      infantCount,
      departure_date,
      return_date,
    );

    this.globalData.set(GlobalDataEnum.FLIGHT_SEARCH_FORM, flightSearchForm);
  }

  fetchFlightsPrices(params: FlightCommonQueryParams): Observable<FlightPricesRawResponse> {
    const {
      fromAirport,
      toAirport,
      departureDate,
      returnDate,
      cabin,
      flightType,
      currency,
      landingPage,
      partnerId,
      productType,
    } = params;

    const urlParams: FlightPricesUrlParams = {
      flight_type: flightType,
      departure_date: departureDate,
      passengers: `${this.getAdultCountOption()},${this.getChildCountOption()},${this.getInfantCountOption()}`,
      from_airport: fromAirport,
      to_airport: toAirport,
      cabin,
      currency,
      partner_id: partnerId,
      landing_page: landingPage,
    };

    if (params.flightType === FLIGHT_TYPE.RETURN) {
      urlParams.return_date = returnDate;
    }

    if (this.appSettingsService.useProductType) {
      urlParams.product_type = this.globalData.productTypeAdapter(productType);
    }

    const response$ = new Subject<FlightPricesRawResponse>();

    this.globalData.set(GlobalDataEnum.FLIGHT_RESULT_STILL_POLLING, true);

    this.apiPollingService
      .poll("flights/prices", urlParams, response$)
      .pipe(take(1))
      .subscribe((response: FlightPricesRawResponse) => {
        this.globalData.set(GlobalDataEnum.FLIGHT_RESULT_STILL_POLLING, false);
        response$.next(response);
      });

    return response$.asObservable();
  }

  fetchFlightsSinglePrice(params: FlightSingleQueryParams): Observable<FlightPricesRawResponse> {
    const { bookingKey, partnerId, revalidate = false } = params;
    const urlParams: FlightSinglePricesUrlParams = {
      landing_page: this.globalData.get(GlobalDataEnum.LANDING_PAGE).url,
      partner_id: partnerId,
      revalidate,
    };

    if (this.appSettingsService.useProductType) {
      urlParams.product_type = this.globalData.productTypeAdapter(this.globalData.get(GlobalDataEnum.PRODUCT_TYPE));
    }

    const flightItemsResponse$: Observable<FlightPricesRawResponse> = this.apiPollingService
      .poll(`flights/single_price/${bookingKey}`, urlParams)
      .pipe(map((response: FlightPricesRawResponse) => response));

    return flightItemsResponse$;
  }

  fetchFlightsSinglePriceWithRevalidation(
    params: FlightSingleQueryParams,
    revalidate?: boolean,
  ): Observable<FlightRevalidationResult> {
    this.globalData.set(
      GlobalDataEnum.FLIGHT_RESULT_STILL_POLLING,
      !this.globalData.get(GlobalDataEnum.IS_REVALIDATING),
    );

    return this.fetchFlightsSinglePrice({...params, revalidate}).pipe(
      switchMap((response: FlightPricesRawResponse) => {
        if (response?.itineraries?.length !== 0 && !revalidate) {
          this.globalData.set(GlobalDataEnum.IS_REVALIDATING, true);
          return this.fetchFlightsSinglePriceWithRevalidation(params, true);
        } else {
          if (response?.itineraries?.length > 0) {
            return this.buildFlightSearchFormFromRawResponse(response, FLIGHT_REVALIDATION_STATUS.SUCCESS);
          } else {
            return this.buildFlightSearchFormFromRawResponse(response, FLIGHT_REVALIDATION_STATUS.NO_RESULTS);
          }
        }
      }),
      catchError(() => {
        this.setFinishRevalidating();
        return of({ status: FLIGHT_REVALIDATION_STATUS.ERROR, response: defaultFlightPricesRawResponse });
      }),
    );
  }

  fetchFromAndToAirports(fromAirport: string, toAirport: string): Observable<FlightsFromAndToAirport> {
    return forkJoin([
      this.destinationService.getFlightDestinations(fromAirport),
      this.destinationService.getFlightDestinations(toAirport),
    ]).pipe(
      map(([from, to]) => {
        return {
          fromAirport: from.filter((destination) => destination.value === fromAirport)[0],
          toAirport: to.filter((destination) => destination.value === toAirport)[0],
        };
      }),
    );
  }

  fetchFlightsTerms(bookingKey: string): Observable<FareRules> {
    return this.apiPollingService.poll(`flights/${bookingKey}/terms`, {}).pipe(map((response: FareRules) => response));
  }

  showFlightExpiredDialog(): void {
    if (this.globalData.get(GlobalDataEnum.FLIGHT_SEARCH_FORM)) {
      this.dialogService
        .open(SIMPLE_DIALOG.FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE, SimpleDialogComponent)
        .subscribe(() => {
          this.flightUrlBuilderService.redirectToResultsPage();
        });
    } else {
      this.dialogService
        .open(SIMPLE_DIALOG.FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE, SimpleDialogComponent)
        .subscribe(() => {
          this.flightUrlBuilderService.redirectToLandingPage();
        });
    }
  }

  buildFlightSearchFormFromRawResponse(
    response: FlightPricesRawResponse,
    status: FlightRevalidationStatus = FLIGHT_REVALIDATION_STATUS.NO_REVALIDATION_REQUIRED,
  ): Observable<FlightRevalidationResult> {
    if (!this.globalData.get(GlobalDataEnum.FLIGHT_SEARCH_FORM) && this.searchHistoryAvailable(response)) {
      return this.fetchFromAndToAirports(response.search.from_airport, response.search.to_airport)
        .pipe(map(({ fromAirport, toAirport }) => {
          this.setFlightSearchFormFromFlightsSinglePrice(response, fromAirport, toAirport);
          this.setFinishRevalidating();
          return { status, response };
        }));
    } else {
      this.setFinishRevalidating();
      return of({ status, response });
    }
  }

  invalidDateOfBirth(checkoutForm: FormGroup): boolean {
    const day = checkoutForm.controls.dateOfBirthDay.value;
    const month = checkoutForm.controls.dateOfBirthMonth.value;
    const year = checkoutForm.controls.dateOfBirthYear.value;
    const paddedTime = TimeUtils.format(`${year}-${month}-${day}`, "YYYY-MM-DD", "YYYY-M-D");
    return !TimeUtils.isValidDate(paddedTime) || TimeUtils.isFutureDate(paddedTime);
  }

  invalidPassportExpiryDate(checkoutForm: FormGroup): boolean {
    // If passportExpiryDay control exists, we know passport expiry month and year must also exist
    if (checkoutForm.controls.passportExpiryDay) {
      const day = checkoutForm.controls.passportExpiryDay.value;
      const month = checkoutForm.controls.passportExpiryMonth.value;
      const year = checkoutForm.controls.passportExpiryYear.value;
      const paddedTime = TimeUtils.format(`${year}-${month}-${day}`, "YYYY-MM-DD", "YYYY-M-D");
      return !TimeUtils.isValidDate(paddedTime) || !TimeUtils.isFutureDate(paddedTime);
    } else {
      return false;
    }
  }

  checkIsPriceChanged(flightPricesRawResponse: FlightPricesRawResponse): boolean {
    const flightRawResponse = flightPricesRawResponse.itineraries[0];
    return flightRawResponse.old_base_cash_payment < flightRawResponse.base_cash_payment;
  }

  private searchHistoryAvailable(response: FlightPricesRawResponse): boolean {
    return !!response.search && Object.keys(response.search).length > 0;
  }

  private setFinishRevalidating(): void {
    this.globalData.set(GlobalDataEnum.FLIGHT_RESULT_STILL_POLLING, false);
    this.globalData.set(GlobalDataEnum.IS_REVALIDATING, false);
  }
}
