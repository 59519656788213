import { Base, LandingPage } from "booking-app-v2/shared/models";
import { Room } from "booking-app-v2/hotels/models";
import { GlobalDataEnum, PRODUCT_TYPE, Voucher } from "booking-app-v2/shared/types";
import { RoomRawPrice } from "../types";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";

export class Rooms extends Base {
  availRooms: Room[];
  roomListing: { [key: string]: { rooms: Room[] } };

  private timeoutEvent: ReturnType<typeof setTimeout> = null;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    currencyService: CurrenciesService,
    payWithPointsCashService: PayWithPointsCashService,
    pageDiscoveryService: PageDiscoveryService,
    appliedVouchers: Voucher[],
    roomsRawPrice: RoomRawPrice[],
  ) {
    super(appSettingsService, globalData);
    this.availRooms = [];

    roomsRawPrice.forEach((roomRawPrice: RoomRawPrice) => {
      this.availRooms.push(
        new Room(
          this.appSettingsService,
          this.globalData,
          currencyService,
          payWithPointsCashService,
          pageDiscoveryService,
          appliedVouchers,
          roomRawPrice,
        ),
      );
    });

    this.removeDuplicates();
    this.filterBlacklistedRooms();
    this.setupRoomsGrouping();
  }

  private removeDuplicateOnGroupedRooms(): void {
    for (const room in this.roomListing) {
      if (this.roomListing[room].rooms.length > 1) {
        this.roomListing[room].rooms = this.removeDuplicatesByPrice(this.roomListing[room].rooms);
      }
    }
  }

  private groupRooms(): void {
    this.roomListing = this.availRooms.reduce((roomListing, room) => {
      const arrKey: string = room.roomNormalizedDescription || room.roomDescription || room.type;
      roomListing[arrKey] = roomListing[arrKey] || { id: arrKey, rooms: [] };
      roomListing[arrKey].rooms.push(room);
      return roomListing;
    }, {});
  }

  private setupRoomsGrouping(): void {
    if (this.timeoutEvent) {
      clearTimeout(this.timeoutEvent);
    }

    this.timeoutEvent = setTimeout(() => {
      this.groupRooms();
      this.removeDuplicateOnGroupedRooms();
      clearTimeout(this.timeoutEvent);
    }, 300);
  }

  private filterBlacklistedRooms(): void {
    const blackListedKeys: string[] = this.globalData.get(GlobalDataEnum.BOOKING_KEYS_BLACK_LIST);

    if (blackListedKeys) {
      this.availRooms = this.availRooms.filter(
        (room) => !blackListedKeys.includes(room.key),
      );
    }

    this.availRooms.forEach((room, i) => {
      return room.visible = i < this.availRooms.length;
    });
  }

  private removeDuplicates(): void {
    const uniqueKey = {};
    const uniqueList: Room[] = [];

    if (this.availRooms) {
      this.availRooms.forEach((room) => {

        if (!uniqueKey[room.key]) {
          uniqueList.push(room);
        }
        return uniqueKey[room.key] = true;
      });
    }

    this.availRooms = uniqueList;
  }

  private shouldShowFreeCancellation(): boolean {
    const landingPage: LandingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
    if (landingPage.hasProductType(PRODUCT_TYPE.REDEEM)) {
      return this.appSettingsService.showCancelPolicyOnRedeem;
    } else if (landingPage.hasProductType(PRODUCT_TYPE.EARN)) {
      return this.appSettingsService.showCancelPolicyOnEarn;
    } else {
      return false;
    }
  }

  private removeDuplicatesByPrice(rooms: Room[]): Room[] {
    const filteredRooms: Room[] = [];
    rooms.forEach(room => {
      const currentRoom: Room = filteredRooms.find(filteredRoom => (
        filteredRoom.roomDescriptionDetail === room.roomDescriptionDetail &&
        (filteredRoom.free_cancellation === room.free_cancellation ||
          !this.shouldShowFreeCancellation())
      ));
      if (currentRoom) {
        if (currentRoom.priceInfo.max_cash_payment < room.priceInfo.max_cash_payment) {
          room = currentRoom;
        }
        return;
      }
      filteredRooms.push(room);
    });

    return filteredRooms;
  }

}
