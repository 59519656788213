import { RawHotelDestination } from "booking-app-v2/hotels/types";
import { ObjectValues } from "./object-values.type";

export const USER_ACTION_TYPE = {
  DESTINATION: "destination",
} as const;

export type UserActionType = ObjectValues<typeof USER_ACTION_TYPE>;

export interface UserAction {
  action_type: UserActionType;
  custom_data: RawHotelDestination;
}

export interface UserActionInput {
  action_type: UserActionType;
  custom_data_id: string;
}
