import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { LINK_TARGET } from "booking-app-v2/shared/types";

@Directive({
  selector: "[linkChangeTarget]",
})
export class LinkChangeTargetDirective implements OnInit {

  @Input() linkChangeTarget: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private appSettingsService: AppSettingsService,
  ) { }

  ngOnInit() {
    if (this.appSettingsService.openInTargetTab.allLinksTarget !== LINK_TARGET.SELF) {
      if (this.el.nativeElement.tagName === "A") {
        this.renderer.setAttribute(
          this.el.nativeElement, "target",
          this.appSettingsService.openInTargetTab.allLinksTarget,
        );
      } else {
        const childNodes = this.el.nativeElement.children("a");
        childNodes.forEach((anchor) => this.renderer.setAttribute(
          childNodes[anchor], "target",
          this.appSettingsService.openInTargetTab.allLinksTarget,
        ));
      }
    }
  }
}
