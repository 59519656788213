import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSimpleDropdownModule } from "@ascenda/ngx-simple-dropdown";
import { SharedModule } from "booking-app-v2/shared/shared.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { RegionSearchDropdownComponent } from "./region-search-dropdown.component";

@NgModule({
  declarations: [ RegionSearchDropdownComponent ],
  imports: [
    SharedModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSimpleDropdownModule,
  ],
  exports: [ RegionSearchDropdownComponent ],
  providers: [],
})
export class RegionSearchDropdownModule {}
