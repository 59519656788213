import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { MatDialogModule } from "@angular/material/dialog";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule } from "@ngx-translate/core";

import { BootstrapDataService } from "./services/bootstrap-data.service";
import { InterceptorService } from "booking-app-v2/shared/services/interceptor.service";
import { ErrorHandlerService } from "booking-app-v2/shared/services/error-handler.service";
import { rollbarFactory, RollbarService } from "booking-app-v2/shared/services/rollbar.service";
import {
  CdnPathDirective,
  ClickOutsideDirective,
  DisableFormControlDirective,
  HumanizeTranslationDirective,
  LinkChangeTargetDirective,
  LoadScriptDirective,
  NumberInputMaxLengthDirective,
  TooltipDirective,
  TranslateSingularDirective,
} from "./directives";
import { CreditCardValidator } from "booking-app-v2/shared/validators";
import {
  AdjustDecimalsPipe,
  CodeSymbolFormatPipe,
  DateTimeFormatPipe,
  LocaleNumberFormat,
  LocaleShortenNumberPipe,
  NumberFormat,
  OrderByPipe,
  ParseHtmlInInputPipe,
  RoundDownNumberPipe,
  ShortenNumberPipe,
} from "./pipes";

import { kaligoConfigs } from "booking-app-v2/shared/configs";

const declaredPipes = [
  AdjustDecimalsPipe,
  CodeSymbolFormatPipe,
  DateTimeFormatPipe,
  NumberFormat,
  LocaleNumberFormat,
  LocaleShortenNumberPipe,
  ShortenNumberPipe,
  OrderByPipe,
  ParseHtmlInInputPipe,
  RoundDownNumberPipe,
];

const declaredDirectives = [
  CdnPathDirective,
  ClickOutsideDirective,
  LinkChangeTargetDirective,
  LoadScriptDirective,
  TooltipDirective,
  DisableFormControlDirective,
  CreditCardValidator,
  TranslateSingularDirective,
  NumberInputMaxLengthDirective,
  HumanizeTranslationDirective,
];

const sharedExternalModules = [
  CommonModule,
  MatDialogModule,
  MatBottomSheetModule,
  RouterModule,
  TranslateModule,
  HttpClientJsonpModule,
  HttpClientModule,
];

@NgModule({
  declarations: [
    ...declaredPipes,
    ...declaredDirectives,
  ],
  imports: [
    ...sharedExternalModules,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [BootstrapDataService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: RollbarService,
      useFactory: rollbarFactory,
    },
    ...kaligoConfigs,
    LocaleNumberFormat,
  ],
  exports: [
    ...sharedExternalModules,
    ...declaredPipes,
    ...declaredDirectives,
  ],
})

export class SharedModule { }

export function initialize(config: BootstrapDataService) {
  return () => config.loadBootstrapData();
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, `translations/`, "");
}
