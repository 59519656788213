import { HotelRawPrice} from "../types";
import { RoomPrice } from "../models";

export class DiscountRatesDecorator {

  static decorate(price: RoomPrice, rawPrice: HotelRawPrice): void {
    if (rawPrice.discount) {
      price.discount = rawPrice.discount;
      price.originalPricePerRoomPerNight = Math.ceil(price.pricePerRoomPerNight / (1 - price.discount / 100));
    }
  }
}
