import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, UrlSerializer, UrlTree } from "@angular/router";
import { Config } from "@ascenda/ngx-simple-dropdown";

import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { UserActionService } from "booking-app-v2/shared/services/user-action.service";
import { GlobalDataEnum, USER_ACTION_TYPE } from "booking-app-v2/shared/types";
import { HotelDestinationValue } from "booking-app-v2/hotels/types";
import { HotelDestination, HotelSearchForm } from "booking-app-v2/hotels/models";
import { DatePickerEmitValues } from "../date-picker/date-picker.component";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { HotelsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/hotels-url-builder.service";

@Component({
  selector: "hotel-search-form",
  templateUrl: "/html/hotels/search_form_v2",
})
export class HotelSearchFormComponent implements OnInit, AfterContentChecked {
  @Input() hidePartnerSelector: boolean = false;

  roomsDropdownConfig: Config<number>;
  adultsDropdownConfig: Config<number>;
  childrenDropdownConfig: Config<number>;
  childrenAgeDropdownConfig: Config<number>;
  isChildrenSearchEnabled: boolean;

  protected hotelSearchForm: FormGroup;

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected router: Router,
    protected urlSerializer: UrlSerializer,
    protected windowRefService: WindowRefService,
    protected appSettingsService: AppSettingsService,
    protected globalData: GlobalData,
    protected pageDiscoveryService: PageDiscoveryService,
    protected userActionService: UserActionService,
    protected pointsCashShareService: PointsCashShareService,
    protected hotelsUrlBuilderService: HotelsUrlBuilderService,
  ) {}

  ngOnInit() {
    this.initFormControlAndSelectedValues();
    this.initDropdowns();
    this.isChildrenSearchEnabled = this.appSettingsService.enableChildrenSearch;
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  get roomsCount(): number {
    return this.hotelSearchForm.controls.rooms.value;
  }

  get adultsCount(): number {
    return this.hotelSearchForm.controls.adults.value;
  }

  get childrenCount(): number {
    return this.hotelSearchForm.controls.children.value;
  }

  get childrenAges(): number[] {
    return this.hotelSearchForm.controls.childrenAges.value;
  }

  get childrenArray(): number[] {
    const numberOfChildren: number = this.hotelSearchForm.controls.children.value;
    return numberOfChildren > 0 ? Array.from(Array(this.hotelSearchForm.controls.children.value).keys()) : [];
  }

  setCheckInCheckOutDate(datePickerEmitValues: DatePickerEmitValues): void {
    this.hotelSearchForm.controls.checkInDate.setValue(datePickerEmitValues.startDate);
    this.hotelSearchForm.controls.checkOutDate.setValue(datePickerEmitValues.endDate);
  }

  setRoomsCount(roomsCount: number): void {
    this.hotelSearchForm.controls.rooms.setValue(roomsCount);
  }

  setAdultsCount(adultsCount: number): void {
    this.hotelSearchForm.controls.adults.setValue(adultsCount);
  }

  setChildrenCount(childrenCount: number): void {
    this.hotelSearchForm.controls.children.setValue(childrenCount);
  }

  setChildrenAge(childrenAge: number, child: number): void {
    const newChildrenAges: number[] = [...this.hotelSearchForm.controls.childrenAges.value];
    newChildrenAges[child] = childrenAge;
    this.setChildrenAges(newChildrenAges);
  }

  setChildrenAges(childrenAges: number[]): void {
    this.hotelSearchForm.controls.childrenAges.setValue(childrenAges);
  }

  isLoading(): boolean {
    // angular-v2-todo: implement this method once the form integration for hotel search is done
    return false;
  }

  protected markFormAsDirty(): void {
    this.hotelSearchForm.controls.destination.markAsDirty();
    this.hotelSearchForm.controls.checkInDate.markAsDirty();
    this.hotelSearchForm.controls.checkOutDate.markAsDirty();
  }

  protected createHotelSearchFormObj(destination: HotelDestination): void {
    const checkInDate: string = this.hotelSearchForm.controls.checkInDate.value;
    const checkOutDate: string = this.hotelSearchForm.controls.checkOutDate.value;
    const rooms: number = this.hotelSearchForm.controls.rooms.value;
    const adults: number = this.hotelSearchForm.controls.adults.value;
    const children: number = this.hotelSearchForm.controls.children.value;
    const childrenAges: number[] = this.hotelSearchForm.controls.childrenAges.value;

    const hotelSearchFormObj: HotelSearchForm = new HotelSearchForm(
      destination, checkInDate, checkOutDate, null, rooms, adults, children, childrenAges,
    );

    this.globalData.set(GlobalDataEnum.HOTEL_SEARCH_FORM, hotelSearchFormObj);
  }

  protected getSearchFormUrl(destination: HotelDestination): UrlTree {

    const searchUrl: UrlTree = this.router.createUrlTree(
      [`/results/${destination.value.destination_id}`],
      {
      queryParams: this.hotelsUrlBuilderService.buildSearchUrl(),
      },
    );

    return searchUrl;
  }

  protected trackHotelSearch(): void {
    // angular-v2-todo: uncomment and complete below 2 implementations separately
    // once trackerService, results page filtering services are
    // this.trackerService.hotelsSearch(this.KaligoSearch.form);
    // this.resetSearchResultState();
  }

  protected initFormControlAndSelectedValues(): void {
    this.hotelSearchForm = new FormGroup({
      destination: new FormControl(null, Validators.required),
      checkInDate: new FormControl("", Validators.required),
      checkOutDate: new FormControl("", Validators.required),
      rooms: new FormControl(1, Validators.required),
      adults: new FormControl(2, Validators.required),
      children: new FormControl(0, Validators.required),
      childrenAges: new FormControl([0, 0, 0, 0]),
    });
  }

  protected initDropdowns(): void {
    this.roomsDropdownConfig = HotelSearchForm.ROOM_DROPDOWN_CONFIG;
    this.adultsDropdownConfig = HotelSearchForm.ADULT_DROPDOWN_CONFIG;
    this.childrenDropdownConfig = HotelSearchForm.CHILD_DROPDOWN_CONFIG;
    this.childrenAgeDropdownConfig = HotelSearchForm.CHILDREN_AGE_DROPDOWN_CONFIG;
  }

  protected submitHotelSearchForm(): void {
    const destination: HotelDestination = this.hotelSearchForm.controls.destination.value;

    this.markFormAsDirty();

    if (!this.hotelSearchForm.valid) {
      return;
    }

    this.trackUserDestinationSearch();

    this.trackHotelSearch();

    this.createHotelSearchFormObj(destination);

    this.globalData.set(GlobalDataEnum.IS_LOADING, true);

    if (this.appSettingsService.reloadOnSearch) {
      this.windowRefService.nativeWindow.location.href =
        this.urlSerializer.serialize(this.getSearchFormUrl(destination));
    } else {
      this.router.navigate(
        [`/results/${destination.value.destination_id}`],
        this.getSearchFormUrl(destination),
      );
    }
  }

  protected trackUserDestinationSearch(): void {
    const destinationValue: HotelDestinationValue = this.hotelSearchForm.controls.destination.value.value;
    if (destinationValue && this.appSettingsService.enableUserActionTracking) {
      const id: string = destinationValue.hotel_id || destinationValue.destination_id;
      this.userActionService.postUserAction(USER_ACTION_TYPE.DESTINATION, id);
    }
  }

}
