import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { GoogleAnalyticsEcommerceTrackerComponent } from "./google-analytics-ecommerce-tracker.component";

@NgModule({
  declarations: [ GoogleAnalyticsEcommerceTrackerComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ GoogleAnalyticsEcommerceTrackerComponent ],
  providers: [],
})
export class GoogleAnalyticsEcommerceTrackerModule {}
