import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import {
  MatCalendarCellClassFunction,
  MatDatepicker,
  MatDatepickerInputEvent,
  MatDateRangePicker,
} from "@angular/material/datepicker";
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from "@angular/material/core";
import { Dayjs } from "dayjs";
import { Observable } from "rxjs";

import { TimeUtils } from "booking-app-v2/shared/utils";
import { GlobalDataEnum, TRAVEL_TYPE, TravelType } from "booking-app-v2/shared/types";
import { HotelSearchForm } from "booking-app-v2/hotels/models";
import { FlightSearchForm } from "booking-app-v2/flights/models";
import { Locale } from "booking-app-v2/shared/models";
import { LocaleService } from "booking-app-v2/shared/services/initializers/locale.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { BootstrapDataService } from "booking-app-v2/shared/services/bootstrap-data.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CarSearchForm } from "booking-app-v2/cars/models/car-search-form.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

export interface DatePickerEmitValues {
  startDate: string | Dayjs;
  endDate: string | Dayjs;
}

@UntilDestroy()
@Component({
  selector: "date-picker",
  template: `
    <mat-form-field [appearance]="appearance" *ngIf="!singleField">
      <mat-label>{{ dateFieldLabel }}</mat-label>
      <mat-date-range-input
        [formGroup]="dateRangeFormGroup"
        [rangePicker]="dateRangePicker"
        [dateFilter]="rangeFilter"
        [min]="minDate"
        [max]="maxDate"
        (click)="dateRangePicker.open()"
      >
        <input
          matStartDate
          formControlName="start"
          placeholder="Start date"
          (dateChange)="onDateRangeStartSelect($event)"
          #dateRangeStart readonly
        >
        <input
          matEndDate
          formControlName="end"
          placeholder="End date"
          (dateChange)="onDateRangeEndSelect($event)"
          #dateRangeEnd
          readonly
        >
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="dateRangePicker">
        <img
          matDatepickerToggleIcon
          [cdn-path]="customIconPath"
          prefix="tenant"
          *ngIf="customIconPath"
        >
      </mat-datepicker-toggle>
      <mat-date-range-picker
        #dateRangePicker
        [maxRange]="maxBookingDays"
        (closed)="onDatePickerClosed()"
        panelClass="date-range-picker-popup"
      >
      </mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="singleField">
      <mat-label>{{ dateFieldLabel }}</mat-label>
      <input matInput
        [matDatepicker]="singleDatePicker"
        [min]="minDate"
        [formControl]="dateSingleFormControl"
        (click)="singleDatePicker.open()"
      >
      <mat-datepicker-toggle matSuffix [for]="singleDatePicker">
        <img
          matDatepickerToggleIcon
          [cdn-path]="customIconPath"
          prefix="tenant"
          *ngIf="customIconPath"
        >
      </mat-datepicker-toggle>
      <mat-datepicker
      [dateClass]="datePickerItemClass"
        #singleDatePicker
        (closed)="onSingleDatePickerClosed()"
        ></mat-datepicker>
    </mat-form-field>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Input() appearance: string;
  @Input() endDateFromStartDate: number;
  @Input() dateFormat: string;
  @Input() dateFieldLabel: string;
  @Input() customIconPath: string;
  @Input() singleField: boolean;
  @Input() otherDatePickerValue: Dayjs;
  @Input() isEndDatePicker: boolean;
  @Input() overrideMinDateListener$: Observable<Dayjs>;

  @Output() dateOnUpdate: EventEmitter<DatePickerEmitValues> = new EventEmitter<DatePickerEmitValues>();

  dateRangeFormGroup: FormGroup;
  dateSingleFormControl: FormControl;
  minDate: Dayjs;
  maxDate: Dayjs;
  maxBookingDays: number;
  datePickerItemClass: MatCalendarCellClassFunction<Date>;
  rangeFilter: (currentDate: Dayjs | null) => boolean = this.filterByRange.bind(this);
  @ViewChild("singleDatePicker") private singleDatePicker: MatDatepicker<Date>;
  @ViewChild("dateRangePicker") private dateRangePicker: MatDateRangePicker<Date>;

  private maxAvailableBookingMonths: number;
  private minDaysToBookInAdvance: number;
  private minBookingDays: number;
  private fromDate: Dayjs;
  private toDate: Dayjs;

  constructor(
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private bootstrapDataService: BootstrapDataService,
    @Inject(MAT_DATE_FORMATS) public dateFormatConfig: MatDateFormats,
    private dateAdapter: DateAdapter<any>,
    private localeService: LocaleService,
  ) { }

  ngOnInit() {
    this.listenLangChange();
    this.initDatePickerParams();
    this.initDatepickerForm();
    this.initOverrideMinDateListener();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.otherDatePickerValue?.currentValue !== null) {
      this.setDatePickerItemClass();
    }
  }

  onDateRangeStartSelect(event: MatDatepickerInputEvent<Dayjs>): void {
    this.fromDate = event.value;
    /* This is to refresh the calendar view after the start date selection so that we can
    update the values such maxBooking dates in the calendar view.
    Unfortunately material datepicker doesn't provide a native way to do this.
    More info here https://github.com/angular/components/issues/15776 */
    this.closeDatePicker();
    setTimeout(() => {
      this.openDatePicker();
      this.minDate = this.dateRangeFormGroup.get("start").value;
    }, 100);
  }

  onDateRangeEndSelect(event: MatDatepickerInputEvent<Dayjs>): void {
    this.toDate = event.value;
    this.resetMinMaxDates();
  }

  onDatePickerClosed(): void {
    if (!this.toDate) {
      this.toDate = this.fromDate;
    }
    this.emitDateValues(this.fromDate, this.toDate);
  }

  onSingleDatePickerClosed(): void {
    const dateSingleValue = TimeUtils.format(this.dateSingleFormControl.value, this.dateFormat);
    this.emitDateValues(dateSingleValue, dateSingleValue);
  }

  filterByRange(currentDate: Dayjs | null): boolean {
    const currentFromDate: Dayjs = this.dateRangeFormGroup.get("start").value;
    const currentToDate: Dayjs = this.getToDate(currentFromDate);
    return !(currentDate > currentFromDate && currentDate < currentToDate);
  }

  setDatePickerItemClass(): void {
    this.datePickerItemClass = (cellDate) => {
      const parsedOtherDatePickerValue = TimeUtils.parseDate(this.otherDatePickerValue);
      const parsedCellDate = TimeUtils.parseDate(cellDate);
      if (parsedOtherDatePickerValue.get("month") === parsedCellDate.get("month") &&
        parsedOtherDatePickerValue.get("date") === parsedCellDate.get("date") &&
        parsedOtherDatePickerValue.get("year") === parsedCellDate.get("year")) {
        return "other-date-selected";
      }
      return "";
    };
  }

  private emitDateValues(startDate: string | Dayjs, endDate: string | Dayjs) {
    this.dateOnUpdate.emit({ startDate, endDate });
  }

  private openDatePicker(): void {
    this.dateRangePicker.open();
  }

  private closeDatePicker(): void {
    this.dateRangePicker.close();
  }

  private resetMinMaxDates(): void {
    this.minDate = TimeUtils.addDate(this.minDaysToBookInAdvance, "day");
    this.maxDate = TimeUtils.addDate(this.maxAvailableBookingMonths, "month");
  }

  private initOverrideMinDateListener(): void {
    if (this.isEndDatePicker) {
      this.overrideMinDateListener$?.pipe(untilDestroyed(this))
        .subscribe((minDateOverride: Dayjs) => {
          this.minDate = TimeUtils.addDate(1, "day", minDateOverride);
          if (this.dateSingleFormControl.value < minDateOverride) {
            this.dateSingleFormControl.patchValue(minDateOverride);
          }
          this.singleDatePicker.open();
        });
    }
  }

  private initDatePickerParams(): void {
    this.appearance = this.appearance ?? "fill";
    this.dateFieldLabel = this.dateFieldLabel ?? "Stay Period";
    this.dateFormat = this.dateFormat || "ll";
    this.dateFormatConfig.display.dateInput = this.dateFormat || "ll";
    this.endDateFromStartDate = this.endDateFromStartDate || 1;
    this.maxAvailableBookingMonths = this.appSettingsService.datePickerSettings.maxAvailableBookingMonths;

    const travelType: TravelType = this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);
    this.minDaysToBookInAdvance = this.bootstrapDataService.bootstrapData.min_days_to_book_in_advance[travelType];
    this.minBookingDays = this.appSettingsService.datePickerSettings[travelType].minBookingDays;
    this.maxBookingDays = this.appSettingsService.datePickerSettings[travelType].maxBookingDays;
    this.resetMinMaxDates();
  }

  private initDatepickerForm(): void {
    this.initFromAndToDate();

    this.dateRangeFormGroup = new FormGroup({
      start: new FormControl(this.fromDate),
      end: new FormControl(this.toDate),
    });

    this.dateSingleFormControl = new FormControl(this.fromDate);

    this.emitDateValues(this.fromDate, this.toDate);
  }

  private initFromAndToDate(): void {
    const travelType = this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);
    const hotelSearchForm: HotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    const flightSearchForm: FlightSearchForm = this.globalData.get(GlobalDataEnum.FLIGHT_SEARCH_FORM);
    const carSearchForm: CarSearchForm = this.globalData.get(GlobalDataEnum.CAR_SEARCH_FORM);
    const startDateFromToday = 10;

    if (travelType === TRAVEL_TYPE.HOTELS && hotelSearchForm) {
      this.fromDate = TimeUtils.parseDate(hotelSearchForm.checkInDate);
      this.toDate = TimeUtils.parseDate(hotelSearchForm.checkOutDate);
    } else if (travelType === TRAVEL_TYPE.FLIGHTS && flightSearchForm) {
      this.fromDate = TimeUtils.parseDate(flightSearchForm.departureDate);
      this.toDate = flightSearchForm.returnDate ? TimeUtils.parseDate(flightSearchForm.returnDate) : undefined;
    } else if (travelType === TRAVEL_TYPE.CARS && carSearchForm) {
      this.fromDate = this.isEndDatePicker ?
        TimeUtils.parseDate(carSearchForm.returnDate) : TimeUtils.parseDate(carSearchForm.pickupDate);
    } else {
      this.fromDate = TimeUtils.addDate(startDateFromToday, "day");
      this.toDate = this.getToDate(this.fromDate);
      this.overrideFromAndToDate(startDateFromToday);
    }
  }

  private overrideFromAndToDate(startDateFromToday: number): void {
    if (this.isEndDatePicker) {
      this.fromDate = this.getToDate(TimeUtils.addDate(startDateFromToday, "day"));
      this.minDate = TimeUtils.addDate(startDateFromToday, "day");
    }
  }

  private getToDate(fromDate: Dayjs): Dayjs {
    const toDateOffset: number = Math.max(this.endDateFromStartDate, this.minBookingDays);
    return TimeUtils.addDate(toDateOffset, "day", fromDate);
  }

  private listenLangChange(): void {
    this.localeService.onLocaleChange
      .pipe(untilDestroyed(this))
      .subscribe((newLocale: Locale) => {
        this.dateAdapter.setLocale(newLocale.code);
      });
  }
}
