import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { catchError, map, Observable, of, Subject } from "rxjs";
import { DOCUMENT } from "@angular/common";

import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { UserAgentChecker } from "booking-app-v2/shared/utils";
import { blankStoredPayments, StoredPayments } from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  private status: "IDLE" | "STARTED" = "IDLE";
  private redirectFrame: any;
  private window: Window;
  private renderer: Renderer2;

  constructor(
    private apiDataService: ApiDataService,
    private windowRefService: WindowRefService,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.window = this.windowRefService.nativeWindow;
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  start(gatewayUrl: string): Observable<any> {
    if (this.status !== "IDLE") {
      return;
    } //  only process when status is idle
    this.status = "STARTED";
    const result: Subject<any> = new Subject<any>();
    this.registerEventListener(result);
    this.createIframe(gatewayUrl);
    return result.asObservable();
  }

  getStoredPayments(): Observable<StoredPayments> {
    return this.apiDataService.get("payments").pipe(
      catchError(() => of(blankStoredPayments)),
      map(data => data as StoredPayments),
    );
  }

  removeStoredPayment(nonce: string): Observable<Response> {
    return this.apiDataService.delete(`payments/${nonce}`);
  }

  private createIframe(gatewayUrl: string): void {
    // create iframe surrounding div
    this.redirectFrame = this.renderer.createElement("div");
    this.renderer.addClass(this.redirectFrame, "popup");
    this.renderer.addClass(this.redirectFrame, "payment-redirect");
    if (UserAgentChecker.isIOS()) {
      this.renderer.addClass(this.redirectFrame, "ios");
    }
    // create iframe
    const iframe: any = this.renderer.createElement("iframe");
    this.renderer.addClass(iframe, "iframe-page");
    this.renderer.setProperty(iframe, "scrolling", UserAgentChecker.isIOS() ? "yes" : "auto");
    this.renderer.setProperty(iframe, "frameborder", "0");
    this.renderer.setProperty(iframe, "src", gatewayUrl);
    // insert iframe to surrounding div
    this.renderer.appendChild(this.redirectFrame, iframe);
    // insert iframe together with surrounding div to body
    this.renderer.appendChild(this.document.body, this.redirectFrame);
  }

  private registerEventListener(result: Subject<any>): void {
    const preventNavigation: EventListener = (e: BeforeUnloadEvent): void => {
      // This is just going to display default browser prompt in the modern browser
      // the message entered here means nothing. it will just use the browser locale and display default text
      e.returnValue = "Please do not navigate during payment process.";
    };
    const onMessage: EventListener = (e: MessageEvent): void => {
      if (e.origin && e.origin.indexOf(this.window.location.hostname) === -1) {
        return; // ignore event from different domain
      }
      this.window.removeEventListener("message", onMessage, true);
      this.window.removeEventListener("beforeunload", preventNavigation);
      this.status = "IDLE";
      if (e.data === "success") {
        this.renderer.removeChild(this.document.body, this.redirectFrame);
        result.next(e.data);
      } else {
        result.error(e.data);
      }
    };
    this.window.addEventListener("message", onMessage, true); // capture on bubble
    this.window.addEventListener("beforeunload", preventNavigation);
  }
}
