import { Injectable } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { map, Observable, ReplaySubject, startWith, Subject, take } from "rxjs";

import { BaseStep } from "./base-step";
import { CountryService } from "booking-app-v2/shared/services/country.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import {
  BuildCheckoutFormResult,
  CHECKOUT_RESULT_NAME,
  CheckoutFormData,
  CheckoutFormResult,
  CheckoutFormUserDetails,
  CheckoutResultName,
  CheckoutResults,
  Country,
  GlobalDataEnum,
  PAYMENT,
  PhoneCode,
  TRAVEL_TYPE,
  TravelType,
} from "booking-app-v2/shared/types";
import { autoCompleteValidator, phoneNumberValidator } from "booking-app-v2/shared/validators";

@Injectable({
  providedIn: "root",
})
export class BuildCheckoutFormService implements BaseStep {
  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.BUILD_CHECKOUT_FORM;
  passportRequired: boolean;

  constructor(
    private countryService: CountryService,
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
  ) { }

  process(results: CheckoutResults): BuildCheckoutFormResult {
    const checkoutFormData = results[CHECKOUT_RESULT_NAME.INIT_FORM_DATA];

    const flightAvailabilityCheckResult = results[CHECKOUT_RESULT_NAME.FLIGHT_AVAILABILITY_CHECK];
    if (flightAvailabilityCheckResult?.selectedFlights) {
      this.passportRequired = flightAvailabilityCheckResult?.selectedFlights[0].passport_required;
    }

    const result: Subject<CheckoutFormResult> = new ReplaySubject<CheckoutFormResult>(1);

    checkoutFormData.pipe(take(1)).subscribe((formData: CheckoutFormData) => {
      const checkoutForm: FormGroup = this.initCheckoutForm(formData.checkoutFormUserDetails);
      const filteredPhoneCountriesList$: Observable<PhoneCode[]> = this.buildFilteredPhoneCountriesList(checkoutForm);
      result.next({
        checkoutForm,
        filteredPhoneCountriesList$,
      });
    });
    return result.asObservable();
  }

  private initCheckoutForm(checkoutFormUserDetails: CheckoutFormUserDetails): FormGroup {
    return new FormGroup({
      ...this.initTravelTypeFields(checkoutFormUserDetails),
      ...this.initTitleAndNameFields(checkoutFormUserDetails),
      phoneCode: new FormControl(checkoutFormUserDetails.phoneCode, [
        Validators.required,
        autoCompleteValidator<PhoneCode>(this.countryService.phoneCountriesMap, "text"),
      ]),
      phoneNumber: new FormControl(checkoutFormUserDetails.phoneNumber, [Validators.required, phoneNumberValidator()]),
      userFirstName: new FormControl(checkoutFormUserDetails.userFirstName),
      email: new FormControl(checkoutFormUserDetails.email, [Validators.pattern(/^[^@\s]+@([^@\s]+\.)+[^@\W]+$/)]),
      sendMarketing: new FormControl(false),
      paymentChannel: new FormControl(PAYMENT.CREDIT_CARD, [Validators.required]),
      selectedSavedCard: new FormControl(checkoutFormUserDetails.selectedSavedCard),
      cardName: new FormControl(checkoutFormUserDetails.cardName),
      address1: new FormControl(checkoutFormUserDetails.address1),
      city: new FormControl(checkoutFormUserDetails.city),
      zipCode: new FormControl(checkoutFormUserDetails.zipCode),
      country: new FormControl(checkoutFormUserDetails.country, [
        autoCompleteValidator<Country>(this.countryService.countriesMap, "name"),
      ]),
      state: new FormControl(checkoutFormUserDetails.state),
      saveCreditCard: new FormControl(false),
      termsCheckbox: new FormControl(checkoutFormUserDetails.termsCheckbox, [Validators.required]),
      encryptedCardNumber: new FormControl(checkoutFormUserDetails.encryptedCardNumber),
      encryptedExpiryMonth: new FormControl(checkoutFormUserDetails.encryptedExpiryMonth),
      encryptedExpiryYear: new FormControl(checkoutFormUserDetails.encryptedExpiryYear),
      encryptedSecurityCode: new FormControl(checkoutFormUserDetails.encryptedSecurityCode),
      browserInfo: new FormControl(checkoutFormUserDetails.browserInfo),
      houseNumber: new FormControl(checkoutFormUserDetails.houseNumber),
    });
  }

  private initTravelTypeFields(checkoutFormUserDetails: CheckoutFormUserDetails): Record<string, AbstractControl> {
    const travelType: TravelType = this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);
    switch (travelType) {
      case TRAVEL_TYPE.HOTELS:
        return {
          specialRequests: new FormControl(checkoutFormUserDetails.specialRequests),
          loyaltyTermsCheckbox: new FormControl(
            checkoutFormUserDetails.loyaltyTermsCheckbox,
            this.appSettingsService.showLoyaltyProgramTnC ? [Validators.required] : [],
          ),
        };
      case TRAVEL_TYPE.FLIGHTS:
        // Create form group for extra passengers
        const flightSearchForm = this.globalData.get(GlobalDataEnum.FLIGHT_SEARCH_FORM);
        const extraPassengers = new FormArray([]);

        const adultPassengers = Array(flightSearchForm.adultCount - 1).fill(
          new FormGroup({
            ...this.initTitleAndNameFields(checkoutFormUserDetails, true),
            ...this.initDateOfBirthAndPassportFields(checkoutFormUserDetails, true),
            passengerType: new FormControl("adult"),
          }),
        );

        const childPassengers = Array(flightSearchForm.childCount).fill(
          new FormGroup({
            ...this.initTitleAndNameFields(checkoutFormUserDetails, true),
            ...this.initDateOfBirthAndPassportFields(checkoutFormUserDetails, true),
            passengerType: new FormControl("child"),
          }),
        );

        const infantPassengers = Array(flightSearchForm.infantCount).fill(
          new FormGroup({
            ...this.initTitleAndNameFields(checkoutFormUserDetails, true),
            ...this.initDateOfBirthAndPassportFields(checkoutFormUserDetails, true),
            passengerType: new FormControl("infant"),
          }),
        );

        adultPassengers
          .concat(childPassengers)
          .concat(infantPassengers)
          .map((formGroup: FormGroup) => {
            extraPassengers.push(formGroup);
          });

        // Create form control for checkout disclaimer
        const checkoutDisclaimer = new FormControl(checkoutFormUserDetails.checkoutDisclaimer, [Validators.required]);

        return {
          ...this.initDateOfBirthAndPassportFields(checkoutFormUserDetails),
          passengerType: new FormControl("adult"),
          extraPassengers,
          checkoutDisclaimer,
        };
      case TRAVEL_TYPE.CARS:
      default:
        return {
          ...this.initMembershipFields(checkoutFormUserDetails),
          countryOfResidence: new FormControl(checkoutFormUserDetails.countryOfResidence, [
            autoCompleteValidator<Country>(this.countryService.countriesMap, "name"),
          ]),
          carRentalTermsCheckbox: new FormControl(checkoutFormUserDetails.carRentalTermsCheckbox, [
            Validators.required,
          ]),
          flightNumber: new FormControl(checkoutFormUserDetails.flightNumber, [
            Validators.pattern(/^[a-zA-Z0-9]{2}[0-9]{1,4}$/),
          ]),
        };
    }
  }

  private initTitleAndNameFields(
    checkoutFormUserDetails: CheckoutFormUserDetails,
    blankInit?: boolean,
  ): Record<string, FormControl> {
    return {
      title: new FormControl(blankInit ? "" : checkoutFormUserDetails.title, [Validators.required]),
      firstName: new FormControl(blankInit ? "" : checkoutFormUserDetails.firstName, [
        Validators.required,
        Validators.pattern(/^[A-Za-z ]+$/),
      ]),
      lastName: new FormControl(blankInit ? "" : checkoutFormUserDetails.lastName, [
        Validators.required,
        Validators.pattern(/^[A-Za-z ]+$/),
      ]),
    };
  }

  private initMembershipFields(
    checkoutFormUserDetails: CheckoutFormUserDetails,
  ): Record<string, FormControl> {
    return {
      selectedMembership: new FormControl(checkoutFormUserDetails.selectedMembership),
      memberNo: new FormControl(checkoutFormUserDetails.memberNo),
      memberFirstName: new FormControl(checkoutFormUserDetails.memberFirstName),
      memberLastName: new FormControl(checkoutFormUserDetails.memberLastName),
    };
  }

  private initDateOfBirthAndPassportFields(
    checkoutFormUserDetails: CheckoutFormUserDetails,
    blankInit?: boolean,
  ): Record<string, FormControl> {
    return {
      dateOfBirthDay: new FormControl(blankInit ? undefined : checkoutFormUserDetails.dateOfBirthDay, [
        Validators.required,
      ]),
      dateOfBirthMonth: new FormControl(blankInit ? undefined : checkoutFormUserDetails.dateOfBirthMonth, [
        Validators.required,
      ]),
      dateOfBirthYear: new FormControl(blankInit ? undefined : checkoutFormUserDetails.dateOfBirthYear, [
        Validators.required,
      ]),
      passportNationality: new FormControl(blankInit ? {} : checkoutFormUserDetails.passportNationality, [
        autoCompleteValidator<Country>(this.countryService.countriesMap, "name"),
      ]),
      ...this.initPassportDetailFields(checkoutFormUserDetails, blankInit),
    };
  }

  private initPassportDetailFields(
    checkoutFormUserDetails: CheckoutFormUserDetails,
    blankInit?: boolean,
  ): Record<string, FormControl> {
    if (this.passportRequired) {
      return {
        passportExpiryDay: new FormControl(blankInit ? undefined : checkoutFormUserDetails.passportExpiryDay, [
          Validators.required,
        ]),
        passportExpiryMonth: new FormControl(blankInit ? undefined : checkoutFormUserDetails.passportExpiryMonth, [
          Validators.required,
        ]),
        passportExpiryYear: new FormControl(blankInit ? undefined : checkoutFormUserDetails.passportExpiryYear, [
          Validators.required,
        ]),
        passportNumber: new FormControl(blankInit ? "" : checkoutFormUserDetails.passportNumber, [Validators.required]),
      };
    }
    return {};
  }

  private buildFilteredPhoneCountriesList(checkoutForm: FormGroup): Observable<PhoneCode[]> {
    return checkoutForm.controls.phoneCode.valueChanges.pipe(
      startWith(checkoutForm.controls.phoneCode.value),
      map((phoneCodeControlValue: PhoneCode | string) => {
        phoneCodeControlValue = phoneCodeControlValue ?? "";
        return typeof phoneCodeControlValue === "string" ? phoneCodeControlValue : phoneCodeControlValue.text;
      }),
      map((phoneCodeName: string) => {
        return phoneCodeName
          ? this.countryService.phoneCountriesList.filter((phoneCode: PhoneCode) => {
              return phoneCode.text.toLowerCase().includes(phoneCodeName.toLowerCase());
            })
          : this.countryService.phoneCountriesList.slice();
      }),
    );
  }
}
