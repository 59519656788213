import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { GalleryComponent } from "./gallery.component";
import { CarouselModule } from "booking-app-v2/shared/components/carousel/carousel.module";
import { GalleryDialogModule } from "booking-app-v2/shared/components/dialog/gallery-dialog/gallery-dialog.module";

@NgModule({
  declarations: [ GalleryComponent ],
  imports: [
    SharedModule,
    CarouselModule,
    GalleryDialogModule,
  ],
  exports: [ GalleryComponent ],
  providers: [],
})
export class GalleryModule {}
