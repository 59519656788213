import { Pipe, PipeTransform } from "@angular/core";
import { Hotel } from "booking-app-v2/hotels/models";

@Pipe({
  name: "orderBy",
  pure: false,
})
export class OrderByPipe implements PipeTransform {

  transform(hotels: Hotel[], predicate): Hotel[] {
    return hotels.sort(predicate);
  }
}
