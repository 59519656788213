import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { SharedFooterComponent } from "./shared-footer.component";

@NgModule({
  declarations: [ SharedFooterComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ SharedFooterComponent ],
  providers: [],
})
export class SharedFooterModule {}
