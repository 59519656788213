import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { TimeUtils } from "booking-app-v2/shared/utils";
import { HotelData } from "booking-app-v2/hotels/types";
import { HotelImageUtil } from "../utils";
import { Booking, CancellationPolicy, PointsPartners } from "booking-app-v2/shared/models";
import { PAYMENT, PRODUCT_TYPE } from "booking-app-v2/shared/types";

export class HotelBooking extends Booking {
  readonly cancellation_policy: CancellationPolicy;
  readonly roomType: string;
  readonly zumata_booking_id: string;
  readonly numOfGuests: number;
  readonly hotel_name: string;
  readonly hotel_id: string;
  readonly booking_reference: string;
  readonly hotel_starring: number;
  readonly hotel_address: string;
  readonly hotel_data: HotelData;
  readonly children_count_info: number;
  readonly children_ages: number[];
  readonly number_of_nights: number;
  readonly numOfRooms: number;
  readonly checkInDate: string;
  readonly checkOutDate: string;
  readonly meal_information: string;
  readonly supplier_name: string;
  readonly itinerary_id: string;
  readonly check_in_instructions?: string;
  readonly special_check_in_instructions?: string;
  readonly fees_optional?: string;
  readonly fees_mandatory?: string;
  readonly know_before_you_go?: string;
  readonly room_remarks?: string;
  readonly cloudflare_image_url?: string;
  readonly thumbImageStyle: { [klass: string]: string; };
  readonly defaultHotelBgImageStyle: { [klass: string]: string; };

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    booking: HotelBooking,
    pointsPartners: PointsPartners,
  ) {
    super(
      appSettingsService,
      globalData,
      booking,
      pointsPartners,
    );

    this.cancellation_policy = new CancellationPolicy(appSettingsService, globalData, booking.cancellation_policy);
    this.roomType = booking.roomType;
    this.zumata_booking_id = booking.zumata_booking_id;
    this.numOfGuests = booking.numOfGuests;
    this.hotel_name = booking.hotel_name;
    this.hotel_id = booking.hotel_id;
    this.booking_reference = booking.booking_reference;
    this.hotel_starring = booking.hotel_starring;
    this.hotel_address = booking.hotel_address;
    this.hotel_data = booking.hotel_data;
    this.children_count_info = booking.children_count_info;
    this.children_ages = booking.children_ages;
    this.number_of_nights = booking.number_of_nights;
    this.numOfRooms = booking.numOfRooms;
    this.checkInDate = booking.checkInDate;
    this.checkOutDate = booking.checkOutDate;
    this.check_in_instructions = booking.check_in_instructions;
    this.special_check_in_instructions = booking.special_check_in_instructions;
    this.fees_optional = booking.fees_optional;
    this.fees_mandatory = booking.fees_mandatory;
    this.know_before_you_go = booking.know_before_you_go;
    this.meal_information = booking.meal_information;
    this.cloudflare_image_url = booking.cloudflare_image_url;
    this.itinerary_id = booking.itinerary_id;
    this.supplier_name = booking.supplier_name;
    this.room_remarks = booking.room_remarks;
    this.thumbImageStyle = HotelImageUtil.getThumbImageStyle(this);
    this.defaultHotelBgImageStyle = HotelImageUtil.getDefaultHotelBgImageStyle();
  }

  nonCancellable(): boolean {
    return super.nonCancellable() ||
      this.payment_type === PAYMENT.FREE_NIGHT_VOUCHER;
  }

  showCancelBooking(): boolean {
    return super.showCancelBooking() &&
      this.product_type !== PRODUCT_TYPE.COMPLIMENTARY_NIGHTS &&
      !this.nonCancellable() &&
      TimeUtils.getDayOffset(this.checkOutDate) > 0;
  }

  formattedCheckInDate(format: string = "LL"): string {
    return TimeUtils.format(this.checkInDate, format, "YYYY-MM-DD");
  }

  formattedCheckOutDate(format: string = "LL"): string {
    return TimeUtils.format(this.checkOutDate, format, "YYYY-MM-DD");
  }
}
