import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  TrackByFunction,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, ParamMap, Router, UrlTree } from "@angular/router";
import { interval, Observable, Subscription, take } from "rxjs";

import {
  HotelPricesResponse,
  HotelRawPrice,
} from "booking-app-v2/hotels/types";
import { Hotel, Hotels, HotelSearchForm } from "booking-app-v2/hotels/models";
import {
  COMPLEX_DIALOG,
  GlobalDataEnum,
  MAPBOX_STATE_VIEW_MODE,
  PRODUCT_TYPE,
  TRAVEL_TYPE,
} from "booking-app-v2/shared/types";
import {
  Currency,
  LandingPage,
  PointsPartner,
} from "booking-app-v2/shared/models";

import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { HotelUtilService, MapboxService, ResultsService } from "../services";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import { HotelsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/hotels-url-builder.service";

import { FilterUtil } from "booking-app-v2/hotels/utils";
import { ScrollUtil, SearchSortingUtil } from "booking-app-v2/shared/utils";
import {
  HotelEditSearchDialogComponent,
} from "booking-app-v2/shared/components/dialog/hotel-edit-search-dialog/hotel-edit-search-dialog.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  templateUrl: `/html/hotels/result_v2`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsPageComponent implements OnInit {
  hotels: Hotels;
  hotelRawPrices: HotelRawPrice[] = [];
  pollCounter: number = 0;
  hotelPricePoll: ReturnType<typeof setTimeout> = null;
  searchCount: number = 0;
  pollHotelPricesResultsFailureCount: number = 0;
  showAdminData: boolean = false;
  progressBarText: string;
  loadingSubtext: string = "";
  hotelSearchForm: HotelSearchForm;
  landingPage: LandingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
  pointsPartner: PointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
  showRedeemPointsSlider: boolean;
  hideRedemptionSliderOnFullCash: boolean;
  showCashSlider: boolean;
  hasPartialResults: boolean;

  @ViewChild("hotelDetailTooltip") hotelDetailTooltipElem: ElementRef;
  @ViewChild("hotelDetailPopup") hotelDetailPopupElem: ElementRef;

  private progressBarSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private hotelResultsService: ResultsService,
    private hotelUtilService: HotelUtilService,
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private pointsCashShareService: PointsCashShareService,
    private mapboxService: MapboxService,
    private windowRefService: WindowRefService,
    private dialogService: DialogService,
    private currenciesService: CurrenciesService,
    private cdRef: ChangeDetectorRef,
    private pageDiscoveryService: PageDiscoveryService,
    private hotelsUrlBuilderService: HotelsUrlBuilderService,
  ) {}

  trackByHotelId: TrackByFunction<Hotel> = (index: number, hotel: Hotel) => hotel.id;

  ngOnInit(): void {
    FilterUtil.init(this.globalData, this.appSettingsService);
    SearchSortingUtil.initializeOptions(this.globalData);
    this.initCurrencyListener();
    this.initRouteData();
    this.initProgressBar();
    this.pollHotelPricesResults();
    this.initPointsCashShareSubscription();
    this.initWindowWidthSubscription();
    this.initMapboxHoveredHotelSubscription();
    this.setLoadingSubtext();
    this.showRedeemPointsSlider = this.hasRedeemPointsSlider();
    this.showCashSlider = this.hasCashSlider();
  }

  @HostListener("window:scroll")
  onScroll(): void {
    if (ScrollUtil.isNearBottomOfPage()) {
      this.hotels.pushMoreVisibleHotels();
    }
  }

  openEditSearchDialog(): void {
    this.dialogService.open(COMPLEX_DIALOG.HOTEL_EDIT_SEARCH, HotelEditSearchDialogComponent);
  }

  getHotelUrl(hotel: Hotel): string {
    const searchUrl: UrlTree = this.router.createUrlTree(
      [`/hotels/detail/${hotel.id}`],
      {
        queryParams: this.hotelsUrlBuilderService.buildDetailsPageUrl(),
      },
    );

    return searchUrl.toString();
  }

  get sortOptionLabel(): string {
    return SearchSortingUtil.options[SearchSortingUtil.current].label;
  }

  isSelectedHotel(hotel): boolean {
    return hotel?.id === this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM).destination.value.hotel_id;
  }

  showHotelNoResultsView(): boolean {
    return this.hotels.counters.displayed === 0 &&
      !this.globalData.get(GlobalDataEnum.IS_NEW_SEARCH) &&
      this.hotelSearchForm.searchDatesValid &&
      this.mapboxService.viewMode === MAPBOX_STATE_VIEW_MODE.List;
  }

  private hasRedeemPointsSlider(): boolean {
    return (this.landingPage.isComplimentaryNights || this.landingPage.isPayWithPoints) &&
      this.hotelSearchForm.searchDatesValid &&
      !this.appSettingsService.hideRedemptionRangeSlider;
  }

  private shouldHideRedemptionSliderOnFullCash(): void {
    this.hideRedemptionSliderOnFullCash = this.pointsCashShareService.pointsCashShare.value ===
      this.pointsCashShareService.pointsCashShare.sliderOptions.ceil;
  }

  private hasCashSlider(): boolean {
    const productTypeHasCashSlider: boolean = !(
      this.landingPage.hasProductType(PRODUCT_TYPE.REDEEM) ||
      this.landingPage.hasProductType(PRODUCT_TYPE.VOUCHER) ||
      this.landingPage.hasProductType(PRODUCT_TYPE.COMPLIMENTARY_NIGHTS)
    );
    return productTypeHasCashSlider &&
      this.hotelSearchForm.searchDatesValid &&
      this.appSettingsService.showCashSlider;
  }

  private setLoadingSubtext(): void {
    if (this.appSettingsService.customResultLoadingMessage) {
      this.loadingSubtext = this.appSettingsService.customResultLoadingMessage;
    } else if (this.globalData.get(GlobalDataEnum.LANDING_PAGE).earnMiles()) {
      this.loadingSubtext = "txt.search_result.loading.subtext.incredible_rewards";
    } else {
      this.loadingSubtext = "txt.search_result.loading.subtext.best_deals";
    }
  }

  private pollHotelPricesResults(): void {
    if (this.hotelSearchForm.searchDatesValid) {
      this.setResultPollingFlag(true);
      this.hotelResultsService.getPrices().subscribe(
        (hotelPricesResponse: HotelPricesResponse) => this.getPricesSuccess(hotelPricesResponse),
        (error) => this.getPricesError(),
      );
    } else {
      this.hotels.searchCompleted = false;
      this.cdRef.markForCheck();
    }
  }

  private getPricesSuccess(hotelPricesResponse: HotelPricesResponse): void {
    this.hotelRawPrices = hotelPricesResponse.hotelRawPrices;
    this.hotels.searchCompleted = hotelPricesResponse.completed;
    if (!hotelPricesResponse.completed) {
      if (this.hotelRawPrices?.length > 0) {
        this.runInitialFilteringProcess();
        this.hasPartialResults = true;
        // scrollToTopOnMobile()
      }
      this.hotelPricePoll = setTimeout(() => {
        this.pollHotelPricesResults();
      }, this.getPollTime());
      this.searchCount += 2;
    } else {
      this.progressBarSubscription.unsubscribe();
      this.runInitialFilteringProcess();
      this.globalData.set(GlobalDataEnum.IS_NEW_SEARCH, false);
      this.setResultPollingFlag(false);
      this.hasPartialResults = false;
      this.hotels.countAllHotels();
      // angular-v2-todo init the map markers with all hotel data
      // $timeout ()->
      // MapboxService.loadAddressPoints(allHotels)
      // , 1

      // angular-v2-todo : complete following implementation when the rest of the results page is implemented
      setTimeout(() => {
        // updateProductPriceSlider(),
        // FilterUtil.reviews.update_slider()
        // scrollToTopOnMobile()
        // $scope.$broadcast('refreshSlider')
        this.initMapView();
      }, 50);
    }
    this.cdRef.markForCheck();
  }

  private getPricesError(): void {
    this.pollHotelPricesResultsFailureCount++;
    if (this.pollHotelPricesResultsFailureCount <= 5) {
      this.hotelPricePoll = setTimeout(() => {
        this.pollHotelPricesResults();
      }, this.getPollTime());
    } else {
      this.setResultPollingFlag(false);
      this.cdRef.markForCheck();
    }
  }

  private getPollTime(): number {
    const pollTimeoutTime = this.pollCounter < 3 ? 1000 : 5000;
    this.pollCounter++;
    return pollTimeoutTime;
  }

  private setResultPollingFlag(flag: boolean): void {
    this.globalData.set(GlobalDataEnum.HOTEL_RESULT_STILL_POLLING, flag);
  }

  private initRouteData(): void {
    this.activatedRoute.data.subscribe((data: { hotels: Hotel[] }) => {
      this.hotels =
        new Hotels(
          this.appSettingsService,
          this.globalData,
          this.cdRef,
          data.hotels,
          this.hotelUtilService,
      );
    });

    this.hotelUtilService.setHotelSearchFormFromQueryParams(
      this.hotelsUrlBuilderService.buildResultsPageDataFromQueryParams(
        this.activatedRoute.snapshot.queryParams,
      ),
    );

    this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
      const pointsCashShare: number = parseInt(params.get("pointsCashShare"), 10);
      if (!Number.isNaN(pointsCashShare)) {
        this.pointsCashShareService.pointsCashShare.value = pointsCashShare;
      }
    });

    this.hotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
  }

  private initProgressBar(counterCap: number = 50): void {
    const progressBarCounter$: Observable<number> = interval(1000);
    const countDown$: Observable<number> = progressBarCounter$.pipe(take(counterCap));
    this.progressBarText = "progressbar.text1";
    this.progressBarSubscription = countDown$.subscribe(counter => {
      if (counter === 30) {
        this.progressBarText = "progressbar.text2";
      }
      if (counter === 40) {
        this.progressBarText = "Just a few more seconds now.";
      }
      if (counter >= counterCap) {
        this.progressBarSubscription.unsubscribe();
      }
    });
  }

  private initMapView(): void {
    this.mapboxService.loadAddressPoints(this.hotels.filteredList);
    this.mapboxService.setViewMode(MAPBOX_STATE_VIEW_MODE.List);
    this.mapboxService.setPopupTooltipElements(this.hotelDetailPopupElem, this.hotelDetailTooltipElem);
  }

  private runInitialFilteringProcess() {
    this.hotels.preFilter(this.hotelRawPrices);
    this.hotels.filter();
  }

  private initWindowWidthSubscription(): void {
    this.windowRefService.desktopBreakpoint$.subscribe((isDesktop: boolean) => {
      if (!isDesktop && this.mapboxService.viewMode === MAPBOX_STATE_VIEW_MODE.Map) {
        this.mapboxService.toggleViewMode();
      }
    });
  }

  private initMapboxHoveredHotelSubscription(): void {
    this.mapboxService.hoveredHotelChanged
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.cdRef.markForCheck();
      });
  }

  private initPointsCashShareSubscription(): void {
    this.pointsCashShareService.getSliderUpdate()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.runInitialFilteringProcess();
        this.shouldHideRedemptionSliderOnFullCash();
        SearchSortingUtil.updateSortingOptionsForPayWithPoints(TRAVEL_TYPE.HOTELS);
        this.hotels.filterForm.initSortDropdown();
      });
  }

  private initCurrencyListener(): void {
    this.currenciesService.onCurrencyChange
      .pipe(untilDestroyed(this))
      .subscribe((newCurrency: Currency) => {
        if (
          !this.globalData
            .get(GlobalDataEnum.LANDING_PAGE)
            .complimentaryOrRedeem()
        ) {
          this.pollHotelPricesResults();
        } else {
          this.runInitialFilteringProcess();
        }
      });
  }
}
