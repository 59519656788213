import { ObjectValues } from "./object-values.type";

export const LINK_TARGET = {
  BLANK: "_blank",
  SELF: "_self",
} as const;

export type LinkTarget = ObjectValues<typeof LINK_TARGET>;

export interface OpenInTargetTabType {
  allLinksTarget: LinkTarget; // convert all links into desired target, default is unchanged
  checkoutInNewTab: boolean; // open checkout page from hotel detail, default is false
  markerPopUp: LinkTarget; // Specific for hotel details popup target link
}
