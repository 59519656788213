import { FilterForm, Flight } from "booking-app-v2/flights/models";
import {
  AirlineFilterUnit,
  FlightSegment,
  flightsSegmentId,
  SelectionStage,
  SELECTION_STAGE,
  TimeRange,
} from "booking-app-v2/flights/types";
import { FilterUtil } from "booking-app-v2/flights/utils";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { Base } from "booking-app-v2/shared/models";
import { GlobalDataEnum, PRODUCT_TYPE, RedeemPoints } from "booking-app-v2/shared/types";
import { SearchSortingUtil, SortUtil, TimeUtils } from "booking-app-v2/shared/utils";

interface FlightsHash {
  [flightId: string]: Flight;
}

export class Flights extends Base {
  rawFlights: Flight[]; // Raw flights, contain segments from both selection stage
  unfilteredFlights: Flight[]; // Flights built depending on selection stage
  displayedFlights: Flight[]; // Flights after filtering, sorting and pagination
  totalDisplayedFlights: Flight[]; // Flights after filtering and sorting
  filterForm: FilterForm;
  selectedOriginFlight: Flight;
  selectedReturnFlight: Flight;
  selectionStage: SelectionStage;
  resultsLimit: number; // for lazy loading

  private isRedeem: boolean;

  readonly DEFAULT_RESULT_COUNT: number = 8;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    flights: Flight[],
    selectedOriginFlight?: Flight,
  ) {
    super(appSettingsService, globalData);
    this.isRedeem = this.globalData.get(GlobalDataEnum.LANDING_PAGE).hasProductType(PRODUCT_TYPE.REDEEM);

    this.selectionStage = selectedOriginFlight ? SELECTION_STAGE.RETURN : SELECTION_STAGE.ORIGIN;
    this.selectedOriginFlight = selectedOriginFlight;
    this.resultsLimit = this.DEFAULT_RESULT_COUNT;
    this.rawFlights = flights;
    this.displayedFlights = [];
    this.totalDisplayedFlights = [];
    this.unfilteredFlights = this.buildFlights(flights);

    this.initFilterForm();
  }

  updateResultsLimit(resultsLimit?: number): void {
    this.resultsLimit = resultsLimit ?? this.DEFAULT_RESULT_COUNT;
  }

  filter(): void {
    const updatedFlights = FilterUtil.filterFlightsWithFilterForm(
      this.rawFlights,
      this.filterForm,
      this.selectedOriginFlight,
    );
    this.unfilteredFlights = updatedFlights.unfilteredFlights;
    this.totalDisplayedFlights = updatedFlights.totalDisplayedFlights.sort(SearchSortingUtil.currentSortFunction);
    this.displayedFlights = this.totalDisplayedFlights.slice(0, this.resultsLimit);
  }

  resetFilters(): void {
    this.initFilterForm();
    this.filter();
    this.resetAirlineFilters();
    this.updateRedeemPointsFilter();
  }

  filterByStopover(stopoverFilterUnitId: string, checkedStatus: boolean): void {
    this.filterForm.stopoverFilterUnits
      .find(stopoverFilter => stopoverFilter.id === stopoverFilterUnitId).isSelected = checkedStatus;
    this.filter();
  }

  filterByAirline(airlineFilterUnitId: string, checkedStatus: boolean): void {
    this.filterForm.airlineFilterUnits
      .find(airlineFilter => airlineFilter.id === airlineFilterUnitId).isSelected = checkedStatus;
    this.filter();
  }

  filterByDepartureRange(timeRange: TimeRange): void {
    this.filterForm.departureRangeFilter = timeRange;
    this.filter();
  }

  filterByArrivalRange(timeRange: TimeRange): void {
    this.filterForm.arrivalRangeFilter = timeRange;
    this.filter();
  }

  filterByRedeemPoints(redeemPoints: RedeemPoints): void {
    this.filterForm.redeemPoints = redeemPoints;
    this.filter();
  }

  resetAirlineFilters(): void {
    // Hash of airline code as key and airline name as value
    const airlineNames: { [airline_code: string]: string } = {};

    this.unfilteredFlights.forEach((flight: Flight) => {
      flight.segments.forEach((segment: FlightSegment) => {
        airlineNames[segment.marketing_airline_code] = segment.marketing_airline_name;
      });
    });

    const airlinesList: AirlineFilterUnit[] = Object.keys(airlineNames).map((key) => {
      return {
        isSelected: false,
        label: airlineNames[key],
        id: key,
      };
    });

    this.filterForm.airlineFilterUnits =  SortUtil.string(airlinesList, "label");
  }

  updateRedeemPointsFilter(): void {
    if (this.isRedeem) {
      this.unfilteredFlights.map((flight: Flight) => {
        flight.priceInfo.points_payment = flight.priceInfo.payWithPointsCashService.pointsToPay(
          flight.priceInfo.base_points_payment,
        );
      });
      this.setRedeemPointsSliderValues();
    }
  }

  private initFilterForm(): void {
    this.filterForm = new FilterForm(
      this.appSettingsService,
      this.globalData,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
  }

  private buildFlights(flights: Flight[]): Flight[] {
    if (this.selectionStage === SELECTION_STAGE.ORIGIN) {
      return this.buildOriginFlights(flights);
    } else if (this.selectionStage === SELECTION_STAGE.RETURN) {
      return this.buildReturnFlights(flights, this.selectedOriginFlight);
    }
  }

  private buildOriginFlights(flights: Flight[]): Flight[] {
    // remove itineraries that have duplicate first flights, display only the minimum price
    const nonDuplicateFlightsHash = this.removeDuplicateFlights(flights);

    // create flight items that only contains origin segments
    return this.filterFlightsBasedOnSegmentType(nonDuplicateFlightsHash);
  }

  private buildReturnFlights(flights: Flight[], selectedOriginFlight: Flight): Flight[] {
    // use data from selectedOriginFlight to filter only itineraries that have same origin flight
    const matchingOriginFlights = flights.filter((flight: Flight) => {
      return this.getFlightId(flight, SELECTION_STAGE.ORIGIN) === selectedOriginFlight.id;
    });

    const nonDuplicateFlightsHash = this.removeDuplicateFlights(matchingOriginFlights);

    // create flights that only contains return segments
    return this.filterFlightsBasedOnSegmentType(nonDuplicateFlightsHash);
  }

  private getFlightId(flight: Flight, selectionStage: SelectionStage): string {
    const segments = flight.segments.filter(segment => segment.segment_type === selectionStage);
    return segments.reduce((identifier, segment) => {
      return `${identifier}${flightsSegmentId(segment)}`;
    }, "");
  }

  private removeDuplicateFlights(flights: Flight[]): FlightsHash {
    const flightsHash: FlightsHash = {};
    flights.forEach((flight: Flight) => {
      const flightId = this.getFlightId(flight, this.selectionStage);
      if (
        !flightsHash[flightId] ||
        (flightsHash[flightId]?.priceInfo.max_points_payment > flight.priceInfo.max_points_payment)
      ) {
        flightsHash[flightId] = Object.assign({}, flight);
      }
    });
    return flightsHash;
  }

  private filterFlightsBasedOnSegmentType(flightsHash: FlightsHash): Flight[] {
    return Object.entries(flightsHash).map(([flightId, flight]) => {
      flight.segments = flight.segments.filter(
        segment => segment.segment_type === this.selectionStage,
      );
      flight.id = flightId;
      flight.duration = this.getFlightDuration(flight.segments);
      flight.departureTimeInMinutes = TimeUtils.formatTimeToMinutes(flight.segments[0].departure_time);
      flight.arrivalTimeInMinutes = TimeUtils.formatTimeToMinutes(
        flight.segments[flight.segments.length - 1].arrival_time);
      return flight;
    });
  }

  private getFlightDuration(segments: FlightSegment[]): number {
    let travelDuration = 0;
    let stopOverDuration = 0;

    for (let i = 0; i < segments.length; i++) {
      if (i < segments.length - 1) {
        stopOverDuration += TimeUtils.getTimeDuration(
          segments[i + 1].departure_time,
          segments[i].arrival_time,
        ).asMilliseconds();
      }

      if (typeof segments[i].duration === "string") {
        travelDuration += TimeUtils.getDuration(segments[i].duration).asMilliseconds();
      } else {
        travelDuration += TimeUtils.getDuration(segments[i].duration, "minutes").asMilliseconds();
      }
    }

    const duration = TimeUtils.getDuration(travelDuration).add({ milliseconds: stopOverDuration });
    return duration.asMilliseconds();
  }

  private setRedeemPointsSliderValues(): void {
    let pointsPayment: number;
    const redeemPointsRange: RedeemPoints = { min: 0, max: 0, ceil: 0, floor: 0 };
    this.unfilteredFlights.forEach((flight: Flight, index: number) => {
      pointsPayment = flight.priceInfo.points_payment;
      if (pointsPayment) {
        if (redeemPointsRange.max < pointsPayment || index === 0) {
          redeemPointsRange.max = pointsPayment;
          redeemPointsRange.ceil = pointsPayment;
        }
        if (redeemPointsRange.min > pointsPayment || index === 0) {
          redeemPointsRange.min = pointsPayment;
          redeemPointsRange.floor = pointsPayment;
        }
      }
    });
    this.filterForm.redeemPoints = redeemPointsRange;
  }
}
