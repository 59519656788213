import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { TrustYouService } from "booking-app-v2/shared/services/trust-you.service";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { BottomSheetService } from "booking-app-v2/shared/services/bottom-sheet.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { COMPLEX_DIALOG, CURRENT_PAGE } from "booking-app-v2/shared/types";
import { Hotel, TrustYou } from "booking-app-v2/hotels/models";
import { TrustYouReviews } from "booking-app-v2/hotels/types";
import { TrustYouDialogComponent } from "booking-app-v2/shared/components/dialog/trust-you-dialog/trust-you-dialog.component";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "trust-you",
  templateUrl: "/html/components/trust_you_v2",
})
export class TrustYouComponent implements OnChanges {
  @Input() hotel: Hotel;
  @Input() viewFullReview: boolean;
  @Input() showReviewBlock: boolean;
  @Output() scrollToReview: EventEmitter<void> = new EventEmitter();
  @ViewChild("trustYouBottomSheet") trustYouBottomSheetTemplate: TemplateRef<any>;

  trustyou: TrustYou;
  isHotelResultsPage: boolean;
  trustscoreAriaLabel: string;

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private trustYouService: TrustYouService,
    private dialogService: DialogService,
    private bottomSheetService: BottomSheetService,
    private pageDiscoveryService: PageDiscoveryService,
    private translateService: TranslateService,
  ) {
    this.isHotelResultsPage = this.pageDiscoveryService.currentPage() === CURRENT_PAGE.SEARCH_RESULT;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hotel.currentValue?.id !== changes.hotel.previousValue?.id) {
      const hotelName: string = changes.hotel.currentValue.name;
      const rawTrustYou: TrustYou = changes.hotel.currentValue.trustyou;

      this.trustyou = new TrustYou(
        this.appSettingsService,
        this.globalData,
        this.trustYouService,
        rawTrustYou,
        hotelName,
      );
      this.getTrustYouReviews();
      this.trustscoreAriaLabel = [
        this.translateService.instant(this.trustyou.ui.category),
        "review score",
        this.trustyou.kaligoOverallScore,
      ].join(" ");
    }
  }

  openDesktopReviewDialog(event): void {
    event.stopPropagation();
    event.preventDefault();
    this.trackEvent();

    this.getTrustYouReviews();
    this.dialogService.open(COMPLEX_DIALOG.TRUSTYOU, TrustYouDialogComponent, {
      trustyou: this.trustyou,
    });
  }

  openMobileReviewBottomSheet(event): void {
    event.stopPropagation();
    event.preventDefault();
    this.trackEvent();

    this.getTrustYouReviews();
    this.bottomSheetService.open(this.trustYouBottomSheetTemplate, ["trust-you-bottom-sheet"]);
  }

  private trackEvent(): void {
    // angular-v2-todo $analytics tracking implementation
    // this.$analytics.eventTrack("hotel-review", {
    //   category: "click",
    //   label: `${this.internalHotel.id}-${this.internalHotel.name}`,
    // });
  }

  private getTrustYouReviews(): void {
    this.trustYouService.getHotelReview(this.hotel.id).subscribe({
      next: (reviews: TrustYouReviews) => this.trustyou.initTrustYouReviewsSuccess(reviews),
      error: () => this.trustyou.initTrustYouReviewsFailed(),
    });
  }
}
