import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";

export abstract class Base {
  readonly appSettingsService: AppSettingsService;
  readonly globalData: GlobalData;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
  ) {
    this.appSettingsService = appSettingsService;
    this.globalData = globalData;
  }
}
