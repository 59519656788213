import { Component, Input, OnChanges } from "@angular/core";
import { map } from "rxjs";

import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { ExclusiveBenefit, ExclusiveBenefitResponse } from "booking-app-v2/shared/types";

@Component({
  selector: "exclusive-benefits",
  template: `
    <div class="exclusive-benefits" *ngIf="exclusiveBenefits?.length > 0">
      <div class="title-container">
        <div class="header" translate='wl.visa.visa_exclusive'></div>
        <div class="sub-header" translate='wl.visa.check_out_latest_deals_near_your_hotel'></div>
      </div>
      <div class="card-container">
        <div class="wrapper">
          <ng-container *ngFor="let benefit of exclusiveBenefits">
            <exclusive-card [benefit]="benefit"></exclusive-card>
          </ng-container>
        </div>
      </div>
    </div>
  `,
})
export class ExclusiveBenefitsComponent implements OnChanges {
  @Input() kaligoReference: string;

  exclusiveBenefits: ExclusiveBenefit[];

  constructor(
    private apiDataService: ApiDataService,
  ) { }

  ngOnChanges(): void {
    this.getExclusiveBenefits();
  }

  getExclusiveBenefits(): void {
    if (this.kaligoReference) {
      this.apiDataService.get(`offers/${this.kaligoReference}`)
        .pipe(map((response: ExclusiveBenefitResponse) => {
          return response.offers;
        }))
        .subscribe((response: ExclusiveBenefit[]) => {
          this.exclusiveBenefits = response;
        });
    }
  }
}
