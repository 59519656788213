import { Injectable } from "@angular/core";
import { take } from "rxjs";

import { BaseStep } from "./base-step";
import { Room } from "booking-app-v2/hotels/models";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  FetchPriceResult,
  GlobalDataEnum,
  PointsBalanceCheckResult,
  TRAVEL_TYPE,
} from "booking-app-v2/shared/types";
import { PointsAdjustmentService } from "booking-app-v2/shared/services/points-adjustment.service";
import { GlobalData } from "../../global-data.service";
import { PayWithPointsCashService } from "../../pay-with-points-cash.service";
import { FlightRawResponse } from "booking-app-v2/flights/types";

@Injectable({
  providedIn: "root",
})
export class PointsBalanceCheckService implements BaseStep {
  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.POINTS_BALANCE_CHECK;

  constructor(
    private globalData: GlobalData,
    private pointsAdjustmentService: PointsAdjustmentService,
    private payWithPointsCashService: PayWithPointsCashService,
  ) {}

  process(results: CheckoutResults): PointsBalanceCheckResult {
    const travelType = this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);
    switch (travelType) {
      case TRAVEL_TYPE.HOTELS:
        (results[CHECKOUT_RESULT_NAME.FETCH_PRICE] as FetchPriceResult).pipe(take(1)).subscribe((room: Room) => {
          this.pointsAdjustmentService.showInsufficientPointBalance(
            room.priceInfo.points_payment,
            room.priceInfo.minPoint,
          );
        });
        break;
      case TRAVEL_TYPE.FLIGHTS:
        const {
          selectedFlights,
        } = results[CHECKOUT_RESULT_NAME.FLIGHT_AVAILABILITY_CHECK];
        const response = results[CHECKOUT_RESULT_NAME.FLIGHT_FETCH_PRICE];

        this.pointsAdjustmentService.showInsufficientPointBalance(
          selectedFlights[0].priceInfo.points_payment,
          this.getMinPointsPayment(response.itineraries[0]),
        );
        break;
      case TRAVEL_TYPE.CARS:
        const car = results[CHECKOUT_RESULT_NAME.CAR_AVAILABILITY_CHECK];
        this.pointsAdjustmentService.showInsufficientPointBalance(
          car.priceInfo.points_payment,
          car.priceInfo.minPoint,
        );
        break;
      default:
        break;
    }
  }

  getMinPointsPayment(flightRawResponse: FlightRawResponse): number {
    return this.payWithPointsCashService.minPoint(flightRawResponse);
  }
}
