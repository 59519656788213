import { ObjectValues } from "./object-values.type";

export const THEME = {
  BOUNTY: "Bounty",
  NEXUS: "Nexus",
  ANZ: "Anz",
  // If no theming, the theme will be empty
  NONE: "",
} as const;

export type Theme = ObjectValues<typeof THEME>;
