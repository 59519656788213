import { InjectionToken } from "@angular/core";
import Rollbar from "rollbar";

const rollbarConfig: Rollbar.Configuration = {
  accessToken: "ee3dd83a16454861b33e05486b964faf",
  captureUncaught: true,
  captureUnhandledRejections: false,
  payload: { environment: window.KaligoConfig.environment },
  enabled: window.KaligoConfig.environment !== "development",
};

export const RollbarService = new InjectionToken<Rollbar>("rollbar");

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
