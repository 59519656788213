import { Injectable } from "@angular/core";

import { BaseStep } from "./base-step";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  RedirectToDetailsResult,
} from "booking-app-v2/shared/types";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { CheckoutError } from "booking-app-v2/shared/models";
import { HotelsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/hotels-url-builder.service";

@Injectable({
  providedIn: "root",
})
export class HotelRedirectToDetailsService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.REDIRECT_TO_DETAILS;

  constructor(
    private windowRefService: WindowRefService,
    private hotelsUrlBuilderService: HotelsUrlBuilderService,
  ) { }

  process(results: CheckoutResults): RedirectToDetailsResult {
    if (!(results[CHECKOUT_RESULT_NAME.LOGIN_CHECK])) {
      this.trackRedirect();
      this.hotelsUrlBuilderService.redirectToHotelDetailsPage(
        this.windowRefService.getURIParamFromWindow(),
      );
      throw new CheckoutError("Unauthorised to visit checkout page without login");
    }
  }

  // angular-v2-todo: we need to implement tracker service to implement this.
  trackRedirect(): void {

  }
}
