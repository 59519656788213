import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { GalleryModule } from "booking-app-v2/shared/components/gallery/gallery.module";
import { RoomDetailsDialogContentComponent } from "./room-details-dialog-content.component";

@NgModule({
  declarations: [ RoomDetailsDialogContentComponent ],
  imports: [
    SharedModule,
    GalleryModule,
  ],
  exports: [ RoomDetailsDialogContentComponent ],
  providers: [],
})
export class RoomDetailsDialogContentModule { }
