import { Injectable, TemplateRef } from "@angular/core";
import { MatBottomSheet, MatBottomSheetRef } from "@angular/material/bottom-sheet";

import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class BottomSheetService {

  private bottomSheetRef: MatBottomSheetRef;

  constructor(
    private bottomSheet: MatBottomSheet,
    private windowRefService: WindowRefService,
  ) {
    this.registerCloseBottomSheetOnWidthChange();
  }

  open(template: TemplateRef<any>, classList: string[] = []): void {
    this.close();
    this.bottomSheetRef = this.bottomSheet.open(template, {
      panelClass: ["full-screen-bottom-sheet", ...classList],
      disableClose: true,
    });
  }

  close(): void {
    this.bottomSheetRef?.dismiss();
  }

  private registerCloseBottomSheetOnWidthChange(): void {
    this.windowRefService.phoneBreakpoint$.pipe(untilDestroyed(this))
      .subscribe((shrinkingToPhone: boolean) => {
        if (!shrinkingToPhone) {
          this.close();
        }
      });
  }

}
