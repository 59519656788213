import { ObjectValues } from "./object-values.type";

export const PRODUCT_TYPE = {
  EARN: "earn",
  REDEEM: "redeem",
  VOUCHER: "voucher",
  CASHVOUCHER: "cash-voucher",
  COMPLIMENTARY_NIGHTS: "complimentary-nights",
} as const;

export type ProductType = ObjectValues<typeof PRODUCT_TYPE>;
