import { ValidationErrors } from "@angular/forms";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, of, Subject } from "rxjs";

import {
  ApiDataError,
  GlobalDataEnum,
  Voucher,
} from "booking-app-v2/shared/types";
import { LandingPage, PointsPartner } from "booking-app-v2/shared/models";
import { Room } from "booking-app-v2/hotels/models";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { BookingErrorsMapperService } from "booking-app-v2/shared/services/booking-errors-mapper.service";
import { Car } from "booking-app-v2/cars/models";

@Injectable({
  providedIn: "root",
})
export class VoucherService {
  vouchers: Voucher[] = [];
  readonly triggerRebuildPriceInfo: Subject<void> = new Subject();

  private readonly landingPage: LandingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
  private readonly pointsPartner: PointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);

  constructor(
    private globalData: GlobalData,
    private apiDataService: ApiDataService,
    private bookingErrorsMapperService: BookingErrorsMapperService,
  ) { }

  validateVoucherCode(travelItem: Room | Car, voucherCode: string): Observable<ValidationErrors | null> {
    const voucherEndpointUrl = `landing_pages/${this.landingPage.url}/vouchers/${voucherCode}`;
    const voucherQueryParams = {
      price: travelItem.priceInfo.leftToPay,
      partnerId: this.pointsPartner.id,
      voucher_type_id: travelItem.priceInfo.voucher_type_id?.toString() || "",
    };

    return this.apiDataService.get(voucherEndpointUrl, voucherQueryParams)
      .pipe(
        catchError((err: ApiDataError) => {
          return of(this.bookingErrorsMapperService.map(err.errors[0]));
        }),
        map((response: Voucher | string) => {
          if (typeof response === "string") {
            return { errorMessage: response };
          } else {
            this.vouchers.push(response);
            this.triggerRebuildPriceInfo.next();
            return of(null);
          }
        }),
      );
  }

  resetVoucherService() {
    this.vouchers = [];
  }
}
