import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { MiniMapComponent } from "./mini-map.component";

@NgModule({
  declarations: [ MiniMapComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ MiniMapComponent ],
  providers: [],
})
export class MiniMapModule {}
