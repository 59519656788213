import { Pipe, PipeTransform } from "@angular/core";

import { FormatNumberUtil } from "../utils/format-number.util";
import { Locale } from "booking-app-v2/shared/models/locale.model";

@Pipe({
  name: "adjustDecimals",
  pure: false,
})
export class AdjustDecimalsPipe implements PipeTransform {

  constructor() { }

  public transform(value: number, locale: Locale, decimalPlace: number): string {
    return FormatNumberUtil.adjustDecimals(value, locale, decimalPlace);
  }
}
