import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { HotelSearchForm } from "booking-app-v2/hotels/models/hotel-search-form.model";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { FormatNumberUtil } from "booking-app-v2/shared/utils";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

@Component({
  selector: "hotel-list-item-distance",
  template: `
    <div class="hotel-distance" *ngIf="showHotelDistance()">
      <em class="fa fa-road"></em>
      <span
        [translate]="hotelDistanceLabel()"
        [translateParams]="{distance: hotelDistance(), poi: poiName()}"
      ></span>
    </div>`,
})
export class HotelListItemDistanceComponent {
  @Input() distance: number;
  constructor(
    private globalData: GlobalData,
    private translateService: TranslateService,
  ) {
  }

  showHotelDistance(): boolean {
    return !!this.distance;
  }

  hotelDistanceLabel(): string {
    return "POI_DISTANCE_AWAY_FROM";
  }

  hotelDistance(): string {
    return FormatNumberUtil.roundNumber(
      this.distance,
      this.globalData.get(GlobalDataEnum.SELECTED_LOCALE),
      1,
    );
  }

  poiName(): string {
    const hotelSearchForm: HotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    if (hotelSearchForm.destination.value.type === "city") {
      return this.translateService.instant("city center");
    } else if (hotelSearchForm.destination.value.type === "airport") {
      return this.translateService.instant("Airport");
    } else {
      return (hotelSearchForm.destination.value.destination_id || "").split(",")[0];
    }
  }
}
