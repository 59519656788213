import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiDataService {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  constructor(
    private http: HttpClient,
  ) { }

  get<Response>(
    urlSuffix: string,
    params: { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> } = {},
    apiPrefix: boolean = true,
  ): Observable<Response> {
    const fullPath = apiPrefix ? `/api/${urlSuffix}` : urlSuffix;
    const filteredParams = JSON.parse(JSON.stringify(params));
    return this.http.get<Response>(
      fullPath,
      {
        headers: this.buildHeaders(),
        params: filteredParams,
      },
    ).pipe(catchError((error: HttpErrorResponse) => this.mapToApiDataError(error.error, error.status)));
  }

  post<Response>(urlSuffix: string, data: any, apiPrefix: boolean = true): Observable<Response> {
    const fullPath: string = apiPrefix ? "/api/" + urlSuffix : urlSuffix;
    const formattedData: string = typeof data === "object" ? JSON.stringify(data) : "{}";
    return this.http.post<Response>(
      fullPath,
      formattedData,
      {
        headers: this.buildHeaders(),
      },
    ).pipe(catchError((error: HttpErrorResponse) => this.mapToApiDataError(error.error, error.status)));
  }

  delete<Response>(urlSuffix: string, apiPrefix: boolean = true): Observable<Response> {
    const fullPath: string = apiPrefix ? "/api/" + urlSuffix : urlSuffix;
    return this.http.delete<Response>(
      fullPath,
      {
        headers: this.buildHeaders(),
      },
    ).pipe(catchError((error: HttpErrorResponse) => this.mapToApiDataError(error.error, error.status)));
  }

  jsonP<Response>(url: string, callbackParam: string = "callback"): Observable<Response>  {
    return this.http.jsonp<Response>(url, callbackParam)
      .pipe(catchError((error: HttpErrorResponse) => this.mapToApiDataError(error.error, error.status)));
  }

  private buildHeaders(): { [key: string]: string |[] } {
    const headers = {
      "Content-Type": "application/json",
      "APP-VERSION": this.kaligoConfig.appVersion,
      "Cache-Control": "no-cache, no-store, must-revalidate",
    };

    return {
      ...headers,
    };
  }

  private mapToApiDataError(errorData: any, status: number): Observable<never> {
    if (status === 500 || status === 501 || status === 502) {
      return this.buildApiDataError(["Unknown error"]);
    }

    if (status === 429) {
      return this.buildApiDataError(["Rate limit exceeded. Please try again later"]);
    }

    if (typeof errorData === "undefined" || errorData === null) {
      return this.buildApiDataError([""]);
    }

    if (typeof errorData === "object") {
      if (errorData.error && typeof errorData.error === "string") {
        return this.buildApiDataError([errorData.error]);
      } else if (errorData.errors && Array.isArray(errorData.errors)) {
        return this.buildApiDataError(errorData.errors);
      } else {
        return this.buildApiDataError([errorData.toString()]);
      }
    } else {
      return this.buildApiDataError([errorData]);
    }
  }

  private buildApiDataError(errors: any[]): Observable<never> {
    return throwError(() => ({ errors }));
  }

}
