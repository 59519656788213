import { FilterForm, Flight, Flights } from "booking-app-v2/flights/models";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AirlineFilterUnit, StopoverFilterUnit, TimeRange } from "booking-app-v2/flights/types";
import { GlobalDataEnum, PRODUCT_TYPE, RedeemPoints } from "booking-app-v2/shared/types";

type FilterUnit = StopoverFilterUnit | AirlineFilterUnit;
type FilterUnitList = StopoverFilterUnit[] | AirlineFilterUnit[];

export class FilterUtil {
  static globalData: GlobalData;
  static appSettingsService: AppSettingsService;
  static isRedeem: boolean;

  static init(globalData: GlobalData, appSettingsService: AppSettingsService) {
    this.globalData = globalData;
    this.appSettingsService = appSettingsService;
    this.isRedeem = globalData.get(GlobalDataEnum.LANDING_PAGE).hasProductType(PRODUCT_TYPE.REDEEM);
  }

  static filterFlightsWithFilterForm(
    preFilteredList: Flight[],
    filterForm: FilterForm,
    selectedOriginFlight: Flight,
  ): Flights {
    const newFilteredFlights: Flights = new Flights(
        this.appSettingsService,
        this.globalData,
        preFilteredList,
        selectedOriginFlight,
      );
    let newFilteredList = newFilteredFlights.unfilteredFlights;

    newFilteredList = this.byFilterUnit(newFilteredList, filterForm.stopoverFilterUnits, this.filterFlightByStopover);
    newFilteredList = this.byFilterUnit(newFilteredList, filterForm.airlineFilterUnits, this.filterFlightByAirline);
    newFilteredList = this.filterFlightByDeparture(filterForm.departureRangeFilter, newFilteredList);
    newFilteredList = this.filterFlightByArrival(filterForm.arrivalRangeFilter, newFilteredList);
    if (this.isRedeem) {
      newFilteredList = this.filterFlightByRedeemPoints(filterForm.redeemPoints, newFilteredList);
    }

    newFilteredFlights.totalDisplayedFlights = newFilteredList;

    return newFilteredFlights;
  }

  static filterFlightByStopover(stopoverFilterUnit: StopoverFilterUnit, flights: Flight[]): Flight[] {
    return flights.filter((flight: Flight) => {
      return flight.segments.length >= stopoverFilterUnit.minSegmentCount &&
        flight.segments.length <= stopoverFilterUnit.maxSegmentCount;
    });
  }

  static filterFlightByAirline(airlineFilterUnit: AirlineFilterUnit, flights: Flight[]): Flight[] {
    return flights.filter((flight: Flight) => {
      for (const segment of flight.segments) {
        if (segment.marketing_airline_code === airlineFilterUnit.id) {
          return true;
        }
      }
      return false;
    });
  }

  static filterFlightByDeparture(timeRange: TimeRange, flights: Flight[]): Flight[] {
    return flights.filter((flight: Flight) => {
      return flight.departureTimeInMinutes >= timeRange.min &&
             flight.departureTimeInMinutes <= timeRange.max;
    });
  }

  static filterFlightByArrival(timeRange: TimeRange, flights: Flight[]): Flight[] {
    return flights.filter((flight: Flight) => {
      return flight.arrivalTimeInMinutes >= timeRange.min &&
             flight.arrivalTimeInMinutes <= timeRange.max;
    });
  }

  static filterFlightByRedeemPoints(redeemPoints: RedeemPoints, flights: Flight[]): Flight[] {
    if (!redeemPoints) {
      return flights;
    }

    const minPoints: number = redeemPoints.min - 1;
    const maxPoints: number = redeemPoints.max + 1;

    return flights.filter((flight: Flight) => {
      const points: number = flight.priceInfo.points_payment;
      return points >= minPoints && points <= maxPoints;
    });
  }

  private static byFilterUnit(
    newFilteredList: Flight[],
    filterUnitList: FilterUnitList,
    filterUnitMethod: (filterUnit: FilterUnit, flights: Flight[]) => Flight[],
  ): Flight[] {
    const selectedFilters = filterUnitList.filter(filter => filter.isSelected);
    if (selectedFilters.length === 0) {
      return newFilteredList;
    }

    const filteredListHolder: Flight[] = [];

    selectedFilters.forEach((filterUnit: FilterUnit) => {
      filteredListHolder.push(...filterUnitMethod(filterUnit, newFilteredList));
    });

    return filteredListHolder;
  }
}
