import { Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RecaptchaComponent, RecaptchaErrorParameters } from "ng-recaptcha";
import { finalize } from "rxjs";
import Rollbar from "rollbar";

import { UserService } from "booking-app-v2/shared/services/user.service";
import { RollbarService } from "booking-app-v2/shared/services/rollbar.service";
import { ApiDataError, ForgetPasswordParams, FormStatus } from "booking-app-v2/shared/types";

@Component({
  selector: "forget-password-modal",
  templateUrl: "/html/dialogs/forget_password_dialog_v2",
})
export class ForgetPasswordDialogComponent {
  @ViewChild("recaptcha") recaptchaElement: RecaptchaComponent;
  forgetPasswordForm: FormGroup;
  formStatus: FormStatus;

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private userService: UserService,
  ) {
    this.formStatus = { processing: false, success: false, errors: [] };
    this.forgetPasswordForm = new FormGroup({
      email: new FormControl("", Validators.required),
      recaptcha: new FormControl("", Validators.required),
    });
  }

  validateForm(): void {
    this.markFormAsDirty();

    // Only execute recaptcha after all the other fields are valid
    if (this.onlyRecaptchaInvalid()) {
      this.recaptchaElement.execute();
    }
  }

  submitForm(recaptchaResponse: string): void {
    if (this.forgetPasswordForm.invalid || !recaptchaResponse) {
      return;
    }

    this.formStatus.processing = true;
    this.userService.forgetPassword(this.buildForgetPasswordParams(recaptchaResponse))
      .pipe(finalize(() => this.formStatus.processing = false))
      .subscribe({
        next: () => this.formStatus.success = true,
        error: (err: ApiDataError) => {
          this.formStatus.errors = err.errors;
          this.recaptchaElement.reset();
        },
      });
  }

  handleRecaptchaError(errors: RecaptchaErrorParameters): void {
    this.rollbar.warning("ForgetPasswordModal RECAPTCHA error: ", errors);
  }

  private buildForgetPasswordParams(recaptchaResponse: string): ForgetPasswordParams {
    const forgetPasswordFormControls = this.forgetPasswordForm.controls;

    return {
      user: {
        email: forgetPasswordFormControls.email.value,
      },
      commit: "Reset password",
      recaptcha_response: recaptchaResponse,
    };
  }

  private markFormAsDirty(): void {
    this.forgetPasswordForm.controls.email.markAsDirty();
  }

  private onlyRecaptchaInvalid(): boolean {
    return this.forgetPasswordForm.controls.email.valid &&
      this.forgetPasswordForm.controls.recaptcha.invalid;
  }

}
