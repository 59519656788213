import { Injectable } from "@angular/core";
import { map, Observable, Subject, take } from "rxjs";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { ApiPollingService } from "booking-app-v2/shared/services/api-polling.service";
import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";

import {
  CarCommonQueryParams,
  CarPricesRawResponse,
  CarPricesUrlParams,
  CarTerms,
} from "booking-app-v2/cars/types";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { CarDestination, CarSearchForm } from "booking-app-v2/cars/models";
import { TimeUtils } from "booking-app-v2/shared/utils";

@Injectable({
  providedIn: "root",
})
export class CarUtilService {
  constructor(
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private apiPollingService: ApiPollingService,
    private apiDataService: ApiDataService,
  ) { }

  fetchCarsPrices(params: CarCommonQueryParams): Observable<CarPricesRawResponse> {
    const response$ = new Subject<CarPricesRawResponse>();

    this.globalData.set(GlobalDataEnum.CAR_RESULT_STILL_POLLING, true);

    this.apiPollingService.poll(
      "cars/prices",
      this.getCarPricesUrlParams(params),
      response$,
    ).pipe(take(1))
    .subscribe((res: CarPricesRawResponse) => {
      this.globalData.set(GlobalDataEnum.CAR_RESULT_STILL_POLLING, false);
      response$.next(res);
    });

    return response$.asObservable();
  }

  fetchCarsSinglePrice(
    params: CarCommonQueryParams,
    bookingKey: string,
    couponCode?: string,
  ): Observable<CarPricesRawResponse> {
    return this.apiPollingService.poll(
      `cars/${bookingKey}/price`,
      this.getCarPricesUrlParams(params, couponCode),
    ).pipe(map((res: CarPricesRawResponse) => res));
  }

  fetchCarsTerms(params: CarCommonQueryParams, bookingKey: string): Observable<CarTerms> {
    return this.apiDataService.get(
      `cars/${bookingKey}/terms`,
      { ...this.getCarPricesUrlParams(params) },
    ).pipe(map((res: CarTerms) => res));
  }

  setCarSearchFormFromQueryParams(
    params: CarCommonQueryParams,
    pickupLocation: CarDestination,
    returnLocation: CarDestination,
  ): void {
    const { pickup_location, return_location, pickup_time, return_time } = params;
    const pickupDate = TimeUtils.formatInEnglish(pickup_time, "DD MMM YYYY");
    const returnDate = TimeUtils.formatInEnglish(return_time, "DD MMM YYYY");
    const pickupTime = TimeUtils.formatInEnglish(pickup_time, "HH:mm");
    const returnTime = TimeUtils.formatInEnglish(return_time, "HH:mm");

    const carSearchForm: CarSearchForm = new CarSearchForm(
      pickupLocation,
      returnLocation,
      pickupTime,
      returnTime,
      pickupDate,
      returnDate,
      pickup_location === return_location,
    );

    this.globalData.set(GlobalDataEnum.CAR_SEARCH_FORM, carSearchForm);
  }

  private getCarPricesUrlParams(params: CarCommonQueryParams, couponCode?: string): CarPricesUrlParams {
    const {
      pickup_location,
      return_location,
      pickup_time,
      return_time,
      currency,
      landingPage,
      partnerId,
      country,
      productType,
    } = params;

    const urlParams: CarPricesUrlParams = {
      pickup_location,
      return_location,
      pickup_time: pickup_time + ":00",
      return_time: return_time + ":00",
      currency,
      landing_page: landingPage,
      partner_id: partnerId,
      country,
    };

    if (this.appSettingsService.useProductType) {
      urlParams.product_type = this.globalData.productTypeAdapter(productType);
    }

    if (couponCode) {
      urlParams.coupon_code = couponCode;
    }

    return urlParams;
  }
}
