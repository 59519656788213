import { Hotel, HotelBooking } from "../models";

export class HotelImageUtil {

  static readonly DEFAULT_IMAGES: string[] = [
    "door.jpg",
    "pillow.jpg",
    "towels.jpg",
    "bell.jpg",
    "hotel-room.jpg",
    "hotel_desk.jpg",
    "bed_lamp.jpg",
    "housekeeping.jpg",
  ];
  static readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  static getThumbImageStyle(booking: HotelBooking): { [klass: string]: string; } {
    return this.getHotelImageStyles(booking.hotel_id, booking.cloudflare_image_url, 1, "h=250&w=250");
  }

  static getDefaultThumbImageStyle(hotel: Hotel): { [klass: string]: string; } {
    return this.getHotelImageStyles(hotel.id, hotel.cloudflare_image_url, hotel.default_image_index);
  }

  static getDefaultHotelBgImageStyle(): { [klass: string]: string; } {
    const imageIndex: number = Math.floor(Math.random() * this.DEFAULT_IMAGES.length);
    const missingBackground: string =
      `${this.kaligoConfig.cdnImageUrl}/hotels_missing_images/${this.DEFAULT_IMAGES[imageIndex]}`;

    return {
      "background-image": `url(${missingBackground})`,
      "background-size": "cover",
    };
  }

  static getDefaultHotelBgImage(): string {
    const imageIndex: number = Math.floor(Math.random() * this.DEFAULT_IMAGES.length);
    return `${this.kaligoConfig.cdnImageUrl}/hotels_missing_images/${this.DEFAULT_IMAGES[imageIndex]}`;
  }

  static getHotelMediumSizeImagesSet(hotel: Hotel): string[] {
    return this.getHotelImagesSet(hotel, "h=500&w=500");
  }

  static getHotelHighResImagesSet(hotel: Hotel): string[] {
    return this.getHotelImagesSet(hotel, "h=1350&w=1800");
  }

  private static getHotelImageStyles(
    hotelId: string,
    baseUrl: string,
    index: number,
    size: string = "",
  ): { [klass: string]: string; } {
    return { "background-image": `url(${this.getHotelImageUrl(hotelId, baseUrl, index, size)})` };
  }

  private static getHotelImageUrl(
    hotelId: string,
    baseUrl: string,
    index: number,
    size: string,
  ): string {
    const suffix: string = size === "" ? "" : `?fit=cover&${size}`;
    return `${baseUrl}/${hotelId}/i${index}.jpg${suffix}`;
  }

  private static getHotelImagesSet(hotel: Hotel, size = ""): string[] {
    const images: string[] = [];
    for (let i = 0; i < hotel.number_of_images; i++) {
      images.push(this.getHotelImageUrl(hotel.id, hotel.cloudflare_image_url, i + 1, size));
    }
    return images.length ? images : [this.getDefaultHotelBgImage()];
  }
}
