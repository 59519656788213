import { ObjectValues } from "./object-values.type";

export const TENANT = {
  ACME: "Acme",
  ASCENDAGO: "Ascendago",
  OLDHAM: "Oldham",
  CTBC: "Ctbc",
  UOB: "Uob",
} as const;

export type Tenant = ObjectValues<typeof TENANT>;
