import { ObjectValues } from "booking-app-v2/shared/types/object-values.type";

export const FLIGHT_TYPE = {
  RETURN: "rt",
  ONE_WAY: "ow",
} as const;

export type FlightType = ObjectValues<typeof FLIGHT_TYPE>;

export interface FlightTypeOption {
  key: FlightType;
  label: string;
}
