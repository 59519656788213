import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { HotelSearchForm } from "booking-app-v2/hotels/models";
import { Currency } from "booking-app-v2/shared/models";
import { LocaleNumberFormat } from "booking-app-v2/shared/pipes";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { TimeUtils } from "booking-app-v2/shared/utils";

@Component({
  selector: "complimentary-price-box",
  template: `
    <div class="complimentary-price-box" *ngIf="!searchCompleted">
      <div class="price loading"></div>
      <div class="points color-points loading"></div>
      <div class="btn-action uppercase loading"></div>
    </div>

    <div class="complimentary-price-box" *ngIf="searchCompleted">
      <div class="our-rate-container">
        <div
          class="rate-text"
          translate="wl.visa.our_rate"
          *ngIf="!!ourRateText"
        ></div>
        <div class="diamond-container">
          <img
            class="diamond-icon"
            *ngIf="complimentaryNights"
            [cdn-path]="diamondLogo"
          />
        </div>
      </div>

      <div class="price">
        <div class="currency-container">
          <span
            class="value"
            [textContent]="selectedCurrency.code | codeSymbolFmt"
          ></span>
          <span
            class="value"
            [textContent]="convertedOriginalPricePerRoomPerNightWithTax()"
          ></span>
          <span class="per-night visible-xs">
            <span>/</span>
            <span translate="night"></span>
          </span>
          <div class="per-night hidden-xs">
            <span translate="per night"></span>
          </div>
        </div>
      </div>
      <div class="points color-points">
        <span
          [innerHTML]="'wl.visa.total_earn_for' | translate: {
            currency_symbol: selectedCurrency.code | codeSymbolFmt,
            amount: convertedTotalDiscountedPriceWithTax(),
            duration: duration
          }"
        ></span>
      </div>

      <button
        mat-raised-button
        class="btn-action uppercase"
        (click)=callPriceBoxAction()
      >
        {{ buttonText | translate }}
      </button>
    </div>
  `,
})
export class ComplimentaryPriceBoxComponent implements OnInit {
  @Input() complimentaryNights: number;
  @Input() singleRoomPerNightWithTax: number;
  @Input() priceWithTax: number;
  @Input() buttonText: string;
  @Input() searchCompleted: boolean;
  @Input() ourRateText: boolean;

  @Output() priceBoxAction = new EventEmitter<any>();

  hotelSearchForm: HotelSearchForm;
  duration: number;
  diamondLogo: string = "/visa/icons/diamond.png";

  constructor(
    private globalData: GlobalData,
    private currenciesService: CurrenciesService,
    private localeNumberFormat: LocaleNumberFormat,
  ) { }

  get selectedCurrency(): Currency {
    return this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
  }

  ngOnInit() {
    this.hotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    this.duration = TimeUtils.diff(
      this.hotelSearchForm.checkOutDate,
      this.hotelSearchForm.checkInDate,
      "day",
    );
  }

  convertedTotalDiscountedPriceWithTax(): string {
    return this.formatCurrency(
      this.currenciesService.convertFromUsd(this.priceWithTax),
    );
  }

  convertedOriginalPricePerRoomPerNightWithTax(): string {
    return this.formatCurrency(
      this.currenciesService.convertFromUsd(this.singleRoomPerNightWithTax),
    );
  }

  callPriceBoxAction(): void {
    this.priceBoxAction.emit();
  }

  formatCurrency(value: number): string {
    return this.localeNumberFormat.transform(value, 0);
  }
}
