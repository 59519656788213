import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { AccordionComponent } from "./accordion.component";

@NgModule({
  declarations: [ AccordionComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ AccordionComponent ],
  providers: [],
})
export class AccordionModule {}
