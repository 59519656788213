import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GalleryDialogContent } from "booking-app-v2/shared/types";

@Component({
  selector: "gallery-dialog",
  templateUrl: "/html/dialogs/gallery_dialog_v2",
})
export class GalleryDialogComponent {
  images: string[];
  currentGalleryIndex: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GalleryDialogContent,
  ) { }

  ngOnInit() {
    this.images = this.data.images;
    this.currentGalleryIndex = this.data.currentGalleryIndex;
  }
}
