import { Injectable } from "@angular/core";
import { map, ReplaySubject, Subject } from "rxjs";

import { BaseStep } from "./base-step";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  FetchInfoResult,
} from "booking-app-v2/shared/types";
import { Hotel } from "booking-app-v2/hotels/models";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";

@Injectable({
  providedIn: "root",
})
export class HotelFetchInfoService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.FETCH_INFO;

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private windowRefService: WindowRefService,
    private apiDataService: ApiDataService,
  ) {}

  process(results: CheckoutResults): FetchInfoResult {
    const hotelId: string = this.windowRefService.getURIParamFromWindow();
    const result: Subject<Hotel> = new ReplaySubject<Hotel>(1);

    this.apiDataService
      .get(`hotels/${hotelId}`)
      .pipe(
        map((rawHotel: Hotel) => {
          return Object.keys(rawHotel).length === 0
            ? undefined
            : new Hotel(this.appSettingsService, this.globalData, rawHotel);
        }),
      ).subscribe((hotel: Hotel) => {
        result.next(hotel);
      });

    return result.asObservable();
  }

}
