import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { BottomSheetService } from "booking-app-v2/shared/services/bottom-sheet.service";

import { Hotels } from "booking-app-v2/hotels/models";
import { SortOption } from "booking-app-v2/shared/types";

@Component({
  selector: "hotel-result-sort-mobile",
  template: `
    <div class="hotel-result-sort-mobile">
      <button class="hotel-result-sort-mobile-button" (click)="openBottomSheet()">
        <span *ngIf="sortIconSource">
          <img [cdn-path]="sortIconSource" alt="">
        </span>
        <div class="sort-label" [translate]="'Sort'"></div>
      </button>

      <ng-template #sortBottomSheet>
        <bottom-sheet-wrapper>
          <div class="sort-title" bottomSheetTitle>
            <span [translate]="'Sort'"></span>
          </div>
          <div class="sort-container" bottomSheetBody>
            <ng-container *ngFor="let opt of hotels.filterForm.sortDropdownConfig.options">
              <button class="sort-options"
                [class.selected]="opt.key === hotels.filterForm.selectedSortValue.key"
                (click)="selectSortOptions(opt)"
              >
                <span [translate]="opt.label"></span>
                <div class="fa fa-check check-select"></div>
              </button>
            </ng-container>
          </div>
        </bottom-sheet-wrapper>
      </ng-template>
    </div>
  `,
})
export class HotelResultSortMobileComponent implements OnInit {
  @Input() hotels: Hotels;
  @ViewChild("sortBottomSheet") sortBottomSheetTemplate: TemplateRef<any>;

  sortIconSource: string;

  constructor(
    private bottomSheetService: BottomSheetService,
    private appSettingsService: AppSettingsService,
  ) { }

  ngOnInit() {
    this.sortIconSource = this.appSettingsService.mobileResultPopupConfig.sortIconSource;
  }

  openBottomSheet(): void {
    this.bottomSheetService.open(this.sortBottomSheetTemplate, ["hotel-result-sort-mobile"]);
  }

  selectSortOptions(selectedSortOption: SortOption) {
    this.hotels.sortHotel(selectedSortOption.key);
    this.bottomSheetService.close();
  }
}
