import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { DOCUMENT } from "@angular/common";

import { GlobalData } from "../../services/global-data.service";
import { UserService } from "booking-app-v2/shared/services/user.service";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { CookiesService } from "booking-app-v2/shared/services/initializers/cookies.service";

@Component({
  selector: "mobile-side-menu",
  templateUrl: "/html/components/mobile_side_menu_v2",
})
export class MobileSideMenuComponent {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  @Output() loginButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private router: Router,
    private globalData: GlobalData,
    private userService: UserService,
    private cookiesService: CookiesService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.listenRouteChangeAndCloseMenu();
  }

  toggleMenu(): void {
    this.globalData.set(GlobalDataEnum.SHOW_MOBILE_SIDE_MENU, !this.showMobileSideMenu());
    this.handleLockBody();
  }

  showMobileSideMenu(): boolean {
    return this.globalData.get(GlobalDataEnum.SHOW_MOBILE_SIDE_MENU);
  }

  openLoginModal(): void {
    this.loginButtonClicked.emit();
  }

  logout(): void {
    this.userService.logout();
  }

  listenRouteChangeAndCloseMenu(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.globalData.set(GlobalDataEnum.SHOW_MOBILE_SIDE_MENU, false);
      }
    });
  }

  private handleLockBody(): void {
    if (this.showMobileSideMenu()) {
      this.document.body.classList.add("lock-body");
    } else {
      this.document.body.classList.remove("lock-body");
    }
  }
}
