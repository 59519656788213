import { Injectable } from "@angular/core";

import { BaseStep } from "./base-step";
import {
  CHECKOUT_RESULT_NAME,
  CarCollectBookingParamsResult,
  CheckoutParamsCarDebugInfo,
  CheckoutParamsCarSpecific,
  CheckoutParamsDriverInfo,
  CheckoutParamsMembership,
  CheckoutResultName,
  CheckoutParamsUser,
  CheckoutResults,
  GlobalDataEnum,
  Membership,
  SelectPaymentChannelResult,
  TRAVEL_TYPE,
  TransactionMembership,
} from "booking-app-v2/shared/types";
import { FormGroup } from "@angular/forms";
import { Car, CarSearchForm } from "booking-app-v2/cars/models";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import {
  CommonCollectBookingParamsService,
} from "booking-app-v2/shared/services/checkout/steps/common-collect-booking-params.service";

@Injectable({
  providedIn: "root",
})
export class CarCollectBookingParamsService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.CAR_COLLECT_BOOKING_PARAMS;
  private checkoutForm: FormGroup;
  private car: Car;
  private carSearchForm: CarSearchForm;
  private voucherIds: string[];
  private familyMilesSelected: boolean;
  private membership: Membership | TransactionMembership;
  private selectPaymentChannelResult: SelectPaymentChannelResult;

  constructor(
    private globalData: GlobalData,
    private commonCollectBookingParamsService: CommonCollectBookingParamsService,
  ) {}

  process(results: CheckoutResults): CarCollectBookingParamsResult {
    this.initializeCheckoutData(results);

    return {
      ...this.commonCollectBookingParamsService.buildCommonBookingParams(
        this.checkoutForm,
        this.voucherIds,
        this.selectPaymentChannelResult.newCardSave,
      ),
      ...this.buildUserParams(),
      ...this.buildDebugInfoParams(),
      ...this.buildMembershipParams(),
      ...this.buildCarSpecificParams(),
    };
  }

  private buildUserParams(): CheckoutParamsUser {
    return {
      user: {
        ...this.commonCollectBookingParamsService.buildCommonUserParams(this.checkoutForm),
      },
    };
  }

  private buildDebugInfoParams(): CheckoutParamsCarDebugInfo {
    return {
      debug_info: {
        car_model: this.car.name,
        car_category: this.car.category,
        passengers: this.car.passengers,
        baggage: this.car.baggage,
        doors: this.car.doors,
        pickup_time: this.carSearchForm.pickupDateTime.toISOString(),
        return_time: this.carSearchForm.returnDateTime.toISOString(),
        pickup_location: this.carSearchForm.pickupLocation.id,
        return_location: this.carSearchForm.returnLocation.id,
        points_payment: this.car.priceInfo?.points_payment ? Math.max(this.car.priceInfo?.points_payment, 0) : 0,
        cash_payment: this.car.priceInfo?.cash_payment ? Math.max(this.car.priceInfo?.cash_payment, 0) : 0,
        points_earned: this.car.priceInfo?.points ?? 0,
        bonus_programs: this.car.priceInfo?.bonus_programs,
        bonus_tiers: this.car.priceInfo?.bonus_tiers,
        exchange_rate: this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).rate,
      },
    };
  }

  private buildMembershipParams(): CheckoutParamsMembership {
    return {
      membership: {
        first_name: this.membership.member_first_name,
        last_name: this.membership.member_last_name,
        number: this.membership.member_no,
      },
    };
  }

  private buildCarSpecificParams(): CheckoutParamsCarSpecific {
    return {
      driver: this.buildDriverParams(),
      travel_type: TRAVEL_TYPE.CARS,
      referrer: this.globalData.get(GlobalDataEnum.CURRENT_USER)?.tenant_referrer,
      use_family_miles: this.familyMilesSelected,
    };
  }

  private buildDriverParams(): CheckoutParamsDriverInfo {
    return {
      first_name: this.checkoutForm.controls.firstName.value,
      last_name: this.checkoutForm.controls.lastName.value,
      phone: this.commonCollectBookingParamsService.formatPhoneNumber(this.checkoutForm),
      age: this.checkoutForm.controls.age?.value ?? 30,
      country_residence: this.checkoutForm.controls.countryOfResidence.value.code,
      flight_number: this.checkoutForm.controls.flightNumber.value,
    };
  }

  private initializeCheckoutData(results: CheckoutResults): void {
    this.selectPaymentChannelResult =
      results[CHECKOUT_RESULT_NAME.SELECT_PAYMENT_CHANNEL] as SelectPaymentChannelResult;
    this.carSearchForm = this.globalData.get(GlobalDataEnum.CAR_SEARCH_FORM);
    this.checkoutForm = results.intermediateCheckoutParams.checkoutForm;
    this.car = results.intermediateCheckoutParams.car;
    this.voucherIds = results.intermediateCheckoutParams.voucherIds;
    this.familyMilesSelected = results.intermediateCheckoutParams.familyMilesSelected;
    this.membership = results.intermediateCheckoutParams.membership;
  }
}
