import { Injectable } from "@angular/core";
import { Params, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { FlightSearchForm } from "booking-app-v2/flights/models";
import {
  FlightCabin,
  FlightCabinOption,
  FlightCommonQueryParams,
  FlightSingleQueryParams,
  FlightType,
  FlightTypeOption,
  FLIGHT_TYPE,
} from "booking-app-v2/flights/types";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { BaseUrlBuilder } from "booking-app-v2/shared/services/url-builder/base-url-builder";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { AppSettingsService } from "../app-settings.service";

@Injectable({
  providedIn: "root",
})
export class FlightsUrlBuilderService extends BaseUrlBuilder {
  constructor(
    private router: Router,
    private windowRefService: WindowRefService,
    private translateService: TranslateService,
    protected globalData: GlobalData,
    protected appSettingsService: AppSettingsService,
    protected pointsCashShareService: PointsCashShareService,
  ) {
    super(globalData, appSettingsService, pointsCashShareService);
  }

  buildCommonSearchUrlParams(): FlightCommonQueryParams {
    const flightSearchForm: FlightSearchForm = this.globalData.get(GlobalDataEnum.FLIGHT_SEARCH_FORM);
    return {
      fromAirport: flightSearchForm.fromFlightDestination.value,
      toAirport: flightSearchForm.toFlightDestination.value,
      cabin: flightSearchForm.flightCabin.key,
      passengers: `${flightSearchForm.adultCount}:${flightSearchForm.childCount}:${flightSearchForm.infantCount}`,
      flightType: flightSearchForm.flightType.key,
      departureDate: flightSearchForm.departureDate,
      returnDate: flightSearchForm.returnDate,
      pointsAccountId: this.globalData.get(GlobalDataEnum.POINTS_ACCOUNT_ID),
      ...super.commonQueryParams(),
    };
  }

  buildSingleSearchUrlParams(bookingKey: string): FlightSingleQueryParams {
    const urlParams: FlightSingleQueryParams = {
      bookingKey,
      partnerId: this.globalData.get(GlobalDataEnum.POINTS_PARTNER).id.toString(),
      pointsCashShare: super.commonPointCashParams(),
      pointsAccountId: this.globalData.get(GlobalDataEnum.POINTS_ACCOUNT_ID),
      landingPage: this.globalData.get(GlobalDataEnum.LANDING_PAGE).url,
    };

    if (this.appSettingsService.useProductType) {
      urlParams.productType = this.globalData.productTypeAdapter(this.globalData.get(GlobalDataEnum.PRODUCT_TYPE));
    }

    return urlParams;
  }

  buildFlightSingleQueryParams(params: Params): FlightSingleQueryParams {
    return params as FlightSingleQueryParams;
  }

  buildSearchUrl(): FlightCommonQueryParams {
    return { ...this.buildCommonSearchUrlParams() };
  }

  buildResultsPageDataFromQueryParams(params: Params): FlightCommonQueryParams {
    return params as FlightCommonQueryParams;
  }

  buildSummaryPageDataFromQueryParams(params: Params): FlightSingleQueryParams {
    return this.buildFlightSingleQueryParams(params);
  }

  buildCheckoutPageDataFromQueryParams(params: Params): FlightSingleQueryParams {
    return this.buildFlightSingleQueryParams(params);
  }

  buildFlightTypeOptionFromUrl(): FlightTypeOption {
    const flightType = this.windowRefService.getQueryParamFromWindow<FlightType>("flightType");
    return {
      key: flightType,
      label: flightType === FLIGHT_TYPE.RETURN ? "Return" : "One Way",
    };
  }

  buildFlightCabinOptionFromUrl(): FlightCabinOption {
    const flightCabin = this.windowRefService.getQueryParamFromWindow<FlightCabin>("cabin");
    return {
      key: flightCabin,
      label: this.translateService.instant(`flight_cabin.${flightCabin}`),
    };
  }

  buildAdultCountFromUrl(): number {
    const passengers: string = this.windowRefService.getQueryParamFromWindow("passengers");
    return Number(passengers.split(":")[0]);
  }

  buildChildCountFromUrl(): number {
    const passengers: string = this.windowRefService.getQueryParamFromWindow("passengers");
    return Number(passengers.split(":")[1]);
  }

  buildInfantCountFromUrl(): number {
    const passengers: string = this.windowRefService.getQueryParamFromWindow("passengers");
    return Number(passengers.split(":")[2]);
  }

  redirectToLandingPage() {
    this.router.navigate([`/flights`]);
  }

  redirectToResultsPage() {
    this.router.navigate([`/flights/results`], {
      queryParams: this.buildCommonSearchUrlParams(),
    });
  }

  redirectToSummaryPage(bookingKey: string) {
    this.router.navigate([`/flights/summary`], {
      queryParams: this.buildSingleSearchUrlParams(bookingKey),
    });
  }

  redirectToCheckoutPage(bookingKey: string) {
    this.router.navigate([`/flights/checkout`], {
      queryParams: this.buildSingleSearchUrlParams(bookingKey),
    });
  }
}
