import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CashRange, GlobalDataEnum, PRODUCT_TYPE, RedeemPoints } from "booking-app-v2/shared/types";
import { Car, Cars, FilterForm } from "booking-app-v2/cars/models";
import { CarStaticFilterUnit, CarSupplierFilterUnit, CarTypeFilterUnit } from "booking-app-v2/cars/types";

type FilterUnit = CarTypeFilterUnit | CarSupplierFilterUnit | CarStaticFilterUnit;
type FilterUnitList = Array<FilterUnit>;

export class FilterUtil {
  static globalData: GlobalData;
  static appSettingsService: AppSettingsService;
  static isRedeem: boolean;

  static init(globalData: GlobalData, appSettingsService: AppSettingsService) {
    this.globalData = globalData;
    this.appSettingsService = appSettingsService;
    this.isRedeem = globalData.get(GlobalDataEnum.LANDING_PAGE).hasProductType(PRODUCT_TYPE.REDEEM);
  }

  static filterCarsWithFilterForm(
    preFilteredList: Car[],
    filterForm: FilterForm,
  ): Cars {
    const newFilteredCars: Cars = new Cars(
      this.appSettingsService,
      this.globalData,
      preFilteredList,
    );
    let newFilteredList = [...newFilteredCars.unfilteredCars];

    newFilteredList = this.byName(newFilteredList, filterForm.name);
    newFilteredList = this.byFilterUnit(newFilteredList, filterForm.carTypeFilterUnits, this.byType);
    newFilteredList = this.byFilterUnit(newFilteredList, filterForm.carSupplierFilterUnits, this.bySupplier);
    newFilteredList = this.byFilterUnit(newFilteredList, filterForm.carRatingFilterUnits, this.byRating);
    newFilteredList = this.byFilterUnit(newFilteredList, filterForm.carSpecificationFilterUnits, this.bySpecifications);
    newFilteredList = this.byFilterUnit(newFilteredList, filterForm.carFuelFilterUnits, this.byFuel);
    newFilteredList = this.byFilterUnit(newFilteredList, filterForm.carTransmissionFilterUnits, this.byTransmission);
    if (this.isRedeem) {
      newFilteredList = this.byRedeemPoints(newFilteredList, filterForm.redeemPoints);
    } else {
      newFilteredList = this.byCashRange(newFilteredList, filterForm.cashRange);
    }

    newFilteredCars.totalDisplayedCars = newFilteredList;

    return newFilteredCars;
  }

  static isBiggerCar(type1: CarTypeFilterUnit, type2: CarTypeFilterUnit): boolean {
    return (
      type1.passengers > type2.passengers ||
      (type1.passengers === type2.passengers && type1.baggage > type2.baggage)
    );
  }

  private static byName(newFilteredList: Car[], name: string): Car[] {
    if (!name) {
      return newFilteredList;
    }

    return newFilteredList.filter((car: Car) => car.name.toLowerCase().includes(name.toLowerCase()));
  }

  private static byType(typeFilterUnit: CarTypeFilterUnit, cars: Car[]): Car[] {
    return cars.filter((car: Car) => car.category === typeFilterUnit.category);
  }

  private static bySupplier(supplierFilterUnit: CarSupplierFilterUnit, cars: Car[]): Car[] {
    return cars.filter((car: Car) => car.supplier_name === supplierFilterUnit.name);
  }

  private static byRating(supplierFilterUnit: CarStaticFilterUnit, cars: Car[]): Car[] {
    return cars.filter((car: Car) => car.rating_category === supplierFilterUnit.name);
  }

  private static bySpecifications(supplierFilterUnit: CarStaticFilterUnit, cars: Car[]): Car[] {
    return cars.filter((car: Car) => car[supplierFilterUnit.name]);
  }

  private static byFuel(supplierFilterUnit: CarStaticFilterUnit, cars: Car[]): Car[] {
    return cars.filter((car: Car) => car.fuel_type === supplierFilterUnit.name);
  }

  private static byTransmission(supplierFilterUnit: CarStaticFilterUnit, cars: Car[]): Car[] {
    return cars.filter((car: Car) => car.transmission_type === supplierFilterUnit.name);
  }

  private static byRedeemPoints(newFilteredList: Car[], redeemPoints: RedeemPoints): Car[] {
    if (!redeemPoints) {
      return newFilteredList;
    }

    const minPoints: number = redeemPoints.min - 1;
    const maxPoints: number = redeemPoints.max + 1;

    return newFilteredList.filter((car: Car) => {
      let points: number;
      if (this.appSettingsService.showTotalNights) {
        points = car.priceInfo.points_payment;
      } else {
        points = car.priceInfo.points_payment_per_night;
      }

      return points >= minPoints && points <= maxPoints;
    });
  }

  private static byCashRange(newFilteredList: Car[], cashRange: CashRange): Car[] {
    if (!cashRange) {
      return newFilteredList;
    }

    const minPrice: number = cashRange.min - 1;
    const maxPrice: number = cashRange.max + 1;

    return newFilteredList.filter((car: Car) => {
      const price: number = car.priceInfo.price;
      return price && price >= minPrice && price <= maxPrice ;
    });
  }

  private static byFilterUnit(
    newFilteredList: Car[],
    filterUnitList: FilterUnitList,
    filterUnitMethod: (filterUnit: FilterUnit, cars: Car[]) => Car[],
  ): Car[] {
    const selectedFilters = filterUnitList.filter(filter => filter.isSelected);
    if (selectedFilters.length === 0) {
      return newFilteredList;
    }

    const filteredListHolder: Car[] = [];

    selectedFilters.forEach((filterUnit: FilterUnit) => {
      filteredListHolder.push(...filterUnitMethod(filterUnit, newFilteredList));
    });

    return filteredListHolder;
  }
}
