import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { HotelRawPrice, MarketRate } from "../types";
import { RoomPrice } from "../models";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

export class MarketRatesDecorator {

  static decorate(price: RoomPrice, rawPrice: HotelRawPrice, numberOfNights: number, roomsCount: number): void {
    price.marketRates = this.getMarketSingleRate(price, rawPrice.market_rates, numberOfNights, roomsCount);
  }

  // Get the right compare rate from market rates
  private static getMarketSingleRate(
    price: RoomPrice,
    marketRates: MarketRate[] = [],
    numberOfNights: number,
    roomsCount: number,
  ): MarketRate[] {
    marketRates.forEach((marketRate) => {
      let rate: number;
      if (price.globalData.get(GlobalDataEnum.TAX_INCLUSIVE)) {
        rate = marketRate.rate;
      } else {
        rate =  marketRate.rate_without_tax;
      }
      // Divide the market rate by roomCount
      marketRate.singleRate = rate / roomsCount / numberOfNights;
    });
    return this.sortMarketRates(this.filterMarketRates(marketRates, price.appSettingsService));
  }

  // filter out expedia compare rates except entertainer
  private static sortMarketRates(marketRates: MarketRate[]): MarketRate[] {
    // TODO: this.sortService.int(marketRates, "singleRate", false);
    return marketRates;
  }

  // filter out expedia compare rates except entertainer
  private static filterMarketRates(marketRates: MarketRate[], appSettingsService: AppSettingsService): MarketRate[] {
    if (!marketRates) {
      return [];
    }

    if (appSettingsService.displayNonBookableMarketRates) {
      return marketRates;
    }

    return marketRates.filter((rate) => rate.booking_url);
  }
}
