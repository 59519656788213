import { Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RecaptchaComponent, RecaptchaErrorParameters } from "ng-recaptcha";
import { finalize } from "rxjs";
import Rollbar from "rollbar";

import { UserService } from "booking-app-v2/shared/services/user.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { RollbarService } from "booking-app-v2/shared/services/rollbar.service";
import { ApiDataError, FormStatus, LoginParams } from "booking-app-v2/shared/types";

@Component({
  selector: "login-dialog",
  templateUrl: "/html/dialogs/login_dialog_v2",
})
export class LoginDialogComponent {
  @ViewChild("recaptcha") recaptchaElement: RecaptchaComponent;
  loginForm: FormGroup;
  formStatus: FormStatus;

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private userService: UserService,
    private windowRefService: WindowRefService,
  ) {
    this.formStatus = { processing: false, success: false, errors: [] };
    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", Validators.required),
      rememberMe: new FormControl(""),
      recaptcha: new FormControl("", Validators.required),
    });
  }

  validateForm(): void {
    this.markFormAsDirty();

    // Only execute recaptcha after all the other fields are valid
    if (this.onlyRecaptchaInvalid()) {
      this.recaptchaElement.execute();
    }
  }

  submitForm(recaptchaResponse: string): void {
    if (this.loginForm.invalid || !recaptchaResponse) {
      return;
    }

    this.formStatus.processing = true;
    this.userService.login(this.buildLoginParams(recaptchaResponse))
      .pipe(finalize(() => this.formStatus.processing = false))
      .subscribe({
        next: () => this.reloadPage(),
        error: (err: ApiDataError) => {
          this.formStatus.errors = err.errors;
          this.recaptchaElement.reset();
        },
      });
  }

  handleRecaptchaError(errors: RecaptchaErrorParameters): void {
    this.rollbar.warning("LoginModal RECAPTCHA error: ", errors);
  }

  private buildLoginParams(recaptchaResponse: string): LoginParams {
    const loginFormControls = this.loginForm.controls;
    return {
      user: {
        email: loginFormControls.email.value,
        password: loginFormControls.password.value,
        remember_me: loginFormControls.rememberMe.value ? "1" : undefined,
      },
      recaptcha_response: recaptchaResponse,
    };
  }

  private markFormAsDirty(): void {
    this.loginForm.controls.email.markAsDirty();
    this.loginForm.controls.password.markAsDirty();
    this.loginForm.controls.rememberMe.markAsDirty();
  }

  private reloadPage(): void {
    this.windowRefService.nativeWindow.location.reload();
  }

  private onlyRecaptchaInvalid(): boolean {
    return this.loginForm.controls.email.valid &&
      this.loginForm.controls.password.valid &&
      this.loginForm.controls.rememberMe.valid &&
      this.loginForm.controls.recaptcha.invalid;
  }
}
