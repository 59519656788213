import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { resultsPageRoutes } from "./results-page.routes";

@NgModule({
  imports: [RouterModule.forChild(resultsPageRoutes)],
  exports: [RouterModule],
})

export class ResultsPageRoutingModule { }
