import { NgModule } from "@angular/core";
import { NgxSliderModule } from "@ascenda/ngx-slider";
import { SharedModule } from "booking-app-v2/shared/shared.module";
import { RedeemPointsSliderComponent } from "./redeem-points-slider.component";

@NgModule({
  declarations: [ RedeemPointsSliderComponent ],
  imports: [
    SharedModule,
    NgxSliderModule,
  ],
  exports: [ RedeemPointsSliderComponent ],
  providers: [],
})
export class RedeemPointsSliderModule {}
