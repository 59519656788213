import { Injectable } from "@angular/core";
import { ApiDataService } from "./api-data.service";
import { CountrySelection } from "../types/country-selection.type";

@Injectable({
  providedIn: "root",
})
export class CityCountryBuilderService {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;
  private countries: any[];
  private cities: any[];

  constructor(private apiDataService: ApiDataService) {}

  buildList(tenant: string): void {
    this.apiDataService
      .get(`${this.kaligoConfig.cdnUrl}/assets/app/${tenant}_cities.json`, {}, false)
      .subscribe((response: CountrySelection[]) => {
        this.countries = response;
        this.cities = this.countries.reduce((cities, country) => {
          return cities.concat(
            country.cities.map((city) => {
              return Object.assign({ country }, city);
            }),
          );
        }, []);
      });
  }

  getCountryList(): any[] {
    return this.countries;
  }

  getCityList(): any[] {
    return this.cities;
  }
}
