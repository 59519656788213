import { RoomAdditionalInfoSurcharges, Taxes } from "../types";
import { Room } from "../models";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

export class CheckoutRoomDecorator {

  static decorate(room: Room): void {
    room.priceInfo.totalPrice = room.roomRawPrice.chargeableRate;
    room.priceInfo.taxes = this.calculateTaxCharges(room);
    room.priceInfo.hotelFees = this.calculatedHotelFees(room);
    room.priceInfo.kaligoServiceFee = room.roomAdditionalInfo.displayFields.kaligo_service_fee;
    room.priceInfo.totalPriceWithoutTax = room.priceInfo.totalPrice - room.priceInfo.taxes.taxAndRecoveryCharges;
    room.priceInfo.totalPriceWithoutFeeAndTax = room.priceInfo.totalPriceWithoutTax - room.priceInfo.kaligoServiceFee;
    room.priceInfo.averageRoomPrice = this.averageRoomPrice(room);
    room.priceInfo.totalRoomPriceWithoutFeeAndTax = this.totalRoomPriceWithoutFeeAndTax(room);
    room.priceInfo.totalRoomPriceWithoutFeeAndTaxPerNight = this.totalRoomPriceWithoutFeeAndTaxPerNight(room);
    room.priceInfo.taxAndRecoveryCharges = this.taxAndRecoveryCharges(room);
    room.priceInfo.salesTax = this.salesTax(room);
    room.priceInfo.propertyFee = this.propertyFee(room);
    room.priceInfo.hotelOccupancyTax = this.hotelOccupancyTax(room);
    room.priceInfo.formattedKaligoServiceFee = this.formattedKaligoServiceFee(room);
    room.priceInfo.formattedHotelFees = this.formattedHotelFees(room);

    if (room.roomRawPrice.original_price) {
      room.priceInfo.complimentaryTotalPriceWithoutTax =
        room.roomRawPrice.original_price - room.priceInfo.taxes.taxAndRecoveryCharges;
      room.priceInfo.complimentaryAverageRoomPrice =
        room.priceInfo.complimentaryTotalPriceWithoutTax / room.hotelSearchForm.duration;
      room.priceInfo.formattedOriginalPrice =
        this.formatCurrencyValue(room.roomRawPrice.original_price, room);
      room.priceInfo.formattedComplimentaryTotalPriceWithoutTax =
        this.formatCurrencyValue(room.priceInfo.complimentaryTotalPriceWithoutTax, room);
      room.priceInfo.formattedComplimentaryAverageRoomPrice =
        this.formatCurrencyValue(room.priceInfo.complimentaryAverageRoomPrice, room);
      room.priceInfo.formattedComplimentaryDiscount =
        this.formatCurrencyValue(room.roomRawPrice.complimentary_discount, room);
    }
  }

  static formatCurrencyValue(value: number, room: Room): string {
    const convertedValue = room.currencyService.convertFromUsd(value);
    return room.currencyService.formatCurrencyValue(convertedValue);
  }

  private static averageRoomPrice(room: Room): string {
    if (room.globalData.get(GlobalDataEnum.TAX_INCLUSIVE)) {
      return this.formatCurrencyValue((room.priceInfo.totalPrice / room.hotelSearchForm.duration), room);
    }
    return this.formatCurrencyValue(room.priceInfo.totalPriceWithoutTax / room.hotelSearchForm.duration, room);
  }

  private static calculateTaxCharges(room: Room): Taxes {
    const surcharges: RoomAdditionalInfoSurcharges[] = room.roomAdditionalInfo.displayFields.surcharges;
    const taxes: Taxes = {
      taxAndRecoveryCharges: 0,
      salesTax: 0,
      hotelOccupancyTax: 0,
      propertyFee: 0,
    };
    surcharges.forEach(surcharge => {
      switch (surcharge.type) {
        case "TaxAndServiceFee":
        case "ExtraPersonFee":
          taxes.taxAndRecoveryCharges += surcharge.amount;
          break;
        case "SalesTax":
          taxes.salesTax += surcharge.amount;
          break;
        case "PropertyFee":
          taxes.propertyFee += surcharge.amount;
          break;
        case "HotelOccupancyTax":
          taxes.hotelOccupancyTax += surcharge.amount;
          break;
      }
    });
    return taxes;
  }

  private static totalRoomPriceWithoutFeeAndTax(room: Room): string {
    return this.formatCurrencyValue(room.priceInfo.totalPriceWithoutFeeAndTax, room);
  }

  private static totalRoomPriceWithoutFeeAndTaxPerNight(room: Room): string {
    return this.formatCurrencyValue(room.priceInfo.totalPriceWithoutFeeAndTax / room.hotelSearchForm.duration, room);
  }

  private static calculatedHotelFees(room: Room): number {
    const hotelFees = room.roomAdditionalInfo.displayFields.hotel_fees;
    let fees = 0;
    hotelFees.forEach(hotelFee => {
      fees += hotelFee.amount;
    });
    return fees;
  }

  private static taxAndRecoveryCharges(room: Room): string {
    return this.formatCurrencyValue(room.priceInfo.taxes.taxAndRecoveryCharges, room);
  }

  private static salesTax(room: Room): string {
    return this.formatCurrencyValue(room.priceInfo.taxes.salesTax, room);
  }

  private static propertyFee(room: Room): string {
    return this.formatCurrencyValue(room.priceInfo.taxes.propertyFee, room);
  }

  private static hotelOccupancyTax(room: Room): string {
    return this.formatCurrencyValue(room.priceInfo.taxes.hotelOccupancyTax, room);
  }

  private static formattedKaligoServiceFee(room: Room): string {
    return this.formatCurrencyValue(room.roomAdditionalInfo.displayFields.kaligo_service_fee, room);
  }

  private static formattedHotelFees(room: Room): string {
    return this.formatCurrencyValue(room.priceInfo.hotelFees, room);
  }
}
