import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";

import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { TrustYouReviews, TrustYouUIDetails } from "booking-app-v2/hotels/types";

@Injectable({
  providedIn: "root",
})
export class TrustYouService {

  private readonly scoreMappings: TrustYouUIDetails[] = [
    { score: 4.5, color: "positive", class: "pos", category: "trustyou_rank_excellent" },
    { score: 4, color: "positive", class: "pos", category: "trustyou_rank_vgood" },
    { score: 3.3, color: "positive", class: "pos", category: "trustyou_rank_good" },
    { score: 0, color: "neutral", class: "neu", category: "trustyou_rank_fair" },
  ];

  constructor(private apiDataService: ApiDataService) {}

  getHotelReview(hotelId: string): Observable<TrustYouReviews> {
    return this.apiDataService.get(`hotels/${hotelId}/review`)
      .pipe(map((reviews: TrustYouReviews) => this.postProcessSingleReview(reviews)));
  }

  getScoreUIDetails(score: number): TrustYouUIDetails {
    let scoreUIDetails: TrustYouUIDetails = this.scoreMappings[this.scoreMappings.length - 1];
    this.scoreMappings.some((scoreMapping) => {
      if (score >= scoreMapping.score) {
        scoreUIDetails = scoreMapping;
        return true;
      }
    });

    return scoreUIDetails;
  }

  private postProcessSingleReview(reviews: TrustYouReviews): TrustYouReviews {
    reviews.summary.sentiment = this.getScoreUIDetails(reviews.summary.value);
    return reviews;
  }
}
