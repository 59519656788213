import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { concatMap, firstValueFrom, Observable } from "rxjs";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { BootstrapDataService } from "booking-app-v2/shared/services/bootstrap-data.service";
import { CookiesService } from "booking-app-v2/shared/services/initializers/cookies.service";
import { DestinationService } from "booking-app-v2/shared/services/destination.service";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { HotelGulfDestination } from "booking-app-v2/hotels/types";
import { SIMPLE_DIALOG } from "booking-app-v2/shared/types";
import { SimpleDialogComponent } from "booking-app-v2/shared/components/dialog/simple-dialog/simple-dialog.component";

@Injectable({ providedIn: "root" })
export class GulfDestinationResolver implements Resolve<void> {
  constructor(
    private appSettingsService: AppSettingsService,
    private bootstrapDataService: BootstrapDataService,
    private cookiesService: CookiesService,
    private destinationService: DestinationService,
    private dialogService: DialogService,
    private router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<void> {
    const destinationId: string = route.paramMap.get("id");
    if (destinationId?.length > 4) {
      this.router.navigate(["/"]);
    } else {
      return this.destinationService.getHotelDestination(destinationId).pipe(
        concatMap((hotelGulfDestination: HotelGulfDestination) => {
          return this.checkGulfResidency(hotelGulfDestination);
        }),
      );
    }
  }

  private checkGulfResidency(data: any): Promise<void> {
    if (this.shouldDisplayGulfDestinationModal(data.gulf_destination)) {
      return this.displayGulfResidentModal();
    } else {
      return Promise.resolve();
    }
  }

  private shouldDisplayGulfDestinationModal(isGulfDestination: boolean): boolean {
    return (
      !!isGulfDestination &&
      !this.isGulfCountry(this.bootstrapDataService.bootstrapData.country_code) &&
      !this.cookiesService.getGulfResident() &&
      this.appSettingsService.showGulfAlert
    );
  }

  private displayGulfResidentModal(): Promise<void> {
    return firstValueFrom(this.dialogService.open(SIMPLE_DIALOG.GULF_RESIDENCY, SimpleDialogComponent))
      .then((response: boolean) => {
        if (response) {
          this.cookiesService.setGulfResident(this.appSettingsService.userGulfResident);
        } else {
          this.cookiesService.setGulfResident(this.bootstrapDataService.bootstrapData.country_code);
        }
      });
  }

  private isGulfCountry(countryCode: string): boolean {
    return ["EG", "BH", "IR", "IQ", "IL", "JO", "KW", "LB",
            "OM", "QA", "SA", "SY", "TR", "AE", "YE", "TN",
            "MA", "MR", "DZ", "LY", "SD"].indexOf(countryCode) >= 0; // to support IE9
  }
}
