import { Component, Input } from "@angular/core";

import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";

@Component({
  selector: "hotel-description",
  template: `
    <div class="hotel-description">
      <div class="headline-container">
        <h2 class="title color-headline" [translate]="'txt.hotel_overview'"></h2>
        <div class="chevron-container">
          <a class="fa" (click)="toggleDescription()"
             [ngClass]="showFullDescription ? 'fa-chevron-up' : 'fa-chevron-down'"
             [attr.aria-label]="showFullDescription ? 'Collapse hotel overview' : 'Expand hotel overview'"
             role="button"
             href="javascript:;">
          </a>
        </div>
      </div>
      <div class="hotelDescription clear" [innerHTML]="description"
           [class.limit-description]="!showFullDescription">
      </div>
    </div>
  `,
})
export class HotelDescriptionComponent {
  @Input() description: string;

  showFullDescription: boolean;

  constructor(
    private windowRefService: WindowRefService,
  ) {}

  toggleDescription(): void {
    this.showFullDescription = !this.showFullDescription;
    // This scroll event is for the sticky headers computation
    // computation finish first before the hide/show therfore causing some issues on the display
    setTimeout(() => this.triggerScrollEvent(), 100);
  }

  // angular-v2-todo: after scroll to view is implemented for WLs with sticky headers, check if we still need this
  // Because in v2 we can use ViewportScroller.scrollToAnchor()
  private triggerScrollEvent(): void {
    const window: Window = this.windowRefService.nativeWindow;
    const scrollEvent: UIEvent = window.document.createEvent("UIEvents");
    scrollEvent.initEvent("scroll", true, true);
    window.dispatchEvent(scrollEvent);
  }
}
