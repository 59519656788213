import { HotelRawPrice } from "../types";
import { RoomPrice } from "../models";
import { Currency } from "booking-app-v2/shared/models";
import { FormatNumberUtil } from "booking-app-v2/shared/utils";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

export class HotelPriceDecorator {

  static decorate(price: RoomPrice, rawPrice: HotelRawPrice, numberOfNights: number, roomsCount: number): void {
    price.priceWithTax = rawPrice.lowest_price;
    price.voucher_type_id = rawPrice.voucher_type_id || undefined;
    price.roomBookingVoucherType = rawPrice.voucher_type_id ?
      price.globalData.get(GlobalDataEnum.LANDING_PAGE).voucherTypes[rawPrice.voucher_type_id] :
      undefined;
    price.points = rawPrice.points;
    price.bonus = rawPrice.bonuses;
    price.searchRank = rawPrice.searchRank;
    price.source = rawPrice.source;

    price.price = this.setPriceWithTax(price, rawPrice);
    price.bestDealMultiplier = this.checkGoodDealMultiplier(price, rawPrice);
    price.formattedPrice = this.formattedPrice(price, numberOfNights, roomsCount);
  }

  private static setPriceWithTax(price: RoomPrice, rawPrice: HotelRawPrice): number {
    return price.globalData.get(GlobalDataEnum.TAX_INCLUSIVE) ?
      rawPrice.lowest_price : rawPrice.lowest_price_without_tax;
  }

  private static checkGoodDealMultiplier(price: RoomPrice, rawPrice: HotelRawPrice): number {
    if (!rawPrice && rawPrice.lowest_price === 0) {
      return;
    }

    const bestDealValueBase: number =
      Math.floor(rawPrice.lowest_price / price.appSettingsService.basePointsRatio);
    const bestDealValueMultiplier: number =
      Math.floor((rawPrice.points + rawPrice.bonuses) / bestDealValueBase);
    return bestDealValueMultiplier;
  }

  private static formattedPrice(price: RoomPrice, numberOfNights: number, roomsCount: number): string {
    let priceDisplay: number;
    const selectedCurrency: Currency = price.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
    priceDisplay = price.price * selectedCurrency.rate;
    if (!price.appSettingsService.showTotalNights) {
      priceDisplay = priceDisplay / numberOfNights / roomsCount;
    }

    if (selectedCurrency.decimalPlace === 0) {
      priceDisplay = Math.ceil(priceDisplay);
    }

    const roundedPrice = FormatNumberUtil.roundNumber(
      priceDisplay,
      price.globalData.get(GlobalDataEnum.SELECTED_LOCALE),
    );

    if (price.appSettingsService.defaultCurrencyDisplay === "symbol") {
      return `${selectedCurrency.symbol}${roundedPrice}`;
    } else {
      return `${selectedCurrency.code} ${roundedPrice}`;
    }
  }
}
