import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { checkoutPageRoutes } from "./checkout-page.routes";

@NgModule({
  imports: [RouterModule.forChild(checkoutPageRoutes)],
  exports: [RouterModule],
})

export class CheckoutPageRoutingModule { }
