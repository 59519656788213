import { ObjectValues } from "./object-values.type";

export const SORT_TYPE = {
  DEAL: "deal",
  PRICE_LOHI: "price-lohi",
  PRICE_HILO: "price-hilo",
  CARS_PRICE_LOHI: "cars-price-lohi",
  CARS_PRICE_HILO: "cars-price-hilo",
  RATING_LOHI: "rating-lohi",
  RATING_HILO: "rating-hilo",
  POINTS_HILO: "point-hilo",
  CARS_POINT_HILO: "cars-point-hilo",
  PAY_POINTS_LOHI: "pay-points-lohi",
  PAY_POINTS_HILO: "pay-points-hilo",
  PAY_POINTS_CASH_LOHI: "pay-points-cash-lohi",
  PAY_POINTS_CASH_HILO: "pay-points-cash-hilo",
  BEST_RATED: "best-rated",
  BUSINESS: "business",
  DISTANCE_LOHI: "distance-lohi",
  FLIGHTS_PRICE_LOHI: "flights-price-lohi",
  FLIGHTS_PRICE_HILO: "flights-price-hilo",
  FLIGHTS_MILES_LOHI: "flights-miles-lohi",
  FLIGHTS_MILES_HILO: "flights-miles-hilo",
  FLIGHTS_STOPS_LOHI: "flights-stops-lohi",
  FLIGHTS_OUTBOUND_LOHI: "flights-outbound-lohi",
  FLIGHTS_OUTBOUND_HILO: "flights-outbound-hilo",
  FLIGHTS_DURATION_LOHI: "flights-duration-lohi",
  FLIGHTS_DURATION_HILO: "flights-duration-hilo",
} as const;

export type SortType = ObjectValues<typeof SORT_TYPE>;
