import { Component, EventEmitter, Output } from "@angular/core";

import { CouponService } from "booking-app-v2/shared/services/coupon.service";
import { CheckoutService } from "booking-app-v2/shared/services/checkout/checkout.service";

@Component({
  selector: "coupon-code",
  templateUrl: "/html/hotels/coupon_code_v2",
})
export class CouponCodeComponent {
  @Output() couponCodeValidated: EventEmitter<void> = new EventEmitter<void>();
  @Output() resetCouponCode: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public couponService: CouponService,
    private checkoutService: CheckoutService,
  ) { }

  couponChanged(event: Event): void {
    this.couponService.updateCouponCode((event.target as HTMLInputElement).value);
    this.couponService.resetMessage();
  }

  async validate(): Promise<void> {
    await this.checkoutService.validateCouponCode();
    this.couponCodeValidated.next();
  }

  removeCouponCode(): void {
    this.resetCouponCode.next();
    this.validate();
  }

  validStatusClass(): string {
    if (this.couponService.isValid()) {
      return "success";
    }
    if (!this.couponService.isValid() && this.couponService.hasErrorMessage()) {
      return "error";
    }
  }
}
