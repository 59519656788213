import { NgModule } from "@angular/core";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { HotelEditSearchDialogModule } from "../dialog/hotel-edit-search-dialog/hotel-edit-search-dialog.module";
import { HotelSearchBarComponent } from "./hotel-search-bar.component";

@NgModule({
  declarations: [ HotelSearchBarComponent ],
  imports: [
    SharedModule,
    HotelEditSearchDialogModule,
  ],
  exports: [ HotelSearchBarComponent ],
  providers: [
    DialogService,
  ],
})
export class HotelSearchBarModule {}
