import { ObjectValues } from "./object-values.type";

export const CARD = {
  AMEX: "amex",
  AURA: "aura",
  DINERS: "diners",
  DISCOVER: "discover",
  ELO: "elo",
  HIPERCARD: "hipercard",
  JCB: "jcb",
  MASTERCARD: "mastercard",
  VISA: "visa",
  UNIONPAY: "cup",
} as const;

export type Card = ObjectValues<typeof CARD>;
