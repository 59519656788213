import { Component, OnInit } from "@angular/core";

import { GoogleAnalyticsService } from "booking-app-v2/shared/services/google-analytics.service";

@Component({
  selector: "google-analytics",
  template: "",
})
export class GoogleAnalyticsComponent implements OnInit {

  constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.googleAnalyticsService.commonGa();
  }
}
