import { ObjectValues } from "./object-values.type";

export const LOADING_MESSAGE = {
  PAYMENT_IN_PROGRESS: "payment_in_progress",
  AUTHENTICATION_IN_PROGRESS: "authentication_in_progress",
  PAYMENT_IS_PENDING: "payment.is_pending",
  PAYMENT_CONFIRMED_BOOKING_NOW: "payment.confirmed_booking_now",
} as const;

export type LoadingMessage = ObjectValues<typeof LOADING_MESSAGE>;
