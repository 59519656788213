import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PayWithPointsCashService } from "../services/pay-with-points-cash.service";
import { Base } from "./base.model";

export class Price extends Base {
  max_points_payment: number;
  max_cash_payment: number;
  base_points_payment: number;
  base_cash_payment: number;
  // discount attributes
  cash_fixed_discount: number;
  points_fixed_discount: number;
  cash_non_fixed_discounts_by_tier: number[];
  points_non_fixed_discounts_by_tier: number[];
  bonus_programs: number[];
  bonus_tiers: object[];
  max_cash_payment_by_tier: number[];

  readonly payWithPointsCashService: PayWithPointsCashService;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    payWithPointsCashService: PayWithPointsCashService,
  ) {
    super(appSettingsService, globalData);
    this.payWithPointsCashService = payWithPointsCashService;
  }

  hasDiscountedPrice(): boolean {
    return this.base_points_payment !== this.max_points_payment ||
      this.base_cash_payment !== this.max_cash_payment;
  }

  getPriceBeforeDiscount(): string {
    return this.payWithPointsCashService.getPriceBeforeDiscount(this);
  }
}
