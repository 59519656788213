import { CheckoutResultName, CheckoutResults, SkipCheckoutStepResult } from "booking-app-v2/shared/types";
import { BaseStep } from "./base-step";

export class SkipCheckoutStep implements BaseStep {
  resultName: CheckoutResultName;

  constructor(checkoutResultName: CheckoutResultName) {
    this.resultName = checkoutResultName;
  }

  process(results: CheckoutResults): SkipCheckoutStepResult {
    return;
  }
}
