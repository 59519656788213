import { Provider } from "@angular/core";

const kaligoConfig: KaligoConfig = window.KaligoConfig;

export const kaligoConfigs: Provider[] = [
  {
    provide: "kaligoConfig",
    useValue: kaligoConfig,
  },
];
