import { ObjectValues } from "booking-app-v2/shared/types/object-values.type";

export interface CarTypeFilterUnit {
  isSelected: boolean;
  category: string;
  passengers: number;
  baggage: number;
  count: number;
}

export interface CarSupplierFilterUnit {
  isSelected: boolean;
  id: string;
  name: string;
}

export interface CarStaticFilterUnit {
  isSelected: boolean;
  type: CarStaticFilter;
  name: string;
}

export const CAR_STATIC_FILTER = {
  RATING: "carRatingFilterUnits",
  SPECIFICATION: "carSpecificationFilterUnits",
  FUEL: "carFuelFilterUnits",
  TRANSMISSION: "carTransmissionFilterUnits",
} as const;

export type CarStaticFilter = ObjectValues<typeof CAR_STATIC_FILTER>;

export const carRatingFilterOptions = ["excellent", "very-good", "good"];
export const carSpecificationFilterOptions = ["aircon"];
export const carFuelFilterOptions = ["diesel", "petrol"];
export const carTransmissionFilterOptions = ["Automatic", "Manual"];
