import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import {
  BuildSearchFormResult,
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
} from "booking-app-v2/shared/types";
import { CarUtilService } from "booking-app-v2/cars/services/car-util.service";
import { CarsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/cars-url-builder.service";
import { CarPickupAndReturnDestination } from "booking-app-v2/cars/types";
import { DestinationService } from "booking-app-v2/shared/services/destination.service";
import { Observable, ReplaySubject, take } from "rxjs";
import { ForceSyncBaseStep } from "./force-sync-base-step";

@Injectable({
  providedIn: "root",
})
export class CarBuildSearchFormService implements ForceSyncBaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.BUILD_SEARCH_FORM;

  constructor(
    private activatedRoute: ActivatedRoute,
    private destinationService: DestinationService,
    private carUtilService: CarUtilService,
    private carsUrlBuilderService: CarsUrlBuilderService,
  ) {}

  processAsync(results: CheckoutResults): Observable<BuildSearchFormResult> {
    const result: ReplaySubject<BuildSearchFormResult> = new ReplaySubject<BuildSearchFormResult>(1);

    this.destinationService.getCarPickupAndReturnDestination(
      this.activatedRoute.snapshot.queryParams.pickup_location,
      this.activatedRoute.snapshot.queryParams.return_location,
      this.activatedRoute.snapshot.queryParams.location_type,
    ).pipe(take(1))
    .subscribe((res: CarPickupAndReturnDestination) => {
      this.carUtilService.setCarSearchFormFromQueryParams(
        this.carsUrlBuilderService.buildResultsPageDataFromQueryParams(this.activatedRoute.snapshot.queryParams),
        res.pickupDestination,
        res.returnDestination,
      );
      result.next();
    });

    return result.asObservable();
  }
}
