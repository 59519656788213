import { Pipe, PipeTransform } from "@angular/core";

import { Locale } from "../models/locale.model";
import { FormatNumberUtil } from "../utils/format-number.util";

@Pipe({
  name: "shortenNumber",
  pure: false,
})
export class ShortenNumberPipe implements PipeTransform {

  constructor() { }

  public transform(value: number, locale: Locale): string {
    return FormatNumberUtil.summarizeNumber(value, locale);
  }
}
