import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { AmenitiesComponent } from "./amenities.component";

@NgModule({
  declarations: [ AmenitiesComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ AmenitiesComponent ],
  providers: [],
})
export class AmenitiesModule {}
