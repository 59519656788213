import { Component, Inject, Input } from "@angular/core";
import { Router, UrlTree } from "@angular/router";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { LocaleNumberFormat } from "booking-app-v2/shared/pipes";

import { PointsPartner } from "booking-app-v2/shared/models";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { Hotel, HotelSearchForm } from "booking-app-v2/hotels/models";
import { MarketRate } from "booking-app-v2/hotels/types";

@Component({
  selector: "hotel-list-item-comp-rates",
  template: `
    <div class="hotel-list-item-comp-rates">
      <div class="outer-container" *ngIf="showMarketRates()">
        <label>
          <span [translate]="'results.compare_rate'"></span>
        </label>
        <div class="market-rates-area">
          <ng-container *ngFor="let marketRate of hotel.priceInfo.marketRates | slice: 0:appSettingsService.marketRatesCount">
            <a class="market-rate-item"
               (click)="redirectToExternalSupplier($event, marketRate)"
               *ngIf="hasComparableRate(marketRate.singleRate)">
              <div class="market-rate-supplier" [textContent]="marketRate.supplier"></div>
              <div class="market-rate-price">
                <span class="currency-symbol" [textContent]="globalData.get('selectedCurrency').code | codeSymbolFmt"></span>
                <span [textContent]="computedPrice(marketRate.singleRate) |  localeShortenNumber"></span>
              </div>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  `,
})
export class HotelListItemCompRatesComponent {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  @Input() hotel: Hotel;

  constructor(
    public globalData: GlobalData,
    public appSettingsService: AppSettingsService,
    private currenciesService: CurrenciesService,
    private windowRefService: WindowRefService,
    private pointsCashShareService: PointsCashShareService,
    private localeNumberFormat: LocaleNumberFormat,
    private router: Router,
  ) {}

  showMarketRates(): boolean {
    // Turkish Airlines wanted us to disable market rates ¯\_(ツ)_ /¯
    const partnerIsTurkish: boolean = this.globalData.get(GlobalDataEnum.POINTS_PARTNER)?.id === 73;
    const hideCompareRatesUI: boolean = this.kaligoConfig.whitelabel && !this.kaligoConfig.displayCompareRate;

    return !hideCompareRatesUI &&
      this.appSettingsService.showMarketRates &&
      this.hotel.priceInfo?.marketRates &&
      !partnerIsTurkish &&
      this.applicableRatesAvailable();
  }

  redirectToExternalSupplier($event, marketRate: MarketRate): void {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.appSettingsService.redirectMarketRates) {
      const redirectUrl: UrlTree = this.router.createUrlTree([`/redirect`], {
        queryParams: {
          lang: this.globalData.get(GlobalDataEnum.SELECTED_LOCALE).langCode,
          redirect: `${marketRate.booking_url}&splash=false`,
          partner: marketRate.supplier,
          price: this.localeNumberFormat.transform(marketRate.rate),
          currency: this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).code,
        },
      });
      this.windowRefService.nativeWindow.open(redirectUrl.toString());
    }
  }

  computedPrice(amount: number): number {
    const hotelSearchForm: HotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    if (this.appSettingsService.showTotalNights) {
      amount = amount * hotelSearchForm.duration;
    }
    return amount;
  }

  hasComparableRate(amount: number): boolean {
    if (this.appSettingsService.showBetterRatesOnly) {
      return Math.ceil(this.maxCashAmount(this.hotel.priceInfo.max_cash_payment))
        <= Math.ceil(this.computedPrice(amount));
    } else {
      return true;
    }
  }

  private maxCashAmount(amount: number): number {
    const sliderOptionsCeil = this.pointsCashShareService.pointsCashShare.sliderOptions.ceil;
    let convertedAmount: number = this.currenciesService.convertFromUsd(amount);
    const pointsPartner: PointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
    if (pointsPartner.hotels_points_payment_tiers) {
      convertedAmount = convertedAmount * pointsPartner.hotels_points_payment_tiers[sliderOptionsCeil];
    }
    return convertedAmount;
  }

  private applicableRatesAvailable(): boolean {
    return this.hotel.priceInfo.marketRates.filter(m => this.hasComparableRate(m.singleRate)).length > 0;
  }
}
