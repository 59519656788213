import { Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { debounceTime, fromEvent, map, pairwise, startWith, Subject } from "rxjs";

import { DEVICE, Device, VIEWPORT_SIZES } from "booking-app-v2/shared/types";

function _window(): Window {
  return window;
}

@Injectable({
  providedIn: "root",
})
export class WindowRefService {
  // Emit true of window width changes from "> 767px" to "<= 767px", emit false if the other way round
  phoneBreakpoint$: Subject<boolean> = new Subject<boolean>();
  // Emit true of window width changes from "<= 991px" to "> 991px", emit false if the other way round
  desktopBreakpoint$: Subject<boolean> = new Subject<boolean>();

  constructor() {
    this.setupWindowEvents();
  }

  get nativeWindow(): Window {
    return _window();
  }

  get device(): Device {
    return this.getDevice();
  }

  getURIParamFromWindow(): string {
    const paths: string[] = this.nativeWindow.location.pathname.split("/");
    return paths[paths.length - 1];
  }

  getQueryParamFromWindow<T extends string>(param: string): T {
    return this.getQueryParamsFromWindow()[param];
  }

  getQueryParamsFromWindow(): Params {
    const urlParams = new URLSearchParams(this.nativeWindow.location.search);
    const params: Params = {};
    urlParams.forEach((value: string, key: string) => {
      params[key] = value;
    });
    return params;
  }

  private getDevice(windowWidth: number = this.nativeWindow.innerWidth): Device {
    if (windowWidth <= VIEWPORT_SIZES.XS_MAX) {
      return DEVICE.PHONE;
    } else if (windowWidth <= VIEWPORT_SIZES.SM_MAX) {
      return DEVICE.TABLET;
    } else {
      return DEVICE.DESKTOP;
    }
  }

  private setupWindowEvents(): void {
    fromEvent(this.nativeWindow, "resize").pipe(
      debounceTime(200),
      startWith(null),
      map(() => this.nativeWindow.innerWidth),
      pairwise(),
    ).subscribe(([prevWindowWidth, currWindowWidth]: number[]) => {
      if (this.getDevice(prevWindowWidth) === DEVICE.PHONE && this.getDevice(currWindowWidth) !== DEVICE.PHONE) {
        this.phoneBreakpoint$.next(false);
      }
      if (this.getDevice(prevWindowWidth) !== DEVICE.PHONE && this.getDevice(currWindowWidth) === DEVICE.PHONE) {
        this.phoneBreakpoint$.next(true);
      }
      if (this.getDevice(prevWindowWidth) === DEVICE.DESKTOP && this.getDevice(currWindowWidth) !== DEVICE.DESKTOP) {
        this.desktopBreakpoint$.next(false);
      }
      if (this.getDevice(prevWindowWidth) !== DEVICE.DESKTOP && this.getDevice(currWindowWidth) === DEVICE.DESKTOP) {
        this.desktopBreakpoint$.next(true);
      }
    });
  }
}
