import { Injectable } from "@angular/core";

import { BaseStep } from "./base-step";
import {
  BuildSearchFormResult,
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
} from "booking-app-v2/shared/types";

import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { HotelUtilService } from "booking-app-v2/hotels/services";
import { HotelsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/hotels-url-builder.service";

@Injectable({
  providedIn: "root",
})
export class HotelBuildSearchFormService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.BUILD_SEARCH_FORM;

  constructor(
    private windowRefService: WindowRefService,
    private hotelUtilService: HotelUtilService,
    private hotelsUrlBuilderService: HotelsUrlBuilderService,
  ) {}

  process(results: CheckoutResults): BuildSearchFormResult {
    this.hotelUtilService.setHotelSearchFormFromQueryParams(
      this.hotelsUrlBuilderService.buildResultsPageDataFromQueryParams(
        this.windowRefService.getQueryParamsFromWindow(),
      ),
    );
  }

}
