import { Component, Input } from "@angular/core";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";

@Component({
  selector: "complimentary-banner",
  template: `
    <div class="complimentary-container">
      <div class="complimentary-title">
        <img [cdn-path]="diamondLogo" />
        <span translate="wl.visa.complimentary_night"></span>
      </div>
      <div class="description">
        <span translate="{{ complimentaryNightDescription() }}"></span>
      </div>
    </div>
  `,
})
export class ComplimentaryBannerComponent {
  tenant: string;
  diamondLogo: string = "/visa/icons/diamond.png";

  constructor(private appSettingsService: AppSettingsService) {
    this.tenant = this.appSettingsService.tenant.toLowerCase();
  }

  complimentaryNightDescription(): string {
    return `wl.${this.tenant}.cc_info_title`;
  }
}
