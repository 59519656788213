import { Component, Inject, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { Room } from "booking-app-v2/hotels/models";

@Component({
  selector: "room-image",
  template: `
    <div
      class="default-image hotel-image"
      [ngStyle]="{ 'background-image': 'url(' + defaultImageUrl + ')' }"
      *ngIf="!hasRoomImages"></div>
    <room-details-dialog-button
      [room]="room"
      [buttonAriaLabel]="'View more room photos'"
      [dialogButtonContent]="dialogButtonContent"
    >
      <ng-template #dialogButtonContent>
        <div class="hotel-image"
          [style.background]="roomImageBackgroundStyle"
          *ngIf="hasRoomImages">
          <div class="image-counter">
              1 / {{ room.images.length }}
          </div>
        </div>
        <div class="broken-image hotel-image"
          [style.background]="brokenImageBackgroundStyle">
        </div>
        <div class="room-details" [translate]="'View more'"></div>
      </ng-template>
    </room-details-dialog-button>
  `,
})
export class RoomImageComponent implements OnInit {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  @Input() room: Room;
  @ViewChild("dialogButtonContent") dialogButtonContent: TemplateRef<any>;

  defaultImageUrl: string;
  hasRoomImages: boolean;
  roomImageBackgroundStyle: string;
  brokenImageBackgroundStyle: string;

  constructor(
    private appSettingsService: AppSettingsService,
  ) { }

  ngOnInit() {
    this.defaultImageUrl = `${this.kaligoConfig.cdnImageUrl}/hotels_missing_images/hotel-room.jpg`;
    this.hasRoomImages = this.room.images?.length > 0;
    this.roomImageBackgroundStyle = this.hasRoomImages ? this.getImageBackgroundStyle(this.displayRoomImage()) : "";
    this.brokenImageBackgroundStyle = this.getImageBackgroundStyle();
  }

  private getImageBackgroundStyle(imageUrl: string = this.defaultImageUrl): string {
    return `url(${imageUrl}) center center / cover no-repeat`;
  }

  private displayRoomImage(): string {
    if (this.appSettingsService.useHighResolutionUrl) {
      return this.room.images[0]?.high_resolution_url || this.room.images[0]?.url;
    } else {
      return this.room.images[0]?.url;
    }
  }
}
