import { Base, LandingPage } from "booking-app-v2/shared/models";
import { Car, CarPrice, FilterForm } from "booking-app-v2/cars/models";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CashRange, GlobalDataEnum, PRODUCT_TYPE, RedeemPoints, SortType } from "booking-app-v2/shared/types";
import { SearchSortingUtil, SortUtil } from "booking-app-v2/shared/utils";
import { debounceTime, distinctUntilChanged, Subscription } from "rxjs";
import { FilterUtil } from "booking-app-v2/cars/utils";
import {
  CAR_STATIC_FILTER,
  carFuelFilterOptions,
  carRatingFilterOptions,
  carSpecificationFilterOptions,
  carTransmissionFilterOptions,
  CarStaticFilter,
  CarStaticFilterUnit,
  CarSupplierFilterUnit,
  CarTypeFilterUnit,
} from "booking-app-v2/cars/types";

export class Cars extends Base {
  unfilteredCars: Car[]; // Raw cars
  totalDisplayedCars: Car[]; // Cars after filtering and sorting
  displayedCars: Car[]; // Cars after filtering, sorting and pagination
  filterForm: FilterForm;

  resultsLimit: number; // for lazy loading

  private isRedeem: boolean;
  private landingPage: LandingPage;

  private carNameSubscription: Subscription;
  readonly DEFAULT_RESULT_COUNT: number = 6;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    cars: Car[],
  ) {
    super(appSettingsService, globalData);
    this.landingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
    this.isRedeem = this.landingPage.hasProductType(PRODUCT_TYPE.REDEEM);

    this.unfilteredCars = cars;
    this.displayedCars = [];
    this.totalDisplayedCars = [];
    this.resultsLimit = this.DEFAULT_RESULT_COUNT;

    this.initFilterForm();
  }

  filter(): void {
    const updatedCars = FilterUtil.filterCarsWithFilterForm(this.unfilteredCars, this.filterForm);
    this.unfilteredCars = updatedCars.unfilteredCars;
    this.rebuildCarPrice();
    this.totalDisplayedCars = updatedCars.totalDisplayedCars;
    this.displayedCars = this.totalDisplayedCars.slice(0, this.resultsLimit);
    this.sortCars(SearchSortingUtil.current);
  }

  resetFilters(): void {
    this.initFilterForm();
    this.filter();
    this.resetCarTypeFilters();
    this.resetCarSupplierFilters();
    this.resetCarStaticFilters();
    this.updateRedeemPointsFilter();
    this.updateCashRangeFilter();
  }

  sortCars(newOption?: SortType): void {
    if (newOption) {
      SearchSortingUtil.current = newOption;
      this.filterForm.selectedSortValue = { ...SearchSortingUtil.options[newOption] };
    }
    this.totalDisplayedCars = this.totalDisplayedCars.sort(SearchSortingUtil.currentSortFunction);
    this.displayedCars = this.totalDisplayedCars.slice(0, this.resultsLimit);
  }

  filterByName(name: string): void {
    this.filterForm.name = name;
    this.filterForm.name$.next(name);
  }

  filterByType(carTypeFilterUnit: CarTypeFilterUnit, checkedStatus: boolean): void {
    this.filterForm.carTypeFilterUnits
      .find(typeFilter => typeFilter.category === carTypeFilterUnit.category).isSelected = checkedStatus;
    this.filter();
  }

  filterBySupplier(supplierFilterUnit: CarSupplierFilterUnit, checkedStatus: boolean): void {
    this.filterForm.carSupplierFilterUnits
      .find(supplierFilter => supplierFilter.id === supplierFilterUnit.id).isSelected = checkedStatus;
    this.filter();
  }

  filterByStaticFilter(filterType: CarStaticFilter, filterUnit: CarStaticFilterUnit, checkedStatus: boolean): void {
    this.filterForm.getStaticFilterUnits(filterType)
      .find(filter => filter.name === filterUnit.name).isSelected = checkedStatus;
    this.filter();
  }

  filterByRedeemPoints(redeemPoints: RedeemPoints): void {
    this.filterForm.redeemPoints = redeemPoints;
    this.filter();
  }

  filterByCashRange(cashRange: CashRange): void {
    this.filterForm.cashRange = cashRange;
    this.filter();
  }

  resetCarTypeFilters(): void {
    const carTypeFilterUnits: CarTypeFilterUnit[] = [];

    this.unfilteredCars.forEach((car: Car) => {
      const existingCategory = carTypeFilterUnits.find((unit: CarTypeFilterUnit) => unit.category === car.category);
      if (existingCategory) {
        existingCategory.count++;
        return;
      }

      carTypeFilterUnits.push({
        isSelected: false,
        category: car.category,
        passengers: car.passengers,
        baggage: car.baggage,
        count: 1,
      });
    });

    carTypeFilterUnits.sort((type1: CarTypeFilterUnit, type2: CarTypeFilterUnit) => {
      return FilterUtil.isBiggerCar(type1, type2) ? 1 : -1;
    });

    this.filterForm.carTypeFilterUnits = carTypeFilterUnits;
  }

  resetCarSupplierFilters(): void {
    const carSupplierFilterUnits: CarSupplierFilterUnit[] = [];

    this.unfilteredCars.forEach((car: Car) => {
      if (!car.supplier_name) {
        return;
      }

      const existingSupplier = carSupplierFilterUnits.find((unit: CarSupplierFilterUnit) => {
        return unit.name === car.supplier_name;
      });
      if (existingSupplier) {
        return;
      }

      carSupplierFilterUnits.push({
        isSelected: false,
        id: car.supplier_name.replace(/\s/g, "_").toLowerCase(),
        name: car.supplier_name,
      });
    });

    this.filterForm.carSupplierFilterUnits = SortUtil.string(carSupplierFilterUnits, "name");
  }

  resetCarStaticFilters(): void {
    this.filterForm.carRatingFilterUnits = carRatingFilterOptions.map((rating: string) => ({
      isSelected: false,
      type: CAR_STATIC_FILTER.RATING,
      name: rating,
    }));

    this.filterForm.carSpecificationFilterUnits = carSpecificationFilterOptions.map((specification: string) => ({
      isSelected: false,
      type: CAR_STATIC_FILTER.SPECIFICATION,
      name: specification,
    }));

    this.filterForm.carFuelFilterUnits = carFuelFilterOptions.map((fuel: string) => ({
      isSelected: false,
      type: CAR_STATIC_FILTER.FUEL,
      name: fuel,
    }));

    this.filterForm.carTransmissionFilterUnits = carTransmissionFilterOptions.map((transmission: string) => ({
      isSelected: false,
      type: CAR_STATIC_FILTER.TRANSMISSION,
      name: transmission,
    }));
  }

  updateRedeemPointsFilter(): void {
    this.setRedeemPointsSliderValues();
  }

  updateCashRangeFilter(): void {
    this.setCashSliderValues();
  }

  pushMoreVisibleCars(): void {
    this.displayedCars = this.totalDisplayedCars.slice(0, this.displayedCars.length + this.DEFAULT_RESULT_COUNT);
  }

  private initFilterForm(): void {
    this.filterForm = new FilterForm(
      this.appSettingsService,
      this.globalData,
      "",
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    this.initCarNameFilter();
  }

  private initCarNameFilter(): void {
    this.carNameSubscription?.unsubscribe();
    this.carNameSubscription = this.filterForm.name$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.filter();
    });
  }

  private rebuildCarPrice(): void {
    this.unfilteredCars.forEach((car: Car) => {
      car.priceInfo = new CarPrice(
        this.appSettingsService,
        this.globalData,
        car.priceInfo.payWithPointsCashService,
        car.rawPrice,
      );
    });
  }

  private setRedeemPointsSliderValues(): void {
    if (this.isRedeem) {
      let pointsPayment: number;
      const redeemPointsRange: RedeemPoints = { min: 0, max: 0, ceil: 0, floor: 0 };
      this.unfilteredCars.forEach((car: Car, index: number) => {
        if (this.appSettingsService.showTotalNights) {
          pointsPayment = car.priceInfo.points_payment;
        } else {
          pointsPayment = car.priceInfo.points_payment_per_night;
        }

        if (pointsPayment) {
          if (redeemPointsRange.max < pointsPayment || index === 0) {
            redeemPointsRange.max = pointsPayment;
            redeemPointsRange.ceil = pointsPayment;
          }
          if (redeemPointsRange.min > pointsPayment || index === 0) {
            redeemPointsRange.min = pointsPayment;
            redeemPointsRange.floor = pointsPayment;
          }
        }
      });
      this.filterForm.redeemPoints = redeemPointsRange;
    }
  }

  private setCashSliderValues(): void {
    if (this.landingPage.complimentaryOrRedeem()) {
      return;
    }
    let min: number = 0;
    let max: number = 0;
    this.unfilteredCars.forEach((car: Car, index: number) => {
      const carPrice: number = car.priceInfo?.price;
      if (carPrice) {
        if ((min > carPrice) || index === 0) {
          min = carPrice;
        }
        if ((max < carPrice) || index === 0) {
          max = carPrice;
        }
      }
    });
    this.filterForm.cashRange = { min, max, floor: min, ceil: max };
  }
}
