import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CARD, RawCurrency } from "booking-app-v2/shared/types";
import { Base } from "booking-app-v2/shared/models/index";

export class Currency extends Base {
  readonly code: string;
  readonly text: string;
  readonly rate: number;
  readonly symbol: string;
  readonly preferredGateway: string;
  readonly decimalPlace: number;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    raw: RawCurrency,
  ) {
    super(appSettingsService, globalData);

    this.code = raw.text;
    this.text = raw.text;
    this.symbol = raw.symbol;
    this.preferredGateway = raw.web_preferred_payment_gateway;
    this.decimalPlace = this.buildDecimalPlace(this.code);
    this.rate = raw.rate;
  }

  acceptAmex(): boolean {
    return this.appSettingsService.supportedCards[CARD.AMEX].indexOf(this.code) > -1;
  }

  private buildDecimalPlace(currencyCode: string): number {
    switch (currencyCode) {
      case "IDR":
      case "JPY":
      case "KRW":
        return 0;
      default:
        return 2;
    }
  }
}
