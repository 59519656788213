import { Injectable } from "@angular/core";

import { BaseStep } from "./base-step";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  RedirectToDetailsResult,
} from "booking-app-v2/shared/types";
import { CheckoutError } from "booking-app-v2/shared/models";
import { FlightsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/flights-url-builder.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";

@Injectable({
  providedIn: "root",
})
export class FlightRedirectToDetailsService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.REDIRECT_TO_DETAILS;

  constructor(
    private flightsUrlBuilderService: FlightsUrlBuilderService,
    private windowRefService: WindowRefService,
  ) {}

  process(results: CheckoutResults): RedirectToDetailsResult {
    if (!(results[CHECKOUT_RESULT_NAME.LOGIN_CHECK])) {
      this.flightsUrlBuilderService.redirectToSummaryPage(
        this.windowRefService.getQueryParamFromWindow("bookingKey"),
      );
      throw new CheckoutError("Unauthorised to visit checkout page without login");
    }
  }

}
