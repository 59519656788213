import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { InvalidCreditCardDialogComponent } from "./invalid-credit-card-dialog.component";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";

@NgModule({
  declarations: [ InvalidCreditCardDialogComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ InvalidCreditCardDialogComponent ],
  providers: [ DialogService ],
  entryComponents: [ InvalidCreditCardDialogComponent ],
})

export class InvalidCreditCardDialogModule {}
