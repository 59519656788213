import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSimpleDropdownModule } from "@ascenda/ngx-simple-dropdown";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { FlightDestinationSearchComponent } from "./flight-destination-search.component";

@NgModule({
  declarations: [ FlightDestinationSearchComponent ],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSimpleDropdownModule,
  ],
  exports: [ FlightDestinationSearchComponent ],
  providers: [],
})
export class FlightDestinationSearchModule {}
