import { FormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";

import { Hotel, Room } from "booking-app-v2/hotels/models";
import {
  BookingTransactionData,
  CancellationPolicyResponse,
  CarCheckoutParams,
  CheckoutFormData,
  CheckoutFormResult,
  CreateBookingResponse,
  FlightAvailabilityCheck,
  FlightCheckoutParams,
  HotelCheckoutParams,
  Membership,
  PaymentChannelData,
  StoredPayments,
  TransactionMembership,
} from "booking-app-v2/shared/types";
import { FlightPricesRawResponse } from "booking-app-v2/flights/types";
import { Flight } from "booking-app-v2/flights/models";
import { ObjectValues } from "./object-values.type";
import { CarPricesRawResponse, CarTerms } from "booking-app-v2/cars/types";
import { Car } from "booking-app-v2/cars/models";

export const CHECKOUT_RESULT_NAME = {
  LOGIN_CHECK: "LoginCheck",
  REDIRECT_TO_DETAILS: "RedirectToDetails",
  START_LOADING: "StartLoading",
  BUILD_SEARCH_FORM: "BuildSearchForm",
  FETCH_INFO: "FetchInfo",
  FETCH_BOOKING_TRANSACTION_DATA: "FetchBookingTransactionData",
  FETCH_BOOKING_STATUS: "FetchBookingStatus",
  START_PAYMENT_TIMER: "StartPaymentTimer",
  FETCH_MEMBERSHIP: "FetchMembership",
  FETCH_POLICY: "FetchPolicy",
  CAR_FETCH_TERMS: "CarFetchTerms",
  FETCH_PRICE: "FetchPrice",
  CAR_FETCH_PRICE: "CarFetchPrice",
  FLIGHT_FETCH_PRICE: "FlightFetchPrice",
  POINTS_BALANCE_CHECK: "PointsBalanceCheck",
  BUILD_CHECKOUT_FORM: "BuildCheckoutForm",
  INIT_FORM_DATA: "InitFormData",
  AVAILABILITY_CHECK: "AvailabilityCheck",
  CAR_AVAILABILITY_CHECK: "CarAvailabilityCheck",
  FLIGHT_AVAILABILITY_CHECK: "FlightAvailabilityCheck",
  FETCH_SAVED_CARDS: "FetchSavedCards",
  VALIDATE_COUPON_CODE: "ValidateCouponCode",
  SELECT_PAYMENT_CHANNEL: "SelectPaymentChannel",
  VALIDATE_BOOKING: "ValidateBooking",
  HOTEL_COLLECT_BOOKING_PARAMS: "HotelCollectBookingParams",
  FLIGHT_COLLECT_BOOKING_PARAMS: "FlightCollectBookingParams",
  CAR_COLLECT_BOOKING_PARAMS: "CarCollectBookingParams",
  CREATE_BOOKING: "CreateBooking",
  START_PAYMENT_LOADING: "StartPaymentLoading",
} as const;

export type CheckoutResultName = ObjectValues<typeof CHECKOUT_RESULT_NAME>;

export interface CheckoutResults {
  intermediateCheckoutParams: IntermediateCheckoutParams;
  checkoutErrorKey$: Subject<string>;
  checkoutLoadingMessage$: Subject<string>;
  isPaymentLoading: boolean;
  [CHECKOUT_RESULT_NAME.LOGIN_CHECK]?: LoginCheckResult;
  [CHECKOUT_RESULT_NAME.REDIRECT_TO_DETAILS]?: RedirectToDetailsResult;
  [CHECKOUT_RESULT_NAME.START_LOADING]?: StartLoadingResult;
  [CHECKOUT_RESULT_NAME.BUILD_SEARCH_FORM]?: BuildSearchFormResult;
  [CHECKOUT_RESULT_NAME.FETCH_INFO]?: FetchInfoResult;
  [CHECKOUT_RESULT_NAME.FETCH_BOOKING_TRANSACTION_DATA]?: FetchBookingTransactionDataResult;
  [CHECKOUT_RESULT_NAME.FETCH_BOOKING_STATUS]?: FetchBookingStatusResult;
  [CHECKOUT_RESULT_NAME.START_PAYMENT_TIMER]?: StartPaymentTimerResult;
  [CHECKOUT_RESULT_NAME.FETCH_MEMBERSHIP]?: FetchMembershipResult;
  [CHECKOUT_RESULT_NAME.FETCH_POLICY]?: FetchPolicyResult;
  [CHECKOUT_RESULT_NAME.CAR_FETCH_TERMS]?: CarFetchTermsResult;
  [CHECKOUT_RESULT_NAME.FETCH_PRICE]?: FetchPriceResult;
  [CHECKOUT_RESULT_NAME.CAR_FETCH_PRICE]?: CarFetchPriceResult;
  [CHECKOUT_RESULT_NAME.FLIGHT_FETCH_PRICE]?: FlightFetchPriceResult;
  [CHECKOUT_RESULT_NAME.POINTS_BALANCE_CHECK]?: PointsBalanceCheckResult;
  [CHECKOUT_RESULT_NAME.BUILD_CHECKOUT_FORM]?: BuildCheckoutFormResult;
  [CHECKOUT_RESULT_NAME.INIT_FORM_DATA]?: InitFormDataResult;
  [CHECKOUT_RESULT_NAME.AVAILABILITY_CHECK]?: AvailabilityCheckResult;
  [CHECKOUT_RESULT_NAME.CAR_AVAILABILITY_CHECK]?: CarAvailabilityCheckResult;
  [CHECKOUT_RESULT_NAME.FLIGHT_AVAILABILITY_CHECK]?: FlightAvailabilityCheckResult;
  [CHECKOUT_RESULT_NAME.FETCH_SAVED_CARDS]?: FetchSavedCardsResult;
  [CHECKOUT_RESULT_NAME.VALIDATE_COUPON_CODE]?: ValidateCouponCodeResult;
  [CHECKOUT_RESULT_NAME.SELECT_PAYMENT_CHANNEL]?: SelectPaymentChannelResult;
  [CHECKOUT_RESULT_NAME.VALIDATE_BOOKING]?: ValidateBookingResult;
  [CHECKOUT_RESULT_NAME.START_PAYMENT_LOADING]?: StartPaymentLoadingResult;
  [CHECKOUT_RESULT_NAME.HOTEL_COLLECT_BOOKING_PARAMS]?: HotelCollectBookingParamsResult;
  [CHECKOUT_RESULT_NAME.FLIGHT_COLLECT_BOOKING_PARAMS]?: FlightCollectBookingParamsResult;
  [CHECKOUT_RESULT_NAME.CAR_COLLECT_BOOKING_PARAMS]?: CarCollectBookingParamsResult;
}

export type LoginCheckResult = boolean;
export type RedirectToDetailsResult = void;
export type StartLoadingResult = void;
export type StartPaymentLoadingResult = void;
export type BuildSearchFormResult = void;
export type FetchInfoResult = Observable<Hotel>;
export interface FetchBookingTransactionDataResult {
  transactionId: string;
  transactionData: Observable<BookingTransactionData>;
}
export type FetchBookingStatusResult = void;
export type StartPaymentTimerResult = void;
export type FetchMembershipResult = Observable<Membership[]>;
export type BuildCheckoutFormResult = Observable<CheckoutFormResult>;
export type FetchPolicyResult = Observable<CancellationPolicyResponse>;
export type CarFetchTermsResult = Observable<CarTerms>;
export type FetchPriceResult = Observable<Room>;
export type CarFetchPriceResult = Observable<CarPricesRawResponse>;
export type PointsBalanceCheckResult = void;
export type InitFormDataResult = Observable<CheckoutFormData>;
export type AvailabilityCheckResult = void;
export type CarAvailabilityCheckResult = Car;
export type FetchSavedCardsResult = Observable<StoredPayments>;
export type ValidateCouponCodeResult = Observable<boolean>;
export type SelectPaymentChannelResult = PaymentChannelData;
export type ValidateBookingResult = void;
export type HotelCollectBookingParamsResult = HotelCheckoutParams;
export type FlightCollectBookingParamsResult = FlightCheckoutParams;
export type CarCollectBookingParamsResult = CarCheckoutParams;
export type CreateBookingResult = CreateBookingResponse;
export type FlightFetchPriceResult = FlightPricesRawResponse;
export type FlightAvailabilityCheckResult = FlightAvailabilityCheck;
export type SkipCheckoutStepResult = void;
export interface IntermediateCheckoutParams {
  checkoutForm?: FormGroup;
  room?: Room;
  hotel?: Hotel;
  familyMilesSelected?: boolean;
  voucherIds?: string[];
  cardType?: string;
  membership?: Membership | TransactionMembership;
  flight?: Flight;
  car?: Car;
}

export type CheckoutResult =
  | LoginCheckResult
  | RedirectToDetailsResult
  | StartLoadingResult
  | BuildSearchFormResult
  | FetchInfoResult
  | FetchBookingTransactionDataResult
  | FetchBookingStatusResult
  | StartPaymentTimerResult
  | FetchMembershipResult
  | FetchPolicyResult
  | CarFetchTermsResult
  | FetchPriceResult
  | CarFetchPriceResult
  | FlightFetchPriceResult
  | PointsBalanceCheckResult
  | BuildCheckoutFormResult
  | InitFormDataResult
  | AvailabilityCheckResult
  | CarAvailabilityCheckResult
  | FlightAvailabilityCheckResult
  | FetchSavedCardsResult
  | ValidateCouponCodeResult
  | SelectPaymentChannelResult
  | ValidateBookingResult
  | HotelCollectBookingParamsResult
  | FlightCollectBookingParamsResult
  | CarCollectBookingParamsResult
  | StartPaymentLoadingResult
  | CreateBookingResult
  | SkipCheckoutStepResult;
