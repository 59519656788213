import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { Language } from "booking-app-v2/shared/types";
import { Base } from "booking-app-v2/shared/models/index";

export class Locale extends Base {
  readonly code: string;
  readonly text: string;
  readonly countryCode: string;
  readonly langCode: string;
  readonly flagCode: string;
  readonly comma: string;
  readonly numSeparator: string;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    language: Language,
  ) {
    super(appSettingsService, globalData);

    this.code = language.code;
    this.text = language.name;
    this.countryCode = language.country_code;
    this.langCode = language.lang_code;
    this.flagCode = language.flag_code;
    this.comma = this.useCommaAsDecimal(language.code);
    this.numSeparator = this.separator(language.code);
  }

  private useCommaAsDecimal(code: string): string {
    const locales: string[] = ["da", "de", "es", "fi", "fr", "gr",
                              "id", "it", "lv", "nl", "no", "pt-BR",
                              "ru", "sv", "tr", "vn"];

    if (locales.includes(code)) {
      return ",";
    } else {
      return ".";
    }
  }

  private separator(code: string): string {
    switch (true) {
      case ["da", "de", "es", "fi", "gr", "id", "it", "lv", "nl",
            "no", "pt-BR", "tr", "vn"].includes(code) :
        return ".";
        break;

      case ["fr", "ru", "sv"].includes(code) :
        return " ";
        break;

      default:
        return ",";
    }
  }

}
