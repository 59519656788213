import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { BootstrapDataService } from "booking-app-v2/shared/services/bootstrap-data.service";
import { GlobalDataEnum, PRODUCT_TYPE, ProductType } from "booking-app-v2/shared/types";
import { LandingPage } from "booking-app-v2/shared/models";

@Component({
  selector: "product-type-switch",
  template: `
    <div class="switch-container" *ngIf="isProductTypeSwitchEnabled">
      <span class="form-input-radio" *ngFor="let value of productTypes">
        <input
          id="{{ 'product-type-' + value }}"
          type="radio"
          name="product-type"
          [ngModel]="selectedProductType"
          (ngModelChange)="setProductType(value)"
          [value]="value"
        >
        <label class="input-label" for="{{ 'product-type-' + value }}"
        [translate]="productTypeMapping[value]"></label>
      </span>
    </div>
  `,
})

export class ProductTypeSwitchComponent {
  productTypes: ProductType[];
  landingPage: LandingPage;
  selectedProductType: string;

  readonly productTypeMapping: { [productType in ProductType]?: string } = {
    [PRODUCT_TYPE.EARN]: "txt.product_type.earn",
    [PRODUCT_TYPE.REDEEM]: "txt.product_type.redeem",
    [PRODUCT_TYPE.CASHVOUCHER]: "txt.product_type.cash_voucher",
    [PRODUCT_TYPE.VOUCHER]: "txt.product_type.voucher",
  };
  readonly isProductTypeSwitchEnabled: boolean;

  constructor(
    private router: Router,
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private windowRefService: WindowRefService,
    private bootstrapDataService: BootstrapDataService,
  ) {
    this.productTypes = this.appSettingsService.supportedProductTypes;
    this.landingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
    this.selectedProductType = this.getProductType();
    this.isProductTypeSwitchEnabled =
      !this.bootstrapDataService.bootstrapData.feature_flags.disable_header_product_type_switcher;
  }

  setProductType(productType: ProductType): void {
    this.landingPage.setUrl(this.appSettingsService.tenant, productType);
    this.router.navigate([], {
      queryParams: { landingPage: this.landingPage.url },
      queryParamsHandling: "merge",
    }).then(() => this.windowRefService.nativeWindow.location.reload());
  }

  getProductType(): ProductType {
    return this.globalData.get(GlobalDataEnum.PRODUCT_TYPE);
  }
}
