import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { HotelDestinationValue, RawHotelDestination } from "../types";
import { Base } from "booking-app-v2/shared/models";

export class HotelDestination extends Base {
  label: string;
  value: HotelDestinationValue;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    rawHotelDestination: RawHotelDestination,
  ) {
    super(appSettingsService, globalData);

    this.label = rawHotelDestination.term;
    this.value = {
      destination_id: rawHotelDestination.value,
      type: rawHotelDestination.type,
      lat: rawHotelDestination.lat,
      lng: rawHotelDestination.lng,
      hotel_id: rawHotelDestination.hotel_id,
    };
  }
}
