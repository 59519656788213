import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { InputCounterModule } from "../input-counter/input-counter.module";
import { PassengersFieldMobileComponent } from "./passengers-field-mobile.component";
import { BottomSheetWrapperModule } from "booking-app-v2/shared/components/bottom-sheet-wrapper/bottom-sheet-wrapper.module";

@NgModule({
  declarations: [PassengersFieldMobileComponent],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    InputCounterModule,
    BottomSheetWrapperModule,
  ],
  exports: [PassengersFieldMobileComponent],
  providers: [],
})
export class PassengersFieldMobileModule { }
