import { Routes, UrlSegment } from "@angular/router";

import { LandingPageComponent } from "../landing-page.component";

export const landingPageRoutes: Routes = [
  {
    matcher: landingPageRouteMatch,
    component: LandingPageComponent,
  },
];

export function landingPageRouteMatch(url: UrlSegment[]) {
  if (url.length === 0) {
    return { consumed: url };
  }
  if (url.length === 1) {
    const path = url[0].path;
    if (path === "hotels" || path === "cars" || path === "flights") {
      return {consumed: url};
    }
  }
  return null;
}
