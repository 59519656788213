
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, filter, map, Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { DestinationService } from "booking-app-v2/shared/services/destination.service";
import { FlightDestination } from "booking-app-v2/flights/models/flight-destination.model";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { FlightSearchForm } from "booking-app-v2/flights/models";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

@Component({
  selector: "flight-destination-search",
  template: `
    <div class="flight-destination-search-container">
      <input
        class="search-input"
        [class.invalid]="isFlightDestinationFieldInvalid()"
        (keyup)="searchTerm$.next($event)"
        [placeholder]="placeHolder"
        [value]="intermediateSearchValue || ''"
        (focus)="searchFocus()"
        (focusout)="searchFocusOut()"
        (clickOutside)="closeDestinationSearch()"
        [attr.aria-label]="inputAriaLabel"
      />
      <ul *ngIf="(searchInProgress || !hasFlightDestinations()) && searchStatusMsg" class="dropdown-container no-items">
        <li class="no-item"> {{ searchStatusMsg | translate }} </li>
      </ul>
      <ul *ngIf="hasFlightDestinations() && !searchInProgress" class="dropdown-container">
        <li *ngFor="let destination of flightDestinations | slice:0:9"
          (click)="selectFlightDestination(destination)" tabindex="0"
          (keyup.enter)="selectFlightDestination(destination)"
          class= "item" [attr.aria-label]="getListItemAriaLabel(destination)">
            <span [innerHTML]= "destination.searchResultQueryFormat"> </span>
            <br/>
            <small>
              {{ destination.country }}
            </small>
        </li>
      </ul>
      <div class="dirtyMsg tooltips" *ngIf="isFlightDestinationFieldInvalid()">
        <span role="alert">{{ "wl.please_fill_in_a_destination" | translate }}</span>
      </div>
    </div>
  `,
})

export class FlightDestinationSearchComponent implements OnInit, OnChanges {
  @Input() parentForm: FormGroup;
  @Input() placeHolder: string;
  @Input() inputAriaLabel: string;

  // defaultValue is only passed for fromFlightDestination
  @Input() defaultValue: FlightDestination;
  @Output() onSelectFlightDestinationSearch: EventEmitter<FlightDestination> = new EventEmitter<FlightDestination>();

  flightDestinations: FlightDestination[] = [];
  selectedFlightDestination: FlightDestination;
  intermediateSearchValue: string;
  searchTerm$ = new Subject<string>();
  searchInProgress: boolean = false;
  searchStatusMsg: string = null;
  flightSearchForm: FlightSearchForm;

  constructor(
    private destinationService: DestinationService,
    private translateService: TranslateService,
    private globalData: GlobalData,
    protected appSettingsService: AppSettingsService,
  ) {
    this.placeHolder = this.placeHolder || "Destination";
    this.inputAriaLabel = this.inputAriaLabel || "Type and select a destination from the list";
    this.flightSearchForm = this.globalData.get(GlobalDataEnum.FLIGHT_SEARCH_FORM);
    this.initializeSearchTerm();
  }

  ngOnInit(): void {
    if (this.defaultValue) {
      this.selectFlightDestination(this.defaultValue);
    } else if (this.flightSearchForm?.toFlightDestination) {
      this.selectFlightDestination(this.flightSearchForm.toFlightDestination);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultValue?.currentValue !== changes.defaultValue?.previousValue) {
      this.selectFlightDestination(changes.defaultValue.currentValue);
    }
  }

  public fetchFlightDestinations(term: string): void {
    this.searchInProgress = true;
    this.searchStatusMsg = this.translateService.instant("Searching ...");
    this.destinationService.getFlightDestinations(term).subscribe((flightDestinations: FlightDestination[]) => {
      this.searchInProgress = false;
      this.flightDestinations = flightDestinations;
      if (flightDestinations.length === 0) {
        this.handleSearchHasNoLocations();
      }
    });
  }

  public closeDestinationSearch(): void {
    this.flightDestinations = [];
    this.searchStatusMsg = null;
  }

  public selectFlightDestination(selectedFlightDestination: FlightDestination): void {
    // TODO: Add logic to store the selected destination in global-data
    // Then in results page we need to get the selected value and
    // Assign to the default value of this.selectedFlightDestination & this.intermediateSearchValue

    this.closeDestinationSearch();
    this.selectedFlightDestination = selectedFlightDestination;
    this.intermediateSearchValue = this.selectedFlightDestination?.getSearchResultQueryFormat();
    this.onSelectFlightDestinationSearch.emit(selectedFlightDestination);
  }

  public searchFocus(): void {
    this.searchStatusMsg = this.translateService.instant("e.g. city or region");
    if (this.selectedFlightDestination) {
      this.intermediateSearchValue = null;
    }
  }

  public searchFocusOut(): void {
    this.intermediateSearchValue = this.selectedFlightDestination?.getSearchResultQueryFormat() ?? null;
  }

  public hasFlightDestinations(): boolean {
    return this.flightDestinations.length > 0;
  }

  public getListItemAriaLabel(destination: FlightDestination): string {
    return `Select ${destination.term}`;
  }

  public isFlightDestinationFieldInvalid(): boolean {
    const hasErrors = this.defaultValue
                        ? this.parentForm.controls.fromFlightDestination.errors?.required
                        : this.parentForm.controls.toFlightDestination.errors?.required;

    const isDirty = this.defaultValue
                      ? this.parentForm.controls.fromFlightDestination.dirty
                      : this.parentForm.controls.toFlightDestination.dirty;

    return hasErrors && isDirty && !this.selectedFlightDestination;
  }

  private initializeSearchTerm(): void {
    this.searchTerm$.pipe(
      map((e: any) => e.target.value),
      debounceTime(100),
      distinctUntilChanged(),
      filter(term => term.length > 1),
    ).subscribe(term => {
      this.fetchFlightDestinations(term);
    });
  }

  private handleSearchHasNoLocations(): void {
    this.searchStatusMsg = this.translateService.instant("Airport Not Found");
  }
}
