import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";

@Component({
  selector: "room-guest-field",
  template: `
    <div class="room-guest-field" (clickOutside)="closeModal()">
      <div class="room-guest-field-input" [class.is-focused]="modalOpen"
        (click)="toggleModal()" tabindex=0 (keyup.enter)="toggleModal()"
        [attr.aria-label]="summaryTextAriaLabel" role="button">
        <ng-content select="[icon-content]"></ng-content>
        <input
          class="field-input"
          type="text"
          readonly
          [value]="summaryText"
          *ngIf="!labelOnlySummaryText"
        >
        <span class="field-input"
          [innerHTML]="summaryText"
          *ngIf="labelOnlySummaryText">
        </span>
      </div>

      <div class="room-guest-field-modal" *ngIf="modalOpen">
        <room-guest-counter
          [roomCount]="roomCount"
          [adultCount]="adultCount"
          [childCount]="childCount"
          [childrenAges]="childrenAges"
          (onUpdateRoomCount)="updateRoomCount($event)"
          (onUpdateAdultCount)="updateAdultCount($event)"
          (onUpdateChildCount)="updateChildCount($event)"
          (onUpdateChildrenAges)="updateChildrenAges($event)"
          (closeModal)="closeModal()"
        ></room-guest-counter>
      </div>
    </div>
  `,
})
export class RoomGuestFieldComponent {
  @Input() roomCount: number;
  @Input() adultCount: number;
  @Input() childCount: number;
  @Input() childrenAges: number[];
  @Input() isShowingTotalGuest: boolean;
  @Output() onUpdateRoomCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateAdultCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateChildCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateChildrenAges: EventEmitter<number[]> = new EventEmitter<number[]>();

  // local state
  modalOpen: boolean = false;
  labelOnlySummaryText: boolean = this.appSettingsService.roomGuestParameters.labelOnlySummaryText;

  constructor(
    protected translateService: TranslateService,
    protected appSettingsService: AppSettingsService,
  ) { }

  get summaryText(): string {
    let res: string = "";

    if (this.roomCount > 0) {
      res += `${ this.roomCaption() } ${ this.separator() } `;
    }

    if (this.isShowingTotalGuest) {
      res += `${this.totalGuestsCaption()}`;
    } else {
      res += `${this.adultCaption()}`;

      if (this.childCount > 0) {
        res += `, ${this.childCaption()}`;
      }
    }
    return res;
  }

  get summaryTextAriaLabel(): string {
    return this.summaryText.replace(this.separator(), ",") + " selected dropdown menu";
  }

  toggleModal(): void {
    this.modalOpen = !this.modalOpen;
  }

  closeModal(): void {
    this.modalOpen = false;
  }

  updateRoomCount(count: number): void {
    this.roomCount = count;
    this.onUpdateRoomCount.emit(count);
  }

  updateAdultCount(count: number): void {
    this.adultCount = count;
    this.onUpdateAdultCount.emit(count);
  }

  updateChildCount(count: number): void {
    this.childCount = count;
    this.onUpdateChildCount.emit(count);
  }

  updateChildrenAges(childrenAges: number[]): void {
    this.childrenAges = childrenAges;
    this.onUpdateChildrenAges.emit(childrenAges);
  }

  protected shouldRenderChildrenCounter(): boolean {
    return Number.isInteger(this.childCount);
  }

  protected adultCaption(): string {
    if (this.shouldRenderChildrenCounter()) {
      return this.showLabelAsAdult();
    } else {
      return this.showLabelAsGuest();
    }
  }

  protected showLabelAsAdult(): string {
    if (this.adultCount === 1) {
      return this.translateService.instant("count.adult_one");
    } else {
      return this.translateService.instant("count.adult_other", { count: this.adultCount });
    }
  }

  protected showLabelAsGuest(): string {
    if (this.adultCount === 1) {
      return this.translateService.instant("wl.single_guest_count_per_room");
    } else {
      return this.translateService.instant("wl.multiple_guest_count_per_room", { count: this.adultCount });
    }
  }

  protected childCaption(): string {
    if (this.childCount === 1) {
      return this.translateService.instant("count.children_one");
    } else {
      return this.translateService.instant("count.children_other", { count: this.childCount });
    }
  }

  protected roomCaption(): string {
    if (this.roomCount === 1) {
      return this.translateService.instant("Single Room Count");
    } else {
      return this.translateService.instant("Multiple Room Count", { count: this.roomCount });
    }
  }

  protected totalGuestsCaption(): string {
    const totalGuests = this.adultCount + this.childCount;
    return totalGuests === 1 ?
      this.translateService.instant("count.guest_per_room.one") :
      this.translateService.instant("count.guest_per_room.other", { count: totalGuests });
  }

  protected separator(): string {
    if (this.labelOnlySummaryText) {
      return `<span class="separator"> | </span>`;
    } else {
      return `|`;
    }
  }
}
