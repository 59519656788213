import { LandingPage, Price } from "booking-app-v2/shared/models";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";
import { TimeUtils } from "booking-app-v2/shared/utils";
import { GlobalDataEnum, PRODUCT_TYPE } from "booking-app-v2/shared/types";
import { HotelRawPrice, MarketRate, Taxes, TaxesAndFees } from "../types";
import { HotelSearchForm } from ".";
import { CommonPriceDecorator, CommonRedeemDecorator } from "booking-app-v2/shared/decorators";
import {
  AdminRatesDecorator,
  ComplimentaryNightsDecorator,
  ComplimentaryNightsEarnDecorator,
  DiscountRatesDecorator,
  HotelPriceDecorator,
  HotelRoomPriceDecorator,
  MarketRatesDecorator,
  PricePerNightDecorator,
  RoomAdminRatesDecorator,
  FeeBreakdownDecorator,
} from "../decorators";

export class RoomPrice extends Price {
  priceWithTax: number;
  bonus: number;
  searchRank: number;
  source: string;
  bestDealMultiplier: number;
  marketRates: MarketRate[];
  price: number;
  voucher_type_id: number;
  rate: number;
  pricePerNight: number;
  pricePerRoomPerNight: number;
  lowestNetRate: number;
  adminPricePerNight: number;
  margin: number;
  compRate: number;
  complimentary_nights: number;
  complimentary_discount: number;
  original_price: number;
  original_price_per_room_per_night: number;
  original_lowest_price_per_room_per_night: number;
  original_lowest_points_per_room_per_night: number;
  discount: number;
  originalPricePerRoomPerNight: number;
  points_payment: number;
  points_payment_per_night: number;
  original_points_payment: number;
  original_points_payment_per_night: number;
  minPoint: number;
  cash_payment: number;
  original_cash_payment: number;
  cash_payment_per_night: number;
  original_cash_payment_per_night: number;
  earnOnBurnValue?: number;
  formattedPrice: string;
  points: number; // In v1 we use both score and points, which are the same. but in v2, points will be used in all the places.
  chargeableRate: number;
  chargeableRateWithoutTax: number;
  converted_complimentary_discount: number;
  leftToPay: number;
  hotelPriceIsReady: boolean;
  roomBookingVoucherType: string;

  // used in checkout
  taxes: Taxes;
  totalPrice: number;
  totalPriceWithoutTax: number;
  totalPriceWithoutFeeAndTax: number;
  complimentaryTotalPriceWithoutTax: number;
  complimentaryAverageRoomPrice: number;
  averageRoomPrice: string;
  hotelFees: number;
  kaligoServiceFee: number;
  totalRoomPriceWithoutFeeAndTax: string;
  totalRoomPriceWithoutFeeAndTaxPerNight: string;
  formattedOriginalPrice: string;
  formattedComplimentaryTotalPriceWithoutTax: string;
  formattedComplimentaryAverageRoomPrice: string;
  formattedComplimentaryDiscount: string;
  propertyFee: string;
  salesTax: string;
  taxAndRecoveryCharges: string;
  hotelOccupancyTax: string;
  formattedKaligoServiceFee: string;
  formattedHotelFees: string;
  formattedSubtotal: string;
  formattedTotalVoucher: string;
  grandTotal: string;

  // fee breakdown
  baseRate: string = "0";
  includedTaxesAndFeesTotal: string = "0";
  excludedTaxesAndFeesCurrency: string = "";
  excludedTaxesAndFeesTotal: string = "0";
  excludedTaxesAndFeesTotalInCurrency: number = 0;
  includedTaxesAndFees: TaxesAndFees = [];

  private numberOfNights: number;
  private roomsCount: number;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    currenciesService: CurrenciesService,
    payWithPointsCashService: PayWithPointsCashService,
    hotelRawPrice: HotelRawPrice,
  ) {
    super(appSettingsService, globalData, payWithPointsCashService);

    this.initHotelSearchFormDetails();

    if (hotelRawPrice.price_type === "multi") {
      this.runHotelDecorators(hotelRawPrice, currenciesService);
    } else {
      this.runRoomDecorators(hotelRawPrice, currenciesService);
    }

    this.decorateByProductType(globalData, hotelRawPrice, payWithPointsCashService);

    this.hotelPriceIsReady = true;
  }

  private runHotelDecorators(hotelRawPrice: HotelRawPrice, currenciesService: CurrenciesService) {
    CommonPriceDecorator.decorate(this, hotelRawPrice);
    MarketRatesDecorator.decorate(this, hotelRawPrice, this.numberOfNights, this.roomsCount);
    HotelPriceDecorator.decorate(this, hotelRawPrice, this.numberOfNights, this.roomsCount);
    PricePerNightDecorator.decorate(this, currenciesService, this.numberOfNights, this.roomsCount);
    AdminRatesDecorator.decorate(this, hotelRawPrice, this.numberOfNights, this.roomsCount);
    DiscountRatesDecorator.decorate(this, hotelRawPrice);
    ComplimentaryNightsEarnDecorator.decorate(this, hotelRawPrice);
  }

  private runRoomDecorators(hotelRawPrice: HotelRawPrice, currenciesService: CurrenciesService) {
    CommonPriceDecorator.decorate(this, hotelRawPrice);
    HotelRoomPriceDecorator.decorate(this, hotelRawPrice);
    PricePerNightDecorator.decorate(this, currenciesService, this.numberOfNights, this.roomsCount);
    RoomAdminRatesDecorator.decorate(this, hotelRawPrice, this.numberOfNights, this.roomsCount);
    DiscountRatesDecorator.decorate(this, hotelRawPrice);
    ComplimentaryNightsEarnDecorator.decorate(this, hotelRawPrice);
    FeeBreakdownDecorator.decorate(this, hotelRawPrice, currenciesService);
  }

  private decorateByProductType(
    globalData: GlobalData,
    hotelRawPrice: HotelRawPrice,
    payWithPointsCashService: PayWithPointsCashService,
  ) {
    const landingPage: LandingPage = globalData.get(GlobalDataEnum.LANDING_PAGE);

    if (landingPage.hasProductType(PRODUCT_TYPE.REDEEM)) {
      CommonRedeemDecorator.decorate(
        this,
        hotelRawPrice,
        this.numberOfNights * this.roomsCount,
        payWithPointsCashService,
      );
      this.earnOnBurnValue = payWithPointsCashService.earnOnBurnValue(
        payWithPointsCashService.getCalculatedCash(hotelRawPrice),
        hotelRawPrice,
      );
    } else if (landingPage.hasProductType(PRODUCT_TYPE.COMPLIMENTARY_NIGHTS)) {
      ComplimentaryNightsDecorator.decorate(this, hotelRawPrice);
    }
  }

  private initHotelSearchFormDetails(): void {
    const hotelSearchForm: HotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);

    this.numberOfNights = TimeUtils.diff(hotelSearchForm.checkOutDate, hotelSearchForm.checkInDate, "days");
    this.roomsCount = hotelSearchForm.rooms;
  }
}
