import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { ContactUsPageRoutingModule } from "../routes/contact-us-page-routing.module";
import { ContactUsPageComponent } from "../contact-us-page.component";
import { ContactUsService } from "../services/contact-us.service";

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ContactUsPageRoutingModule,
  ],
  declarations: [
    ContactUsPageComponent,
  ],
  providers: [
    ContactUsService,
  ],
})
export class SharedContactUsPageModule {}
