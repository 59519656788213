import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";

@Injectable({
  providedIn: "root",
})
export class WhitelabelTranslateService {
  constructor(
    private translateService: TranslateService,
    private appSettingsService: AppSettingsService,
  ) { }

  translate(translateString: string, translateValues?: any): string {
    let translationKey: string = `wl.${this.appSettingsService.tenant.toLowerCase()}.${translateString}`;
    let translatedText: string = this.translateService.instant(
      translationKey,
      translateValues,
    );

    if (translatedText === translationKey && this.appSettingsService.wlTranslationPath) {
      translationKey = `${this.appSettingsService.wlTranslationPath}.${translateString}`;
      translatedText = this.translateService.instant(
        translationKey,
        translateValues,
      );
    }

    if (translatedText === translationKey) {
      translatedText = this.translateService.instant(
        translateString,
        translateValues,
      );
    }

    return translatedText;
  }
}
