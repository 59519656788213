import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { GalleryDialogComponent } from "./gallery-dialog.component";
import { CarouselModule } from "booking-app-v2/shared/components/carousel/carousel.module";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";

@NgModule({
  declarations: [ GalleryDialogComponent ],
  imports: [
    SharedModule,
    CarouselModule,
  ],
  exports: [ GalleryDialogComponent ],
  providers: [ DialogService ],
  entryComponents: [ GalleryDialogComponent ],
})
export class GalleryDialogModule {}
