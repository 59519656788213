import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { VoucherService } from "booking-app-v2/shared/services/voucher.service";
import { Room } from "booking-app-v2/hotels/models";
import { Voucher } from "booking-app-v2/shared/types";
import { voucherCodeErrorValidator } from "booking-app-v2/shared/validators";
import { Car } from "booking-app-v2/cars/models";

@Component({
  selector: "voucher-field",
  templateUrl: "/html/components/voucher_field_v2",
})
export class VoucherFieldComponent implements OnInit {
  @Input() index: number;
  @Input() travelItem: Room | Car;
  @Input() bookingVoucherType: string;
  @Input() isSingleVoucherForm?: boolean;

  voucherForm: FormGroup;

  constructor(
    private voucherService: VoucherService,
  ) { }

  ngOnInit(): void {
    this.voucherForm = new FormGroup({
      voucherCode: new FormControl("", {
        asyncValidators: voucherCodeErrorValidator(this.voucherService, this.travelItem),
        updateOn: "submit",
      }),
    });
  }

  get voucher(): Voucher {
    return this.voucherService.vouchers[this.index];
  }

  notAllowedToAddVoucher(): boolean {
    return (!this.bookingVoucherType && !this.isSingleVoucherForm) ||
      this.voucherService.vouchers.length !== this.index;
  }

  isValidateVoucherButtonDisabled(): boolean {
    return this.voucherForm.controls.voucherCode.pending || this.notAllowedToAddVoucher();
  }
}
