import { Injectable } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";

import { BaseStep } from "./base-step";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import {
  CancellationPolicyResponse,
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  FetchPolicyResult,
} from "booking-app-v2/shared/types";
import { CancellationPolicy } from "booking-app-v2/shared/models";
import { HotelCancellationPolicyService } from "booking-app-v2/hotels/services/hotel-cancellation-policy.service";

@Injectable({
  providedIn: "root",
})
export class HotelFetchPolicyService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.FETCH_POLICY;

  constructor(
    private hotelCancellationPolicyService: HotelCancellationPolicyService,
    private windowRefService: WindowRefService,
  ) {}

  process(results: CheckoutResults): FetchPolicyResult {
    const bookingKey: string = this.windowRefService.getQueryParamFromWindow("bookingKey");
    const result: Subject<CancellationPolicyResponse> = new ReplaySubject<CancellationPolicyResponse>(1);

    this.hotelCancellationPolicyService.fetch(bookingKey).subscribe({
      next: (policy: CancellationPolicy) => {
        if (policy) {
          result.next({ policy });
        } else {
          result.next({ invalid: true, bookingKey });
        }
      },
      error: () => result.next({ invalid: true, bookingKey }),
    });

    return result.asObservable();
  }

}
