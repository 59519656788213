import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { TimeUtils } from "booking-app-v2/shared/utils";
import { Base } from "booking-app-v2/shared/models/index";

export class CancellationPolicyClause extends Base {
  readonly from: string;
  readonly to: string;
  readonly text?: string;
  readonly percentage?: number;
  amount?: string;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    policy: CancellationPolicyClause,
  ) {
    super(appSettingsService, globalData);

    this.from = policy.from;
    this.to = policy.to;
    this.amount = policy.amount;
    this.percentage = policy.percentage;
    this.text = policy.text ? policy.text : `booking_detail.cancellation_policy.clause.in_between`;
  }

  formatFromDateInFormat(format: string): string {
    return TimeUtils.format(this.from, format);
  }

  formatToDateInFormat(format: string): string {
    return TimeUtils.format(this.to, format);
  }
}
