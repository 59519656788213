import { Component, Input } from "@angular/core";
import { RoomPrice } from "booking-app-v2/hotels/models";

@Component({
  selector: "hotel-tax-and-fees-accordion",
  template: `
    <accordion *ngIf="renderAccordion" class="accordion-element" [disableDropdownArrow]="true">
      <div accordionTitle>
        <div class="price-list">
          <div class="label pull-left">
            <span translate="checkout.tax_breakdown.label.fee_breakdown"></span>
            <em class="fa fa-angle-down"></em>
          </div>
          <div class="value pull-left text-right cash-value">
            <span class="currency" [textContent]="currencyCode"></span>
            <span [textContent]="priceInfo.includedTaxesAndFeesTotal"></span>
          </div>
        </div>
      </div>
      <div accordionContent>
        <div>
          <div class="price-list" *ngFor="let taxAndFeeItem of priceInfo.includedTaxesAndFees">
            <div class="label pull-left">
              <span translate="checkout.tax_breakdown.label.{{ taxAndFeeItem.id }}"></span>
            </div>
            <div class="value pull-left text-right cash-value">
              <span class="currency" [textContent]="currencyCode"></span>
              <span [textContent]="taxAndFeeItem.amount"></span>
            </div>
          </div>
        </div>
      </div>
    </accordion>
    <div *ngIf="!renderAccordion">
      <div class="price-list">
        <div class="label pull-left">
          <span translate="checkout.tax_breakdown.label.{{ priceInfo.includedTaxesAndFees[0].id }}"></span>
        </div>
        <div class="value pull-left text-right cash-value">
          <span class="currency" [textContent]="currencyCode"></span>
          <span [textContent]="priceInfo.includedTaxesAndFees[0].amount"></span>
        </div>
      </div>
    </div>
  `,
})
export class HotelTaxAndFeesAccordionComponent {
  @Input() currencyCode: string;
  @Input() priceInfo: RoomPrice;

  renderAccordion: boolean;

  ngOnInit() {
    this.renderAccordion = this.priceInfo.includedTaxesAndFees.length > 1;
  }
}
