import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { SimpleDialogComponent } from "./simple-dialog.component";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";

@NgModule({
  declarations: [ SimpleDialogComponent ],
  imports: [
    SharedModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [ SimpleDialogComponent ],
  providers: [ DialogService ],
  entryComponents: [ SimpleDialogComponent ],
})
export class SimpleDialogModule {}
