import { Inject, Injectable } from "@angular/core";
import { HttpResponse } from "@angular/common/http";
import { forkJoin, map, retry } from "rxjs";

import { ApiDataService } from "./api-data.service";
import { GlobalData } from "./global-data.service";
import { AppSettingsService } from "./app-settings.service";
import { BootstrapData, GlobalDataEnum } from "booking-app-v2/shared/types";
import { User } from "booking-app-v2/shared/models";

@Injectable({
  providedIn: "root",
})
export class BootstrapDataService {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  private _bootstrapData: BootstrapData;
  private _userData: User;

  constructor(
    private apiDataService: ApiDataService,
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
  ) { }

  get bootstrapData(): BootstrapData {
    return this._bootstrapData;
  }

  get userData(): User {
    return this._userData;
  }

  public loadBootstrapData(): Promise<any> {
    this._bootstrapData = null;
    this._userData = null;

    // Rxjs Equivalent of promise.all
    return forkJoin([this.fetchGenericData(), this.fetchUserData()])
      .toPromise()
      .then((results: any[]) => {
        this._bootstrapData = results[0];
        this._userData = results[1];
        this.globalData.set(GlobalDataEnum.SELECTED_COUNTRY_SITE, { code: this._bootstrapData.country_code });
        this.globalData.set(
          GlobalDataEnum.CURRENT_USER,
          new User(this.appSettingsService, this.globalData, this._userData),
        );
      })
      .catch((err: any) => Promise.resolve());
  }

  private fetchGenericData() {
    const landingPageUrl: string = this.detectLandingPageUrl();
    return this.apiDataService
      .get(`bootstrap?url=${landingPageUrl}`)
      .pipe(retry(2))
      .pipe(map((res: HttpResponse<BootstrapData>) => res));
  }

  private fetchUserData() {
    return this.apiDataService
      .get("user")
      .pipe(retry(2))
      .pipe(map((res: HttpResponse<any>) => res));
  }

  // The logic is following:
  // * if we are on the main page, we determine landing page by looking for /{lp}
  // * if we are on another page, we look for landing page by looking for
  // /...?landingPage={lp}
  private detectLandingPageUrl(): string {
    const potentialLandingPageUrl: string = this.extractLandingPage().toLowerCase();
    return this.isPossibleDlpUrl(potentialLandingPageUrl) ? potentialLandingPageUrl : this.findLandingPageParam();
  }

  private extractLandingPage(): string {
    if (this.kaligoConfig.dlp) {
      return this.kaligoConfig.dlp;
    }
    if (location.pathname !== "/") {
      return location.pathname.split("/")[1] || "";
    }
    return "";
  }

  private findLandingPageParam(): string {
    if (this.kaligoConfig.dlp) {
      return this.kaligoConfig.dlp;
    }
    const queryParams = location.search.split("?")[1];
    if (queryParams) {
      for (const part of queryParams.split("&")) {
        const paramKey: string = part.split("=")[0];
        const paramValue: string = part.split("=")[1];
        if (paramKey === "landingPage") {
          return paramValue.toLowerCase();
        }
      }
    }
    return "";
  }

  private isPossibleDlpUrl(url: string): boolean {
    return (url !== "" && this.globalData.get(GlobalDataEnum.RESERVED_URL_KEYWORDS).indexOf(url) === -1);
  }

}
