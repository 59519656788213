import { Component, Input } from "@angular/core";

import { ExclusiveBenefit } from "booking-app-v2/shared/types";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";

@Component({
  selector: "exclusive-card",
  template: `
    <a class="card" (click)="goToExclusiveBenefits(benefit.merchant.id)">
      <div class="image-container" [style.background-image]="processImage(benefit.offer.image_list[0])"></div>
      <div class="card-message-container">
        <div class="title" [innerHTML]="benefit.merchant.name"></div>
        <div class="description-container">
          <div class="context" [innerHTML]="benefit.offer.short_description"></div>
          <div class="fa fa-chevron-right"></div>
        </div>
      </div>
    </a>
  `,
})
export class ExclusiveCardComponent {
  @Input() benefit: ExclusiveBenefit;

  constructor(
    private windowRefService: WindowRefService,
  ) { }

  processImage(imageUrl: string): string {
    return `url(${this.processVisaImage(imageUrl, 640)})`;
  }

  processVisaImage(url: string, width: number): string {
    return `${url}?imwidth=${width}`;
  }

  goToExclusiveBenefits(merchantId: number): void {
    this.windowRefService.nativeWindow.location.href = this.exclusiveBenefitUrl(merchantId);
  }

  private exclusiveBenefitUrl(merchantId: number): string {
    return `/exclusive_benefits?merchant_id=${merchantId}`;
  }
}
