import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";

import { Room } from "booking-app-v2/hotels/models";
import { RoomImage } from "booking-app-v2/hotels/types";
import { COMPLEX_DIALOG, ComplexDialog } from "booking-app-v2/shared/types";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { BottomSheetService } from "booking-app-v2/shared/services/bottom-sheet.service";

import { RoomDetailsDialogComponent } from "booking-app-v2/shared/components/dialog/room-details-dialog/room-details-dialog.component";

@Component({
  selector: "room-details-dialog-button",
  templateUrl: "/html/dialogs/room_details_dialog_button_v2",
})
export class RoomDetailsDialogButtonComponent implements OnInit {
  @Input() room: Room;
  @Input() buttonAriaLabel: string;
  @Input() defaultImageUrl: string;
  @Input() dialogButtonContent: TemplateRef<any>;

  images: string[];

  @ViewChild("roomDetailsBottomSheet") private roomDetailsBottomSheetTemplate: TemplateRef<any>;

  constructor(
    public globalData: GlobalData,
    public appSettingsService: AppSettingsService,
    private dialogService: DialogService,
    private bottomSheetService: BottomSheetService,
  ) { }

  ngOnInit() {
    this.images = this.initRoomImages();
  }

  openDesktopDialog($event): void {
    $event.stopPropagation();
    $event.preventDefault();

    let dialogType: ComplexDialog;
    if (this.room.long_description) {
      dialogType = COMPLEX_DIALOG.ROOM_DETAILS_WITH_DESCRIPTION;
    } else {
      dialogType = COMPLEX_DIALOG.ROOM_DETAILS_NO_DESCRIPTION;
    }
    this.dialogService.open(dialogType, RoomDetailsDialogComponent, {
      images: this.images,
      title: this.room.roomDescriptionShort,
      description: this.room.long_description,
      defaultImage: this.defaultImageUrl,
    });
  }

  initRoomImages(): string[] {
    if (this.room.images !== undefined && this.room.images.length > 0) {
      return this.room.images.map((image: RoomImage) => image.high_resolution_url || image.url);
    } else {
      return [this.defaultImageUrl];
    }
  }

  openMobileBottomSheet(): void {
    const classList: string[] = this.room.long_description
      ? ["room-details-dialog", "with-description"]
      : ["room-details-dialog", "no-description"];
    this.bottomSheetService.open(this.roomDetailsBottomSheetTemplate, classList);
  }

  hasRoomImages(): boolean {
    return this.room.images && this.room.images.length > 0;
  }
}
