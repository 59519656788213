import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { LocaleService } from "booking-app-v2/shared/services/initializers/locale.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { TranslationUtilsService } from "booking-app-v2/shared/services/translation-utils.service";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Directive({
  selector: "[translateSingular]",
})
export class TranslateSingularDirective implements OnInit {
  private static SUFFIX_KEYS_TO_TRY: string[] = ["one", "other", ""];

  @Input("translateSingular") translationKeyPrefix: string;

  constructor(
    private el: ElementRef,
    private localeService: LocaleService,
    private translateService: TranslateService,
    private globalData: GlobalData,
    private translationUtilsService: TranslationUtilsService,
  ) {}

  ngOnInit() {
    this.translateAndSetInnerText();
    this.localeService.onLocaleChange
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.translateAndSetInnerText();
      });
  }

  // This handles issues with phraseApp's API for pluralisation.
  // Ex: key is `txt.night`. If a `txt.night.one` exists (English/German/etc), use that translation key
  // If that doesn't exist (`txt.night.one` for Korean/Japanese), then use the `txt.night.other` key.
  // If that also doesn't exist, then just use the original key.
  // Ex: `txt.day_one` doesnt have pluralisation, so just use the original key.
  private translateAndSetInnerText(): void {
    const langCode: string = this.globalData.get(GlobalDataEnum.SELECTED_LOCALE).langCode;
    this.translationUtilsService.getTranslation(langCode).subscribe((translations: Record<string, any>) => {
      this.el.nativeElement.innerText = this.getTranslatedText(translations)
        // Display English translation when all possible translations are missing.
        // We can modify this in the future so that if a tenant has a different default
        // locale, then default to that locale
        // return this.translatedText() ?? ;
        ?? this.translateService.instant(this.translationKeyPrefix, "en");
    });
  }

  private getTranslatedText(translations: Record<string, any>): string {
    for (const suffix of TranslateSingularDirective.SUFFIX_KEYS_TO_TRY) {
      const translationValueObj: any = translations[this.translationKeyPrefix];
      if (translationValueObj != null && translationValueObj[suffix]) {
        return translationValueObj[suffix];
      }
    }
  }
}
