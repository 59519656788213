import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, ReplaySubject, take } from "rxjs";

import { defaultFlightPricesRawResponse, FlightPricesRawResponse } from "booking-app-v2/flights/types";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  FLIGHT_REVALIDATION_STATUS,
  FlightFetchPriceResult,
  FlightRevalidationResult,
} from "booking-app-v2/shared/types";

import { FlightUtilService } from "booking-app-v2/flights/services/flight-util.service";
import { CouponService } from "booking-app-v2/shared/services/coupon.service";
import { FlightsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/flights-url-builder.service";
import { ForceSyncBaseStep } from "./force-sync-base-step";

@Injectable({
  providedIn: "root",
})
export class FlightFetchPriceService implements ForceSyncBaseStep {
  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.FLIGHT_FETCH_PRICE;

  private couponCode: string = "";

  constructor(
    private activatedRoute: ActivatedRoute,
    private urlBuilderService: FlightsUrlBuilderService,
    private couponService: CouponService,
    private flightUtilService: FlightUtilService,
  ) {}

  processAsync(results: CheckoutResults): Observable<FlightFetchPriceResult> {
    const result: ReplaySubject<FlightPricesRawResponse> = new ReplaySubject<FlightPricesRawResponse>(1);
    this.couponCode = this.couponService.isValid() ? this.couponService.couponCodeDetails.code : "";

    this.fetchFlightSinglePrice(result);
    return result.asObservable();
  }

  private fetchFlightSinglePrice(result: ReplaySubject<FlightPricesRawResponse>): void {
    const params = this.urlBuilderService.buildCheckoutPageDataFromQueryParams({
      ...this.activatedRoute.snapshot.queryParams,
      couponCode: this.couponCode,
    });

    this.flightUtilService.fetchFlightsSinglePriceWithRevalidation(params)
      .pipe(take(1))
      .subscribe((revalidationResult: FlightRevalidationResult) => {
        switch (revalidationResult.status) {
          case FLIGHT_REVALIDATION_STATUS.SUCCESS:
            result.next(revalidationResult.response);
            break;
          case FLIGHT_REVALIDATION_STATUS.NO_RESULTS:
            result.next(revalidationResult.response);
            break;
          default:
            result.next(defaultFlightPricesRawResponse);
        }
      });
  }
}
