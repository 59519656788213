import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Observable } from "rxjs";

import { ResultsService } from "../../services";
import { Hotel } from "booking-app-v2/hotels/models";

@Injectable({ providedIn: "root" })
export class HotelResultsResolver implements Resolve<Hotel[]> {
  constructor(
    private hotelResultsService: ResultsService,
    private router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Hotel[]> {
    const destinationId: string = route.paramMap.get("id");
    if (destinationId?.length > 4) {
      this.router.navigate(["/"]);
    } else {
      return this.hotelResultsService.getHotels();
    }
  }
}
