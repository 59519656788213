import { Injectable, Type } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable, Subscription } from "rxjs";

import {
  COMPLEX_DIALOG,
  ComplexDialog,
  Dialog,
  DialogData,
  DialogOptions,
  SimpleDialog,
  SimpleDialogContents,
} from "booking-app-v2/shared/types";
import { SimpleDialogComponent } from "booking-app-v2/shared/components/dialog/simple-dialog/simple-dialog.component";
import { LoginDialogComponent } from "booking-app-v2/shared/components/dialog/login-dialog/login-dialog.component";
import { RegisterDialogComponent } from "booking-app-v2/shared/components/dialog/register-dialog/register-dialog.component";
import { ForgetPasswordDialogComponent } from "booking-app-v2/shared/components/dialog/forget-password-dialog/forget-password-dialog.component";
import { dialogOptions, simpleDialogContents } from "booking-app-v2/shared/constants";

interface DialogConfigs {
  wlDialogOptions?: DialogOptions;
  wlSimpleDialogContents?: SimpleDialogContents;
}

@Injectable({
  providedIn: "root",
})
export class DialogService {

  private matDialogRef: MatDialogRef<any>;

  constructor(private matDialog: MatDialog) { }

  open(dialogId: Dialog, component: Type<any>, dialogData?: DialogData): Observable<any> {
    this.matDialog.closeAll();

    const data: DialogData = component === SimpleDialogComponent
      ? { ...simpleDialogContents[dialogId], ...dialogData }
      : dialogData;

    this.matDialogRef = this.matDialog.open(component, {
      ...dialogOptions[dialogId],
      data,
    });

    return this.matDialogRef.afterClosed();
  }

  openLoginModal(): void {
    const loginModalSubscription: Subscription = this.open(COMPLEX_DIALOG.LOGIN, LoginDialogComponent)
      .subscribe((dialogId: ComplexDialog) => {
        if (dialogId === COMPLEX_DIALOG.REGISTER) {
          this.open(dialogId, RegisterDialogComponent);
        } else if (dialogId === COMPLEX_DIALOG.FORGET_PASSWORD) {
          this.open(dialogId, ForgetPasswordDialogComponent);
        }
        loginModalSubscription.unsubscribe();
      });
  }

  initCustomDialogConfig(dialogConfigs: DialogConfigs): void {
    if (!!dialogConfigs.wlDialogOptions) {
      Object.keys(dialogConfigs.wlDialogOptions).forEach((dialogId: Dialog) => {
        dialogOptions[dialogId] = {
          ...dialogOptions[dialogId],
          ...dialogConfigs.wlDialogOptions[dialogId],
        };
      });
    }

    if (!!dialogConfigs.wlSimpleDialogContents) {
      Object.keys(dialogConfigs.wlSimpleDialogContents).forEach((dialogId: SimpleDialog) => {
        simpleDialogContents[dialogId] = {
          ...simpleDialogContents[dialogId],
          ...dialogConfigs.wlSimpleDialogContents[dialogId],
        };
      });
    }
  }

}
