import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";

import { ForceSyncBaseStep } from "./force-sync-base-step";
import { BookingsService } from "booking-app-v2/shared/services/bookings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";

import {
  ApiDataError,
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  CreateBookingResponse,
  CreateBookingResult,
  GlobalDataEnum,
  TRAVEL_TYPE,
  TravelType,
} from "booking-app-v2/shared/types";
import { CheckoutError } from "booking-app-v2/shared/models";

@Injectable({
  providedIn: "root",
})
export class CreateBookingService implements ForceSyncBaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.CREATE_BOOKING;

  constructor(
    private globalData: GlobalData,
    private bookingsService: BookingsService,
  ) {}

  processAsync(results: CheckoutResults): Observable<CreateBookingResult> {
    const createBookingResult: Subject<CreateBookingResult> = new ReplaySubject<CreateBookingResult>(1);
    this.bookingsService.createBooking(this.getBookingParams(results))
      .subscribe({
        next: (resp: CreateBookingResponse) => createBookingResult.next(resp),
        error: (err: ApiDataError) => {
          results.checkoutErrorKey$.next(err.errors[0]);
          createBookingResult.error(new CheckoutError(`Error when creating booking: ${err.errors[0]}`));
        },
      });

    return createBookingResult.asObservable();
  }

  private getBookingParams(results: CheckoutResults): any {
    const travelType: TravelType = this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);
    switch (travelType) {
      case TRAVEL_TYPE.HOTELS:
        return results[CHECKOUT_RESULT_NAME.HOTEL_COLLECT_BOOKING_PARAMS];
      case TRAVEL_TYPE.FLIGHTS:
        return results[CHECKOUT_RESULT_NAME.FLIGHT_COLLECT_BOOKING_PARAMS];
      case TRAVEL_TYPE.CARS:
        return results[CHECKOUT_RESULT_NAME.CAR_COLLECT_BOOKING_PARAMS];
    }
  }
}
