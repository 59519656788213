import { Provider } from "@angular/core";
import {
  RECAPTCHA_BASE_URL,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
  RecaptchaComponent,
  RecaptchaSettings,
} from "ng-recaptcha";

import { BootstrapDataService } from "booking-app-v2/shared/services/bootstrap-data.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

function initializeReCaptcha(config: KaligoConfig): RecaptchaSettings {
  return {
    size: "invisible",
    siteKey: config.isProduction ?
      "6Lfa6wEaAAAAAGde53kVaXMCOhr7RGu4Wpy3T3a-" :
      "6Lf16gEaAAAAAB5q1TE6WolPwPUAEXRYF3nWjJYE",
  };
}

function initializeReCaptchaLanguage(globalData: GlobalData): string {
  return globalData.get(GlobalDataEnum.SELECTED_LOCALE)?.langCode || "en";
}

function initializeReCaptchaBaseUrl(bootstrapDataService: BootstrapDataService): string {
  return bootstrapDataService.bootstrapData.country_code === "CN" ?
    "https://recaptcha.net/recaptcha/api.js" :
    "https://google.com/recaptcha/api.js";
}

export const reCaptchaProviders: Provider[] = [
  {
    provide: RECAPTCHA_SETTINGS,
    useFactory: initializeReCaptcha,
    deps: [ "kaligoConfig" ],
  },
  {
    provide: RECAPTCHA_LANGUAGE,
    useFactory: initializeReCaptchaLanguage,
    deps: [ GlobalData ],
  },
  {
    provide: RECAPTCHA_BASE_URL,
    useFactory: initializeReCaptchaBaseUrl,
    deps: [ BootstrapDataService ], // use recaptcha.net script source for some of our users
  },
];

// Temporary solution for this issue https://github.com/DethAriel/ng-recaptcha/issues/123
// REMOVE function below & import of RecaptchaComponent on top part when the issue is fixed
RecaptchaComponent.prototype.ngOnDestroy = function() {
  if (this.subscription) {
    this.subscription.unsubscribe();
  }
};
