import { Component, Input } from "@angular/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { BannersService } from "booking-app-v2/shared/services/initializers/banners.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { Banner, VIEWPORT_SIZES } from "booking-app-v2/shared/types";
import { ObjectValues } from "booking-app-v2/shared/types/object-values.type";

export const BANNER_TYPE = {
  DESKTOP: "desktop",
  MOBILE: "mobile",
} as const;

export type BannerType = ObjectValues<typeof BANNER_TYPE>;

@Component({
  selector: "banner",
  template: `
    <div class="banner-wrap" *ngIf="!isMobile() && hasDesktopBanner()">
      <a [href]="banner.target_url" target="bannerTargetWindow()">
        <img [src]="banner.image_url">
      </a>
    </div>
    <div class="banner-wrap" *ngIf="isMobile() && hasMobileBanner()">
      <a [href]="banner.target_url" target="bannerTargetWindow()">
        <img [src]="banner.image_url">
      </a>
    </div>
  `,
})
export class BannerComponent {
  @Input() slot: string;
  @Input() type: BannerType;

  banner: Banner;

  constructor(
    private bannersService: BannersService,
    private appSettingsService: AppSettingsService,
    private windowRefService: WindowRefService,
  ) {}

  public hasDesktopBanner(): boolean {
    if (this.isDesktopBanner()) {
      this.banner = this.bannersService.find(this.slot);
      return !!this.banner;
    }
  }

  public hasMobileBanner(): boolean {
    if (this.isMobileBanner()) {
      this.banner = this.bannersService.find(this.slot);
      return !!this.banner;
    }
  }

  public bannerTargetWindow(): string {
    return this.appSettingsService.bannerTargetWindow;
  }

  public isMobile(): boolean {
    return this.appSettingsService.mobileFirst || this.windowRefService.nativeWindow.innerWidth <= VIEWPORT_SIZES.XS_MAX;
  }

  private isMobileBanner(): boolean {
    return this.type === BANNER_TYPE.MOBILE;
  }

  private isDesktopBanner(): boolean {
    return this.type === BANNER_TYPE.DESKTOP;
  }

}
