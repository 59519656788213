import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Options } from "@ascenda/ngx-slider";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import {
  CashPointsRatio,
  CASH_POINTS_RATIO,
  CURRENT_PAGE,
  GlobalDataEnum,
  PointsCashSliderSettings,
} from "booking-app-v2/shared/types";

export interface PointsCashShare {
  value: number;
  sliderOptions: Options;
}

@Injectable({
  providedIn: "root",
})
export class PointsCashShareService {

  pointsCashShare: PointsCashShare;
  onSliderChange: Subject<void> = new Subject();

  private pointsCashSliderSettings: PointsCashSliderSettings;
  private cashPaymentTiers: number[];
  private pointsPaymentTiers: number[];

  constructor(
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private pageDiscoveryService: PageDiscoveryService,
    private translateService: TranslateService,
  ) {
    this.pointsCashSliderSettings = this.appSettingsService.pointsCashSliderSettings;
  }

  init(): void {
    this.cashPaymentTiers = this.globalData.get(GlobalDataEnum.POINTS_PARTNER).cashPaymentTiersByTravelType;
    this.pointsPaymentTiers = this.globalData.get(GlobalDataEnum.POINTS_PARTNER).pointsPaymentTiersByTravelType;

    if (!this.cashPaymentTiers || !this.pointsPaymentTiers) {
      return;
    }

    const ceilValue: number = this.cashPaymentTiers.length - 1;

    this.pointsCashShare = {
      value: this.pointsCashSliderSettings.pointSliderDefaultValue,
      sliderOptions: {
        floor: 0,
        ceil: ceilValue,
        step: 1,
        isRtl: this.isRtl(),
        value: this.pointsCashSliderSettings.pointSliderDefaultValue,
        valueLabel: this.translate.bind(this),
      },
    };
  }

  onChange(newPointsCashValue: number): void {
    if (newPointsCashValue === this.pointsCashShare.value) {
      return;
    }
    this.pointsCashShare.sliderOptions.value = newPointsCashValue;
    this.pointsCashShare.value = newPointsCashValue;
    this.onSliderChange.next();
  }

  getSliderUpdate(): Observable<void> {
    return this.onSliderChange.asObservable();
  }

  translate(value: number): string {
    let pointsUsagePercentage: number;
    let pointsUsageText: string;

    if (this.pointsCashSliderSettings.incrementPointsSliderCounter) {
      pointsUsagePercentage = Math.round(this.pointsPaymentTiers[value] * 100);
    } else {
      pointsUsagePercentage = 100 - Math.round(this.pointsPaymentTiers[value] * 100);
    }

    if (!this.pointsCashSliderSettings.hasCustomSliderLabel) {
      pointsUsageText = `${pointsUsagePercentage}%
        ${this.translateService.instant(this.globalData.get(GlobalDataEnum.POINTS_PARTNER).currency_short)}`;
    } else {
      pointsUsageText = `${pointsUsagePercentage}%`;
    }

    if (this.pageDiscoveryService.currentPage() === CURRENT_PAGE.CHECKOUT &&
      this.pointsCashShare.value === this.pointsPaymentTiers.length - 1) {
      pointsUsageText = `
        ${this.translateService.instant(this.pointsCashSliderSettings.ceilingLabelPrefix)}\n${pointsUsageText}
      `;
    }

    return pointsUsageText;
  }

  pointsCashRatio(): CashPointsRatio {
    const cashPaymentTierValue = this.cashPaymentTiers[this.pointsCashShare.value];
    const pointsPaymentTierValue = this.pointsPaymentTiers[this.pointsCashShare.value];
    if (pointsPaymentTierValue === 0) {
      return CASH_POINTS_RATIO.FULLCASH;
    } else if (cashPaymentTierValue === 0) {
      return CASH_POINTS_RATIO.FULLPOINTS;
    } else {
      return CASH_POINTS_RATIO.CASHANDPOINTS;
    }
  }

  toggleSliderRTL(): void {
    if (this.pointsCashShare) {
      const newSliderOption: Options = Object.assign({}, this.pointsCashShare.sliderOptions);
      newSliderOption.isRtl = this.isRtl();
      this.pointsCashShare.sliderOptions = newSliderOption;
    }
  }

  private isRtl(): boolean {
    return this.globalData.get(GlobalDataEnum.DISPLAY_ALIGNMENT) === "rtl";
  }
}
