import { Routes } from "@angular/router";
import { ResultsPageComponent } from "../results-page.component";
import { HotelResultsResolver } from "./results-page.resolver";
import { GulfDestinationResolver } from "./gulf-destination-page.resolver";


export const resultsPageRoutes: Routes = [
  {
    path: ":id",
    component: ResultsPageComponent,
    resolve: {
      hotels: HotelResultsResolver,
      gulfDestination: GulfDestinationResolver,
    },
  },
];
