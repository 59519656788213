import { Directive, ElementRef, Inject, Input, OnInit, Renderer2 } from "@angular/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";

@Directive({
  selector: "img[cdn-path]",
  host: {
    "[src]": "src",
    "(error)": "updateOrRemoveImage()",
  },
})
export class CdnPathDirective implements OnInit {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  @Input("cdn-path") cdnPath: string;
  @Input() prefix: "tenant" | "theme" | "fullPath";
  @Input() src: string;
  @Input() default: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private appSettingsService: AppSettingsService,
  ) {}

  ngOnInit() {
    this.src = this.formattedSrcFromCdnPath();
  }

  // Called when (error) happens on host element
  // If default is an empty string, the img will be removed from DOM
  // If default is not an empty string, the img will fallback to the URL set in default attribute
  // If the URL set in default attribute is invalid too, the img will also be removed from DOM
  updateOrRemoveImage(): void {
    if (this.isDefaultImageInvalid()) {
      this.renderer.removeChild(
        this.el.nativeElement.parentElement,
        this.el.nativeElement,
        true,
      );
    } else {
      this.src = this.default;
    }
  }

  private formattedSrcFromCdnPath(): string {
    if (this.isCdnPathInvalid()) {
      return "";
    }

    if (this.prefix === "tenant") {
      return `${this.kaligoConfig.cdnImageUrl}/${this.appSettingsService.tenant.toLowerCase()}${this.cdnPath}`;
    } else if (this.prefix === "theme") {
      return `${this.kaligoConfig.cdnImageUrl}/${this.appSettingsService.theme.toLowerCase()}${this.cdnPath}`;
    } else if (this.prefix === "fullPath") {
      return this.cdnPath;
    } else {
      return `${this.kaligoConfig.cdnImageUrl}${this.cdnPath}`;
    }
  }

  private isDefaultImageInvalid(): boolean {
    return !this.default || this.default.length === 0 || this.default === this.src;
  }

  private isCdnPathInvalid(): boolean {
    return !this.cdnPath || this.cdnPath.length === 0 ||
      (this.prefix !== "fullPath" && !this.cdnPath.startsWith("/")) ||
      (this.prefix === "fullPath" && !/^https?:\/\//i.test(this.cdnPath));
  }
}
