import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "booking-app-v2/shared/shared.module";
import { InputCounterComponent } from "./input-counter.component";

@NgModule({
  declarations: [InputCounterComponent],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [InputCounterComponent],
  providers: [],
})
export class InputCounterModule {}
