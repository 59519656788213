import { COMPLEX_DIALOG, DialogOptions, SIMPLE_DIALOG } from "booking-app-v2/shared/types";

export const dialogOptions: DialogOptions = {
  [SIMPLE_DIALOG.CANCEL_BOOKING_SUCCESS]: {
    disableClose: true,
    panelClass: "cancel-booking-success-dialog",
  },
  [SIMPLE_DIALOG.CANCEL_BOOKING_FAILED]: {
    disableClose: true,
    panelClass: "cancel-booking-failed-dialog",
  },
  [SIMPLE_DIALOG.CANCEL_BOOKING]: {
    disableClose: true,
    panelClass: "cancel-booking-dialog",
  },
  [SIMPLE_DIALOG.CANCELLING_BOOKING]: {
    disableClose: true,
    panelClass: "cancelling-booking-dialog",
  },
  [SIMPLE_DIALOG.CANCEL_BOOKING_REQUEST]: {
    disableClose: true,
    panelClass: "cancel-booking-request-modal-dialog",
  },
  [SIMPLE_DIALOG.CANCEL_BOOKING_REQUEST_SUCCESS]: {
    disableClose: true,
    panelClass: "cancel-booking-request-modal-dialog",
  },
  [SIMPLE_DIALOG.CANCEL_BOOKING_REQUEST_FAILED]: {
    disableClose: true,
    panelClass: "cancel-booking-request-modal-dialog",
  },
  [SIMPLE_DIALOG.GULF_RESIDENCY]: {
    disableClose: true,
  },
  [SIMPLE_DIALOG.HOTEL_ROOM_UNAVAILABLE]: {
    panelClass: "hotel-room-unavailable-dialog",
    disableClose: true,
  },
  [SIMPLE_DIALOG.FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE]: {
    disableClose: true,
  },
  [SIMPLE_DIALOG.FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE]: {
    disableClose: true,
  },
  [SIMPLE_DIALOG.PAYMENT_CARD_VALIDATION]: {
    disableClose: true,
  },
  [SIMPLE_DIALOG.BONUS_VALIDATION]: {
    disableClose: true,
  },
  [SIMPLE_DIALOG.INVALID_CREDIT_CARD_FOR_PARTNER]: {
    disableClose: true,
  },
  [SIMPLE_DIALOG.CHANGE_PARTNER]: {
    disableClose: true,
  },
  [SIMPLE_DIALOG.USER_INELIGIBILITY]: {
    disableClose: true,
  },
  [COMPLEX_DIALOG.LOGIN]: {
    panelClass: "login-dialog",
    disableClose: true,
  },
  [COMPLEX_DIALOG.REGISTER]: {
    panelClass: "register-dialog",
    disableClose: true,
  },
  [COMPLEX_DIALOG.FORGET_PASSWORD]: {
    panelClass: "forget-password-dialog",
    disableClose: true,
  },
  [COMPLEX_DIALOG.CARS_TERMS]: {
    panelClass: "cars-terms-dialog",
  },
  [COMPLEX_DIALOG.FLIGHTS_TERMS]: {
    panelClass: "flights-terms-dialog",
  },
  [COMPLEX_DIALOG.HOTEL_EDIT_SEARCH]: {
    panelClass: "hotel-edit-search-dialog",
    maxWidth: "100vw",
  },
  [COMPLEX_DIALOG.FLIGHT_EDIT_SEARCH]: {
    panelClass: "flight-edit-search-dialog",
  },
  [COMPLEX_DIALOG.CAR_EDIT_SEARCH]: {
    panelClass: "car-edit-search-dialog",
  },
  [COMPLEX_DIALOG.TRUSTYOU]: {
    panelClass: "trustyou-dialog",
  },
  [COMPLEX_DIALOG.GALLERY]: {
    panelClass: "gallery-dialog",
  },
  [COMPLEX_DIALOG.INSUFFICIENT_POINTS_CHECK]: {
    panelClass: "insufficient-points-check-dialog",
  },
  [COMPLEX_DIALOG.ROOM_DETAILS_WITH_DESCRIPTION]: {
    panelClass: ["room-details-dialog", "with-description"],
    backdropClass: "gallery-backdrop",
    maxWidth: "100vw",
  },
  [COMPLEX_DIALOG.ROOM_DETAILS_NO_DESCRIPTION]: {
    panelClass: ["room-details-dialog", "no-description"],
    backdropClass: "gallery-backdrop",
    maxWidth: "758px",
  },
  [COMPLEX_DIALOG.INVALID_CREDIT_CARD]: {
    panelClass: "invalid-credit-card-dialog",
    width: "470px",
  },
  [COMPLEX_DIALOG.MAPBOX_DIRECTION]: {
    panelClass: "mapbox-direction-dialog",
    disableClose: true,
  },
  [SIMPLE_DIALOG.CARD_VALIDATION]: {
    disableClose: true,
    panelClass: "card-validation-dialog",
  },
};
