import { NgModule } from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgxSimpleDropdownModule } from "@ascenda/ngx-simple-dropdown";
import { HotelDetailPopupModule } from "booking-app-v2/shared/components/hotel-detail-popup/hotel-detail-popup.module";
import { HotelListItemModule } from "booking-app-v2/shared/components/hotel-list-item/hotel-list-item.module";
import { HotelSearchBarModule } from "booking-app-v2/shared/components/hotel-search-bar/hotel-search-bar.module";
import {
  RedeemPointsSliderModule,
} from "booking-app-v2/shared/components/redeem-points-slider/redeem-points-slider.module";
import { CashSliderModule } from "booking-app-v2/shared/components/cash-slider/cash-slider.module";
import { MiniMapModule } from "booking-app-v2/shared/components/mini-map/mini-map.module";
import { PointsCashSliderModule } from "booking-app-v2/shared/components/points-cash-slider/points-cash-slider.module";
import {
  ReviewsScoreSliderModule,
} from "booking-app-v2/shared/components/reviews-score-slider/reviews-score-slider.module";
import { StarRatingModule } from "booking-app-v2/shared/components/star-rating/star-rating.module";
import { SharedModule } from "booking-app-v2/shared/shared.module";
import { ResultsPageComponent } from "../results-page.component";
import { ResultsPageRoutingModule } from "../routes/results-page-routing.module";
import {
  HotelResultMapMobileModule,
} from "booking-app-v2/shared/components/hotel-result-map-mobile/hotel-result-map-mobile.module";
import {
  HotelResultFilterMobileModule,
} from "booking-app-v2/shared/components/hotel-result-filter-mobile/hotel-result-filter-mobile.module";
import {
  HotelResultSortMobileModule,
} from "booking-app-v2/shared/components/hotel-result-sort-mobile/hotel-result-sort-mobile.module";
import {
  ComplimentaryBannerModule,
} from "booking-app-v2/shared/components/complimentary-banner/complimentary-banner.module";
import {
  ComplimentaryPriceBoxModule,
} from "booking-app-v2/shared/components/complimentary-price-box/complimentary-price-box.module";

@NgModule({
  imports: [
    SharedModule,
    ResultsPageRoutingModule,
    PointsCashSliderModule,
    ReviewsScoreSliderModule,
    HotelSearchBarModule,
    HotelListItemModule,
    MiniMapModule,
    HotelDetailPopupModule,
    StarRatingModule,
    RedeemPointsSliderModule,
    CashSliderModule,
    NgxSimpleDropdownModule,
    HotelResultMapMobileModule,
    HotelResultFilterMobileModule,
    HotelResultSortMobileModule,
    MatCheckboxModule,
    ComplimentaryBannerModule,
    ComplimentaryPriceBoxModule,
  ],
  declarations: [
    ResultsPageComponent,
  ],
})
export class SharedResultsPageModule {}
