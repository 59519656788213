import { RoomPrice } from "booking-app-v2/hotels/models";
import { HotelRawPrice, TaxesAndFees } from "booking-app-v2/hotels/types";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";

type TravelItemPrice = RoomPrice;
type TravelItemRawPrice = HotelRawPrice;

export class FeeBreakdownDecorator {

  static decorate(
    travelItemPrice: TravelItemPrice,
    travelItemRawPrice: TravelItemRawPrice,
    currenciesService: CurrenciesService,
  ): void {
    const hasFeeBreakdown = !!(
      typeof travelItemRawPrice.base_rate === "number"
      && typeof travelItemRawPrice.included_taxes_and_fees_total === "number"
      && typeof travelItemRawPrice.excluded_taxes_and_fees_currency === "string"
      && typeof travelItemRawPrice.excluded_taxes_and_fees_total === "number"
      && Array.isArray(travelItemRawPrice.included_taxes_and_fees)
      && typeof travelItemRawPrice.excluded_taxes_and_fees_total_in_currency === "number"
    );

    if (!hasFeeBreakdown) {
      return;
    }
    travelItemPrice.baseRate = this.formattedBaseRate(travelItemRawPrice, currenciesService);
    travelItemPrice.includedTaxesAndFeesTotal = this.formattedIncludedTaxesAndFeesTotal(
      travelItemRawPrice, currenciesService,
    );
    travelItemPrice.excludedTaxesAndFeesCurrency = travelItemRawPrice.excluded_taxes_and_fees_currency;
    travelItemPrice.excludedTaxesAndFeesTotal = this.formattedExcludedTaxesAndFeesTotal(
      travelItemRawPrice, currenciesService,
    );
    travelItemPrice.excludedTaxesAndFeesTotalInCurrency = travelItemRawPrice.excluded_taxes_and_fees_total_in_currency;
    travelItemPrice.includedTaxesAndFees = this.formattedIncludedTaxesAndFees(travelItemRawPrice, currenciesService);
  }

  static formattedBaseRate(
    travelItemRawPrice: TravelItemRawPrice,
    currenciesService: CurrenciesService,
  ): string {
    const value = travelItemRawPrice.base_rate;
    const convertedValue = currenciesService.convertFromUsd(value);
    return currenciesService.formatCurrencyValue(convertedValue);
  }

  private static formattedIncludedTaxesAndFeesTotal(
    travelItemRawPrice: TravelItemRawPrice,
    currenciesService: CurrenciesService,
  ): string {
    const value = travelItemRawPrice.included_taxes_and_fees_total;
    const convertedValue = currenciesService.convertFromUsd(value);
    return currenciesService.formatCurrencyValue(convertedValue);
  }

  private static formattedExcludedTaxesAndFeesTotal(
    travelItemRawPrice: TravelItemRawPrice,
    currenciesService: CurrenciesService,
  ): string {
    // Only formatting decimal places - keeping this value static in supplier's currency
    return currenciesService.formatCurrencyValue(travelItemRawPrice.excluded_taxes_and_fees_total);
  }

  private static formattedIncludedTaxesAndFees(
    travelItemRawPrice: TravelItemRawPrice,
    currenciesService: CurrenciesService,
  ): TaxesAndFees {
    const includedTaxesAndFees = travelItemRawPrice.included_taxes_and_fees.map((item) => {
      const convertedValue = currenciesService.convertFromUsd(item.amount);
      return {
        id: item.id,
        amount: currenciesService.formatCurrencyValue(convertedValue),
      };
    });
    return includedTaxesAndFees;
  }
}
