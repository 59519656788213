import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { TimeUtils } from "booking-app-v2/shared/utils";
import { Booking, CancellationPolicy, Locale, PointsPartners } from "booking-app-v2/shared/models";
import { CarFee, CarPricedCoverage } from "booking-app-v2/cars/types";

export class CarBooking extends Booking {
  readonly zumata_booking_id: string;
  readonly aircon: boolean;
  readonly booking_reference: string;
  readonly baggage: number;
  readonly category: string;
  readonly car_model: string;
  readonly city: string;
  readonly confirmation_number: string;
  readonly phone_number: string;
  readonly passengers: number;
  readonly pickup_location_name: string;
  readonly return_location_name: string;
  readonly start_time: string;
  readonly end_time: string;
  readonly transmission_type: string;
  readonly supplier_name: string;
  readonly partner_currency_short: string;
  readonly partner_currency_long: string;
  readonly user_points_balance: number;
  readonly cancellation_policy: CancellationPolicy;
  readonly image_url: string;
  readonly supplier_image_url: string;
  readonly pickup_address: string;
  readonly return_address: string;
  readonly priced_coverages: CarPricedCoverage[];
  readonly pay_at_counter_fee?: CarFee;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    booking: CarBooking,
    pointsPartners: PointsPartners,
  ) {
    super(
      appSettingsService,
      globalData,
      booking,
      pointsPartners,
    );

    this.cancellation_policy = new CancellationPolicy(appSettingsService, globalData, booking.cancellation_policy);
    this.zumata_booking_id = booking.zumata_booking_id;
    this.aircon = booking.aircon;
    this.booking_reference = booking.booking_reference;
    this.baggage = booking.baggage;
    this.category = booking.category;
    this.car_model = booking.car_model;
    this.city = booking.city;
    this.confirmation_number = booking.confirmation_number;
    this.phone_number = booking.phone_number;
    this.passengers = booking.passengers;
    this.pickup_location_name = booking.pickup_location_name;
    this.return_location_name = booking.return_location_name;
    this.start_time = booking.start_time;
    this.end_time = booking.end_time;
    this.transmission_type = booking.transmission_type;
    this.supplier_name = booking.supplier_name;
    this.partner_currency_short = booking.partner_currency_short;
    this.partner_currency_long = booking.partner_currency_long;
    this.user_points_balance = booking.user_points_balance;
    this.image_url = booking.image_url;
    this.supplier_image_url = booking.supplier_image_url;
    this.pickup_address = booking.pickup_address;
    this.return_address = booking.return_address;
    this.priced_coverages = booking.priced_coverages;
    this.pay_at_counter_fee = booking.pay_at_counter_fee;
  }

  userPointsBalanceInLocale(locale: Locale): string {
    return (Math.ceil(this.user_points_balance / 100) * 100).toLocaleString(locale.code);
  }

  // We don't allow car cancellation yet, so set always return true for now
  nonCancellable(): boolean {
    return true;
  }

  // We don't allow car cancellation yet, so set always return false for now
  showCancelBooking(): boolean {
    return false;
  }

  formattedStartTime(): string {
    return `${TimeUtils.format(this.start_time, "LL")}, ${TimeUtils.format(this.start_time, "LT")}`;
  }

  formattedEndTime(): string {
    return `${TimeUtils.format(this.end_time, "LL")}, ${TimeUtils.format(this.end_time, "LT")}`;
  }
}
