import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { HotelListItemDistanceComponent } from "./hotel-list-item-distance.component";

@NgModule({
  declarations: [ HotelListItemDistanceComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ HotelListItemDistanceComponent ],
  providers: [],
})
export class HotelListItemDistanceModule {}
