import { Component, OnInit } from "@angular/core";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import { COMPLEX_DIALOG, CURRENT_PAGE, GlobalDataEnum } from "booking-app-v2/shared/types";
import { HotelDestination, HotelSearchForm } from "booking-app-v2/hotels/models";
import { Router, UrlTree } from "@angular/router";
import { HotelEditSearchDialogComponent } from "../dialog/hotel-edit-search-dialog/hotel-edit-search-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { HotelsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/hotels-url-builder.service";

@Component({
  selector: "hotel-search-bar",
  templateUrl: "/html/components/hotel_search_bar_v2",
})
export class HotelSearchBarComponent implements OnInit {
  hotelSearchForm: HotelSearchForm;
  pointsPartnerIconUrl: string;
  stayDuration: number;
  isResultsPage: boolean;
  isDetailsPage: boolean;

  constructor(
    private dialogService: DialogService,
    private pageDiscoveryService: PageDiscoveryService,
    private globalData: GlobalData,
    private router: Router,
    private hotelsUrlBuilderService: HotelsUrlBuilderService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.hotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    this.pointsPartnerIconUrl = this.globalData.get(GlobalDataEnum.POINTS_PARTNER).logo_url;
    this.stayDuration = this.hotelSearchForm.duration;
    this.isResultsPage = this.pageDiscoveryService.currentPage() === CURRENT_PAGE.SEARCH_RESULT;
    this.isDetailsPage = this.pageDiscoveryService.currentPage() === CURRENT_PAGE.HOTEL_DETAIL;
  }

  openEditSearchDialog(): void {
    this.dialogService.open(COMPLEX_DIALOG.HOTEL_EDIT_SEARCH, HotelEditSearchDialogComponent );
  }

  backToSearchResultsPage(): void {
    this.router.navigate(
      [`/results/${this.hotelSearchForm.destination.value.destination_id}`],
      this.getSearchFormUrl(this.hotelSearchForm.destination),
    );
  }

  private getSearchFormUrl(destination: HotelDestination): UrlTree {
    const searchUrl: UrlTree = this.router.createUrlTree(
      [`/results/${destination.value.destination_id}`],
      {
        queryParams: this.hotelsUrlBuilderService.buildSearchUrl(),
      },
    );
    return searchUrl;
  }

}
