import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { HotelBooking } from "booking-app-v2/hotels/models";
import { CarBooking } from "booking-app-v2/cars/models";
import { Base, CancellationPolicyClause } from "booking-app-v2/shared/models/index";
import { TimeUtils } from "booking-app-v2/shared/utils";


export class CancellationPolicy extends Base {
  readonly free_cancellation_before: string;
  readonly non_refundable_from: string;
  readonly penalties?: CancellationPolicyClause[];
  readonly refundable: string;
  readonly remarks: string;
  readonly restrictive: boolean;
  readonly displayablePolicyClauses?: CancellationPolicyClause[];
  readonly freeCancellationText?: string;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    policy: CancellationPolicy,
  ) {
    super(appSettingsService, globalData);

    this.free_cancellation_before = TimeUtils.format(policy.free_cancellation_before, "LL");
    this.non_refundable_from = policy.non_refundable_from;
    this.penalties = this.mappedPenalties(policy.penalties);
    this.refundable = policy.refundable;
    this.remarks = policy.remarks;
    this.restrictive = policy.restrictive;
    this.displayablePolicyClauses = this.getDisplayablePolicyClauses();
    this.freeCancellationText = this.checkFreeCancellation(policy);
  }

  isRefundable(): boolean {
    return !(this.refundable === "no");
  }

  private getDisplayablePolicyClauses(): CancellationPolicyClause[] {
    return this.penalties.length > 0 ? this.penalties.filter(p => p.percentage < 100) : [];
  }

  private mappedPenalties(penalties: CancellationPolicyClause[]): CancellationPolicyClause[] {
    return penalties.map(penalty => {
      return new CancellationPolicyClause(this.appSettingsService, this.globalData, penalty);
    });
  }

  private checkFreeCancellation(policy): string {
    const roomRefundable = policy.refundable === "full";
    const freeCancellationBefore = policy.free_cancellation_before;
    return policy.freeCancellationText = roomRefundable && freeCancellationBefore;
  }
}
