import { HotelRawPrice } from "../types";
import { RoomPrice } from "../models";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { Currency } from "booking-app-v2/shared/models";
import { FormatNumberUtil } from "booking-app-v2/shared/utils";

export class HotelRoomPriceDecorator {

  static decorate(price: RoomPrice, rawPrice: HotelRawPrice): void {
    price.voucher_type_id = rawPrice.voucher_type_id || undefined;
    price.roomBookingVoucherType = rawPrice.voucher_type_id ?
      price.globalData.get(GlobalDataEnum.LANDING_PAGE).voucherTypes[rawPrice.voucher_type_id] :
      undefined;

    price.points = rawPrice.points;
    price.bonus = rawPrice.bonuses;

    price.price = this.setPriceWithTax(price, rawPrice);
    price.formattedPrice = this.formattedPrice(price);
  }

  private static setPriceWithTax(price: RoomPrice, rawPrice: HotelRawPrice): number {
    return price.globalData.get(GlobalDataEnum.TAX_INCLUSIVE) ?
      rawPrice.chargeableRate : rawPrice.chargeableRateWithoutTax;
  }

  private static formattedPrice(price: RoomPrice): string {
    const selectedCurrency: Currency = price.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
    const convertedPrice = price.price * selectedCurrency.rate;
    const roundedPrice = FormatNumberUtil.roundNumber(
      convertedPrice,
      price.globalData.get(GlobalDataEnum.SELECTED_LOCALE),
      selectedCurrency.decimalPlace,
    );

    if (price.appSettingsService.defaultCurrencyDisplay === "symbol") {
      return `${selectedCurrency.symbol}${roundedPrice}`;
    } else {
      return `${selectedCurrency.code} ${roundedPrice}`;
    }
  }
}
