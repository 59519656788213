import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { ConfirmationPageRoutingModule } from "../routes/confirmation-page-routing.module";
import { ConfirmationPageComponent } from "../confirmation-page.component";
import { StarRatingModule } from "booking-app-v2/shared/components/star-rating/star-rating.module";
import { CancellationPolicyModule } from "booking-app-v2/shared/components/cancellation-policy/cancellation-policy.module";
import { GoogleAnalyticsEcommerceTrackerModule } from "booking-app-v2/shared/components/google-analytics-ecommerce-tracker/google-analytics-ecommerce-tracker.module";
import { BannerModule } from "booking-app-v2/shared/components/banner";
import { ExclusiveBenefitsModule } from "booking-app-v2/shared/components/exclusive-benefits/exclusive-benefits.module";

@NgModule({
  imports: [
    SharedModule,
    ConfirmationPageRoutingModule,
    StarRatingModule,
    CancellationPolicyModule,
    GoogleAnalyticsEcommerceTrackerModule,
    BannerModule,
    ExclusiveBenefitsModule,
  ],
  declarations: [
    ConfirmationPageComponent,
  ],
})
export class SharedConfirmationPageModule {}
