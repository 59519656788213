import { Component } from "@angular/core";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";

@Component({
  selector: "partner-type-icon",
  template: `<i class="fa {{ iconClass() }}"></i>
  `,
})
export class PartnerTypeIconComponent {
  private partnerType: any;

  constructor(
    private globalData: GlobalData,
  ) {
    this.partnerType = this.globalData.get("pointsPartner").category;
  }

  public iconClass() {
    if (this.partnerType === "airline") { return "fa-plane"; }
    if (this.partnerType === "credit_card") { return "fa-credit-card"; }
    return "fa-car";
  }
}
