import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router, UrlTree } from "@angular/router";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { TrustYouService } from "booking-app-v2/shared/services/trust-you.service";
import { GlobalDataEnum, LinkTarget } from "booking-app-v2/shared/types";
import { Currency, LandingPage, PointsPartner } from "booking-app-v2/shared/models";
import { Hotel, HotelSearchForm, TrustYou } from "booking-app-v2/hotels/models";
import { TranslateService } from "@ngx-translate/core";
import { HotelsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/hotels-url-builder.service";
import { PointsCashPaymentHandlingService } from "booking-app-v2/shared/services/points-cash-payment-handling.service";

@Component({
  selector: "hotel-detail-popup",
  templateUrl: "/html/hotels/hotel_details_popup_v2",
})
export class HotelDetailPopupComponent implements OnInit, OnChanges {
  @Input() hotel: Hotel;

  hotelSearchForm: HotelSearchForm;
  landingPage: LandingPage;
  linkTarget: LinkTarget;
  showTotal: boolean;
  locationUrl: string;
  trustyou: TrustYou;

  constructor(
    public globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private trustYouService: TrustYouService,
    private router: Router,
    private hotelsUrlBuilderService: HotelsUrlBuilderService,
    private translateService: TranslateService,
    private pointsCashPaymentHandlingService: PointsCashPaymentHandlingService,
  ) { }

  ngOnInit() {
    this.hotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    this.landingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
    this.linkTarget = this.appSettingsService.openInTargetTab.markerPopUp;
    this.showTotal = this.appSettingsService.showTotalNights;
  }

  ngOnChanges(changes: SimpleChanges) {
    const newHotel: Hotel = changes.hotel.currentValue;
    const selectedCountrySite: { code: string } = this.globalData.get(GlobalDataEnum.SELECTED_COUNTRY_SITE);
    if (!newHotel || !selectedCountrySite) {
      return;
    }

    this.trustyou = new TrustYou(
      this.appSettingsService,
      this.globalData,
      this.trustYouService,
      this.hotel.trustyou,
      this.hotel.name,
    );

    // angular-v2-todo after trust you component PR is merged
    // if $scope.hotel && $scope.hotel.trustyou && $scope.hotel.trustyou.score
    //   $scope.hotel.trustyou.ui = TrustyouService.getScoreUIDetails($scope.hotel.trustyou.score.kaligo_overall.toFixed(1))
    //   $scope.hotel.trustyouClass = if $scope.hotel.trustyou.ui.color == 'positive' then "pos" else "neu"

    this.locationUrl = this.hotelDetailsUrl(newHotel);
  }

  zeroFullCashPayment(): boolean {
    return this.pointsCashPaymentHandlingService.zeroFullCashPayment(
      this.hotel.priceInfo.cash_payment,
      this.hotel.priceInfo.points_payment,
    );
  }

  private hotelDetailsUrl(hotel: Hotel): string {
    if (!hotel.available) {
      return "#";
    }
    const searchUrl: UrlTree = this.router.createUrlTree(
      [`/hotels/detail/${hotel.id}`],
      {
        queryParams: this.hotelsUrlBuilderService.buildDetailsPageUrl(),
      },
    );
    return searchUrl.toString();
  }

  get totalEarnTranslation(): string {
    return this.translateService.instant("txt.total_earn_for", {
      duration: `<b>${this.hotelSearchForm.duration}</b>`,
    });
  }

  get selectedCurrency(): Currency {
    return this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
  }

  get pointsPartner(): PointsPartner {
    return this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
  }
}
