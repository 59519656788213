import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";
import { FlightRawResponse } from "booking-app-v2/flights/types";
import { Price } from "booking-app-v2/shared/models";
import { CommonPriceDecorator } from "booking-app-v2/shared/decorators";
import { FlightPriceDecorator } from "booking-app-v2/flights/decorators/flight-price.decorator";

export class FlightPrice extends Price {
  has_discount_for_coupon_code: boolean;
  old_max_cash_payment: number;
  old_max_points_payment: number;
  points_payment: number;
  rate: number;
  points: number;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    payWithPointsCashService: PayWithPointsCashService,
    flightRawResponse: FlightRawResponse,
  ) {
    super(
      appSettingsService,
      globalData,
      payWithPointsCashService,
    );

    this.runFlightDecorators(flightRawResponse);
  }

  private runFlightDecorators(flightRawResponse: FlightRawResponse): void {
    CommonPriceDecorator.decorate(this, flightRawResponse);
    FlightPriceDecorator.decorate(this, flightRawResponse);
  }
}
