import { Injectable } from "@angular/core";

import { BaseStep } from "./base-step";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  RedirectToDetailsResult,
} from "booking-app-v2/shared/types";
import { CheckoutError } from "booking-app-v2/shared/models";
import { CarsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/cars-url-builder.service";
import { WindowRefService } from "../../window-ref.service";

@Injectable({
  providedIn: "root",
})
export class CarRedirectToDetailsService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.REDIRECT_TO_DETAILS;

  constructor(
    private windowRefService: WindowRefService,
    private carsUrlBuilderService: CarsUrlBuilderService,
  ) {}

  process(results: CheckoutResults): RedirectToDetailsResult {
    if (!(results[CHECKOUT_RESULT_NAME.LOGIN_CHECK])) {
      this.carsUrlBuilderService.redirectToCarDetailsPage(
        this.windowRefService.getURIParamFromWindow(),
      );
      throw new CheckoutError("Unauthorised to visit checkout page without login");
    }
  }

}
