import { Component, Input } from "@angular/core";

@Component({
  selector: "room-details-dialog-content",
  template: `
    <div class="image-container">
      <gallery [imageList]="images" [arrowAriaLabel]="'room'" [imageInsetShadow]="false">
      </gallery>
    </div>
    <div class="room-description-container" *ngIf="longRoomDescription">
      <div class="detail-content">
        <div class="room-title" [innerHTML]="shortRoomDescription">
        </div>
        <div class="room-description" [innerHTML]="longRoomDescription">
        </div>
      </div>
    </div>
  `,
})
export class RoomDetailsDialogContentComponent {
  @Input() images: string[];
  @Input() longRoomDescription: string;
  @Input() shortRoomDescription: string;
  @Input() defaultImage: string;

  constructor() { }
}
