import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { map, Observable, of, startWith } from "rxjs";

import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { SortUtil } from "booking-app-v2/shared/utils";
import { Country, PhoneCode, State } from "booking-app-v2/shared/types";
import { countries } from "booking-app-v2/shared/constants";

const COUNTRIES_REQUIRING_STATE = ["AU", "BR", "CA", "US"];

@Injectable({
  providedIn: "root",
})
export class CountryService {
  phoneCountriesMap: Record<string, PhoneCode> = {};
  phoneCountriesList: PhoneCode[] = [];
  countriesMap: Record<string, Country> = {};
  countriesList: Country[] = [];

  constructor(private apiDataService: ApiDataService) {
    this.buildCountryLists();
  }

  getCountries(): Country[] {
    return countries;
  }

  getCountry(countryCode: string): Country {
    if (countryCode == null) {
      return undefined;
    }
    const selectedCountry: Country = countries.find((country: Country) => country.code === countryCode);
    return selectedCountry ? Object.assign({}, selectedCountry) : undefined;
  }

  getCountryByPhoneCode(phoneCode: number): Country {
    if (phoneCode == null || isNaN(phoneCode)) {
      return undefined;
    }
    const selectedCountry: Country = countries.find((country: Country) => country.phoneCode === phoneCode);
    return selectedCountry ? Object.assign({}, selectedCountry) : undefined;
  }

  getState(countryCode: string, stateCode: string): Observable<State> {
    return countryCode?.length === 2 && stateCode?.length === 2
      ? this.apiDataService.get(`countries/${countryCode}/states/${stateCode}`)
      : of(undefined);
  }

  getStates(countryCode: string): Observable<State[]> {
    return countryCode?.length === 2 ? this.apiDataService.get(`countries/${countryCode}/states`) : of([]);
  }

  getFilteredStates(stateList: State[], stateFormControl: FormControl) {
    return stateFormControl.valueChanges.pipe(
      startWith(stateFormControl.value),
      map((stateControlValue: State | string) => {
        const stateName = this.getNameFromFormControlValue(stateControlValue);
        return this.filteredStatesList(stateList, stateName);
      }),
    );
  }

  isStateRequired(countryCode: string): boolean {
    return countryCode && COUNTRIES_REQUIRING_STATE.includes(countryCode);
  }

  getNameFromFormControlValue(formControlValue: Country | State | string): string {
    const controlValue = formControlValue ?? "";
    return typeof controlValue === "string" ? controlValue : controlValue.name;
  }

  filteredStatesList(stateList: State[], stateName: string): State[] {
    return stateName
      ? stateList.filter((state: State) => {
          return state.name.toLowerCase().includes(stateName.toLowerCase());
        })
      : stateList.slice();
  }

  filteredCountriesList(countryName: string): Country[] {
    return countryName
      ? this.countriesList.filter((country: Country) => {
          return country.name.toLowerCase().includes(countryName.toLowerCase());
        })
      : this.countriesList.slice();
  }

  private buildCountryLists(): void {
    this.getCountries().forEach((country: Country) => {
      const countryCodeId: string = `(+${country.phoneCode})`;
      const countryCodeText: string = `${country.name} ${countryCodeId}`;
      this.phoneCountriesMap[countryCodeText] = { id: countryCodeId, text: countryCodeText };
      this.countriesMap[country.name] = country;
    });
    this.phoneCountriesList = SortUtil.string<PhoneCode>(Object.values(this.phoneCountriesMap), "text");
    this.countriesList = SortUtil.string<Country>(Object.values(this.countriesMap), "name");
  }
}
