import { FlightPrice } from "booking-app-v2/flights/models";
import { FlightRawResponse } from "booking-app-v2/flights/types";

export class FlightPriceDecorator {
  static decorate(price: FlightPrice, rawResponse: FlightRawResponse): void {
    price.has_discount_for_coupon_code = rawResponse.has_discount_for_coupon_code;
    price.old_max_cash_payment = rawResponse.max_cash_payment;
    price.old_max_points_payment = rawResponse.max_points_payment;
    price.points = rawResponse.points;
  }
}
