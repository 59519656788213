import { ObjectValues } from "./object-values.type";
import { FlightPricesRawResponse } from "booking-app-v2/flights/types";

export interface FlightRevalidationResult {
  status: FlightRevalidationStatus;
  response: FlightPricesRawResponse;
}

export const FLIGHT_REVALIDATION_STATUS = {
  SUCCESS: "success",
  NO_RESULTS: "no-results",
  ERROR: "error",
  NO_REVALIDATION_REQUIRED: "no-revalidation-required",
} as const;

export type FlightRevalidationStatus = ObjectValues<typeof FLIGHT_REVALIDATION_STATUS>;
