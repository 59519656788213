import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { HotelSearchForm } from "booking-app-v2/hotels/models";

@Component({
  selector: "hotel-edit-search-dialog",
  templateUrl: "/html/dialogs/hotels_search_dialog_v2",
})
export class HotelEditSearchDialogComponent {
  modalContent: HotelSearchForm;

  constructor(public dialogRef: MatDialogRef<HotelEditSearchDialogComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
