import { Component, Input } from "@angular/core";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { LocaleService } from "booking-app-v2/shared/services/initializers/locale.service";

import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { Locale } from "booking-app-v2/shared/models";

@Component({
  selector: "locale-dropdown",
  template: `
    <ng-select class="locale-switcher"
               *ngIf="useNgSelect && selectedLocale"
               [ngModel]="selectedLocale.langCode"
               [clearable]="false"
               [searchable]="false"
               (ngModelChange)="changeLocale($event)"
               [ngClass] ="[hasArrow ? 'ng-select-with-arrow': '' ]">
      <ng-template ng-label-tmp>
        <span class="ng-value-label">
          <div *ngIf="showSelectedFlag" class="flag flag-{{ selectedLocale.flagCode.toLowerCase() }}"></div>
          <div *ngIf="showSelectedText" class="dropdown-item-name" [textContent]="selectedLocale.text"></div>
          <div *ngIf="showSelectedCode" class="dropdown-item-code" [textContent]="selectedLocale.code.toUpperCase()"></div>
        </span>
      </ng-template>
      <ng-option *ngFor="let locale of locales" [value]="locale.langCode">
        <div *ngIf="showOptionFlag" class="flag flag-{{ locale.flagCode.toLowerCase() }}"></div>
        <div *ngIf="showOptionText" class="dropdown-item-name" [textContent]="locale.text"></div>
        <div *ngIf="showOptionCode" class="dropdown-item-code" [textContent]="locale.code.toUpperCase()"></div>
      </ng-option>
    </ng-select>

    <ng-container *ngIf="!useNgSelect && selectedLocale">
      <div class="nav-label" [translate]="'Language'"></div>
      <div class="nav-value">
        <div class="flag flag-{{ selectedLocale.flagCode.toLowerCase() }}"></div>
        <div class="nav-language-text" [textContent]="selectedLocale.text"></div>
      </div>
      <div class="chevron-right"></div>
      <select class="hiding"
              [ngModel]="selectedLocale.langCode"
              (ngModelChange)="changeLocale($event)">
        <option *ngFor="let locale of locales"
                [textContent]="locale.text"
                [value]="locale.langCode">
        </option>
      </select>
    </ng-container>
  `,
})

export class LocaleDropdownComponent {
  @Input() useNgSelect: boolean;

  @Input() showSelectedFlag: boolean = true;
  @Input() showSelectedCode: boolean = false;
  @Input() showSelectedText: boolean = false;

  @Input() showOptionFlag: boolean = true;
  @Input() showOptionCode: boolean = false;
  @Input() showOptionText: boolean = true;

  @Input() hasArrow: boolean = false;

  private locales: Locale[];

  constructor(
    private localeService: LocaleService,
    private globalData: GlobalData,
  ) {
    this.locales = this.globalData.get(GlobalDataEnum.ENABLED_LOCALES);
  }

  changeLocale(newLocaleLangCode: string): void {
    this.localeService.changeLocale(newLocaleLangCode);
  }

  get selectedLocale(): Locale {
    return this.globalData.get(GlobalDataEnum.SELECTED_LOCALE);
  }
}
