import { Component, OnDestroy, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { StripeIntentFields } from "booking-app-v2/shared/types";
import { StripePaymentIntentService } from "booking-app-v2/shared/services/stripe-payment-intent.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "stripe-intent-payment-form",
  templateUrl: "/html/components/stripe_intent_payment_form_v2",
})

export class StripePaymentIntentFormComponent implements OnInit, OnDestroy {
  @Input() checkoutForm: FormGroup;
  @Input() checkoutFormSubmittedListener$: Observable<void>;

  isNameOnCardInvalid: boolean;
  nameOnCard: string;
  checkoutFormSubmitted: boolean;

  constructor(
    private globalData: GlobalData,
    private stripePaymentIntentService: StripePaymentIntentService,
  ) {
  }

  ngOnInit(): void {
    this.stripePaymentIntentService.mountStripeIntentElements();
    this.stripePaymentIntentService.setupOnChangeListeners();
    this.initCheckoutFormSubmittedListenerSubscription();
  }

  ngOnDestroy() {
    this.stripePaymentIntentService.destroyCurrencySubscription();
  }

  showErrorMessage(field: StripeIntentFields): boolean {
    return (
      this.stripePaymentIntentService.errorFlags[field] ||
      (this.checkoutFormSubmitted && !this.stripePaymentIntentService.stripeIntentForm[field])
    );
  }

  handleNameOnCardOnChange(inputName: string): void {
    this.nameOnCard = inputName;
    if (!!inputName && this.isNameOnCardInvalid) {
      this.isNameOnCardInvalid = false;
    }

    this.checkoutForm.controls.cardName.setValue(inputName);

    if (!this.checkoutForm.controls.cardName.value) {
      this.isNameOnCardInvalid = true;
    }
  }

  private initCheckoutFormSubmittedListenerSubscription(): void {
    this.checkoutFormSubmittedListener$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.checkoutFormSubmitted = true;
      });
  }
}
