import { CARD, Card } from "booking-app-v2/shared/types";

interface CardTypePattern {
  readonly pattern: RegExp;
  readonly eagerPattern: RegExp;
  readonly card: Card;
}

export class CardTypeUtil {
  static getCardType(ccNumber: string, eager: boolean): Card {
    const targetCardTypePattern: CardTypePattern = this.cardTypePatterns.find((cardTypePattern: CardTypePattern) => {
      return cardTypePattern[this.getProperty(eager)].test(ccNumber);
    });
    return targetCardTypePattern ? targetCardTypePattern.card : undefined;
  }

  private static readonly cardTypePatterns: CardTypePattern[] = [
    // EXAMPLE
    // 4111111111111111
    // 4012888888881881
    // regex grabbed from here: http://www.regular-expressions.info/creditcard.html
    {
      pattern: /^4\d{15}?$/,
      eagerPattern: /^4/,
      card: CARD.VISA,
    },
    // EXAMPLE
    // 5555555555554444
    // 5105105105105100
    // regex grabbed from here: http://www.regular-expressions.info/creditcard.html
    {
      pattern: /^5[1-5]\d{14}$/,
      eagerPattern: /^5[1-5]/,
      card: CARD.MASTERCARD,
    },
    // EXAMPLE
    // 378282246310005
    // 371449635398431
    // regex grabbed from here: http://www.regular-expressions.info/creditcard.html
    {
      pattern: /^3[47]\d{13}$/,
      eagerPattern: /^3[47]/,
      card: CARD.AMEX,
    },
    // EXAMPLE
    // 30569309025904
    // 38520000023237
    // regex grabbed from here: http://www.regular-expressions.info/creditcard.html
    {
      pattern: /^3(?:0[0-5]|[68][0-9])\d{11}$/,
      eagerPattern: /^(36|38|30[0-5])/,
      card: CARD.DINERS,
    },
    // EXAMPLE
    // 6011111111111117
    // regex grabbed from here: http://stackoverflow.com/a/13500918/613896
    {
      pattern: /^6(?:011\d{12}|5\d{14}|4[4-9]\d{13}|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d{2}|9(?:[01]\d|2[0-5]))\d{10})$/,
      eagerPattern: /^(6011|65|64[4-9]|622)/,
      card: CARD.DISCOVER,
    },
    // EXAMPLE
    // 5078601870000127985
    // 5078601800003247449
    {
      pattern: /^(5078\d{15})$/,
      eagerPattern: /^(5078)/,
      card: CARD.AURA,
    },
    // EXAMPLE
    // 6362970000457013
    {
      pattern: /^(6362\d{12})$/,
      eagerPattern: /^(6362)/,
      card: CARD.ELO,
    },
    // EXAMPLE
    // 6062825624254001
    {
      pattern: /^(6062\d{12})$/,
      eagerPattern: /^(6062)/,
      card: CARD.HIPERCARD,
    },
    // EXAMPLE
    // 3088000000000009
    // regex grabbed from here: https://stackoverflow.com/questions/35565991/reg-ex-for-jcb-credit-card-validation
    {
      pattern: /^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9]))\d{12})$/,
      eagerPattern: /^(3(?:088|096|112|158|337|5(?:2[89]|[3-8][0-9])))/,
      card: CARD.JCB,
    },
  ];

  private static getProperty(eager: boolean): string {
    return eager ? "eagerPattern" : "pattern";
  }
}
