import { Component, Input } from "@angular/core";

@Component({
  selector: "checkout-button",
  template: `
    <main-button *ngIf="!paymentIsLoading" type="submit" [isDisabled]="isDisabled" [hasRelativePosition]="false">
      <div class="button-content-block" centerContent="">
        <span [textContent]="checkoutTranslationKey | translate"></span>
        <i class="fa fa-chevron-right"></i>
      </div>
    </main-button>
    <main-button *ngIf="paymentIsLoading">
    </main-button>
  `,
})
export class CheckoutButtonComponent {
  @Input() checkoutTranslationKey: string;
  @Input() paymentIsLoading: boolean;
  @Input() isDisabled: boolean;

  constructor() { }
}
