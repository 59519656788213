import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { RedemptionDiscountComponent } from "./redemption-discount.component";

@NgModule({
  declarations: [ RedemptionDiscountComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ RedemptionDiscountComponent ],
  providers: [],
})
export class RedemptionDiscountModule {}
