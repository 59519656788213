import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { HotelResultMapMobileComponent } from "./hotel-result-map-mobile.component";
import { BottomSheetWrapperModule } from "booking-app-v2/shared/components/bottom-sheet-wrapper/bottom-sheet-wrapper.module";

@NgModule({
  declarations: [ HotelResultMapMobileComponent ],
  imports: [
    SharedModule,
    BottomSheetWrapperModule,
  ],
  exports: [ HotelResultMapMobileComponent ],
  providers: [],
})
export class HotelResultMapMobileModule {}
