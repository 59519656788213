import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { RoomGuestCounterModule } from "booking-app-v2/shared/components/room-guest-counter/room-guest-counter.module";
import {
  BottomSheetWrapperModule,
} from "booking-app-v2/shared/components/bottom-sheet-wrapper/bottom-sheet-wrapper.module";
import { RoomGuestFieldMobileComponent } from "./room-guest-field-mobile.component";

@NgModule({
  declarations: [ RoomGuestFieldMobileComponent ],
  imports: [
    SharedModule,
    RoomGuestCounterModule,
    BottomSheetWrapperModule,
  ],
  exports: [ RoomGuestFieldMobileComponent ],
  providers: [],
})
export class RoomGuestFieldMobileModule {}
