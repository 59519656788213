import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, catchError, finalize, map, Observable, of, Subject, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";

import { CardTypeUtil, TimeUtils } from "booking-app-v2/shared/utils";

import {
  CARD,
  Card,
  COMPLEX_DIALOG,
  CreditCardValidationResult,
  DialogData,
  GlobalDataEnum,
  PartnerScore,
  SIMPLE_DIALOG,
  TRAVEL_TYPE,
} from "booking-app-v2/shared/types";
import { PointsPartner } from "booking-app-v2/shared/models";
import { RoomPrice } from "booking-app-v2/hotels/models";

import { SimpleDialogComponent } from "booking-app-v2/shared/components/dialog/simple-dialog/simple-dialog.component";
import {
  InvalidCreditCardDialogComponent,
} from "booking-app-v2/shared/components/dialog/invalid-credit-card-dialog/invalid-credit-card-dialog.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

interface CreditCardValidationResponse {
  status: "VALIDATION_PASS" | "BONUS_VALIDATION" | "INVALID_UOB_BIN_FOR_DLP" | "INVALID_BIN_FOR_PARTNER";
  eligible_bonus: number;
  matching_partner: number;
}

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class CreditCardValidationService {

  onCreditCardValidated: Subject<CreditCardValidationResult> = new Subject<CreditCardValidationResult>();
  onPointsPartnerChanged: Subject<void> = new Subject<void>();
  onPartnerScoreUpdated: Subject<PartnerScore> = new BehaviorSubject<PartnerScore>({ base: 0, bonus: 0 });
  isValidating: boolean = false;

  roomPrice: RoomPrice;
  partnerScore: PartnerScore;

  private readonly invalidCardStatus = {
    INVALID_CARD_HSBC: "HSBC",
    INVALID_CARD_VISA: "Visa",
  };
  private readonly errorMessages = {
    INVALID_BIN_FOR_PARTNER: `<p>You have selected to earn rewards with your <b>:programName</b>.</p>
    <p>Please use a <b>:programName</b> for payment, or go back to change your rewards partner selection.</p>`,
    INVALID_BIN_FOR_PARTNER_MATCHING: `<p>You are making payment with <b>:newProgramName</b>.</p>
    <p>If you proceed, we will automatically change your rewards currency to <b>:currency</b>.</p>
    <p>Alternatively, you can enter a different payment card and earn with <b>:programName</b>.</p>`,
    BONUS_VALIDATION: "hotels_checkout.bonus_validation.modal_body",
    INVALID_BIN_FOR_PARTNER_AIRLINE: "hotels_checkout.cc_validation.invalid_bin_for_airline",
    VALUE_CHANGED: "hotels_checkout.bonus_validation.value_changed",
  };

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private apiDataService: ApiDataService,
    private dialogService: DialogService,
    private translateService: TranslateService,
  ) {
    this.onPartnerScoreUpdated
      .pipe(untilDestroyed(this))
      .subscribe((newPartnerScore: PartnerScore) => {
        this.partnerScore = newPartnerScore;
      });
  }

  validate(ccNumber: string, eager: boolean = false): Observable<boolean> {
    const cardType: Card = CardTypeUtil.getCardType(ccNumber, eager);
    const isValid: boolean = !!cardType;
    const supportCheck: string[] = this.appSettingsService.supportedCards[cardType];
    let isSupported: boolean = true;
    let warnAmex: boolean = false;

    // if card is valid, check if it is supported by the selected currency
    if (isValid) {
      // if supportCheck is null, we assume that it support all currency
      if (supportCheck && supportCheck.indexOf(this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).code) < 0) {
        isSupported = false;
      }
      // for amex card, display error message when it is not supported
      if (cardType === CARD.AMEX) {
        warnAmex = !(isValid && isSupported);
      }
    }

    // emit intermediate results for checkout page component display
    this.onCreditCardValidated.next({ cardType, warnAmex });

    // if card is valid and currency is supported, we call the backend validation
    if (isValid && isSupported) {
      const travelType = this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);
      let params = {};

      switch (travelType) {
        case TRAVEL_TYPE.HOTELS:
          params = {
            roomPrice: this.roomPrice.leftToPay,
            basePoints: this.partnerScore.base,
            checkin: this.formatDate(this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM).checkInDate),
            checkout: this.formatDate(this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM).checkOutDate),
          };
          break;
        default:
          params = {};
      }

      return this.validateFromBackend({
        ccNumber,
        params,
      });
    } else {
      return of(false);
    }
  }

  defaultParams(ccNumber: string): any {
    return {
      landingPageUrl: this.globalData.get(GlobalDataEnum.LANDING_PAGE).url,
      partnerId: this.globalData.get(GlobalDataEnum.POINTS_PARTNER).id,
      creditCardBin: ccNumber.substring(0, 9),
      product_type: this.productType(),
    };
  }

  private validateFromBackend({
    ccNumber,
    params = {},
  }: {
    ccNumber: string;
    params?: {};
  }): Observable<boolean> {

    if (this.isValidating || !ccNumber) {
      return;
    }

    this.isValidating = true;

    return this.pollCreditCardValidation(ccNumber, params);
  }

  private pollCreditCardValidation(ccNumber: string, params: any): Observable<boolean> {
    return this.apiDataService.post(
      "checkout/validate",
      {
        ...this.defaultParams(ccNumber),
        ...params,
      },
    ).pipe(
      catchError(() => of(true)),
      map((response: boolean | CreditCardValidationResponse) => {
        if (typeof response === "boolean") {
          return response;
        } else if (response.status) {
          return this.handleResponseByStatus(response);
        } else {
          return true;
        }
      }),
      finalize(() => this.isValidating = false),
    );
  }

  // contains scope.hotel specific data, but this response will only be returned by checkout_credit_card_validator.rb
  // and not for whitelabel credit card validator
  // TODO: refactor when WLs also implement something similar to checkout_credit_card_validator
  // WLs validators only return the following
  private handleResponseByStatus(response: CreditCardValidationResponse): boolean {
    switch (response.status) {
      case "BONUS_VALIDATION":
        if (!this.partnerScore || this.partnerScore.bonus === response.eligible_bonus) {
          return true;
        }
        this.onPartnerScoreUpdated.next({
          base: this.partnerScore.base,
          bonus: response.eligible_bonus,
        });
        const originalPartnerBonus: number = this.roomPrice.bonus;
        if (!(response.eligible_bonus !== null && originalPartnerBonus !== response.eligible_bonus)) {
          return true;
        }

        const valueChanged: boolean = response.eligible_bonus > originalPartnerBonus;
        this.openBonusValidationDialog(valueChanged);
        return true;
      case "VALIDATION_PASS":
        if (this.roomPrice?.bonus != null) {
          this.onPartnerScoreUpdated.next({
            base: this.partnerScore.base,
            bonus: this.roomPrice.bonus,
          });
        }
        return true;
      case "INVALID_BIN_FOR_PARTNER":
        if (response.matching_partner) {
          this.openChangePartnerDialog(response);
        } else {
          // message for when if no matching_partner or pointsPartner data found
          this.openInvalidCreditCardForPartnerDialog(response.status);
        }
        return false;
      case "INVALID_UOB_BIN_FOR_DLP":
        this.openPaymentCardValidationDialog("UOB");
        return false;
      default:
        const invalidCard: string = this.invalidCardStatus[response.status];
        if (invalidCard) {
          if (this.appSettingsService.ccValidationUseCardErrorModal) {
            this.openCardErrorDialog();
          } else {
            this.openPaymentCardValidationDialog(invalidCard);
          }
        }
        return !invalidCard;
    }
  }

  private openBonusValidationDialog(valueChanged: boolean): void {
    const key: string = valueChanged ? "VALUE_CHANGED" : "BONUS_VALIDATION";
    const dialogBody: string = this.translateService.instant(this.errorMessages[key],
      { currency: this.globalData.get(GlobalDataEnum.POINTS_PARTNER).currency_long });
    this.dialogService.open(SIMPLE_DIALOG.BONUS_VALIDATION, SimpleDialogComponent, { dialogBody });
  }

  private openInvalidCreditCardForPartnerDialog(status: string): void {
    const dialogBody: string = this.translateService.instant(this.errorMessages[status],
      { programName: this.globalData.get(GlobalDataEnum.POINTS_PARTNER).program_name });
    this.dialogService.open(SIMPLE_DIALOG.INVALID_CREDIT_CARD_FOR_PARTNER, SimpleDialogComponent, { dialogBody });
  }

  private openChangePartnerDialog(response: CreditCardValidationResponse): void {
    let dialogData: DialogData;
    const newPointsPartner: PointsPartner =
      this.globalData.get(GlobalDataEnum.POINTS_PARTNERS).findById(response.matching_partner);

    if (newPointsPartner.category === "credit_card") {
      dialogData = {
        dialogHeader: "Change Points Partner",
        dialogBody: this.translateService.instant(this.errorMessages[response.status + "_MATCHING"], {
          programName: this.normalizeCobrandProgramName(this.globalData.get(GlobalDataEnum.POINTS_PARTNER)),
          newProgramName: this.normalizeCobrandProgramName(newPointsPartner),
          currency: newPointsPartner.currency_long,
        }),

        resolveButtonText: this.translateService.instant(
          "Proceed & earn", { currency: newPointsPartner.currency_long },
        ),
        rejectButtonText: "Go back & change payment card",
      };
    } else if (newPointsPartner.category === "airline") {
      dialogData = {
        dialogHeader: "Points adjustment",
        dialogBody: this.errorMessages.INVALID_BIN_FOR_PARTNER_AIRLINE,
        resolveButtonText: "Proceed",
        rejectButtonText: "Go back & change payment card",
      };
    }

    this.dialogService.open(SIMPLE_DIALOG.CHANGE_PARTNER, SimpleDialogComponent, dialogData)
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.globalData.set(GlobalDataEnum.POINTS_PARTNER, newPointsPartner);
          this.onPointsPartnerChanged.next();
        }
      });
  }

  private openCardErrorDialog(): void {
    this.dialogService.open(COMPLEX_DIALOG.INVALID_CREDIT_CARD, InvalidCreditCardDialogComponent);
  }

  private openPaymentCardValidationDialog(invalidCard: string): void {
    this.dialogService.open(SIMPLE_DIALOG.PAYMENT_CARD_VALIDATION, SimpleDialogComponent, {
      dialogBody: `Please use a valid ${invalidCard} card for payment.`,
    });
  }

  private normalizeCobrandProgramName(pointsPartner: PointsPartner): string {
    switch (pointsPartner.name) {
      case "Virgin Atlantic Bank of America":
        return "Virgin Atlantic World Elite MasterCard Credit Card";
      case "Avianca Cobrand":
        return "Avianca LifeMiles Credit Card";
      default:
        return pointsPartner.program_name;
    }
  }

  private productType(): string | undefined {
    return this.appSettingsService.useProductType
      ? this.globalData.productTypeAdapter(this.globalData.get(GlobalDataEnum.PRODUCT_TYPE))
      : undefined;
  }

  private formatDate(dateStr: string): string {
    return TimeUtils.format(dateStr, "YYYY-MM-DD", "MM/DD/YYYY");
  }

}
