import { Pipe, PipeTransform } from "@angular/core";

import { FormatNumberUtil } from "../utils/format-number.util";
import { Locale } from "booking-app-v2/shared/models/locale.model";

@Pipe({
  name: "numberFmt",
  pure: false,
})
export class NumberFormat implements PipeTransform {

  constructor() { }

  public transform(value: number, locale: Locale, decimalPlace: number): string {
    return FormatNumberUtil.roundNumber(value, locale, decimalPlace);
  }
}
