import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { landingPageRoutes } from "./landing-page.routes";

@NgModule({
  imports: [
    RouterModule.forChild(landingPageRoutes),
  ],
  exports: [RouterModule],
})
export class LandingPageRoutingModule { }
