import { CarPrice } from "booking-app-v2/cars/models";
import { CarRawResponse } from "booking-app-v2/cars/types";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

export class CarPriceDecorator {
  static decorate(price: CarPrice, rawResponse: CarRawResponse): void {
    price.has_discount_for_coupon_code = rawResponse.has_discount_for_coupon_code;
    price.voucher_type_id = rawResponse.voucher_type_id || undefined;
    price.bookingVoucherType = rawResponse.voucher_type_id ?
      price.globalData.get(GlobalDataEnum.LANDING_PAGE).voucherTypes[rawResponse.voucher_type_id] :
      undefined;
    price.points = rawResponse.points;
    price.bonus = rawResponse.bonuses;
    price.totalPrice = rawResponse.max_cash_payment;
  }
}
