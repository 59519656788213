import { LandingPage } from "booking-app-v2/shared/models";
import { CashRange, GlobalDataEnum, PRODUCT_TYPE, RedeemPoints } from "booking-app-v2/shared/types";
import { FilterForm, Hotel, Hotels } from "../models";
import { PopularCategories, ReviewScore, ScoreValues, StarRatings } from "../types";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";

export class FilterUtil {
  static globalData: GlobalData;
  static appSettingsService: AppSettingsService;
  static displayCurrentHotelSet: { endIndex: number; hotels: Hotel[], startIndex: number } = {
    endIndex: 8,
    hotels: [],
    startIndex: 0,
  };
  static preFilterHotels: (hotels: Hotel[], searchCompleted: boolean) => Hotel[];
  static readonly minimumScore: number = 80;

  static init(globalData: GlobalData, appSettingsService: AppSettingsService) {
    this.globalData = globalData;
    this.appSettingsService = appSettingsService;
    const landingPage: LandingPage = globalData.get(GlobalDataEnum.LANDING_PAGE);

    if (landingPage.hasProductType(PRODUCT_TYPE.VOUCHER)) {
      this.preFilterHotels =  this.filterVoucherHotels;
    } else if (landingPage.hasProductType(PRODUCT_TYPE.COMPLIMENTARY_NIGHTS) ||
      landingPage.hasProductType(PRODUCT_TYPE.REDEEM)) {
      this.preFilterHotels =  this.filterAvailableHotels;
    } else {
      this.preFilterHotels =  this.filterVisibleHotels;
    }
  }

  static filterHotelsWithFilterForm(preFilteredList: Hotel[], filterForm: FilterForm): Hotel[] {
    let newFilteredList: Hotel[] = new Hotels(
        this.appSettingsService,
        this.globalData,
        undefined,
        preFilteredList,
      ).unFilteredList;

    newFilteredList = this.byName(newFilteredList, filterForm.name);
    newFilteredList = this.byStarRatings(newFilteredList, filterForm.starRatings);
    newFilteredList = this.byPopularCategory(newFilteredList, filterForm.popularCategories);
    newFilteredList = this.byReviewScore(newFilteredList, filterForm.reviewScore);
    newFilteredList = this.byVoucher(newFilteredList, filterForm.selectedVoucherTypeId);
    newFilteredList = this.byRedeemPoints(newFilteredList, filterForm.redeemPoints);
    newFilteredList = this.byCashRange(newFilteredList, filterForm.cashRange);

    return newFilteredList;
  }
  private static byVoucher(newFilteredList: Hotel[], voucherId: number): Hotel[] {
    if (!voucherId) {
      return newFilteredList;
    }

    return newFilteredList.filter(hotel => hotel.priceInfo.voucher_type_id === voucherId );
  }

  private static byName(newFilteredList: Hotel[], name: string): Hotel[] {
    if (name === "") {
      return newFilteredList;
    }

    const nameInLowerCase: string = name.toLowerCase();
    return newFilteredList.filter((hotel: Hotel) => {
      return hotel.name.toLowerCase().includes(nameInLowerCase) ||
        hotel.original_metadata.name?.toLowerCase()?.includes(nameInLowerCase);
    });
  }

  private static byStarRatings(newFilteredList: Hotel[], starRatings: StarRatings): Hotel[] {
    if (!Object.values(starRatings).includes(true)) {
      return newFilteredList;
    }

    return newFilteredList.filter((hotel: Hotel) => {
      return Object.keys(starRatings)
        .filter(rating => starRatings[rating])
        .includes(hotel.rating.toString());
    });
  }

  private static byPopularCategory(newFilteredList: Hotel[], popularCategories: PopularCategories): Hotel[] {
    if (!Object.values(popularCategories).includes(true)) {
      return newFilteredList;
    }

    return newFilteredList.filter((hotel: Hotel) => {
      const scores: ScoreValues = hotel.trustyou?.score;
      return scores && !this.isScoreTooLow(scores, popularCategories);
    });
  }

  private static byReviewScore(newFilteredList: Hotel[], reviewScore: ReviewScore): Hotel[] {
    if (!reviewScore.min) {
      return newFilteredList;
    }

    let score: number;
    return newFilteredList.filter((hotel: Hotel) => {
      if (hotel.trustyou) {
        score = hotel.trustyou.score.kaligo_overall;
      } else {
        score = 2.6;
      }

      score = score <= 2.6 ? 2.6 : score;
      return score >= reviewScore.min && score <= reviewScore.max;
    });
  }

  private static byRedeemPoints(newFilteredList: Hotel[], redeemPoints: RedeemPoints): Hotel[] {
    if (!redeemPoints) {
      return newFilteredList;
    }

    const minPoints: number = redeemPoints.min - 1;
    const maxPoints: number = redeemPoints.max + 1;

    return newFilteredList.filter((hotel: Hotel) => {
      let points: number;
      if (this.appSettingsService.showTotalNights) {
        points = hotel.priceInfo.points_payment;
      } else {
        points = hotel.priceInfo.points_payment_per_night;
      }

      return points >= minPoints && points <= maxPoints;
    });
  }

  private static byCashRange(newFilteredList: Hotel[], cashRange: CashRange): Hotel[] {
    if (!cashRange) {
      return newFilteredList;
    }

    const minPrice: number = cashRange.min - 1;
    const maxPrice: number = cashRange.max + 1;

    return newFilteredList.filter((hotel: Hotel) => {
      const price: number = hotel.priceInfo?.price;
      return price && price >= minPrice && price <= maxPrice ;
    });
  }

  private static isScoreTooLow(scores: ScoreValues, popularCategories: PopularCategories): boolean {
    /**
     * Returns true if the hotel trust you score for the checked popular category
     * is less than the minimumScore. If returns true, we omit the hotel from
     * the filtered list in the caller method
    */
    for (const filter in popularCategories) {
      if (!popularCategories[filter]) {
        continue;
      }
      if (!scores[filter] || scores[filter] < this.minimumScore) {
        return true;
      }
    }
    return false;
  }

  private static filterByStarRatingAndAvailability(hotels: Hotel[]): Hotel[] {
    let filteredHotels: Hotel[] = this.preFilterByStarRating(hotels, this.appSettingsService.ratingArray);
    if (this.appSettingsService.preFilterUnavailableHotels) {
      filteredHotels = this.filterUnavailableHotels(hotels);
    }
    return filteredHotels;
  }

  private static filterAvailableHotels(hotels: Hotel[]): Hotel[] {
    hotels = hotels.filter(hotel => hotel.available);
    return this.filterByStarRatingAndAvailability(hotels);
  }

  private static filterVoucherHotels(hotels: Hotel[]): Hotel[] {
    hotels = hotels.filter(hotel => hotel.priceInfo?.voucher_type_id);
    return this.filterByStarRatingAndAvailability(hotels);
  }

  private static filterVisibleHotels(hotels: Hotel[], searchCompleted: boolean): Hotel[] {
    const results: Hotel[] = [];
    let unavailableHotelsCount: number = 0;

    hotels.forEach((hotel: Hotel): void => {
      if (hotel.priceInfo?.price) {
        results.push(hotel);
      } else if (
        searchCompleted &&
        !this.globalData.get(GlobalDataEnum.LANDING_PAGE).allowsVoucher() &&
        this.shouldAddUnavailableHotel(hotel, unavailableHotelsCount, results.length)
      ) {
        results.push(hotel);
        unavailableHotelsCount += 1;
      }
    });
    return this.filterByStarRatingAndAvailability(results);
  }

  private static filterUnavailableHotels(hotels: Hotel[]): Hotel[] {
    return hotels.filter(hotel => hotel.available);
  }

  private static preFilterByStarRating(hotels: Hotel[], ratingArray: number[] ): Hotel[] {
    hotels = hotels.filter(hotel => ratingArray.includes(hotel.rating));
    return hotels;
  }

  private static shouldAddUnavailableHotel(hotel: Hotel, unavailableCount: number, totalCount: number): boolean {
    const minStarRating: number = 3;
    const maxUnavailableRatio: number = 0.28;
    const minUnavailableCount: number = 5;
    if (hotel.rating < minStarRating) {
      return false;
    }
    if (unavailableCount < minUnavailableCount) {
      return true;
    }
    if (((unavailableCount + 1) / totalCount) < maxUnavailableRatio) {
      return true;
    }
  }

  private static fireFilteringEvent(): void {
    // angular-v2-todo: do this part in a separate task when we migrate tracker.ts service
    // TrackerService.filteringEvent(
    //   minPrice: Math.round(self.qprices.minPrice)
    //   maxPrice: Math.round(self.qprices.maxPrice)
    //   hotelName: self.query.name
    // )
  }

}
