import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import Rollbar from "rollbar";

import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { RollbarService } from "booking-app-v2/shared/services/rollbar.service";
import { User } from "booking-app-v2/shared/models";
import {
  ForgetPasswordParams,
  LoginParams,
  RegisterParams,
  ResetPassword,
  UpdatePasswordParams,
  UpdateProfileParams,
} from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class UserService {

  private window: Window;

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private apiDataService: ApiDataService,
    private appSettingsService: AppSettingsService,
    private windowRefService: WindowRefService,
  ) {
    this.window = this.windowRefService.nativeWindow;
  }

  login(params: LoginParams): Observable<User> {
    const endpoint: string = this.appSettingsService.loginUrl;

    return this.apiDataService.post(endpoint, params, false);
  }

  // Used for non-e2e integrated whitelabels where we simply
  // call devise's logout method to delete the user's session
  logout(): void {
    const endpoint: string = this.appSettingsService.logoutUrl;

    this.apiDataService.delete(endpoint, false)
      .subscribe({
        error: (error) => this.rollbar.info("Error on sign out: " + JSON.stringify(error.data)),
        complete: () => this.window.location.reload(),
      });
  }

  // Used for e2e integrated whitelabels where we send
  // the user to GuardHouse's (GH) logout URL to purge his
  // GH session
  redirectLogout(): void {
    this.window.location.href = `${this.appSettingsService.logoutUrl}?reason=GH2030`;
  }

  register(params: RegisterParams): Observable<User> {
    const endpoint: string = this.appSettingsService.registerUrl;

    return this.apiDataService.post(endpoint, params, false);
  }

  forgetPassword(params: ForgetPasswordParams): Observable<User> {
    const endpoint: string = this.appSettingsService.forgetPasswordUrl;

    return this.apiDataService.post(endpoint, params, false);
  }

  update(params: UpdateProfileParams): Observable<User> {
    const endpoint: string = "user/update";

    return this.apiDataService.post(endpoint, params);
  }

  updatePassword(params: UpdatePasswordParams): Observable<User> {
    const endPoint: string = "user/update_password";

    return this.apiDataService.post(endPoint, params);
  }

  resetPassword(params: ResetPassword): Observable<User> {
    const endPoint: string = "user/reset_password";

    return this.apiDataService.post(endPoint, params);
  }

  pollCurrentUser(): Observable<User> {
    return this.apiDataService.get("user");
  }
}
