import { Injectable } from "@angular/core";
import { of, ReplaySubject, Subject } from "rxjs";

import { BaseStep } from "./base-step";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  FetchMembershipResult,
  GlobalDataEnum,
  Membership,
} from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class FetchMembershipService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.FETCH_MEMBERSHIP;

  constructor(
    private globalData: GlobalData,
    private apiDataService: ApiDataService,
  ) {}

  process(results: CheckoutResults): FetchMembershipResult {
    if (!this.globalData.isUserLoggedIn()) {
      return of([]);
    }

    return this.fetchMemberships();
  }

  private fetchMemberships(): FetchMembershipResult {
    const pointsPartnerId = this.globalData.get(GlobalDataEnum.POINTS_PARTNER).id;
    const result: Subject<Membership[]> = new ReplaySubject<Membership[]>(1);

    this.apiDataService
      .get(`partners/${pointsPartnerId}/memberships`)
      .subscribe((memberships: Membership[]) => {
        result.next(memberships);
      });

    return result.asObservable();
  }

}
