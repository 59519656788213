import { Injectable } from "@angular/core";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { WhitelabelTranslateService } from "booking-app-v2/shared/services/whitelabel-translate.service";
import { PointsPartner } from "booking-app-v2/shared/models";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class BookingErrorsMapperService {

  private errors: { [key: string]: string };
  private pointsPartner: PointsPartner;

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private whitelabelTranslateService: WhitelabelTranslateService,
  ) {
    this.errors = {
      "Payment Error": "checkout.errors.payment_declined",
      "Payment Failed": "checkout.errors.payment_declined",
      "Account not verified": "checkout.errors.not_verified",
      "Verification attempt failed": "checkout.errors.attempt_failed",
      "Verification cannot be performed": "checkout.errors.unable_to_verify",
      "Account rejected by issuer": "checkout.errors.rejected",
      "Payment Cancelled": "checkout.errors.alipay.payment_cancelled",
      "User Registration Error": "checkout.errors.user_registration_error",
      "Booking Supplier Error": "checkout.errors.supplier_error",
      "Booking Supplier Error: Offer request error": "checkout.errors.supplier_error.offer_request_error",
      "Booking Supplier Error: Offer request expired": "checkout.errors.supplier_error.offer_request_expired",
      "Booking Supplier Error: Invalid phone number": "checkout.errors.supplier_error.invalid_phone_number",
      "Car info error": "checkout.errors.supplier_error",
      "Flight Info Error": "checkout.errors.supplier_error",
      "Booking Error; Duplicate Request": "checkout.errors.duplicate_booking",
      "voucher cannot be used": "checkout.errors.voucher_invalid",
      "voucher not found": "checkout.errors.voucher_not_found",
      "Redemption Error": "checkout.errors.redemption_error_message",
      "OTP Error": "checkout.errors.otp",
      "Exceed attempt limit": "national_id.blocked_message",
      "web_authentication_failed": "checkout.errors.web_login_failed",
      "PayAnyone Payment Failed": "checkout.errors.pay_anyone",
      "PayAnyone Payment Expired": "checkout.errors.pay_anyone.session.expired",
      "OCBC Digital Payment Failed": "checkout.errors.ocbc_digital",
      "OCBC Digital Payment Expired": "checkout.errors.ocbc_digital.session.expired",
      "Validate coupon code failed": "checkout.errors.coupon_code.expired",
      "Date of birth": "checkout.errors.date_of_birth",
      "Too few travellers": "checkout.errors.date_of_birth",
      "Duplicate bookings found": "checkout.errors.duplicate_booking",
      "Invalid passport expiry date": "checkout.errors.expired_passport",
      "Results no longer available": "checkout.errors.supplier_error",
      "Travelfusion Price Changed": "checkout.errors.travelfusion_price_changed",
      "Impersonation Unauthorized User": "checkout.errors.impersonation_unauthorized_user",
      "Impersonation Read-Only Access": "checkout.errors.impersonation_read_only_access",
      "TE3014": "checkout.errors.supplier_error",
      "TE3016": "checkout.errors.price_changed",
      "TE3017": "checkout.errors.bonus_program.expired",
      "TE3018": "checkout.errors.price_changed",
    };
    this.pointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
  }

  map(error: string): string {
    let errorMsg: string;
    const translationKey = this.errors[error] || "checkout.errors.default";
    errorMsg = this.translateWithEmail(translationKey);
    if (error === "Payment Error" && this.pointsPartner.name === "CommBank") {
      errorMsg += " Please note that this is an international online payment. If you have placed an international online payment lock on your CommBank credit card, be sure to unlock via the CommBank app or NetBank before re-attempting your booking.";
    }
    return errorMsg;
  }

  private translateWithEmail(translationKey: string): string {
    return this.whitelabelTranslateService.translate(translationKey, {
      email: this.appSettingsService.serviceEmail,
    });
  }
}
