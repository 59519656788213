import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { HotelListItemComponent } from "./hotel-list-item.component";
import { HotelListItemCompRatesModule } from "booking-app-v2/shared/components/comp-rates/comp-rates.module";
import { HotelListItemDistanceModule } from "booking-app-v2/shared/components/hotel-list-item-distance/hotel-list-item-distance.module";
import { HotelListItemImageModule } from "booking-app-v2/shared/components/hotel-list-item-image/hotel-list-item-image.module";
import { HotelListItemMapButtonModule } from "booking-app-v2/shared/components/hotel-list-item-map-button/hotel-list-item-map-button.module";
import { PartnerTypeIconModule } from "booking-app-v2/shared/components/partner-type-icon/partner-type-icon.module";
import { RedemptionDiscountModule } from "booking-app-v2/shared/components/redemption-discount/redemption-discount.module";
import { TrustYouModule } from "booking-app-v2/shared/components/trust-you/trust-you.module";
import { StarRatingModule } from "booking-app-v2/shared/components/star-rating/star-rating.module";
import { ComplimentaryPriceBoxModule } from "../complimentary-price-box/complimentary-price-box.module";

@NgModule({
  declarations: [ HotelListItemComponent ],
  imports: [
    SharedModule,
    HotelListItemCompRatesModule,
    HotelListItemDistanceModule,
    HotelListItemImageModule,
    HotelListItemMapButtonModule,
    PartnerTypeIconModule,
    RedemptionDiscountModule,
    TrustYouModule,
    StarRatingModule,
    ComplimentaryPriceBoxModule,
  ],
  exports: [ HotelListItemComponent ],
  providers: [],
})
export class HotelListItemModule {}
