import { Injectable } from "@angular/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PaymentFormUtilService } from "booking-app-v2/shared/services/payment-form-util.service";
import {
  CARD,
  GlobalDataEnum,
  PAYMENT,
  PAYMENT_METHOD,
  Payment,
  PaymentMethod,
  SavedCard,
} from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class PaymentMethodService {

  /**
   * TODO: Need to move other payment method related
   * variables (introdued in ocbc) to this service,
   * So that we can decouple those from ctrls (hotel, cars and flight checkout)
   */
  showPayAnyone: boolean;
  activePaymentTab: PaymentMethod;
  selectedSavedCard: SavedCard;
  checkedSaveCreditCard: boolean;
  adyenSavedCards: SavedCard[];
  // make SavedCards private so it can only be updated using setter which will populate the extra properties
  private SavedCards: SavedCard[];

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private paymentFormUtilService: PaymentFormUtilService,
  ) {
    this.initPaymentMethodData();
  }

  get savedCards(): SavedCard[] {
    return this.SavedCards;
  }

  set savedCards(savedCards: SavedCard[]) {
    this.populateExtraProperties(savedCards);
    this.SavedCards = savedCards;
  }

  allowSaveCreditCard(): boolean {
    return this.appSettingsService.storeCreditCard &&
      this.globalData.isUserLoggedIn() &&
      this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).preferredGateway !== "checkout_com";
  }

  hasSavedCards(): boolean {
    return this.savedCards.length > 0;
  }

  setActiveTab(paymentMethod: PaymentMethod): void {
    this.activePaymentTab = paymentMethod;

    // If we are not on the saved cards tab, we don't want to
    // use a saved card to make a booking
    if (paymentMethod !== PAYMENT_METHOD.SAVED_CARDS) {
      this.selectedSavedCard = null;
    }
  }

  updateStripeCardPaymentChannel(stripeArray: SavedCard[]): SavedCard[] {
    return stripeArray.map((card) => {
      card.payment_channel = "stripe";
      return card;
    });
  }

  filterSavedCards(): void {
    const stripeCards = this.savedCards.filter((card) => card.payment_channel === "stripe");
    let filteredCards = stripeCards;

    if (this.paymentFormUtilService.acceptJcb()) {
      const jcbCards = this.adyenSavedCards.filter((card) => card.card_type === CARD.JCB);
      filteredCards = filteredCards.concat(jcbCards);
    }
    if (this.paymentFormUtilService.acceptUnionpay()) {
      const cupCards = this.adyenSavedCards.filter((card) => card.card_type === CARD.UNIONPAY);
      filteredCards = filteredCards.concat(cupCards);
    }
    if (this.paymentFormUtilService.acceptDinersViaAdyen) {
      const dinersCards = this.adyenSavedCards.filter((card) => card.card_type === CARD.DINERS);
      filteredCards = filteredCards.concat(dinersCards);
    }

    this.savedCards = filteredCards;
  }

  isUsingSavedAdyenCard() {
    return this.activePaymentTab === PAYMENT_METHOD.SAVED_CARDS &&
      this.selectedSavedCard?.payment_channel === PAYMENT.ADYEN;
  }

  getPaymentChannelFromAdyenSavedCard(): Payment {
    switch (this.selectedSavedCard.card_type) {
      case "alipay":
        return PAYMENT.ADYEN_ALIPAY;
      case "diners":
        return PAYMENT.ADYEN_DINERS;
      case "jcb":
        return PAYMENT.ADYEN_JCB;
      case "cup":
        return PAYMENT.ADYEN_UNIONPAY;
      default:
        return PAYMENT.ADYEN;
    }
  }

  private initPaymentMethodData(): void {
    this.showPayAnyone = false;
    this.activePaymentTab = this.appSettingsService.hasMultiplePaymentMethods
      ? this.appSettingsService.defaultPaymentMethod
      : undefined;
    this.checkedSaveCreditCard = false;
    this.savedCards = [];
  }

  /**
   * Adding 2 extra properties "removeInProgress" , "id".
   * id: To have a unique ID for each card
   * removeInProgress: Remove card confirmation in front end temporarily
   */
  private populateExtraProperties(savedCards: SavedCard[]) {
    savedCards.forEach((card, index) => {
      card.removeInProgress = false;
      card.id = `${card.card_type}_${index}`;
      card.card_type = card.card_type === "American Express" ? "amex" : card.card_type;
    });
  }
}
