import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { SimpleDialogContent } from "booking-app-v2/shared/types";

@Component({
  selector: "simple-dialog",
  template: `
    <div class="dialog-header" mat-dialog-title>
      <div class="error-icon" *ngIf="dialogIconCDNPath">
        <img [cdn-path]="dialogIconCDNPath">
      </div>
      <div class="dialog-title" id="dialog-basic-title" *ngIf="dialogHeader" [translate]="dialogHeader"></div>
    </div>
    <mat-dialog-content class="dialog-body"
         [innerHTML]="dialogBody | translate"
         *ngIf="dialogBody">
    </mat-dialog-content>
    <mat-dialog-actions class="dialog-footer">
      <button mat-raised-button class="mat-btn mat-btn-action btn btn-action"
              color="primary"
              [mat-dialog-close]="true"
              *ngIf="resolveButtonText">{{resolveButtonText | translate}}</button>
      <button mat-raised-button class="mat-btn mat-btn-cancel btn btn-cancel"
              color="accent"
              [mat-dialog-close]="false"
              *ngIf="rejectButtonText">{{rejectButtonText | translate}}</button>
    </mat-dialog-actions>
  `,
})
export class SimpleDialogComponent implements OnInit {
  dialogHeader?: string;
  dialogBody?: string;
  dialogIconCDNPath?: string;
  rejectButtonText?: string;
  resolveButtonText?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogContent: SimpleDialogContent,
  ) { }

  ngOnInit() {
    this.dialogHeader = this.dialogContent.dialogHeader;
    this.dialogBody = this.dialogContent.dialogBody;
    this.dialogIconCDNPath = this.dialogContent.dialogIconCDNPath;
    this.rejectButtonText = this.dialogContent.rejectButtonText;
    this.resolveButtonText = this.dialogContent.resolveButtonText;
  }
}
