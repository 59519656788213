import { Pipe, PipeTransform } from "@angular/core";

import { GlobalData } from "../services/global-data.service";
import { FormatNumberUtil } from "../utils/format-number.util";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

@Pipe({
  name: "localeNumberFmt",
  pure: false,
})
export class LocaleNumberFormat implements PipeTransform {

  constructor(
    private globalData: GlobalData,
  ) { }

  transform(value: number, decimalPlace: number = 0): string {
    return FormatNumberUtil.roundNumber(
      value,
      this.globalData.get(GlobalDataEnum.SELECTED_LOCALE),
      decimalPlace,
    );
  }
}
