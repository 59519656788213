import { Injectable } from "@angular/core";
import { catchError, Observable, of, ReplaySubject, Subject, take } from "rxjs";
import { Router } from "@angular/router";

import { ForceSyncBaseStep } from "./force-sync-base-step";
import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { AppSettingsService } from "../../app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { PaymentService } from "booking-app-v2/shared/services/payment.service";
import { AdyenFormService } from "booking-app-v2/shared/services/adyen-form.service";
import { StripePaymentIntentService } from "booking-app-v2/shared/services/stripe-payment-intent.service";
import {
  ApiDataError,
  BOOKING_TRANSACTION_STATUS,
  BookingTransactionStatus,
  BookingTransactionStatusResponse,
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  FetchBookingStatusResult,
  GlobalDataEnum,
  LOADING_MESSAGE,
  LoadingMessage,
  PAYMENT,
  TRAVEL_TYPE,
  TravelType,
} from "booking-app-v2/shared/types";
import { CheckoutError } from "booking-app-v2/shared/models";

@Injectable({
  providedIn: "root",
})
export class FetchBookingStatusService implements ForceSyncBaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.FETCH_BOOKING_STATUS;

  private checkoutFetchBookingResult: Subject<void>;
  private results: CheckoutResults;
  private transactionId: string;
  private readonly travelType: TravelType = this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);

  constructor(
    private router: Router,
    private apiDataService: ApiDataService,
    private globalData: GlobalData,
    private windowRefService: WindowRefService,
    private appSettingsService: AppSettingsService,
    private paymentService: PaymentService,
    private adyenFormService: AdyenFormService,
    private stripePaymentIntentService: StripePaymentIntentService,
  ) { }

  processAsync(results: CheckoutResults): Observable<FetchBookingStatusResult> {
    this.results = results;
    this.transactionId = this.getTransactionId();
    this.checkoutFetchBookingResult = new ReplaySubject<void>(1);
    if (this.transactionId) {
      this.pollTransactionStatus();
      return this.checkoutFetchBookingResult.asObservable();
    }

    return of(undefined);
  }

  private pollTransactionStatus(): void {
    setTimeout(() => {
      this.apiDataService
        .get(`transactions/${this.transactionId}/status`)
        .pipe(
          // catch error and resolve so that it will be handled in the handleUnknownStatus method down the stream
          catchError((error: ApiDataError) => of(error)),
        )
        .subscribe((transactionResponse: BookingTransactionStatusResponse) => {
          this.handleTransactionResponse(transactionResponse);
        });
    }, 2000);
  }

  private updateTransactionStatus(status: BookingTransactionStatus, response?: any): void {
    this.apiDataService
      .post(`transactions/${this.transactionId}/status`, { status, response})
      .pipe(
        // catch error and resolve so that it will be handled in the handleUnknownStatus method down the stream
        catchError((error: ApiDataError) => of(error)),
      )
      .subscribe((params) => {
        this.handleTransactionResponse({
          status,
          payment_channel: PAYMENT.STRIPE_PAYMENT_INTENTS,
          params,
        });
      });
  }

  private handleTransactionResponse(transactionResponse: BookingTransactionStatusResponse) {
    switch (transactionResponse.status) {
      case BOOKING_TRANSACTION_STATUS.SUCCESS:
        this.handleSuccess(transactionResponse);
        break;
      case BOOKING_TRANSACTION_STATUS.PAYMENT_STARTED:
        this.updateCheckoutLoadingMessage(LOADING_MESSAGE.PAYMENT_IN_PROGRESS);
        this.handlePaymentStarted(transactionResponse);
        break;
      case BOOKING_TRANSACTION_STATUS.REQUIRES_USER_AUTHENTICATION:
        this.updateCheckoutLoadingMessage(LOADING_MESSAGE.AUTHENTICATION_IN_PROGRESS);
        this.redirectUserToGateway(transactionResponse);
        break;
      case BOOKING_TRANSACTION_STATUS.PAYMENT_PENDING:
        this.updateCheckoutLoadingMessage(LOADING_MESSAGE.PAYMENT_IS_PENDING);
        this.pollTransactionStatus();
        break;
      case BOOKING_TRANSACTION_STATUS.PAYMENT_CONFIRMED:
        this.updateCheckoutLoadingMessage(LOADING_MESSAGE.PAYMENT_CONFIRMED_BOOKING_NOW);
        this.pollTransactionStatus();
        break;
      case BOOKING_TRANSACTION_STATUS.IN_PROGRESS:
        this.pollTransactionStatus();
        break;
      default:
        this.handleUnknownStatus(transactionResponse);
    }
  }

  private handleSuccess(transactionResponse: BookingTransactionStatusResponse): void {
    // angular-v2-todo: complete below line once payanyone service is done
    // this.payAnyoneService.resetPayAnyone();
    switch (this.travelType) {
      case TRAVEL_TYPE.HOTELS:
        this.goToHotelBookingSuccessPage(transactionResponse);
        break;
      case TRAVEL_TYPE.CARS:
        this.goToCarBookingSuccessPage(transactionResponse);
        break;
      case TRAVEL_TYPE.FLIGHTS:
        this.goToFlightBookingSuccessPage(transactionResponse);
        break;
    }
  }

  private goToHotelBookingSuccessPage(transactionResponse: BookingTransactionStatusResponse): void {
    const successPageUrl: string = this.appSettingsService.checkoutRedirectToBookingsPath
      ? `/bookings/${transactionResponse.booking_reference}?access_token=${transactionResponse.access_token}`
      : `/purchases/${transactionResponse.booking_id}?access_token=${transactionResponse.access_token}`;
    this.navigateToSuccessPage(successPageUrl);
  }

  private goToCarBookingSuccessPage(transactionResponse: BookingTransactionStatusResponse): void {
    const successPageUrl: string = this.appSettingsService.carsCheckoutRedirectToBookingsPath
      ? `/cars/bookings/${transactionResponse.booking_reference}?access_token=${transactionResponse.access_token}`
      : `/purchases/${transactionResponse.booking_id}?access_token=${transactionResponse.access_token}`;
    this.navigateToSuccessPage(successPageUrl);
  }

  private goToFlightBookingSuccessPage(transactionResponse: BookingTransactionStatusResponse): void {
    const successPageUrl: string =
      `/flights/bookings/${transactionResponse.booking_reference}?access_token=${transactionResponse.access_token}`;
    this.navigateToSuccessPage(successPageUrl);
  }

  private navigateToSuccessPage(successPageUrl: string): void {
    if (this.appSettingsService.reloadAfterCheckout) {
      this.windowRefService.nativeWindow.location.href = successPageUrl;
    } else {
      this.router.navigateByUrl(successPageUrl);
    }
  }

  private handleUnknownStatus(transactionResponse: BookingTransactionStatusResponse): void {
    // angular-v2-todo: uncomment below part when we do PAO
    // this.payAnyoneService.resetPayAnyone();
    // angular-v2-todo: complete this part for cars
    // if (this.globalStateService.isTravelType(TRAVEL_TYPE.CARS)) {
    //   this.carsState.transactionId = "";
    // }
    this.failAndUpdateErrorMessage(transactionResponse.error);
  }

  private handlePaymentStarted(transactionResponse: BookingTransactionStatusResponse): void {
    switch (transactionResponse.payment_channel) {
      case PAYMENT.ADYEN_ALIPAY:
        this.redirectUserToAlipay(transactionResponse);
        break;
      case PAYMENT.ADYEN_UNIONPAY:
      case PAYMENT.ADYEN_DINERS:
      case PAYMENT.ADYEN_JCB:
        this.handleAdyenCardStart(transactionResponse);
        break;
      case PAYMENT.CHECKOUT_COM:
      case PAYMENT.STRIPE:
        this.handleStripeStart(transactionResponse);
        break;
      case PAYMENT.PAY_ANYONE:
        // angular-v2-todo handle this when starting OCBC
        // this.handlePayAnyoneStart(res);
        break;
      case PAYMENT.STRIPE_PAYMENT_INTENTS:
        this.handlePaymentIntentStart(transactionResponse);
        break;
      default:
        this.redirectUserToGateway(transactionResponse);
        break;
    }
  }

  private handleStripeStart(transactionResponse: BookingTransactionStatusResponse): void {
    this.paymentService.start(transactionResponse.gateway_url).subscribe({
      next: () => this.pollTransactionStatus(),
      error: err => this.failAndUpdateErrorMessage(err.error),
    });
  }

  private handlePaymentIntentStart(transactionResponse: BookingTransactionStatusResponse): void {
    if (transactionResponse.params.payment_intent) {
      this.stripePaymentIntentService.confirmIntentProcess(
        transactionResponse.params.payment_intent.secret,
        (_response) => {
          // Update BT status payment_confirmed, rerun checkout steps
          // We won't rely on the webhook because:
          // 1. at this point, we are already 100% sure the payment is confirmed, no need to wait for a notification
          // 2. in development, a webhook endpoint is not always setup
          this.updateTransactionStatus(BOOKING_TRANSACTION_STATUS.PAYMENT_CONFIRMED);
          this.pollTransactionStatus();
        },
        (error) => {
          // Update BT status failed
          this.updateTransactionStatus(BOOKING_TRANSACTION_STATUS.FAIL, {
            error: "Payment Failed",
            error_detail: error,
          });
          this.pollTransactionStatus();
        },
      ).pipe(take(1)).subscribe();
    } else {
      this.pollTransactionStatus();
    }
  }

  private handleAdyenCardStart(transactionResponse: BookingTransactionStatusResponse): void {
    if (transactionResponse.params.action) {
      this.adyenFormService.handle3dsAction(transactionResponse.params.action);
    } else {
      this.pollTransactionStatus();
    }
  }

  private redirectUserToAlipay(transactionResponse: BookingTransactionStatusResponse): void {
    this.windowRefService.nativeWindow.location.href = transactionResponse.params.url;
  }

  private redirectUserToGateway(transactionResponse: BookingTransactionStatusResponse): void {
    this.windowRefService.nativeWindow.location.href = transactionResponse.gateway_url;
  }

  private getTransactionId(): string {
    const transactionIdFromCreateBooking: string =
      (this.results[CHECKOUT_RESULT_NAME.CREATE_BOOKING])?.transaction_id;
    const transactionIdFromUrl: string = this.windowRefService.getQueryParamFromWindow("transactionId");
    return transactionIdFromCreateBooking ?? transactionIdFromUrl;
  }

  private updateCheckoutLoadingMessage(loadingMessage: LoadingMessage): void {
    this.results.checkoutLoadingMessage$.next(loadingMessage);
  }

  private failAndUpdateErrorMessage(errorMessage: string): void {
    this.results.checkoutErrorKey$.next(errorMessage);
    this.checkoutFetchBookingResult.error(new CheckoutError(errorMessage));
  }
}
