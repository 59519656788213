import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { TrustYouComponent } from "./trust-you.component";
import { TrustYouDialogModule } from "booking-app-v2/shared/components/dialog/trust-you-dialog/trust-you-dialog.module";
import { BottomSheetWrapperModule } from "booking-app-v2/shared/components/bottom-sheet-wrapper/bottom-sheet-wrapper.module";

@NgModule({
  declarations: [ TrustYouComponent ],
  imports: [
    SharedModule,
    TrustYouDialogModule,
    BottomSheetWrapperModule,
  ],
  exports: [ TrustYouComponent ],
  providers: [],
})
export class TrustYouModule {}
