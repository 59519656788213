import { Injectable } from "@angular/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { PointsPartner, User } from "booking-app-v2/shared/models";
import { COMPLEX_DIALOG, GlobalDataEnum } from "booking-app-v2/shared/types";
import {
  InsufficientPointsCheckDialogComponent,
} from "booking-app-v2/shared/components/dialog/insufficient-points-check-dialog/insufficient-points-check-dialog.component";

@Injectable({
  providedIn: "root",
})
export class PointsAdjustmentService {

  pointsNeeded: number;
  minPoint: number;

  private readonly currentUser: User;
  private readonly pointsPartner: PointsPartner;

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private dialogService: DialogService,
    private pointsCashShareService: PointsCashShareService,
  ) {
    this.currentUser = this.globalData.get(GlobalDataEnum.CURRENT_USER);
    this.pointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
  }

  showInsufficientPointBalance(pointsNeeded: number, minPoint: number = 0): boolean {
    this.pointsNeeded = pointsNeeded;
    this.minPoint = minPoint;
    const insufficientPointBalance: boolean = this.shouldShowPointsBalanceDialog();

    if (insufficientPointBalance) {
      this.dialogService.open(
        COMPLEX_DIALOG.INSUFFICIENT_POINTS_CHECK,
        InsufficientPointsCheckDialogComponent,
        { minPoint: this.minPoint },
      );
    }

    return insufficientPointBalance;
  }

  adjustMiles(): void {
    const maxPaymentTier =
      this.pointsPartner.pointsPaymentTiersByTravelType.length - 1;

    while (this.currentPointsBalance() < this.pointsNeeded) {
      if (this.pointsCashShareService.pointsCashShare.value === maxPaymentTier) {
        break;
      } else {
        this.pointsCashShareService.onChange(
          this.pointsCashShareService.pointsCashShare.value + 1,
        );
      }
    }
  }

  private hasNotEnoughPoints(): boolean {
    // Checks for the current points
    return this.currentUser?.currentPointsBalance < this.pointsNeeded;
  }

  private shouldShowPointsBalanceDialog(): boolean {
    return this.appSettingsService.checkoutSettings.insufficientPointsModalCheck &&
      this.hasNotEnoughPoints();
  }

  private currentPointsBalance(): number {
    if (this.appSettingsService.multiplePointsPartner) {
      return this.currentUser.selectedPointsBalance || 0;
    } else {
      return this.currentUser.redemption_points_balance || 0;
    }
  }
}
