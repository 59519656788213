import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { BottomSheetWrapperModule } from "booking-app-v2/shared/components/bottom-sheet-wrapper/bottom-sheet-wrapper.module";
import { RoomDetailsDialogModule } from "booking-app-v2/shared/components/dialog/room-details-dialog/room-details-dialog.module";
import { RoomDetailsDialogContentModule } from "booking-app-v2/shared/components/dialog/room-details-dialog-content/room-details-dialog-content.module";
import { RoomDetailsDialogButtonComponent } from "./room-details-dialog-button.component";

@NgModule({
  declarations: [ RoomDetailsDialogButtonComponent ],
  imports: [
    SharedModule,
    RoomDetailsDialogContentModule,
    RoomDetailsDialogModule,
    BottomSheetWrapperModule,
  ],
  exports: [ RoomDetailsDialogButtonComponent ],
  providers: [],
})
export class RoomDetailsDialogButtonModule { }
