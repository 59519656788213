import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { Booking, PointsPartners } from "booking-app-v2/shared/models";
import { TimeUtils } from "booking-app-v2/shared/utils";
import { FlightCabin, FlightCabinHelper } from "../types";
import { BookingPassenger, FareRules, FlightSegment } from "../types";

export class FlightBooking extends Booking {
  readonly flight_booking_passengers: BookingPassenger[];
  readonly flight_type: string;
  readonly from_airport: string;
  readonly from_airport_name: string;
  readonly to_airport: string;
  readonly to_airport_name: string;
  readonly origin_flights: FlightSegment[];
  readonly return_flights: FlightSegment[] | null;
  readonly departure_date: Date;
  readonly return_date: Date;
  readonly num_adults: number;
  readonly num_children: number;
  readonly num_infants: number;
  readonly confirmation_number: string;
  readonly cabin: FlightCabin;
  readonly fare_rules: FareRules;
  readonly long_departure_date: string;
  readonly long_return_date: string;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    booking: FlightBooking,
    pointsPartners: PointsPartners,
  ) {
    super(
      appSettingsService,
      globalData,
      booking,
      pointsPartners,
    );

    this.flight_booking_passengers = booking.flight_booking_passengers;
    this.flight_type = booking.flight_type;
    this.from_airport = booking.from_airport;
    this.from_airport_name = booking.from_airport_name;
    this.to_airport = booking.to_airport;
    this.to_airport_name = booking.to_airport_name;
    this.origin_flights = booking.origin_flights;
    this.return_flights = booking.return_flights;
    this.departure_date = booking.departure_date;
    this.return_date = booking.return_date;
    this.num_adults = booking.num_adults;
    this.num_children = booking.num_children;
    this.num_infants = booking.num_infants;
    this.confirmation_number = booking.confirmation_number;
    this.cabin = booking.cabin;
    this.fare_rules = booking.fare_rules;
    this.long_departure_date = booking.long_departure_date;
    this.long_return_date = booking.long_return_date;
  }

  isReturnTrip(): boolean {
    return this.flight_type === "return";
  }

  displayArrivalDate(segments: FlightSegment[]): boolean {
    if (segments && segments.length !== 0) {
      const arrival = segments[segments.length - 1].arrival_time;
      const departure = segments[0].departure_time;
      return this.getOffsetDay(arrival, departure) > 0;
    }
  }

  delayedOrigin(): boolean {
    return this.displayArrivalDate(this.origin_flights);
  }

  delayedReturn(): boolean {
    return this.displayArrivalDate(this.return_flights);
  }

  departureDate(): string {
    return TimeUtils.format(this.origin_flights[0].departure_time, "D MMM YYYY | hh:mmA");
  }

  returnDate(): string {
    return this.isReturnTrip() ?
      TimeUtils.format(this.return_flights[0].departure_time, "D MMM YYYY | hh:mmA") :
      "";
  }

  originArrivalDate(): string {
    const flight = this.origin_flights.slice(-1)[0];
    return this.buildArrivalDate(this.origin_flights[0].departure_time, flight);
  }

  departureArrivalDate(): string {
    if (!this.isOneWay()) {
      const flight = this.returnFlights().slice(-1)[0];
      return this.buildArrivalDate(this.returnFlights()[0].departure_time, flight);
    }
  }

  flightCabinText(): string {
    return FlightCabinHelper.toStringTranslation(this.cabin);
  }

  departureFlightText(): string {
    return `${this.from_airport_name} (${this.from_airport})`;
  }

  returnFlightText(): string {
    return `${this.to_airport_name} (${this.to_airport})`;
  }

  returnFlights(): FlightSegment[] {
    return this.return_flights;
  }

  isOneWay(): boolean {
    return this.returnFlights().length === 0;
  }

  private getOffsetDay(arrival, departure): number {
    return TimeUtils.getDayOffset(arrival, departure);
  }

  private buildArrivalDate(departure, flight): string {
    const arrivalDate = TimeUtils.format(flight.arrival_time, "MM-DD");
    const departureDate = TimeUtils.format(departure, "MM-DD");

    if (arrivalDate > departureDate) {
      return TimeUtils.format(flight.arrival_time, "D MMM");
    }
  }
}
