import { FlightCabin, FlightRawResponse, FlightType } from "booking-app-v2/flights/types";

export interface FlightPricesRawResponse {
  completed: boolean;
  itineraries: FlightRawResponse[];
  search: {
    cabin: FlightCabin,
    currency: string,
    departure_date: string,
    flight_type: FlightType,
    from_airport: string,
    from_airport_name: string,
    passengers: string, // <adultCount>,<childCount>,<infantCount>
    return_date: string,
    to_airport: string,
    to_airport_name: string,
  };
}

export const defaultFlightPricesRawResponse: FlightPricesRawResponse = {
  completed: true,
  itineraries: [],
  search: null,
};
