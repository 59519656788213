import { ErrorHandler, Inject, Injectable } from "@angular/core";
import Rollbar from "rollbar";

import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { RollbarService } from "booking-app-v2/shared/services/rollbar.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private windowRefService: WindowRefService,
  ) {
    super();
  }

  handleError(error: any): void {
    // report as rollbar critical and reload the page if load chunk failed
    if (/Loading chunk .+ failed/.test(error.message)) {
      this.rollbar.critical(error.message);
      this.windowRefService.nativeWindow.location.reload();
      return;
    }

    // report the other exceptions as rollbar errors
    this.rollbar.error(error.originalError || error);

    // pass the error back to browser
    super.handleError(error);
  }
}
