import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { LocaleService } from "booking-app-v2/shared/services/initializers/locale.service";
import { TranslationUtilsService } from "booking-app-v2/shared/services/translation-utils.service";

@UntilDestroy()
@Directive({
  selector: "[humanizeTranslation]",
})
export class HumanizeTranslationDirective implements OnInit {
  @Input("humanizeTranslation") rawCharge: string;

  constructor(
    private el: ElementRef,
    private localeService: LocaleService,
    private translationUtilsService: TranslationUtilsService,
  ) {}

  ngOnInit() {
    this.humanizeAndSetInnerText();
    this.localeService.onLocaleChange
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.humanizeAndSetInnerText();
      });
  }

  private humanizeAndSetInnerText(): void {
    this.el.nativeElement.innerText = this.translationUtilsService.humanizeTranslation(this.rawCharge);
  }
}
