import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function autoCompleteValidator<T>(validMap: { [key: string]: T }, bindingKey: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlIsString: boolean = typeof control.value === "string";
    const controlTextValue: string = controlIsString
      ? control.value
      : control.value[bindingKey];
    const mappedItem: T = validMap[controlTextValue];

    if (mappedItem == null) {
      return { notFound: { value: control.value } };
    }

    if (controlIsString) {
      control.setValue(mappedItem);
    }
    return null;
  };
}
