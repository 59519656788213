import { Params } from "@angular/router";

import { CarCommonQueryParams } from "booking-app-v2/cars/types";
import { FlightCommonQueryParams } from "booking-app-v2/flights/types";
import { HotelCommonQueryParams } from "booking-app-v2/hotels/types";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { CommonQueryParams, GlobalDataEnum, PRODUCT_TYPE } from "booking-app-v2/shared/types";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";

type TravelQueryParams = HotelCommonQueryParams | FlightCommonQueryParams | CarCommonQueryParams;

export abstract class BaseUrlBuilder {
  constructor(
    protected globalData: GlobalData,
    protected appSettingsService: AppSettingsService,
    protected pointsCashShareService: PointsCashShareService,
  ) { }

  abstract buildCommonSearchUrlParams(): TravelQueryParams;
  abstract buildSearchUrl(): TravelQueryParams;
  abstract buildResultsPageDataFromQueryParams(params: Params): TravelQueryParams;

  commonQueryParams(): CommonQueryParams {
    let urlParams: CommonQueryParams = {
      currency: this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).code,
      landingPage: this.globalData.get(GlobalDataEnum.LANDING_PAGE).url,
      partnerId: this.globalData.get(GlobalDataEnum.POINTS_PARTNER).id.toString(),
      pointsCashShare: this.commonPointCashParams(),
      country: this.globalData.get(GlobalDataEnum.SELECTED_COUNTRY_SITE).code,
    };

    if (this.appSettingsService.useProductType) {
      urlParams.productType = this.globalData.get(GlobalDataEnum.PRODUCT_TYPE);
    }

    return urlParams;
  }

  commonPointCashParams(): string {
    const isRedeem: boolean = this.globalData.get(GlobalDataEnum.LANDING_PAGE).hasProductType(PRODUCT_TYPE.REDEEM);
    return isRedeem
      ? String(this.pointsCashShareService.pointsCashShare.value)
      : undefined;
  }
}
