import {
  Directive,
  EventEmitter,
  HostListener,
  Output,
} from "@angular/core";

@Directive({
  selector: "[clickOutside]",
})
export class ClickOutsideDirective {
  @Output()
  public clickOutside = new EventEmitter<MouseEvent>();

  private clickedInside = false;

  @HostListener("click")
  detectInsideClick(): void {
    this.clickedInside = true;
  }

  @HostListener("document:click", ["$event"])
  detectOutsideClick(event: MouseEvent): void {
    if (!this.clickedInside) {
      this.clickOutside.emit(event);
    }
    this.clickedInside = false;
  }
}
