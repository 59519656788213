import { GlobalDataEnum, PRODUCT_TYPE, Voucher } from "booking-app-v2/shared/types";
import { Room } from "booking-app-v2/hotels/models";
import { Car } from "booking-app-v2/cars/models";
import { Currency, Locale } from "booking-app-v2/shared/models";
import { NumberFormat } from "booking-app-v2/shared//pipes";

type TravelItem = Room | Car;

export class VoucherDecorator {

  static decorate(travelItem: TravelItem, appliedVouchers: Voucher[]): void {
    travelItem.priceInfo.leftToPay = this.getLeftToPay(travelItem, appliedVouchers);
    travelItem.priceInfo.formattedTotalVoucher = this.formattedTotalVoucher(travelItem);
    travelItem.priceInfo.grandTotal = this.formattedGrandTotal(travelItem);
    travelItem.priceInfo.formattedSubtotal = this.formattedSubtotal(travelItem);
  }

  static getLeftToPay(travelItem: TravelItem, appliedVouchers: Voucher[]): number {
    if (travelItem?.priceInfo) {
      return this.leftToPay(travelItem, appliedVouchers);
    }
  }

  static formatCurrencyValue(value: number, travelItem: TravelItem): string {
    const convertedValue = travelItem.currencyService.convertFromUsd(value);
    return travelItem.currencyService.formatCurrencyValue(convertedValue);
  }

  private static leftToPay(travelItem: TravelItem, appliedVouchers: Voucher[]): number {
    if (
      travelItem.priceInfo.totalPrice &&
      !travelItem.globalData.get(GlobalDataEnum.LANDING_PAGE).hasProductType(PRODUCT_TYPE.VOUCHER)
    ) {
      return travelItem.priceInfo.totalPrice - this.sumOfVouchers(appliedVouchers);
    } else {
      return 0;
    }
  }

  private static formattedTotalVoucher(travelItem: TravelItem): string {
    if (!travelItem.priceInfo.leftToPay) {
      return "0.00";
    }

    const totalVoucher = travelItem.priceInfo.totalPrice - travelItem.priceInfo.leftToPay;
    const formattedValue = this.formatCurrencyValue(totalVoucher, travelItem);

    if (totalVoucher > 0) {
      return `-${formattedValue}`;
    }

    return formattedValue;
  }

  private static formattedGrandTotal(travelItem: TravelItem): string {
    return this.formatCurrencyValue(travelItem.priceInfo.leftToPay, travelItem);
  }

  private static sumOfVouchers(appliedVouchers: Voucher[]): number {
    return appliedVouchers.reduce((totalAmount: number, voucher: Voucher) => totalAmount + voucher.value_in_usd, 0);
  }

  private static formattedSubtotal(travelItem: TravelItem): string {
    return this.formatCurrencyValue(travelItem.priceInfo.totalPrice, travelItem);
  }
}
