import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "checkout-family-miles",
  templateUrl: "/html/components/checkout_family_miles_v2",
})
export class CheckoutFamilyMilesComponent implements OnInit {
  @Input() personalMiles: number = 0;
  @Input() totalCharge: number = 0;
  @Input() useFamilyMiles: boolean;

  @Output() familyMilesSelectChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  familyMilesSelected: boolean;

  constructor() {}

  ngOnInit(): void {
    this.familyMilesSelected = (this.useFamilyMiles === undefined) ? true : this.useFamilyMiles;
  }

  onFamilyMilesSelectChange(useFamilyMiles: boolean): void {
    this.useFamilyMiles = useFamilyMiles;
    this.familyMilesSelectChanged.emit(this.useFamilyMiles);
  }
}
