import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { take } from "rxjs/operators";
import { CityCountryBuilderService } from "booking-app-v2/shared/services/city-country-builder.service";
import { AbstractControl, FormGroup } from "@angular/forms";
import { RegionSearchDropdownService } from "booking-app-v2/shared/services/region-search-dropdown.service";
import {
  CitySelection,
  CountrySelection,
} from "booking-app-v2/shared/types/country-selection.type";
import { HotelSearchForm } from "booking-app-v2/hotels/models";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";

@Component({
  selector: "region-search-dropdown",
  template: `
    <div class="hotel-destination-search-container" [formGroup]="parentForm">
      <ng-select
        [(ngModel)]="internalValue"
        [clearable]="false"
        [searchable]="true"
        [disabled]="locations.length === 0"
        (ngModelChange)="changeSelection($event)"
        [placeholder]="label"
        [ngModelOptions]="{ standalone: true }"
        [notFoundText]="notFoundMessage"
      >
        <ng-option *ngFor="let location of locations" [value]="location">
          <span>
            {{ location.name }}
          </span>
        </ng-option>
      </ng-select>
      <div class="dirtyMsg tooltips" *ngIf="isDestinationFieldInvalid()">
        <span role="alert" translate="wl.please_fill_in_a_destination"></span>
      </div>
    </div>
  `,
})
export class RegionSearchDropdownComponent implements OnInit, OnChanges {
  @Input() parentForm: FormGroup;
  @Input() label: string;
  @Input() isCity: boolean;
  @Input() notFoundMessage: string;

  locations: CountrySelection[] | CitySelection[] = [];
  internalValue: CountrySelection | CitySelection;
  destinationFormControl: AbstractControl;
  hotelSearchForm: HotelSearchForm;

  constructor(
    private globalData: GlobalData,
    private cityCountryBuilderSerivce: CityCountryBuilderService,
    private regionSearchDropdownService: RegionSearchDropdownService,
  ) {}

  ngOnInit(): void {
    this.hotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);

    // Using pipe + take fn to initial internal value based on search form
    // Why take(2) instead of take(1)?
    // When take(1), country returns undefined as regionSearchDropdownService is just initialised
    // Only when it's we set the country based on search form, then it will reflect the selected country
    // Use Cases: when user searched once and back to the landing page, the search form should
    // show the selected country and city correctly.

    this.regionSearchDropdownService.selectedCountry$
      .pipe(take(2))
      .subscribe((country: CountrySelection) => {
        if (this.isCity && this.hotelSearchForm?.destination) {
          this.locations = country?.cities ?? [];
          this.internalValue = country.cities.find(
            (city) =>
              city.id === this.hotelSearchForm.destination.value.destination_id,
          );
        }
        this.regionSearchDropdownService.selectedCountry$.subscribe(
          (country: CountrySelection) => {
            if (this.isCity) {
              this.locations = country?.cities ?? [];
              this.internalValue = country?.cities?.[0] ?? country;
            } else {
              this.internalValue = country;
            }
          },
        );
      });

    if (this.hotelSearchForm?.destination) {
      this.destinationFormControl.setValue(this.hotelSearchForm?.destination);
      const locationList = this.cityCountryBuilderSerivce.getCountryList();
      const countryName = this.hotelSearchForm.destination.label
        .split(", ")
        .pop();
      const selectedCountry = locationList.find(
        (location) => location.name === countryName,
      );
      if (!this.isCity) {
        this.locations = locationList;
        this.internalValue = selectedCountry;
        this.regionSearchDropdownService.setSelectedCountry(selectedCountry);
      } else {
        this.internalValue = selectedCountry.cities.find(
          (city) =>
            city.id === this.hotelSearchForm.destination.value.destination_id,
        );
      }
    } else {
      if (!this.isCity) {
        this.locations = this.cityCountryBuilderSerivce.getCountryList();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.destinationFormControl = this.parentForm.controls.destination;
    if (changes.value && changes.value.currentValue) {
      this.internalValue = changes.value.currentValue;
    }
  }

  changeSelection(location: CountrySelection | CitySelection) {
    this.internalValue = location;
    if (this.isCity && "lat" in location) {
      const hotelDestination = {
        label: location.term,
        value: {
          destination_id: location.id,
          type: "city",
          lat: location.lat,
          lng: location.lng,
          hotel_id: null,
        },
      };
      this.destinationFormControl.setValue(hotelDestination);
    } else if (!this.isCity && "cities" in location) {
      this.regionSearchDropdownService.setSelectedCountry(location);
      const hotelDestination = {
        label: location.cities[0].term,
        value: {
          destination_id: location.cities[0].id,
          type: "city",
          lat: location.cities[0].lat,
          lng: location.cities[0].lng,
          hotel_id: null,
        },
      };
      this.destinationFormControl.setValue(hotelDestination);
    }
  }

  isDestinationFieldInvalid(): boolean {
    return (
      this.parentForm.controls.destination.errors?.required &&
      this.parentForm.controls.destination.dirty
    );
  }
}
