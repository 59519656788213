import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { RoomGuestFieldComponent } from "./room-guest-field.component";
import { RoomGuestCounterModule } from "booking-app-v2/shared/components/room-guest-counter/room-guest-counter.module";

@NgModule({
  declarations: [ RoomGuestFieldComponent ],
  imports: [
    SharedModule,
    RoomGuestCounterModule,
  ],
  exports: [ RoomGuestFieldComponent ],
  providers: [],
})
export class RoomGuestFieldModule {}
