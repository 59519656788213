import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { CurrencyDropdownComponent } from "./currency-dropdown.component";

@NgModule({
  declarations: [ CurrencyDropdownComponent ],
  imports: [
    SharedModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [ CurrencyDropdownComponent ],
  providers: [],
})
export class CurrencyDropdownModule {}
