import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { CheckoutValidationService } from "booking-app-v2/shared/services/checkout-validation.service";
import { BootstrapDataService } from "booking-app-v2/shared/services/bootstrap-data.service";
import { TimeUtils } from "booking-app-v2/shared/utils";
import { CancellationPolicy, Currency, LandingPage, PointsPartner, User } from "booking-app-v2/shared/models";
import { HotelSearchForm, Room } from "booking-app-v2/hotels/models";
import { RoomCancellationPolicy } from "booking-app-v2/hotels/types";
import { DEVICE, GlobalDataEnum, PRODUCT_TYPE, ProductType } from "booking-app-v2/shared/types";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import { PointsCashPaymentHandlingService } from "booking-app-v2/shared/services/points-cash-payment-handling.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "grouped-room-list-item",
  templateUrl: "/html/hotels/grouped_room_list_item_v2",
})
export class GroupedRoomListItemComponent implements OnInit {
  @Input() room: Room;

  @Input() policies: RoomCancellationPolicy;

  hotelSearchForm: HotelSearchForm;
  pointsPartner: PointsPartner;
  landingPage: LandingPage;
  currentUser: User;
  productType: ProductType;
  selectedCurrency: Currency;
  private adminDataOn: boolean;

  constructor(
    public globalData: GlobalData,
    public appSettingsService: AppSettingsService,
    private windowRefService: WindowRefService,
    private currenciesService: CurrenciesService,
    private translateService: TranslateService,
    private checkoutValidationService: CheckoutValidationService,
    private bootstrapDataService: BootstrapDataService,
    public pageDiscoveryService: PageDiscoveryService,
    private pointsCashPaymentHandlingService: PointsCashPaymentHandlingService,
  ) { }

  ngOnInit() {
    this.initCurrencyListener();
    this.selectedCurrency = this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
    this.hotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    this.pointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
    this.landingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
    this.currentUser = this.globalData.get(GlobalDataEnum.CURRENT_USER);
    this.productType = this.globalData.get(GlobalDataEnum.PRODUCT_TYPE);
  }

  breakfastLogoUrl(): string {
    if (this.room.roomDescriptionDetail !== "hotel_detail_room_only") {
      return this.appSettingsService.hotelDetailsTemplateConfig.breakfastIconUrl;
    } else {
      return this.appSettingsService.hotelDetailsTemplateConfig.noBreakfastIconUrl;
    }
  }

  goToHotelCheckoutPage(): void {
    this.checkoutValidationService.goToHotelCheckoutPage(this.room);
  }

  shouldShowFreeCancellation(): boolean {
    switch (this.globalData.get(GlobalDataEnum.PRODUCT_TYPE)) {
      case PRODUCT_TYPE.REDEEM:
        return this.appSettingsService.showCancelPolicyOnRedeem;
      case PRODUCT_TYPE.EARN:
        return this.appSettingsService.showCancelPolicyOnEarn;
      default:
        return false;
    }
  }

  hasCancellationText(): boolean {
    return !!this.roomPolicy().freeCancellationText && this.shouldShowFreeCancellation();
  }

  cancellationText(): string {
    if (this.bootstrapDataService.bootstrapData.cancellation_fee_percentage) {
      return this.translateService.instant(this.appSettingsService.cancellationFeeText.details);
    } else {
      return this.translateService.instant("Free cancellation");
    }
  }

  // to check if loaded
  hasPolicy(): boolean {
    return !!this.roomPolicy() && Object.prototype.hasOwnProperty.call(this.roomPolicy(), "refundable");
  }

  toggleAdminData(e: Event): void {
    this.adminDataOn = !this.adminDataOn;
    e.stopPropagation();
  }

  formatCancellationDate(policy: CancellationPolicy): string {
    const date = policy.freeCancellationText || policy.free_cancellation_before;
    const dateFormat = "ddd, D MMM";
    let dateString: string;

    if (TimeUtils.isValidDate(date)) {
      dateString = TimeUtils.format(date, dateFormat);
    } else {
      dateString = date;
    }

    return this.translateService.instant("txt.before_cancellation_date", { date: dateString });
  }

  isMobile(): boolean {
    return this.windowRefService.device === DEVICE.PHONE;
  }

  zeroFullCashPayment(): boolean {
    return this.pointsCashPaymentHandlingService.zeroFullCashPayment(
      this.room.priceInfo.cash_payment,
      this.room.priceInfo.points_payment,
    );
  }

  zeroFullCashPaymentPerNight(): boolean {
    return this.pointsCashPaymentHandlingService.zeroFullCashPayment(
      this.room.priceInfo.cash_payment_per_night,
      this.room.priceInfo.points_payment_per_night,
    );
  }

  // angular-v2-todo: details page room hidden heading
  // roomItemHiddenHeadingText(): string {
  //   const roomName: string = this.room.roomDescriptionShort;
  //   const roomOption: string = this.$translate.instant(this.room.roomDescriptionDetail);
  //   let heading: string = `${roomName}, ${roomOption}`;
  //
  //   if (!this.hasPolicy()) {
  //     return heading;
  //   }
  //
  //   if (this.hasCancellationText()) {
  //     const cancellationDate: string = this.formatCancellationDate(
  //       this.policies[this.room.key],
  //       "MMMM Do YYYY",
  //     );
  //     heading = heading + `, with ${this.cancellationText()} ${cancellationDate}`;
  //   } else {
  //     heading = heading + ", non refundable";
  //   }
  //
  //   return heading;
  // }

  private roomPolicy(): CancellationPolicy {
    return this.policies && this.policies[this.room.key];
  }

  private initCurrencyListener(): void {
    this.currenciesService.onCurrencyChange
      .pipe(untilDestroyed(this))
      .subscribe((newCurrency: Currency) => {
        this.selectedCurrency = newCurrency;
      });
  }
}
