import { Component, Input } from "@angular/core";

import { RoomPrice } from "booking-app-v2/hotels/models";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { FormatNumberUtil } from "booking-app-v2/shared/utils";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

@Component({
  selector: "redemption-discount",
  templateUrl: "/html/components/redemption_discount_v2",
})
export class RedemptionDiscountComponent {
  @Input() priceInfo: RoomPrice;

  constructor(
    private globalData: GlobalData,
  ) {
  }

  hasPoints(): boolean {
    return this.priceInfo.original_cash_payment_per_night > 0;
  }

  hasCash(): boolean {
    return this.priceInfo.original_cash_payment_per_night > 0;
  }

  displayedPoints(): string {
    return `${FormatNumberUtil.roundNumber(this.priceInfo.original_points_payment_per_night,
      this.globalData.get(GlobalDataEnum.SELECTED_LOCALE))} ${this.globalData.get(GlobalDataEnum.POINTS_PARTNER)
        .currency_short}`;
  }

  displayedCash(): string {
    return FormatNumberUtil.roundNumber(
      this.priceInfo.original_cash_payment_per_night,
      this.globalData.get(GlobalDataEnum.SELECTED_LOCALE),
    );
  }

  shouldRender(): boolean {
    return (this.priceInfo.original_cash_payment_per_night - this.priceInfo.cash_payment_per_night) > 0
      || (this.priceInfo.original_points_payment_per_night - this.priceInfo.points_payment_per_night) > 0;
  }
}
