export class SortUtil {
  static string<T>(list: T[], key: string, isDescending: boolean = false): T[] {
    return list.sort((a: T, b: T): number => {
      const aValue: string = a[key] ? a[key].toLowerCase() : "";
      const bValue: string = b[key] ? b[key].toLowerCase() : "";
      return this.compare(aValue, bValue, isDescending);
    });
  }

  static int<T>(list: T[], key: string, isDescending: boolean = false): T[] {
    return list.sort((a: T, b: T): number => {
      const aValue: number = a[key] ? parseInt(a[key], 10) : 0;
      const bValue: number = b[key] ? parseInt(b[key], 10) : 0;
      return this.compare(aValue, bValue, isDescending);
    });
  }

  static func<T>(list: T[], fun: (arg: T) => number, isDescending: boolean = false): any[] {
    return list.sort((a: T, b: T): number => {
      const aValue: number = fun(a);
      const bValue: number = fun(b);
      return this.compare(aValue, bValue, isDescending);
    });
  }

  static compare(a: string | number, b: string | number, isDescending: boolean = false): number {
    let compared: number = 0;
    if (a > b) {
      compared = 1;
    } else if (a < b) {
      compared = -1;
    } else {
      return compared;
    }
    return isDescending ? -compared : compared;
  }
}
