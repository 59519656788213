import {
  Amenities,
  Categories,
  ImageDetails,
  OriginalMetadata,
} from "../types";
import { TrustYou } from "../models";
import { RoomPrice } from "./room-price.model";
import { Base } from "booking-app-v2/shared/models";
import { HotelImageUtil } from "../utils";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { Rooms } from "./rooms.model";

export class Hotel extends Base {
  readonly address: string;
  readonly address1: string;
  readonly amenities: Amenities;
  readonly categories: Categories;
  readonly cloudflare_image_url: string;
  readonly default_image_index: number;
  readonly description: string;
  readonly distance: number;
  readonly id: string;
  readonly imageCount: number;
  readonly image_details: ImageDetails;
  readonly imgix_url: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly name: string;
  readonly number_of_images: number;
  readonly original_metadata?: OriginalMetadata;
  readonly rating?: number;
  readonly trustyou?: TrustYou;
  readonly defaultThumbImageStyle: { [klass: string]: string; };
  readonly defaultHotelBgImageStyle: { [klass: string]: string; };
  readonly defaultBackgroundImage: string;
  readonly mediumSizeImagesSet: string[];
  readonly highResImagesSet: string[];
  priceInfo: RoomPrice;
  available: boolean;
  rooms: Rooms;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    hotel: Hotel) {
    super(appSettingsService, globalData);

    this.address = hotel.address;
    this.address1 = hotel.address1;
    this.amenities = hotel.amenities;
    this.categories = hotel.categories;
    this.cloudflare_image_url = hotel.cloudflare_image_url;
    this.default_image_index = hotel.default_image_index;
    this.description = hotel.description;
    this.distance = hotel.distance;
    this.id = hotel.id;
    this.imageCount = hotel.imageCount;
    this.image_details = hotel.image_details;
    this.imgix_url = hotel.imgix_url;
    this.latitude = hotel.latitude;
    this.longitude = hotel.longitude;
    this.name = hotel.name;
    this.number_of_images = hotel.number_of_images;
    this.original_metadata = hotel.original_metadata;
    this.rating = hotel.rating;
    this.trustyou = hotel.trustyou;
    this.defaultThumbImageStyle = HotelImageUtil.getDefaultThumbImageStyle(this);
    this.defaultHotelBgImageStyle = HotelImageUtil.getDefaultHotelBgImageStyle();
    this.defaultBackgroundImage = HotelImageUtil.getDefaultHotelBgImage();
    this.mediumSizeImagesSet = HotelImageUtil.getHotelMediumSizeImagesSet(this);
    this.highResImagesSet = HotelImageUtil.getHotelHighResImagesSet(this);
  }
}
