import { RawPrice } from "booking-app-v2/shared/types";
import { Price } from "booking-app-v2/shared/models";

export class CommonPriceDecorator {

  static decorate(price: Price, rawPrice: RawPrice): void {
    price.max_points_payment = rawPrice.max_points_payment;
    price.max_cash_payment = rawPrice.max_cash_payment;
    price.base_points_payment = rawPrice.base_points_payment;
    price.base_cash_payment = rawPrice.base_cash_payment;
    price.cash_fixed_discount = rawPrice.cash_fixed_discount;
    price.points_fixed_discount = rawPrice.points_fixed_discount;
    price.cash_non_fixed_discounts_by_tier = rawPrice.cash_non_fixed_discounts_by_tier;
    price.points_non_fixed_discounts_by_tier = rawPrice.points_non_fixed_discounts_by_tier;
    price.bonus_programs = rawPrice.bonus_programs;
    price.bonus_tiers = rawPrice.bonus_tiers;
    price.max_cash_payment_by_tier = rawPrice.max_cash_payment_by_tier;
  }
}
