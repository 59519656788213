import { AfterViewInit, Component, Input } from "@angular/core";

import { MapboxService } from "booking-app-v2/hotels/services";

@Component({
  selector: "map-view",
  template: `
    <div id="hotel-detail-map">
      <div id="mapbox-search-map"></div>
    </div>
  `,
})
export class MapViewComponent implements AfterViewInit {
  @Input() lng: number;
  @Input() lat: number;

  constructor(private mapboxService: MapboxService) {}

  ngAfterViewInit() {
    this.setupMap();
  }

  private setupMap(): void {
    setTimeout(() => {
      this.mapboxService.resetAll();
      this.mapboxService.setupMapView("mapbox-search-map");
      this.mapboxService.setupSingleHotelMap([this.lng, this.lat]);
    });
  }
}
