import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Observable } from "rxjs";

import { HotelBooking } from "booking-app-v2/hotels/models";
import { BookingsService } from "booking-app-v2/shared/services/bookings.service";

@Injectable({ providedIn: "root" })
export class HotelBookingResolver implements Resolve<HotelBooking> {
  constructor(
    private bookingsService: BookingsService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<HotelBooking> {
    const bookingReference: string = route.paramMap.get("id");
    const accessToken: string = route.queryParamMap.get("access_token");
    return this.bookingsService.getHotelBooking(bookingReference, accessToken);
  }
}
