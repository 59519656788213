import { Component, EventEmitter, Input, Output } from "@angular/core";
@Component({
  selector: "input-counter",
  templateUrl: "/html/components/input_counter_v2",
})
export class InputCounterComponent {
  @Input() value: number;
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() customLabel: string;
  @Input() customAriaLabel: string;
  @Output() onUpdate: EventEmitter<number> = new EventEmitter<number>();

  decrementValue(): void {
    if (this.isDecrementEnabled()) {
      this.onUpdate.emit(--this.value);
    }
  }

  incrementValue(): void {
    if (this.isIncrementEnabled()) {
      this.onUpdate.emit(++this.value);
    }
  }

  isDecrementEnabled(): boolean {
    return this.value > this.minValue;
  }

  isIncrementEnabled(): boolean {
    return this.value < this.maxValue;
  }
}
