import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from "@angular/animations";

export const carouselAnimation: AnimationTriggerMetadata = trigger("carouselAnimation", [
  state("prev", style({
    transform: "translateX(-100%)",
  })),
  state("curr", style({
    transform: "translateX(0)",
  })),
  state("next", style({
    transform: "translateX(100%)",
  })),
  transition("prev => curr", [
    animate("0.3s ease-out"),
  ]),
  transition("curr => prev", [
    animate("0.3s ease-out"),
  ]),
  transition("next => curr", [
    animate("0.3s ease-out"),
  ]),
  transition("curr => next", [
    animate("0.3s ease-out"),
  ]),
]);
