import { Injectable } from "@angular/core";
import { finalize, ReplaySubject, take } from "rxjs";

import { BaseStep } from "./base-step";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { HotelUtilService } from "booking-app-v2/hotels/services";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { CouponService } from "booking-app-v2/shared/services/coupon.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  FetchPriceResult,
  GlobalDataEnum,
} from "booking-app-v2/shared/types";
import { HotelRoomRawResponse } from "booking-app-v2/hotels/types";
import { Room } from "booking-app-v2/hotels/models";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import { VoucherService } from "booking-app-v2/shared/services/voucher.service";

@Injectable({
  providedIn: "root",
})
export class HotelFetchPriceService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.FETCH_PRICE;

  private readonly hotelId: string = this.windowRefService.getURIParamFromWindow();
  private readonly bookingKey: string = this.windowRefService.getQueryParamFromWindow("bookingKey");
  private couponCode: string = "";

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private currenciesService: CurrenciesService,
    private payWithPointsCashService: PayWithPointsCashService,
    private pageDiscoveryService: PageDiscoveryService,
    private windowRefService: WindowRefService,
    private apiDataService: ApiDataService,
    private hotelUtilService: HotelUtilService,
    private couponService: CouponService,
    private voucherService: VoucherService,
  ) { }

  process(results: CheckoutResults): FetchPriceResult {
    const result: ReplaySubject<Room> = new ReplaySubject<Room>(1);
    this.couponCode = this.couponService.isValid() ? this.couponService.couponCodeDetails.code : "";

    // If coupon code is set, wait for the coupon validation to complete
    if (this.couponService.hasCouponCode()) {
      (results[CHECKOUT_RESULT_NAME.VALIDATE_COUPON_CODE])
        .pipe(take(1))
        .subscribe((valid: boolean) => {
          if (valid) {
            this.couponCode = this.couponService.couponCodeDetails.code;
            this.couponService.validatingPriceChanged = true;
            this.callFetchPrice(result);
          }
        });
      return result.asObservable();
    }

    this.callFetchPrice(result);
    return result.asObservable();
  }

  private callFetchPrice(result: ReplaySubject<Room>): void {
    this.apiDataService.get(this.buildPriceCallUrl())
      .pipe(finalize(() => this.couponService.validatingPriceChanged = false))
      .subscribe({
        next: (hotelRoomRawResponse: HotelRoomRawResponse) => {
          let room: Room;
          if (!!hotelRoomRawResponse) {
            this.globalData.set(GlobalDataEnum.TAX_INCLUSIVE, !!hotelRoomRawResponse.tax_inclusive);
          }
          for (const roomRawPrice of hotelRoomRawResponse.rooms) {
            if (roomRawPrice.key === this.bookingKey) {
              room = new Room(
                this.appSettingsService,
                this.globalData,
                this.currenciesService,
                this.payWithPointsCashService,
                this.pageDiscoveryService,
                this.voucherService.vouchers,
                roomRawPrice,
              );
            }
          }
          if (this.couponService.hasCouponCode()) {
            this.couponService.updateValidationResponse(room);
          }
          result.next(room);
        },
        error: () => result.next(undefined),
      });
  }

  private buildPriceCallUrl(): string {
    return this.hotelUtilService.getUrlForPriceAndReviews(
      `hotels/${this.hotelId}/price`,
      this.bookingKey,
      this.couponCode,
    );
  }
}
