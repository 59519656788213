import { Injectable } from "@angular/core";

import { BaseStep } from "./base-step";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  StartPaymentLoadingResult,
} from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class StartPaymentLoadingService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.START_PAYMENT_LOADING;

  process(results: CheckoutResults): StartPaymentLoadingResult {
    results.isPaymentLoading = true;
  }

}
