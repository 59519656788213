import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Options } from "@ascenda/ngx-slider";
import { TranslateService } from "@ngx-translate/core";

import { Hotels, HotelSearchForm } from "booking-app-v2/hotels/models";
import { CashRange, GlobalDataEnum, TRAVEL_TYPE, TravelType } from "booking-app-v2/shared/types";
import { Locale } from "booking-app-v2/shared/models";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { LocaleService } from "booking-app-v2/shared/services/initializers/locale.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { FormatNumberUtil } from "booking-app-v2/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CarSearchForm } from "booking-app-v2/cars/models";

@UntilDestroy()
@Component({
  selector: "cash-slider",
  template: `
    <ascenda-ngx-slider
      (minValueChange)="updateSliderMinValue($event)"
      (valueChange)="updateSliderMaxValue($event)"
      [options]="sliderOptions"
    >
    </ascenda-ngx-slider>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashSliderComponent implements OnInit, OnChanges {
  sliderOptions: Options;
  travelType: TravelType;

  @Input() sliderValues: CashRange;
  @Input() hotels: Hotels;
  @Output() onSliderUpdate: EventEmitter<CashRange> = new EventEmitter<CashRange>();

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private translateService: TranslateService,
    private localeService: LocaleService,
    private currenciesService: CurrenciesService,
  ) {}

  ngOnInit() {
    this.travelType = this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);
    this.listenLangChange();
  }

  getComplimentaryNights(): number {
    return this.hotels?.visibleList[0]?.priceInfo?.complimentary_nights ?? 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    const sliderValuesObj: CashRange = changes.sliderValues.currentValue
      ? changes.sliderValues.currentValue
      : changes.sliderValues.previousValue;

    this.initSlider({ ...sliderValuesObj });
  }

  updateSliderMinValue(minValue: number): void {
    this.sliderOptions.minValue = minValue;
    this.updateCashRange();
  }

  updateSliderMaxValue(maxValue: number): void {
    this.sliderOptions.value = maxValue;
    this.updateCashRange();
  }

  isRtl(): boolean {
    return this.globalData.get(GlobalDataEnum.DISPLAY_ALIGNMENT) === "rtl";
  }

  private listenLangChange(): void {
    this.localeService.onLocaleChange
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.initSlider({
          min: this.sliderOptions.minValue,
          max: this.sliderOptions.value,
          ceil: this.sliderOptions.ceil,
          floor: this.sliderOptions.floor,
        });
      });
  }

  private updateCashRange(): void {
    const cashRange: CashRange = {
      min: this.sliderOptions.minValue,
      max: this.sliderOptions.value,
      ceil: this.sliderOptions.ceil,
      floor: this.sliderOptions.floor,
    };

    this.onSliderUpdate.emit(cashRange);
  }

  private initSlider(config: CashRange): void {
    this.sliderOptions = {
      minValue: config.min,
      floor: config.floor,
      ceil: config.ceil,
      value: config.max,
      step: 1,
      isRtl: this.isRtl(),
      minValueLabel: this.formatPrice.bind(this),
      valueLabel: this.formatPrice.bind(this),
    };
    this.changeDetectionRef.markForCheck();
  }

  private formatPrice(price: number): string {
    if (this.travelType === TRAVEL_TYPE.HOTELS) {
      return this.formatHotelPrice(price);
    } else if (this.travelType === TRAVEL_TYPE.CARS) {
      return this.formatCarPrice(price);
    } else {
      return "";
    }
  }

  private formatHotelPrice(price: number): string {
    const hotelSearchForm: HotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    const currencyCode: string = this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).symbol;
    const selectedLocale: Locale = this.globalData.get(GlobalDataEnum.SELECTED_LOCALE);
    if (!this.appSettingsService.showTotalNights) {
      price = price / (hotelSearchForm.duration - this.getComplimentaryNights());
      price = price / hotelSearchForm.rooms;
    }
    price = this.currenciesService.convertFromUsd(price);
    const formattedPrice: string = FormatNumberUtil.roundNumber(price, selectedLocale);
    return `${currencyCode}${formattedPrice}`;
  }

  private formatCarPrice(price: number): string {
    const carSearchForm: CarSearchForm = this.globalData.get(GlobalDataEnum.CAR_SEARCH_FORM);
    const currencyCode: string = this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).symbol;
    const selectedLocale: Locale = this.globalData.get(GlobalDataEnum.SELECTED_LOCALE);
    if (!this.appSettingsService.showTotalNights) {
      price = price / carSearchForm.duration;
    }
    price = this.currenciesService.convertFromUsd(price);
    const formattedPrice: string = FormatNumberUtil.roundNumber(price, selectedLocale);
    return `${currencyCode}${formattedPrice}`;
  }
}
