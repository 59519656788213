import { Injectable } from "@angular/core";
import { PaymentSettings } from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class PaymentStylingSettingsService {
  paymentSettings: PaymentSettings;

  constructor() {
    this.setupPaymentStyling();
  }

  setupPaymentStyling(): void {
    // Default base style
    this.paymentSettings = {
      stripe: {
        base: {
          color: "#282828",
          fontSize: "14.4px",
        },
        invalid: {
          color: "#282828",
        },
      },
    };
  }
}
