import { Component, Input } from "@angular/core";

import { PointsPartner } from "booking-app-v2/shared/models";
import { CURRENT_PAGE, GlobalDataEnum, PRODUCT_TYPE } from "booking-app-v2/shared/types";

import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";

@Component({
  selector: "points-cash-slider",
  template: `
    <div class="point-cash-slider-component" *ngIf="displayPointsCashSlider()">
      <div id="fixed-background" class="partner-bg-color"
          [ngStyle]="{ 'background-color': pointsPartner.formattedSettings.colors.backgroundColor }"></div>
      <div class="component-container pos-relative">
        <div class="custom-label inline-element hidden-xs hidden-sm" *ngIf="atResultsPage || atFlightDetailPage">
          <span [translate]="sliderLabel()"
                [translateParams]="{ partner_currency: pointsPartner.currency_short }"></span>
          <span> :</span>
        </div>
        <div *ngIf="!hideLabels" class="label label-slider-begin">
          <span class="capitalize" [translate]="appSettingsService.pointsCashSliderSettings.sliderLeftLabel"></span>
        </div>
        <ascenda-ngx-slider id="points-cash-slider" class="partner-bg-color"
                    [options]="pointsCashShareService.pointsCashShare.sliderOptions"
                    (valueChange)="pointsCashShareService.onChange($event)"></ascenda-ngx-slider>
        <div *ngIf="!hideLabels" class="label label-slider-end">
          <span class="capitalize" [translate]="appSettingsService.pointsCashSliderSettings.sliderRightLabel"></span>
        </div>
      </div>
    </div>
  `,
})

export class PointsCashSliderComponent {
  @Input() customSliderLabel: string;
  @Input() hideLabels: boolean;

  atResultsPage: boolean;
  atFlightDetailPage: boolean;
  pointsPartner: PointsPartner;

  constructor(
    public globalData: GlobalData,
    public appSettingsService: AppSettingsService,
    public pointsCashShareService: PointsCashShareService,
    public pageDiscoveryService: PageDiscoveryService,
  ) {
    this.atResultsPage = this.pageDiscoveryService.currentPage() === CURRENT_PAGE.SEARCH_RESULT;
    this.atFlightDetailPage = this.pageDiscoveryService.currentPage() === CURRENT_PAGE.FLIGHT_DETAIL;
    this.pointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
  }

  displayPointsCashSlider(): boolean {
    return this.globalData.get(GlobalDataEnum.LANDING_PAGE).hasProductType(PRODUCT_TYPE.REDEEM)
      || this.appSettingsService.pointsCashSliderSettings.alwaysShowPointsSlider;
  }

  sliderLabel(): string {
    return this.customSliderLabel ?? this.appSettingsService.pointsCashSliderSettings.sliderLabel;
  }
}
