import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { BaseStep } from "./base-step";
import {
  CheckoutParamsDebugInfo,
  CheckoutParamsGuest,
  CheckoutParamsHotelSpecific,
  CheckoutParamsMembership,
  CheckoutParamsTraffic,
  CheckoutParamsUser,
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  GlobalDataEnum,
  Membership,
  SelectPaymentChannelResult,
  TransactionMembership,
  HotelCollectBookingParamsResult,
} from "booking-app-v2/shared/types";
import { Hotel, HotelSearchForm, Room } from "booking-app-v2/hotels/models";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import {
  CommonCollectBookingParamsService,
} from "booking-app-v2/shared/services/checkout/steps/common-collect-booking-params.service";

import { LocaleNumberFormat } from "booking-app-v2/shared/pipes";
import { TimeUtils } from "booking-app-v2/shared/utils";

@Injectable({
  providedIn: "root",
})
export class HotelCollectBookingParamsService implements BaseStep {
  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.HOTEL_COLLECT_BOOKING_PARAMS;
  private checkoutForm: FormGroup;
  private room: Room;
  private hotel: Hotel;
  private cardType: string;
  private voucherIds: string[];
  private familyMilesSelected: boolean;
  private membership: Membership | TransactionMembership;
  private hotelSearchForm: HotelSearchForm;
  private selectPaymentChannelResult: SelectPaymentChannelResult;

  constructor(
    private globalData: GlobalData,
    private localeNumberFormat: LocaleNumberFormat,
    private commonCollectBookingParamsService: CommonCollectBookingParamsService,
  ) {}

  process(results: CheckoutResults): HotelCollectBookingParamsResult {
    this.initializeCheckoutData(results);

    return {
      ...this.commonCollectBookingParamsService.buildCommonBookingParams(
        this.checkoutForm,
        this.voucherIds,
        this.selectPaymentChannelResult.newCardSave,
      ),
      ...this.buildUserParams(),
      ...this.buildDebugInfoParams(),
      ...this.buildMembershipParams(),
      ...this.buildGuestParams(),
      ...this.buildTrafficParams(),
      ...this.buildHotelSpecificParams(),
    };
  }

  private buildUserParams(): CheckoutParamsUser {
    return {
      user: {
        ...this.commonCollectBookingParamsService.buildCommonUserParams(this.checkoutForm),
        // angular-v2-todo: will do once implemented in v2
        redemption_password: undefined,
      },
    };
  }

  private buildDebugInfoParams(): CheckoutParamsDebugInfo {
    return {
      debug_info: {
        points_payment: this.room.priceInfo?.points_payment ? Math.max(this.room.priceInfo?.points_payment, 0) : 0,
        cash_payment: parseFloat(this.formatValue(this.cashToPay())),
        points_earned: this.room.priceInfo?.points ?? 0,
        bonus_programs: this.room.priceInfo?.bonus_programs,
        bonus_tiers: this.room.priceInfo?.bonus_tiers,
        exchange_rate: this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).rate,
      },
    };
  }

  private buildMembershipParams(): CheckoutParamsMembership {
    return {
      membership: {
        first_name: this.membership.member_first_name,
        last_name: this.membership.member_last_name,
        number: this.membership.member_no,
      },
    };
  }

  private buildGuestParams(): CheckoutParamsGuest {
    return {
      guest: {
        salutation: this.checkoutForm.controls.title.value,
        first_name: this.checkoutForm.controls.firstName.value,
        last_name: this.checkoutForm.controls.lastName.value,
        room_remarks: this.formatSpecialRequest(this.checkoutForm.controls.specialRequests.value),
        phone: this.commonCollectBookingParamsService.formatPhoneNumber(this.checkoutForm),
      },
    };
  }

  private buildTrafficParams(): CheckoutParamsTraffic {
    return {
      // angular-v2-todo: we will implement this when doing CookieService
      traffic_source: undefined,
      // angular-v2-todo: we will implement this when doing CookieService
      traffic_click_id: undefined,
    };
  }

  private buildHotelSpecificParams(): CheckoutParamsHotelSpecific {
    return {
      hotel_id: this.hotel?.id,
      destination_id: this.hotelSearchForm.destination.value.destination_id,
      checkin: this.formatDate(this.hotelSearchForm.checkInDate),
      checkout: this.formatDate(this.hotelSearchForm.checkOutDate),
      room_description: this.room.roomNormalizedDescription,
      hotel_name: this.hotel?.name,
      guests: this.hotelSearchForm.guests,
      referrer: this.globalData.get(GlobalDataEnum.CURRENT_USER)?.tenant_referrer,
      payment_nonce: this.selectPaymentChannelResult.paymentNonce,
      payment_nonce_type: "card_token",
      use_family_miles: this.familyMilesSelected,
      // angular-v2-todo: remove when hainan is decommissioned
      otp: "",
    };
  }

  private initializeCheckoutData(results: CheckoutResults): void {
    this.selectPaymentChannelResult = results[CHECKOUT_RESULT_NAME.SELECT_PAYMENT_CHANNEL];
    this.hotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    this.checkoutForm = results.intermediateCheckoutParams.checkoutForm;
    this.room = results.intermediateCheckoutParams.room;
    this.hotel = results.intermediateCheckoutParams.hotel;
    this.cardType = results.intermediateCheckoutParams.cardType;
    this.voucherIds = results.intermediateCheckoutParams.voucherIds;
    this.membership = results.intermediateCheckoutParams.membership;
    this.familyMilesSelected = results.intermediateCheckoutParams.familyMilesSelected;
  }

  private formatSpecialRequest(specialRequest: string): string {
    return specialRequest.replace(/&/g, "and").trim();
  }

  private formatDate(dateStr: string): string {
    return TimeUtils.format(dateStr, "YYYY-MM-DD", "MM/DD/YYYY");
  }

  private cashToPay(): number {
    if (!this.room.priceInfo) {
      return 0;
    } else if (
      this.globalData.get(GlobalDataEnum.LANDING_PAGE).complimentaryOrRedeem() &&
      this.room.priceInfo.cash_payment > 0
    ) {
      return this.room.priceInfo.cash_payment;
    } else {
      return this.room.currencyService.convertFromUsd(this.room.priceInfo.leftToPay);
    }
  }

  private formatValue(value: number): string {
    return this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).decimalPlace === 0
      ? this.localeNumberFormat.transform(Math.ceil(value), 0)
      : this.localeNumberFormat.transform(value, 2);
  }
}
