import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, Subject, take, zip } from "rxjs";

import {
  BookingTransactionData,
  CheckoutFormData,
  CheckoutFormUserDetails,
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  Country,
  FetchBookingTransactionDataResult,
  GlobalDataEnum,
  InitFormDataResult,
  Membership,
  PhoneCode,
  State,
  TRAVEL_TYPE,
  TravelType,
  TransactionMembership,
} from "booking-app-v2/shared/types";
import { BaseStep } from ".";
import { User } from "booking-app-v2/shared/models";
import { GlobalData } from "../../global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { CountryService } from "booking-app-v2/shared/services/country.service";

@Injectable({
  providedIn: "root",
})
export class InitFormDataService implements BaseStep {
  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.INIT_FORM_DATA;

  checkoutFormUserDetails: CheckoutFormUserDetails = {} as CheckoutFormUserDetails;
  private stateCode: string;
  private defaultCountryCode: string = this.appSettingsService.defaultCountryCodeInCheckout;

  constructor(
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private countryService: CountryService,
  ) {}

  process(results: CheckoutResults): InitFormDataResult {
    const transactionDataResult =
      results[CHECKOUT_RESULT_NAME.FETCH_BOOKING_TRANSACTION_DATA];
    const membershipDataResult = results[CHECKOUT_RESULT_NAME.FETCH_MEMBERSHIP];
    const result: Subject<CheckoutFormData> = new ReplaySubject<CheckoutFormData>(1);
    const checkoutFormUserDetails$: Observable<CheckoutFormUserDetails> =
      this.buildUserDetailsObs(transactionDataResult);

    zip(transactionDataResult.transactionData, membershipDataResult, checkoutFormUserDetails$)
      .pipe(take(1))
      .subscribe(([transactionData, rawMembershipData, checkoutFormUserDetails]) => {
        const membershipData = this.buildMembershipDetails(transactionData, rawMembershipData);
        setTimeout(() => {
          result.next({
            checkoutFormUserDetails,
            memberships: rawMembershipData,
            membershipData,
          });
        });
    });

    return result.asObservable();
  }

  private buildUserDetailsObs(transactionDataResult: FetchBookingTransactionDataResult):
    Observable<CheckoutFormUserDetails> {

    const result: Subject<CheckoutFormUserDetails> = new ReplaySubject<CheckoutFormUserDetails>(1);

    transactionDataResult.transactionData.subscribe((bookingTransactionData: BookingTransactionData) => {
      const user: User = this.globalData.get(GlobalDataEnum.CURRENT_USER);

      if (bookingTransactionData) {
        this.populateCommonDetailsFromBookingTransaction(bookingTransactionData);
      } else if (user) {
        this.populateCommonDetailsFromUser(user);
      } else {
        this.populateDefaultCommonDetails();
      }

      this.populateState();
      this.populateTravelTypeFields(bookingTransactionData, user);

      result.next(this.checkoutFormUserDetails);
    });

    return result.asObservable();
  }

  private populateTravelTypeFields(bookingTransactionData: BookingTransactionData, user: User): void {
    const travelType: TravelType = this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);
    switch (travelType) {
      case TRAVEL_TYPE.HOTELS:
        this.populateSpecialRequests(bookingTransactionData);
        break;
      case TRAVEL_TYPE.FLIGHTS:
        this.populateDateOfBirth(user);
        this.populatePassportDetails(user);
        break;
      case TRAVEL_TYPE.CARS:
        this.populateCountryOfResidence();
        break;
      default:
        break;
    }
  }

  private populateCommonDetailsFromBookingTransaction(bookingTransactionData: BookingTransactionData): void {
    this.checkoutFormUserDetails.title = bookingTransactionData.salutation;
    this.checkoutFormUserDetails.firstName = bookingTransactionData.firstName;
    this.checkoutFormUserDetails.lastName = bookingTransactionData.lastName;
    this.checkoutFormUserDetails.address1 = bookingTransactionData.street;
    this.checkoutFormUserDetails.email = bookingTransactionData.user_email;
    this.checkoutFormUserDetails.zipCode = bookingTransactionData.postalCode;
    this.checkoutFormUserDetails.city = bookingTransactionData.city;
    this.checkoutFormUserDetails.country = this.buildCountry(bookingTransactionData.country);
    this.checkoutFormUserDetails.phoneCode = this.buildPhoneCode(bookingTransactionData.phone_number);
    this.checkoutFormUserDetails.phoneNumber = this.buildPhoneNumber(bookingTransactionData.phone_number);
    this.checkoutFormUserDetails.userFirstName = bookingTransactionData.user_first_name;
    this.stateCode = bookingTransactionData.state;
  }

  private populateCommonDetailsFromUser(user: User): void {
    this.checkoutFormUserDetails.title = user.title;
    this.checkoutFormUserDetails.firstName = user.first_name;
    this.checkoutFormUserDetails.lastName = user.last_name;
    this.checkoutFormUserDetails.address1 = user.address1;
    this.checkoutFormUserDetails.email = user.email;
    this.checkoutFormUserDetails.gender = user.gender;
    this.checkoutFormUserDetails.zipCode = user.zip_code;
    this.checkoutFormUserDetails.city = user.city;
    this.checkoutFormUserDetails.country = this.buildCountry(user.country);
    this.checkoutFormUserDetails.phoneCode = this.buildPhoneCode(user.telephone);
    this.checkoutFormUserDetails.phoneNumber = this.buildPhoneNumber(user.telephone);
    this.checkoutFormUserDetails.userFirstName = user.first_name;
    this.stateCode = user.state;
  }

  private populateDefaultCommonDetails(): void {
    this.checkoutFormUserDetails.firstName = "";
    this.checkoutFormUserDetails.lastName = "";
    this.checkoutFormUserDetails.address1 = "";
    this.checkoutFormUserDetails.email = "";
    this.checkoutFormUserDetails.gender = "";
    this.checkoutFormUserDetails.zipCode = "";
    this.checkoutFormUserDetails.city = "";
    this.checkoutFormUserDetails.country = this.buildCountry(this.defaultCountryCode);
    this.checkoutFormUserDetails.phoneCode = {} as PhoneCode;
    this.checkoutFormUserDetails.phoneNumber = "";
    this.checkoutFormUserDetails.userFirstName = "";
  }

  private populateState(): void {
    const countryCode: string = this.checkoutFormUserDetails.country?.code;
    this.countryService.getState(countryCode, this.stateCode).subscribe((state: State) => {
      this.checkoutFormUserDetails.state = state || {} as State;
    });
  }

  private populateSpecialRequests(bookingTransactionData: BookingTransactionData): void {
    this.checkoutFormUserDetails.specialRequests = bookingTransactionData?.guest_special_requests || "";
  }

  private populateDateOfBirth(user: User): void {
    if (user) {
      const { day, month, year } = user;
      this.checkoutFormUserDetails.dateOfBirthDay = day;
      this.checkoutFormUserDetails.dateOfBirthMonth = month;
      this.checkoutFormUserDetails.dateOfBirthYear = year;
    }
  }

  private populatePassportDetails(user: User): void {
    if (user) {
      this.checkoutFormUserDetails.passportNumber = user.passport_number;
      this.checkoutFormUserDetails.passportExpiryDay = user.passport_day;
      this.checkoutFormUserDetails.passportExpiryMonth = user.passport_month;
      this.checkoutFormUserDetails.passportExpiryYear = user.passport_year;
    } else {
      this.checkoutFormUserDetails.passportNumber = "";
    }

    this.checkoutFormUserDetails.passportNationality = this.checkoutFormUserDetails.country;
  }

  private populateCountryOfResidence(): void {
    this.checkoutFormUserDetails.countryOfResidence = this.checkoutFormUserDetails.country;
  }

  private buildMembershipDetails(
    bookingTransactionData: BookingTransactionData,
    membershipData: Membership[],
  ): Membership | TransactionMembership {
    if (bookingTransactionData) {
      const transactionMembership: TransactionMembership = {
        member_no: bookingTransactionData.memberNo,
        member_first_name: bookingTransactionData.memberFirstName,
        member_last_name: bookingTransactionData.memberLastName,
      };
      return this.selectMembership(membershipData, transactionMembership);
    } else {
      return membershipData[0] || {
        member_first_name: "",
        member_no: "",
        member_last_name: "",
      };
    }
  }

  private selectMembership(
    membershipsList: Membership[],
    transactionMembership: TransactionMembership,
  ): Membership | TransactionMembership {
    for (const membership of membershipsList) {
      if (transactionMembership.member_no === membership.member_no) {
        return membership;
      }
    }

    return { ...transactionMembership };
  }

  private buildPhoneCode(telephone: string): PhoneCode {
    const phoneCode: number = parseInt(telephone?.split(" ")[0].replace(/[^\w\s]/gi, ""), 0);
    const country: Country = this.countryService.getCountryByPhoneCode(phoneCode);
    return country
      ? { text: `${country.name} (+${country.phoneCode})`, id: `(+${country.phoneCode})` }
      : {} as PhoneCode;
  }

  private buildPhoneNumber(telephone: string): string {
    return telephone?.split(" ")[1] || "";
  }

  private buildCountry(countryCode: string): Country {
    return this.countryService.getCountry(countryCode) || {} as Country;
  }
}
