import { TimeUtils } from "booking-app-v2/shared/utils";
import { FlightCabinOption, FlightTypeOption } from "../types";
import { FlightDestination } from "./flight-destination.model";

export class FlightSearchForm {
  static readonly MIN_ADULT_COUNT = 1;
  static readonly MAX_ADULT_COUNT = 9;
  static readonly MIN_CHILD_COUNT = 0;
  static readonly MAX_CHILD_COUNT = 9;
  static readonly MIN_INFANT_COUNT = 0;
  static readonly MAX_INFANT_COUNT = 9;

  private static readonly FLIGHT_SEARCH_DATE_FORMAT: string = "YYYY-MM-DD";

  readonly flightType: FlightTypeOption;
  readonly flightCabin: FlightCabinOption;
  readonly fromFlightDestination: FlightDestination;
  readonly toFlightDestination: FlightDestination;
  readonly adultCount: number;
  readonly childCount: number;
  readonly infantCount: number;
  readonly departureDate: string;
  readonly returnDate: string;
  readonly travelerCount: number;

  constructor(
    flightType: FlightTypeOption,
    flightCabin: FlightCabinOption,
    fromFlightDestination: FlightDestination,
    toFlightDestination: FlightDestination,
    adultCount: number,
    childCount: number,
    infantCount: number,
    departureDate: string,
    returnDate: string,
  ) {
    this.flightCabin = flightCabin;
    this.flightType = flightType;
    this.fromFlightDestination = fromFlightDestination;
    this.toFlightDestination = toFlightDestination;
    this.adultCount = adultCount;
    this.childCount = childCount;
    this.infantCount = infantCount;
    this.departureDate = this.formatDateAndTime(departureDate);
    this.returnDate = this.formatDateAndTime(returnDate);
    this.travelerCount = adultCount + childCount + infantCount;
  }

  fromAirportName(): string {
    if (this.fromFlightDestination.term) {
      return `${this.fromFlightDestination.term} (${this.fromFlightDestination.value})`;
    } else {
      return this.fromFlightDestination.value;
    }
  }

  toAirportName(): string {
    if (this.toFlightDestination.term) {
      return `${this.toFlightDestination.term} (${this.toFlightDestination.value})`;
    } else {
      return this.toFlightDestination.value;
    }
  }

  private formatDateAndTime(dateTime: string): string {
    return TimeUtils.format(dateTime , FlightSearchForm.FLIGHT_SEARCH_DATE_FORMAT)
  }
}
