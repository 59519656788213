import { NgModule } from "@angular/core";
import { SharedModule } from "booking-app-v2/shared/shared.module";
import { ExclusiveCardModule } from "booking-app-v2/shared/components/exclusive-card/exclusive-card.module";

import { ExclusiveBenefitsComponent } from "./exclusive-benefits.component";

@NgModule({
  declarations: [ ExclusiveBenefitsComponent ],
  imports: [
    SharedModule,
    ExclusiveCardModule,
  ],
  exports: [ ExclusiveBenefitsComponent ],
  providers: [],
})
export class ExclusiveBenefitsModule {}
