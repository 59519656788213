import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { CouponService } from "booking-app-v2/shared/services/coupon.service";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { PaymentMethodService } from "booking-app-v2/shared/services/payment-method.service";
import { AdyenFormService } from "booking-app-v2/shared/services/adyen-form.service";
import { StripePaymentIntentService } from "booking-app-v2/shared/services/stripe-payment-intent.service";

import {
  CheckoutCommonParams,
  CheckoutParamsAdyenCommonInfo,
  CheckoutParamsPayment,
  CheckoutParamsUserCommon,
  CheckoutParamsWL,
  CommonCheckoutParams,
  GlobalDataEnum,
  SavedCardCheckoutData,
} from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class CommonCollectBookingParamsService {
  constructor(
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private windowRefService: WindowRefService,
    private couponService: CouponService,
    private pointsCashShareService: PointsCashShareService,
    private paymentMethodService: PaymentMethodService,
    private adyenFormService: AdyenFormService,
    private stripePaymentIntentService: StripePaymentIntentService,
  ) {}

  buildCommonBookingParams(
    checkoutForm: FormGroup,
    voucherIds: string[],
    newCardSave: boolean,
  ): CommonCheckoutParams {
    return {
      ...this.buildCommonCheckoutParams(voucherIds, newCardSave),
      ...this.buildCommonPaymentParams(checkoutForm),
      ...this.buildCommonWLParams(),
      ...this.buildCommonAdyenParams(checkoutForm),
    };
  }

  buildCommonUserParams(checkoutForm: FormGroup): CheckoutParamsUserCommon {
    return {
      email: checkoutForm.controls.email.value,
      first_name: checkoutForm.controls.userFirstName.value,
      send_marketing: checkoutForm.controls.sendMarketing.value,
    };
  }

  formatPhoneNumber(checkoutForm: FormGroup): string {
    const trimmedPhoneNumber = checkoutForm.controls.phoneNumber.value.replace(/[^\d]/g, "");
    return `${checkoutForm.controls.phoneCode.value.id} ${trimmedPhoneNumber}`;
  }

  private buildCommonWLParams(): CheckoutParamsWL {
    return {
      product_type: this.getProductType(),
      lp: this.globalData.get(GlobalDataEnum.LANDING_PAGE).url,
      partner_id: this.globalData.get(GlobalDataEnum.POINTS_PARTNER).id,
    };
  }

  private buildCommonCheckoutParams(voucherIds: string[], newCardSave: boolean): CheckoutCommonParams {
    return {
      booking_key:
        this.windowRefService.getQueryParamFromWindow("bookingKey") ??
        this.windowRefService.getURIParamFromWindow(),
      currency: this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).code,
      coupon_code: this.couponService.isValid() ? this.couponService.couponCodeDetails.code : "",
      new_card_save: newCardSave,
      pay_with_points_tier: this.getPayWithPointsTier(),
      voucher_ids: voucherIds,
      // angular-v2-todo: will do once implemented in v2
      remember_me: false,
      points_account_id: this.globalData.get(GlobalDataEnum.POINTS_ACCOUNT_ID),
    };
  }

  private buildCommonPaymentParams(checkoutForm: FormGroup): CheckoutParamsPayment {
    const { payment_method, customer } = this.getSavedCardData();
    const { first_name, last_name } = this.getNameFromCardholderName(checkoutForm);

    return {
      payment: {
        house_number: checkoutForm.controls.houseNumber.value,
        browser_info: checkoutForm.controls.browserInfo.value,
        address: checkoutForm.controls.address1.value,
        city: checkoutForm.controls.city.value,
        country: checkoutForm.controls.country.value.code,
        first_name,
        last_name,
        payment_type_code: this.stripePaymentIntentService.currentCardBrand,
        postal_code: checkoutForm.controls.zipCode.value,
        state: this.getStateValue(checkoutForm),
        ...this.adyenFormService.adyenEncryptedData(checkoutForm),
        payment_method,
        customer,
      },
      payment_channel: checkoutForm.controls.paymentChannel.value,
    };
  }

  private buildCommonAdyenParams(checkoutForm: FormGroup): CheckoutParamsAdyenCommonInfo {
    let adyen_payment_type = "default";
    let adyen_card_token = "";
    let payment_channel = checkoutForm.controls.paymentChannel.value;
    if (this.paymentMethodService.isUsingSavedAdyenCard()) {
      adyen_payment_type = "adyen-recurring";
      adyen_card_token = this.paymentMethodService.selectedSavedCard.token;
      payment_channel = this.paymentMethodService.getPaymentChannelFromAdyenSavedCard();
    }
    return {adyen_payment_type, adyen_card_token, payment_channel};
  }

  private getProductType(): string {
    return this.appSettingsService.useProductType
      ? this.globalData.productTypeAdapter(this.globalData.get(GlobalDataEnum.PRODUCT_TYPE))
      : undefined;
  }

  private getSavedCardData(): SavedCardCheckoutData {
    if (this.stripePaymentIntentService.isUsingSavedCard()) {
      return {
        payment_method: this.paymentMethodService.selectedSavedCard.token,
        customer: this.paymentMethodService.selectedSavedCard.customer_id,
      };
    }

    return {
      payment_method: null,
      customer: null,
    };
  }

  private getNameFromCardholderName(checkoutForm: FormGroup): { first_name: string, last_name: string } {
    const name = checkoutForm.controls.cardName.value;
    if (name === "" || name === null) {
      return {
        first_name: "",
        last_name: "",
      };
    }

    const names = name.split(" ");

    return {
      first_name: names[0],
      last_name: names[names.length - 1],
    };
  }

  private getPayWithPointsTier(): number {
    return this.globalData.get(GlobalDataEnum.LANDING_PAGE).complimentaryOrRedeem() ?
      this.pointsCashShareService.pointsCashShare.value : 0;
  }

  private getStateValue(checkoutForm: FormGroup): string {
    return checkoutForm.controls.state?.value?.code ?? "";
  }
}
