import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { HotelEditSearchDialogComponent } from "./hotel-edit-search-dialog.component";
import {
  HotelEditSearchFormModule,
} from "booking-app-v2/shared/components/hotel-edit-search-form/hotel-edit-search-form.module";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";

@NgModule({
  declarations: [ HotelEditSearchDialogComponent ],
  imports: [
    SharedModule,
    HotelEditSearchFormModule,
  ],
  exports: [ HotelEditSearchDialogComponent ],
  providers: [ DialogService ],
  entryComponents: [ HotelEditSearchDialogComponent ],
})
export class HotelEditSearchDialogModule {}
