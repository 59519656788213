import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "roundDownNumber",
  pure: false,
})
export class RoundDownNumberPipe implements PipeTransform {

  transform(value: number): number {
    return Math.floor(value);
  }
}
