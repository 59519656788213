import { HotelRawPrice} from "../types";
import { RoomPrice } from "../models";

export class ComplimentaryNightsDecorator {

  static decorate(price: RoomPrice, rawPrice: HotelRawPrice): void {
    price.original_lowest_price_per_room_per_night = rawPrice.base_cash_payment;
    price.original_lowest_points_per_room_per_night = rawPrice.base_points_payment;
  }
}
