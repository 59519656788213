import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, filter, Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { HotelDestination } from "booking-app-v2/hotels/models";
import { DestinationService } from "booking-app-v2/shared/services/destination.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { UserActionService } from "booking-app-v2/shared/services/user-action.service";
import { GlobalDataEnum, USER_ACTION_TYPE, UserAction } from "booking-app-v2/shared/types";

@Component({
  selector: "hotel-destination-search",
  template: `
    <div class="hotel-destination-search-container" [formGroup]="parentForm">
      <input hidden autofocus />
      <input
        class="search-input"
        [class.invalid]="isDestinationFieldInvalid()"
        (input)="handleInput($event)"
        [placeholder]="placeHolder"
        (focus)="searchFocus()"
        (focusout)="searchFocusOut()"
        (clickOutside)="closeDestinationSearch()"
        [attr.aria-label]="inputAriaLabel"
        [value]="intermediateSearchValue || ''"
      />

      <ul *ngIf="(searchInProgress || !hasHotelDestinations()) && searchStatusMsg" class="dropdown-container no-items">
        <li class="no-item">{{ searchStatusMsg | translate }}</li>
      </ul>

      <ul *ngIf="hasHotelDestinations() && !searchInProgress" class="dropdown-container">
        <div class="destination-group-heading" *ngIf="showSearchedDestinations">You Previously Searched For</div>
        <ng-container *ngFor="let destination of hotelDestinations">
          <li
            (click)="selectHotelDestination(destination)"
            tabindex="0"
            (keyup.enter)="selectHotelDestination(destination)"
            class="item"
            [attr.aria-label]="'Select ' + destination.label"
            *ngIf="showSearchedDestinations"
          >
            {{ destination.label }}
          </li>
        </ng-container>
        <div
          class="destination-group-heading destinations"
          *ngIf="hasAtLeastOneDestination && !showSearchedDestinations"
        >
          Destinations
        </div>
        <ng-container *ngFor="let destination of hotelDestinations">
          <li
            (click)="selectHotelDestination(destination)"
            tabindex="0"
            (keyup.enter)="selectHotelDestination(destination)"
            class="item"
            [attr.aria-label]="'Select ' + destination.label"
            *ngIf="destination.value.type !== 'hotel' && !showSearchedDestinations"
          >
            {{ destination.label }}
          </li>
        </ng-container>
        <div class="destination-group-heading hotels" *ngIf="hasAtLeastOneHotel && !showSearchedDestinations">
          Hotels
        </div>
        <ng-container *ngFor="let destination of hotelDestinations">
          <li
            (click)="selectHotelDestination(destination)"
            tabindex="0"
            (keyup.enter)="selectHotelDestination(destination)"
            class="item"
            [attr.aria-label]="'Select ' + destination.label"
            *ngIf="destination.value.type === 'hotel' && !showSearchedDestinations"
          >
            {{ destination.label }}
          </li>
        </ng-container>
      </ul>

      <div class="dirtyMsg tooltips" *ngIf="isDestinationFieldInvalid()">
        <span role="alert">{{ "wl.please_fill_in_a_destination" | translate }}</span>
      </div>
    </div>
  `,
})
export class HotelDestinationSearchComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() placeHolder: string;
  @Input() inputAriaLabel: string;

  hotelDestinations: HotelDestination[] = [];
  selectedHotelDestination: HotelDestination;
  intermediateSearchValue: string;
  destinationFormControl: AbstractControl;
  searchTerm$ = new Subject<string>();
  searchInProgress: boolean = false;
  searchStatusMsg: string = null;
  hasAtLeastOneDestination: boolean;
  hasAtLeastOneHotel: boolean;
  hotelDestinationFromGlobalData: HotelDestination;
  searchedHotelDestinations: HotelDestination[] = [];
  showSearchedDestinations: boolean = false;

  constructor(
    private destinationService: DestinationService,
    private translateService: TranslateService,
    public appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    protected userActionService: UserActionService,
  ) {
    this.placeHolder = this.placeHolder || "Destination";
    this.inputAriaLabel = this.inputAriaLabel || "Type and select a destination from the list";
    this.hotelDestinationFromGlobalData = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM)?.destination;
    this.initializeSearchTerm();
    if (this.appSettingsService.enableUserActionTracking) {
      this.initializeSearchedLocations();
    }
  }

  ngOnInit() {
    this.destinationFormControl = this.parentForm.controls.destination;
    if (this.hotelDestinationFromGlobalData) {
      this.selectHotelDestination(this.hotelDestinationFromGlobalData);
    }
  }

  handleInput(e: Event) {
    this.intermediateSearchValue = (e.target as HTMLInputElement).value;
    this.searchTerm$.next((e.target as HTMLInputElement).value);
  }

  closeDestinationSearch(): void {
    this.parentForm.controls.destination.markAsPristine();
    this.hotelDestinations = [];
    this.searchStatusMsg = null;
    this.showSearchedDestinations = false;
  }

  selectHotelDestination(selectedHotelDestination: HotelDestination): void {
    this.closeDestinationSearch();
    this.selectedHotelDestination = selectedHotelDestination;
    this.intermediateSearchValue = this.selectedHotelDestination.label;
    this.destinationFormControl.setValue(this.selectedHotelDestination);
  }

  searchFocus(): void {
    this.searchStatusMsg = this.translateService.instant("e.g. city or region");
    this.parentForm.controls.destination.markAsPristine();
    if (this.selectedHotelDestination) {
      this.intermediateSearchValue = null;
      this.destinationFormControl.setValue(null);
    }
    if (this.appSettingsService.enableUserActionTracking && this.searchedHotelDestinations.length > 0) {
      this.showSearchedDestinations = true;
      this.hotelDestinations = this.searchedHotelDestinations;
    }
  }

  searchFocusOut(): void {
    this.intermediateSearchValue = this.selectedHotelDestination?.label;
    if (!this.selectHotelDestination) {
      this.destinationFormControl.setValue(null);
    } else {
      this.destinationFormControl.setValue(this.selectedHotelDestination);
    }
  }

  hasHotelDestinations(): boolean {
    return this.hotelDestinations.length > 0;
  }

  isDestinationFieldInvalid(): boolean {
    return (
      this.parentForm.controls.destination.errors?.required &&
      this.parentForm.controls.destination.dirty &&
      !this.selectedHotelDestination
    );
  }

  private initializeSearchTerm(): void {
    this.searchTerm$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((term) => term.length > 1),
      )
      .subscribe((term) => {
        this.fetchHotelDestinations(term);
      });
  }

  private fetchHotelDestinations(term: string): void {
    this.searchInProgress = true;
    this.showSearchedDestinations = false;
    this.searchStatusMsg = this.translateService.instant("Searching ...");
    this.destinationService.getHotelDestinations(term).subscribe((hotelDestinations: HotelDestination[]) => {
      this.searchInProgress = false;
      if (hotelDestinations.length > 0) {
        this.handleSearchHasLocations(hotelDestinations);
      } else {
        this.handleSearchHasNoLocations();
      }
    });
  }

  private handleSearchHasNoLocations(): void {
    this.searchStatusMsg = this.translateService.instant("No matching city or region");
    this.hotelDestinations = [];
  }

  private handleSearchHasLocations(hotelDestinations: HotelDestination[]): void {
    this.hotelDestinations =
      this.appSettingsService.blacklistedDestinations.length > 0
        ? this.handleBlackListedDestinations(hotelDestinations)
        : hotelDestinations;
    this.hasAtLeastOneDestination =
      this.hotelDestinations.filter((destination) => destination.value.type !== "hotel").length > 0;
    this.hasAtLeastOneHotel =
      this.hotelDestinations.filter((destination) => destination.value.type === "hotel").length > 0;
  }

  private handleBlackListedDestinations(hotelDestinationArr: HotelDestination[]): HotelDestination[] {
    const filteredDestinations: HotelDestination[] = hotelDestinationArr.filter(
      (destination) => !this.appSettingsService.blacklistedDestinations.includes(destination.value.destination_id),
    );
    return filteredDestinations;
  }

  private initializeSearchedLocations(): void {
    this.userActionService.getUserAction(USER_ACTION_TYPE.DESTINATION).subscribe((resultsArr: UserAction[]) => {
      if (resultsArr.length > 0) {
        this.searchedHotelDestinations = resultsArr.map(
          (result) => new HotelDestination(this.appSettingsService, this.globalData, result.custom_data),
        );
      }
    });
  }

  // TODO: Need to migrate this logic to V2. Task added in backlog
  // private handleAnalytics(query: string): void {
  //   if (query.length > 2) {
  //     this.$analytics.eventTrack("autosuggest_Destination", {
  //       category: "destination",
  //       label: query,
  //     });
  //   }
  // }
}
