import { NgModule } from "@angular/core";
import { ComplimentaryBannerComponent } from "./complimentary-banner.component";
import { SharedModule } from "booking-app-v2/shared/shared.module";

@NgModule({
  declarations: [ComplimentaryBannerComponent],
  imports: [SharedModule],
  exports: [ComplimentaryBannerComponent],
})
export class ComplimentaryBannerModule {}
