import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { FormatNumberUtil } from "booking-app-v2/shared/utils";
import { GlobalDataEnum, RawPrice } from "booking-app-v2/shared/types";
import { PointsPartner, Price } from "booking-app-v2/shared/models";
import { RoomPrice } from "booking-app-v2/hotels/models";
import { HotelRawPrice } from "booking-app-v2/hotels/types";
import { FlightRawResponse } from "booking-app-v2/flights/types";
import { CarRawResponse } from "booking-app-v2/cars/types";
import { CarPrice } from "booking-app-v2/cars/models";

@Injectable({
  providedIn: "root",
})
export class PayWithPointsCashService {
  private readonly pointsPartner: PointsPartner;

  constructor(
    private globalData: GlobalData,
    private translateService: TranslateService,
    private appSettingsService: AppSettingsService,
    private currenciesService: CurrenciesService,
    private pointsCashShareService: PointsCashShareService,
  ) {
    this.pointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
  }

  isPayByPointsAvailable(rawPrice: HotelRawPrice | CarRawResponse): boolean {
    return rawPrice.max_points_payment && rawPrice.max_points_payment > 0;
  }

  isPayByCashAvailable(rawPrice: HotelRawPrice | CarRawResponse): boolean {
    return (
      (rawPrice.max_cash_payment && rawPrice.max_cash_payment > 0) ||
      (rawPrice.max_cash_payment_by_tier && rawPrice.max_cash_payment === 0)
    );
  }

  pointsPayment(rawPrice: HotelRawPrice | FlightRawResponse | CarRawResponse): number {
    // duplicate array for invertedPointsCashSlider condition
    let pointsDiscountTier: number[] = [...rawPrice.points_non_fixed_discounts_by_tier];
    if (this.appSettingsService.invertedPointsCashSlider) {
      pointsDiscountTier = pointsDiscountTier.reverse();
    }
    const nonFixedDiscount = pointsDiscountTier[this.pointsCashShareService.pointsCashShare.value];
    return this.pointsToPay(
      rawPrice.base_points_payment,
      nonFixedDiscount,
      rawPrice.points_fixed_discount,
    );
  }

  pointsPaymentPerNight(price: RoomPrice | CarPrice, duration: number): number {
    return Math.ceil(price.points_payment / duration);
  }

  originalPointsPayment(rawPrice: HotelRawPrice | CarRawResponse): number {
    return this.pointsToPay(rawPrice.base_points_payment);
  }

  originalPointsPaymentPerNight(price: RoomPrice | CarPrice, duration: number): number {
    return Math.ceil(price.original_points_payment / duration);
  }

  minPoint(rawPrice: RawPrice): number {
    const tiersLength: number = this.pointsPartner.pointsPaymentTiersByTravelType.length;
    let minPoints: number = (rawPrice.max_points_payment *
                            this.pointsPartner.pointsPaymentTiersByTravelType[tiersLength - 1]);

    minPoints = Math.max(this.pointsPartner.min_points, minPoints);
    return this.toNearest(minPoints);
  }

  getCalculatedCash(rawPrice: HotelRawPrice | FlightRawResponse | CarRawResponse): number {
    if (this.useMinPoints(rawPrice)) {
      return this.cashToPayAfterMinPointsValue(rawPrice.max_cash_payment);
    } else {
      // duplicate array for invertedPointsCashSlider condition
      let cashDiscountTier: number[] = [...rawPrice.cash_non_fixed_discounts_by_tier];
      if (this.appSettingsService.invertedPointsCashSlider) {
        cashDiscountTier = cashDiscountTier.reverse();
      }
      const nonFixedDiscount = cashDiscountTier[this.pointsCashShareService.pointsCashShare.value];
      return this.cashToPay(
        rawPrice.base_cash_payment,
        nonFixedDiscount,
        rawPrice.cash_fixed_discount,
      );
    }
  }

  getCalculatedBaseCash(rawPrice: HotelRawPrice | CarRawResponse): number {
    if (this.useMinPoints(rawPrice)) {
      return this.cashToPayAfterMinPointsValue(rawPrice.base_cash_payment);
    } else {
      return this.cashToPay(rawPrice.base_cash_payment);
    }
  }

  cashPayment(calculatedCash: number): number {
    return calculatedCash > 0 ? this.formatCurrency(calculatedCash) : 0;
  }

  originalCashPayment(calculatedCash: number, calculatedBaseCash: number): number {
    return calculatedCash > 0 ? this.formatCurrency(calculatedBaseCash) : 0;
  }

  cashPaymentPerNight(calculatedCash: number, price: RoomPrice | CarPrice, duration: number): number {
    return calculatedCash > 0 ? this.formatCurrency(price.cash_payment / duration) : 0;
  }

  originalCashPaymentPerNight(calculatedCash: number, price: RoomPrice | CarPrice, duration: number): number {
    return calculatedCash > 0 ? this.formatCurrency(price.original_cash_payment / duration) : 0;
  }

  earnOnBurnValue(calculatedCash: number, rawPrice: HotelRawPrice): number {
    return calculatedCash > 0 ? this.earnOnRedeemPoints(rawPrice) : 0;
  }

  getPriceBeforeDiscount(price: Price): string {
    const originalPointsPayment: number = this.pointsToPay(price.base_points_payment);
    let originalCashPayment: number;
    let priceBeforeDiscount: string = "";

    if (this.useMinPoints(price)) {
      originalCashPayment = this.cashToPayAfterMinPointsValue(price.base_cash_payment);
    } else {
      originalCashPayment = this.cashToPay(price.base_cash_payment);
    }

    if (price && originalPointsPayment > 0) {
      const pointsPayment: string = FormatNumberUtil.roundNumber(
        originalPointsPayment,
        this.globalData.get(GlobalDataEnum.SELECTED_LOCALE),
        0,
      );
      priceBeforeDiscount = `${pointsPayment} ${this.translateService.instant(this.pointsPartner.currency_short)}`;
    }

    if (price && originalCashPayment > 0) {
      if (originalPointsPayment > 0) {
        priceBeforeDiscount = `${priceBeforeDiscount} + `;
      }
      const cashPayment: string = FormatNumberUtil.roundNumber(
        originalCashPayment,
        this.globalData.get(GlobalDataEnum.SELECTED_LOCALE),
        2,
      );
      const selectedCurrencyCode: string = this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).code;
      priceBeforeDiscount = `${priceBeforeDiscount} ${selectedCurrencyCode} ${cashPayment}`;
    }

    return priceBeforeDiscount;
  }

  pointsToPay(basePoints: number, nonFixedDiscountPoints: number = 0, fixedDiscountPoints: number = 0): number {
    if (!this.pointsPartner || !this.pointsPartner.pointsPaymentTiersByTravelType) {
      return this.toNearest(basePoints - nonFixedDiscountPoints - fixedDiscountPoints);
    }
    if (!this.pointsPartner.min_points) {
      return this.toNearest(
        this.calculatedPoints(basePoints, nonFixedDiscountPoints, fixedDiscountPoints),
      );
    }
    return this.toNearest(
      Math.max(
        this.calculatedPoints(basePoints, nonFixedDiscountPoints, fixedDiscountPoints),
        this.pointsPartner.min_points,
      ),
    );
  }

  cashToPay(baseCash: number, nonFixedDiscountCash: number = 0, fixedDiscountCash: number = 0): number {
    let maxCash: number;
    if (this.pointsPartner?.cashPaymentTiersByTravelType && this.pointsCashShareService.pointsCashShare) {
      maxCash = this.calculatedCash(baseCash, nonFixedDiscountCash, fixedDiscountCash);
    } else {
      maxCash = baseCash - nonFixedDiscountCash - fixedDiscountCash;
    }
    return this.toTwoDecimalPlace(
      this.currenciesService.convertFromUsd(maxCash),
    );
  }

  hasDiscountedPrice(priceInfo: Price): boolean {
    return priceInfo && (priceInfo.base_points_payment !== priceInfo.max_points_payment ||
      priceInfo.base_cash_payment !== priceInfo.max_cash_payment);
  }

  private toNearest(value: number): number {
    const rounding: number = this.appSettingsService.payWithPointsSettings.roundToNearest;
    return Math.ceil(value / rounding) * rounding;
  }

  private toTwoDecimalPlace(value: number): number {
    return parseFloat(this.currenciesService.adjustDecimals(value, 2));
  }

  private formatCurrency(value: number): number {
    if (this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).decimalPlace === 0) {
      return Math.ceil(value);
    } else {
      return this.toTwoDecimalPlace(value);
    }
  }

  private useMinPoints(price: Price | RawPrice): boolean {
    return this.calculatedPoints(price.max_points_payment) < this.pointsPartner.min_points;
  }

  private cashToPayAfterMinPointsValue(maxCash: number): number {
    return this.currenciesService.convertFromUsd(maxCash - this.pointsPartner.min_points_cash_value_in_usd);
  }

  private earnOnRedeemPoints(rawPrice: HotelRawPrice): number {
    if (this.useMinPoints(rawPrice)) {
      return rawPrice.max_earn_on_redeem_points / this.currenciesService.convertFromUsd(rawPrice.max_cash_payment) *
        this.cashToPayAfterMinPointsValue(rawPrice.max_cash_payment);
    } else {
      return Math.ceil(
        rawPrice.max_earn_on_redeem_points *
        this.pointsPartner.cashPaymentTiersByTravelType[this.pointsCashShareService.pointsCashShare.value],
      );
    }
  }

  private calculatedPoints(
    basePoints: number,
    nonFixedDiscountPoints: number = 0,
    fixedDiscountPoints: number = 0): number {
    return (
      basePoints *
      this.pointsPartner.pointsPaymentTiersByTravelType[this.pointsCashShareService.pointsCashShare.value]
    ) - nonFixedDiscountPoints - fixedDiscountPoints;
  }

  private calculatedCash(
    baseCash: number,
    nonFixedDiscountCash: number = 0,
    fixedDiscountCash: number = 0,
  ): number {
    return (
      baseCash *
      this.pointsPartner.cashPaymentTiersByTravelType[this.pointsCashShareService.pointsCashShare.value]
    ) - nonFixedDiscountCash - fixedDiscountCash;
  }
}
