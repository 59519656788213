import { Injectable } from "@angular/core";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { BaseUrlBuilder } from "booking-app-v2/shared/services/url-builder/base-url-builder";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { CarCommonQueryParams } from "booking-app-v2/cars/types";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { CarSearchForm } from "booking-app-v2/cars/models";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { Params, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class CarsUrlBuilderService extends BaseUrlBuilder {
  constructor(
    private router: Router,
    protected globalData: GlobalData,
    protected appSettingsService: AppSettingsService,
    protected pointsCashShareService: PointsCashShareService,
  ) {
    super(globalData, appSettingsService, pointsCashShareService);
  }

  buildCommonSearchUrlParams(): CarCommonQueryParams {
    const carSearchForm: CarSearchForm = this.globalData.get(GlobalDataEnum.CAR_SEARCH_FORM);
    return {
      pickup_location: carSearchForm.pickupLocation.id,
      pickup_location_name: carSearchForm.pickupLocation.value,
      return_location: carSearchForm.returnLocation.id,
      return_location_name: carSearchForm.returnLocation.value,
      location_type: carSearchForm.pickupLocation.type,
      pickup_time: carSearchForm.pickupDateTime.format(CarSearchForm.CAR_SEARCH_DATE_FORMAT),
      return_time: carSearchForm.returnDateTime.format(CarSearchForm.CAR_SEARCH_DATE_FORMAT),
      ...super.commonQueryParams(),
    };
  }

  buildSearchUrl(): CarCommonQueryParams {
    return { ...this.buildCommonSearchUrlParams() };
  }

  getCarDetailsUrl(bookingKey: string): string {
    return `/cars/details/${bookingKey}`;
  }

  buildResultsPageDataFromQueryParams(params: Params): CarCommonQueryParams {
    return params as CarCommonQueryParams;
  }

  redirectToCarResultsPage() {
    this.router.navigate([`/cars/results`], {
      queryParams: this.buildSearchUrl(),
    });
  }

  redirectToCarDetailsPage(bookingKey: string) {
    this.router.navigate([this.getCarDetailsUrl(bookingKey)], {
      queryParams: this.buildSearchUrl(),
    });
  }
}
