import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { HotelListItemMapButtonComponent } from "./hotel-list-item-map-button.component";

@NgModule({
  declarations: [ HotelListItemMapButtonComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ HotelListItemMapButtonComponent ],
  providers: [],
})
export class HotelListItemMapButtonModule {}
