import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";

@Component({
  selector: "invalid-credit-card-dialog",
  templateUrl: "/html/dialogs/card_number_invalid_v2",
})
export class InvalidCreditCardDialogComponent implements OnInit {
  title: string;
  errorMessage: string;

  constructor(
    private appSettingsService: AppSettingsService,
    public dialogRef: MatDialogRef<InvalidCreditCardDialogComponent>,
    ) {}

  ngOnInit() {
    const currentAppName = this.appSettingsService.tenant.toLowerCase();
    this.errorMessage = `wl.${currentAppName}.use_valid_${currentAppName}_card`;
    this.title = "wl.visa.non-visa_card";
  }

  close(): void {
    this.dialogRef.close();
  }
}
