import { Injectable } from "@angular/core";
import { Params, Router } from "@angular/router";

import { HotelDestination, HotelSearchForm } from "booking-app-v2/hotels/models";
import {
  HotelCheckoutPageQueryParamsData,
  HotelCommonQueryParams,
  HotelDetailsPageQueryParamsData,
  RawHotelDestination,
} from "booking-app-v2/hotels/types";
import { Currency, LandingPage, PointsPartner } from "booking-app-v2/shared/models";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { BaseUrlBuilder } from "booking-app-v2/shared/services/url-builder/base-url-builder";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { GlobalDataEnum, ProductType } from "booking-app-v2/shared/types";
import { TimeUtils } from "booking-app-v2/shared/utils";

@Injectable({
  providedIn: "root",
})
export class HotelsUrlBuilderService extends BaseUrlBuilder {
  constructor(
    private router: Router,
    private windowRefService: WindowRefService,
    protected globalData: GlobalData,
    protected appSettingsService: AppSettingsService,
    protected pointsCashShareService: PointsCashShareService,
  ) {
    super(
      globalData,
      appSettingsService,
      pointsCashShareService,
    );
  }

  buildCommonSearchUrlParams(): HotelCommonQueryParams {
    const hotelSearchForm: HotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    return {
      destination: hotelSearchForm.destination.label,
      destination_type: hotelSearchForm.destination.value.type,
      lat: hotelSearchForm.destination.value.lat,
      lng: hotelSearchForm.destination.value.lng,
      checkInDate: hotelSearchForm.checkInDate,
      checkOutDate: hotelSearchForm.checkOutDate,
      guests: hotelSearchForm.guests,
      selectedHotelId: this.getSelectedHotelId(hotelSearchForm),
      ...super.commonQueryParams(),
    };
  }

  buildSearchUrl(): HotelCommonQueryParams {
    return { ...this.buildCommonSearchUrlParams() };
  }

  buildResultsPageDataFromQueryParams(params: Params): HotelCommonQueryParams {
    return params as HotelCommonQueryParams;
  }

  buildUrlForHotelPriceCache(): string {
    const hotelSearchForm: HotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    const selectedCurrency: Currency = this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
    const landingPage: LandingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
    const pointsPartner: PointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);

    const url: string = `${hotelSearchForm.destination.value.destination_id}-${hotelSearchForm.checkInDate}-` +
      `${hotelSearchForm.checkOutDate}-${hotelSearchForm.guests}-${selectedCurrency.code}-` +
      `${landingPage.id}-${pointsPartner.id}`;
    return url;
  }

  buildUrlForHotels(destination: HotelDestination): string {
    const url: string = `hotels?destination_id=${destination.value.destination_id}` +
      `&destination_type=${destination.value.type}${this.getLatOrLong(destination, "lat")}` +
      `${this.getLatOrLong(destination, "lng")}`;
    return url;
  }

  buildUrlForPriceAndReviews(
    path: string,
    bookingKey: string,
    couponCode: string,
  ): string {
    const hotelSearchForm: HotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    const selectedCurrency: Currency = this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
    const landingPage: LandingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
    const pointsPartner: PointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
    const countryCode: string = this.globalData.get(GlobalDataEnum.SELECTED_COUNTRY_SITE).code;
    const dateFormat: string = "YYYY-MM-DD";

    const url: string = `${path}?destination_id=${hotelSearchForm.destination.value.destination_id}` +
      `&checkin=${TimeUtils.localiseAndFormat(hotelSearchForm.checkInDate, dateFormat)}` +
      `&checkout=${TimeUtils.localiseAndFormat(hotelSearchForm.checkOutDate, dateFormat)}` +
      `&lang=en_US&currency=${selectedCurrency.code}` +
      `&landing_page=${landingPage.url}` +
      `${this.getProductType()}&partner_id=${pointsPartner.id}` +
      `&country_code=${countryCode}` +
      `${this.getBookingKey(bookingKey)}` +
      `${this.getGuestsValue(hotelSearchForm)}` +
      `${this.getTrafficSource()}` +
      `${this.getSuppliers()}` +
      `${this.getCouponCode(couponCode)}`;
    return url;
  }

  buildCheckoutPageUrl(bookingKey: string): HotelCheckoutPageQueryParamsData {
    const hotelSearchForm: HotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    return {
      destinationId: hotelSearchForm.destination.value.destination_id,
      bookingKey,
      ...this.buildCommonSearchUrlParams(),
    };
  }

  buildDestinationFromUrl(): HotelDestination {
    const destinationIdFromQueryParam: string = this.windowRefService.getQueryParamFromWindow("destinationId");
    const hotelDestinationObjFromUrl: RawHotelDestination = {
      type: this.windowRefService.getQueryParamFromWindow("destination_type"),
      value: destinationIdFromQueryParam || this.windowRefService.getURIParamFromWindow(),
      lat: this.windowRefService.getQueryParamFromWindow("lat"),
      lng: this.windowRefService.getQueryParamFromWindow("lng"),
      term: this.windowRefService.getQueryParamFromWindow("destination"),
      hotel_id: this.windowRefService.getQueryParamFromWindow("selectedHotelId"),
    };
    return new HotelDestination(this.appSettingsService, this.globalData, hotelDestinationObjFromUrl);
  }

  buildDetailsPageUrl(): HotelDetailsPageQueryParamsData {
    const hotelSearchForm: HotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    return {
      destinationId: hotelSearchForm.destination.value.destination_id,
      ...this.buildCommonSearchUrlParams(),
    };
  }

  redirectToHotelDetailsPage(hotelId: string) {
    this.router.navigate([this.getHotelDetailsUrl(hotelId)], {
      queryParams: this.buildDetailsPageUrl(),
    });
  }

  getHotelDetailsUrl(hotelId: string): string {
    return `/hotels/detail/${hotelId}`;
  }

  private getSelectedHotelId(hotelSearchForm: HotelSearchForm): string {
    return hotelSearchForm.destination.value.hotel_id
      ? hotelSearchForm.destination.value.hotel_id
      : undefined;
  }

  private getLatOrLong(destination: HotelDestination, type: string): string {
    const lat: string = destination.value.lat;
    const lng: string = destination.value.lng;

    if (type === "lat") {
      return lat && lat !== "0" ? `&lat=${lat}` : "";
    } else if (type === "lng") {
      return lng && lng !== "0" ? `&lng=${lng}` : "";
    }
  }

  private getProductType(): string {
    if (this.appSettingsService.useProductType) {
      const productTypeFromUrl = this.windowRefService.getQueryParamFromWindow<ProductType>("productType");
      const productType = productTypeFromUrl || this.globalData.get(GlobalDataEnum.PRODUCT_TYPE);
      return `&product_type=${this.globalData.productTypeAdapter(productType)}`;
    } else {
      return "";
    }
  }

  private getBookingKey(bookingKey: string): string {
    return bookingKey ? `&booking_key=${bookingKey}` : "";
  }

  private getGuestsValue(hotelSearchForm: HotelSearchForm): string {
    return hotelSearchForm.guests ? `&guests=${hotelSearchForm.guests}` : "";
  }

  private getTrafficSource(): string {
    const source: string = this.windowRefService.getQueryParamFromWindow("trafficSource");
    return source ? `&traffic_source=${source}` : "";
  }

  private getSuppliers(): string {
    const suppliers: string = this.windowRefService.getQueryParamFromWindow("suppliers");
    return suppliers ? `&suppliers=${suppliers}` : "";
  }

  private getCouponCode(couponCode: string): string {
    return couponCode ? `&coupon_code=${couponCode}` : "";
  }
}
