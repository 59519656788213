import { Injectable } from "@angular/core";
import { delayWhen, map, Observable, retryWhen, Subject, tap, timer } from "rxjs";
import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";

@Injectable({
  providedIn: "root",
})
export class ApiPollingService {

  static MAX_RETRIES = 5;

  constructor(
    private apiDataService: ApiDataService,
  ) { }

  poll(baseUrl: string, params: object, notify$?: Subject<any>): Observable<any> {
    const url = `${baseUrl}?${this.convertJSONToQueryString(params)}`;
    let failures: number = 0;
    let tries: number = 0;

    return this.apiDataService.get(url).pipe(
      tap((data: any) => {
        if (!data.completed) {
          notify$?.next(data);
        }
      }),
      map((data: any) => {
        if (!data.completed) {
          throw {type: "success", data};
        }
        return data;
      }),
      retryWhen((pollResult: any) => {
        return pollResult.pipe(
          map((thrownObject: any) => {
            if (thrownObject.type === "success") {
              return thrownObject; // incomplete data, poll again
            }
            if (this.shouldRetry(failures++)) {
              return thrownObject; // request error, try to poll again but increment failures count
            }
            throw thrownObject; // max retries reached, stop poll and throw the error
          }),
          delayWhen(() => timer(this.pollingInterval(tries++))),
        );
      }),
    );
  }

  private shouldRetry(failures: number): boolean {
    return failures <= ApiPollingService.MAX_RETRIES;
  }

  private pollingInterval(tries: number): number {
    return tries > 3 ? 3000 : 1000;
  }

  private convertJSONToQueryString = (obj: any): string => {
    return Object.keys(obj)
      .filter((key: string) => obj[key] != null)
      .map((key: string) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join("&");
  }
}
