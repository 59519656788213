import { Directive, ElementRef, HostListener, Inject, Input, OnDestroy, Renderer2 } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";

@Directive({
  selector: "[tooltip]",
})

export class TooltipDirective implements OnDestroy {
  @Input("tooltip") private tooltipContent: string;
  @Input() private tooltipPosition: string;
  @Input() private offset: number = 10;
  @Input() private delay: number = 200;
  private tooltip: HTMLElement;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private el: ElementRef,
    private renderer: Renderer2,
    private windowRefService: WindowRefService,
  ) {}

  ngOnDestroy() {
    if (this.tooltip) {
      this.hide();
    }
  }

  @HostListener("mouseenter")
  onMouseEnter() {
    if (!this.tooltip) {
      this.show();
    }
  }
  @HostListener("mouseleave")
  onMouseLeave() {
    if (this.tooltip) {
      this.hide();
    }
  }

  private show(): void {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, "ng-tooltip-show");
  }

  private hide(): void {
    this.renderer.removeClass(this.tooltip, "ng-tooltip-show");
    window.setTimeout(() => {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }, this.delay);
  }

  private create(): void {
    this.createTooltip();
    this.setTooltipClasses();
    this.setTooltipDelay();
  }

  private createTooltip(): void {
    this.tooltip = this.renderer.createElement("span");
    this.renderer.appendChild(
      this.tooltip,
      this.renderer.createText(this.tooltipContent),
    );
    this.renderer.appendChild(document.body, this.tooltip);
  }

  private setTooltipClasses(): void {
    this.renderer.addClass(this.tooltip, "ng-tooltip");
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.tooltipPosition}`);
  }

  private setTooltipDelay(): void {
    this.renderer.setStyle(this.tooltip, "-webkit-transition", `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, "-moz-transition", `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, "-o-transition", `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, "transition", `opacity ${this.delay}ms`);
  }

  private setPosition(): void {
    const hostPos: ClientRect = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos: ClientRect = this.tooltip.getBoundingClientRect();
    const scrollPos: number = this.windowRefService.nativeWindow.pageYOffset || this.document.documentElement.scrollTop
                              || this.document.body.scrollTop || 0;
    let top: number;
    let left: number;

    if (this.tooltipPosition === "top") {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.tooltipPosition === "bottom") {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.tooltipPosition === "left") {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.tooltipPosition === "right") {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this.tooltip, "top", `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, "left", `${left}px`);
  }

}
