import { NgModule } from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";

import { DayjsDateAdapter } from "./dayjs-date-adapter";
import { MAT_DAYJS_DATE_FORMATS } from "./dayjs-date-formats";

@NgModule({
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: DayjsDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class MatDayjsDateModule { }
