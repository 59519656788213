import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";

import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { HotelRoomRawResponse } from "booking-app-v2/hotels/types";
import { Hotel } from "../models";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { HotelUtilService } from "./hotel-util.service";

@Injectable({
  providedIn: "root",
})
export class DetailsService {
  constructor(
    private apiDataService: ApiDataService,
    private globalData: GlobalData,
    private hotelUtilService: HotelUtilService,
  ) { }

  getHotelRooms(hotelId: string): Observable<HotelRoomRawResponse> {
    return this.apiDataService.get(this.hotelUtilService.getUrlForPriceAndReviews(`hotels/${hotelId}/price`))
      .pipe(map((hotelRoomRawResponse: HotelRoomRawResponse) => {
        if (!!hotelRoomRawResponse) {
          this.globalData.set(GlobalDataEnum.TAX_INCLUSIVE, !!hotelRoomRawResponse.tax_inclusive);
        }
        return hotelRoomRawResponse;
      }));
  }

  getHotel(hotelId: string): Observable<Hotel> {
    return this.apiDataService.get(`hotels/${hotelId}`)
      .pipe(map((hotelResponse: Hotel) => {
        return hotelResponse;
      }));
  }
}
