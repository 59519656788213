import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { BottomSheetWrapperComponent } from "./bottom-sheet-wrapper.component";

@NgModule({
  declarations: [BottomSheetWrapperComponent],
  imports: [
    SharedModule,
  ],
  exports: [BottomSheetWrapperComponent],
  providers: [],
})
export class BottomSheetWrapperModule {}
