import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "input[type='number'][numberInputMaxLength]",
})
export class NumberInputMaxLengthDirective {
  @Input() numberInputMaxLength: number;
  private disallowedKey = ["+", "-", "e"];

  constructor(private el: ElementRef) { }

  @HostListener("keypress", ["$event"])
  onInput($event: KeyboardEvent) {
    if (this.disallowedKey.indexOf($event.key) >= 0) {
      $event.preventDefault();
    }

    const value: string = this.el.nativeElement.value;
    if (value.length > this.numberInputMaxLength - 1) {
      $event.preventDefault();
    }
  }
}
