import { Injectable } from "@angular/core";
import { Observable, of, ReplaySubject, Subject } from "rxjs";

import { BaseStep } from "./base-step";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import {
  BookingTransactionData,
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  FetchBookingTransactionDataResult,
} from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class FetchBookingTransactionDataService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.FETCH_BOOKING_TRANSACTION_DATA;

  constructor(
    private windowRefService: WindowRefService,
    private apiDataService: ApiDataService,
  ) {}

  process(results: CheckoutResults): FetchBookingTransactionDataResult {
    const transactionId: string = this.windowRefService.getQueryParamFromWindow("transactionId");

    const transactionData: Observable<BookingTransactionData> = transactionId
      ? this.fetchBookingTransactionData(transactionId)
      : of(undefined);

    return { transactionId, transactionData };
  }

  private fetchBookingTransactionData(transactionId: string): Observable<BookingTransactionData> {
    const result: Subject<BookingTransactionData> = new ReplaySubject<BookingTransactionData>(1);

    this.apiDataService
      .get(`transactions/${transactionId}`)
      .subscribe((transactionData: BookingTransactionData) => {
        result.next(transactionData);
      });

    return result.asObservable();
  }

}
