import { Injectable } from "@angular/core";
import { BaseStep } from "./base-step";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  StartPaymentTimerResult,
} from "booking-app-v2/shared/types";
import { PaymentTimerService } from "booking-app-v2/shared/services/payment-timer.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";

@Injectable({
  providedIn: "root",
})
export class StartPaymentTimerService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.START_PAYMENT_TIMER;

  constructor(
    private paymentTimerService: PaymentTimerService,
    private windowRefService: WindowRefService,
  ) {}

  process(results: CheckoutResults): StartPaymentTimerResult {
    const transactionId: string = this.windowRefService.getQueryParamFromWindow("transactionId");

    if (!transactionId) {
      return;
    }

    this.paymentTimerService.paymentInProgressTimer();
  }
}
