import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { ContactForm } from "booking-app-v2/shared/types";

@Injectable()
export class ContactUsService {
  constructor(
    private apiDataService: ApiDataService,
  ) { }

  submitContactUsForm(contactForm: ContactForm): Observable<ContactForm> {
    return this.apiDataService.post("tickets", contactForm);
  }
}
