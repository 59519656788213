import { Directive, Inject, Input } from "@angular/core";
import { MAT_DATE_RANGE_SELECTION_STRATEGY } from "@angular/material/datepicker";
import { Dayjs } from "dayjs";
import { MaxRangeSelectionStrategyService } from "../services";

@Directive({
  selector: "[maxRange]",
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: MaxRangeSelectionStrategyService,
    },
  ],
})
export class MaxRangeDirective {
  constructor(
    @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
    private maxRangeStrategy: MaxRangeSelectionStrategyService<Dayjs>,
  ) {}

  @Input() set maxRange(value: number) {
    if (value === 0) {
      return;
    }
    this.maxRangeStrategy.delta = value;
  }
}
