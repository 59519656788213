import { ElementRef, Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Subject, Subscription } from "rxjs";

import { Currency, Locale, MapboxState } from "booking-app-v2/shared/models";
import { Hotel, HotelSearchForm } from "booking-app-v2/hotels/models";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import { LocaleService } from "booking-app-v2/shared/services/initializers/locale.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import {
  CURRENT_PAGE,
  PRODUCT_TYPE,
  GlobalDataEnum,
  MAPBOX_STATE_VIEW,
  MAPBOX_STATE_VIEW_MODE,
  MapboxStateViewMode,
} from "booking-app-v2/shared/types";
import { GeoJSONSource, LngLatLike, Map, Popup } from "mapbox-gl";
import { Point } from "geojson";

declare var mapboxgl: any;

const OPEN_POPUP_EVENT = "openPopup";
const FOCUS_ZOOM_LEVEL = 18;
const CLUSTER_CLICK_ANIMATION_DURATION = 500;

@Injectable({
  providedIn: "root",
})

export class MapboxService {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  userHasTouch: boolean = false;
  hoveredHotelChanged: Subject<void> = new Subject<void>();
  selectedHotelChanged: Subject<void> = new Subject<void>();

  private addressPoints: any;
  private map: Map;
  private currencyListener: Subscription;
  private localeListener: Subscription;
  private hotelData: Hotel[];
  private currentLocale: Locale;
  private callbackButtonElem: HTMLElement;
  private mapboxState: MapboxState;
  private popupElement: Node;
  private tooltipElement: Node;

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private pageDiscoveryService: PageDiscoveryService,
    private localeService: LocaleService,
    private currenciesService: CurrenciesService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.mapboxState = new MapboxState(this.appSettingsService, this.globalData);
    this.addressPoints = {
      type: "FeatureCollection",
      features: [],
    };
    this.hoveredHotelChanged.next();
    this.selectedHotelChanged.next();
  }

  setViewMode(viewMode: MapboxStateViewMode): void {
    // Reject if viewMode is not valid to prevent unwanted side effects
    if (!this.mapboxState.isValidViewMode(viewMode)) {
      return;
    }

    if (viewMode === MAPBOX_STATE_VIEW_MODE.Map) {
      this.resize();
    }

    this.mapboxState.viewMode = viewMode;

    if (this.mapboxState.view === MAPBOX_STATE_VIEW.ResultPage) {
      this.mapboxState.savedViewMode = viewMode;
    }
  }

  toggleViewMode(event?): void {
    event?.preventDefault();

    if (this.mapboxState.viewMode === MAPBOX_STATE_VIEW_MODE.List) {
      if (this.isHotelResultStillPolling()) { return; }
      this.setViewMode(MAPBOX_STATE_VIEW_MODE.Map);
      setTimeout(() => this.setupMapView("mapbox-search-map"), 0);
    } else if (this.mapboxState.viewMode === MAPBOX_STATE_VIEW_MODE.Map) {
      // Remove elements inside mapbox-serach-map because we are redrawing
      // for the next toggle
      this.document.getElementById("mapbox-search-map").innerHTML = "";

      this.setViewMode(MAPBOX_STATE_VIEW_MODE.List);

      // focus on the callback button if navigated using keyboard
      if (this.callbackButtonElem) {
        setTimeout(() => {
          this.callbackButtonElem.focus();
        }, 0);
      }
    }
  }

  toggleMobileViewMode(): void {
    this.setupMapView("mapbox-search-map-mobile");
  }

  openPopup(coordinates: LngLatLike, hotelId: string,
            zoomLevel?: number, polygonID?: string): void {

    this.flyMapTo(coordinates, zoomLevel || this.map.getZoom());

    const selectPopup = new mapboxgl.Popup();
    selectPopup.remove();

    this.mapboxState.selectedHotel = this.getHotelFromId(hotelId);
    this.selectedHotelChanged.next();

    selectPopup.setLngLat(coordinates)
      .setDOMContent(this.popupElement)
      .addTo(this.map);

    selectPopup.on("close", (e) => {
      this.mapboxState.selectedHotel = null;
      this.selectedHotelChanged.next();
    });

    if (typeof polygonID !== "undefined" && this.isEarn()) {
      this.updateFeatureStates(polygonID, true);

      selectPopup.on("close", (e) => {
        this.updateFeatureStates(polygonID, false);
      });
    } else if (typeof polygonID === "undefined" && this.isEarn()) {
      this.map.once("load", () => {
        setTimeout(() => {
          this.queryFeatureAndHideState(selectPopup);
        }, 1500);
      });
    }
  }

  setupSingleHotelMap(coordinates: LngLatLike): void {
    this.map.on("load", () => {
      this.flyMapTo(coordinates, FOCUS_ZOOM_LEVEL);
      this.setupMapMarker(coordinates);
    });
  }

  setupMapMarker(coordinates: LngLatLike): void {
    // create a HTML element for each feature
    const el = this.document.createElement("div");
    el.className = "hotel-detail-marker";
    const marker = new mapboxgl.Marker(el)
      .setLngLat(coordinates)
      .addTo(this.map);
  }

  setupMapView(containerId: string): void {
    this.createMapboxglInstance(containerId);
    this.configureMap();
    this.map.on("load", () => {
      this.setupMapResources();
    });
  }

  // Get hotels data from ResultsComponent
  loadAddressPoints(addressPoints: Hotel[]): void {
    this.hotelData = addressPoints;
    this.setupAddressPointsFeatures(addressPoints);
  }

  // Update hotel data without removing sources / layers
  updateAddressPoints(addressPoints: Hotel[]): void {
    this.hotelData = addressPoints;
    this.removeAddressPointsFeatures();
    this.setupAddressPointsFeatures(addressPoints);
  }

  resize(): void {
    if (this.map) {
      // Asynchronously call resize on map object, to cater for map resizing bug when map is not loaded
      setTimeout(() => {
        this.map.resize();
      }, 1);
    }
  }

  focusOnHotel(hotel: Hotel): void {
    if (this.isHotelResultStillPolling()) {
      return;
    }

    this.toggleViewMode();
    const coordinates: LngLatLike = [hotel.longitude, hotel.latitude];
    setTimeout(() => {
      this.openPopup(coordinates, hotel.id, 18);
    }, 0);
  }

  reset(removeMapResources = true): void {
    this.setViewMode(MAPBOX_STATE_VIEW_MODE.List);

    if (removeMapResources) {
      this.removeMapResources();
    }

    this.removeAddressPointsFeatures();
  }

  resetAll(): void {
    // We can skip removing the resources, because Map#remove() will do that for us
    this.reset(false);
    this.removeMap();
  }

  setCallbackButtonElement(elem: HTMLElement): void {
    this.callbackButtonElem = elem;
  }

  setPopupTooltipElements(popupElement: ElementRef, tooltipElement: ElementRef): void {
    this.popupElement = popupElement?.nativeElement;
    this.tooltipElement = tooltipElement?.nativeElement;
  }

  get viewMode(): MapboxStateViewMode {
    return this.mapboxState.viewMode;
  }

  get selectedHotel(): Hotel {
    return this.mapboxState.selectedHotel;
  }

  get hoveredHotel(): Hotel {
    return this.mapboxState.hoveredHotel;
  }

  private queryFeatureAndHideState(selectPopup: any): void {
    const features = this.map.queryRenderedFeatures(null, { layers: ["hotelsLayer"]});
    for (const feature of features) {
      this.map.setFeatureState(feature, {
        click: true,
      });
    }

    selectPopup.on("close", (e) => {
      if (!this.map) { return; }
      for (const feature of features) {
        try {
          this.map.setFeatureState(feature, {
            click: false,
          });
        } catch (_) {
          break;
        }
      }
    });
  }

  private flyMapTo(coordinates: LngLatLike, zoomLevel?: number, duration?: number): void {
    this.map.flyTo({
      center: coordinates,
      around: coordinates,
      zoom: zoomLevel || FOCUS_ZOOM_LEVEL,
      duration: duration || 0,
    });
  }

  private isHotelResultStillPolling(): boolean {
    return this.globalData.get(GlobalDataEnum.HOTEL_RESULT_STILL_POLLING);
  }

  private createMapboxglInstance(containerId: string): void {
    mapboxgl.accessToken = this.appSettingsService.mapboxAPIKey;
    const hotelSearchForm: HotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);

    this.map = new mapboxgl.Map({
      container: containerId,
      style: "mapbox://styles/mapbox/streets-v10",
      center: [hotelSearchForm.destination.value.lng, hotelSearchForm.destination.value.lat],
      zoom: 10,
    });
  }

  private configureMap(): void {
    // Disable map rotation
    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();

    // Map controls UI
    this.map.addControl(new mapboxgl.NavigationControl(), "top-left");
  }

  private mapExists(): boolean {
    return this.map && this.mapboxState.loaded;
  }

  // Setup resources required to render markers and popups on the map object
  private setupMapResources(): void {
    this.setupSources();
    this.setupLayers();
    this.setupEventListeners();
    this.setupLayerRefresherListener();
  }

  private setupSources(): void {
    if (!this.setupSourcesData()) {
      const maxZoom = this.isEarn() ? 22 : 13;
      this.map.addSource("hotels", {
        type: "geojson",
        data: this.addressPoints,
        cluster: true,
        clusterMaxZoom: maxZoom,
        clusterRadius: 80,
        generateId: true,
      });
    }
  }

  private hasCurrencyLocaleListener(): boolean {
    return (
      this.currencyListener &&
      !this.currencyListener.closed &&
      this.localeListener &&
      !this.localeListener.closed
    );
  }

  // Set data to "hotels" map source if available, useful for updating data without reloading sources/layers
  private setupSourcesData(): boolean {
    const hotelsSource = this.map.getSource("hotels") as GeoJSONSource;
    hotelsSource?.setData(this.addressPoints);
    return !!hotelsSource;
  }

  private setupEarnLayers(): void {
    this.map.loadImage(
      `${this.kaligoConfig.cdnImageUrl}/callout-shadow.png`, (error, image) => {
        if (error) {
          throw error;
        }

        this.map.addImage("callout", image, {
          stretchX: [
            [50, 95],
            [130, 175],
          ],
          stretchY: [[45, 80]],
          content: [50, 45, 170, 80],
          pixelRatio: 2,
        });

        this.map.addLayer({
          id: "hotelsLayer",
          type: "symbol",
          source: "hotels",
          layout: {
            "text-field": ["get", "description"],
            "text-font": [
              "Arial Unicode MS Regular",
              "Arial Unicode MS Bold",
            ],
            "icon-text-fit": "both",
            "text-size": 12,
            "icon-image": ["get", "image-name"],
            "icon-allow-overlap": true,
            "text-allow-overlap": false,
            "text-optional": true,
            "icon-optional": true,
            "symbol-z-order": "viewport-y",
          },
          paint: {
            "text-color": this.appSettingsService.mapboxHotelMarkerColor,
            "icon-opacity": [
              "case",
              ["boolean", ["feature-state", "click"], false],
              0,
              1,
            ],
            "text-opacity": [
              "case",
              ["boolean", ["feature-state", "click"], false],
              0,
              1,
            ],
          },
        });
      },
    );
  }

  private setupLayerRefresherListener(): void {
    // Had to add this new variable because of some weird bug on JPY
    // where it is always JPY as new and oldvalue
    if (this.isEarn() && this.hasCurrencyLocaleListener()) { return; }
    this.currentLocale = this.globalData.get(GlobalDataEnum.SELECTED_LOCALE);

    this.currencyListener = this.currenciesService.onCurrencyChange.subscribe((newCurrency: Currency) => {
        this.resetLayers();
    });

    this.localeListener = this.localeService.onLocaleChange.subscribe((newLocale: Locale) => {
      if (newLocale !== this.currentLocale) {
        this.currentLocale = newLocale;
        this.resetLayers();
      }
    });
  }

  private resetLayers(): void {
    this.addressPoints = {
      type: "FeatureCollection",
      features: [],
    };
    this.resetAll();

    if (this.mapboxState.viewMode === MAPBOX_STATE_VIEW_MODE.Map) {
      this.toggleViewMode();
    }

    this.setupLayerRefresherListener();
    this.loadAddressPoints(this.hotelData);
  }

  private setupLayers(): void {
    // Individual hotel markers
    if (this.pageDiscoveryService.currentPage() !== CURRENT_PAGE.SEARCH_RESULT) { return; }

    if (this.isEarn()) {
      this.setupEarnLayers();
    } else {
      this.map.addLayer({
        id: "hotelsLayer",
        source: "hotels",
        type: "circle",
        filter: ["!has", "point_count"],
        paint: {
          "circle-radius": 8,
          "circle-color": this.appSettingsService.mapboxHotelMarkerColor,
        },
      });
    }

    // Hotel cluster markers
    this.map.addLayer({
      id: "hotelsCluster",
      source: "hotels",
      type: "circle",
      filter: ["has", "point_count"],
      paint: {
        "circle-radius": 15,
        "circle-color": this.appSettingsService.mapboxHotelClusterColor,
      },
    });

    // Hotel cluster count
    this.map.addLayer({
      id: "hotelsClusterCount",
      source: "hotels",
      type: "symbol",
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-size": 14,
      },
      paint: {
        "text-color": this.appSettingsService.mapboxHotelClusterCounterTextColor,
      },
    });
  }

  private setupEventListeners(): void {
    // Tooltips
    const hoverTooltip: Popup = new mapboxgl.Popup({
      closeButton: false,
    });
    // Check if device has touch capabilities
    this.map.on("touchstart", (e) => {
      this.userHasTouch = true;
    });
    this.map.on("mouseenter", "hotelsLayer", () => {
      // Do not attach mousemove event listener if device has touch
      if (!this.userHasTouch) {
        // Using mousemove instead of mouseenter so that its easier to select hotels in close proximity of each other
        this.map.on("mousemove", "hotelsLayer", (e) => {
          this.mapboxState.hoveredHotel = this.getHotelFromId(e.features[0].properties.id);
          this.hoveredHotelChanged.next();
          if (hoverTooltip.isOpen()) {
            return;
          }
          if (
            this.mapboxState.hoveredHotel &&
            this.mapboxState.hoveredHotel.id === e.features[0].properties.id &&
            this.mapboxState.hoveredHotel === this.mapboxState.selectedHotel
          ) {
            return;
          }
          this.map.getCanvas().style.cursor = "pointer";
          const point = e.features[0].geometry as Point;
          const coordinates = point.coordinates as LngLatLike;
          hoverTooltip.setLngLat(coordinates)
            .setDOMContent(this.tooltipElement)
            .addTo(this.map);
        });
      }
      this.map.getCanvas().style.cursor = "pointer";
    });
    this.map.on("mouseleave", "hotelsLayer", () => {
      this.map.getCanvas().style.cursor = "";
      hoverTooltip.remove();
    });

    // Popups
    const selectPopup = new mapboxgl.Popup({anchor: "bottom"});

    this.map.on("click", "hotelsLayer", (e) => {
      const point = e.features[0].geometry as Point;
      const coordinates = point.coordinates as LngLatLike;
      const hotelId = e.features[0].properties.id;
      let polygonID: string | number;

      if (e.features.length > 0) {
        if (polygonID) {
          this.map.removeFeatureState({
            source: "hotels",
            id: polygonID,
          });
        }

        polygonID = e.features[0].id;

        setTimeout(() => this.openPopup(coordinates, hotelId, null, polygonID.toString()));
      }
    });

    this.map.on("moveend", (e) => {
      // For smoother animation, open popup only after the map has centered on the marker
      const { eventType, coordinates } = e;
      if (eventType === OPEN_POPUP_EVENT) {
        selectPopup.remove();
        selectPopup.setLngLat(coordinates)
          .setDOMContent(this.popupElement)
          .addTo(this.map);
      }
    });

    // Clusters
    this.map.on("click", "hotelsCluster", (e) => {
      const currentZoom = this.map.getZoom();
      const point = e.features[0].geometry as Point;
      const coordinates = point.coordinates as LngLatLike;
      this.flyMapTo(coordinates,
        currentZoom + 1,
        CLUSTER_CLICK_ANIMATION_DURATION,
      );
    });

    this.map.on("mouseenter", "hotelsCluster", () => {
      this.map.getCanvas().style.cursor = "pointer";
    });
    this.map.on("mouseleave", "hotelsCluster", () => {
      this.map.getCanvas().style.cursor = "";
    });
  }

  private updateFeatureStates(polygonID: string, state: boolean): void {
    if (!state) {
      this.map.removeFeatureState({
        source: "hotels",
        id: polygonID,
      });
    }

    this.map.setFeatureState({
      source: "hotels",
      id: polygonID,
    }, {
      click: state,
    });
  }

  private getHotelFromId(id: string): Hotel {
    const selectedHotel: Hotel = this.hotelData.find(hotel => hotel.id === id);
    if (selectedHotel) {
      return selectedHotel;
    } else {
      return null;
    }
  }

  private setupAddressPointsFeatures(points: Hotel[]): void {
    points
    .filter((point) => point.available)
    .forEach((point) => {
      const descriptionText: string = point.priceInfo.formattedPrice;
      this.addressPoints.features.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [point.longitude, point.latitude],
        },
        properties: {
          "id": point.id,
          "description": descriptionText,
          "image-name": "callout",
        },
      });
    });
  }

  private removeAddressPointsFeatures(): void {
    this.addressPoints.features = [];
  }

  private removeMap(): void {
    if (!this.map) { return; }

    this.map.on("remove", () => this.map = null);
    this.mapboxState.loaded = false;
    this.map.remove();
    this.localeListener.unsubscribe();
    this.currencyListener.unsubscribe();
  }

  private removeMapResources(): void {
    const layers = ["hotelsLayer", "hotelsCluster", "hotelsClusterCount"];

    layers.forEach((layer) => {
      if (this.map && this.map.getLayer(layer)) {
        this.map.removeLayer(layer);
      }
    });

    const sources = ["hotels"];

    sources.forEach((source) => {
      if (this.map && this.map.getSource(source)) {
        this.map.removeSource(source);
      }
    });
  }

  private isEarn(): boolean {
    return this.globalData.get(GlobalDataEnum.LANDING_PAGE).hasProductType(PRODUCT_TYPE.EARN);
  }

}
