
import { Locale } from "booking-app-v2/shared/models/locale.model";
export class FormatNumberUtil {

  static roundNumber(num: number, locale: Locale, decimalPlace = 0): string {
    const value: number = Number(num);
    let formattedValue: string;
    if (typeof value !== "number" || isNaN(value) || !locale) {
      return "";
    }

    if (decimalPlace > 0) {
      formattedValue = value.toFixed(decimalPlace);
    } else {
      formattedValue = Math.ceil(value).toString();
    }

    const parts: string[] = formattedValue.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, locale.numSeparator);

    if (!decimalPlace || decimalPlace <= 0) {
      if (parts[0] === "0" && parts.length > 1) {
        return "1";
      } else {
        return parts[0];
      }
    }

    return parts.join(locale.comma);
  }

  static summarizeNumber(num: number, locale: Locale) {
    if (num < 10000) {
      return this.roundNumber(num, locale, 0);
    }

    if (num < 1000000) {
      // 999,999 to 10,000
      const thousands: number = Math.ceil(num / 1000);
      return `${thousands}K`;
    } else {
      // >= 1,000,000
      const millions: number = Math.floor(num / 1000000);
      const value: number = millions - (millions * 1000000);
      const tenThousands: number = Math.ceil(value / 10000);

      if (tenThousands === 0) {
        return `${millions}M`;
      } else {
        return `${millions}${locale.comma}${tenThousands}M`;
      }
    }
  }

  static adjustDecimals(num: number, locale: Locale, decimalPlace = 0): string {
    const value: number = Number(num);
    let formattedValue: string;
    if (typeof value !== "number" || isNaN(value) || !locale) {
      return "";
    }

    if (decimalPlace > 0) {
      formattedValue = (Math.floor(value * (10 ** decimalPlace)) / (10 ** decimalPlace)).toFixed(decimalPlace);
    } else {
      formattedValue = Math.ceil(value).toString();
    }

    const parts: string[] = formattedValue.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, locale.numSeparator);

    if (!decimalPlace || decimalPlace <= 0) {
      if (parts[0] === "0" && parts.length > 1) {
        return "1";
      } else {
        return parts[0];
      }
    }

    return parts.join(locale.comma);
  }

  static formatCurrencyValue(value: number, decimalPlace = 0): number {
    return decimalPlace > 0 ? Math.ceil(value * 100) / 100 : Math.ceil(value);
  }
}
