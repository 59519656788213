import { ObjectValues } from "./object-values.type";

export const BOOKING_FILTER = {
  ALL: "all",
  UPCOMING: "upcoming",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
} as const;

export type BookingFilter = ObjectValues<typeof BOOKING_FILTER>;
