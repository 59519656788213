import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { DetailsPageComponent } from "../details-page.component";
import { DetailsPageRoutingModule } from "../routes/details-page-routing.module";
import { GalleryModule } from "booking-app-v2/shared/components/gallery/gallery.module";
import { HotelSearchBarModule } from "booking-app-v2/shared/components/hotel-search-bar/hotel-search-bar.module";
import { BannerModule } from "booking-app-v2/shared/components/banner";
import { TrustYouModule } from "booking-app-v2/shared/components/trust-you/trust-you.module";
import { MapViewModule } from "booking-app-v2/shared/components/map-view/map-view.module";
import { HotelDescriptionModule } from "booking-app-v2/shared/components/hotel-description/hotel-description.module";
import { AmenitiesModule } from "booking-app-v2/shared/components/amenities/amenities.module";
import { PointsCashSliderModule } from "booking-app-v2/shared/components/points-cash-slider/points-cash-slider.module";
import { GroupedRoomModule } from "booking-app-v2/shared/components/grouped-room/grouped-room.module";
import { StarRatingModule } from "booking-app-v2/shared/components/star-rating/star-rating.module";
import { TravelBreakdownModule } from "booking-app-v2/shared/components/travel-breakdown/travel-breakdown.module";
import {
  MobileRoomPricesButtonModule,
} from "booking-app-v2/shared/components/mobile-room-prices-button/mobile-room-prices-button.module";
import { ComplimentaryPriceBoxModule } from "booking-app-v2/shared/components/complimentary-price-box/complimentary-price-box.module";

@NgModule({
  imports: [
    SharedModule,
    DetailsPageRoutingModule,
    GalleryModule,
    HotelSearchBarModule,
    BannerModule,
    TrustYouModule,
    MapViewModule,
    HotelDescriptionModule,
    AmenitiesModule,
    PointsCashSliderModule,
    GroupedRoomModule,
    StarRatingModule,
    TravelBreakdownModule,
    MobileRoomPricesButtonModule,
    ComplimentaryPriceBoxModule,
  ],
  declarations: [
    DetailsPageComponent,
  ],
})
export class SharedDetailsPageModule {}
