import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { BannerComponent } from "./banner.component";

@NgModule({
  declarations: [ BannerComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ BannerComponent ],
  providers: [],
})
export class BannerModule {}
