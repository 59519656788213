import dayjs, { Dayjs, InstanceLocaleDataReturn, OpUnitType, QUnitType, UnitTypeLong } from "dayjs";
import { Duration, DurationUnitType } from "dayjs/plugin/duration";
import localeData from "dayjs/plugin/localeData";

// Enable required plugins here:
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(localeData);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

import { Locale } from "booking-app-v2/shared/models";

export class TimeUtils {

  static MIN_MINUTES_IN_DAY = 0;
  static MAX_MINUTES_IN_DAY = 1439;

  // The time will NOT be localised, ONLY the format will be changed
  // Call this when you only want to format the time without change any part of the time value, e.g.:
  // "2021-04-15T14:15:00.000", "lll" => "Apr 15, 2021 2:15 PM"
  // "2021-04-15T14:15:00.000Z", "lll" => "Apr 15, 2021 2:15 PM"
  static format(dateTime: string | Dayjs, format: string, inputFormat?: string) {
    return dateTime ? dayjs.utc(dateTime, inputFormat).format(format) : "";
  }

  // The time will be treated as a UTC time and localised then formatted, even for time without the trailing "Z"
  // Call this when you want to recalculate the time, e.g.:
  // "2021-04-15T14:15:00.000", "lll" => "Apr 15, 2021 10:15 PM"
  // "2021-04-15T14:15:00.000Z", "lll" => "Apr 15, 2021 10:15 PM"
  static localiseAndFormat(dateTime: string | Dayjs, format: string, inputFormat?: string): string {
    return dateTime ? dayjs.utc(dateTime, inputFormat).local().format(format) : "";
  }

  // When selected locale is not English, the `format` method above will output
  // translated value in the selected locale instead of English. This method forces
  // the output to be in English for the event where the output string needs to
  // be passed through another dayjs parser
  static formatInEnglish(dateTime: string | Dayjs, format: string, inputFormat?: string) {
    const currentLocale = dayjs.locale();
    dayjs.locale("en");
    const result = dateTime ? dayjs.utc(dateTime, inputFormat).format(format) : "";
    dayjs.locale(currentLocale);
    return result;
  }

  // Get current user's localised time and format it with the format parameter provided
  static now(format: string = "ddd, D MMM, YYYY - h:mm A"): string {
    return dayjs().format(format);
  }

  // Parse a date string, number, Date object or Dayjs object to a Dayjs object
  static parseDate(date: string | number | Date | Dayjs): Dayjs {
    return dayjs(date);
  }

  // Given the start and end time in string, they will be converted to a Dayjs object without any localise conversion
  // Then it will return the difference between start and end based on the unit provided
  // If unit is not provided, it will return the difference in milliseconds
  // "2021-05-03", "2021-05-01", "days" => 2
  // dayjs("2021-05-03"), dayjs("2021-05-01"), "days" => 2
  static diff(endTime: string | Dayjs, startTime: string | Dayjs, unit?: QUnitType | OpUnitType): number {
    if (typeof endTime === "string" || endTime instanceof String) {
      endTime = dayjs.utc(endTime);
    }
    if (typeof startTime === "string" || startTime instanceof String) {
      startTime = dayjs.utc(startTime);
    }
    return endTime.diff(startTime, unit);
  }

  // V1's numberToHours
  // Given a number of minutes, convert it to the respective time of the day, e.g.:
  // 10 => "0:10 AM"
  // 100 => "1:40 AM"
  static formatMinutesToTime(value: number, format: string = "h:mm A"): string {
    return dayjs("0").add(dayjs.duration(value, "minute")).format(format);
  }

  // V1's timeToNumber
  // Given a time, calculate how many minutes is this time since the start of the day
  // "0:10 AM" => 10
  // "1:40 AM" => 100
  static formatTimeToMinutes(time: string): number {
    const hours: number = parseInt(dayjs(time).format("HH"), 10);
    const minutes: number = parseInt(dayjs(time).format("mm"), 10);

    return hours * 60 + minutes;
  }

  // Calculate the day offset between startTime and endTime, regardless of whether input is in UTC format
  // If startTime is not provided, startTime will be today, e.g.
  // "2021-05-07T20:15:00.000Z", "2021-05-01T20:15:00.000" => 5 (endTime in UTC, startTime not in UTC)
  // "2021-05-07T20:15:00.000Z", undefined => 1 (if today is 2021-05-06 in user's location)
  static getDayOffset(endTime: string, startTime?: string): number {
    const endTimeDay: Dayjs = dayjs.utc(endTime).startOf("day");
    const startTimeDay: Dayjs = startTime ?
      dayjs.utc(startTime).startOf("day") :
      dayjs().startOf("day");

    return this.diff(endTimeDay, startTimeDay, "days");
  }

  // Returns the difference between the startTime and endTime as a dayjs Duration object
  static getTimeDuration(endTime: string, startTime: string): Duration {
    return dayjs.duration(this.diff(endTime, startTime));
  }

  static getDuration(dur: number, unit?: DurationUnitType): Duration {
    return unit ? dayjs.duration(dur, unit) : dayjs.duration(dur);
  }

  static formatDuration(dur: Duration): string {
    return (dur.days() > 0 ? `${dur.days()}d ` : "") +
      (dur.hours() > 0 ? `${dur.hours()}h ` : "") +
      (dur.minutes() > 0 ? `${dur.minutes()}m` : "");
  }

  static setLocale(locale: Locale): void {
    const langCode: string = locale.langCode.toLowerCase();
    dayjs.locale(langCode);
    // trick to fallback dayjs locale to "en" if locale provided is unavailable
    if (dayjs.locale() !== langCode) {
      dayjs.locale("en");
    }
  }

  // Expose localeData from dayjs, used for datepicker for example
  static get localeData(): InstanceLocaleDataReturn {
    return dayjs().localeData();
  }

  static isValidDate(date: string): boolean {
    return dayjs(date).isValid();
  }

  static isFutureDate(date: string): boolean {
    return dayjs(date).isAfter(dayjs());
  }

  static setStartOfTime(unit: OpUnitType): Dayjs {
    return dayjs().startOf(unit);
  }

  static addDate(offSet: number, unit: UnitTypeLong, date?: Dayjs): Dayjs {
    return dayjs(date ?? new Date()).add(offSet, unit);
  }

  static getCurrentYear(): number {
    return dayjs().year();
  }
}
