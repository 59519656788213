import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";

import { User } from "booking-app-v2/shared/models";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { HeaderUrlListService } from "booking-app-v2/shared/services/initializers/header-url-list.service";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import { PaymentMethodService } from "booking-app-v2/shared/services/payment-method.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { CURRENT_PAGE, GlobalDataEnum, VIEWPORT_SIZES } from "booking-app-v2/shared/types";
import { SharedHeaderComponent } from "../shared-header/shared-header.component";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { UserService } from "booking-app-v2/shared/services/user.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";

@Component({
  selector: "sticky-header",
  templateUrl: "/html/components/sticky_header_v2",
})

export class StickyHeaderComponent extends SharedHeaderComponent implements OnInit {
  isImpersonatedUser: boolean = this.globalData.get(GlobalDataEnum.IS_IMPERSONATED_USER);

  private hideFlag: boolean;
  private navbar: any; // Cant use Element Type here as we need to modify styles
  private customFlagElement: any;
  private currentScrollValue: any;
  private eventDebouncerValue: any;
  private fixedElement: boolean; // Status marker if the element is either affixed to the top or not
  private debounceTimeout: any;

  constructor(
    public globalData: GlobalData,
    protected dialogService: DialogService,
    protected userService: UserService,
    protected pageDiscoveryService: PageDiscoveryService,
    protected appSettingsService: AppSettingsService,
    private paymentMethodService: PaymentMethodService,
    private headerUrlListService: HeaderUrlListService,
    private windowRefService: WindowRefService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super(globalData, dialogService, userService, pageDiscoveryService, appSettingsService);
  }

  ngOnInit(): void {
    this.hideFlag = false;
    this.currentScrollValue = 0;
    this.navbar = this.document.getElementById("sticky");
    this.customFlagElement = this.document.getElementById("header-static-pages");
    this.setupStickyListener();
    this.fixedElement = true;
  }

  get user(): User {
    return this.globalData.get(GlobalDataEnum.CURRENT_USER);
  }

  triggerHoverBehaviour(): void {
    this.headerUrlListService.updateActiveLink();
  }

  isMobile(): boolean {
    return this.windowRefService.nativeWindow.innerWidth <= VIEWPORT_SIZES.XS_MAX;
  }

  showSegmentedBanner(): boolean {
    if (!this.globalData.isUserLoggedIn()) {
      return false;
    }

    const headerValue: string = this.user?.segments.header;
    return (this.pageDiscoveryService.currentPage() === CURRENT_PAGE.CHECKOUT &&
      !this.paymentMethodService.showPayAnyone &&
      (headerValue === "16" ||  headerValue === "17"));
  }

  private setupStickyListener(): void {
    this.windowRefService.nativeWindow.onscroll = () => {
      const scrollY = this.windowRefService.nativeWindow.scrollY;
      this.triggerScrollBehaviour(scrollY);
    };
  }

  private triggerScrollBehaviour(scrollY): void {
    const delta = this.currentScrollValue - scrollY;
    if (scrollY === 0) {
      this.scrollOnTopBehaviour();
    } else if (delta < 0 && scrollY > this.navbar.offsetHeight) {
      this.scrollDownBehaviour();
    } else if (delta > 0 && !this.fixedElement) {
      this.scrollUpBehaviour(scrollY);
    }
    this.setCurrentScroll(scrollY);
  }

  private setCurrentScroll(scrollValue: number): void {
    this.currentScrollValue = scrollValue;
  }

  private updateHideFlag(flag: boolean): void {
    this.hideFlag = flag;
  }

  private customElementAction(action: boolean, element: any): void {
    if (this.windowRefService.nativeWindow.scrollY <= 70) {
      element.classList.remove("hide-transition");
    }

    if (action) {
      if (this.hideFlag) {
        if (this.eventDebouncerValue - this.windowRefService.nativeWindow.scrollY >= 30) {
          element.classList.remove("hide-transition");
        }
      } else {
        this.eventDebouncerValue = this.windowRefService.nativeWindow.scrollY;
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
          this.updateHideFlag(action);
        }, 100);
      }
    } else {
      this.updateHideFlag(action);
    }
  }

  private scrollOnTopBehaviour(): void {
    // Scroll on top of screen
    this.navbar.classList.remove("scroll-up");
    this.addScrollOnTopClass();
    this.navbar.style.position = "relative";
    this.customElementAction(false, this.customFlagElement);
    this.customFlagElement.classList.remove("hide-transition");
    this.fixedElement = true;
  }

  private scrollDownBehaviour(): void {
    // Scroll going down behaviour
    this.navbar.classList.add("scroll-up");
    this.removeScrollOnTopClass();
    this.customFlagElement.classList.add("hide-transition");
    this.updateHideFlag(false);
    this.fixedElement = false;
  }

  private scrollUpBehaviour(scrollY: number): void {
    // Scrolling up behaviour
    this.navbar.classList.remove("scroll-up");
    this.navbar.style.position = "fixed";
    this.customElementAction(true, this.customFlagElement);
  }

  private addScrollOnTopClass(): void {
    this.navbar.classList.add("scroll-on-top");
    this.customFlagElement.classList.add("scroll-on-top");
  }

  private removeScrollOnTopClass(): void {
    this.navbar.classList.remove("scroll-on-top");
    this.customFlagElement.classList.remove("scroll-on-top");
  }
}
