import { Base, CancellationPolicy } from "booking-app-v2/shared/models";
import {
  CarFee,
  CarPricedCoverage,
  CarPricedEquip,
  CarRawResponse,
  RawCarPricedEquip,
} from "booking-app-v2/cars/types";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CarPrice } from "booking-app-v2/cars/models";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { Voucher } from "booking-app-v2/shared/types";
import { VoucherDecorator } from "booking-app-v2/shared/decorators";

export class Car extends Base {
  readonly booking_key: string;
  readonly rate: number;
  aircon: boolean;
  baggage: number;
  cancellation_policy: CancellationPolicy;
  category: string;
  charges: string[];
  dropoff_supplier_address: string;
  doors: number;
  fuel_type: string;
  image_url: string;
  name: string;
  passengers: number;
  priced_coverages: CarPricedCoverage[];
  priced_equips: CarPricedEquip[];
  rating: string;
  rating_category: string;
  reviews_count: number;
  supplier_address: string;
  supplier_distance: number;
  supplier_image_url: string;
  supplier_lat: string;
  supplier_lng: string;
  supplier_name: string;
  transmission_type: string;
  carFees: CarFee[];

  priceInfo: CarPrice;
  rawPrice: CarRawResponse;

  currencyService: CurrenciesService;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    currencyService: CurrenciesService,
    payWithPointsCashService: PayWithPointsCashService,
    carRawResponse: CarRawResponse,
    isCheckoutPage?: boolean,
    appliedVouchers?: Voucher[],
  ) {
    super(
      appSettingsService,
      globalData,
    );
    this.currencyService = currencyService;

    this.rawPrice = carRawResponse;
    this.aircon = carRawResponse.aircon;
    this.booking_key = carRawResponse.booking_key;
    this.baggage = carRawResponse.baggage;
    this.cancellation_policy = carRawResponse.cancellation_policy;
    this.category = carRawResponse.category;
    this.charges = carRawResponse.charges;
    this.dropoff_supplier_address = carRawResponse.dropoff_supplier_address;
    this.doors = carRawResponse.doors;
    this.fuel_type = carRawResponse.fuel_type;
    this.image_url = carRawResponse.image_url;
    this.name = carRawResponse.name;
    this.passengers = carRawResponse.passengers;
    this.priced_coverages = carRawResponse.priced_coverages;
    this.priced_equips = this.getCarPricedEquips(carRawResponse.priced_equips);
    this.rate = carRawResponse.rate;
    this.rating = carRawResponse.rating;
    this.rating_category = carRawResponse.rating_category;
    this.reviews_count = carRawResponse.reviews_count;
    this.supplier_name = carRawResponse.supplier_name;
    this.supplier_address = carRawResponse.supplier_address;
    this.supplier_distance = carRawResponse.supplier_distance;
    this.supplier_image_url = carRawResponse.supplier_image_url;
    this.supplier_lat = carRawResponse.supplier_lat;
    this.supplier_lng = carRawResponse.supplier_lng;
    this.transmission_type = carRawResponse.transmission_type;
    this.carFees = carRawResponse.fees;

    this.priceInfo = new CarPrice(
      appSettingsService,
      globalData,
      payWithPointsCashService,
      carRawResponse,
    );

    if (isCheckoutPage && appliedVouchers) {
      VoucherDecorator.decorate(this, appliedVouchers);
    }
  }

  private getCarPricedEquips(rawCarPricedEquips: RawCarPricedEquip[]): CarPricedEquip[] {
    if (!rawCarPricedEquips) {
      return [];
    }

    const equips: CarPricedEquip[] = [];
    const uniqueEquipHash: Record<string, boolean> = {};

    rawCarPricedEquips.forEach((rawCarPricedEquip) => {
      if (!uniqueEquipHash[rawCarPricedEquip.type]) {
        equips.push({
          name: rawCarPricedEquip.type.replace(/_/g, "-"),
          currency: rawCarPricedEquip.currency,
          included: rawCarPricedEquip.included,
          rate: parseFloat(rawCarPricedEquip.rate),
          tax_inclusive: rawCarPricedEquip.tax_inclusive === "true",
          type: rawCarPricedEquip.type,
        });
        uniqueEquipHash[rawCarPricedEquip.type] = true;
      }
    });

    return equips;
  }
}
