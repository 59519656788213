import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { Base, PointsPartner, PointsPartners } from "booking-app-v2/shared/models";
import { TravelType } from "booking-app-v2/shared/types";

export interface PointsCredit {
  points_partner: PointsPartner;
}

export class Booking extends Base {
  readonly id: number;
  readonly points_credits: PointsCredit[];
  readonly first_name: string;
  readonly last_name: string;
  readonly tax_amount_usd: number;
  readonly created_at: string;
  readonly paid_in_points: number;
  readonly cost: number;
  readonly converted_amount: number;
  readonly currency: string;
  readonly tax_amount: number;
  readonly destination_name: string;
  readonly original_destination_name: string;
  readonly status_description: string;
  readonly status: "active" | "pending" | "cancelled" | "verification_required";
  readonly salutation: string;
  readonly payment_type: string;
  readonly tenant_user_id: number;
  readonly kaligo_booking_ref: string;
  readonly travel_type: TravelType;
  readonly product_type: string;
  readonly email: string;
  readonly base_points: number;
  readonly bonus_points: number;
  readonly pointsPartner: PointsPartner;
  readonly itinerary_id: string;
  readonly cancellable: boolean;
  readonly cancelled: boolean;
  readonly cancellation_date?: string;
  readonly cancellation_fee_percentage?: number;

  private readonly points_partner: { currency_short: string, name: string };

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    booking: Booking,
    pointsPartners: PointsPartners,
  ) {
    super(appSettingsService, globalData);

    this.id = booking.id;
    this.points_credits = this.initPointsCredits(booking.points_credits);
    this.first_name = booking.first_name;
    this.last_name = booking.last_name;
    this.tax_amount_usd = booking.tax_amount_usd;
    this.created_at = booking.created_at;
    this.paid_in_points = booking.paid_in_points;
    this.cost = booking.cost;
    this.converted_amount = booking.converted_amount;
    this.currency = booking.currency;
    this.tax_amount = booking.tax_amount;
    this.destination_name = booking.destination_name;
    this.original_destination_name = booking.original_destination_name;
    this.status_description = booking.status_description;
    this.status = booking.status;
    this.salutation = booking.salutation;
    this.payment_type = booking.payment_type;
    this.tenant_user_id = booking.tenant_user_id;
    this.kaligo_booking_ref = booking.kaligo_booking_ref;
    this.travel_type = booking.travel_type;
    this.product_type = booking.product_type;
    this.email = booking.email;
    this.base_points = booking.base_points;
    this.bonus_points = booking.bonus_points;
    this.points_partner = booking.points_partner;
    this.pointsPartner = this.getPointsPartner(booking, pointsPartners);
    this.itinerary_id = booking.itinerary_id;
    this.cancellable = booking.cancellable;
    this.cancelled = booking.cancelled;
    this.cancellation_date = booking.cancellation_date;
    this.cancellation_fee_percentage = booking.cancellation_fee_percentage;
  }

  isActiveBooking(): boolean {
    return this.status === "active";
  }

  isPendingBooking(): boolean {
    return this.status === "pending";
  }

  isVerificationRequiredBooking(): boolean {
    return this.status === "verification_required";
  }

  hasPaidAnyCash(): boolean {
    return this.converted_amount && this.converted_amount > 0;
  }

  hasPaidAnyPoints(): boolean {
    return this.paid_in_points && this.paid_in_points > 0;
  }

  hasPaidInCashOnly(): boolean {
    return !this.paid_in_points && this.converted_amount > 0;
  }

  hasPaidInBothPointsAndCash(): boolean {
    return this.hasPaidAnyCash() && this.hasPaidAnyPoints();
  }

  getBookingGuestName(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  displayCancellation(): boolean {
    return !this.isPendingBooking() && !this.isVerificationRequiredBooking();
  }

  displayCancelButton(): boolean {
    return !this.hasPaidAnyPoints() || this.appSettingsService.allowCancelRedemptionBooking;
  }

  nonCancellable(): boolean {
    return this.hasPaidAnyPoints() && !this.appSettingsService.showCancelPolicyOnRedeem;
  }

  showCancelBooking(): boolean {
    return this.isActiveBooking() && !this.cancelled;
  }

  private getPointsPartner(booking: Booking, pointsPartners: PointsPartners): PointsPartner {
    const pointsCredit: PointsCredit = booking.points_credits[0];
    if (pointsCredit) {
      const pointsPartnerId = pointsCredit.points_partner.id;
      const pointsPartner: PointsPartner = pointsPartners.findById(pointsPartnerId);
      return pointsPartner;
    } else {
      return new PointsPartner(this.appSettingsService, this.globalData, booking.points_partner as PointsPartner);
    }
  }

  private initPointsCredits(rawPointsCredits: PointsCredit[]): PointsCredit[] {
    const pointsCredits: PointsCredit[] = [];
    rawPointsCredits.forEach((pointsCredit: PointsCredit) => {
      pointsCredits.push({
        points_partner: new PointsPartner(this.appSettingsService, this.globalData, pointsCredit.points_partner),
      });
    });
    return pointsCredits;
  }
}
