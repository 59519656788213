import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { HotelDetailPopupComponent } from "./hotel-detail-popup.component";
import { StarRatingModule } from "booking-app-v2/shared/components/star-rating/star-rating.module";
import { TravelBreakdownModule } from "booking-app-v2/shared/components/travel-breakdown/travel-breakdown.module";

@NgModule({
  declarations: [ HotelDetailPopupComponent ],
  imports: [
    SharedModule,
    StarRatingModule,
    TravelBreakdownModule,
  ],
  exports: [ HotelDetailPopupComponent ],
  providers: [],
})
export class HotelDetailPopupModule {}
