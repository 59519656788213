import { Injectable } from "@angular/core";
import { Params, Router } from "@angular/router";
import { finalize, Observable, of, Subject, Subscription } from "rxjs";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { GlobalDataEnum, PRODUCT_TYPE } from "booking-app-v2/shared/types";
import { Room } from "booking-app-v2/hotels/models";
import { HotelsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/hotels-url-builder.service";
import { CarsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/cars-url-builder.service";

@Injectable({
  providedIn: "root",
})
export class CheckoutValidationService {

  constructor(
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private apiDataService: ApiDataService,
    private dialogService: DialogService,
    private windowRefService: WindowRefService,
    private router: Router,
    private hotelsUrlBuilderService: HotelsUrlBuilderService,
    private carsUrlBuilderService: CarsUrlBuilderService,
  ) {}

  goToHotelCheckoutPage(room: Room): void {
    const minPoint: number = room.priceInfo.minPoint;
    const validToCheckoutSubscription: Subscription = this.checkIfValidToCheckout(minPoint)
      .pipe(finalize(() => validToCheckoutSubscription.unsubscribe()))
      .subscribe((validToCheckout: boolean) => {
        if (!validToCheckout) {
          return;
        }

        const hotelId: string = this.windowRefService.getURIParamFromWindow();
        const queryParams: Params = this.hotelsUrlBuilderService.buildCheckoutPageUrl(room.key);
        if (this.appSettingsService.openInTargetTab.checkoutInNewTab) {
          const checkoutUrl: string = this.router.createUrlTree(
            [`/hotels/checkout/${hotelId}`],
            { queryParams },
          ).toString();

          this.windowRefService.nativeWindow.open(checkoutUrl, "_blank");
        } else {
          this.router.navigate([`/hotels/checkout/${hotelId}`], { queryParams });
        }
      });
  }

  goToCarCheckoutPage(): void {
    const validToCheckoutSubscription: Subscription = this.checkIfValidToCheckout()
    .pipe(finalize(() => validToCheckoutSubscription.unsubscribe()))
    .subscribe((validToCheckout: boolean) => {
      if (!validToCheckout) {
        return;
      }

      const bookingKey: string = this.windowRefService.getURIParamFromWindow();
      const queryParams: Params = this.carsUrlBuilderService.buildCommonSearchUrlParams();
      if (this.appSettingsService.openInTargetTab.checkoutInNewTab) {
        const checkoutUrl: string = this.router.createUrlTree(
          [`/cars/checkout/${bookingKey}`],
          { queryParams },
        ).toString();

        this.windowRefService.nativeWindow.open(checkoutUrl, "_blank");
      } else {
        this.router.navigate([`/cars/checkout/${bookingKey}`], { queryParams });
      }
    });
  }

  checkIfValidToCheckout(minPoint?: number): Observable<boolean> {
    if (this.requireLogin()) {
      this.dialogService.openLoginModal();
      return of(false);
    } else if (this.isInsufficientPoints(minPoint)) {
      this.openMilesModal();
      return of(false);
    } else if (this.appSettingsService.checkUserStillLoggedIn) {
      const validToCheckout: Subject<boolean> = new Subject<boolean>();
      const pollCurrentUser: Subscription = this.apiDataService.get("user")
        .pipe(finalize(() => pollCurrentUser.unsubscribe()))
        .subscribe({
          next: user => {
            if (user) {
              validToCheckout.next(true);
            } else {
              this.dialogService.openLoginModal();
              validToCheckout.next(false);
            }
          },
          error: () => {
            this.dialogService.openLoginModal();
            validToCheckout.next(false);
          },
        });
      return validToCheckout.asObservable();
    }

    return of(true);
  }

  private openMilesModal(): void {
    // angular-v2-todo migrate this modal as a simple dialog when migrating JAL to angular v2
    // if (this.$rootScope.globalState.activeModal) {
    //   return;
    // }
    // this.$rootScope.globalState.activeModal = true;
    // this.modalManager.open({
    //   animation: true,
    //   templateUrl: "/html/modals/miles_check_modal",
    //   windowClass: "login-modal-validation",
    //   size: "sm",
    //   controller: "LoginPopupCtrl",
    //   controllerAs: "popup",
    //   backdrop: true,
    //   backdropClass: "validation-backdrop",
    //   resolve: {
    //     redirectUrl: () => "",
    //   },
    // });
  }

  private isInsufficientPoints(minPoint?: number): boolean {
    if (!minPoint) {
      return false;
    }

    return this.appSettingsService.requireSufficientPointsOnCheckout &&
      this.globalData.isUserLoggedIn() &&
      this.globalData.get(GlobalDataEnum.CURRENT_USER).family_miles < minPoint &&
      this.globalData.get(GlobalDataEnum.LANDING_PAGE).hasProductType(PRODUCT_TYPE.REDEEM);
  }

  private requireLogin(): boolean {
    return this.appSettingsService.requireLoginOnCheckout && !this.globalData.isUserLoggedIn();
  }
}
