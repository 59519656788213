import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";
import { LandingPage, Price } from "booking-app-v2/shared/models";
import { CommonPriceDecorator, CommonRedeemDecorator } from "booking-app-v2/shared/decorators";
import { CarRawResponse } from "booking-app-v2/cars/types";
import { CarPriceDecorator } from "../decorators/car-price.decorator";
import { GlobalDataEnum, PRODUCT_TYPE } from "booking-app-v2/shared/types";
import { CarSearchForm } from "./car-search-form.model";

export class CarPrice extends Price {
  has_discount_for_coupon_code: boolean;

  // Earn variables
  bonus: number;
  points: number;
  pricePerNight: number;
  price: number;

  // Redeem variables
  cash_payment: number;
  original_cash_payment: number;
  cash_payment_per_night: number;
  original_cash_payment_per_night: number;

  points_payment: number;
  points_payment_per_night: number;
  original_points_payment: number;
  original_points_payment_per_night: number;

  minPoint: number;
  totalPrice: number;

  // Voucher variables
  voucher_type_id: number;
  bookingVoucherType: string;
  leftToPay: number;
  formattedTotalVoucher: string;
  formattedSubtotal: string;
  grandTotal: string;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    payWithPointsCashService: PayWithPointsCashService,
    carRawResponse: CarRawResponse,
  ) {
    super(
      appSettingsService,
      globalData,
      payWithPointsCashService,
    );

    this.runCarDecorators(carRawResponse);
    this.decorateByProductType(globalData, carRawResponse, payWithPointsCashService);
  }

  private runCarDecorators(carRawResponse: CarRawResponse): void {
    CommonPriceDecorator.decorate(this, carRawResponse);
    CarPriceDecorator.decorate(this, carRawResponse);
  }

  private decorateByProductType(
    globalData: GlobalData,
    carRawResponse: CarRawResponse,
    payWithPointsCashService: PayWithPointsCashService,
  ) {
    const landingPage: LandingPage = globalData.get(GlobalDataEnum.LANDING_PAGE);
    const carSearchForm: CarSearchForm = globalData.get(GlobalDataEnum.CAR_SEARCH_FORM);

    if (landingPage.hasProductType(PRODUCT_TYPE.REDEEM)) {
      CommonRedeemDecorator.decorate(
        this,
        carRawResponse,
        carSearchForm.duration,
        payWithPointsCashService,
      );
    } else if (landingPage.hasProductType(PRODUCT_TYPE.EARN) || landingPage.hasProductType(PRODUCT_TYPE.CASHVOUCHER)) {
      this.price = carRawResponse.max_cash_payment;
      this.pricePerNight = this.price / carSearchForm.duration;
    }
  }
}
