import { NgModule } from "@angular/core";
import { NgxSimpleDropdownModule } from "@ascenda/ngx-simple-dropdown";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { RoomGuestCounterComponent } from "./room-guest-counter.component";
import { InputCounterModule } from "booking-app-v2/shared/components/input-counter/input-counter.module";

@NgModule({
  declarations: [ RoomGuestCounterComponent ],
  imports: [
    SharedModule,
    InputCounterModule,
    NgxSimpleDropdownModule,
  ],
  exports: [ RoomGuestCounterComponent ],
  providers: [],
})
export class RoomGuestCounterModule {}
