import { Subject } from "rxjs";
import { Config } from "@ascenda/ngx-simple-dropdown";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PopularCategories, ReviewScore, StarRatings, StarRatingsCount } from "../types";
import { Base } from "booking-app-v2/shared/models";
import { Hotel } from ".";
import {
  CashRange,
  GlobalDataEnum,
  RedeemPoints,
  SortOption,
  SortType,
  VoucherOption,
} from "booking-app-v2/shared/types";
import { SearchSortingUtil } from "booking-app-v2/shared/utils";

export class FilterForm extends Base {
  name: string;
  starRatings: StarRatings;
  starRatingsCountList: StarRatingsCount;
  selectedVoucherTypeId: number;
  voucherDropdownConfig: Config<VoucherOption>;
  popularCategories: PopularCategories;
  reviewScore: ReviewScore;
  redeemPoints: RedeemPoints;
  cashRange: CashRange;
  sortDropdownConfig: Config<SortOption>;
  selectedSortValue: SortOption;

  name$ = new Subject<string>(); // to get the observable value when input to name field

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    name: string,
    starRatings: StarRatings,
    popularCategories: PopularCategories,
    reviewScore: ReviewScore,
    voucherDropdownConfig: Config<VoucherOption>,
    redeemPoints: RedeemPoints,
    cashRange: CashRange,
  ) {
    super(appSettingsService, globalData);

    this.name = name;
    this.starRatings = starRatings || this.initStarRatings();
    this.popularCategories = popularCategories || this.initPopularCategories();
    this.reviewScore = reviewScore || this.initReviewScore();
    this.voucherDropdownConfig = voucherDropdownConfig || this.initVoucherDropdownConfig();
    this.redeemPoints = redeemPoints;
    this.cashRange = cashRange;
    this.initSortDropdown();
  }

  updateStarRatingsCountList(filteredHotelsList: Hotel[]): void {
    const starRatingsArray: StarRatingsCount = {};
    this.appSettingsService.ratingArray.forEach(rating => starRatingsArray[rating] = 0);
    this.starRatingsCountList = starRatingsArray;

    filteredHotelsList.map(hotel => {
      if (hotel.available && hotel.rating) {
        this.starRatingsCountList[hotel.rating]++;
      }
    });
  }

  initSortDropdown(): void {
    const sortOptions: SortOption[] = [];
    SearchSortingUtil.permittedOptions.forEach((sortKey: SortType) => {
      sortOptions.push({ ...SearchSortingUtil.options[sortKey] });
    });
    this.sortDropdownConfig = {
      options: sortOptions,
      labelKey: "label",
      dropdownLabel: "Sort By:",
    };
    this.selectedSortValue = { ...SearchSortingUtil.options[SearchSortingUtil.current] };

  }

  private initStarRatings(): StarRatings {
    const starRatingsArray: StarRatings = {};
    this.appSettingsService.ratingArray.forEach(rating => starRatingsArray[rating] = false);
    return starRatingsArray;
  }

  private initPopularCategories(): PopularCategories {
    return {
      business: false,
      family: false,
      couple: false,
      solo: false,
    };
  }

  private initReviewScore(): ReviewScore {
    return { min: 2.5, max: 5, floor: 2.5, ceil: 5 };
  }

  private initVoucherDropdownConfig(): Config<VoucherOption> {
    const voucherOptions: VoucherOption[] = [{
      voucherId: 0,
      voucherName: "Show All Voucher Categories",
    }];

    this.globalData.get(GlobalDataEnum.LANDING_PAGE).voucher_types.forEach(voucher => {
      voucherOptions.push({
        voucherId: +voucher.id,
        voucherName: voucher.name,
      });
    });

    return {
      options: voucherOptions,
      labelKey: "voucherName",
      sortBy: (a: VoucherOption, b: VoucherOption) => a.voucherId - b.voucherId,
    };
  }

}
