import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { ContactUsService } from "booking-app-v2/contact-us-page/services/contact-us.service";
import { ContactForm, ContactUsConfig, FormStatus } from "booking-app-v2/shared/types";

@Component({
  templateUrl: "/html/company/contact_us_v2",
})
export class ContactUsPageComponent {

  formStatus: FormStatus; // ContactForm in contact_us_form
  contactForm: ContactForm;
  contactUsConfig: ContactUsConfig;
  userForm: FormGroup;

  constructor(
    private translateService: TranslateService,
    private appSettingsService: AppSettingsService,
    private contactUsService: ContactUsService,
  ) {
    this.contactUsConfig = this.appSettingsService.contactUsConfig;
    this.formStatus = { processing: false, success: false, errors: [] };
    this.userForm = new FormGroup({
      question: new FormControl("", [Validators.required]),
      details: new FormControl("", [Validators.required]),
      name: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
    });
  }

  get userFormControl() {
    return this.userForm.controls;
  }

  public getHeadingText(): string {
    return this.contactUsConfig.contactUsHeadingText;
  }

  public displayEmailText(): boolean {
    return this.contactUsConfig.contactUsEmailText;
  }

  public getParagraphText(): string {
    return this.contactUsConfig.contactUsParagraphText;
  }

  public serviceEmail(): string {
    return this.appSettingsService.serviceEmail;
  }

  public onSubmit(): void {
    this.markFormAsDirty();

    if (!this.userForm.valid) {
      return;
    }

    this.startProcess();
    this.contactUsService.submitContactUsForm(this.contactForm)
      .pipe(finalize(() => this.formStatus.processing = false))
      .subscribe({
        next: _data => {
          this.formStatus.success = true;
        },
        error: e => {
          this.formStatus.errors = e.error.errors;
        },
      });
  }

  private startProcess(): void {
    this.formStatus.processing = true;

    const formControl = this.userFormControl;
    this.contactForm = {
      question: formControl.question.value,
      details: formControl.details.value,
      name: formControl.name.value,
      email: formControl.email.value,
    };
  }

  private markFormAsDirty(): void {
    this.userFormControl.email.markAsDirty();
    this.userFormControl.details.markAsDirty();
    this.userFormControl.name.markAsDirty();
    this.userFormControl.question.markAsDirty();
  }
}
