import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { MobileSideMenuComponent } from "./mobile-side-menu.component";
import { LocaleDropdownModule } from "booking-app-v2/shared/components/locale-dropdown/locale-dropdown.module";
import { CurrencyDropdownModule } from "booking-app-v2/shared/components/currency-dropdown/currency-dropdown.module";

@NgModule({
  declarations: [ MobileSideMenuComponent ],
  imports: [
    SharedModule,
    LocaleDropdownModule,
    CurrencyDropdownModule,
  ],
  exports: [ MobileSideMenuComponent ],
  providers: [],
})
export class MobileSideMenuModule {}
