import { Pipe, PipeTransform } from "@angular/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { Currency } from "booking-app-v2/shared/models";

@Pipe({
  name: "codeSymbolFmt",
  pure: false,
})
export class CodeSymbolFormatPipe implements PipeTransform {

  constructor(
    private appSettingsService: AppSettingsService,
    private currenciesService: CurrenciesService,
  ) { }

  transform(currencyCode: string): string {
    const currency: Currency = this.currenciesService.findByCode(currencyCode);
    if (!currency) {
      return "";
    }
    return currency[this.appSettingsService.defaultCurrencyDisplay];
  }
}
