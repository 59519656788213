import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { Base } from "booking-app-v2/shared/models";
import { Hotel } from "booking-app-v2/hotels/models";
import { MAPBOX_STATE_VIEW, MAPBOX_STATE_VIEW_MODE, MapboxStateViewMode } from "booking-app-v2/shared/types";

export class MapboxState extends Base {
  viewMode: MapboxStateViewMode = MAPBOX_STATE_VIEW_MODE.List;
  savedViewMode: string;
  view: string;
  loaded: boolean;
  hoveredHotel: Hotel;
  selectedHotel: Hotel;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
  ) {
    super(appSettingsService, globalData);

    this.viewMode = MAPBOX_STATE_VIEW_MODE.List;
    this.savedViewMode = MAPBOX_STATE_VIEW_MODE.List;
    this.view = MAPBOX_STATE_VIEW.ResultPage;
    this.loaded = false;
    this.hoveredHotel = undefined;
    this.selectedHotel = undefined;
  }

  isValidViewMode(viewMode: string) {
    const viewModes = Object.keys(MAPBOX_STATE_VIEW_MODE).map(e => MAPBOX_STATE_VIEW_MODE[e]);
    return viewModes.indexOf(viewMode) > -1;
  }
}
