import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { AccordionModule } from "../accordion/accordion.module";
import { HotelTaxAndFeesAccordionComponent } from "./hotel-tax-and-fees-accordion.component";

@NgModule({
  declarations: [HotelTaxAndFeesAccordionComponent],
  imports: [
    SharedModule,
    AccordionModule,
  ],
  exports: [HotelTaxAndFeesAccordionComponent],
})
export class HotelTaxAndFeeAccordionModule {}
