import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { VoucherFieldComponent } from "./voucher-field.component";

@NgModule({
  declarations: [VoucherFieldComponent],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
  ],
  exports: [VoucherFieldComponent],
})
export class VoucherFieldModule { }
