import { Injectable } from "@angular/core";
import { finalize, ReplaySubject, take } from "rxjs";

import { BaseStep } from "./base-step";
import {
  CHECKOUT_RESULT_NAME,
  CarFetchPriceResult,
  CheckoutResultName,
  CheckoutResults,
  GlobalDataEnum,
} from "booking-app-v2/shared/types";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CarUtilService } from "booking-app-v2/cars/services/car-util.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { ActivatedRoute } from "@angular/router";
import { CarCommonQueryParams, CarPricesRawResponse } from "booking-app-v2/cars/types";
import { CouponService } from "booking-app-v2/shared/services/coupon.service";

@Injectable({
  providedIn: "root",
})
export class CarFetchPriceService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.CAR_FETCH_PRICE;

  private couponCode: string = "";

  constructor(
    private globalData: GlobalData,
    private activatedRoute: ActivatedRoute,
    private carUtilService: CarUtilService,
    private windowRefService: WindowRefService,
    private couponService: CouponService,
  ) {}

  process(results: CheckoutResults): CarFetchPriceResult {
    const result: ReplaySubject<CarPricesRawResponse> = new ReplaySubject<CarPricesRawResponse>(1);

    // If coupon code is set, wait for the coupon validation to complete
    if (this.couponService.hasCouponCode()) {
      (results[CHECKOUT_RESULT_NAME.VALIDATE_COUPON_CODE])
        .pipe(take(1))
        .subscribe((valid: boolean) => {
          if (valid) {
            this.couponCode = this.couponService.couponCodeDetails.code;
            this.couponService.validatingPriceChanged = true;
            this.callFetchPrice(result, this.couponCode);
          }
        });
      return result.asObservable();
    }

    this.callFetchPrice(result);
    return result.asObservable();
  }

  private callFetchPrice(result: ReplaySubject<CarPricesRawResponse>, couponCode?: string): void {
    this.globalData.set(GlobalDataEnum.CAR_RESULT_STILL_POLLING, true);

    this.carUtilService.fetchCarsSinglePrice(
      this.activatedRoute.snapshot.queryParams as CarCommonQueryParams,
      this.windowRefService.getURIParamFromWindow(),
      couponCode,
    )
    .pipe(finalize(() => this.globalData.set(GlobalDataEnum.CAR_RESULT_STILL_POLLING, false)))
    .subscribe({
      next: (rawCar: CarPricesRawResponse) => result.next(rawCar),
      error: () => result.next(undefined),
    });
  }
}
