import { Observable, of } from "rxjs";
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";

import { GlobalDataEnum, PRODUCT_TYPE } from "booking-app-v2/shared/types";
import { Room } from "booking-app-v2/hotels/models";
import { VoucherService } from "booking-app-v2/shared/services/voucher.service";
import { Car } from "booking-app-v2/cars/models";

export function voucherCodeErrorValidator(voucherService: VoucherService, travelItem: Room | Car): AsyncValidatorFn {
  const reachedMaximumAllowableVoucher = (): boolean => {
    return voucherService.vouchers.length === 1 &&
      !travelItem.globalData.get(GlobalDataEnum.LANDING_PAGE).hasProductType(PRODUCT_TYPE.VOUCHER);
  };

  const hasDuplicateVoucher = (voucherString: string): boolean => {
    if (voucherService.vouchers.length > 0) {
      for (const appliedVoucher of voucherService.vouchers) {
        if (appliedVoucher.uid === voucherString) {
          return true;
        }
      }
    }
    return false;
  };

  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (control.value === "") {
      return of(null);
    }

    if (reachedMaximumAllowableVoucher()) {
      return of({ errorMessage: "Only one voucher can be applied." });
    }

    if (hasDuplicateVoucher(control.value)) {
      return of({
        errorMessage: "This voucher code has already been added to the booking. Please add a different code.",
      });
    }

    return voucherService.validateVoucherCode(travelItem, control.value);
  };
}
