import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { CheckoutFamilyMilesComponent } from "./checkout-family-miles.component";

@NgModule({
  declarations: [ CheckoutFamilyMilesComponent ],
  imports: [  SharedModule ],
  exports: [ CheckoutFamilyMilesComponent ],
  providers: [],
})
export class CheckoutFamilyMilesModule {}
