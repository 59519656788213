import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { BottomSheetService } from "booking-app-v2/shared/services/bottom-sheet.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";

import { Hotels, HotelSearchForm } from "booking-app-v2/hotels/models";
import { LandingPage, PointsPartner } from "booking-app-v2/shared/models";
import { GlobalDataEnum, ProductType } from "booking-app-v2/shared/types";

@Component({
  selector: "hotel-result-filter-mobile",
  templateUrl: "/html/components/hotel_result_filter_mobile_v2",
})
export class HotelResultFilterMobileComponent implements OnInit {
  @Input() hotels: Hotels;
  @Input() showRedeemPointsSlider: boolean;
  @Input() showCashSlider: boolean;
  @Input() hideRedemptionSliderOnFullCash: boolean;
  @ViewChild("filterBottomSheet") filterBottomSheetTemplate: TemplateRef<any>;

  filterIconSource: string;
  landingPage: LandingPage;
  pointsPartner: PointsPartner;
  hotelSearchForm: HotelSearchForm;

  constructor(
    private bottomSheetService: BottomSheetService,
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
  ) { }

  ngOnInit() {
    this.filterIconSource = this.appSettingsService.mobileResultPopupConfig.filterIconSource;
    this.landingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
    this.pointsPartner = this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
    this.hotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
  }

  openBottomSheet(): void {
    this.bottomSheetService.open(this.filterBottomSheetTemplate, ["hotel-result-filter-mobile"]);
  }
}
