import { CarRawResponse } from "booking-app-v2/cars/types";

export interface CarPricesRawResponse {
  cars: CarRawResponse[];
  completed: boolean;
  currency: string;
  search?: {
    pickup_location: string;
    pickup_time: string;
    return_location: string;
    return_time: string;
  };
}

export const defaultCarPricesRawResponse: CarPricesRawResponse = {
  cars: [],
  completed: true,
  currency: "USD",
  search: null,
};
