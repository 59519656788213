import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { HotelDetailsPageQueryParamsData } from "booking-app-v2/hotels/types";
import { Hotel } from "booking-app-v2/hotels/models";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { HotelsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/hotels-url-builder.service";
import { PointsCashPaymentHandlingService } from "booking-app-v2/shared/services/points-cash-payment-handling.service";
import { Currency, PointsPartner } from "booking-app-v2/shared/models";

@Component({
  selector: "hotel-list-item",
  templateUrl: "/html/hotels/results_list_item_v2",
})
export class HotelListItemComponent implements OnInit {
  @Input() hotel: Hotel;
  @Input() showAdminData: boolean;
  @Output() adminDataToggled: EventEmitter<void> = new EventEmitter<void>();

  isSelectedHotel: boolean;
  hotelDetailsUrl: string;
  stayPeriod: number;
  earnAmount: number;
  showEarnOnBurn: boolean;

  constructor(
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private hotelsUrlBuilderService: HotelsUrlBuilderService,
    private pointsCashPaymentHandlingService: PointsCashPaymentHandlingService,
  ) { }

  ngOnInit() {
    this.isSelectedHotel =
      this.hotel.id === this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM).destination.value.hotel_id;
    this.hotelDetailsUrl = this.hotel.available
      ? this.hotelsUrlBuilderService.getHotelDetailsUrl(this.hotel.id)
      : undefined;
    this.stayPeriod = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM).duration;
    this.earnAmount = this.hotel.priceInfo.points + this.hotel.priceInfo.bonus;
    this.showEarnOnBurn = this.appSettingsService.payWithPointsSettings.earnOnBurn;
  }

  get pointsPartner(): PointsPartner {
    return this.globalData.get(GlobalDataEnum.POINTS_PARTNER);
  }

  get selectedCurrency(): Currency {
    return this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
  }

  // angular-v2-todo: we need to implement tracker service to implement this.
  trackRedirect(): void {

  }

  hotelDetailsQueryParams(): HotelDetailsPageQueryParamsData {
    return this.hotelsUrlBuilderService.buildDetailsPageUrl();
  }

  // angular-v2-todo: Not sure if this is legacy code.
  toggleAdminData($event) {
    $event.preventDefault();
    this.adminDataToggled.emit();
  }

  // earn specific
  showAppName(): boolean {
    return true;
    // angular-v2-todo: implement when whitelabelPartner is ready
    // return !this.whitelabelPartner.hideCompareRatesUI;
  }

  zeroFullCashPayment(): boolean {
    return this.pointsCashPaymentHandlingService.zeroFullCashPayment(
      this.hotel.priceInfo.cash_payment,
      this.hotel.priceInfo.points_payment,
    );
  }
}
