import { Config } from "@ascenda/ngx-simple-dropdown";

import { Base } from "booking-app-v2/shared/models";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AirlineFilterUnit, StopoverFilterUnit, TimeRange } from "booking-app-v2/flights/types";
import { SearchSortingUtil, TimeUtils } from "booking-app-v2/shared/utils";
import { RedeemPoints, SortOption, SortType } from "booking-app-v2/shared/types";

export class FilterForm extends Base {
  stopoverFilterUnits: StopoverFilterUnit[];
  airlineFilterUnits: AirlineFilterUnit[];
  departureRangeFilter: TimeRange;
  arrivalRangeFilter: TimeRange;
  redeemPoints: RedeemPoints;
  sortDropdownConfig: Config<SortOption>;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    stopoverFilterUnits: StopoverFilterUnit[],
    airlineFilterUnits: AirlineFilterUnit[],
    departureRangeFilter: TimeRange,
    arrivalRangeFilter: TimeRange,
    redeemPoints: RedeemPoints,
  ) {
    super(appSettingsService, globalData);
    this.stopoverFilterUnits = stopoverFilterUnits || this.initStopoverFilterUnit();
    this.airlineFilterUnits = airlineFilterUnits || [];
    this.departureRangeFilter = departureRangeFilter || this.initTimeRangeFilterUnit();
    this.arrivalRangeFilter = arrivalRangeFilter || this.initTimeRangeFilterUnit();
    this.redeemPoints = redeemPoints;
    this.initSortDropdown();
  }

  initStopoverFilterUnit(): StopoverFilterUnit[] {
    return [{
      isSelected: false,
      minSegmentCount: 1,
      maxSegmentCount: 1,
      label: "Direct",
      id: "stopover-1-1",
    }, {
      isSelected: false,
      minSegmentCount: 2,
      maxSegmentCount: 2,
      label: SearchSortingUtil.whitelabelTranslateService.translate("one_stop"),
      id: "stopover-2-2",
    }, {
      isSelected: false,
      minSegmentCount: 3,
      maxSegmentCount: 100,
      label: SearchSortingUtil.whitelabelTranslateService.translate("two_stop"),
      id: "stopover-3-100",
    }];
  }

  initTimeRangeFilterUnit(): TimeRange {
    return {
      min: TimeUtils.MIN_MINUTES_IN_DAY,
      max: TimeUtils.MAX_MINUTES_IN_DAY,
      floor: TimeUtils.MIN_MINUTES_IN_DAY,
      ceil: TimeUtils.MAX_MINUTES_IN_DAY,
    };
  }

  initSortDropdown(): void {
    const sortOptions: SortOption[] = [];
    SearchSortingUtil.permittedOptions.forEach((sortKey: SortType) => {
      sortOptions.push({ ...SearchSortingUtil.options[sortKey] });
    });
    this.sortDropdownConfig = {
      options: sortOptions,
      labelKey: "label",
      dropdownLabel: "Sort By:",
    };
  }
}
