import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { contactUsPageRoutes } from "./contact-us-page.routes";

@NgModule({
  imports: [
    RouterModule.forChild(contactUsPageRoutes),
  ],
  exports: [RouterModule],
})

export class ContactUsPageRoutingModule { }
