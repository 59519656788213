import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { Base, PointsPartner } from "booking-app-v2/shared/models/index";

export class PointsPartners extends Base {
  readonly all: PointsPartner[];
  readonly visible: PointsPartner[];
  readonly responsive: any[];

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    partnersList: PointsPartner[],
  ) {
    super(appSettingsService, globalData);

    this.all = partnersList;
    this.visible = partnersList.filter((pointsPartner: PointsPartner) => pointsPartner.visible);
    this.responsive = [
      {
        breakpoint: 10240,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ];
  }

  findById(id: number): PointsPartner {
    return this.all.find((pointsPartner: PointsPartner) => {
      return pointsPartner.id === id;
    });
  }
}
