import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CountrySelection } from "../types/country-selection.type";

@Injectable({
  providedIn: "root",
})
export class RegionSearchDropdownService {
  selectedCountrySubject = new BehaviorSubject<CountrySelection | null>(null);
  selectedCountry$ = this.selectedCountrySubject.asObservable();

  setSelectedCountry(country: CountrySelection) {
    this.selectedCountrySubject.next(country);
  }
}
