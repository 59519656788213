import { debounceTime, distinctUntilChanged, Subscription } from "rxjs";
import { ChangeDetectorRef } from "@angular/core";

import { Base, LandingPage } from "booking-app-v2/shared/models";
import {
  CashRange,
  GlobalDataEnum,
  KEYPRESS,
  RedeemPoints,
  SortType,
  VoucherOption,
} from "booking-app-v2/shared/types";
import { FilterForm, Hotel } from "booking-app-v2/hotels/models";
import { HotelRawPrice, PopularCategories, ReviewScore } from "booking-app-v2/hotels/types";
import { FilterUtil } from "booking-app-v2/hotels/utils";
import { SearchSortingUtil } from "booking-app-v2/shared/utils";
import { HotelUtilService } from "booking-app-v2/hotels/services";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";

export class Hotels extends Base {
  unFilteredList: Hotel[];
  preFilteredList: Hotel[];
  filteredList: Hotel[];
  visibleList: Hotel[];
  filterForm: FilterForm;
  searchCompleted: boolean;
  counters: { all: number; displayed: number; available: number } = { all: 0, displayed: 0, available: 0 };
  defaultRedeemPoints: RedeemPoints;
  defaultCashRange: CashRange;
  defaultReviewScore: ReviewScore;

  private hotelNameSubscription: Subscription;
  private readonly hotelUtilService: HotelUtilService;
  private readonly cdRef: ChangeDetectorRef;
  private readonly landingPage: LandingPage;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    cdRef: ChangeDetectorRef,
    hotels: Hotel[],
    hotelUtilService?: HotelUtilService,
  ) {
    super(appSettingsService, globalData);
    this.unFilteredList = hotels;
    this.preFilteredList = [];
    this.filteredList = [];
    this.visibleList = [];
    this.hotelUtilService = hotelUtilService;
    this.cdRef = cdRef;
    this.landingPage = globalData.get(GlobalDataEnum.LANDING_PAGE);
    this.defaultReviewScore = { min: 2.5, max: 5, floor: 2.5, ceil: 5 };

    this.initFilterForm();
  }

  filter(): void {
    this.filteredList = FilterUtil.filterHotelsWithFilterForm(this.preFilteredList, this.filterForm);
    this.visibleList = this.filteredList.slice(0, 8);
    this.countDisplayedHotels();
    this.countStarRateList();
    this.sortHotel(SearchSortingUtil.current);
    this.pushSelectedHotelToTop();
    this.cdRef.markForCheck();
  }

  sortHotel(newOption?: SortType): void {
    if (newOption) {
      SearchSortingUtil.current = newOption;
      this.filterForm.selectedSortValue = { ...SearchSortingUtil.options[newOption] };
    }
    this.filteredList.sort(SearchSortingUtil.currentSortFunction);
    this.visibleList = this.filteredList.slice(0, 8);
  }

  preFilter(hotelRawPrices: HotelRawPrice[]): void {
    this.unFilteredList = this.hotelUtilService.collatePricesToDetails(hotelRawPrices, this.unFilteredList);
    this.preFilteredList = FilterUtil.preFilterHotels(this.unFilteredList, this.searchCompleted);
    this.countAvailableHotels();
    this.setRedeemPointsSliderValues();
    this.setCashSliderValues();
  }

  resetFilter(): void {
    this.initFilterForm();
    this.filter();
    this.resetSliders();
  }

  countAllHotels(): void {
    this.counters.all = this.getAllHotelsLength();
  }

  filterHotelsByName(name: string): void {
    this.filterForm.name = name;
    this.filterForm.name$.next(name);
  }

  filterHotelsByStarRatings(rating: number, checkedStatus: boolean) {
    this.filterForm.starRatings[rating] = checkedStatus;
    this.filter();
  }

  filterHotelsByVoucher(voucher: VoucherOption) {
    this.filterForm.selectedVoucherTypeId = +voucher.voucherId;
    this.filter();
  }

  filterByPopularCategory(popularCategory: keyof PopularCategories, checkedStatus: boolean): void {
    this.filterForm.popularCategories[popularCategory] = checkedStatus;
    this.filter();
  }

  clearDisplayedHotels(): void {
    this.filteredList = [];
  }

  filterByRedeemPoints(redeemPoints: RedeemPoints): void {
    this.filterForm.redeemPoints = redeemPoints;
    this.filter();
  }

  filterByCashRange(cashRange: CashRange): void {
    this.filterForm.cashRange = cashRange;
    this.filter();
  }

  filterByReviewScore(reviewScore: ReviewScore): void {
    this.filterForm.reviewScore = reviewScore;
    this.filter();
  }

  triggerKeydownTogglePopularFilter(popularCategory: keyof PopularCategories, event: KeyboardEvent): void {
    if (event.key === KEYPRESS.ENTER || event.key === KEYPRESS.SPACEBAR || event.key === KEYPRESS.SPACEBAR_ALT) {
      event.preventDefault();
      this.filterByPopularCategory(popularCategory, !this.filterForm.popularCategories[popularCategory]);
    }
  }

  triggerKeydownToggleStarRatingFilter(rating: number, event: KeyboardEvent): void {
    if (event.key === KEYPRESS.ENTER || event.key === KEYPRESS.SPACEBAR || event.key === KEYPRESS.SPACEBAR_ALT) {
      event.preventDefault();
      this.filterHotelsByStarRatings(rating, !this.filterForm.starRatings[rating]);
    }
  }

  pushMoreVisibleHotels(): void {
    this.visibleList = this.filteredList.slice(0, this.visibleList.length + 8);
  }

  private setRedeemPointsSliderValues(): void {
    if (!this.landingPage.complimentaryOrRedeem()) {
      return;
    }
    let pointsPayment: number;
    let isFirstHotel: boolean = true;
    const redeemPointsRange: RedeemPoints = { min: 0, max: 0, ceil: 0, floor: 0 };
    this.preFilteredList.forEach((hotel: Hotel) => {
      if (this.appSettingsService.showTotalNights) {
        pointsPayment = hotel.priceInfo.points_payment;
      } else {
        pointsPayment = hotel.priceInfo.points_payment_per_night;
      }

      if (pointsPayment) {
        if (redeemPointsRange.max < pointsPayment || isFirstHotel) {
          redeemPointsRange.max = pointsPayment;
          redeemPointsRange.ceil = pointsPayment;
        }
        if (redeemPointsRange.min > pointsPayment || isFirstHotel) {
          redeemPointsRange.min = pointsPayment;
          redeemPointsRange.floor = pointsPayment;
        }
        isFirstHotel = false;
      }
    });
    this.defaultRedeemPoints = redeemPointsRange;
    this.filterForm.redeemPoints = undefined;
  }

  private setCashSliderValues(): void {
    if (this.landingPage.complimentaryOrRedeem()) {
      return;
    }
    let min: number = 0;
    let max: number = 0;
    let isFirstHotel: boolean = true;
    this.preFilteredList.forEach((hotel: Hotel) => {
      const hotelPrice: number = hotel.priceInfo?.price;
      if (hotelPrice && hotel.available) {
        if ((min > hotelPrice) || isFirstHotel) {
          min = hotelPrice;
        }
        if ((max < hotelPrice) || isFirstHotel) {
          max = hotelPrice;
        }
        isFirstHotel = false;
      }
    });
    this.defaultCashRange = { min, max, floor: min, ceil: max };
    this.filterForm.cashRange = undefined;
  }

  private initFilterForm(): void {
    this.filterForm = new FilterForm(
      this.appSettingsService,
      this.globalData,
      "",
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    this.initHotelNameFilter();
  }

  private initHotelNameFilter(): void {
    this.hotelNameSubscription?.unsubscribe();
    this.hotelNameSubscription = this.filterForm.name$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.filter();
    });
  }

  private countStarRateList(): void {
    /**
     * Star rated hotel list count is calculated everytime the filter
     * is fired. However this count should exclude the results filtered by
     * star rate filter. Therefore everytime this count is calculated,
     * we need to get the list by resetting the star rate filter.
    */
    const filteredListWithoutStarRateFilter: Hotel[] =
      FilterUtil.filterHotelsWithFilterForm(
        this.preFilteredList,
        new FilterForm(
          this.filterForm.appSettingsService,
          this.filterForm.globalData,
          this.filterForm.name,
          undefined,
          this.filterForm.popularCategories,
          this.filterForm.reviewScore,
          this.filterForm.voucherDropdownConfig,
          this.filterForm.redeemPoints,
          this.filterForm.cashRange,
        ),
      );
    this.filterForm.updateStarRatingsCountList(filteredListWithoutStarRateFilter);
  }

  private countAvailableHotels(): void {
    this.counters.available = this.preFilteredList.filter((hotel: Hotel) => hotel.available).length;
  }

  private countDisplayedHotels(): void {
    this.counters.displayed = this.filteredList.length;
  }

  private getAllHotelsLength(): number {
    let allHotels: Hotel[];

    if (this.appSettingsService.includeUnavailableInTotalCount) {
      allHotels = this.unFilteredList;
    } else {
      allHotels = this.filteredList;
    }
    return allHotels.length;
  }

  private pushSelectedHotelToTop(): void {
    const selectedHotelId: string = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM).destination.value.hotel_id;
    if (!selectedHotelId) {
      return;
    }

    const selectedHotel: Hotel = this.unFilteredList.find(hotel => hotel.id === selectedHotelId);
    if (!selectedHotel) {
      return;
    }

    // remove duplicate hotel if hotel available
    const selectedHotelIdx: number = this.filteredList.findIndex(hotel => hotel.id === selectedHotelId);
    if (selectedHotelIdx !== -1) {
      this.filteredList.splice(selectedHotelIdx, 1);
    }

    // add hotel to top
    this.filteredList.unshift(selectedHotel);
  }

  private resetSliders(): void {
    this.defaultRedeemPoints = { ...this.defaultRedeemPoints };
    this.defaultCashRange = { ...this.defaultCashRange };
    this.defaultReviewScore = { min: 2.5, max: 5, floor: 2.5, ceil: 5 };
  }
}
