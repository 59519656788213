import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { RawCarDestination } from "../types";
import { Base } from "booking-app-v2/shared/models";

export class CarDestination extends Base {
  id: string;
  value: string;
  type: string;
  lat: string;
  lng: string;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    rawCarDestination: RawCarDestination,
  ) {
    super(appSettingsService, globalData);

    this.id = rawCarDestination.value;
    this.value = rawCarDestination.term;
    this.type = rawCarDestination.type;
    if (rawCarDestination.coordinates) {
      this.lat = rawCarDestination.coordinates.lat;
      this.lng = rawCarDestination.coordinates.lng;
    }
  }
}
