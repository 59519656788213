import { Injectable } from "@angular/core";
import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { E2eUserPreferences } from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class E2eUserPreferenceService {

  constructor(
    private apiDataService: ApiDataService,
  ) { }

  updatePreferences(preferences: E2eUserPreferences): void {
    this.apiDataService.post("e2e/update_preferences", preferences);
  }
}
