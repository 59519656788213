import { Component, Input} from "@angular/core";

@Component({
  selector: "hotel-remarks",
  template: `
    <div class="instruction" *ngIf="content">
      <strong class="capitalize">{{ heading | translate }}</strong>
      <br />
      <div [innerHTML]="content"></div>
    </div>
  `,
})
export class HotelRemarksComponent {
  @Input() heading: string;
  @Input() content: string;
}
