import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

import { Amenities, TrustYouReviews } from "booking-app-v2/hotels/types";

@Component({
  selector: "amenities",
  template: `
    <div class="amenities-list hidden-xs">
      <div class="guest-love-list" *ngIf="guestLoveList.length > 0">
        <div class="title color-headline" [translate]="'txt.guest_love'"></div>
        <div class="item-container">
          <ng-container *ngFor="let list of guestLoveList">
            <div class="amenity-item">
              <em class="fa fa-check"></em>
              <span [translate]="list"></span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="facilities-list" *ngIf="hasAmenities">
        <div class="title color-headline" [translate]="'txt.facilities'"></div>
        <div class="item-container">
          <ng-container *ngFor="let amenity of amenities | keyvalue">
            <div class="amenity-item" *ngIf="amenity.value">
              <em class="fa fa-check"></em>
              <span [translate]="amenity.key"></span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  `,
})
export class AmenitiesComponent implements OnInit, OnChanges {
  @Input() amenities: Amenities;
  @Input() trustYouReviews: TrustYouReviews;

  hasAmenities: boolean;
  guestLoveList: string[] = [];

  @HostBinding("hidden")
  get shouldHideComponent(): boolean {
    return (this.guestLoveList.length === 0) && !this.hasAmenities;
  }

  ngOnInit() {
    this.hasAmenities = Object.keys(this.amenities).length > 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.trustYouReviews.currentValue?.good_to_know_list) {
      this.guestLoveList = this.trustYouReviews.good_to_know_list
        .filter(comment => comment.sentiment === "pos")
        .map(comment => comment.title);
    }
  }
}
