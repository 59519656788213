import { Injectable } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import {
  CommonCollectBookingParamsService,
} from "booking-app-v2/shared/services/checkout/steps/common-collect-booking-params.service";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";

import { BaseStep } from "./base-step";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutParamsDebugInfo,
  CheckoutParamsFlightSpecific,
  CheckoutParamsMembership,
  CheckoutParamsPassengerInfo,
  CheckoutParamsUser,
  CheckoutResultName,
  CheckoutResults,
  FlightCollectBookingParamsResult,
  GlobalDataEnum,
  PRODUCT_TYPE,
  SelectPaymentChannelResult,
} from "booking-app-v2/shared/types";
import { Flight } from "booking-app-v2/flights/models";

@Injectable({
  providedIn: "root",
})
export class FlightCollectBookingParamsService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.FLIGHT_COLLECT_BOOKING_PARAMS;
  private checkoutForm: FormGroup;
  private flight: Flight;
  private voucherIds: string[];
  private selectPaymentChannelResult: SelectPaymentChannelResult;

  constructor(
    private globalData: GlobalData,
    private commonCollectBookingParamsService: CommonCollectBookingParamsService,
    private payWithPointsCashService: PayWithPointsCashService,
  ) { }

  process(results: CheckoutResults): FlightCollectBookingParamsResult {
    this.initializeCheckoutData(results);

    return {
      ...this.commonCollectBookingParamsService.buildCommonBookingParams(
        this.checkoutForm,
        this.voucherIds,
        this.selectPaymentChannelResult.newCardSave,
      ),
      ...this.buildUserParams(),
      ...this.buildDebugInfoParams(),
      ...this.buildMembershipParams(),
      ...this.buildFlightSpecificParams(),
    };
  }

  private buildUserParams(): CheckoutParamsUser {
    return {
      user: {
        ...this.commonCollectBookingParamsService.buildCommonUserParams(this.checkoutForm),
        phone: this.commonCollectBookingParamsService.formatPhoneNumber(this.checkoutForm),
      },
    };
  }

  private buildFlightSpecificParams(): CheckoutParamsFlightSpecific {
    return {
      travel_type: "flights",
      passengers: this.buildPassengersData(),
    };
  }

  private buildDebugInfoParams(): CheckoutParamsDebugInfo {
    return {
      debug_info: {
        points_payment: (
          this.isRedeem() ?
          Math.max(this.payWithPointsCashService.pointsPayment(this.flight.rawPrice), 0) :
          0
        ),
        cash_payment: Math.max(this.payWithPointsCashService.getCalculatedCash(this.flight.rawPrice), 0),
        points_earned: this.flight.priceInfo?.points ?? 0,
        bonus_programs: this.flight.priceInfo?.bonus_programs,
        bonus_tiers: this.flight.priceInfo?.bonus_tiers,
        exchange_rate: this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY).rate,
      },
    };
  }

  private isRedeem() {
    return this.globalData.get(GlobalDataEnum.LANDING_PAGE).hasProductType(PRODUCT_TYPE.REDEEM);
  }

  private buildMembershipParams(): CheckoutParamsMembership {
    return {
      membership: {
        first_name: null,
        last_name: null,
        number: null,
      },
    };
  }

  private buildPassengersData(): CheckoutParamsPassengerInfo[] {
    const primaryPassengerData = this.commonPassengerData(this.checkoutForm);
    const secondaryPassengersData = (this.checkoutForm.controls.extraPassengers as FormArray).controls.map(
      (formGroup: FormGroup) => this.commonPassengerData(formGroup),
    );
    return [primaryPassengerData, ...secondaryPassengersData];
  }

  private initializeCheckoutData(results: CheckoutResults): void {
    this.selectPaymentChannelResult = results[CHECKOUT_RESULT_NAME.SELECT_PAYMENT_CHANNEL] as SelectPaymentChannelResult;
    this.flight = results.intermediateCheckoutParams.flight;
    this.checkoutForm = results.intermediateCheckoutParams.checkoutForm;
    this.voucherIds = results.intermediateCheckoutParams.voucherIds;
  }

  private commonPassengerData(checkoutForm: FormGroup): CheckoutParamsPassengerInfo {
    return {
      date_of_birth: this.getDateText(checkoutForm),
      first_name: checkoutForm.controls.firstName.value,
      last_name: checkoutForm.controls.lastName.value,
      passport_country: checkoutForm.controls.passportNationality.value.code,
      passport_number: checkoutForm.controls.passportNumber?.value,
      passport_expiry_date: this.getPassportExpiryText(checkoutForm),
      salutation: checkoutForm.controls.title.value,
      type: checkoutForm.controls.passengerType.value || "adult",
    };
  }

  private getDateText(checkoutForm: FormGroup): string {
    const day = this.paddedNumber(checkoutForm.controls.dateOfBirthDay.value);
    const month = this.paddedNumber(checkoutForm.controls.dateOfBirthMonth.value);
    const year = checkoutForm.controls.dateOfBirthYear.value;

    const dateText = [year, month, day].filter(item => item).join("-");

    return dateText === "" ? null : dateText;
  }

  private getPassportExpiryText(checkoutForm: FormGroup): string {
    const day = this.paddedNumber(checkoutForm.controls.passportExpiryDay?.value);
    const month = this.paddedNumber(checkoutForm.controls.passportExpiryMonth?.value);
    const year = checkoutForm.controls.passportExpiryYear?.value;

    const dateText = [year, month, day].filter(item => item).join("-");

    return dateText === "" ? null : dateText;
  }

  private paddedNumber(dayOrMonth: number): string {
    if (dayOrMonth) {
      return dayOrMonth < 10 ? `0${dayOrMonth}` : `${dayOrMonth}`;
    } else {
      return null;
    }
  }
}
