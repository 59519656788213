import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";

import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { HotelPricesRawResponse, HotelPricesResponse } from "../types";
import { Hotel } from "../models";
import { LocalCacheUtil } from "../utils";
import { HotelUtilService } from "./hotel-util.service";

@Injectable({
  providedIn: "root",
})
export class ResultsService {

  constructor(
    private apiDataService: ApiDataService,
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private hotelUtilService: HotelUtilService,
  ) { }

  getHotels(): Observable<Hotel[]> {
    return this.apiDataService
      .get(this.hotelUtilService.getHotelDestinationUrl())
      .pipe(
        map((rawHotelsArray: Hotel[]) => {
          return rawHotelsArray.map((rawHotel: Hotel) => {
            return new Hotel(this.appSettingsService, this.globalData, rawHotel);
          });
        }),
      );
  }

  getPrices(): Observable<HotelPricesResponse> {
    const cacheKey: string = this.hotelUtilService.getUrlForHotelPriceCache();
    return LocalCacheUtil.cacheCall("prices", cacheKey, 300, this.fetchRawPrices.bind(this), this.isPriceReady);
  }

  private fetchRawPrices(): Observable<HotelPricesResponse> {
    let results: HotelPricesResponse = {
      completed: false,
      hotelRawPrices: [],
    };

    return this.apiDataService.get(this.hotelUtilService.getUrlForPriceAndReviews("hotels/prices"))
      .pipe(map((resultPricesRawResponse: HotelPricesRawResponse) => {
        if (!!resultPricesRawResponse) {
          this.globalData.set(GlobalDataEnum.TAX_INCLUSIVE, !!resultPricesRawResponse.tax_inclusive);
          results = {
            completed: resultPricesRawResponse.completed,
            hotelRawPrices: resultPricesRawResponse.hotels,
          };
        }
        return results;
      }));
  }

  private isPriceReady(data): boolean {
    return data.completed;
  }

}
