import { HotelRawPrice} from "../types";
import { RoomPrice } from "../models";

export class RoomAdminRatesDecorator {

  static decorate(price: RoomPrice, rawPrice: HotelRawPrice, numberOfNights: number, roomsCount: number): void {
    if (!rawPrice.rate) {
      return;
    }
    price.adminPricePerNight = rawPrice.lowest_price / roomsCount / numberOfNights;
  }
}
