import { Hotel } from "booking-app-v2/hotels/models";
import { HotelSearchForm } from "booking-app-v2/hotels/models/hotel-search-form.model";
import { PointsPartner } from "booking-app-v2/shared/models";
import { Flight } from "booking-app-v2/flights/models";

import { TranslateService } from "@ngx-translate/core";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { WhitelabelTranslateService } from "booking-app-v2/shared/services/whitelabel-translate.service";

import { SortUtil } from "booking-app-v2/shared/utils";
import { CASH_POINTS_RATIO, GlobalDataEnum, SORT_TYPE, SortType, TRAVEL_TYPE, TravelType } from "booking-app-v2/shared/types";
import { Car } from "booking-app-v2/cars/models";

type SortOptions = {
  [sortType in SortType]? : {
    label: string;
    sort: (firstEl: Hotel | Flight | Car, secondEl: Hotel | Flight | Car) => number;
    key: SortType;
  }
};

export class SearchSortingUtil {

  static current: SortType = SORT_TYPE.DEAL;
  static originalPermittedOptions: SortType[] = [];
  static permittedOptions: SortType[] = [];
  static options: SortOptions;
  static appSettingsService: AppSettingsService;
  static pointsCashShareService: PointsCashShareService;
  static whitelabelTranslateService: WhitelabelTranslateService;
  static translateService: TranslateService;

  static init(
    appSettingsService: AppSettingsService,
    pointsCashShareService: PointsCashShareService,
    whitelabelTranslateService: WhitelabelTranslateService,
  ): void {
    this.appSettingsService = appSettingsService;
    this.pointsCashShareService = pointsCashShareService;
    this.whitelabelTranslateService = whitelabelTranslateService;
  }

  static reset(): void {
    this.current = this.permittedOptions[0];
  }

  static setCurrent(newOption: SortType): void {
    this.current = newOption;
  }

  static fireSortingEvent(): void {
    // angular-v2-todo: implement this after we move tracker service to v2
    // this.trackerService.sortingEvent(
    //   { order: this.options[this.current].label },
    // );
  }

  static get currentSortFunction(): (a: Hotel | Flight | Car, b: Hotel | Flight | Car) => number {
    return this.options[this.current].sort;
  }

  static get currentSortLabel(): string {
    return this.options[this.current].label;
  }

  static toggleBestForBusiness(): void {
    if (this.current === SORT_TYPE.BUSINESS) {
      this.current = this.permittedOptions[0];
    } else {
      this.current = SORT_TYPE.BUSINESS;
    }
  }

  static initializePermittedSortingOptions(landingPageOptions): void {
    this.originalPermittedOptions = JSON.parse(JSON.stringify(landingPageOptions));
    this.permittedOptions = [...this.originalPermittedOptions];
    this.reset();
  }

  static initializeCarsPermittedSortingOptions(sortingOptions: SortType[]): void {
    this.originalPermittedOptions = [...sortingOptions];
    this.permittedOptions = [...sortingOptions];
    this.reset();
  }

  static initializeFlightsPermittedSortingOptions(flightsEnabledSortingOptions: SortType[]): void {
    const enabledArr: SortType[] = (Object.keys(this.options) as SortType[]).filter((option: SortType) => {
      return flightsEnabledSortingOptions.includes(option);
    });
    this.permittedOptions.splice(0, this.permittedOptions.length, ...enabledArr);
    this.originalPermittedOptions = [...this.permittedOptions];
    this.updateSortingOptionsForPayWithPoints(TRAVEL_TYPE.FLIGHTS);
    this.reset();
  }

  static updateSortingOptionsForPayWithPoints(travelType: TravelType): void {

    if (!this.appSettingsService.sortSettings.canUseFullCashOnRedemption &&
      !this.appSettingsService.sortSettings.canUseFullPointsOnRedemption) {
      return;
    }

    this.permittedOptions = [...this.originalPermittedOptions];

    let fullCashSortArr: SortType[];
    let fullPointsSortArr: SortType[];
    if (travelType === TRAVEL_TYPE.FLIGHTS) {
      fullCashSortArr = [SORT_TYPE.FLIGHTS_PRICE_LOHI, SORT_TYPE.FLIGHTS_PRICE_HILO];
      fullPointsSortArr = [SORT_TYPE.FLIGHTS_MILES_LOHI, SORT_TYPE.FLIGHTS_MILES_HILO];
    } else {
      fullCashSortArr = [SORT_TYPE.PAY_POINTS_CASH_LOHI, SORT_TYPE.PAY_POINTS_CASH_HILO];
      fullPointsSortArr = [SORT_TYPE.PAY_POINTS_LOHI, SORT_TYPE.PAY_POINTS_HILO];
    }

    if (this.appSettingsService.sortSettings.canUseFullCashOnRedemption) {
      if (this.pointsCashShareService.pointsCashRatio() === CASH_POINTS_RATIO.FULLCASH) {
        const subsetWithoutPoints: SortType[] = this.getUpdatedPermittedSortArr(fullPointsSortArr);

        this.current = this.isCurrentCashOrPoints(fullPointsSortArr) ? fullCashSortArr[0] : this.current;

        if (subsetWithoutPoints.length > 0) {
          this.permittedOptions.splice(0, this.permittedOptions.length, ...subsetWithoutPoints);
        }

        if (!this.isSortingOptionsExist(fullCashSortArr)) {
          this.permittedOptions.push(...this.getMatchingSortArr(fullCashSortArr));
        }
      }
    }

    if (this.appSettingsService.sortSettings.canUseFullPointsOnRedemption) {
      if (this.pointsCashShareService.pointsCashRatio() === CASH_POINTS_RATIO.FULLPOINTS) {
        const subsetWithoutCash: SortType[] = this.getUpdatedPermittedSortArr(fullCashSortArr);

        this.current = this.isCurrentCashOrPoints(fullCashSortArr) ? fullPointsSortArr[0] : this.current;

        if (subsetWithoutCash.length > 0) {
          this.permittedOptions.splice(0, this.permittedOptions.length, ...subsetWithoutCash);
        }

        if (!this.isSortingOptionsExist(fullPointsSortArr)) {
          this.permittedOptions.push(...this.getMatchingSortArr(fullPointsSortArr));
        }
      }
    }

    if (this.pointsCashShareService.pointsCashRatio() === CASH_POINTS_RATIO.CASHANDPOINTS) {
      if (!this.isSortingOptionsExist(fullPointsSortArr)) {
        this.permittedOptions.push(...this.getMatchingSortArr(fullPointsSortArr));
      }

      if (!this.isSortingOptionsExist(fullCashSortArr)) {
        this.permittedOptions.push(...this.getMatchingSortArr(fullCashSortArr));
      }
    }
  }

  static initializeOptions(globalData: GlobalData): void {
    const hotelSearchForm: HotelSearchForm = globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    const pointsPartner: PointsPartner = globalData.get(GlobalDataEnum.POINTS_PARTNER);
    this.options  = {
      ...this.hotelOptions(hotelSearchForm, pointsPartner),
      ...this.carOptions(),
      ...this.flightOptions(pointsPartner),
    };
  }

  private static hotelOptions(hotelSearchForm: HotelSearchForm, pointsPartner: PointsPartner): SortOptions {
    return {
      [SORT_TYPE.DEAL]: {
        key: SORT_TYPE.DEAL,
        label: "Best Deal",
        sort: (firstEl: Hotel, secondEl: Hotel) => {
          if (firstEl.available && secondEl.available) {
            if (firstEl.priceInfo.searchRank < secondEl.priceInfo.searchRank) {
              return 1;
            } else if (firstEl.priceInfo.searchRank > secondEl.priceInfo.searchRank) {
              return -1;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.PRICE_LOHI]: {
        key: SORT_TYPE.PRICE_LOHI,
        label: "Price (Low to High)",
        sort: (firstEl: Hotel, secondEl: Hotel) => {
          if (firstEl.available && firstEl.priceInfo.price &&
            secondEl.available && secondEl.priceInfo.price ) {
            return SortUtil.compare(firstEl.priceInfo.price, secondEl.priceInfo.price, false);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.PRICE_HILO]: {
        key: SORT_TYPE.PRICE_HILO,
        label: "Price (High to Low)",
        sort: (firstEl: Hotel, secondEl: Hotel) => {
          if (firstEl.available && firstEl.priceInfo.price &&
            secondEl.available && secondEl.priceInfo.price ) {
            return SortUtil.compare(firstEl.priceInfo.price, secondEl.priceInfo.price, true);
          } else {
            return 1000000;
          }
        },
      },
      [SORT_TYPE.RATING_LOHI]: {
        key: SORT_TYPE.RATING_LOHI,
        label: "Star Rating (Low to High)",
        sort: (firstEl: Hotel, secondEl: Hotel) => {
          if (firstEl.rating &&
            secondEl.rating ) {
            return SortUtil.compare(firstEl.rating, secondEl.rating, false);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.RATING_HILO]: {
        key: SORT_TYPE.RATING_HILO,
        label: "Star Rating (High to Low)",
        sort: (firstEl: Hotel, secondEl: Hotel) => {
          if (firstEl.rating &&
            secondEl.rating ) {
            return SortUtil.compare(firstEl.rating, secondEl.rating, true);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.POINTS_HILO]: {
        key: SORT_TYPE.POINTS_HILO,
        label: `${ pointsPartner?.currency_short ?? "Points" } (High to Low)`,
        sort: (firstEl: Hotel, secondEl: Hotel) => {
          if (firstEl.available && firstEl.priceInfo.price &&
            secondEl.available && secondEl.priceInfo.price ) {
            return SortUtil.compare(
              firstEl.priceInfo.points + firstEl.priceInfo.bonus,
              secondEl.priceInfo.points + firstEl.priceInfo.bonus,
              true,
            );
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.PAY_POINTS_LOHI]: {
        key: SORT_TYPE.PAY_POINTS_LOHI,
        label: `${ pointsPartner?.currency_short ?? "Points" } (Low to High)`,
        sort: (firstEl: Hotel | Car, secondEl: Hotel | Car) => {
          if (firstEl.priceInfo.points_payment &&
            secondEl.priceInfo.points_payment ) {
            return SortUtil.compare(firstEl.priceInfo.points_payment, secondEl.priceInfo.points_payment, false);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.PAY_POINTS_HILO]: {
        key: SORT_TYPE.PAY_POINTS_HILO,
        label: `${ pointsPartner?.currency_short ?? "Points" } (High to Low)`,
        sort: (firstEl: Hotel | Car, secondEl: Hotel | Car) => {
          if (firstEl.priceInfo.points_payment &&
            secondEl.priceInfo.points_payment ) {
            return SortUtil.compare(firstEl.priceInfo.points_payment, secondEl.priceInfo.points_payment, true);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.PAY_POINTS_CASH_LOHI]: {
        key: SORT_TYPE.PAY_POINTS_CASH_LOHI,
        label: "Price (Low to High)",
        sort: (firstEl: Hotel, secondEl: Hotel) => {
          if (firstEl.priceInfo.max_cash_payment &&
            secondEl.priceInfo.max_cash_payment ) {
            return SortUtil.compare(firstEl.priceInfo.max_cash_payment, secondEl.priceInfo.max_cash_payment, false);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.PAY_POINTS_CASH_HILO]: {
        key: SORT_TYPE.PAY_POINTS_CASH_HILO,
        label: "Price (High to Low)",
        sort: (firstEl: Hotel, secondEl: Hotel) => {
          if (firstEl.priceInfo.max_cash_payment &&
            secondEl.priceInfo.max_cash_payment ) {
            return SortUtil.compare(firstEl.priceInfo.max_cash_payment, secondEl.priceInfo.max_cash_payment, true);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.BEST_RATED]: {
        key: SORT_TYPE.BEST_RATED,
        label: "Best Rated",
        sort: (firstEl: Hotel, secondEl: Hotel) => {
          if (firstEl.trustyou && firstEl.available &&
            secondEl.trustyou && secondEl.available ) {
            return SortUtil.compare(
              firstEl.trustyou.score.kaligo_overall,
              secondEl.trustyou.score.kaligo_overall,
              true,
            );
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.BUSINESS]: {
        key: SORT_TYPE.BUSINESS,
        label: "Best for Business Travellers",
        sort: (firstEl: Hotel, secondEl: Hotel) => {
          if (firstEl.trustyou && firstEl.available &&
            secondEl.trustyou && secondEl.available ) {
            const score1 = firstEl.trustyou.score;
            const score2 = secondEl.trustyou.score;
            if (score1.business && score2.business) {
              return SortUtil.compare(score1.business, score2.business, true);
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.DISTANCE_LOHI]: {
        key: SORT_TYPE.DISTANCE_LOHI,
        label: `${ hotelSearchForm?.destination.value.type === "cit" ? "Distance to city center" : "Distance" }`,
        sort: (firstEl: Hotel, secondEl: Hotel) => {
          if (firstEl.available && firstEl.distance &&
            secondEl.available && secondEl.distance ) {
            return SortUtil.compare(firstEl.distance, secondEl.distance, false);
          } else {
            return 0;
          }
        },
      },
    };
  }

  private static carOptions(): SortOptions {
    return {
      [SORT_TYPE.CARS_PRICE_LOHI]: {
        key: SORT_TYPE.CARS_PRICE_LOHI,
        label: "Price (Low to High)",
        sort: (firstEl: Car, secondEl: Car) => {
          if (firstEl.priceInfo.price &&
            secondEl.priceInfo.price ) {
            return SortUtil.compare(
              firstEl.priceInfo.price,
              secondEl.priceInfo.price,
              false,
            );
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.CARS_PRICE_HILO]: {
        key: SORT_TYPE.CARS_PRICE_HILO,
        label: "Price (High to Low)",
        sort: (firstEl: Car, secondEl: Car) => {
          if (firstEl.priceInfo.price &&
            secondEl.priceInfo.price ) {
            return SortUtil.compare(
              firstEl.priceInfo.price,
              secondEl.priceInfo.price,
              true,
            );
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.CARS_POINT_HILO]: {
        key: SORT_TYPE.CARS_POINT_HILO,
        label: "Points (High to Low)",
        sort: (firstEl: Car, secondEl: Car) => {
          if (firstEl.priceInfo.price && secondEl.priceInfo.price ) {
            return SortUtil.compare(
              firstEl.priceInfo.points + firstEl.priceInfo.bonus,
              secondEl.priceInfo.points + firstEl.priceInfo.bonus,
              true,
            );
          } else {
            return 0;
          }
        },
      },
    };
  }

  private static flightOptions(pointsPartner: PointsPartner): SortOptions {
    return {
      [SORT_TYPE.FLIGHTS_PRICE_LOHI]: {
        key: SORT_TYPE.FLIGHTS_PRICE_LOHI,
        label: "Price (Low to High)",
        sort: (firstEl: Flight, secondEl: Flight) => {
          if (firstEl.priceInfo.max_cash_payment && secondEl.priceInfo.max_cash_payment) {
            return SortUtil.compare(firstEl.priceInfo.max_cash_payment, secondEl.priceInfo.max_cash_payment, false);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.FLIGHTS_PRICE_HILO]: {
        key: SORT_TYPE.FLIGHTS_PRICE_HILO,
        label: "Price (High to Low)",
        sort: (firstEl: Flight, secondEl: Flight) => {
          if (firstEl.priceInfo.max_cash_payment && secondEl.priceInfo.max_cash_payment) {
            return SortUtil.compare(firstEl.priceInfo.max_cash_payment, secondEl.priceInfo.max_cash_payment, true);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.FLIGHTS_MILES_LOHI]: {
        key: SORT_TYPE.FLIGHTS_MILES_LOHI,
        label: this.whitelabelTranslateService.translate("price_low_to_high", {
          partnersShortCurrency: pointsPartner?.currency_short,
        }),
        sort: (firstEl: Flight, secondEl: Flight) => {
          if (firstEl.priceInfo.max_points_payment && secondEl.priceInfo.max_points_payment) {
            return SortUtil.compare(firstEl.priceInfo.max_points_payment, secondEl.priceInfo.max_points_payment, false);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.FLIGHTS_MILES_HILO]: {
        key: SORT_TYPE.FLIGHTS_MILES_HILO,
        label: this.whitelabelTranslateService.translate("price_high_to_low", {
          partnersShortCurrency: pointsPartner?.currency_short,
        }),
        sort: (firstEl: Flight, secondEl: Flight) => {
          if (firstEl.priceInfo.max_points_payment && secondEl.priceInfo.max_points_payment) {
            return SortUtil.compare(firstEl.priceInfo.max_points_payment, secondEl.priceInfo.max_points_payment, true);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.FLIGHTS_STOPS_LOHI]: {
        key: SORT_TYPE.FLIGHTS_STOPS_LOHI,
        label: "Stops (Low to High)",
        sort: (firstEl: Flight, secondEl: Flight) => {
          if (firstEl.segments && secondEl.segments) {
            return SortUtil.compare(firstEl.segments.length, secondEl.segments.length, false);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.FLIGHTS_OUTBOUND_LOHI]: {
        key: SORT_TYPE.FLIGHTS_OUTBOUND_LOHI,
        label: "Outbound Departure (Low to High)",
        sort: (firstEl: Flight, secondEl: Flight) => {
          if (firstEl.departureTimeInMinutes && secondEl.departureTimeInMinutes) {
            return SortUtil.compare(firstEl.departureTimeInMinutes, secondEl.departureTimeInMinutes, false);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.FLIGHTS_OUTBOUND_HILO]: {
        key: SORT_TYPE.FLIGHTS_OUTBOUND_HILO,
        label: "Outbound Departure (High to Low)",
        sort: (firstEl: Flight, secondEl: Flight) => {
          if (firstEl.departureTimeInMinutes && secondEl.departureTimeInMinutes) {
            return SortUtil.compare(firstEl.departureTimeInMinutes, secondEl.departureTimeInMinutes, true);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.FLIGHTS_DURATION_LOHI]: {
        key: SORT_TYPE.FLIGHTS_DURATION_LOHI,
        label: "Flight Duration (Shortest to Longest)",
        sort: (firstEl: Flight, secondEl: Flight) => {
          if (firstEl.duration && secondEl.duration) {
            return SortUtil.compare(firstEl.duration, secondEl.duration, false);
          } else {
            return 0;
          }
        },
      },
      [SORT_TYPE.FLIGHTS_DURATION_HILO]: {
        key: SORT_TYPE.FLIGHTS_DURATION_HILO,
        label: "Flight Duration (Longest to Shortest)",
        sort: (firstEl: Flight, secondEl: Flight) => {
          if (firstEl.duration && secondEl.duration) {
            return SortUtil.compare(firstEl.duration, secondEl.duration, true);
          } else {
            return 0;
          }
        },
      },
    };
  }

  private static getUpdatedPermittedSortArr(sortLabelArrToBeRemoved: SortType[]): SortType[] {
    /*
     Return subset of permitted sorting options array
     * If full points = remove "pay-points-cash-lohi", "pay-points-cash-hilo"
     * If full cash =  remove "pay-points-lohi", "pay-points-hilo"
    */
    return this.permittedOptions.filter((element) => !sortLabelArrToBeRemoved.includes(element));
  }

  private static getMatchingSortArr(sortLabelArrToBeMatched: SortType[]): SortType[] {
    return (Object.keys(this.options) as SortType[]).filter((element) => sortLabelArrToBeMatched.includes(element));
  }

  private static isSortingOptionsExist(sortLabelArrToBeChecked: SortType[]): boolean {
    return this.permittedOptions.filter((element) => sortLabelArrToBeChecked.includes(element)).length > 0;
  }

  private static isCurrentCashOrPoints(fullPointsOrCashArr: SortType[]): boolean {
    const currentElement: SortType = fullPointsOrCashArr.find(e => e === this.current);
    return currentElement && currentElement.length > 0;
  }
}
