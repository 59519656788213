import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { MainButtonModule } from "booking-app-v2/shared/components/main-button/main-button.module";
import { CheckoutButtonComponent } from "./checkout-button.component";

@NgModule({
  declarations: [ CheckoutButtonComponent ],
  imports: [
    SharedModule,
    MainButtonModule,
  ],
  exports: [ CheckoutButtonComponent ],
  providers: [],
})
export class CheckoutButtonModule { }
