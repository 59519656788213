import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { CountryService } from "booking-app-v2/shared/services/country.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { Country, GlobalDataEnum, State } from "booking-app-v2/shared/types";
import { autoCompleteValidator } from "booking-app-v2/shared/validators";
import { Observable, map, startWith, take, tap } from "rxjs";

@Component({
  selector: "country-dropdown",
  templateUrl: `/html/components/country_dropdown_v2`,
})
export class CountryDropdownComponent implements OnInit {
  @Input() countryFormControl: FormControl;
  @Input() stateFormControl: FormControl;
  @Input() isDisabled: boolean;

  filteredCountryOptions$: Observable<Country[]>;
  filteredStateOptions$: Observable<State[]>;

  constructor(private countryService: CountryService, private globalData: GlobalData) {}

  ngOnInit(): void {
    this.buildFilteredCountriesList();

    if (this.globalData.isUserLoggedIn) {
      const user = this.globalData.get(GlobalDataEnum.CURRENT_USER);
      if (user?.country) {
        const userCountry = this.countryService.getCountry(user.country);
        this.countryFormControl.setValue(userCountry);
      }
    }
  }

  countryDisplayFn = (country: Country) => country?.name ?? "";
  stateDisplayFn = (state: State) => state?.name ?? "";

  get isStateRequired(): boolean {
    if (this.countryFormControl?.value?.code) {
      return this.countryService.isStateRequired(this.countryFormControl.value.code);
    }
    return false;
  }

  private buildFilteredCountriesList(): void {
    this.filteredCountryOptions$ = this.countryFormControl.valueChanges.pipe(
      startWith(this.countryFormControl.value),
      tap((formControlValue) => {
        if (!formControlValue || typeof formControlValue === "string" || this.isDisabled) {
          return;
        }

        this.stateFormControl.setValue({});
        if (!this.countryService.isStateRequired(formControlValue.code)) {
          setTimeout(() => this.stateFormControl.disable());
          return;
        }
        this.stateFormControl.markAsUntouched();

        this.stateFormControl.enable();
        this.countryService
          .getStates(formControlValue.code)
          .pipe(take(1))
          .subscribe((states: State[]) => {
            this.initStateAutoCompleteValidator(states);
            this.buildFilteredStatesList(states);
          });
      }),
      map((formControlValue: Country | string) => {
        const countryName = this.countryService.getNameFromFormControlValue(formControlValue);
        return this.countryService.filteredCountriesList(countryName);
      }),
    );
  }

  private buildFilteredStatesList(statesList: State[]): void {
    this.filteredStateOptions$ = this.stateFormControl.valueChanges.pipe(
      startWith(this.stateFormControl.value),
      map((formControlValue: State | string) => {
        const stateName = this.countryService.getNameFromFormControlValue(formControlValue);
        return this.countryService.filteredStatesList(statesList, stateName);
      }),
    );
  }

  private initStateAutoCompleteValidator(states: State[]): void {
    const statesNameMap = states.reduce<Record<string, State>>((statesMap, state) => {
      statesMap[state.name] = state;
      return statesMap;
    }, {});
    this.stateFormControl.setValidators([autoCompleteValidator<State>(statesNameMap, "name")]);
  }
}
