import { Base } from "booking-app-v2/shared/models";
import { HotelSearchForm } from "booking-app-v2/hotels/models";
import { RoomPrice } from "./room-price.model";
import { CURRENT_PAGE, GlobalDataEnum, Voucher } from "booking-app-v2/shared/types";
import { Amenities, RoomAdditionalInfo, RoomImage, RoomRawPrice } from "booking-app-v2/hotels/types";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import { CheckoutRoomDecorator } from "booking-app-v2/hotels/decorators";
import { VoucherDecorator } from "booking-app-v2/shared/decorators/voucher.decorator";

export class Room extends Base {
  readonly amenities: Amenities;
  readonly bonus_program_type: string;
  readonly description: string;
  readonly free_cancellation: boolean;
  readonly images: RoomImage[];
  readonly key: string;
  readonly long_description: string;
  readonly roomAdditionalInfo: RoomAdditionalInfo;
  readonly roomDescription: string;
  readonly roomNormalizedDescription: string;
  readonly type: string;
  readonly roomRawPrice: RoomRawPrice;
  readonly showRapidVersion: boolean;
  priceInfo: RoomPrice;

  // used in getRoomDescription
  roomDescriptionShort?: string;
  roomDescriptionDetail?: string;
  visible?: boolean;
  discount?: number;

  hotelSearchForm: HotelSearchForm;
  currencyService: CurrenciesService;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    currencyService: CurrenciesService,
    payWithPointsCashService: PayWithPointsCashService,
    pageDiscoveryService: PageDiscoveryService,
    appliedVouchers: Voucher[],
    roomRawPrice: RoomRawPrice,
  ) {
    super(appSettingsService, globalData);
    this.hotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    this.currencyService = currencyService;

    this.roomRawPrice = roomRawPrice;
    this.amenities = roomRawPrice.amenities;
    this.bonus_program_type = roomRawPrice.bonus_program_type;
    this.description = roomRawPrice.description;
    this.free_cancellation = roomRawPrice.free_cancellation;
    this.images = roomRawPrice.images;
    this.key = roomRawPrice.key;
    this.long_description = roomRawPrice.long_description;
    this.roomAdditionalInfo = roomRawPrice.roomAdditionalInfo;
    this.roomDescription = roomRawPrice.roomDescription;
    this.roomNormalizedDescription = roomRawPrice.roomNormalizedDescription;
    this.roomDescriptionShort = this.roomNormalizedDescription;
    this.roomDescriptionDetail = this.roomAdditionalInfo.breakfastInfo;
    this.showRapidVersion = this.shouldShowRapidVersion();
    this.priceInfo = new RoomPrice(
      appSettingsService,
      globalData,
      currencyService,
      payWithPointsCashService,
      roomRawPrice,
    );

    if (pageDiscoveryService.currentPage() === CURRENT_PAGE.CHECKOUT) {
      CheckoutRoomDecorator.decorate(this);
      VoucherDecorator.decorate(this, appliedVouchers);
    }
  }

  private shouldShowRapidVersion(): boolean {
    return !!this.roomAdditionalInfo.displayFields.know_before_you_go ||
      !!this.roomAdditionalInfo.displayFields.fees_optional ||
      !!this.roomAdditionalInfo.displayFields.fees_mandatory;
  }
}
