import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { StripePaymentIntentFormComponent } from "./stripe-payment-intent-form.component";

@NgModule({
  declarations: [ StripePaymentIntentFormComponent ],
  imports: [
    SharedModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
  ],
  exports: [ StripePaymentIntentFormComponent ],
  providers: [],
})
export class StripePaymentIntentFormModule {}
