import { Inject, Injectable } from "@angular/core";
import { catchError, concatMap, EMPTY, from, map, Observable, Subscription } from "rxjs";
import { Currency } from "booking-app-v2/shared/models";
import { CreditCardValidationService } from "booking-app-v2/shared/services/credit-card-validation.service";
import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PaymentMethodService } from "booking-app-v2/shared/services/payment-method.service";
import { PaymentStylingSettingsService } from "booking-app-v2/shared/services/payment-styling-settings.service";
import { RollbarService } from "booking-app-v2/shared/services/rollbar.service";
import Rollbar from "rollbar";
import { loadStripe, PaymentIntentResult, Stripe } from "@stripe/stripe-js";

import {
  GlobalDataEnum,
  PAYMENT_METHOD,
  STRIPE_INTENT_FIELDS,
  STRIPE_PAYMENT_INTENT_STATUS,
  StripeIntentFields,
  StripeIntentIINResponse,
} from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class StripePaymentIntentService {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  cardNumberElement: any;
  cardExpiryElement: any;
  cardCvcElement: any;
  currentCardBrand: string;
  formScope: any;
  errorFlags: { [key in StripeIntentFields]: boolean };
  stripeIntentForm: { [key in StripeIntentFields]: boolean };
  selectedCurrency: Currency;
  stripe: Stripe;

  private currencySubscription: Subscription;

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private appSettingsService: AppSettingsService,
    private apiDataService: ApiDataService,
    private globalData: GlobalData,
    private paymentMethodService: PaymentMethodService,
    private creditCardValidationService: CreditCardValidationService,
    private paymentStylingSettingsService: PaymentStylingSettingsService,
    private currenciesService: CurrenciesService,
  ) {
    this.initStripe();
    this.stripeIntentForm = {
      cardNumber: false,
      cardExpiry: false,
      cardExpiryPastDate: false,
      cardCvc: false,
      amexVerification: false,
    };
    this.errorFlags = {
      cardNumber: false,
      cardExpiry: false,
      cardExpiryPastDate: false,
      cardCvc: false,
      amexVerification: false,
    };
    this.selectedCurrency = this.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);

    if (this.isAmexAllowed()) {
      this.stripeIntentForm.amexVerification = true;
    }
  }

  // Form & Validation Related Methods

  mountStripeIntentElements(): void {
    if (typeof this.stripe === "undefined" || !this.stripe) {
      this.rollbar.warning(("Error on mounting Stripe Intent Elements: stripe not initialized"));
    }

    const elements = this.stripe.elements();
    const elementStyles = this.paymentStylingSettingsService.paymentSettings.stripe;
    this.cardNumberElement = elements.create("cardNumber", { style: elementStyles });
    this.cardExpiryElement = elements.create("cardExpiry", { style: elementStyles });
    this.cardCvcElement = elements.create("cardCvc", { style: elementStyles });

    // Since we conditionally load the template,
    // we need to make sure template is available in the DOM before we mount.
    setTimeout( () => {
      this.cardNumberElement.mount("#card-number");
      this.cardExpiryElement.mount("#card-expiry");
      this.cardCvcElement.mount("#card-cvc");
    }, 100);
  }

  setupOnChangeListeners(): void {
    this.cardNumberElement.on("change", this.handleOnChange.bind(this));
    this.cardExpiryElement.on("change", this.handleOnChange.bind(this));
    this.cardCvcElement.on("change", this.handleOnChange.bind(this));
    this.cardNumberElement.on("focus", this.formElementFocus.bind(this));
    this.cardExpiryElement.on("focus", this.formElementFocus.bind(this));
    this.cardCvcElement.on("focus", this.formElementFocus.bind(this));
    this.cardNumberElement.on("blur", this.formElementBlur.bind(this));
    this.cardExpiryElement.on("blur", this.formElementBlur.bind(this));
    this.cardCvcElement.on("blur", this.formElementBlur.bind(this));
    this.currencySubscription = this.currenciesService.onCurrencyChange.subscribe((newCurrency: Currency) => {
      this.selectedCurrency = newCurrency;
      this.checkAndUpdateAmexValid(this.currentCardBrand);
    });
  }

  destroyCurrencySubscription(): void {
    this.currencySubscription.unsubscribe();
  }

  validateStripeIntentForm(): boolean {
    if (this.isUsingSavedCard() || this.isPayAnyone()) {
      return true;
    }

    if (!this.stripeIntentForm.cardExpiry) {
      this.stripeIntentForm.cardExpiryPastDate = true;
    }

    return (
      this.stripeIntentForm.cardNumber &&
      this.stripeIntentForm.cardExpiry &&
      this.stripeIntentForm.cardCvc &&
      this.stripeIntentForm.amexVerification
    );
  }

  // Endpoint related methods

  confirmIntentProcess(
    clientSecret: string,
    successCallback: (response) => any,
    failCallback: (error) => any,
  ): Observable<any> {
    return this.confirmPayment(clientSecret).pipe(
      concatMap((response: PaymentIntentResult) => {
        // If response is an error object, throw straight away
        if (response.error) {
          throw new Error(response.error.message);
        }

        if (this.isConfirmPaymentSucceeded(response)) {
          if (this.isUsingSavedCard()) {
            successCallback(response);
            return EMPTY;
          }

          const paymentMethodId = (typeof response.paymentIntent.payment_method === "string") ?
                                  response.paymentIntent.payment_method :
                                  response.paymentIntent.payment_method.id;

          return this.getIntentIin(paymentMethodId).pipe(
            concatMap((iinResponse: StripeIntentIINResponse) => {
              return this.creditCardValidationService.validate(iinResponse.iin, true).pipe(
                map((valid) => {
                  if (valid) {
                    successCallback(response);
                    return EMPTY;
                  } else {
                    throw new Error("IIN validation failed");
                  }
                }),
              );
            }),
          );
        } else {
          throw new Error("Stripe intent status not succeeded or requires_capture");
        }
      }),
      catchError((error) => {
        failCallback(error);
        return EMPTY;
      }),
    );
  }

  isUsingSavedCard(): boolean {
    return (
      this.appSettingsService.storeCreditCard &&
      this.paymentMethodService.activePaymentTab === PAYMENT_METHOD.SAVED_CARDS &&
      !!this.paymentMethodService.selectedSavedCard
    );
  }

  private async initStripe(): Promise<void> {
    this.stripe = await loadStripe(
      this.kaligoConfig.isProduction ?
      this.appSettingsService.stripePublishableKey.production :
      this.appSettingsService.stripePublishableKey.test
    );
  }

  private confirmPayment(secret: string): Observable<PaymentIntentResult> {
    if (this.isUsingSavedCard()) {
      return from<Promise<PaymentIntentResult>>(
        this.stripe.confirmCardPayment(secret, { payment_method: this.paymentMethodService.selectedSavedCard.token }),
      );
    }

    if (!this.cardNumberElement) {
      throw new Error("Stripe intent card number element not found");
    }

    return from<Promise<PaymentIntentResult>>(
      this.stripe.confirmCardPayment(secret, { payment_method: { card: this.cardNumberElement } }),
    );
  }

  private isConfirmPaymentSucceeded(response: PaymentIntentResult): boolean {
    if (response.paymentIntent) {
      return response.paymentIntent.status === STRIPE_PAYMENT_INTENT_STATUS.SUCCEEDED ||
             response.paymentIntent.status === STRIPE_PAYMENT_INTENT_STATUS.REQUIRES_CAPTURE;
    }
    return false;
  }

  private getIntentIin(paymentMethodId: string): Observable<StripeIntentIINResponse> {
    return this.apiDataService.get(`payment_methods/${paymentMethodId}`).pipe(
      map((iinResponse: StripeIntentIINResponse) => {
        return iinResponse;
      }),
    );
  }

  // Private Methods for OnChange Handlers

  private handleOnChange(event) {
    if (event.elementType === STRIPE_INTENT_FIELDS.CardNumber) {
      this.currentCardBrand = event.brand;
      this.checkAndUpdateAmexValid(event.brand);
    }

    const cardSupported: boolean = this.isCardSupported(event.brand);
    this.updateStripeIntentForm(event.elementType, event.complete && !event.error && cardSupported);

    if (event.error || event.empty || !cardSupported) {
      this.toggleFieldValidity(event.elementType, false, event.error?.code);
    } else if (event.complete) {
      this.toggleFieldValidity(event.elementType, true);
    }
  }

  private checkAndUpdateAmexValid(brand: string): void {
    if (brand === "amex" && !this.isAmexAllowed()) {
      this.stripeIntentForm.amexVerification = false;
      this.errorFlags.amexVerification = true;
    } else {
      this.stripeIntentForm.amexVerification = true;
      this.errorFlags.amexVerification = false;
    }
  }

  private updateStripeIntentForm(elementType: StripeIntentFields, value: boolean) {
    this.stripeIntentForm[elementType] = value;
  }

  private formElementFocus(event): void {
    // ID's must be always be based on elementType ea. #card-number, #card-cvc...
    const dashedId = event.elementType.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
    const element = document.getElementById(dashedId);
    this.updateStripeFocusElement(element, true);
  }

  private formElementBlur(event): void {
    const dashedId = event.elementType.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
    const element = document.getElementById(dashedId);
    switch (event.elementType) {
      case STRIPE_INTENT_FIELDS.CardNumber: {
        if (this.cardNumberElement._empty) {
          this.updateStripeFocusElement(element, false);
          this.errorFlags.cardNumber = true;
        }
        break;
      }
      case STRIPE_INTENT_FIELDS.CardExpiry: {
        if (this.cardExpiryElement._empty) {
          this.updateStripeFocusElement(element, false);
          this.errorFlags.cardExpiry = true;
        }
        break;
      }
      case STRIPE_INTENT_FIELDS.CardCvc: {
        if (this.cardCvcElement._empty) {
          this.updateStripeFocusElement(element, false);
          this.errorFlags.cardCvc = true;
        }
        break;
      }
    }
  }

  private updateStripeFocusElement(element: any, isFocused: boolean = true): void {
    if (isFocused) {
      element.classList.add("stripe-input-focused");
      element.parentElement.classList.add("input-focused");
    } else {
      element.classList.remove("stripe-input-focused");
      element.parentElement.classList.remove("input-focused");
    }
  }

  private toggleFieldValidity(field: StripeIntentFields, isValid: boolean, errorCode?: string) {
    switch (field) {
      case STRIPE_INTENT_FIELDS.CardNumber: {
        this.errorFlags.cardNumber = !isValid;
        break;
      }
      case STRIPE_INTENT_FIELDS.CardExpiry: {
        if (errorCode === "invalid_expiry_year_past") {
          this.errorFlags.cardExpiryPastDate = true;
          this.errorFlags.cardExpiry = false;
          this.stripeIntentForm.cardExpiryPastDate = false;
        } else {
          this.errorFlags.cardExpiry = !isValid;
          this.errorFlags.cardExpiryPastDate = false;
          this.stripeIntentForm.cardExpiryPastDate = true;
        }
        break;
      }
      case STRIPE_INTENT_FIELDS.CardCvc: {
        this.errorFlags.cardCvc = !isValid;
        break;
      }
    }
  }

  private isAmexAllowed(): boolean {
    const supportCheck: string[] = this.appSettingsService.supportedCards.amex;
    return supportCheck && supportCheck.indexOf(this.selectedCurrency.code) >= 0;
  }

  private isCardSupported(brand: string): boolean {
    const supportCheck: string[] = this.appSettingsService.supportedCards[brand];

     // if supportCheck is null, we assume that it support all currency
    if (!supportCheck) {
      return true;
    }

    return supportCheck.indexOf(this.selectedCurrency.code) >= 0;
  }

  private isPayAnyone(): boolean {
    return this.paymentMethodService.activePaymentTab === PAYMENT_METHOD.PAY_ANYONE;
  }
}
