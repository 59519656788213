import { Component } from "@angular/core";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { UserService } from "booking-app-v2/shared/services/user.service";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import { CURRENT_PAGE, CurrentPage, GlobalDataEnum } from "booking-app-v2/shared/types";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";

@Component({
  selector: "shared-header",
  templateUrl: "/html/components/header_v2",
})
export class SharedHeaderComponent {
  tenant: string = this.appSettingsService.tenant.toLowerCase();

  constructor(
    public globalData: GlobalData,
    protected dialogService: DialogService,
    protected userService: UserService,
    protected pageDiscoveryService: PageDiscoveryService,
    protected appSettingsService: AppSettingsService,
  ) {}

  showProductTypeSwitch(): boolean {
    const homeURLs: CurrentPage[] = [
      CURRENT_PAGE.HOME,
      CURRENT_PAGE.HOTEL_HOME,
      CURRENT_PAGE.FLIGHT_HOME,
      CURRENT_PAGE.CAR_HOME,
    ];

    return homeURLs.includes(this.pageDiscoveryService.currentPage());
  }

  openLoginModal(): void {
    this.dialogService.openLoginModal();
  }

  closeSideMenuAndOpenLoginModal(): void {
    this.globalData.set(GlobalDataEnum.SHOW_MOBILE_SIDE_MENU, false);
    this.openLoginModal();
  }

  logout(): void {
    this.userService.logout();
  }
}
