import { Injectable } from "@angular/core";

import { AppSettingsService } from "./../app-settings.service";
import { BootstrapDataService } from "./../bootstrap-data.service";
import { GlobalData } from "./../global-data.service";
import { CookiesService } from "./cookies.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { PointsPartner, PointsPartners } from "booking-app-v2/shared/models";

@Injectable({
  providedIn: "root",
})
export class PointsPartnerService {

  private _pointsPartner: PointsPartner;
  private _pointsPartners: PointsPartners;

  constructor(
    private appSettingsService: AppSettingsService,
    private bootstrapService: BootstrapDataService,
    private globalData: GlobalData,
    private cookiesService: CookiesService,
    private windowRefService: WindowRefService,
  ) { }

  public init(): void {
    this.initPointsPartners();
    this.initPointsPartner();

    this.globalData.set(GlobalDataEnum.POINTS_PARTNERS, this._pointsPartners);
    this.globalData.set(GlobalDataEnum.POINTS_PARTNER, this._pointsPartner);
    this.appSettingsService.pointsCashSliderSettings.sliderLeftLabel = this._pointsPartner.currency_short;
  }

  private initPointsPartners(): void {
    const rawPartnersList: PointsPartner[] = this.bootstrapService.bootstrapData.partners.partners;
    const partnersList: PointsPartner[] = [];

    rawPartnersList.forEach((partner: PointsPartner): void => {
      partnersList.push(new PointsPartner(this.appSettingsService, this.globalData, partner));
    });

    this._pointsPartners = new PointsPartners(this.appSettingsService, this.globalData, partnersList);
  }

  private initPointsPartner(): void {
    let pointsPartner: PointsPartner;
    const pointsPartnerIdPriorityList: number[] = [
      parseInt(this.windowRefService.getQueryParamFromWindow("partnerId"), 10),
      this.bootstrapService.bootstrapData.landing_page.points_partner_id,
      this.cookiesService.getPartner(),
      this.bootstrapService.bootstrapData.default_points_partner_id,
      this.bootstrapService.bootstrapData.partners.default,
    ];

    for (const id of pointsPartnerIdPriorityList) {
      pointsPartner = this._pointsPartners.findById(id);
      if (pointsPartner) { break; }
    }
    this._pointsPartner = pointsPartner;
  }
}
