import { Base } from "booking-app-v2/shared/models";
import { ScoreValues, TrustYouReviews, TrustYouUIDetails } from "booking-app-v2/hotels/types";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { TrustYouService } from "booking-app-v2/shared/services/trust-you.service";

export class TrustYou extends Base {
  readonly id: string;
  readonly hotelName?: string;
  readonly score?: ScoreValues;
  readonly ui?: TrustYouUIDetails;
  readonly kaligoOverallScore?: string;
  reviews?: TrustYouReviews;
  reviewsError?: boolean;
  reviewsScore?: string;
  reviewsScoreStyles?: { [klass: string]: any; };

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    trustYouService: TrustYouService,
    rawTrustYou: TrustYou,
    hotelName: string,
  ) {
    super(appSettingsService, globalData);

    this.id = rawTrustYou.id;
    this.hotelName = hotelName;
    this.score = rawTrustYou.score;
    if (rawTrustYou.score) {
      this.ui = trustYouService.getScoreUIDetails(rawTrustYou.score.kaligo_overall);
      this.kaligoOverallScore = rawTrustYou.score.kaligo_overall.toFixed(1);
    }
  }

  initTrustYouReviewsSuccess(reviews: TrustYouReviews): void {
    this.reviews = reviews;
    this.reviewsScore = reviews.summary.value.toFixed(1);
    this.reviewsScoreStyles = {
      "positive-text": reviews.summary.sentiment.color === "positive",
      "neutral-text": reviews.summary.sentiment.color !== "positive",
    };
  }

  initTrustYouReviewsFailed(): void {
    this.reviewsError = true;
  }
}
