import { RoomPrice } from "../models";
import { Currency } from "booking-app-v2/shared/models";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";

export class PricePerNightDecorator {

  static decorate(
    price: RoomPrice,
    currenciesService: CurrenciesService,
    numberOfNights: number,
    roomsCount: number): void {
      price.pricePerNight = this.pricePerNight(price, currenciesService, numberOfNights);
      price.pricePerRoomPerNight = this.pricePerRoomPerNight(price, roomsCount);
  }

  private static pricePerNight(
    roomPrice: RoomPrice,
    currenciesService: CurrenciesService,
    numberOfNights: number,
  ): number {
    if (!roomPrice.price) {
      return 0;
    }

    let pricePerNight: number = (roomPrice.price / numberOfNights);
    pricePerNight = currenciesService.convertFromUsd(pricePerNight);

    const selectedCurrency: Currency = roomPrice.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
    if (selectedCurrency.decimalPlace === 0) {
      pricePerNight = Math.ceil(pricePerNight);
    }

    return pricePerNight;
  }

  private static pricePerRoomPerNight(
    roomPrice: RoomPrice,
    roomsCount: number,
  ): number {
    if (!roomPrice.price) {
      return 0;
    }

    let pricePerRoomPerNight: number = roomPrice.pricePerNight / roomsCount;

    const selectedCurrency: Currency = roomPrice.globalData.get(GlobalDataEnum.SELECTED_CURRENCY);
    if (selectedCurrency.decimalPlace === 0) {
      pricePerRoomPerNight = Math.ceil(pricePerRoomPerNight);
    }

    return pricePerRoomPerNight;
  }
}
