import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "parseHtmlInInput",
  pure: false,
})
export class ParseHtmlInInputPipe implements PipeTransform {
  transform(str): string {
    const element: HTMLElement = document.createElement("span");
    element.innerHTML = str;
    return element.innerText || element.textContent;
  }
}
