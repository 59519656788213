import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule  } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { DatePickerComponent } from "./date-picker.component";
import { MatDayjsDateModule } from "./adapters/dayjs-mat-adapter.module";
import { MaxRangeDirective } from "./directives";

@NgModule({
  declarations: [ DatePickerComponent, MaxRangeDirective],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDayjsDateModule,
  ],
  exports: [ DatePickerComponent ],
  providers: [],
})
export class DatePickerModule {}
