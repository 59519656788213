import { CarPrice } from "booking-app-v2/cars/models";
import { CarRawResponse } from "booking-app-v2/cars/types";
import { RoomPrice } from "booking-app-v2/hotels/models";
import { HotelRawPrice } from "booking-app-v2/hotels/types";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";

export class CommonRedeemDecorator {

  static decorate(
    price: RoomPrice | CarPrice,
    rawPrice: HotelRawPrice | CarRawResponse,
    duration: number,
    payWithPointsCashService: PayWithPointsCashService,
  ): void {
    if (payWithPointsCashService.isPayByPointsAvailable(rawPrice)) {
      price.points_payment = payWithPointsCashService.pointsPayment(rawPrice);
      price.points_payment_per_night = payWithPointsCashService.pointsPaymentPerNight(price, duration);
      price.original_points_payment = payWithPointsCashService.originalPointsPayment(rawPrice);
      price.original_points_payment_per_night = payWithPointsCashService.originalPointsPaymentPerNight(price, duration);
      price.minPoint = payWithPointsCashService.minPoint(rawPrice);
    }

    const calculatedCash: number = payWithPointsCashService.getCalculatedCash(rawPrice);
    const calculatedBaseCash: number = payWithPointsCashService.getCalculatedBaseCash(rawPrice);
    if (payWithPointsCashService.isPayByCashAvailable(rawPrice)) {
      price.cash_payment = payWithPointsCashService.cashPayment(calculatedCash);
      price.original_cash_payment = payWithPointsCashService.originalCashPayment(calculatedCash, calculatedBaseCash);
      price.cash_payment_per_night = payWithPointsCashService.cashPaymentPerNight(calculatedCash, price, duration);
      price.original_cash_payment_per_night = payWithPointsCashService.originalCashPaymentPerNight(calculatedCash, price, duration);
    }
  }
}
