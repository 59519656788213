import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { RoomDetailsDialogContent } from "booking-app-v2/shared/types";

@Component({
  selector: "room-details-dialog",
  templateUrl: "/html/dialogs/room_details_dialog_v2",
})
export class RoomDetailsDialogComponent implements OnInit {
  images: string[];
  title: string;
  description: string;
  defaultImage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RoomDetailsDialogContent,
  ) { }

  ngOnInit() {
    this.images = this.data.images;
    this.title = this.data.title;
    this.description = this.data.description;
    this.defaultImage = this.data.defaultImage;
  }
}
