import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class TranslationUtilsService {
  private translationsMap: Record<string, Observable<Record<string, any>>> = {};

  constructor(private translateService: TranslateService) { }

  getTranslation(langCode: string): Observable<Record<string, any>> {
    if (this.translationsMap[langCode]) {
      return this.translationsMap[langCode];
    }

    const translationTable: Observable<Record<string, any>> = this.translateService.getTranslation(langCode);
    this.translationsMap[langCode] = translationTable;

    return translationTable;
  }

  humanizeTranslation(translation: string): string {
    const pattern: any = (/\.|\_/g);
    const translatedText = this.translateService.instant("cars.charge." + translation);
    if (translatedText.match(pattern)) {
      return translation.replace(pattern, " ");
    }
    return translatedText;
  }
}
