import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiDataService } from "booking-app-v2/shared/services/api-data.service";
import { UserAction, UserActionInput, UserActionType } from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class UserActionService {

  constructor(
    private apiDataService: ApiDataService,
  ) { }

  postUserAction(userActionType: UserActionType, customDataId: string): void {
    const userAction: UserActionInput = {
      action_type: userActionType,
      custom_data_id: customDataId,
    };

    this.apiDataService.post("user_actions", userAction).subscribe(() => {});
  }

  getUserAction(userActionType: UserActionType, limit?: number): Observable<UserAction[]> {
    const params = { action_type: userActionType, limit };

    return this.apiDataService.get("user_actions", params);
  }
}
