import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { ProductTypeSwitchComponent } from "./product-type-switch.component";

@NgModule({
  declarations: [ ProductTypeSwitchComponent ],
  imports: [
    SharedModule,
    FormsModule,
  ],
  exports: [ ProductTypeSwitchComponent ],
  providers: [],
})
export class ProductTypeSwitchModule {}
