import { ObjectValues } from "booking-app-v2/shared/types/object-values.type";

export const FLIGHT_CABIN = {
  ECONOMY: "Y",
  PREMIUM_ECONOMY: "S",
  BUSINESS: "C",
  FIRST_CLASS: "F",
} as const;

export type FlightCabin = ObjectValues<typeof FLIGHT_CABIN>;

export class FlightCabinHelper {
  static toStringTranslation(value: FlightCabin): string {
    return `flight_cabin.${value}`;
  }
}

export interface FlightCabinOption {
  key: FlightCabin;
  label: string;
}
