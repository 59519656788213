import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { CheckoutComKitCardDetails, CheckoutComKitData } from "booking-app-v2/shared/types";

declare let CheckoutKit: any;

@Injectable({
  providedIn: "root",
})
export class CheckoutComKitService {

  cardToken(checkoutComKitCardDetails: CheckoutComKitCardDetails): Observable<string> {
    const tokenSubject: Subject<string> = new Subject<string>();
    if (CheckoutKit) {
      CheckoutKit.createCardToken(
        checkoutComKitCardDetails,
        { includeBinData: false },
        (data: CheckoutComKitData) => {
          if (data.id) {
            tokenSubject.next(data.id);
          } else {
            tokenSubject.error(Error(data.errors[0]));
          }
        },
      );
    } else {
      setTimeout(() => {
        tokenSubject.error(Error("Missing CheckoutKit"));
      });
    }

    return tokenSubject.asObservable();
  }

}
