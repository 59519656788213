import { Injectable } from "@angular/core";
import { Banner, GlobalDataEnum } from "booking-app-v2/shared/types";

import { BootstrapDataService } from "../bootstrap-data.service";
import { GlobalData } from "../global-data.service";

@Injectable({
  providedIn: "root",
})
export class BannersService {
  constructor(
    private bootstrapService: BootstrapDataService,
    private globalData: GlobalData,
  ) {
  }

  public init() {
    this.globalData.set(GlobalDataEnum.BANNERS, this.bootstrapService.bootstrapData.banners);
  }

  find(slot: string): Banner {
    if (this.globalData.isUserLoggedIn()) {
      const segments: any = this.globalData.get(GlobalDataEnum.CURRENT_USER).segments || {};

      return this.findBannerForSegment(slot, segments.banner) || this.findDefaultBanner(slot);
    } else {
      return this.findDefaultBanner(slot);
    }
  }

  private findBannerForSegment(slot: string, segment: string): Banner {
    return this.globalData.get(GlobalDataEnum.BANNERS).find((banner: Banner) => {
      return banner.slot === slot && banner.user_segment === segment;
    });
  }

  private findDefaultBanner(slot: string): Banner {
    const isEmpty = (segment: string) => segment === null || segment === "";

    return this.globalData.get(GlobalDataEnum.BANNERS).find((banner: Banner) => {
      return banner.slot === slot && isEmpty(banner.user_segment);
    });
  }
}
