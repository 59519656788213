import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Options } from "@ascenda/ngx-slider";
import { ReviewScore, TrustYouUIDetails } from "booking-app-v2/hotels/types";
import { TrustYouService } from "booking-app-v2/shared/services/trust-you.service";

export interface SentimentValues {
  min: TrustYouUIDetails;
  max: TrustYouUIDetails;
}

@Component({
  selector: "reviews-score-slider",
  templateUrl: "/html/components/review_score_filter_v2",
})

export class ReviewsScoreSliderComponent implements OnChanges {
  sliderOptions: Options;
  sentimentValues: SentimentValues = { min: undefined, max: undefined };

  @Input() sliderValues: ReviewScore;
  @Output() onSliderUpdate: EventEmitter<ReviewScore> = new EventEmitter<ReviewScore>();

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private trustYouService: TrustYouService,
  ) {
  }

  updateSliderMinValue(minValue: number): void {
    this.sliderOptions.minValue = minValue;
    this.updateReviewScoresAndSentiment();
  }

  updateSliderMaxValue(maxValue: number): void {
    this.sliderOptions.value = maxValue;
    this.updateReviewScoresAndSentiment();
  }

  ngOnChanges(changes: SimpleChanges) {
    const sliderMinMaxValues: ReviewScore = changes.sliderValues.currentValue || { min: 2.5, max: 5.0, floor: 2.5, ceil: 5.0 };
    this.updateSentimentValues(sliderMinMaxValues);
    this.initSlider(sliderMinMaxValues);
  }

  private updateReviewScoresAndSentiment(): void {
    const reviewScores: ReviewScore = {
      min: this.sliderOptions.minValue,
      max: this.sliderOptions.value,
      floor: this.sliderOptions.floor,
      ceil: this.sliderOptions.ceil,
    };

    this.updateSentimentValues(reviewScores);
    this.onSliderUpdate.emit(reviewScores);
  }

  private initSlider(reviewScore: ReviewScore): void {
    this.sliderOptions = {
      minValue: reviewScore.min,
      floor: reviewScore.floor,
      ceil: reviewScore.ceil,
      value: reviewScore.max,
      step: 0.1,
    };

    this.changeDetectionRef.detectChanges();
  }

  private updateSentimentValues(reviewScores: ReviewScore = { min: 2.5, max: 5, floor: 2.5, ceil: 5 }): void {
    this.sentimentValues.min = this.trustYouService.getScoreUIDetails(reviewScores.min);
    this.sentimentValues.max = this.trustYouService.getScoreUIDetails(reviewScores.max);
  }

}
