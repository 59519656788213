import { MatDateFormats } from "@angular/material/core";

export const MAT_DAYJS_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "ll",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
