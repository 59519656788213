import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { CarouselComponent } from "./carousel.component";

@NgModule({
  declarations: [ CarouselComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ CarouselComponent ],
  providers: [],
})
export class CarouselModule {}
