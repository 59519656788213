import { Pipe, PipeTransform } from "@angular/core";
import { TimeUtils } from "booking-app-v2/shared/utils";

interface DateTimeFormatPipeOptions {
  fmt?: string;
  localise?: boolean;
  inputFormat?: string;
}

@Pipe({
  name: "dateTimeFmt",
  pure: false,
})
export class DateTimeFormatPipe implements PipeTransform {

  constructor() { }

  transform(dateTime: string, options?: DateTimeFormatPipeOptions): string {
    const fmt: string = options?.fmt || "lll";
    const localise: boolean = options?.localise || false;
    const inputFormat: string = options?.inputFormat;

    return localise ?
      TimeUtils.localiseAndFormat(dateTime, fmt, inputFormat) :
      TimeUtils.format(dateTime, fmt, inputFormat);
  }
}
