import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import {
  CheckoutFamilyMilesModule,
} from "booking-app-v2/shared/components/checkout-family-miles/checkout-family-miles.module";
import { PointsCashSliderModule } from "booking-app-v2/shared/components/points-cash-slider/points-cash-slider.module";
import { CouponCodeModule } from "booking-app-v2/shared/components/coupon-code/coupon-code.module";
import { CheckoutPageRoutingModule } from "../routes/checkout-page-routing.module";
import { CheckoutPageComponent } from "../checkout-page.component";
import { HotelRemarksModule } from "booking-app-v2/shared/components/hotel-remarks/hotel-remarks.module";
import { CancellationPolicyModule } from "booking-app-v2/shared/components/cancellation-policy/cancellation-policy.module";
import { AdyenFormModule } from "booking-app-v2/shared/components/adyen-form/adyen-form.module";
import {
  StripePaymentIntentFormModule,
} from "booking-app-v2/shared/components/stripe-payment-intent-form/stripe-payment-intent-form.module";
import { VoucherFieldModule } from "booking-app-v2/shared/components/voucher-field/voucher-field.module";
import { CheckoutButtonModule } from "booking-app-v2/shared/components/checkout-button/checkout-button.module";
import { CountryDropdownModule } from "booking-app-v2/shared/components/country-dropdown/country-dropdown.module";
import {
  HotelTaxAndFeeAccordionModule,
} from "booking-app-v2/shared/components/hotel-tax-and-fees-accordion/hotel-tax-and-fees-accordion.module";

@NgModule({
  imports: [
    SharedModule,
    CheckoutFamilyMilesModule,
    PointsCashSliderModule,
    HotelRemarksModule,
    CancellationPolicyModule,
    CouponCodeModule,
    FormsModule,
    ReactiveFormsModule,
    CheckoutPageRoutingModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatRadioModule,
    AdyenFormModule,
    StripePaymentIntentFormModule,
    VoucherFieldModule,
    CheckoutButtonModule,
    CountryDropdownModule,
    HotelTaxAndFeeAccordionModule,
  ],
  declarations: [
    CheckoutPageComponent,
  ],
})
export class SharedCheckoutPageModule {}
