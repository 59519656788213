import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";

import { BootstrapDataService } from "./../bootstrap-data.service";
import { AppSettingsService } from "./../app-settings.service";
import { DomService } from "./../dom.service";
import { GlobalData } from "./../global-data.service";
import { CookiesService } from "./cookies.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { TimeUtils } from "booking-app-v2/shared/utils";

import { Locale } from "booking-app-v2/shared/models";
import { GlobalDataEnum, Language } from "booking-app-v2/shared/types";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { E2eUserPreferenceService } from "booking-app-v2/shared/services/initializers/e2e-user-preference.service";

@Injectable({
  providedIn: "root",
})
export class LocaleService {

  onLocaleChange: Subject<Locale> = new Subject<Locale>();
  private _enabledLocales: Locale[] = [];

  constructor(
    private bootstrapService: BootstrapDataService,
    private appSettingsService: AppSettingsService,
    private translateService: TranslateService,
    private globalData: GlobalData,
    private cookiesService: CookiesService,
    private windowRefService: WindowRefService,
    private domService: DomService,
    private pointsCashShareService: PointsCashShareService,
    private e2eUserPreferenceService: E2eUserPreferenceService,
  ) { }

  init(): void {
    const languages: Language[] = this.bootstrapService.bootstrapData.languages;
    const locales: Locale[] = this.mappedLocales(languages);

    this._enabledLocales = this.nonRestrictedLocales(locales);
    this.globalData.set(GlobalDataEnum.ENABLED_LOCALES, this._enabledLocales);

    this.changeLocale();
  }

  changeLocale(langCode?: string): void {
    const oldLocale: Locale = this.globalData.get(GlobalDataEnum.SELECTED_LOCALE);
    const newLocale: Locale = this.findByLangCode(
      langCode ||
      this.langParam() ||
      this.cookiesService.getLocale() ||
      this.cookiesService.getGeoLocale(),
    );

    if (newLocale.langCode === oldLocale?.langCode) {
      return;
    }

    // The best practice is to put all locale related changes inside the subscription
    // of translateService to make sure we successfully call translate API
    // but we need to set it immediately to avoid delay of adding css class and direction(rtl and ltr).
    this.updateDisplayAlignment(oldLocale, newLocale);

    this.translateService.use(newLocale.langCode.toLowerCase()).subscribe(() => {
      // Init/Update locale wherever needed
      this.globalData.set(GlobalDataEnum.SELECTED_LOCALE, newLocale);
      TimeUtils.setLocale(newLocale);
      this.cookiesService.setLocale(newLocale.langCode);
      this.pointsCashShareService.toggleSliderRTL();
      this.domService.updateBodyClass(`locale-${oldLocale?.code}`, `locale-${newLocale.code}`);
      this.domService.setDocumentAttribute("lang", newLocale.langCode);

      // Emit event of new locale
      this.onLocaleChange.next(newLocale);
    });

    if (this.appSettingsService.e2eWhitelabel && this.globalData.isUserLoggedIn()) {
      this.e2eUserPreferenceService.updatePreferences({ locale: newLocale.code });
    }
  }

  private findByLangCode(langCode: string): Locale {
    return this._enabledLocales.find(x => x.langCode.toLowerCase() === langCode?.toLowerCase()) ||
      this._enabledLocales.find(x => x.langCode === "en");
  }

  private mappedLocales(languages: Language[]): Locale[] {
    return languages.map(language => {
      return new Locale(this.appSettingsService, this.globalData, language);
    });
  }

  private nonRestrictedLocales(locales: Locale[]): Locale[] {
    const restrictedLocaleCodes: string[]  = this.appSettingsService.restrictedLocaleCodes;
    return locales.filter(item => !restrictedLocaleCodes.includes(item.code));
  }

  private langParam(): string {
    let lang: string = this.windowRefService.getQueryParamFromWindow("lang");

    if (lang === "zh-HK" || lang === "zh-tw") {
      lang = "zh-TW";
    }

    return lang;
  }

  private updateDisplayAlignment(oldLocale: Locale, newLocale: Locale): void {
    const rtlLangCodes: string[] = ["ar", "he"];
    const oldLocaleDir: "ltr" | "rtl" = !!oldLocale ?
      (rtlLangCodes.indexOf(oldLocale.langCode) === -1 ? "ltr" : "rtl") :
      undefined;
    const newLocaleDir: "ltr" | "rtl" = rtlLangCodes.indexOf(newLocale.langCode) === -1 ? "ltr" : "rtl";

    if (oldLocaleDir !== newLocaleDir) {
      this.domService.updateDocumentClass(oldLocaleDir, newLocaleDir);
      this.globalData.set(GlobalDataEnum.DISPLAY_ALIGNMENT, newLocaleDir);
    }
  }
}
