import { Injectable } from "@angular/core";

import {
  CancellationFeeText,
  CarDetailsTemplateConfig,
  CheckoutConfig,
  CheckoutSettings,
  ContactUsConfig,
  DatePickerSettings,
  HotelDetailsTemplateConfig,
  LandingPagePointsCalculationConfig,
  LINK_TARGET,
  MobileResultPopupConfig,
  OpenInTargetTabType,
  PAYMENT_METHOD,
  PaymentMethod,
  PayWithPointsSettings,
  PointsCashSliderSettings,
  PrismicSettings,
  PRODUCT_TYPE,
  ProductType,
  SORT_TYPE,
  SortSettings,
  SortType,
  SupportedCards,
  TENANT,
  Tenant,
  THEME,
  Theme,
  TRAVEL_TYPE,
  TravelType,
} from "booking-app-v2/shared/types";
import { SearchResultsSettings } from "booking-app-v2/hotels/types";

@Injectable({
  providedIn: "root",
})
export class AppSettingsService {
  tenant: Tenant = TENANT.ACME;
  // Identify current tenant full name
  tenantFullName: string = TENANT.ACME;
  theme: Theme = THEME.NONE;
  displayPurchaseAccessAndCancelText: boolean = false;
  restrictedLocaleCodes: string[] = ["he", "tlh"];
  defaultLocale: string = "en";
  useProductType: boolean = false;
  supportedProductTypes: ProductType[] = [PRODUCT_TYPE.EARN, PRODUCT_TYPE.REDEEM, PRODUCT_TYPE.VOUCHER, PRODUCT_TYPE.CASHVOUCHER];
  serviceEmail: string = "service@kaligo.com";

  // Flight settings
  customFlightIcon: string = null;
  flightSegmentArrivalDisplay: boolean = false;
  defaultAirlineSearchCode: string = "MSP";

  blacklistedDestinations: string[] = [];
  gauid: string = "UA-54613553-1";
  displayFeatures: boolean = true;
  contactUsConfig: ContactUsConfig = {
    contactUsHeadingText: "We'd love to hear from you!",
    contactUsEmailText: false,
    contactUsParagraphText: `Our quick & friendly service team
      monitors messages 24/7 for urgent matters, and also looks
      forward to receiving your general inquiries and feedback.`,
  };
  displayBestDealBadge: boolean = false;
  basePointsRatio: number = 0;
  displayNonBookableMarketRates: boolean = false;
  search_results: SearchResultsSettings = {
    modifiers: [],
    ui: {
      extra_disclaimer: null,
    },
  };
  loginUrl: string = "/sign_in";
  logoutUrl: string = "/logout";
  registerUrl: string = "/users";
  forgetPasswordUrl: string = "/users/password";
  defaultTravelTypeInHomePage: TravelType = TRAVEL_TYPE.HOTELS;
  showCancelPolicyOnRedeem: boolean = false;
  showCancelPolicyOnEarn: boolean = true;
  allowCancelRedemptionBooking: boolean = false;
  datePickerSettings: DatePickerSettings = {
    maxAvailableBookingMonths: 12,
    hotels: {
      minBookingDays: 1,
      maxBookingDays: 0,
    },
    flights: {
      minBookingDays: 1,
      maxBookingDays: 0,
    },
    cars: {
      minBookingDays: 1,
      maxBookingDays: 0,
    },
  };
  enableChildrenSearch: boolean = false;
  reloadOnSearch: boolean = false;
  ratingArray: number[] = [5, 4, 3, 2, 1];
  preFilterUnavailableHotels: boolean = false;
  includeUnavailableInTotalCount: boolean = true;
  showTotalNights: boolean = false;
  totalNightsDisclaimerText: string = undefined;
  showBetterRatesOnly: boolean = false;
  marketRatesCount: number = 4;
  payWithPointsSettings: PayWithPointsSettings = {
    roundToNearest: 100,
    earnOnBurn: false,
  };
  pointsCashSliderSettings: PointsCashSliderSettings = {
    alwaysShowPointsSlider: false,
    pointSliderDefaultValue: 0,
    hasCustomSliderLabel: false,
    incrementPointsSliderCounter: true,
    ceilingLabelPrefix: "txt.minimum",
    invertedPointsCashSlider: false,
    sliderLabel: "txt.combine",
    sliderLeftLabel: "Points",
    sliderRightLabel: "txt.cash",
  };
  defaultCurrencyDisplay: "code" | "symbol" = "code";
  redirectMarketRates: boolean = true;
  defaultCountryCodeInCheckout: string = undefined;
  bannerTargetWindow: string = "_blank";
  mobileFirst: boolean = false;
  mapboxHotelMarkerColor: string = "#105399";
  mapboxHotelClusterColor: string = "rgba(38,168,207,0.6)";
  mapboxHotelClusterCounterTextColor: string = "#000000";
  mapboxAPIKey: string = "pk.eyJ1IjoianVueTRuZyIsImEiOiIyZTkxM2ViNTk3ZDI2N2VmNzVjZDVlZDU0NzQ0ODQyMiJ9.z6e9MUTtICeK4JeuvFDe-g";
  hasCustomLoaderFile: boolean = false;
  customResultLoadingMessage: string = undefined;
  useSimpleLoader: boolean = false;
  extraDisclaimerText: string = "";
  openInTargetTab: OpenInTargetTabType = {
    allLinksTarget: LINK_TARGET.SELF,
    checkoutInNewTab: false,
    markerPopUp: LINK_TARGET.BLANK,
  };
  isTravelBreakdownFlexDirectionDefault: boolean = true;
  showMarketRates: boolean = true;
  hotelDetailsTemplateConfig: HotelDetailsTemplateConfig = {
    inlinePointsLabel: false,
    roomItemEarnLabel: "",
    showBgPageColor: false,
    earnRewardLabel: "results.earn_at_least",
    doubleMobileRoomPricesButton: false,
    showPointCashSliderRoomList: true,
    showExtraHeadingsRoomList: false,
    showGroupedActions: true,
    showCurrencyIcon: false,
    breakfastIconUrl: "/icons/Breakfast.png",
    noBreakfastIconUrl: "/icons/NoBreakfast.png",
  };
  carDetailsTemplateConfig: CarDetailsTemplateConfig = {
    carEquipPriceNote: "",
  };
  showCashSlider: boolean = true;
  requireLoginOnCheckout: boolean = false;
  requireSufficientPointsOnCheckout: boolean = false; // angular-v2-todo: enable this for JAL (replacement for v1's isPointChecking)
  checkUserStillLoggedIn: boolean = false;
  enableUserActionTracking: boolean = false;
  mobileResultPopupConfig: MobileResultPopupConfig = {
    filterIconSource: "",
    sortIconSource: "",
    mapIconSource: "",
  };
  customLoadingSearchMessage: string = undefined;
  specialRequestText: string = "Please note that requests are passed to the hotel and not guaranteed";
  useHighResolutionUrl: boolean = false;
  showPerNightText: boolean = false;
  userGulfResident: string = "AE";
  showGulfAlert: boolean = true;
  hasCouponCode: boolean = false;
  hideRedemptionRangeSlider: boolean = false;
  checkoutSettings: CheckoutSettings = {
    insufficientPointsModalCheck: false,
  };
  multiplePointsPartner: boolean = false;
  sortSettings: SortSettings = {
    canUseFullCashOnRedemption: false,
    canUseFullPointsOnRedemption: false,
    showPointsSortInFullPoints: true,
  };
  storeCreditCard: boolean = true;
  alwaysAskForEmail: boolean = false;
  useMembershipNoAsEmail: boolean = false;
  checkoutHideUserDetailName: boolean = false;
  checkoutRedirectToBookingsPath: boolean = true;
  carsCheckoutRedirectToBookingsPath: boolean = false;
  reloadAfterCheckout: boolean = false;
  checkoutConfig: CheckoutConfig = {};
  onlyShowVisaCardLogo: boolean = false;
  displayDinersOptions: boolean = false;
  displayDinersLogo: boolean = false;
  displayJcbLogo: boolean = false;
  displayDiscoverLogo: boolean = false;
  customPaymentLogo: string = "";
  hasMultiplePaymentMethods: boolean = false;
  // In checkout page, check supported currencies for credit cards
  // First introduced by Entertainer
  supportedCards: SupportedCards = {
    amex: ["AED", "AUD", "BRL", "CAD", "CNY", "EUR", "GBP", "HKD", "ILS",
      "INR", "JPY", "KRW", "MYR", "QAR", "RUB", "SAR", "SGD", "THB",
      "TRY", "TWD", "USD", "ZAR"],
    discover: ["BRL"],
    diners: ["BRL"],
    elo: ["BRL"],
    aura: ["BRL"],
    hipercard: ["BRL"],
  };
  bookingSummaryDateFormat: string = "LL"; // this is equivalent to v1's dateFormat. renamed it to make it clearer
  cancellationFeeText: CancellationFeeText = {
    details: "txt.free_cancellation_except_service_fee",
    checkout: "cancellation_policy_service_fee",
    manageBookings: "cancellation_policy_service_fee",
    success: "cancellation_policy_service_fee",
  };
  wlTranslationPath: string = undefined;
  poweredByAscenda: boolean = false;
  insufficientPointsCheck: boolean = false;
  ccValidationUseCardErrorModal: boolean = false;
  defaultPaymentMethod: PaymentMethod = PAYMENT_METHOD.ADD_CARDS;
  userIneligibilityCheck: boolean = false;
  abTest: { withConfidence?: string } = {};
  iconFormat: string = "png";
  alternateSchemeSettings: {
    checkAlternateScheme: boolean;
    userCustomDataEndpoint?: string;
    redirectScheme?: string;
    redirectSchemeUrl?: string;
  } = {
    checkAlternateScheme: false,
  };
  destinationTileCheckinDaysLater: number = 14;
  destinationTileCheckoutDaysAfter: number = 3;
  reloadOnQuickSearch: boolean = false;
  carsBookingPage: {
    confirmedIcon: string;
    pendingIcon: string;
    pendingEmailInText: string;
    bookingReferenceText: string;
    nonRefundableText: string;
    viewManageBookingText: string;
  } = {
    confirmedIcon: "/icons/generic-confirmed-solid.svg",
    pendingIcon: "/icons/generic-pending-solid.svg",
    pendingEmailInText: "purchase.pending.email_in_48h",
    bookingReferenceText: "Booking reference",
    nonRefundableText: "booking_detail.cancellation_policy.non_refundable",
    viewManageBookingText: "View manage booking",
    };
  landingPagePointsCalculationConfig: LandingPagePointsCalculationConfig = {
    nights: 6,
    perNight: 200,
    milesMultiplier: 11,
  };
  roomGuestParameters: {
    labelOnlyRoomCounter: boolean;
    hasRoomGuestCloser: boolean;
    labelOnlySummaryText: boolean;
  } = {
    labelOnlyRoomCounter: false,
    hasRoomGuestCloser: false,
    labelOnlySummaryText: false,
  };
  maximumGuestsMessage: {
    showMessage: boolean;
    maximumGuests: number;
  } = {
    showMessage: false,
    maximumGuests: 0,
  };
  invertedPointsCashSlider: boolean = false;
  multipleAirlineLogoUrl: string = "/flights/icn-multiflights.png";
  customFlightsTimeLabel: string = "h:mm A";
  customFlightsDateTimeLabel: string = "D MMM YYYY | hh:mmA";
  showFlightSelectionState: boolean = false;
  customFlightLineIconUrl: string = "/linedots.png";
  // Indroduced in FAB, OCBC to allow more sorting options for flights
  flightsEnabledSortingOptions: SortType[] = [
    SORT_TYPE.FLIGHTS_PRICE_LOHI,
    SORT_TYPE.FLIGHTS_PRICE_HILO,
    SORT_TYPE.FLIGHTS_DURATION_LOHI,
    SORT_TYPE.FLIGHTS_DURATION_HILO,
    SORT_TYPE.FLIGHTS_OUTBOUND_LOHI,
    SORT_TYPE.FLIGHTS_OUTBOUND_HILO,
    SORT_TYPE.FLIGHTS_STOPS_LOHI,
  ];
  // Indroduced in BNZ, FAB, OCBC to allow custom sorting options for cars
  customCarsEnabledSortingOptions: SortType[] = [];
  // Introduced for OCBC to hide the currency denomination in the redemption range filter
  showCurrencyInFilterSliders: boolean = true;
   // Introduced on FAB flights to customize summary text header
  hasCustomFlightSummaryHeaderText: boolean = false;
  // US date format formatting  MM / DD / YYYY
  usDateFormat: boolean = false;
  // First introduced in BNZ
  customFlightsTermsAndConditionsModalTitle: string = "";
  // Used to toggle loyalty program terms and condition checkbox
  showLoyaltyProgramTnC: boolean = false;
  // Prismic Implementation settings control
  prismicSettings: PrismicSettings = {
    enabled: false,
  };

  checkoutPagePlaceholderText: {
    firstName: string;
    lastName: string;
  } = {
    firstName: "",
    lastName: "",
  };

  limitedCountryCityOptions: boolean = false;
  hideUserAddressFromProfile: boolean = false;
  stripePublishableKey: {
    production: string;
    test: string;
  } = {
    production: "pk_live_yU30Pd1u2Tp4WWTJjg0euqfh",
    test: "pk_test_5AaYXwkcnyL1mGmllELOFdqO",
  };
  // Customize cars result list item button label
  // First introduced by Loylogic
  carsListBtnLabel: string = "Book Now";
  // Display or hide book with confidence panel in checkout
  // First introduced by Loylogic
  displayBookWithConfidence: boolean = true;
  displayMembershipAtCheckout: boolean = true;
  enableCountryOfResidence: boolean = true;
  // For updating user preferences on locale change
  e2eWhitelabel: boolean = false;
  // First introduced in OCBC for revamped
  // confirmation page to show TF statuses
  useTravelfusionConfirmationPage: boolean = false;
  // First Introduced in Ascendago flights
  departuresOnly: boolean;
}
