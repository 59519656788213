import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { RawFlightDestination } from "../types";
import { Base } from "booking-app-v2/shared/models";

export class FlightDestination extends Base {
  lat: number;
  lng: number;
  city: string;
  country: string;
  term: string;
  value: string;
  highlighted: {
    term: string;
    value: string;
    city: string;
    country: string;
  };
  searchResultQueryFormat: string;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    rawFlightDestination: RawFlightDestination,
  ) {
    super(appSettingsService, globalData);

    this.lat = rawFlightDestination.lat;
    this.lng = rawFlightDestination.lng;
    this.city = rawFlightDestination.city;
    this.country = rawFlightDestination.country;
    this.term = rawFlightDestination.term;
    this.value = rawFlightDestination.value;
    this.highlighted = {
      term: rawFlightDestination.term,
      value: rawFlightDestination.value,
      city: rawFlightDestination.highlighted.city,
      country: rawFlightDestination.highlighted.city,
    };
    this.searchResultQueryFormat = this.getSearchResultQueryFormat();
  }

  getSearchResultQueryFormat(): string {
    if (this.city && this.term) {
      return `${this.city} - ${this.term} (${this.value})`;
    } else if (this.term && this.value) {
      return `${this.term} (${this.value})`;
    } else if (this.value) {
      return `${this.value}`;
    } else {
      return "";
    }
  }
}
