import { Injectable } from "@angular/core";

import * as Cookies from "es-cookie";

import { WindowRefService } from "./../window-ref.service";
import { Currency } from "../../models/currency.model";

@Injectable({
  providedIn: "root",
})
export class CookiesService {
  constructor(private windowService: WindowRefService) {}

  public init(): void {
    this.windowService.nativeWindow.geoLocale = Cookies.get("geolocale");
    Cookies.remove("currency", { path: "/results" });
    Cookies.remove("showInterstitial", { path: "/results" });
    Cookies.remove("partner", { path: "/results" });
    Cookies.remove("landingPage", { path: "/results" });
    Cookies.remove("countrySite", { path: "/results" });
  }

  getLocale(): string {
    return Cookies.get("lang");
  }

  setLocale(value: string): void {
    Cookies.set("lang", value);
  }

  getGeoLocale(): string {
    return Cookies.get("geolocale");
  }

  setCurrency(currency: Currency): void {
    Cookies.set("currency", currency.code);
  }

  getCurrency(): string {
    return Cookies.get("currency");
  }

  getPartner(): number {
    return parseInt(Cookies.get("partner"));
  }

  setPartner(value: number): void {
    Cookies.set("partner", value.toString());
  }

  getGulfResident(): string {
    return Cookies.get("gulfResident");
  }

  setGulfResident(value: string): void {
    Cookies.set("gulfResident", value.toString());
  }

  getKnownCard(): string {
    return Cookies.get("known");
  }

  setKnownCard(value: boolean, exp?: Date): void {
    Cookies.set("known", value.toString(), {expires: exp});
  }

  getImpersonated(): string {
    return Cookies.get("impersonated");
  }

  setImpersonated(value: boolean): void {
    Cookies.set("impersonated", value.toString());
  }
}
