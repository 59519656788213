import { ObjectValues } from "./object-values.type";

export const SIMPLE_DIALOG = {
  RESEND_CONFIRMATION_SUCCESS: "resend-confirmation-success-dialog",
  RESEND_CONFIRMATION_FAILED: "resend-confirmation-failed-dialog",
  CANCEL_BOOKING: "cancel-booking-dialog",
  CANCEL_BOOKING_REQUEST: "cancel-booking-request-dialog",
  CANCELLING_BOOKING: "cancelling-booking-dialog",
  CANCEL_BOOKING_SUCCESS: "cancel-booking-success-dialog",
  CANCEL_BOOKING_FAILED: "cancel-booking-failed-dialog",
  CANCEL_BOOKING_REQUEST_SUCCESS: "cancel-booking-request-success-dialog",
  CANCEL_BOOKING_REQUEST_FAILED: "cancel-booking-request-failed-dialog",
  GULF_RESIDENCY: "gulf-residency-dialog",
  HOTEL_ROOM_UNAVAILABLE: "hotel-room-unavailable-dialog",
  FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE: "flights-expiry-back-to-results-page-dialog",
  FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE: "flights-expiry-back-to-landing-page-dialog",
  PAYMENT_CARD_VALIDATION: "payment-card-validation-dialog",
  BONUS_VALIDATION: "bonus-validation-dialog",
  INVALID_CREDIT_CARD_FOR_PARTNER: "invalid-credit-card-for-partner-dialog",
  CHANGE_PARTNER: "change-partner-dialog",
  USER_INELIGIBILITY: "user-ineligibility-dialog",
  CARD_VALIDATION: "card-validation-dialog",
} as const;

export type SimpleDialog = ObjectValues<typeof SIMPLE_DIALOG>;

export const COMPLEX_DIALOG = {
  LOGIN: "login-dialog",
  REGISTER: "register-dialog",
  FORGET_PASSWORD: "forget-password-dialog",
  CARS_TERMS: "cars-terms-dialog",
  FLIGHTS_TERMS: "flights-terms-dialog",
  HOTEL_EDIT_SEARCH: "hotel-edit-search-dialog",
  FLIGHT_EDIT_SEARCH: "flight-edit-search-dialog",
  CAR_EDIT_SEARCH: "car-edit-search-dialog",
  TRUSTYOU: "trustyou-dialog",
  GALLERY: "gallery-dialog",
  INSUFFICIENT_POINTS_CHECK: "insufficient-points-check-dialog",
  INVALID_CREDIT_CARD: "invalid-credit-card-dialog",
  ROOM_DETAILS_WITH_DESCRIPTION: "room-details-with-description-dialog",
  ROOM_DETAILS_NO_DESCRIPTION: "room-details-no-description-dialog",
  MAPBOX_DIRECTION: "mapbox-direction",
} as const;

export type ComplexDialog = ObjectValues<typeof COMPLEX_DIALOG>;

export type Dialog = SimpleDialog | ComplexDialog;
