import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { Base } from "booking-app-v2/shared/models/index";

export class User extends Base {
  readonly bookings_exist: boolean;
  readonly display_name: string;
  readonly email: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly gender: string;
  readonly id: number;
  readonly redemption_member_no: string;
  readonly redemption_member_tier: string
  readonly redemption_member_tracking_user_id: number;
  readonly redemption_points_balance: number;
  readonly referral_code: string;
  readonly segments: {banner: string, header: string};
  readonly send_marketing: boolean;
  readonly tenant_referrer: number;
  readonly tenant_user_currency: string; // We can change to CurrencyEnum later
  readonly title: string;
  readonly address1?: string;
  readonly city?: string;
  readonly zip_code?: string;
  readonly country?: string;
  readonly state?: string;
  readonly company?: string;
  readonly company_details?: string;
  readonly birthday?: string;
  readonly year?: number;
  readonly month?: number;
  readonly day?: number;
  readonly family_miles?: number;
  readonly telephone?: string;
  readonly account_status?: string;
  readonly passport_number?: string;
  readonly passport_expiry?: string;
  readonly passport_day?: number;
  readonly passport_month?: number;
  readonly passport_year?: number;

  selectedPointsBalance: number;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    user: User,
  ) {
    super(appSettingsService, globalData);

    this.bookings_exist = user.bookings_exist;
    this.display_name = user.display_name;
    this.email = user.email;
    this.first_name = user.first_name;
    this.last_name = user.last_name;
    this.gender = user.gender;
    this.id = user.id;
    this.redemption_member_no = user.redemption_member_no;
    this.redemption_member_tier = user.redemption_member_tier;
    this.redemption_member_tracking_user_id = user.redemption_member_tracking_user_id;
    this.redemption_points_balance = user.redemption_points_balance;
    this.referral_code = user.referral_code;
    this.segments = user.segments;
    this.send_marketing = user.send_marketing;
    this.tenant_referrer = user.tenant_referrer;
    this.tenant_user_currency = user.tenant_user_currency; // We can change to CurrencyEnum later
    this.title = user.title;
    this.address1 = user.address1;
    this.city = user.city;
    this.zip_code = user.zip_code;
    this.country = user.country;
    this.state = user.state;
    this.company = user.company;
    this.company_details = user.company_details;
    this.family_miles = user.family_miles;
    this.telephone = user.telephone;
    this.account_status = user.account_status;
    this.passport_number = user.passport_number;

    if (user.birthday) {
      const birthdayArray: number[] = this.parseDateString(user.birthday);
      this.birthday = user.birthday;
      this.year = birthdayArray[0];
      this.month = birthdayArray[1];
      this.day = birthdayArray[2];
    }

    if (user.passport_expiry) {
      const passportExpiryArray: number[] = this.parseDateString(user.passport_expiry);
      this.passport_expiry = user.passport_expiry;
      this.passport_year = passportExpiryArray[0];
      this.passport_month = passportExpiryArray[1];
      this.passport_day = passportExpiryArray[2];
    }
  }

  get currentPointsBalance(): number {
    return this.appSettingsService.multiplePointsPartner
      ? (this.selectedPointsBalance || 0)
      : (this.redemption_points_balance || 0);
  }

  private parseDateString(rawDateString: string): number[] {
    return rawDateString.split("-").map((value) => parseInt(value, 10));
  }
}
