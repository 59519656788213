import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";

import { ApiDataService } from "./api-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { FlightBooking } from "booking-app-v2/flights/models";
import { HotelBooking } from "booking-app-v2/hotels/models";
import { CarBooking } from "booking-app-v2/cars/models";
import { Booking, PointsPartners } from "booking-app-v2/shared/models";
import {
  BOOKING_FILTER,
  BookingFilter,
  CreateBookingResponse,
  GlobalDataEnum,
  TRAVEL_TYPE,
  Bookings,
} from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class BookingsService {

  constructor(
    private apiDataService: ApiDataService,
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
  ) { }

  // angular-v2-todo: update bookingParams type after CollectBookingParams step is done
  createBooking(bookingParams: any) {
    return this.apiDataService.post("bookings", bookingParams)
      .pipe(map((res: CreateBookingResponse) => res ?? {}));
  }

  getHotelBooking(bookingReference: string, accessToken: string): Observable<HotelBooking> {
    const pointsPartners: PointsPartners = this.globalData.get(GlobalDataEnum.POINTS_PARTNERS);
    return this.getBooking(bookingReference, accessToken)
      .pipe(map((booking: HotelBooking) => {
        return new HotelBooking(this.appSettingsService, this.globalData, booking, pointsPartners);
      }));
  }

  getFlightBooking(bookingReference: string, accessToken: string): Observable<FlightBooking> {
    const pointsPartners: PointsPartners = this.globalData.get(GlobalDataEnum.POINTS_PARTNERS);
    return this.getBooking(bookingReference, accessToken)
      .pipe(map((booking: FlightBooking) => {
        return new FlightBooking(this.appSettingsService, this.globalData, booking, pointsPartners);
      }));
  }

  getCarBooking(bookingReference: string, accessToken: string): Observable<CarBooking> {
    const pointsPartners: PointsPartners = this.globalData.get(GlobalDataEnum.POINTS_PARTNERS);
    return this.getBooking(bookingReference, accessToken)
      .pipe(map((booking: CarBooking) => {
        return new CarBooking(this.appSettingsService, this.globalData, booking, pointsPartners);
      }));
  }

  getBookings(filter: BookingFilter): Observable<Bookings> {
    const pointsPartners: PointsPartners = this.globalData.get(GlobalDataEnum.POINTS_PARTNERS);
    const apiSuffix: string = filter === BOOKING_FILTER.ALL ? "" : filter;

    return this.apiDataService
      .get(`bookings/${apiSuffix}`)
      .pipe(map((bookings: Bookings) => {
        return bookings.map((booking) => {
          if (booking.travel_type === TRAVEL_TYPE.HOTELS) {
            return new HotelBooking(this.appSettingsService, this.globalData, booking as HotelBooking, pointsPartners);
          } else if (booking.travel_type === TRAVEL_TYPE.FLIGHTS) {
            return new FlightBooking(this.appSettingsService, this.globalData, booking as FlightBooking, pointsPartners);
          } else if (booking.travel_type === TRAVEL_TYPE.CARS) {
            return new CarBooking(this.appSettingsService, this.globalData, booking as CarBooking, pointsPartners);
          }
        });
      }));
  }

  sendConfirmationEmail(bookingId: number): Observable<Response> {
    return this.apiDataService
      .post(`bookings/${bookingId}/send_confirmation_email`, {});
  }

  cancelBooking(bookingId: number, token?: string): Observable<Response> {
    const url: string = token ?
      `bookings/${bookingId}/cancel?access_token=${token}` :
      `bookings/${bookingId}/cancel`;
    return this.apiDataService.post(url, {});
  }

  cancelBookingRequest(bookingId: number): Observable<Response> {
    return this.apiDataService.post(`bookings/${bookingId}/cancel_request`, {});
  }

  private getBooking(bookingReference: string, accessToken: string): Observable<Booking> {
    return this.apiDataService
      .get(`bookings/${bookingReference}?access_token=${accessToken}`);
  }
}
