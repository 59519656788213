import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { StarRatingComponent } from "./star-rating.component";

@NgModule({
  declarations: [ StarRatingComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ StarRatingComponent ],
  providers: [],
})
export class StarRatingModule {}
