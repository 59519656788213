import { Injectable } from "@angular/core";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { TENANT } from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class PaymentTimerService {

  paymentInProgressMsg: string | undefined;
  private paymentInProgressMsgArr: string[] = [
    "Payment in progress",
    "Hang on, we need a bit more time...",
    "You’re almost there!",
  ];

  constructor(
    private appSettingsService: AppSettingsService,
  ) { }

  paymentInProgressTimer(): void {
    if (this.appSettingsService.tenant !== TENANT.OLDHAM) {
      return;
    }

    let timeout: number = 3000;

    this.paymentInProgressMsg = this.paymentInProgressMsgArr[0];
    this.paymentInProgressMsgArr.shift();

    const updatePaymentMsg = () => {
      timeout = 6000;
      if (this.paymentInProgressMsgArr.length > 0) {
        this.paymentInProgressMsg = this.paymentInProgressMsgArr.shift();
      } else {
        clearTimeout(paymentInProgressInterval);
      }
      setTimeout(updatePaymentMsg, timeout);
    };

    const paymentInProgressInterval: ReturnType<typeof setTimeout> = setTimeout(updatePaymentMsg, timeout);
  }

}
