import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { RoomDetailsDialogComponent } from "./room-details-dialog.component";
import { RoomDetailsDialogContentModule } from "booking-app-v2/shared/components/dialog/room-details-dialog-content/room-details-dialog-content.module";

@NgModule({
  declarations: [ RoomDetailsDialogComponent ],
  imports: [
    SharedModule,
    RoomDetailsDialogContentModule,
  ],
  exports: [ RoomDetailsDialogComponent ],
  providers: [ DialogService ],
  entryComponents: [ RoomDetailsDialogComponent ],
})
export class RoomDetailsDialogModule { }
