import { ObjectValues } from "./object-values.type";

export const BOOKING_TRANSACTION_STATUS = {
  IN_PROGRESS: "in progress",
  SUCCESS: "success",
  PAYMENT_STARTED: "payment_started",
  REQUIRES_USER_AUTHENTICATION: "requires_user_authentication",
  PAYMENT_PENDING: "payment_pending",
  PAYMENT_CONFIRMED: "payment_confirmed",
  FAIL: "fail",
} as const;

export type BookingTransactionStatus = ObjectValues<typeof BOOKING_TRANSACTION_STATUS>;
