import { Injectable } from "@angular/core";

import { LandingPage } from "booking-app-v2/shared/models";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CurrenciesService } from "booking-app-v2/shared/services/initializers/currencies.service";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";
import { Hotel, HotelDestination, HotelSearchForm, RoomPrice } from "../models";
import { HotelCommonQueryParams, HotelRawPrice } from "../types";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { HotelsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/hotels-url-builder.service";

@Injectable({
  providedIn: "root",
})
export class HotelUtilService {
  private landingPage: LandingPage;

  constructor(
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private currenciesService: CurrenciesService,
    private payWithPointsCashService: PayWithPointsCashService,
    private hotelsUrlBuilderService: HotelsUrlBuilderService,
  ) {
    this.landingPage = this.globalData.get(GlobalDataEnum.LANDING_PAGE);
   }

  collatePricesToDetails(hotelRawPrices: HotelRawPrice[], hotels: Hotel[]): Hotel[] {
    if (!hotelRawPrices || (hotelRawPrices && hotelRawPrices.length === 0)) {
      return hotels;
    }

    for (const hotel of hotels) {
      let matched: boolean = false;
      for (const hotelRawPrice of hotelRawPrices) {
        if (hotelRawPrice.id === hotel.id) {
          hotel.priceInfo = new RoomPrice(
            this.appSettingsService,
            this.globalData,
            this.currenciesService,
            this.payWithPointsCashService,
            hotelRawPrice,
          );
          hotel.available = this.isHotelAvailable(hotel.priceInfo);
          matched = true;
          break;
        }
      }

      // We need to set unmatched rates to null because they might have been set to some value
      // in one of the previous iterations, to prevent out-of-date prices to be
      // shown in results
      if (!matched) {
        hotel.priceInfo = null;
        hotel.available = false;
      }
    }
    this.applyResultListModifiers(hotels);
    return hotels;
  }

  getHotelDestinationUrl(): string {
    return this.hotelsUrlBuilderService.buildUrlForHotels(this.getHotelDestination());
  }

  getHotelDestination(): HotelDestination {
    const hotelSearchForm: HotelSearchForm = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    if (hotelSearchForm) {
      return hotelSearchForm.destination;
    } else {
      return this.hotelsUrlBuilderService.buildDestinationFromUrl();
    }
  }

  setHotelSearchFormFromQueryParams(params: HotelCommonQueryParams): void {
    if (!this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM)) {
      const hotelSearchForm: HotelSearchForm = new HotelSearchForm(
        this.getHotelDestination(), params.checkInDate, params.checkOutDate, params.guests, null, null, null, null,
      );
      this.globalData.set(GlobalDataEnum.HOTEL_SEARCH_FORM, hotelSearchForm);
    }
  }

  getUrlForPriceAndReviews(path: string, bookingKey: string = null, couponCode: string = null): string {
    return this.hotelsUrlBuilderService.buildUrlForPriceAndReviews(
      path,
      bookingKey,
      couponCode,
    );
  }

  getUrlForHotelPriceCache(): string {
    return this.hotelsUrlBuilderService.buildUrlForHotelPriceCache();
  }

  private isHotelAvailable(roomPrice: RoomPrice): boolean {
    return (roomPrice.price && roomPrice.price > 0) ||
      (roomPrice.max_points_payment && roomPrice.max_points_payment > 0) ||
      (roomPrice.max_cash_payment && roomPrice.max_cash_payment > 0) ||
      !!roomPrice.voucher_type_id;
  }

  private applyResultListModifiers(hotels: Hotel[]): void {
    for (const modifier of this.appSettingsService.search_results.modifiers) {
      modifier.perform(hotels);
    }
  }

}
