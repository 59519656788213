import { TimeUtils } from "booking-app-v2/shared/utils";
import { CarDestination } from "booking-app-v2/cars/models";
import { Dayjs } from "dayjs";

export class CarSearchForm {
  static readonly CAR_SEARCH_DATE_FORMAT: string = "YYYY-MM-DDTHH:mm";
  readonly pickupLocation: CarDestination;
  readonly returnLocation: CarDestination;
  readonly pickupTime: string;
  readonly returnTime: string;
  readonly pickupDate: string;
  readonly returnDate: string;
  readonly sameAsPickup: boolean;
  readonly searchDatesValid: boolean;
  duration: number;
  pickupDateTime: Dayjs;
  returnDateTime: Dayjs;

  constructor(
    pickupLocation: CarDestination,
    returnLocation: CarDestination,
    pickupTime: string,
    returnTime: string,
    pickupDate: string,
    returnDate: string,
    sameAsPickup: boolean,
  ) {
    this.pickupLocation = pickupLocation;
    this.returnLocation = returnLocation;
    this.pickupTime = pickupTime;
    this.returnTime = returnTime;
    this.pickupDate = pickupDate;
    this.returnDate = returnDate;
    this.sameAsPickup = sameAsPickup;
    this.initDateTimeVariables();
    this.searchDatesValid = this.isSearchDatesValid();
  }

  isEqual(newSearchForm: CarSearchForm) {
    return (
      this.pickupLocation?.id === newSearchForm.pickupLocation?.id &&
      this.returnLocation?.id === newSearchForm.returnLocation?.id &&
      this.pickupDateTime.diff(newSearchForm.pickupDateTime, "m") === 0 &&
      this.returnDateTime.diff(newSearchForm.returnDateTime, "m") === 0
    );
  }

  private initDateTimeVariables(): void {
    const [pickupHour, pickupMinute] = this.pickupTime.split(":").map(Number);
    const [returnHour, returnMinute] = this.returnTime.split(":").map(Number);
    this.pickupDateTime = TimeUtils.parseDate(this.pickupDate)
                          .set("hour", pickupHour)
                          .set("minute", pickupMinute)
                          .set("second", 0)
                          .set("millisecond", 0);
    this.returnDateTime = TimeUtils.parseDate(this.returnDate)
                          .set("hour", returnHour)
                          .set("minute", returnMinute)
                          .set("second", 0)
                          .set("millisecond", 0);
    this.duration = TimeUtils.diff(this.returnDateTime, this.pickupDateTime, "day");
  }

  private isSearchDatesValid(): boolean {
    if (!this.pickupDateTime.isValid || !this.returnDateTime.isValid) {
      return false;
    }

    const today: string = TimeUtils.setStartOfTime("day").format(CarSearchForm.CAR_SEARCH_DATE_FORMAT);

    return TimeUtils.diff(this.pickupDateTime, today, "d") >= 0 &&
           TimeUtils.diff(this.returnDateTime, this.pickupDateTime, "m") >= 30;
  }
}
