import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";
import { Base } from "booking-app-v2/shared/models";
import { FlightPrice } from "booking-app-v2/flights/models";
import { FlightRawResponse, FlightSegment } from "booking-app-v2/flights/types";

export class Flight extends Base {
  readonly bonus_program_type: string;
  readonly booking_key: string;
  readonly passport_required?: boolean;
  readonly total_earn_points: number;
  readonly rate: number;
  segments: FlightSegment[];
  id: string;
  duration: number;
  priceInfo: FlightPrice;
  departureTimeInMinutes: number;
  arrivalTimeInMinutes: number;
  source: string;
  rawPrice: FlightRawResponse;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    payWithPointsCashService: PayWithPointsCashService,
    flightRawResponse: FlightRawResponse,
  ) {
    super(
      appSettingsService,
      globalData,
    );

    this.rawPrice = flightRawResponse;
    this.bonus_program_type = flightRawResponse.bonus_program_type;
    this.booking_key = flightRawResponse.booking_key;
    this.passport_required = flightRawResponse.passport_required;
    this.segments = flightRawResponse.segments;
    this.total_earn_points = flightRawResponse.base_points_payment;
    this.rate = flightRawResponse.rate;
    this.source = flightRawResponse.source;
    this.priceInfo = new FlightPrice(
      appSettingsService,
      globalData,
      payWithPointsCashService,
      flightRawResponse,
    );
  }
}
