import { FlightCabin } from "../types";
import { TimeUtils } from "booking-app-v2/shared/utils";

export type FlightSegmentType = "origin" | "return";

export interface FlightSegment {
  aircraft_type: string;
  aircraft_type_name: string;
  airline_full_name: string;
  arrival_time: string;
  arrive_next_day: boolean;
  baggage_allowance: string;
  cabin: FlightCabin;
  cabin_full_name: string;
  departure_time: string;
  duration: number;
  fare_reference: string;
  fare_brand_name: string;
  format_arrival_time: string;
  format_departure_time: string;
  from_airport: string;
  marketing_airline_code: string;
  marketing_airline_name: string;
  marketing_airline_flight_no: string;
  operating_airline_code: string;
  operating_airline_name: string;
  operating_airline_flight_no: string;
  segment_type: FlightSegmentType;
  time_duration: string;
  to_airport: string;
  from_airport_name: string;
  to_airport_name: string;
}

export const flightsSegmentId = (segment: FlightSegment): string => {
  return [
    segment.marketing_airline_code,
    segment.operating_airline_code,
    segment.operating_airline_flight_no,
    segment.cabin,
    segment.fare_brand_name,
  ].join("-");
};

export const departureTime = (segment: FlightSegment): string => {
  return TimeUtils.format(segment.departure_time, "HH:mm");
};
