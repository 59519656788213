import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { TrustYou } from "booking-app-v2/hotels/models";
import { TrustYouDialogContent } from "booking-app-v2/shared/types";

@Component({
  selector: "trustyou-dialog",
  templateUrl: "/html/dialogs/trustyou_review_dialog_v2",
})
export class TrustYouDialogComponent implements OnInit {
  trustyou: TrustYou;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TrustYouDialogContent,
  ) { }

  ngOnInit() {
    this.trustyou = this.data.trustyou;
  }
}
