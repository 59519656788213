import { Injectable } from "@angular/core";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CHECKOUT_RESULT_NAME, GlobalDataEnum, TRAVEL_TYPE, TravelType } from "booking-app-v2/shared/types";
import {
  BaseStep,
  BuildCheckoutFormService,
  CarAvailabilityCheckService,
  CarBuildSearchFormService,
  CarCollectBookingParamsService,
  CarFetchPriceService,
  CarFetchTermsService,
  CarRedirectToDetailsService,
  CreateBookingService,
  FetchBookingStatusService,
  FetchBookingTransactionDataService,
  FetchMembershipService,
  FetchSavedCardsService,
  FlightAvailabilityCheckService,
  FlightCollectBookingParamsService,
  FlightFetchPriceService,
  FlightRedirectToDetailsService,
  ForceSyncBaseStep,
  HotelAvailabilityCheckService,
  HotelBuildSearchFormService,
  HotelCollectBookingParamsService,
  HotelFetchInfoService,
  HotelFetchPolicyService,
  HotelFetchPriceService,
  HotelRedirectToDetailsService,
  InitFormDataService,
  LoginCheckService,
  PointsBalanceCheckService,
  SelectPaymentChannelService,
  StartLoadingService,
  StartPaymentLoadingService,
  StartPaymentTimerService,
  ValidateBookingService,
  ValidateCouponCodeService,
} from "booking-app-v2/shared/services/checkout/steps";
import { SkipCheckoutStep } from "./steps/skip-checkout-step";

@Injectable({
  providedIn: "root",
})
export class CheckoutStepsService {

  readonly pageLoadSteps: Array<BaseStep | ForceSyncBaseStep>;
  readonly validateCouponCodeSteps: Array<BaseStep | ForceSyncBaseStep>;
  readonly processBookingSteps: Array<BaseStep | ForceSyncBaseStep>;
  private readonly travelType: TravelType = this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);

  constructor(
    private globalData: GlobalData,
    private loginCheckService: LoginCheckService,
    private hotelRedirectToDetailsService: HotelRedirectToDetailsService,
    private carRedirectToDetailsService: CarRedirectToDetailsService,
    private flightRedirectToDetailsService: FlightRedirectToDetailsService,
    private startLoadingService: StartLoadingService,
    private hotelBuildSearchFormService: HotelBuildSearchFormService,
    private carBuildSearchFormService: CarBuildSearchFormService,
    private hotelFetchInfoService: HotelFetchInfoService,
    private fetchBookingTransactionDataService: FetchBookingTransactionDataService,
    private fetchBookingStatusService: FetchBookingStatusService,
    private startPaymentTimerService: StartPaymentTimerService,
    private fetchMembershipService: FetchMembershipService,
    private hotelFetchPolicyService: HotelFetchPolicyService,
    private carFetchTermsService: CarFetchTermsService,
    private hotelFetchPriceService: HotelFetchPriceService,
    private carFetchPriceService: CarFetchPriceService,
    private flightFetchPriceService: FlightFetchPriceService,
    private pointsBalanceCheckService: PointsBalanceCheckService,
    private buildCheckoutFormService: BuildCheckoutFormService,
    private initFormDataService: InitFormDataService,
    private hotelAvailabilityCheckService: HotelAvailabilityCheckService,
    private carAvailabilityCheckService: CarAvailabilityCheckService,
    private flightAvailabilityCheckService: FlightAvailabilityCheckService,
    private fetchSavedCardsService: FetchSavedCardsService,
    private validateCouponCodeService: ValidateCouponCodeService,
    private selectPaymentChannelService: SelectPaymentChannelService,
    private validateBookingService: ValidateBookingService,
    private hotelCollectBookingParamsService: HotelCollectBookingParamsService,
    private flightCollectBookingParamsService: FlightCollectBookingParamsService,
    private carCollectBookingParamsService: CarCollectBookingParamsService,
    private createBookingService: CreateBookingService,
    private startPaymentLoadingService: StartPaymentLoadingService,
  ) {
    this.travelType = this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);

    this.pageLoadSteps = [
      this.loginCheckService,
      this.startLoadingService,
      this.buildSearchFormStep(),
      this.redirectToDetailsStep(),
      this.fetchInfoStep(),
      this.fetchBookingTransactionDataService,
      this.fetchBookingStatusService,
      this.startPaymentTimerService,
      this.fetchMembershipService,
      this.fetchPolicyStep(),
      this.fetchPriceStep(),
      this.availabilityCheckStep(),
      this.pointsBalanceCheckService,
      this.fetchSavedCardsService,
      this.initFormDataService,
      this.buildCheckoutFormService,
    ];
    this.validateCouponCodeSteps = [
      this.validateCouponCodeService,
      this.fetchPriceStep(),
      this.availabilityCheckStep(),
      this.pointsBalanceCheckService,
    ];
    this.processBookingSteps = [
      this.validateBookingService,
      this.startPaymentTimerService,
      this.startPaymentLoadingService,
      this.selectPaymentChannelService,
      this.collectBookingParamsStep(),
      this.createBookingService,
      this.fetchBookingStatusService,
    ];
  }

  private collectBookingParamsStep(): BaseStep {
    switch (this.travelType) {
      case TRAVEL_TYPE.HOTELS: {
        return this.hotelCollectBookingParamsService;
      }
      case TRAVEL_TYPE.CARS: {
        return this.carCollectBookingParamsService;
      }
      case TRAVEL_TYPE.FLIGHTS: {
        return this.flightCollectBookingParamsService;
      }
    }
  }

  private redirectToDetailsStep(): BaseStep {
    switch (this.travelType) {
      case TRAVEL_TYPE.HOTELS: {
        return this.hotelRedirectToDetailsService;
      }
      case TRAVEL_TYPE.CARS: {
        return this.carRedirectToDetailsService;
      }
      case TRAVEL_TYPE.FLIGHTS: {
        return this.flightRedirectToDetailsService;
      }
    }
  }

  private buildSearchFormStep(): BaseStep | ForceSyncBaseStep {
    switch (this.travelType) {
      case TRAVEL_TYPE.HOTELS: {
        return this.hotelBuildSearchFormService;
      }
      case TRAVEL_TYPE.CARS: {
        return this.carBuildSearchFormService;
      }
      case TRAVEL_TYPE.FLIGHTS: {
        return new SkipCheckoutStep(CHECKOUT_RESULT_NAME.BUILD_SEARCH_FORM);
      }
    }
  }

  private fetchInfoStep(): BaseStep {
    if (this.travelType === TRAVEL_TYPE.HOTELS) {
      return this.hotelFetchInfoService;
    } else {
      return new SkipCheckoutStep(CHECKOUT_RESULT_NAME.FETCH_INFO);
    }
  }

  private fetchPolicyStep(): BaseStep {
    switch (this.travelType) {
      case TRAVEL_TYPE.HOTELS: {
        return this.hotelFetchPolicyService;
      }
      case TRAVEL_TYPE.CARS: {
        return this.carFetchTermsService;
      }
      case TRAVEL_TYPE.FLIGHTS: {
        return new SkipCheckoutStep(CHECKOUT_RESULT_NAME.FETCH_POLICY);
      }
    }
  }

  private fetchPriceStep(): BaseStep | ForceSyncBaseStep {
    switch (this.travelType) {
      case TRAVEL_TYPE.HOTELS: {
        return this.hotelFetchPriceService;
      }
      case TRAVEL_TYPE.CARS: {
        return this.carFetchPriceService;
      }
      case TRAVEL_TYPE.FLIGHTS: {
        return this.flightFetchPriceService;
      }
    }
  }

  private availabilityCheckStep(): BaseStep | ForceSyncBaseStep {
    switch (this.travelType) {
      case TRAVEL_TYPE.HOTELS: {
        return this.hotelAvailabilityCheckService;
      }
      case TRAVEL_TYPE.CARS: {
        return this.carAvailabilityCheckService;
      }
      case TRAVEL_TYPE.FLIGHTS: {
        return this.flightAvailabilityCheckService;
      }
    }
  }

}
