import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";

/*** HOW TO USE
 * Use <accordion> with 2 child elements that has attribute title and content each
 * e.g.
 * <accordion>
 *   <div title></div>
 *   <div content></div>
 * </accordion>
 */
@Component({
  selector: "accordion",
  template: `
    <div class="header" (click)="togglePanel()" [ngClass]="{ active: showPanel }">
      <ng-content select="[accordionTitle]"></ng-content>
      <span *ngIf="!disableDropdownArrow"><em class="fa fa-angle-down"></em></span>
    </div>
    <div #content class="content" [style.height.px]="contentHeight">
      <ng-content select="[accordionContent]"></ng-content>
    </div>
  `,
})
export class AccordionComponent implements AfterViewInit {
  @ViewChild("content") contentElement: ElementRef;
  @Input() disableDropdownArrow: boolean = false;

  showPanel: boolean;
  contentHeight: number;
  contentOriginalHeight: number;

  constructor(private changeDetectionRef: ChangeDetectorRef) {
    this.showPanel = false;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.contentOriginalHeight = this.contentElement.nativeElement.clientHeight + 15;
      // Hide content part after we saved its original height
      this.contentHeight = 0;
      this.changeDetectionRef.detectChanges();
    }, 0);
  }

  togglePanel(): void {
    this.showPanel = !this.showPanel;
    this.contentHeight = this.showPanel ? this.contentOriginalHeight : 0;
  }
}
