import { SIMPLE_DIALOG, SimpleDialogContents } from "booking-app-v2/shared/types";

export const simpleDialogContents: SimpleDialogContents  = {
  [SIMPLE_DIALOG.RESEND_CONFIRMATION_SUCCESS]: {
    dialogHeader: "Confirmation email sent",
    resolveButtonText: "OK",
  },
  [SIMPLE_DIALOG.RESEND_CONFIRMATION_FAILED]: {
    dialogHeader: "Send Failed. Please try again",
    resolveButtonText: "Ok",
  },
  [SIMPLE_DIALOG.CANCEL_BOOKING_SUCCESS]: {
    dialogHeader: "wl.booking_cancelled_header",
    dialogBody: "wl.booking_cancelled_message",
    resolveButtonText: "Ok",
  },
  [SIMPLE_DIALOG.CANCEL_BOOKING_FAILED]: {
    dialogHeader: "bp.admin.notifications.error",
    resolveButtonText: "Ok",
  },
  [SIMPLE_DIALOG.CANCEL_BOOKING]: {
    dialogBody: "my_bookings.cancel_booking.are_you_sure",
    resolveButtonText: "Yes",
    rejectButtonText: "No",
  },
  [SIMPLE_DIALOG.CANCELLING_BOOKING]: {
    dialogBody: "Cancelling...",
  },
  [SIMPLE_DIALOG.CANCEL_BOOKING_REQUEST]: {
    dialogHeader: "my_bookings.cancel_booking_request.title",
    dialogBody: "my_bookings.cancel_booking_request.are_you_sure",
    resolveButtonText: "Ok",
    rejectButtonText: "Cancel",
  },
  [SIMPLE_DIALOG.CANCEL_BOOKING_REQUEST_SUCCESS]: {
    dialogHeader: "my_bookings.cancel_booking_request.success",
    resolveButtonText: "OK",
  },
  [SIMPLE_DIALOG.CANCEL_BOOKING_REQUEST_FAILED]: {
    dialogHeader: `Your cancellation request has not been sent.<br>Please try again.`,
    resolveButtonText: "OK",
  },
  [SIMPLE_DIALOG.GULF_RESIDENCY]: {
    dialogHeader: "search.gulf_resident.header",
    dialogBody: "search.gulf_resident.body",
    resolveButtonText: "Yes",
    rejectButtonText: "No",
  },
  [SIMPLE_DIALOG.HOTEL_ROOM_UNAVAILABLE]: {
    dialogBody: "cancellation_policy_error_message",
    resolveButtonText: "cancellation_policy_error_button",
  },
  [SIMPLE_DIALOG.FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE]: {
    dialogBody: "flights.itinerary_unavailable",
    resolveButtonText: "flights.back_to_search_results",
  },
  [SIMPLE_DIALOG.FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE]: {
    dialogBody: "flights.itinerary_unavailable",
    resolveButtonText: "flights.back_to_main_page",
  },
  [SIMPLE_DIALOG.PAYMENT_CARD_VALIDATION]: {
    dialogHeader: "Payment card validation",
    resolveButtonText: "Continue",
  },
  [SIMPLE_DIALOG.BONUS_VALIDATION]: {
    dialogHeader: "hotels_checkout.bonus_validation.modal_header",
    resolveButtonText: "Ok",
  },
  [SIMPLE_DIALOG.INVALID_CREDIT_CARD_FOR_PARTNER]: {
    dialogHeader: "Invalid credit card validation",
    resolveButtonText: "Ok",
  },
  [SIMPLE_DIALOG.USER_INELIGIBILITY]: {
    dialogBody: "user_ineligibility.modal_body",
    resolveButtonText: "OK",
  },
  [SIMPLE_DIALOG.CARD_VALIDATION]: {
    dialogHeader: "wl.visa.non-visa_card",
    resolveButtonText: "session_timer.modal.continue",
  },
};
