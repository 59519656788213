import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { MapboxService } from "booking-app-v2/hotels/services";
import { BottomSheetService } from "booking-app-v2/shared/services/bottom-sheet.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "hotel-result-map-mobile",
  template: `
    <div class="hotel-result-map-mobile">
      <button class="hotel-result-map-mobile-button" (click)="openBottomSheet()">
        <ng-container *ngIf="mapIconSource">
          <img [cdn-path]="mapIconSource" alt="">
        </ng-container>
        <div class="map-label" [translate]="'Map'"></div>
      </button>

      <ng-template #mapBottomSheet>
        <bottom-sheet-wrapper>
          <div class="map-title" bottomSheetTitle>
            <span [translate]="'hotel_result_map_mobile.title'"></span>
          </div>
          <div class="max-size" id="resultMapContainer" bottomSheetBody>
            <div id="mapbox-search-map-mobile"></div>
          </div>
        </bottom-sheet-wrapper>
      </ng-template>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotelResultMapMobileComponent implements OnInit {
  @ViewChild("mapBottomSheet") mapBottomSheetTemplate: TemplateRef<any>;

  mapIconSource: string;

  constructor(
    private bottomSheetService: BottomSheetService,
    private appSettingsService: AppSettingsService,
    private mapboxService: MapboxService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.mapboxService.selectedHotelChanged
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.cdRef.markForCheck();
      });
  }

  ngOnInit() {
    this.mapIconSource = this.appSettingsService.mobileResultPopupConfig.mapIconSource;
  }

  openBottomSheet(): void {
    this.bottomSheetService.open(this.mapBottomSheetTemplate, ["hotel-result-map-mobile"]);
    setTimeout(() => {
      this.mapboxService.toggleMobileViewMode();
    });
  }
}
