import { Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { RecaptchaComponent, RecaptchaErrorParameters } from "ng-recaptcha";
import { finalize } from "rxjs";
import Rollbar from "rollbar";

import { UserService } from "booking-app-v2/shared/services/user.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { RollbarService } from "booking-app-v2/shared/services/rollbar.service";
import { ApiDataError, FormStatus, GlobalDataEnum, RegisterParams } from "booking-app-v2/shared/types";

@Component({
  selector: "register-dialog",
  templateUrl: "/html/dialogs/register_dialog_v2",
})
export class RegisterDialogComponent {
  @ViewChild("recaptcha") recaptchaElement: RecaptchaComponent;
  registerForm: FormGroup;
  formStatus: FormStatus;

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private windowRefService: WindowRefService,
    private globalData: GlobalData,
  ) {
    this.formStatus = { processing: false, success: false, errors: [] };
    this.registerForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      firstName: new FormControl("", Validators.required),
      lastName: new FormControl(""),
      recaptcha: new FormControl("", Validators.required),
    });
  }

  validateForm(): void {
    this.markFormAsDirty();

    // Only execute recaptcha after all the other fields are valid
    if (this.onlyRecaptchaInvalid()) {
      this.recaptchaElement.execute();
    }
  }

  submitForm(recaptchaResponse: string): void {
    if (this.registerForm.invalid || !recaptchaResponse) {
      return;
    }

    this.formStatus.processing = true;
    this.userService.register(this.buildRegisterParams(recaptchaResponse))
      .pipe(finalize(() => this.formStatus.processing = false))
      .subscribe({
        next: () => this.handleRegisterSuccess(),
        error: (err: ApiDataError) => {
          this.formStatus.errors = err.errors;
          this.recaptchaElement.reset();
        },
      });
  }

  handleRecaptchaError(errors: RecaptchaErrorParameters): void {
    this.rollbar.warning("RegisterModal RECAPTCHA error: ", errors);
  }

  private buildRegisterParams(recaptchaResponse: string): RegisterParams {
    const registerFormControls = this.registerForm.controls;
    return {
      email: registerFormControls.email.value,
      first_name: registerFormControls.firstName.value,
      last_name: registerFormControls.lastName.value || "",
      recaptcha_response: recaptchaResponse,
      send_marketing: false,
      lp: this.globalData.get(GlobalDataEnum.LANDING_PAGE).url,
      referral_code: this.getReferralCodeFromUrl(),
    };
  }

  private markFormAsDirty(): void {
    this.registerForm.controls.email.markAsDirty();
    this.registerForm.controls.firstName.markAsDirty();
  }

  private handleRegisterSuccess(): void {
    this.formStatus.success = true;
    this.trackLead();

    setTimeout(() => {
      this.windowRefService.nativeWindow.location.reload();
    }, 2000);
  }

  private onlyRecaptchaInvalid(): boolean {
    return this.registerForm.controls.email.valid &&
      this.registerForm.controls.firstName.valid &&
      this.registerForm.controls.lastName.valid &&
      this.registerForm.controls.recaptcha.invalid;
  }

  private getReferralCodeFromUrl(): string {
    const queryParamMapSnapshot: ParamMap = this.activatedRoute.snapshot.queryParamMap;
    return queryParamMapSnapshot.get("referral") || queryParamMapSnapshot.get("refer");
  }

  // TODO: Migrate facebookPixelService
  private trackLead(): void {
    // this.facebookPixelService.eventTrack("Lead", {
    //   content_category: "LandingPage",
    //   content_name: this.globalData.get(GlobalDataEnum.LANDING_PAGE).url,
    // });
  }
}
