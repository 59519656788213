import { Injectable } from "@angular/core";
import { ReplaySubject, Subject } from "rxjs";

import { BaseStep } from "./base-step";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  ValidateCouponCodeResult,
} from "booking-app-v2/shared/types";
import { CouponService } from "booking-app-v2/shared/services/coupon.service";

@Injectable({
  providedIn: "root",
})
export class ValidateCouponCodeService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.VALIDATE_COUPON_CODE;

  constructor(
    private couponService: CouponService,
  ) {}

  process(results: CheckoutResults): ValidateCouponCodeResult {

    const isValidCouponCode: Subject<boolean> = new ReplaySubject<boolean>(1);

    this.couponService.validateCouponCode().subscribe((valid: boolean) => {
      isValidCouponCode.next(valid);
    });

    return isValidCouponCode.asObservable();
  }
}
