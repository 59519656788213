import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Dayjs } from "dayjs";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { TravelTypeService } from "booking-app-v2/shared/services/initializers/travel-type.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { TimeUtils } from "booking-app-v2/shared/utils";
import { HotelBooking } from "booking-app-v2/hotels/models";

@Component({
  templateUrl: `/html/hotels/success_page_v2`,
})
export class ConfirmationPageComponent implements OnInit {
  booking: HotelBooking;

  constructor(
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private appSettingsService: AppSettingsService,
    private windowRefService: WindowRefService,
    public travelTypeService: TravelTypeService,
  ) {}

  ngOnInit(): void {
    this.booking = this.activatedRoute.snapshot.data.booking;
  }

  serviceEmail(): string {
    return this.appSettingsService.serviceEmail;
  }

  showAccessAndCancelText(): boolean {
    return this.appSettingsService.displayPurchaseAccessAndCancelText;
  }

  navigateToLandingPage(): void {
    // Below product type is the parameter we receive from backend.
    // Not to be confused with the format complementary-nights which we use it front-end.
    const productType: string = this.booking.product_type;
    const url: string = productType === "complimentary_nights" ? productType : "/";
    this.windowRefService.nativeWindow.location.href = url;
  }

  getPendingEmailLabel(): string {
    return this.translateService.instant("purchase.pending.email_in_24h", { email: this.booking.email });
  }

  formattedChildrenInfo(): string {
    const { children_count_info, children_ages } = this.booking;
    return `${children_count_info} (${children_ages.join(", ")})`;
  }

  getDestinationMap(): string {
    const destination: string = this.booking.original_destination_name || this.booking.destination_name;
    if (destination) {
      return `(${destination})`;
    }
  }

  verificationTimeLeft(): string {
    const createdAt: Dayjs = this.booking.created_at && TimeUtils.parseDate(this.booking.created_at);
    const checkInDate: Dayjs = this.booking.checkInDate && TimeUtils.parseDate(this.booking.checkInDate);
    const checkOutDate: Dayjs = this.booking.checkOutDate && TimeUtils.parseDate(this.booking.checkOutDate);

    if (!createdAt || !checkInDate || !checkOutDate) {
      return "";
    }

    if (TimeUtils.diff(createdAt.clone().add(1, "day"), checkInDate, "days") >= 0) {
      return "60 minutes";
    } else if (TimeUtils.diff(createdAt.clone().add(3, "day"), checkInDate, "days") >= 0) {
      return "3 hours";
    }

    return "24 hours";
  }
}
