import { NgModule } from "@angular/core";
import { SharedModule } from "booking-app-v2/shared/shared.module";
import { MatButtonModule } from "@angular/material/button";
import { ComplimentaryPriceBoxComponent } from "./complimentary-price-box.component";

@NgModule({
  declarations: [ComplimentaryPriceBoxComponent],
  imports: [
    SharedModule,
    MatButtonModule,
  ],
  exports: [ComplimentaryPriceBoxComponent],
})
export class ComplimentaryPriceBoxModule {}
