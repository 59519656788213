import AdyenCheckout from "@adyen/adyen-web";
import {
  CbObjOnBinLookup,
  CbObjOnFieldValid,
} from "@adyen/adyen-web/dist/types/components/internal/SecuredFields/lib/types";
import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable, Subscription } from "rxjs";

import { AdyenFormService } from "booking-app-v2/shared/services/adyen-form.service";
import { AdyenField, ADYEN_FIELD } from "booking-app-v2/shared/types";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "adyen-form",
  template: `
    <div id="adyen-custom-card-container">
      <div class="adyen-row">
        <label class="adyen-field-container card-number-field">
          <span>Card number:</span>
          <div class="adyen-field" [ngClass]="{ 'invalid': isCardNumberInvalid }">
            <span data-cse="encryptedCardNumber"></span>
          </div>
          <div *ngIf="isCardNumberInvalid" class="dirtyMsg"
            [translate]="'accounts.error.required.field'"
            [translateParams]="{ field: 'card number' }"
          >
          </div>
        </label>
        <label class="adyen-field-container name-on-card-field">
          <span>Name on Card:</span>
          <input type="text"
            class="adyen-field"
            [ngModel]="nameOnCard"
            (ngModelChange)="handleNameOnCardOnChange($event)"
            [ngClass]="{ 'invalid': isNameOnCardInvalid }"/>
          <mat-error *ngIf="!nameOnCard && checkoutFormSubmitted">
            <span translate="accounts.error.required.field"
                  [translateParams]="{ field: 'Name on Card' | translate | lowercase }"></span>
          </mat-error>
        </label>
      </div>
      <div class="adyen-row security-field">
        <label class="adyen-field-container expiry-date-field">
          <span>Expiry date:</span>
          <div class="adyen-field" [ngClass]="{ 'invalid': isExpiryDateInvalid }">
            <span data-cse="encryptedExpiryDate"></span>
          </div>
          <div *ngIf="isExpiryDateInvalid" class="dirtyMsg"
            [translate]="'accounts.error.required.field'"
            [translateParams]="{ field: 'expiry date' }"
          >
          </div>
        </label>
        <label class="adyen-field-container cvv-field">
          <span>CVV/CVC:</span>
          <div class="adyen-field" [ngClass]="{ 'invalid': isCvvInvalid }">
            <span data-cse="encryptedSecurityCode"></span>
          </div>
          <div *ngIf="isCvvInvalid" class="dirtyMsg"
            [translate]="'accounts.error.required.field'"
            [translateParams]="{ field: 'CVV/CVC' }"
          >
          </div>
        </label>
        <div class="col-sm-3 col-xs-6 security-code">
          <img class="adyen-security-img inline-element" [cdn-path]="'/icons/icn-ccv.svg'">
        </div>
      </div>
    </div>
  `,
})
export class AdyenFormComponent implements OnInit {
  readonly kaligoConfig: KaligoConfig = window.KaligoConfig;

  @Input() brand: string;
  @Input() checkoutForm: FormGroup;
  @Input() checkoutFormSubmittedListener$: Observable<void>;

  isCardNumberInvalid: boolean;
  isExpiryDateInvalid: boolean;
  isCvvInvalid: boolean;
  isNameOnCardInvalid: boolean;

  nameOnCard: string;

  checkoutFormSubmitted: boolean;

  constructor(
    private adyenFormService: AdyenFormService,
  ) {}

  ngOnInit(): void {
    this.renderAdyenCheckoutForm();
    this.initCheckoutFormSubmittedListenerSubscription();
  }

  validateAdyenForm(): void {
    this.checkoutFormSubmitted = true;
    this.checkoutForm.controls.cardName.setValue(this.nameOnCard);
    if (!this.checkoutForm.controls.encryptedCardNumber.value) {
      this.isCardNumberInvalid = true;
    }
    if (!this.checkoutForm.controls.encryptedExpiryMonth.value
      || !this.checkoutForm.controls.encryptedExpiryYear.value) {
      this.isExpiryDateInvalid = true;
    }
    if (!this.checkoutForm.controls.encryptedSecurityCode.value) {
      this.isCvvInvalid = true;
    }
    if (!this.checkoutForm.controls.cardName.value) {
      this.isNameOnCardInvalid = true;
    }
  }

  renderAdyenCheckoutForm() {
    AdyenCheckout(this.adyenFormService.adyenCheckoutParams()).then((checkout) => {
      checkout.create("securedfields", {
        // Optional configuration
        type: "card",
        brands: [this.brand],
        styles: {
          placeholder: {
            color: "#999999",
          },
        },
        // Only for Web Components before 4.0.0.
        // For Web Components 4.0.0 and above, configure aria-label attributes in translation files
        ariaLabels: {
          lang: "en-GB",
          encryptedCardNumber: {
            label: "Credit or debit card number field",
          },
        },
        // Events
        onChange: this.handleOnChange.bind(this),
        onValid: () => { },
        onLoad: () => { },
        onConfigSuccess: () => { },
        onFieldValid: this.onFieldValid.bind(this),
        onBrand: () => { },
        onError: () => { },
        onFocus: () => { },
        onBinValue: (bin) => { },
        onBinLookup: (callbackObj: CbObjOnBinLookup) => { },
      }).mount("#adyen-custom-card-container");
    });
  }

  handleNameOnCardOnChange(inputName: string): void {
    this.nameOnCard = inputName;
    if (!!inputName && this.isNameOnCardInvalid) {
      this.isNameOnCardInvalid = false;
    }
  }

  private initCheckoutFormSubmittedListenerSubscription(): void {
    this.checkoutFormSubmittedListener$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.validateAdyenForm();
      });
  }

  private handleOnChange(state, _component): void {
    this.handleEncryptedCardNumberOnChange(state);
    this.handleEncryptedExpiryDateOnChange(state);
    this.handleEncryptedSecurityCodeOnChange(state);
    this.handleBrowserInfoOnChange(state);
  }

  private handleEncryptedCardNumberOnChange(state): void {
    if (state.errors.encryptedCardNumber && !state.errors.encryptedCardNumber.isValid) {
      this.toggleFieldError(ADYEN_FIELD.CardNumber, false);
    } else {
      this.checkoutForm.controls.encryptedCardNumber.setValue(state.data.paymentMethod.encryptedCardNumber ?? "");
    }
  }

  private handleEncryptedExpiryDateOnChange(state): void {
    if (state.errors.encryptedExpiryDate && !state.errors.encryptedExpiryDate.isValid) {
      this.toggleFieldError(ADYEN_FIELD.ExpiryDate, false);
    } else {
      this.checkoutForm.controls.encryptedExpiryYear.setValue(state.data.paymentMethod.encryptedExpiryYear ?? "");
      this.checkoutForm.controls.encryptedExpiryMonth.setValue(state.data.paymentMethod.encryptedExpiryMonth ?? "");
    }
  }

  private handleEncryptedSecurityCodeOnChange(state): void {
    if (state.errors.encryptedSecurityCode && !state.errors.encryptedSecurityCode.isValid) {
      this.toggleFieldError(ADYEN_FIELD.SecurityCode, false);
    } else {
      this.checkoutForm.controls.encryptedSecurityCode.setValue(state.data.paymentMethod.encryptedSecurityCode ?? "");
    }
  }

  private handleBrowserInfoOnChange(state): void {
    this.checkoutForm.controls.browserInfo.setValue(state.data.browserInfo);
  }

  private onFieldValid(field: CbObjOnFieldValid): void {
    this.toggleFieldError(field.fieldType as AdyenField, field.valid);
  }

  private toggleFieldError(field: AdyenField, isValid: boolean) {
    switch (field) {
      case ADYEN_FIELD.CardNumber: {
        this.isCardNumberInvalid = !isValid;
        break;
      }
      case ADYEN_FIELD.ExpiryDate: {
        this.isExpiryDateInvalid = !isValid;
        break;
      }
      case ADYEN_FIELD.SecurityCode: {
        this.isCvvInvalid = !isValid;
        break;
      }
    }
  }
}
