import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PointsCashPaymentHandlingService {
  constructor() { }

  zeroFullCashPayment(cash_payment: number, points_payment: number): boolean {
    return (!cash_payment || cash_payment < 0) && points_payment === 0;
  }
}
