import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const phoneNumber: string = control.value.replace(/ /g, "");
    const validPhoneNumber: boolean = (
      phoneNumber.match(/\d/g)?.length >= 5 &&
      /^[\d\+\-]+$/.test(phoneNumber)
    );
    return validPhoneNumber ? null : { invalidPhoneNumber: { value: control.value } };
  };
}
