import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BottomSheetService } from "booking-app-v2/shared/services/bottom-sheet.service";

import { FlightSearchForm } from "booking-app-v2/flights/models";

@Component({
  selector: "passengers-field-mobile",
  templateUrl: "/html/flights/passengers_field_mobile_v2",
})
export class PassengersFieldMobileComponent implements OnInit {
  @ViewChild("flightTravellerBottomSheet") flightTravellerBottomSheetTemplate: TemplateRef<any>;

  @Input() adultCount: number;
  @Input() childCount: number;
  @Input() infantCount: number;
  @Output() onUpdateAdultCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateChildCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUpdateInfantCount: EventEmitter<number> = new EventEmitter<number>();

  minAdultCount: number;
  maxAdultCount: number;
  minChildCount: number;
  maxChildCount: number;
  minInfantCount: number;
  maxInfantCount: number;

  constructor(
    private translateService: TranslateService,
    private bottomSheetService: BottomSheetService,
  ) { }

  ngOnInit() {
    this.minAdultCount = FlightSearchForm.MIN_ADULT_COUNT;
    this.maxAdultCount = FlightSearchForm.MAX_ADULT_COUNT;
    this.minChildCount = FlightSearchForm.MIN_CHILD_COUNT;
    this.maxChildCount = FlightSearchForm.MAX_CHILD_COUNT;
    this.minInfantCount = FlightSearchForm.MIN_INFANT_COUNT;
    this.maxInfantCount = FlightSearchForm.MAX_INFANT_COUNT;
  }

  summaryText(): string {
    let res = `${this.adultCaption()}`;

    if (this.childCount > 0) {
      res += `, ${this.childCaption()}`;
    }
    if (this.infantCount > 0) {
      res += `, ${this.infantCaption()}`;
    }

    return res;
  }

  beginSelection(): void {
    this.bottomSheetService.open(this.flightTravellerBottomSheetTemplate, ["flight-traveller-bottom-sheet"]);
  }

  closeBottomSheet(): void {
    this.bottomSheetService.close();
  }

  updateAdultCount(value: number) {
    this.adultCount = value;
    this.onUpdateAdultCount.emit(value);
  }

  updateChildCount(value: number) {
    this.childCount = value;
    this.onUpdateChildCount.emit(value);
  }

  updateInfantCount(value: number) {
    this.infantCount = value;
    this.onUpdateInfantCount.emit(value);
  }

  inputLabel(): string {
    if (this.totalTravelerCount() === 1) {
      return this.translateService.instant("count.traveller_one");
    } else {
      return this.translateService.instant("count.traveller_other", { count: this.totalTravelerCount() });
    }
  }

  private adultCaption(): string {
    if (Number.isInteger(this.childCount)) {
      return this.showLabelAsAdult();
    } else {
      return this.showLabelAsGuest();
    }
  }

  private showLabelAsAdult(): string {
    if (this.adultCount === 1) {
      return this.translateService.instant("count.adult_one");
    } else {
      return this.translateService.instant("count.adult_other", { count: this.adultCount });
    }
  }

  private showLabelAsGuest(): string {
    if (this.adultCount === 1) {
      return this.translateService.instant("Single Guest Count");
    } else {
      return this.translateService.instant("Multiple Guest Count", { count: this.adultCount });
    }
  }

  private childCaption(): string {
    if (this.childCount === 1) {
      return this.translateService.instant("count.children_one");
    } else if (this.childCount > 1) {
      return this.translateService.instant("count.children_other", { count: this.childCount });
    }
  }

  private infantCaption(): string {
    if (this.infantCount === 1) {
      return this.translateService.instant("count.infant_one");
    } else if (this.infantCount > 1) {
      return this.translateService.instant("count.infant_other", { count: this.infantCount });
    }
  }

  private totalTravelerCount(): number {
    return this.adultCount + this.childCount + this.infantCount;
  }
}
