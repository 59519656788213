import { Component, Input, OnInit } from "@angular/core";

import { MapboxService } from "booking-app-v2/hotels/services";
import { Hotel } from "booking-app-v2/hotels/models";

@Component({
  selector: "hotel-list-item-map-button",
  template: `
    <button class="inline-element show-on-map" (click)="showInMap($event)">
      <em class="fa fa-map-marker"></em>
      <span> {{ mapText | translate }} </span>
    </button>
  `,
})
export class HotelListItemMapButtonComponent implements OnInit {
  @Input() hotel: Hotel;
  @Input() mapText: string;

  constructor(
    private mapboxService: MapboxService,
  ) { }

  ngOnInit() {
    this.mapText = this.mapText || "Map";
  }

  showInMap($event: Event): void {
    $event.preventDefault();
    if (
      document.getElementsByTagName("html")[0].classList.contains("is-keyboard-user")
    ) {
      this.openMap(this.hotel, $event.currentTarget as HTMLElement);
    } else {
      this.openMap(this.hotel);
    }
  }

  openMap(hotel?: Hotel, callbackButtonElem?: HTMLElement): void {
    if (callbackButtonElem) {
      this.mapboxService.setCallbackButtonElement(callbackButtonElem);
    }
    this.openWebMap(hotel);
  }

  private openWebMap(hotel: Hotel): void {
    this.mapboxService.focusOnHotel(hotel);
  }
}
