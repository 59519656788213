import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { HotelRemarksComponent } from "./hotel-remarks.component";

@NgModule({
  declarations: [ HotelRemarksComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ HotelRemarksComponent ],
  providers: [],
})
export class HotelRemarksModule {}
