import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { CouponCodeComponent } from "./coupon-code.component";

@NgModule({
  declarations: [ CouponCodeComponent ],
  imports: [
    SharedModule,
    MatInputModule,
    MatButtonModule,
  ],
  exports: [ CouponCodeComponent ],
  providers: [],
})
export class CouponCodeModule {}
