import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BehaviorSubject, firstValueFrom, Observable, Subject, zip } from "rxjs";

import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { CheckoutStepsService } from "booking-app-v2/shared/services/checkout/checkout-steps.service";
import { CountryService } from "booking-app-v2/shared/services/country.service";
import { PaymentMethodService } from "booking-app-v2/shared/services/payment-method.service";

import { BaseStep, ForceSyncBaseStep } from "booking-app-v2/shared/services/checkout/steps";
import {
  BookingTransactionData,
  CHECKOUT_RESULT_NAME,
  CheckoutFormData,
  CheckoutFormResult,
  CheckoutResults,
  GlobalDataEnum,
  IntermediateCheckoutParams,
  PAYMENT_METHOD,
  SavedCard,
  StoredPayments,
} from "booking-app-v2/shared/types";
import { CheckoutError } from "booking-app-v2/shared/models";

@Injectable({
  providedIn: "root",
})
export class CheckoutService {

  results: CheckoutResults;

  constructor(
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
    private checkoutStepsService: CheckoutStepsService,
    private countryService: CountryService,
    private paymentMethodService: PaymentMethodService,
  ) {
    this.resetCheckoutResults();
  }

  resetCheckoutResults(): void {
    this.results = {
      checkoutErrorKey$: new Subject<string>(),
      checkoutLoadingMessage$: new BehaviorSubject<string>("payment_in_progress"),
      isPaymentLoading: false,
      intermediateCheckoutParams: {},
    };
  }

  async pageLoad(): Promise<void> {
    await this.process(this.checkoutStepsService.pageLoadSteps);

    // always stop loading after
    this.globalData.set(GlobalDataEnum.IS_LOADING, false);
  }

  async validateCouponCode(): Promise<void> {
    await this.process(this.checkoutStepsService.validateCouponCodeSteps);
  }

  async processBooking(intermediateCheckoutParams: IntermediateCheckoutParams): Promise<void> {
    // load intermediate data needed for booking processing from component
    this.results.intermediateCheckoutParams = {
      ...this.results.intermediateCheckoutParams,
      ...intermediateCheckoutParams,
    };

    await this.process(this.checkoutStepsService.processBookingSteps);

    // always stop loading after
    this.results.isPaymentLoading = false;
  }

  async initBookingTransactionData(): Promise<BookingTransactionData> {
    const bookingTransactionDataResult =
      this.results[CHECKOUT_RESULT_NAME.FETCH_BOOKING_TRANSACTION_DATA];
    const bookingTransactionData$: Observable<BookingTransactionData> = bookingTransactionDataResult.transactionData;
    return await firstValueFrom<BookingTransactionData>(bookingTransactionData$);
  }

  async initCheckoutFormData(): Promise<CheckoutFormData> {
    const initFormDataResult$: Observable<CheckoutFormData> =
      this.results[CHECKOUT_RESULT_NAME.INIT_FORM_DATA];
    if (initFormDataResult$) {
      return await firstValueFrom<CheckoutFormData>(initFormDataResult$);
    }
  }

  async initCheckoutFormAndSavedCards(): Promise<[CheckoutFormResult, StoredPayments]> {
    // we need to zip both here because to init selectedSavedCard we need checkoutForm to be available
    const checkoutFormResult = this.results[CHECKOUT_RESULT_NAME.BUILD_CHECKOUT_FORM];
    const storedPaymentResult = this.results[CHECKOUT_RESULT_NAME.FETCH_SAVED_CARDS];
    if (checkoutFormResult == null || storedPaymentResult == null) {
      return;
    }

    return await firstValueFrom<[CheckoutFormResult, StoredPayments]>(
      zip(checkoutFormResult, storedPaymentResult),
    );
  }

  isAddressFilled(checkoutForm: FormGroup): boolean {
    return checkoutForm.controls.address1.value ||
      checkoutForm.controls.city.value ||
      checkoutForm.controls.zipCode.value ||
      checkoutForm.controls.country.value;
  }

  initSavedCards({adyen, stripe}: StoredPayments): void {
    this.paymentMethodService.savedCards = this.paymentMethodService.updateStripeCardPaymentChannel(stripe);
    this.paymentMethodService.adyenSavedCards = adyen;
    this.paymentMethodService.filterSavedCards();
  }

  initSelectedSavedCard(checkoutForm: FormGroup): void {
    const savedCards = this.paymentMethodService.savedCards;
    const selectedSavedCard: SavedCard = (savedCards.length > 0 && !this.appSettingsService.hasMultiplePaymentMethods)
      ? savedCards[0]
      : null;
    checkoutForm.controls.selectedSavedCard.setValue(selectedSavedCard);
    this.paymentMethodService.selectedSavedCard = selectedSavedCard;
    if (selectedSavedCard) {
      this.paymentMethodService.setActiveTab(PAYMENT_METHOD.SAVED_CARDS);
    }
  }

  private async process(steps: Array<BaseStep | ForceSyncBaseStep>): Promise<void> {
    for (const step of steps) {
      try {
        this.results[step.resultName] = "processAsync" in step
          ? await firstValueFrom(step.processAsync(this.results))
          : step.process(this.results);
      } catch (error) {
        // change to use instanceof instead when we are compiling to es2015/es6 or above
        if (error.name === CheckoutError.NAME) {
          break;
        } else {
          throw error;
        }
      }
    }
  }
}
