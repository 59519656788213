import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { TrustYouDialogComponent } from "./trust-you-dialog.component";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";

@NgModule({
  declarations: [ TrustYouDialogComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ TrustYouDialogComponent ],
  providers: [ DialogService ],
  entryComponents: [ TrustYouDialogComponent ],
})
export class TrustYouDialogModule {}
