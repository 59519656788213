import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { BottomSheetService } from "booking-app-v2/shared/services/bottom-sheet.service";

@Component({
  selector: "bottom-sheet-wrapper",
  template: `
    <div class="bottom-sheet-title" #title>
      <button class="fa fa-close close-tick"
              [attr.aria-label]="'Close' | translate"
              (click)="closeBottomSheet()"></button>
      <ng-content select="[bottomSheetTitle]"></ng-content>
    </div>
    <div class="bottom-sheet-body" [style.padding-top.px]="titleHeight + bodyTopPadding">
      <ng-content select="[bottomSheetBody]"></ng-content>
    </div>
  `,
})
export class BottomSheetWrapperComponent implements AfterViewInit, OnInit {
  @Input() bodyTopPadding: number;
  @Output() closeCallback: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild("title") titleElement: ElementRef;
  titleHeight: number = 0;

  constructor(
    private bottomSheetService: BottomSheetService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.bodyTopPadding = this.bodyTopPadding ?? 0;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.titleHeight = this.titleElement.nativeElement.offsetHeight;
      this.cdRef.detectChanges();
    });
  }

  closeBottomSheet(): void {
    if (this.closeCallback) {
      this.closeCallback.next();
    }
    this.bottomSheetService.close();
  }
}
