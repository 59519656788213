import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { catchError, Observable, of, throwError } from "rxjs";

import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";

@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(private windowRefService: WindowRefService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(error => this.handleAuthError(error)));
  }

  private handleAuthError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      this.windowRefService.nativeWindow.location.href = "/";
      return of(error.message);
    }
    return throwError(() => error);
  }
}
