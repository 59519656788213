import { Config } from "@ascenda/ngx-simple-dropdown";
import { Base } from "booking-app-v2/shared/models";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { CashRange, RedeemPoints, SortOption, SortType } from "booking-app-v2/shared/types";
import { SearchSortingUtil } from "booking-app-v2/shared/utils";
import { Subject } from "rxjs";
import {
  CarSupplierFilterUnit,
  CarStaticFilterUnit,
  CarStaticFilter,
  CarTypeFilterUnit,
  CAR_STATIC_FILTER,
} from "booking-app-v2/cars/types";

export class FilterForm extends Base {
  // Filter
  name: string;
  name$ = new Subject<string>(); // to get the observable value when input to name field
  carTypeFilterUnits: CarTypeFilterUnit[];
  carSupplierFilterUnits: CarSupplierFilterUnit[];
  carRatingFilterUnits: CarStaticFilterUnit[];
  carSpecificationFilterUnits: CarStaticFilterUnit[];
  carFuelFilterUnits: CarStaticFilterUnit[];
  carTransmissionFilterUnits: CarStaticFilterUnit[];
  redeemPoints: RedeemPoints;
  cashRange: CashRange;

  // Sort
  sortDropdownConfig: Config<SortOption>;
  selectedSortValue: SortOption;

  constructor(
    appSettingsService: AppSettingsService,
    globalData: GlobalData,
    name: string,
    carTypeFilterUnits: CarTypeFilterUnit[],
    carSupplierFilterUnits: CarSupplierFilterUnit[],
    carRatingFilterUnits: CarStaticFilterUnit[],
    carSpecificationFilterUnits: CarStaticFilterUnit[],
    carFuelFilterUnits: CarStaticFilterUnit[],
    carTransmissionFilterUnits: CarStaticFilterUnit[],
    redeemPoints: RedeemPoints,
  ) {
    super(appSettingsService, globalData);
    this.name = name;
    this.carTypeFilterUnits = carTypeFilterUnits || [];
    this.carSupplierFilterUnits = carSupplierFilterUnits || [];
    this.carRatingFilterUnits = carRatingFilterUnits || [];
    this.carSpecificationFilterUnits = carSpecificationFilterUnits || [];
    this.carFuelFilterUnits = carFuelFilterUnits || [];
    this.carTransmissionFilterUnits = carTransmissionFilterUnits || [];
    this.redeemPoints = redeemPoints;
    this.initSortDropdown();
  }

  getStaticFilterUnits(filter: CarStaticFilter): CarStaticFilterUnit[] {
    switch (filter) {
      case CAR_STATIC_FILTER.RATING:
        return this.carRatingFilterUnits;
      case CAR_STATIC_FILTER.SPECIFICATION:
        return this.carSpecificationFilterUnits;
      case CAR_STATIC_FILTER.FUEL:
        return this.carFuelFilterUnits;
      case CAR_STATIC_FILTER.TRANSMISSION:
        return this.carTransmissionFilterUnits;
      default:
        return [];
    }
  }

  initSortDropdown(): void {
    const sortOptions: SortOption[] = SearchSortingUtil.permittedOptions.map((sortKey: SortType) => ({
      ...SearchSortingUtil.options[sortKey],
    }));
    this.sortDropdownConfig = {
      options: sortOptions,
      labelKey: "label",
      dropdownLabel: "Sort By:",
    };
    this.selectedSortValue = { ...SearchSortingUtil.options[SearchSortingUtil.current] };
  }
}
