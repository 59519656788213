import { Config } from "@ascenda/ngx-simple-dropdown";

import { HotelDestination } from "./hotel-destination.model";
import { TimeUtils } from "booking-app-v2/shared/utils";

export interface GuestsBreakdown {
  roomCount: number;
  adultCount: number;
  childrenCount: number;
  childrenAge: number[];
}

export class HotelSearchForm {
  static MIN_ROOM_COUNT: number = 1;
  static MIN_ADULT_COUNT: number = 1;
  static MIN_CHILD_COUNT: number = 0;
  static MAX_ROOM_COUNT: number = 4;
  static MAX_ADULT_COUNT: number = 4;
  static MAX_CHILD_COUNT: number = 4;
  static ROOM_DROPDOWN_CONFIG: Config<number> = { options: [1, 2, 3, 4] };
  static ADULT_DROPDOWN_CONFIG: Config<number> = { options: [1, 2, 3, 4] };
  static CHILD_DROPDOWN_CONFIG: Config<number> = { options: [0, 1, 2, 3, 4] };
  static CHILDREN_AGE_DROPDOWN_CONFIG: Config<number> = {
    options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
  };
  private static readonly HOTEL_SEARCH_DATE_FORMAT: string = "MM/DD/YYYY";

  readonly destination: HotelDestination;
  readonly checkInDate: string;
  readonly checkOutDate: string;
  readonly rooms: number;
  readonly adults: number;
  readonly children: number;
  readonly childrenAges: number[];
  readonly guests: string;
  readonly duration: number;
  readonly searchDatesValid: boolean;

  constructor(
    destination: HotelDestination,
    checkInDate: string,
    checkOutDate: string,
    guests?: string,
    rooms?: number,
    adults?: number,
    children?: number,
    childrenAges?: number[],
  ) {
    this.destination = destination;
    this.checkInDate = TimeUtils.localiseAndFormat(checkInDate , HotelSearchForm.HOTEL_SEARCH_DATE_FORMAT);
    this.checkOutDate = TimeUtils.localiseAndFormat(checkOutDate , HotelSearchForm.HOTEL_SEARCH_DATE_FORMAT);
    this.duration = TimeUtils.getTimeDuration(this.checkOutDate, this.checkInDate).days();
    this.searchDatesValid = this.isSearchDatesValid();
    if (guests) {
      // if guests exist, it means we retrieved the guest info from the URL,
      // then we break down the guests string to get the rooms, adults and children info
      this.guests = guests;
      this.rooms = this.guestsBreakdown.roomCount;
      this.adults = this.guestsBreakdown.adultCount;
      this.children = this.guestsBreakdown.childrenCount;
      this.childrenAges = this.guestsBreakdown.childrenAge;
    } else {
      // else, it means we collected the room, adults and children info from the search form,
      // then we assign each of those values and format them to the guests string
      this.rooms = rooms;
      this.adults = adults;
      this.children = children;
      this.childrenAges = this.getAgesOfOnlyAddedChildren(childrenAges);
      this.guests = this.formattedGuests;
    }
  }

  private get formattedGuests(): string {
    if (!this.rooms || !this.adults) {
      return "";
    }
    const guestParams: string[] = [];
    for (let index = 0; index < this.rooms; index++) {
      if (this.children > 0) {
        guestParams.push(`${this.adults}:${this.childrenAges.join(",")}`);
      } else {
        guestParams.push(this.adults.toString());
      }
    }
    return guestParams.join("|");
  }

  private get guestsBreakdown(): GuestsBreakdown {
    const roomsInfo = this.guests.split("|");
    const firstRoomAdultCount = roomsInfo[0].split(":")[0];
    const childrenInfo = roomsInfo[0].split(":")[1];
    const childrenAgesArray = childrenInfo ? childrenInfo.split(",").map(Number) : [];

    return {
      roomCount: roomsInfo.length,
      adultCount: parseInt(firstRoomAdultCount, 10),
      childrenCount: childrenAgesArray.length,
      childrenAge: childrenAgesArray,
    };
  }

  private getAgesOfOnlyAddedChildren(childrenAges: number[]): number[] {
    return childrenAges.slice(0, this.children);
  }

  private isSearchDatesValid(): boolean {
    const inDate = this.formatDateAndTime(this.checkInDate);
    const outDate = this.formatDateAndTime(this.checkOutDate);

    if (!TimeUtils.isValidDate(inDate) || !TimeUtils.isValidDate(outDate)) {
      return false;
    }

    const today: string = TimeUtils.setStartOfTime("day").format(HotelSearchForm.HOTEL_SEARCH_DATE_FORMAT);

    return TimeUtils.diff(inDate, today, "d") >= 0 && TimeUtils.diff(outDate, inDate,  "d") > 0;
  }

  private formatDateAndTime(dateTime: string): string {
    return TimeUtils.format(dateTime , HotelSearchForm.HOTEL_SEARCH_DATE_FORMAT)
  }
}
