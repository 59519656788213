import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, Subject, take, zip } from "rxjs";

import { ForceSyncBaseStep } from "./force-sync-base-step";
import { DialogService } from "../../dialog.service";
import { WindowRefService } from "../../window-ref.service";

import {
  AvailabilityCheckResult,
  CancellationPolicyResponse,
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  SIMPLE_DIALOG,
} from "booking-app-v2/shared/types";
import { Room } from "booking-app-v2/hotels/models";
import { CheckoutError } from "booking-app-v2/shared/models";
import { SimpleDialogComponent } from "booking-app-v2/shared/components/dialog/simple-dialog/simple-dialog.component";
import { HotelsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/hotels-url-builder.service";

@Injectable({
  providedIn: "root",
})

export class HotelAvailabilityCheckService implements ForceSyncBaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.AVAILABILITY_CHECK;

  constructor(
    private dialogService: DialogService,
    private windowRefService: WindowRefService,
    private hotelsUrlBuilderService: HotelsUrlBuilderService,
  ) { }

  processAsync(results: CheckoutResults): Observable<AvailabilityCheckResult> {
    const availabilityCheckResult: Subject<void> = new ReplaySubject<void>(1);

    zip(
      results[CHECKOUT_RESULT_NAME.FETCH_PRICE],
      results[CHECKOUT_RESULT_NAME.FETCH_POLICY],
    ).pipe(take(1)).subscribe(([room, policy]: [Room, CancellationPolicyResponse]) => {
      if (room === undefined || policy.invalid) {
        this.showRoomUnavailableDialog();
        availabilityCheckResult.error(new CheckoutError("Room not available"));
      } else {
        availabilityCheckResult.next();
      }
    });

    return availabilityCheckResult.asObservable();
  }

  private showRoomUnavailableDialog(): void {
    this.dialogService.open(SIMPLE_DIALOG.HOTEL_ROOM_UNAVAILABLE, SimpleDialogComponent).subscribe(() => {
      this.hotelsUrlBuilderService.redirectToHotelDetailsPage(
        this.windowRefService.getURIParamFromWindow(),
      );
    });
  }
}
