const MAX_YEAR = (new Date().getFullYear() - 16);
const CREDIT_CARD_EXPIRY_MIN_YEAR = new Date().getFullYear();
const CREDIT_CARD_EXPIRY_MAX_YEAR = CREDIT_CARD_EXPIRY_MIN_YEAR + 20;

function range(begin, end): number[] {
  const arr = [];
  if (begin > end) {
    for (let i = begin; i >= end; i -= 1) { arr.push(i); }
  } else {
    for (let i = begin; i <= end; i += 1) { arr.push(i); }
  }
  return arr;
}

export const kaligoConstants = {
  emailRegex: "^([^@]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$",
  creditCardExpirationYears: range(CREDIT_CARD_EXPIRY_MIN_YEAR, CREDIT_CARD_EXPIRY_MAX_YEAR),
};
