import { Injectable } from "@angular/core";
import { Location } from "@angular/common";

import { WindowRefService } from "../window-ref.service";
import { TRAVEL_TYPE, TravelType } from "../../types/travel-type";
import { AppSettingsService } from "../app-settings.service";
import { GlobalData } from "../global-data.service";
import { GlobalDataEnum } from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class TravelTypeService {

  constructor(
    private windowService: WindowRefService,
    private appSettingsService: AppSettingsService,
    private globalDataService: GlobalData,
    private location: Location,
  ) { }

  public init(): void {
    let travelType: TravelType;
    switch (this.getTravelTypeFromUrl()) {
      case TRAVEL_TYPE.HOTELS: {
        travelType = TRAVEL_TYPE.HOTELS;
        break;
      }
      case TRAVEL_TYPE.FLIGHTS: {
        travelType = TRAVEL_TYPE.FLIGHTS;
        break;
      }
      case TRAVEL_TYPE.CARS: {
        travelType = TRAVEL_TYPE.CARS;
        break;
      }
      default: {
        travelType = TRAVEL_TYPE.HOTELS;
        break;
      }
    }
    this.changeTravelType(travelType);
  }

  public changeTravelTypeAndUrl(type: TravelType): void {
    this.changeTravelType(type);
    this.changeTravelTypeUrl(type);
  }

  // Only accepts original path ea "/results/:destinationId"
  public getTravelTypeFromPath(path: string): TravelType {
    if (path.includes("car")) {
      return TRAVEL_TYPE.CARS;
    } else if (path.includes("flight")) {
      return TRAVEL_TYPE.FLIGHTS;
    } else {
      return TRAVEL_TYPE.HOTELS;
    }
  }

  public isTravelType(type: TravelType): boolean {
    return this.globalDataService.get(GlobalDataEnum.TRAVEL_TYPE) === type;
  }

  private changeTravelTypeUrl(type: TravelType): void {
    this.location.replaceState(type);
  }

  private changeTravelType(travelType: TravelType): void {
    this.globalDataService.set(GlobalDataEnum.TRAVEL_TYPE, travelType);
  }

  private getTravelTypeFromUrl(): TravelType {
    const urlSegment: string = this.windowService.nativeWindow.location.pathname.split("/")[1];

    if (urlSegment === "") {
      return this.appSettingsService.defaultTravelTypeInHomePage;
    }

    if (urlSegment === "results") {
      return TRAVEL_TYPE.HOTELS;
    }

    return urlSegment as TravelType;
  }
}
