import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import { finalize, Observable, ReplaySubject, Subject } from "rxjs";

import { ForceSyncBaseStep } from "./force-sync-base-step";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  GlobalDataEnum,
  SIMPLE_DIALOG,
  TRAVEL_TYPE,
  TravelType,
  ValidateBookingResult,
} from "booking-app-v2/shared/types";
import { CheckoutError, User } from "booking-app-v2/shared/models";
import { Room } from "booking-app-v2/hotels/models";
import { Flight } from "booking-app-v2/flights/models";
import { Car } from "booking-app-v2/cars/models";

import { PointsAdjustmentService } from "booking-app-v2/shared/services/points-adjustment.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { UserService } from "booking-app-v2/shared/services/user.service";
import { DialogService } from "booking-app-v2/shared/services/dialog.service";
import { PaymentFormUtilService } from "booking-app-v2/shared/services/payment-form-util.service";
import { AdyenFormService } from "booking-app-v2/shared/services/adyen-form.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { SimpleDialogComponent } from "booking-app-v2/shared/components/dialog/simple-dialog/simple-dialog.component";
import { PayWithPointsCashService } from "booking-app-v2/shared/services/pay-with-points-cash.service";
import { FlightUtilService } from "booking-app-v2/flights/services/flight-util.service";

@Injectable({
  providedIn: "root",
})
export class ValidateBookingService implements ForceSyncBaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.VALIDATE_BOOKING;

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private pointsAdjustmentService: PointsAdjustmentService,
    private userService: UserService,
    private dialogService: DialogService,
    private adyenFormService: AdyenFormService,
    public paymentFormUtilService: PaymentFormUtilService,
    private payWithPointsCashService: PayWithPointsCashService,
    private flightUtilService: FlightUtilService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  processAsync(results: CheckoutResults): Observable<ValidateBookingResult> {
    const result: Subject<void> = new ReplaySubject<void>(1);

    if (this.appSettingsService.checkUserStillLoggedIn) {
      this.userService.pollCurrentUser()
        .pipe(finalize(() => result.next()))
        .subscribe({
          next: (user: User) => {
            if (user) {
              this.validateOthers(results, result);
            } else {
              this.dialogService.openLoginModal();
              result.error(new CheckoutError("User need to be login"));
            }
          },
          error: () => {
            this.dialogService.openLoginModal();
            result.error(new CheckoutError("User need to be login"));
          },
        });
    } else {
      this.validateOthers(results, result);
    }

    return result.asObservable();
  }

  private validateOthers(results: CheckoutResults, result: Subject<void>): void {

    if (!this.checkIsPointsBalanceValid(results)) {
      result.error(new CheckoutError("Invalid points balance"));
    }

    if (this.isUserIneligible()) {
      this.dialogService.open(SIMPLE_DIALOG.USER_INELIGIBILITY, SimpleDialogComponent);
      result.error(new CheckoutError("User not eligible"));
    }

    if (!this.adyenFormService.validAdyenForm(results.intermediateCheckoutParams.checkoutForm)) {
      this.scrollToAdyenForm();
      result.error(new CheckoutError("Invalid Adyen form"));
    }

    if (this.invalidCheckoutForm(results.intermediateCheckoutParams.checkoutForm)) {
      this.scrollToInvalidField();
      result.error(new CheckoutError("Invalid checkout form"));
    }

    if (this.invalidDates(results.intermediateCheckoutParams.checkoutForm)) {
      result.error(new CheckoutError("Invalid checkout form"));
    }

    // resolve result if above 3 check all pass
    result.next();
  }

  private isUserIneligible(): boolean {
    return this.appSettingsService.userIneligibilityCheck &&
      this.globalData.get(GlobalDataEnum.CURRENT_USER).account_status === "blocked";
  }

  private checkIsPointsBalanceValid(results: CheckoutResults): boolean {
    const travelType: TravelType = this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);

    switch (travelType) {
      case TRAVEL_TYPE.HOTELS:
        const room: Room = results.intermediateCheckoutParams.room;

        if (!room) {
          return false;
        }

        return !this.pointsAdjustmentService.showInsufficientPointBalance(
          room.priceInfo.points_payment,
          room.priceInfo.minPoint,
        );
      case TRAVEL_TYPE.FLIGHTS:
        const flight: Flight = results.intermediateCheckoutParams.flight;

        if (!flight) {
          return false;
        }

        return !this.pointsAdjustmentService.showInsufficientPointBalance(
          flight.priceInfo.points_payment,
          this.payWithPointsCashService.minPoint(flight.rawPrice),
        );
      case TRAVEL_TYPE.CARS:
        const car: Car = results.intermediateCheckoutParams.car;

        if (!car) {
          return false;
        }

        return !this.pointsAdjustmentService.showInsufficientPointBalance(
          car.priceInfo.points_payment,
          car.priceInfo.minPoint,
        );
      default:
        return false;
    }
  }

  private invalidCheckoutForm(checkoutForm: FormGroup): boolean {
    if (!checkoutForm) {
      return true;
    }

    // below is to rerun validators for the invalid fields so that
    // certain hidden fields (e.g. card number field when in full points payment) will be skipped for validations
    // (e.g. card number will still be valid even though it has the required validator bind on the template)
    Object.keys(checkoutForm.controls).forEach((formControlName: string) => {
      const control: AbstractControl = checkoutForm.get(formControlName);
      if (!control.valid) {
        control.updateValueAndValidity();
      }
    });

    return !checkoutForm.valid;
  }

  private invalidDates(checkoutForm: FormGroup): boolean {
    const travelType: TravelType = this.globalData.get(GlobalDataEnum.TRAVEL_TYPE);

    if (travelType === TRAVEL_TYPE.FLIGHTS) {
      const secondaryPassengersDatesInvalid = (checkoutForm.controls.extraPassengers as FormArray).controls.some(
        (secondaryForm: FormGroup) => (
          this.flightUtilService.invalidDateOfBirth(secondaryForm) ||
          this.flightUtilService.invalidPassportExpiryDate(secondaryForm)
        ),
      );

      return (
        this.flightUtilService.invalidDateOfBirth(checkoutForm) ||
        this.flightUtilService.invalidPassportExpiryDate(checkoutForm) ||
        secondaryPassengersDatesInvalid
      );
    } else {
      return false;
    }
  }

  private scrollToInvalidField(): void {
    setTimeout(() => {
      const errorElement: HTMLElement = this.document
        .querySelector("form")
        .querySelector("mat-form-field.ng-invalid, mat-checkbox.ng-invalid");

      errorElement?.scrollIntoView({ behavior: "smooth", block: "start" });
    });
  }

  private scrollToAdyenForm(): void {
    setTimeout(() => {
      const errorElement: HTMLElement = this.document
        .querySelector("#adyen-custom-card-container");
      errorElement?.scrollIntoView({ behavior: "smooth", block: "start" });
    });
  }
}
