import { Injectable } from "@angular/core";

import { BaseStep } from "./base-step";
import {
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  LoginCheckResult,
} from "booking-app-v2/shared/types";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";

@Injectable({
  providedIn: "root",
})
export class LoginCheckService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.LOGIN_CHECK;

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
  ) {}

  process(results: CheckoutResults): LoginCheckResult {
    if (!this.appSettingsService.requireLoginOnCheckout) {
      return true;
    }

    return this.globalData.isUserLoggedIn();
  }

}
