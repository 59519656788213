import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from "@angular/core";

import { carouselAnimation } from "booking-app-v2/shared/animations";

@Component({
  selector: "carousel",
  template: `
    <div class="carousel-images-container" (swipeleft)="onNext()" (swiperight)="onPrevious()">
      <ng-container *ngIf="displayArrows">
          <button type="button" class="carousel-prev-btn"
                  [attr.aria-label]="'Previous ' + arrowAriaLabel + ' photo'"
                  (click)="onPrevious()"></button>
          <button type="button" class="carousel-next-btn"
                  [attr.aria-label]="'Next ' + arrowAriaLabel + ' photo'"
                  (click)="onNext()"></button>
      </ng-container>
      <ng-container *ngFor="let img of images; let idx = index">
        <div *ngIf="!!getImageState(idx)"
             [@carouselAnimation]="getImageState(idx)"
             class="carousel-image-container">
          <img [src]="img"
               (error)="handleError($event)"
               [ngClass]="imagePosition"
               class="carousel-image"
               draggable="false"
               alt="Image {{ idx + 1 }}" />
        </div>
      </ng-container>
      <div *ngIf="imageInsetShadow" class="carousel-inset-shadow"></div>
    </div>
  `,
  animations: [carouselAnimation],
})
export class CarouselComponent implements OnInit {
  @Input() images: string[];
  @Input() initialImageIndex: number;
  @Input() displayArrows: boolean;
  @Input() arrowAriaLabel: string;
  @Input() autoplay: boolean;
  @Input() autoplaySpeed: number;
  @Input() imagePosition: "contain" | "cover";
  @Input() imageInsetShadow: boolean;

  @Output() imageChange: EventEmitter<number> = new EventEmitter<number>();

  counter: number;

  private autoPlayInterval: ReturnType<typeof setInterval>;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    this.images = this.images || [];
    this.counter = this.initCounter();
    this.displayArrows = this.displayArrows !== false && (this.images.length > 1);
    this.arrowAriaLabel = this.arrowAriaLabel || "";
    this.autoplay = this.autoplay || false;
    this.autoplaySpeed = this.autoplaySpeed || 3000;
    this.imagePosition = this.imagePosition || "cover";
    this.imageInsetShadow = this.imageInsetShadow || false;

    this.imageChange.emit(this.counter);
    this.handleAutoPlay();
  }

  onNext(): void {
    this.goNext();
    this.handleAutoPlay();
  }

  onPrevious(): void {
    this.goPrevious();
    this.handleAutoPlay();
  }

  getImageState(index: number): "prev" | "curr" | "next" {
    if (index === this.counter) {
      return "curr";
    }

    if (this.counter === this.images.length - 1 && index === 0) {
      return "next";
    }

    if (this.counter === 0 && index === this.images.length - 1) {
      return "prev";
    }

    if (index === this.counter - 1) {
      return "prev";
    } else if (index === this.counter + 1) {
      return "next";
    } else {
      return null;
    }
  }

  handleError(event: Event): void {
    this.renderer.addClass(event.target, "error");
  }

  private initCounter(): number {
    if (!this.initialImageIndex || (this.initialImageIndex >= this.images.length) || (this.initialImageIndex < 0)) {
      return 0;
    }
    return this.initialImageIndex;
  }

  private goNext(): void {
    if (this.counter === this.images.length - 1) {
      this.counter = 0;
    } else {
      this.counter++;
    }
    this.imageChange.emit(this.counter);
  }

  private goPrevious(): void {
    if (this.counter === 0) {
      this.counter = this.images.length - 1;
    } else {
      this.counter--;
    }
    this.imageChange.emit(this.counter);
  }

  private handleAutoPlay(): void {
    if (!this.autoplay) {
      return;
    }

    if (this.autoPlayInterval) {
      clearInterval(this.autoPlayInterval);
    }
    this.autoPlayInterval = setInterval(() => this.goNext(), this.autoplaySpeed);
  }
}
