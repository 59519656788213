import { ChangeDetectorRef, Component, EventEmitter, Output } from "@angular/core";
import { Router, UrlSerializer, UrlTree } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PageDiscoveryService } from "booking-app-v2/shared/services/page-discovery.service";
import { WindowRefService } from "booking-app-v2/shared/services/window-ref.service";
import { UserActionService } from "booking-app-v2/shared/services/user-action.service";
import { PointsCashShareService } from "booking-app-v2/shared/services/initializers/points-cash-share.service";
import { GlobalDataEnum } from "booking-app-v2/shared/types";
import { HotelDestination, HotelSearchForm } from "booking-app-v2/hotels/models";
import { HotelSearchFormComponent } from "../hotel-search-form/hotel-search-form.component";
import { HotelsUrlBuilderService } from "booking-app-v2/shared/services/url-builder/hotels-url-builder.service";

@Component({
  selector: "hotel-edit-search-form",
  templateUrl: "/html/hotels/edit_search_form_v2",
})

export class HotelEditSearchFormComponent extends HotelSearchFormComponent {
  @Output() closeHotelEditSearchModal: EventEmitter<void> = new EventEmitter<void>();

  hotelSearchFormValuesFromGlobalData: HotelSearchForm;

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected router: Router,
    protected urlSerializer: UrlSerializer,
    protected pageDiscoveryService: PageDiscoveryService,
    protected windowRefService: WindowRefService,
    protected appSettingsService: AppSettingsService,
    protected globalData: GlobalData,
    protected userActionService: UserActionService,
    protected pointsCashShareService: PointsCashShareService,
    protected hotelsUrlBuilderService: HotelsUrlBuilderService,
  ) {
    super(
      changeDetector,
      router,
      urlSerializer,
      windowRefService,
      appSettingsService,
      globalData,
      pageDiscoveryService,
      userActionService,
      pointsCashShareService,
      hotelsUrlBuilderService,
    );
  }

  protected initFormControlAndSelectedValues(): void {
    this.hotelSearchFormValuesFromGlobalData = this.globalData.get(GlobalDataEnum.HOTEL_SEARCH_FORM);
    this.hotelSearchForm = new FormGroup({
      destination: new FormControl(this.hotelSearchFormValuesFromGlobalData.destination),
      checkInDate: new FormControl(this.hotelSearchFormValuesFromGlobalData.checkInDate),
      checkOutDate: new FormControl(this.hotelSearchFormValuesFromGlobalData.checkOutDate),
      rooms: new FormControl(this.hotelSearchFormValuesFromGlobalData.rooms),
      adults: new FormControl(this.hotelSearchFormValuesFromGlobalData.adults),
      children: new FormControl(this.hotelSearchFormValuesFromGlobalData.children),
      childrenAges: new FormControl(this.hotelSearchFormValuesFromGlobalData.childrenAges),
    });
  }

  protected submitHotelSearchForm(): void {
    const destination: HotelDestination = this.hotelSearchForm.controls.destination.value;

    this.markFormAsDirty();

    if (!this.hotelSearchForm.valid) {
      return;
    }

    this.trackUserDestinationSearch();

    this.createHotelSearchFormObj(destination);

    if (this.isSearchParamsNotChanged(this.getSearchFormUrl(destination))) {
      this.closeHotelEditSearchModal.emit();
      return;
    }

    this.trackHotelSearch();

    this.globalData.set(GlobalDataEnum.IS_LOADING, true);
    this.globalData.set(GlobalDataEnum.IS_NEW_SEARCH, true);

    this.windowRefService.nativeWindow.location.href = this.urlSerializer.serialize(this.getSearchFormUrl(destination));
  }

  private isSearchParamsNotChanged(searchUrl: UrlTree): boolean {
    const location: Location = this.windowRefService.nativeWindow.location;
    return decodeURIComponent(searchUrl.toString())
      === decodeURIComponent(`${location.pathname}${location.search}`);
  }

}
