import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { TravelBreakdownModule } from "booking-app-v2/shared/components/travel-breakdown/travel-breakdown.module";
import { GroupedRoomListItemComponent } from "./grouped-room-list-item.component";
import { ComplimentaryPriceBoxModule } from "../complimentary-price-box/complimentary-price-box.module";

@NgModule({
  declarations: [GroupedRoomListItemComponent],
  imports: [
    SharedModule,
    TravelBreakdownModule,
    ComplimentaryPriceBoxModule,
  ],
  exports: [GroupedRoomListItemComponent],
  providers: [],
})
export class GroupedRoomListItemModule { }
