import { NgModule } from "@angular/core";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { HotelListItemImageComponent } from "./hotel-list-item-image.component";

@NgModule({
  declarations: [ HotelListItemImageComponent ],
  imports: [
    SharedModule,
  ],
  exports: [ HotelListItemImageComponent ],
  providers: [],
})
export class HotelListItemImageModule {}
