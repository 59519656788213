import { Injectable } from "@angular/core";
import { finalize, Observable, Subject, Subscription } from "rxjs";

import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import { ApiDataService  } from "booking-app-v2/shared/services/api-data.service";
import { CouponCodeDetails, CouponCodeResponse } from "booking-app-v2/shared/types";
import { Room } from "booking-app-v2/hotels/models/room.model";
import { Car } from "booking-app-v2/cars/models";

@Injectable({
  providedIn: "root",
})
export class CouponService {
  couponCodeDetails: CouponCodeDetails;
  showCouponCodeUI: boolean;
  validationResult: Subject<boolean>;
  validatingPriceChanged: boolean;

  private validatingCouponCode: boolean = false;
  private readonly couponErrorMapper = {
    "Invalid Coupon code": "txt.invalid_coupon_code",
    "Coupon has expired": "txt.expired_coupon_code",
    "Coupon fully redeemed": "txt.fully_redeemed_coupon_code",
    "Conditions not met": "txt.not_eligible_coupon_code",
  };

  constructor(
    private apiDataService: ApiDataService,
    private appSettingsService: AppSettingsService,
  ) {
    this.showCouponCodeUI = this.appSettingsService.hasCouponCode;
    this.validationResult = new Subject<boolean>();
    this.initializeCouponDetails();
  }

  validateCouponCode(): Observable<boolean> {
    const couponCode: string = this.couponCodeDetails.code;
    if (!couponCode) {
      return this.validationResult.asObservable();
    }
    this.validatingCouponCode = true;
    this.resetCouponDetailsResponse();

    const validateCouponCodeCall: Subscription = this.apiDataService
      .post("coupon_codes/validate", { coupon_code: couponCode })
      .pipe(finalize(() => validateCouponCodeCall.unsubscribe()))
      .subscribe((response: CouponCodeResponse) => {
          this.couponCodeDetails.response = {
            valid: response.valid,
            errorMsg: response.error,
          };
          if (!response.valid) {
            this.validatingCouponCode = false;
          }
          this.validationResult.next(response.valid);
        },
      );
    return this.validationResult.asObservable();
  }

  disableButton(): boolean {
    return this.couponCodeDetails.code === ""
      || this.validatingCouponCode
      || this.isValid();
  }

  resetMessage(): void {
    this.couponCodeDetails.response.errorMsg = null;
  }

  resetCouponCode(): void {
    this.couponCodeDetails.code = "";
    this.validatingCouponCode = false;
    this.validatingPriceChanged = false;
    this.couponCodeDetails.response.valid = false;
    this.resetMessage();
  }

  isValid(): boolean {
    return this.couponCodeDetails.response.valid && !this.validatingPriceChanged;
  }

  hasErrorMessage(): boolean {
    return !!this.couponCodeDetails.response.errorMsg;
  }

  couponErrorMessage(): string {
    return this.couponErrorMapper[this.couponCodeDetails.response.errorMsg];
  }

  showCouponAppliedMessage(): boolean {
    return this.showCouponCodeUI && this.couponCodeDetails.response.valid;
  }

  updateCouponCode(couponCode: string): void {
    this.couponCodeDetails.code = couponCode;
  }

  updateValidationResponse(travelItem: Room | Car): void {
    const hasDiscountedPrice: boolean = travelItem.priceInfo.hasDiscountedPrice();
    if (!hasDiscountedPrice && !this.hasErrorMessage()) {
      this.couponCodeDetails.response = {
        valid: false,
        errorMsg: "Conditions not met",
      };
    }
    this.validatingCouponCode = false;
  }

  isValidatingCouponCode(): boolean {
    return this.validatingCouponCode || this.validatingPriceChanged;
  }

  hasCouponCode(): boolean {
    return this.couponCodeDetails.code.length > 0;
  }

  private initializeCouponDetails(couponCode: string = ""): void {
    this.couponCodeDetails = {
      code: couponCode,
      response: {
        valid: false,
        errorMsg: null,
      },
    };
  }

  private resetCouponDetailsResponse(): void {
    this.couponCodeDetails.response = {
      valid: false,
      errorMsg: null,
    };
  }
}
