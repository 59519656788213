import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSimpleDropdownModule } from "@ascenda/ngx-simple-dropdown";

import { DatePickerModule } from "booking-app-v2/shared/components/date-picker/date-picker.module";
import { HotelDestinationSearchModule } from "booking-app-v2/shared/components/hotel-destination-search/hotel-destination-search.module";
import { RoomGuestFieldModule } from "booking-app-v2/shared/components/room-guest-field/room-guest-field.module";
import {
  RoomGuestFieldMobileModule,
} from "booking-app-v2/shared/components/room-guest-field-mobile/room-guest-field-mobile.module";
import { SharedModule } from "booking-app-v2/shared/shared.module";
import { HotelEditSearchFormComponent } from "./hotel-edit-search-form.component";
import { RegionSearchDropdownModule } from "../region-search-dropdown/region-search-dropdown.module";

@NgModule({
  declarations: [ HotelEditSearchFormComponent ],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HotelDestinationSearchModule,
    DatePickerModule,
    NgxSimpleDropdownModule,
    RoomGuestFieldModule,
    RoomGuestFieldMobileModule,
    RegionSearchDropdownModule,
  ],
  exports: [ HotelEditSearchFormComponent ],
  providers: [],
})

export class HotelEditSearchFormModule {}
