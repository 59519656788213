import { Injectable } from "@angular/core";
import { of, ReplaySubject, Subject } from "rxjs";

import { BaseStep } from "./base-step";
import { GlobalData } from "booking-app-v2/shared/services/global-data.service";
import { PaymentService } from "booking-app-v2/shared/services/payment.service";
import { AppSettingsService } from "booking-app-v2/shared/services/app-settings.service";
import {
  blankStoredPayments,
  CHECKOUT_RESULT_NAME,
  CheckoutResultName,
  CheckoutResults,
  FetchSavedCardsResult,
  StoredPayments,
} from "booking-app-v2/shared/types";

@Injectable({
  providedIn: "root",
})
export class FetchSavedCardsService implements BaseStep {

  resultName: CheckoutResultName = CHECKOUT_RESULT_NAME.FETCH_SAVED_CARDS;

  constructor(
    private appSettingsService: AppSettingsService,
    private globalData: GlobalData,
    private paymentService: PaymentService,
  ) {}

  process(results: CheckoutResults): FetchSavedCardsResult {
    if (!this.globalData.isUserLoggedIn() || !this.appSettingsService.storeCreditCard) {
      return of(blankStoredPayments);
    }

    return this.fetchSavedCards();
  }

  private fetchSavedCards(): FetchSavedCardsResult {
    const result: Subject<StoredPayments> = new ReplaySubject<StoredPayments>(1);

    this.paymentService.getStoredPayments()
      .subscribe((storedPayments: StoredPayments) => result.next(storedPayments));

    return result.asObservable();
  }

}
