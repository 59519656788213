import { NgModule } from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgxSimpleDropdownModule } from "@ascenda/ngx-simple-dropdown";

import { SharedModule } from "booking-app-v2/shared/shared.module";
import { HotelResultFilterMobileComponent } from "./hotel-result-filter-mobile.component";
import { BottomSheetWrapperModule } from "booking-app-v2/shared/components/bottom-sheet-wrapper/bottom-sheet-wrapper.module";
import { MiniMapModule } from "booking-app-v2/shared/components/mini-map/mini-map.module";
import { ReviewsScoreSliderModule } from "booking-app-v2/shared/components/reviews-score-slider/reviews-score-slider.module";
import { RedeemPointsSliderModule } from "booking-app-v2/shared/components/redeem-points-slider/redeem-points-slider.module";
import { CashSliderModule } from "booking-app-v2/shared/components/cash-slider/cash-slider.module";
import { StarRatingModule } from "../star-rating/star-rating.module";

@NgModule({
  declarations: [HotelResultFilterMobileComponent],
  imports: [
    SharedModule,
    BottomSheetWrapperModule,
    MiniMapModule,
    ReviewsScoreSliderModule,
    RedeemPointsSliderModule,
    CashSliderModule,
    StarRatingModule,
    NgxSimpleDropdownModule,
    MatCheckboxModule,
  ],
  exports: [HotelResultFilterMobileComponent],
  providers: [],
})
export class HotelResultFilterMobileModule { }
