import { HotelRawPrice} from "../types";
import { RoomPrice } from "../models";

export class AdminRatesDecorator {

  static decorate(price: RoomPrice, rawPrice: HotelRawPrice, numberOfNights: number, roomsCount: number): void {
    if (!rawPrice.rate) {
      return;
    }

    price.lowestNetRate = rawPrice.rate / roomsCount / numberOfNights;
    price.adminPricePerNight = rawPrice.lowest_price / roomsCount / numberOfNights;
    price.margin = rawPrice.margin;
    price.compRate = rawPrice.compare_rate / roomsCount / numberOfNights;
  }
}
