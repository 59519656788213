import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { Hotel } from "booking-app-v2/hotels/models";
import { DetailsService } from "booking-app-v2/hotels/services";

@Injectable({ providedIn: "root" })
export class HotelDetailsResolver implements Resolve<Hotel> {
  constructor(
    private hotelDetailsService: DetailsService,
    private router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Hotel> {
    const destinationId: string = route.paramMap.get("id");
    return this.hotelDetailsService.getHotel(destinationId).pipe(
      tap((rawHotel: Hotel) => {
        // when destinationId from route is invalid, the API will return empty object {}
        // then redirect user to landing page immediately
        if (Object.keys(rawHotel).length === 0) {
          this.router.navigate(["/"]);
        }
      }),
    );
  }
}
