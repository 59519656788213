import { Injectable } from "@angular/core";

import { BootstrapDataService } from "../bootstrap-data.service";
import { GlobalData } from "../global-data.service";
import { GlobalDataEnum, RawLandingPage } from "booking-app-v2/shared/types";
import { LandingPage } from "booking-app-v2/shared/models";
import { AppSettingsService } from "../app-settings.service";

@Injectable({
  providedIn: "root",
})
export class LandingPageService {

  private _landingPage: LandingPage;

  constructor(
    private bootstrapService: BootstrapDataService,
    private globalData: GlobalData,
    private appSettingsService: AppSettingsService,
  ) { }

  public init(): void {
    this.initLandingPage();
    this.globalData.set(GlobalDataEnum.LANDING_PAGE, this._landingPage);
  }

  private initLandingPage(): void {
    const rawLandingPage: RawLandingPage = this.bootstrapService.bootstrapData.landing_page;
    this._landingPage = new LandingPage(this.appSettingsService, this.globalData, rawLandingPage);
  }
}
